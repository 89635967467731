import { AssignationCondition, AvailableCondition } from 'api/models';

import { AssignationConditionText } from './AssignationConditionText';

type AssignationConditionsProps = {
  items: AssignationCondition[];
  availableConditions: AvailableCondition[];
  detailView?: boolean;
};

export const AssignationConditions = ({
  items,
  availableConditions,
  detailView,
}: AssignationConditionsProps) => (
  <div>
    {items.map((assignationCondition) => (
      <AssignationConditionText
        key={assignationCondition.id}
        assignationCondition={assignationCondition}
        availableConditions={availableConditions}
        detailView={detailView}
      />
    ))}
  </div>
);
