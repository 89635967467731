import { buildActions, makeReqCons } from 'store/actions';
import { ReduxFunction } from 'types/redux';

export const onBoardingConstants = {
  companyDetails: makeReqCons('company_details'),
  stripe: makeReqCons('stripe'),
  noPayment: makeReqCons('no_payment'),
  codaboxRequest: makeReqCons('codabox_request'),
  changeCodaboxMessage: makeReqCons('codabox_message'),
  codaboxToken: makeReqCons('codabox_token'),
  ponto: makeReqCons('ponto'),
  exact: makeReqCons('exact'),
  exactFetchCompanies: makeReqCons('onboarding_exact_fetch_companies'),
  bankAccount: makeReqCons('bankAccount'),
  setupDebtCollectionSettings: makeReqCons('debt_collection_settings'),
  companyIdentity: makeReqCons('color_logo'),
  twikey: makeReqCons('twikey'),
  yuki: makeReqCons('yuki'),
  horus: makeReqCons('horus'),
  horusFetchCompanies: makeReqCons('onboarding_horus_fetch_companies'),
  teamleader: makeReqCons('teamleader'),
  billit: makeReqCons('billit'),
  uploadTerms: makeReqCons('uploadTerms'),
};

type OnBoardingActions = {
  companyDetails: Function;
  companyDetailsRes: Function;
  stripe: Function;
  stripeRes: Function;
  codaboxRequest: Function;
  codaboxRequestRes: Function;
  changeCodaboxMessage: Function;
  changeCodaboxMessageRes: Function;
  codaboxToken: Function;
  codaboxTokenRes: Function;
  ponto: Function;
  pontoRes: Function;
  exact: Function;
  exactRes: Function;
  exactFetchCompanies: ReduxFunction;
  exactFetchCompaniesRes: Function;
  bankAccount: ReduxFunction;
  bankAccountRes: Function;
  setupDebtCollectionSettings: Function;
  setupDebtCollectionSettingsRes: Function;
  companyIdentity: Function;
  companyIdentityRes: Function;
  twikey: ReduxFunction;
  twikeyRes: Function;
  yuki: ReduxFunction;
  yukiRes: Function;
  horus: ReduxFunction;
  horusRes: Function;
  horusFetchCompanies: ReduxFunction;
  horusFetchCompaniesRes: Function;
  teamleader: ReduxFunction;
  teamleaderRes: Function;
  noPayment: ReduxFunction;
  noPaymentRes: Function;
  billit: Function;
  billitRes: Function;
  uploadTerms: Function;
  uploadTermsRes: Function;
};

const onBoardingActions = buildActions<OnBoardingActions>('', onBoardingConstants, {});

export { onBoardingActions };
