import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, Input } from 'shared/io';
import { RecoverActionType, RecoveryPlanStep } from 'types/recovery-plan';
import { StoreState } from 'types/storeTypes';

import { RecoveryStepFormCreateTask } from './RecoveryStepFormCreateTask';
import { RecoveryStepFormSendReminder } from './RecoveryStepFormSendReminder';

import styleIdentifiers from './RecoveryStepForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  step?: RecoveryPlanStep;
  availableTemplateTypes: RecoverActionType[];
  onSelectTemplate: (step: RecoveryPlanStep) => void;
  onlyTask?: boolean;
  onlyReminder?: boolean;
};

export default function RecoveryStepForm({
  step,
  availableTemplateTypes,
  onSelectTemplate,
  onlyTask = false,
  onlyReminder = false,
}: Props) {
  const { t } = useTranslation();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const form = useForm<RecoveryPlanStep>({
    shouldUnregister: true,
    defaultValues: step,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const getType = () => {
    if (onlyTask) {
      return 'task_generation';
    }
    if (onlyReminder) {
      return 'send_reminder';
    }
    if (!step?.step_type) {
      return undefined;
    }
    switch (step.step_type) {
      case 'task_generation':
        return step.step_type;
      default:
        return 'send_reminder';
    }
  };

  const getTitle = () => {
    if (onlyTask) {
      return t(i18nKeys.ADD_TASK);
    }
    if (onlyReminder) {
      return t(i18nKeys.ADD_REMINDER);
    }
    return t(i18nKeys.PLAN.EDITION_STEP);
  };

  const [type, setType] = useState(getType());

  const submit = (values: RecoveryPlanStep) =>
    onSelectTemplate({
      ...step,
      ...values,
    });

  if (!type) {
    return (
      <div className={styles('recovery-step-form')}>
        <div className={styles('head')}>{getTitle()}</div>
        <div className={styles('content')}>
          <div className={styles('action')} onClick={() => setType('send_reminder')}>
            <Icon name={IconName.SEND} />
            {t(i18nKeys.FORM.RECOVER_STEP.TYPE.SEND_REMINDER)}
          </div>
          <div className={styles('action')} onClick={() => setType('task_generation')}>
            <Icon name={IconName.COMMENT} />
            {t(i18nKeys.FORM.RECOVER_STEP.TYPE.CREATE_TASK)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...form}>
      <form className={styles('recovery-step-form')} onSubmit={handleSubmit(submit)}>
        <div className={styles('head')}>{getTitle()}</div>
        <div className={styles('content')}>
          {type === 'task_generation' ? (
            <>
              {!company.package.can_use_tasks ? (
                <div>{t(i18nKeys.ACCOUNT_LIMITATION)}</div>
              ) : (
                <RecoveryStepFormCreateTask>
                  <Input
                    register={register('delay', { required: true, min: 0 })}
                    errorMessage={errors.delay}
                    label={t(i18nKeys.DELAY_WITH_PREVIOUS_ACTION)}
                    type="number"
                    withBorder
                  />
                </RecoveryStepFormCreateTask>
              )}
            </>
          ) : (
            <RecoveryStepFormSendReminder availableTemplateTypes={availableTemplateTypes}>
              <Input
                register={register('delay', { required: true, min: 0 })}
                errorMessage={errors.delay}
                label={t(i18nKeys.DELAY_WITH_PREVIOUS_ACTION)}
                type="number"
                withBorder
              />
            </RecoveryStepFormSendReminder>
          )}
        </div>
        <div className={styles('button-wrapper')}>
          {(type !== 'task_generation' || company.package.can_use_tasks) && (
            <Button type="submit" noMargin label={t(i18nKeys.SAVE)} disabled={!type} />
          )}
        </div>
      </form>
    </FormProvider>
  );
}
