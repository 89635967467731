export enum BATCH_ACTIONS {
  PAUSE = 'pause',
  UNPAUSE = 'unpause',
  ASSIGN = 'assign',
  ASSIGN_TEMPLATE = 'assign_template',
  ASSIGN_CGV = 'assign_cgv',
  DELETE = 'delete',
  TASK = 'task',
  DISPUTE = 'dispute',
}
