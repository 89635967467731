import { i18nKeys, useTranslation } from 'locales';
import { useLoadDso } from 'shared/hooks';

import { Box, LoadingOverlay, Text } from '@mantine/core';

import { AnalyticsCard } from '../AnalyticsCard';
import { DsoChart } from '../charts';

export const DsoCard = () => {
  const { t } = useTranslation();
  const { dso = {}, isDsoLoading } = useLoadDso();

  return (
    <AnalyticsCard title={t(i18nKeys.ANALYTICS.DSO.TITLE)} helpContent={<DsoHelpContent />}>
      <Box w="100%" h="400px">
        {isDsoLoading ? <LoadingOverlay visible /> : <DsoChart dso={dso} />}
      </Box>
    </AnalyticsCard>
  );
};

const DsoHelpContent = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text fw="bold">{t(i18nKeys.ANALYTICS.DSO.HELP.TITLE)}</Text>
      <br />
      <Text>{t(i18nKeys.ANALYTICS.DSO.HELP.LINE_1)}</Text>
    </Box>
  );
};
