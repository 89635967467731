import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { useChangeLocale } from 'shared/hooks';

import { rem } from '@mantine/core';
import { Spotlight as MantineSpotlight } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons-react';

import { useBuildMenuItems } from '../hooks/use-build-menu-items';
import { menuToSpotlightActions } from '../utils';

export const Spotlight = ({}) => {
  const changeLocale = useChangeLocale();
  const { t } = useTranslation();
  const menu = useBuildMenuItems();
  const history = useHistory();

  const spotlightActions = menuToSpotlightActions(menu, history.push);
  spotlightActions.push(
    ...[
      {
        id: 'setLangEn',
        label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'EN' }),
        onClick: () => changeLocale('en'),
      },
      {
        id: 'setLangFr',
        label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'FR' }),
        onClick: () => changeLocale('fr'),
      },
      {
        id: 'setLangNl',
        label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'NL' }),
        onClick: () => changeLocale('nl'),
      },
    ],
  );

  return (
    <MantineSpotlight
      limit={10}
      shortcut={['mod+p', '/']}
      actions={spotlightActions}
      centered
      highlightQuery
      styles={{
        search: {
          borderRadius: '0px',
          border: 'none',
          borderBottom: '1px solid lightgrey',
        },
        action: {
          width: '100%',
          padding: '10px 0px 10px 20px',
        },
        content: {
          borderRadius: '10px',
        },
      }}
      searchProps={{
        leftSection: <IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />,
        placeholder: t(i18nKeys.SPOTLIGHT.PLACEHOLDER),
      }}
    />
  );
};
