import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const CeiSchema = z.record(z.coerce.string().datetime({ offset: true }), z.number().nullable());

export type Cei = z.infer<typeof CeiSchema>;

export async function loadCeiFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;
  const { data } = await instance.get('/analytics/ceis');
  return CeiSchema.parse(data);
}

export function useLoadCei() {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Cei, ApiError>({
    queryKey: ['cei'],
    queryFn: () => loadCeiFn(axiosInstance),
  });

  return addResourceNameToQueryResult<Cei, ApiError, 'cei'>('cei', queryResult);
}
