import { DisplayType, ModelType, VarType } from 'app/Private/CustomVariables/CustomVariables.types';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect, DateSelector, Input } from 'shared/io';
import { CustomVariable } from 'types/custom-variable-state';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './customVariableForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues: CustomVariable;
};

export default function CustomVariableForm({ onSubmit, initialValues }: Props) {
  const { t } = useTranslation();

  const defaultValues = {
    ...initialValues,
  };

  const form = useForm({
    defaultValues,
  });

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = form;

  const submit = (data: CustomVariable) => {
    data = { ...data };

    onSubmit(data);
  };

  const accurateFormInput = (varType) => {
    switch (varType) {
      case VarType.BOOLEAN:
        return (
          <Controller
            defaultValue=""
            name="default_value"
            render={() => (
              <CustomSelect
                selectClassName={styles('input')}
                keyText="description"
                keyValue="value"
                items={[
                  {
                    description: t(i18nKeys.YES),
                    value: 'true',
                  },
                  {
                    description: t(i18nKeys.NO),
                    value: 'false',
                  },
                ]}
                withBorder
                name="default_value"
                label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE)}
              />
            )}
          />
        );
      case VarType.DATE:
        return (
          <DateSelector
            name="default_value"
            className={styles('input')}
            noMinDate
            withBorder
            placeholder=" "
            label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE)}
            handleChange={(value) => {
              setValue('default_value', value);
            }}
          />
        );
      default:
        return (
          <Input
            register={register('default_value')}
            errorMessage={errors.name}
            className={styles('input')}
            withBorder
            noMargin
            type={varType === VarType.NUMBER ? 'number' : 'text'}
            step="0.01"
            label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE)}
          />
        );
    }
  };

  const isNewForm = typeof initialValues.id === 'undefined';

  return (
    <FormProvider {...form}>
      <form className={styles('CustomVariableForm')} onSubmit={handleSubmit(submit)}>
        <div className={styles('head')}>
          {t(i18nKeys.FORM.CUSTOM_VARIABLES.TITLE[isNewForm ? 'NEW' : 'EDIT'])}
        </div>
        <div className={styles('content')}>
          <div className="grid-row">
            <div className="col-12">
              <Input
                register={register('name', { required: true })}
                errorMessage={errors.name}
                className={styles('input', 'first-field')}
                withBorder
                noMargin
                type="text"
                label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.NAME)}
              />
            </div>
            <div className="col-6">
              <Controller
                defaultValue=""
                name="var_type"
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    selectClassName={styles('input')}
                    keyText="description"
                    keyValue="value"
                    items={[
                      {
                        description: t(i18nKeys.COLUMN_TYPE.STRING),
                        value: VarType.STRING,
                      },
                      {
                        description: t(i18nKeys.COLUMN_TYPE.NUMBER),
                        value: VarType.NUMBER,
                      },
                      {
                        description: t(i18nKeys.COLUMN_TYPE.BOOLEAN),
                        value: VarType.BOOLEAN,
                      },
                      {
                        description: t(i18nKeys.COLUMN_TYPE.DATE),
                        value: VarType.DATE,
                      },
                    ]}
                    withBorder
                    name="var_type"
                    label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.VAR_TYPE)}
                    notAllowed={!isNewForm}
                  />
                )}
              />
            </div>
            <div className="col-6">
              <Controller
                defaultValue=""
                name="model_type"
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    selectClassName={styles('input')}
                    keyText="description"
                    keyValue="value"
                    items={[
                      {
                        description: t(i18nKeys.COMMON.INVOICE),
                        value: ModelType.INVOICE,
                      },
                      {
                        description: t(i18nKeys.COMMON.CLIENT),
                        value: ModelType.DEBTOR,
                      },
                    ]}
                    withBorder
                    name="model_type"
                    label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.MODEL_TYPE)}
                    notAllowed={!isNewForm}
                  />
                )}
              />
            </div>
            {watch('var_type') === VarType.NUMBER && (
              <div className="col-12">
                <Controller
                  defaultValue=""
                  name="display"
                  render={() => (
                    <CustomSelect
                      selectClassName={styles('input')}
                      keyText="description"
                      keyValue="value"
                      items={[
                        { description: t(i18nKeys.DEFAULT), value: '' },
                        {
                          description: t(i18nKeys.DEBTOR.CURRENCY),
                          value: DisplayType.MONEY,
                        },
                        {
                          description: t(i18nKeys.PERCENTAGE),
                          value: DisplayType.PERCENTAGE,
                        },
                      ]}
                      withBorder
                      name="display"
                      label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DISPLAY)}
                    />
                  )}
                />
              </div>
            )}
            <div className="col-12">{accurateFormInput(watch('var_type'))}</div>
            {/* <div className="col-6">
              <Checkbox
                register={register('api')}
                watch={watch}
                label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.API)}
              />
            </div>
            <div className="col-6">
              <Checkbox
                register={register('webhook')}
                watch={watch}
                label={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.WEBHOOK)}
              />
            </div> */}
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button disabled={!isDirty} noMargin label={t(i18nKeys.SAVE)} type="submit" />
        </div>
      </form>
    </FormProvider>
  );
}
