import { i18nKeys, useTranslation } from 'locales';

import { Center, Image, Stack, Title } from '@mantine/core';

import { Monitoring } from '../../../../shared/hooks';

type ClientScoreProps = {
  score: NonNullable<Monitoring>['score'];
};

export const ClientScore = ({ score }: ClientScoreProps) => {
  const { t } = useTranslation();
  const { image, value } = score;

  return (
    <Center>
      <Stack>
        <Title order={3}>{t(i18nKeys.DEBTOR.MONITORING.SCORE)}</Title>
        <Image
          src={`https://recovr.s3.eu-west-3.amazonaws.com/public/company-web/${image}`}
          alt={`A gauge showing the score of the company, in this case: ${value}`}
          w={{ base: 220, xl: 250 }}
          h="100%"
        />
      </Stack>
    </Center>
  );
};
