import Twikey from 'app/Private/Onboarding/PaymentMethod/Twikey';
import { useTenant } from 'app/TenantValidator';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Cards from 'react-credit-cards';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';
import { Integration, INTEGRATION_ROUTE } from 'shared/components/Integrations';
import PaymentForm from 'shared/forms/PaymentForm';
import { Button, ButtonColor } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { SubscriptionInformation } from 'types/store/account-state';
import { StoreState } from 'types/storeTypes';

import '~/../node_modules/react-credit-cards/es/styles-compiled.css';
import styleIdentifiers from './paymentSettingsForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues?: any;
};

export default function PaymentSettingsForm({ onSubmit, initialValues }: Props) {
  const { t } = useTranslation();
  const company = useSelector((state: StoreState) => state.account.company.data!);
  const tenant = useTenant();
  const card = (initialValues && initialValues.card_information) || {};
  const myPackage: SubscriptionInformation = (initialValues && initialValues.package) || {};
  const history = useHistory();

  const editBankingInfo: onSubmitFunction = (data) => {
    accountActions.editBankingInfo({
      data,
      callback: () => {
        accountActions.getCompany();
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  const openBankingModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      keepMountOnExit: true,

      title: t(i18nKeys.CREDIT_CARD),
      children: <PaymentForm onSubmit={editBankingInfo} />,
    });
  };

  const goToDigitealIntegration = () => {
    history.push(INTEGRATION_ROUTE[Integration.DIGITEAL]);
  };

  const chooseTwikey = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.TITLE),
      children: <Twikey onSubmit={() => {}} />,
    });
  };

  return (
    <form className={styles('PaymentSettingsForm')} onSubmit={onSubmit}>
      <div className={styles('content', tenant.customBranding && 'reseller')}>
        {!tenant.customBranding && (
          <div
            className={styles('card', initialValues.payment_method === 'twikey' && 'domiciliation')}
          >
            <div>
              <h3>{t(i18nKeys.SETTINGS.PACKAGE.PAYMENT_METHOD)}</h3>
              {!company.payment_method && (
                <span className={styles('text-red')}>
                  <Icon name={IconName.ALERT_CIRCLE} /> {t(i18nKeys.NO_PAYMENT_METHOD)}
                </span>
              )}
            </div>
            {initialValues.payment_method === 'twikey' ? (
              <span>
                <Icon name={IconName.DOMICILIATION} className={styles('dark')} size="25px" />
                {t(i18nKeys.SETTINGS.PACKAGE.SEPA_MANDATE_VIA_TWIKEY)}
              </span>
            ) : company.card_information.can_edit_card ? (
              <>
                <Cards
                  preview
                  number={(card && `************${card.last4_digits}`) || ''}
                  name={card.holder_name || ''}
                  expiry={(card && `${card.expiry_month}/${card.expiry_year}`) || ''}
                  cvc={card.verification_numbers || ''}
                  issuer={card.card_brand}
                />

                {company.card_information.can_edit_card && (
                  <Button
                    onClick={openBankingModal}
                    noMargin
                    label={t(i18nKeys.SETTINGS.PACKAGE.EDIT_BANKING_INFO)}
                  />
                )}
              </>
            ) : (
              !company.payment_method && (
                <div className={styles('no-payment-section')}>
                  <Button
                    iconSize="18px"
                    iconLeft={IconName.DOMICILIATION}
                    color={ButtonColor.GREEN}
                    label={t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.TITLE)}
                    onClick={chooseTwikey}
                  />
                  <Button
                    iconSize="18px"
                    iconLeft={IconName.CREDIT_CARD}
                    label={t(i18nKeys.CREDIT_CARD)}
                    onClick={openBankingModal}
                  />
                </div>
              )
            )}
            {company.api.direct_billing_by_integrator && (
              <span>
                <Icon name={IconName.INFO} className={styles('dark')} size="25px" />
                {t(i18nKeys.SETTINGS.PACKAGE.PAYMENT_BY_INTEGRATOR)}
              </span>
            )}
          </div>
        )}
        <div className={styles('package')}>
          <h3>{t(i18nKeys.SETTINGS.PACKAGE.YOUR_PACKAGE)}</h3>

          <ul className={styles('features')}>
            <li>
              <div className={styles('single')}>
                <span className={styles('description')}>
                  {t(i18nKeys.SETTINGS.PACKAGE.NUMBER_PHONE_BY_MONTH)}
                </span>
                <span className={styles('value')}>{myPackage.included_callcenter_count}</span>
              </div>
            </li>

            <li>
              <div className={styles('single')}>
                <span className={styles('description')}>
                  {t(i18nKeys.SETTINGS.PACKAGE.NUMBER_LEGAL_CREDITS)}{' '}
                </span>
                <span className={styles('value')}>{myPackage.included_legal_credits_count}</span>
              </div>
            </li>
            <li>
              <div className={styles('single')}>
                <span className={styles('description')}>{t(i18nKeys.ONLINE_PAYMENT)}</span>
                <span className={styles('value')}>
                  <Button
                    iconRight={IconName.PENCIL}
                    noMargin
                    color={ButtonColor.TRANSPARENT}
                    noShadow
                    small
                    onClick={goToDigitealIntegration}
                    label={t(
                      company.digiteal_integration.status === 'in_process'
                        ? i18nKeys.IN_PROGRESS
                        : company.digiteal_integration.status === 'activated'
                          ? i18nKeys.ACTIVATED
                          : i18nKeys.DEACTIVATED,
                    )}
                  />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </form>
  );
}
