import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsLineProps = {
  top?: boolean;
  bottom?: boolean;
};

export const WorkflowFormStepsLine = ({
  top = false,
  bottom = false,
  children,
}: PropsWithChildren<WorkflowFormStepsLineProps>) => (
  <div className={styles('line', top && 'top', bottom && 'bottom')}>
    <div>{children}</div>
  </div>
);
