import { useEffect } from 'react';
import { useGetRecoveryPlans } from 'api';
import {
  AssignableModel,
  AssignableTo,
  AutomaticAssignation,
  AvailableCondition,
  CreateAutomaticAssignationBody,
  DescVal,
} from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import FormSection from 'shared/forms/FormSection';
import { useLoadLightUsers } from 'shared/hooks';
import { Button, CustomSelect } from 'shared/io';
import { usersToDescVal } from 'shared/utils';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';

import { getInitialValues } from '../AutomaticAssignation.utils';

import AssignationConditionsForm from './AssignationConditionsForm';

import styleIdentifiers from './AutomaticAssignationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type AutomaticAssignationFormProps = {
  onSubmit: SubmitHandler<CreateAutomaticAssignationBody>;
  automaticAssignation?: AutomaticAssignation;
  close?: OnClickFunction;
  itemsTotal?: number;
  prefill?: {
    modelToAssign: AssignableModel | undefined;
    assignToType: AssignableTo | undefined;
  };
  availableConditions: AvailableCondition[];
};

export const AutomaticAssignationForm = ({
  prefill,
  onSubmit,
  automaticAssignation,
  close,
  itemsTotal,
  availableConditions,
}: AutomaticAssignationFormProps) => {
  const { t } = useTranslation();

  const { data: recoveryPlansResponse } = useGetRecoveryPlans();
  const recoveryPlans = (recoveryPlansResponse?.data || []).map((e) => ({
    description: e.attributes.name,
    value: e.id,
  })) as DescVal[];

  const { users: _users = [] } = useLoadLightUsers();
  const users = usersToDescVal(_users);

  const modelsToAssign = Object.values(AssignableModel).map((e) => ({
    description: t(i18nKeys.COMMON[e === AssignableModel.debtor ? 'CLIENT' : 'INVOICE']),
    value: e,
  })) as DescVal[];

  const assignableTo = Object.values(AssignableTo).map((e) => ({
    description: t(i18nKeys[e === AssignableTo.user ? 'ACCOUNT_MANAGER' : 'RECOVERY_PLAN']),
    value: e,
  })) as DescVal[];

  const defaultValues = {
    model_to_assign: prefill?.modelToAssign,
    assign_to_type: prefill?.assignToType,
    assign_to_id: '',
    assignation_conditions_attributes: [{ name: undefined, value: [] }],
    ...getInitialValues(automaticAssignation),
  };

  const form = useForm<CreateAutomaticAssignationBody>({
    shouldUnregister: true,
    defaultValues,
  });

  const {
    watch,
    formState: { isDirty },
    handleSubmit,
    setValue,
    control,
  } = form;

  const { fields, append, remove } = useFieldArray<CreateAutomaticAssignationBody>({
    control,
    name: 'assignation_conditions_attributes',
  });

  const values = watch('assignation_conditions_attributes');

  useEffect(() => {
    sideMenuSetAskBeforeClose(isDirty);
  }, [isDirty]);

  const items = watch('assign_to_type') === AssignableTo.recovery_plan ? recoveryPlans : users;

  const fieldName = t(
    i18nKeys[
      watch('assign_to_type') === AssignableTo.recovery_plan ? 'RECOVERY_PLAN' : 'ACCOUNT_MANAGER'
    ],
  );

  const priorities: DescVal[] = [];
  for (let i = 1; i <= (itemsTotal || 0); i += 1) {
    priorities.push({ description: i.toString(), value: i });
  }

  const removeCondition = (index: number) => {
    if (values[index]?.id) {
      setValue(
        `assignation_conditions_attributes.${index}._destroy`,
        true as never, // React hook form typescript fix
        {
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    } else {
      remove(index);
    }
  };

  return (
    <FormProvider {...form}>
      <form className={styles('automatic-assignation-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('head')}>
          {t(i18nKeys.FORM.AUTOMATIC_ASSIGNATION[automaticAssignation ? 'EDIT' : 'NEW'])}
          {close && (
            <div className={styles('close')} onClick={close}>
              <Icon name={IconName.SIMPLE_REMOVE} size="20px" />
            </div>
          )}
        </div>
        <div className={styles('content')}>
          <div className={styles('grid-row')}>
            <div className={styles('col-12')}>
              <Controller
                name="model_to_assign"
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name="model_to_assign"
                    label={t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.MODEL_TO_ASSIGN)}
                    keyValue="value"
                    keyText="description"
                    items={modelsToAssign}
                    selectClassName={styles('input')}
                    withBorder
                    notAllowed
                  />
                )}
              />
            </div>
            <div className={styles('col-12')}>
              <Controller
                name="assign_to_type"
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name="assign_to_type"
                    label={t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGN_TO)}
                    keyValue="value"
                    keyText="description"
                    items={assignableTo}
                    selectClassName={styles('input')}
                    withBorder
                    onChange={() => {
                      setValue('assign_to_id', '');
                    }}
                    notAllowed
                  />
                )}
              />
            </div>
            {typeof watch('assign_to_type') !== 'undefined' && watch('assign_to_type') !== null && (
              <div className={styles('col-12')}>
                <Controller
                  name="assign_to_id"
                  rules={{ required: true }}
                  render={() => (
                    <CustomSelect
                      name="assign_to_id"
                      label={fieldName}
                      keyValue="value"
                      keyText="description"
                      items={items}
                      selectClassName={styles('input')}
                      withBorder
                    />
                  )}
                />
              </div>
            )}
            {automaticAssignation && (
              <div className={styles('col-12')}>
                <Controller
                  name="order"
                  rules={{ required: true }}
                  render={() => (
                    <CustomSelect
                      name="order"
                      label={t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ORDER)}
                      keyValue="value"
                      keyText="description"
                      items={priorities}
                      selectClassName={styles('input')}
                      withBorder
                    />
                  )}
                />
              </div>
            )}
            <FormSection
              className={styles('assignation-condition-section')}
              title={t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGNATION_CONDITIONS)}
              onAdd={() =>
                append({
                  name: undefined,
                  value: [],
                  operator: undefined,
                })
              }
            >
              <AssignationConditionsForm
                availableConditions={availableConditions}
                formArray={fields}
                removeCondition={removeCondition}
              />
            </FormSection>
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button type="submit" label={t(i18nKeys.SAVE)} noShadow noMargin />
        </div>
      </form>
    </FormProvider>
  );
};
