import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import Card from 'shared/components/Card';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import { Debtor } from 'types/store/client-state';

import styleIdentifiers from './debtorAdditionalInfo.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  imported?: boolean;
  clientData: Debtor;
};

export default function DebtorAdditionalInfo({ imported, clientData }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const seeClient = (debtorId) => () => {
    dialogHide(DialogShowId.INVOICE);
    history.push(`/clients/${debtorId}`);
  };

  return (
    <Card
      title={t(i18nKeys.DETAILS)}
      className={styles('box', 'client-info', 'className')}
      actionHead={imported ? undefined : seeClient(clientData!.id)}
    >
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.EXTERNAL_ID)}</span>
        <div className={styles('value', 'medium')}>{clientData.csv_id}</div>
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.VAT_NUMBER)}</span>
        <div className={styles('value', 'medium')}>{clientData.vat_number}</div>
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.RECOVERY_PLAN)}</span>
        <div className={styles('value', 'medium')}>{clientData.recovery_plan?.name}</div>
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.DEBTOR.LOCALE)}</span>
        <div className={styles('value', 'medium')}>
          {clientData.locale
            ? AVAILABLE_LANGUAGES.find((lang) => lang.value === clientData.locale)!.description
            : 'N/A'}
        </div>
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.DEBTOR.CURRENCY)}</span>
        <div className={styles('value', 'medium')}>{clientData.currency}</div>
      </div>

      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.COMMON.BANK_ACCOUNT)}</span>
        <div className={styles('value', 'medium')}>
          {clientData.debtor_bank_accounts_attributes[0]?.iban}
        </div>
      </div>
    </Card>
  );
}
