import classNames from 'classnames/bind';
import { nice, ticks } from 'd3-array';
import { i18nKeys, useTranslation } from 'locales';
import { clone, first, mergeWith, subtract } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { BarChartTooltip } from 'shared/charts';
import Card from 'shared/components/Card';
import { AgedBalanceCompact, useAgedBalanceColors, useProfile } from 'shared/hooks';
import { formatAmount, useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';

import { Box, useMantineTheme } from '@mantine/core';
import { ResponsiveBar } from '@nivo/bar';

import styleIdentifiers from './balanceGraphic.module.scss';

const styles = classNames.bind(styleIdentifiers);

const DESIRED_X_AXIS_TICKS = 6;

interface BalanceGraphicProps {
  agedBalanceCompact: AgedBalanceCompact;
}

export function BalanceGraphic({ agedBalanceCompact }: BalanceGraphicProps) {
  const { push } = useHistory();

  const profile = useProfile();

  const { t } = useTranslation();
  const theme = useMantineTheme();

  const colors = useAgedBalanceColors();
  const formatter = useSafeLocalizedCompactCurrencyFormatter();

  const netBalance = mergeWith(
    clone(agedBalanceCompact.debits),
    agedBalanceCompact.credits,
    subtract,
  );

  const totalDue = netBalance[0] + netBalance[30] + netBalance[60] + netBalance[90];

  const graphLines = [
    {
      label: t(i18nKeys.ANALYTICS.AGED_BALANCE.notDue),
      query: 'max_days_late=0&status=unpaid,not_lost&',
      amount: Number(netBalance.notDue),
      key: 'notDue',
      color: colors.notDue,
    },
    {
      label: t(i18nKeys.ANALYTICS.AGED_BALANCE.totalDue),
      query: 'min_days_late=1&status=unpaid,not_lost&',
      amount: totalDue,
      key: 'totalDue',
      color: colors.totalDue,
    },
    {
      label: t(i18nKeys.DELAY.WITHIN_30),
      query: 'min_days_late=1&max_days_late=30&status=unpaid,not_lost&',
      amount: Number(netBalance['0']),
      key: 'within_30',
      color: colors[0],
    },
    {
      label: t(i18nKeys.DELAY.BETWEEN_30_AND_60),
      query: 'min_days_late=30&max_days_late=60&status=unpaid,not_lost&',
      amount: Number(netBalance['30']),
      key: 'between_30_and_60',
      color: colors[30],
    },
    {
      label: t(i18nKeys.DELAY.BETWEEN_60_AND_90),
      query: 'min_days_late=60&max_days_late=90&status=unpaid,not_lost&',
      amount: Number(netBalance['60']),
      key: 'between_60_and_90',
      color: colors[60],
    },
    {
      label: t(i18nKeys.DELAY.ABOVE_90),
      query: 'min_days_late=90&status=unpaid,not_lost&',
      amount: Number(netBalance['90']),
      key: 'above_90',
      color: colors[90],
    },
  ].reverse();

  const maxValue = Math.max(...graphLines.map((bar) => bar.amount));
  const minValue = Math.min(...graphLines.map((bar) => bar.amount));

  const [rangeStart, rangeEnd] = nice(minValue, maxValue, DESIRED_X_AXIS_TICKS);
  const xValues = ticks(rangeStart, rangeEnd, DESIRED_X_AXIS_TICKS);

  return (
    <Card
      className={styles('balance-graphic')}
      noPadding
      title={t(i18nKeys.DASHBOARD.DELAY_GRAPHIC.TITLE)}
    >
      <Box w="100%" h="350px">
        <ResponsiveBar
          onClick={(d) => push(`/invoices/listing?${d.data.query}`)}
          onMouseEnter={(_datum, event) => {
            event.currentTarget.style.cursor = 'pointer';
          }}
          colors={(d) => d.data.color}
          margin={{ bottom: 35, top: 10, left: profile.locale === 'nl' ? 120 : 100, right: 95 }}
          layout="horizontal"
          data={graphLines}
          indexBy="label"
          keys={['amount']}
          padding={0.45}
          isInteractive
          colorBy="indexValue"
          label={(d) => formatAmount(d.value, '.', ' ')}
          enableGridX
          enableGridY={false}
          labelSkipWidth={80}
          labelTextColor={theme.colors.gray[0]}
          tooltip={({ data: { amount } }) => {
            // Fix for incorrect initial tooltip position, see https://github.com/plouc/nivo/issues/2161
            setTimeout(() => {
              const tooltip = document.getElementById('tooltip-fix');
              if (tooltip) tooltip.style.opacity = '1';
            }, 50);

            return (
              <BarChartTooltip
                id="tooltip-fix"
                style={{ opacity: 0, transition: 'all 0.1s ease-in-out' }}
              >
                {formatAmount(amount, '.', ' ')}
              </BarChartTooltip>
            );
          }}
          axisBottom={{
            tickSize: 0,
            tickValues: xValues,
            format: (val) => formatter.format(val).replace(/(\.[0]+|,[0]+)$/, ''),
          }}
          axisLeft={{
            tickPadding: xValues[0] < 0 ? 25 : 15,
            tickSize: 0,
          }}
          animate={false}
          theme={{
            grid: {
              line: {
                stroke: theme.colors.gray[3],
                strokeDasharray: 7,
                strokeDashoffset: 15,
              },
            },
            labels: {
              text: {
                fontSize: 12,
                fontFamily: 'Work Sans',
                fill: theme.colors.gray[6],
              },
            },
            text: {
              fontSize: 10,
              fontFamily: 'Work Sans',
              fill: theme.colors.gray[6],
            },
          }}
          gridXValues={xValues}
          layers={[
            'grid',
            'axes',
            'bars',
            ({ bars, innerWidth }) => {
              const [, secondBar, thirdBar] = [...bars].reverse();
              const secondBarEndPositionY = secondBar.y + secondBar.height;
              const halfwayPointY = (secondBarEndPositionY + thirdBar.y) / 2;

              return (
                <line
                  x1={-65}
                  y1={halfwayPointY}
                  x2={innerWidth + 65}
                  y2={halfwayPointY}
                  stroke="#CED4DA"
                  strokeWidth="1.5"
                  strokeDasharray={7}
                />
              );
            },
            // See https://github.com/plouc/nivo/issues/146#issuecomment-1009184119
            ({ bars, labelSkipWidth }) => {
              const negativeBars = bars.filter((bar) => (bar.data.value ?? 0) < 0);
              const sortedNegativeBars = [...negativeBars].sort(
                (a, b) => (a.data.value ?? 0) - (b.data.value ?? 0),
              );
              const yAxisNegativeValuePadding = first(sortedNegativeBars)?.width ?? 0;

              return (
                <g>
                  {bars.map(({ width, height, y, data: barData }) =>
                    width < labelSkipWidth ? (
                      <text
                        key={barData.data.key}
                        fontSize={12}
                        fontFamily="Work Sans"
                        fill={theme.colors.gray[6]}
                        transform={`translate(${width + 10 + yAxisNegativeValuePadding}, ${
                          y + height / 2
                        })`}
                        textAnchor="left"
                        dominantBaseline="central"
                      >
                        {formatAmount(barData.value, '.', ' ')}
                      </text>
                    ) : null,
                  )}
                </g>
              );
            },
          ]}
        />
      </Box>
    </Card>
  );
}
