import { PlanType, StepType } from 'api/models';

import { WorkflowStepsTarget } from '../Workflows.constants';

export type TemplateConditions = {
  value: StepType;
  single?: boolean;
  first?: boolean;
  last?: boolean;
};

export const TEMPLATES = {
  [PlanType.payment]: {
    [WorkflowStepsTarget.BEFORE_DUE_DATE]: [
      {
        value: StepType.preventive_reminder,
        single: true,
        first: true,
        last: true,
      },
    ] as TemplateConditions[],
    [WorkflowStepsTarget.AFTER_DUE_DATE]: [
      {
        value: StepType.first_reminder,
        single: true,
        first: true,
      },
      {
        value: StepType.intermediate_reminder,
      },
      {
        value: StepType.last_reminder,
        single: true,
        last: true,
      },
    ] as TemplateConditions[],
  },
};
