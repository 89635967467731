import { useEffect, useLayoutEffect, useState } from 'react';
import { useGetTemplates } from 'api';
import { GetTemplatesParams, PlanType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit, pick } from 'lodash-es';
import CustomTable from 'shared/components/CustomTable';
import { useProfile } from 'shared/hooks';

import TemplateListItem from './TemplateListItem';

import styleIdentifiers from './TemplateList.module.scss';

const styles = classNames.bind(styleIdentifiers);

const Table = CustomTable(TemplateListItem);

type Props = {
  plan_type: PlanType;
  filters: GetTemplatesParams;
};

export default function TemplateList({ plan_type, filters }: Props) {
  const { t } = useTranslation();

  const profile = useProfile();

  const [params, setParams] = useState<GetTemplatesParams>({
    plan_type,
    page_limit: profile.preferences.itemsPerPage || 20,
    page: 1,
  });

  useLayoutEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<GetTemplatesParams>(Object.fromEntries(new URLSearchParams(location.search)), [
        'page',
        'page_limit',
        'sort_by',
        'sort_order',
        'name',
        'step_type',
      ]),
    }));
  }, []);

  useEffect(() => {
    setParams((currentParams) => ({
      ...omit(currentParams, ['name', 'step_type']),
      ...filters,
      page: 1,
    }));
  }, [filters]);

  const { data: templatesListResponse, isLoading, isFetched, refetch } = useGetTemplates(params);

  const onSortChange = (newParams: GetTemplatesParams) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['sort_by', 'sort_order']),
    }));
  };

  const handleFilters = (newParams: GetTemplatesParams) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['page_limit', 'page']),
    }));
  };

  const headers = [
    {
      title: t(i18nKeys.NAME),
      key: 'name',
    },
    {
      title: t(i18nKeys.FORM.TYPE),
      key: 'step_type',
    },
    {
      title: t(i18nKeys.UPDATE_DATE),
      key: 'updated_at',
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  return (
    <Table
      headers={headers}
      className={styles('templates-list')}
      items={templatesListResponse?.data || []}
      pagination={templatesListResponse?.metadata.pagination}
      loading={isLoading}
      loaded={isFetched}
      onSortChange={onSortChange}
      handleFilters={handleFilters}
      itemProps={{ refetch }}
      noCheckbox
      noShadow
    />
  );
}
