import { useState } from 'react';
import { useGetDebtorsInfinite } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { concat, flat, map, pipe, prop, uniqueBy } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';

import styleIdentifiers from './ExternalMailAction.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailFormDebtorProps = {
  fieldName?: string;
};

export const ExternalMailActionDebtor = ({
  fieldName = 'debtor_id',
}: ExternalMailFormDebtorProps) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const [selectSearchValue, setSelectSearchValue] = useState<string>();
  const { data: debtorsResponse, fetchNextPage } = useGetDebtorsInfinite(
    { name: selectSearchValue },
    {
      query: {
        getNextPageParam,
      },
    },
  );

  const pages = debtorsResponse?.pages ?? [];
  const selectedDebtor = form.getValues().debtor;
  const selectedDebtorArray = selectedDebtor
    ? [{ id: selectedDebtor.id, full_name: selectedDebtor.attributes.full_name }]
    : [];

  const items = pipe(
    pages,
    map((e) => e.data),
    flat(),
    map(treatClient),
    concat(selectedDebtorArray), // See (1)
    uniqueBy(prop('id')),
  );

  return (
    <Controller
      name={fieldName}
      render={() => (
        <CustomSelect
          selectClassName={styles('input')}
          filter
          onChangeFilter={setSelectSearchValue}
          withBorder
          noMargin
          infiniteScroll
          items={items}
          keyValue="id"
          keyText="full_name"
          name={fieldName}
          label={t(i18nKeys.TASK.ATTRIBUTES.DEBTOR)}
          load={fetchNextPage}
        />
      )}
    />
  );
};

/**
 * (1) The CustomSelect only knows the id of the currently selected debtor. To display its
 * name correctly in the field, it tries to match that id to the objects it has been given
 * as options for the dropdown. This does not work well for paginated data, because we may
 * not have loaded the full object yet. Since the surrounding context knows the full object,
 * we add it to the list of options systematically so that the field may feed from it.
 */
