import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { InvoiceActionsDropdown } from 'shared/components';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import { Button, ButtonColor } from 'shared/io';
import { reloadCustomView } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Invoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import { captureMessage } from '@sentry/react';

import BatchActions from '../Listing/BatchActions';
import ToHandleDetail from '../ToHandleDetail';

import styleIdentifiers from './toHandle.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function InvoicesToHandle() {
  const { t } = useTranslation();
  const invoicesData = useSelector((state: StoreState) => state.invoice.toHandle);
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const [areAllItemsAcrossPagesSelected, setAreAllItemsAcrossPagesSelected] = useState(false);
  const [topElement, setTopElement] = useState('0');

  const showInvoice = (item) => () => {
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail id={item.id} modal />,
    });
  };

  const reloadData = () => {
    reloadCustomView(invoiceActions.toHandleRes);
  };

  const actionsCol = (item: unknown) => {
    if (item && typeof item === 'object' && 'actions' in item) {
      return (
        <InvoiceActionsDropdown
          shouldDeferRequest
          reload={reloadData}
          className={styles('action')}
          topElement={topElement}
          invoice={item as Invoice}
          item={
            <Button
              onClick={(e) => {
                const elPosition = (e.target as HTMLElement).getBoundingClientRect();
                setTopElement(`${elPosition.y - elPosition.height - 30}px`);
              }}
              label={t(i18nKeys.PROCESS)}
              color={ButtonColor.BLUE}
              className={styles('button')}
              noMargin
              noShadow
            />
          }
        />
      );
    }

    captureMessage(
      "Tried to pass an `invoice` prop to InvoiceActionsDropdown that wasn't an object, or didn't respond to the `actions` field.",
      { extra: { item } },
    );

    return null;
  };

  const openBatchActionsModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.FORM.ACTIONS),
      children: (
        <BatchActions
          selectedInvoices={selectedInvoices}
          isSelectedAll={areAllItemsAcrossPagesSelected}
          reload={reloadData}
        />
      ),
    });
  };

  return (
    <div className={styles('InvoicesToHandle')}>
      <CustomTableViews
        title={t(i18nKeys.INVOICE_TO_PROCESS)}
        tableName="actionable_invoices"
        onClickRow={showInvoice}
        callbackAction={invoiceActions.toHandleRes}
        actions={
          selectedInvoices.length > 0 && (
            <Button noMargin label={t(i18nKeys.FORM.ACTIONS)} onClick={openBatchActionsModal} />
          )
        }
        isLoading={!invoicesData.loaded}
        pagination={invoicesData.metadata?.pagination}
        items={invoicesData.pages}
        currentPage={invoicesData.currentPage}
        onChangeSelectionCallback={(values) => setSelectedInvoices(values as Array<Invoice>)}
        onChangeSelectItemsAcrossAllPagesCallback={setAreAllItemsAcrossPagesSelected}
        noResultMessage={t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.NO_INVOICE_WITH_DELAY)}
        actionsCol={actionsCol}
      />
    </div>
  );
}
