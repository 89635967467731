import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import ProductForm from 'shared/forms/ProductForm';
import { productActions } from 'store/product/product.actions';
import {
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import { captureMessage } from '@sentry/react';

import styleIdentifiers from './productListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function ProductListItem(props) {
  const { item, noCheckbox, action } = props;
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);

  const submitProduct = (values: any) => {
    productActions.update({
      id: values.id,
      data: values,
      callback: () => {
        sideMenuHide();
      },
    });
  };

  const updateProduct = () => {
    sideMenuShow({
      unmount: true,
      content: <ProductForm onSubmit={submitProduct} initialValues={item} />,
    });
  };

  const handleDelete = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(i18nKeys.INVOICING.PRODUCT.AYS_TO_DELETE)}
          onConfirm={() => productActions.delete({ id: item.id })}
        />
      ),
    });
  };

  // Sentry 15x
  const taskCategory = constants.tax_categories.find(
    (tax_category) => tax_category.value === item.tax_category_code,
  );

  if (taskCategory == null) {
    captureMessage('In ProductListItem, taskCategory is null and will be hidden for this field.', {
      extra: { item, constants },
    });
  }

  return (
    <tr className={styles('ProductListItem')} key={item.id}>
      <td>
        {!noCheckbox && (
          <div onClick={action} className={styles('checkbox', noCheckbox && 'small')}>
            <div className={styles('box', item.checked && 'checked')} />
          </div>
        )}
      </td>
      <td>{item.id}</td>
      <td className={styles('name')}>{item.name}</td>
      <td className={styles('description')}>{item.description}</td>
      <td className={styles('price')}>
        <Amount value={item.price_htva} />
      </td>
      <td className={styles('tva')}>{taskCategory?.description ?? ''}</td>
      <td className={styles('actions')}>
        <span onClick={updateProduct}>
          <Icon name={IconName.SETTINGS_GEAR} size="18px" className={styles('action')} />
        </span>
        <span onClick={handleDelete}>
          <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
        </span>
      </td>
    </tr>
  );
}
