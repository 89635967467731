/**
 * It takes a key and returns the value of the query parameter with that key
 * @param {string} key - The key of the query parameter you want to get.
 * @returns A string or undefined.
 */
export const getQueryParams = <T extends string>(key: string): T | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);
  return value ? (value as T) : undefined;
};

export const setQueryParams = (key, value) => {
  const searchParams = new URLSearchParams(window.location.search);

  value === undefined ? searchParams.delete(key) : searchParams.set(key, value);
  const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
  history.pushState(null, '', newRelativePathQuery);
};
