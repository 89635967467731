import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { toLowerCase } from 'remeda';
import { useLoadAgedBalanceCompact, useRefreshBalance } from 'shared/hooks';

import { ActionIcon, Group, Text, Tooltip } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';

export const UpdatedAt = () => {
  const { t } = useTranslation();
  const { agedBalanceCompact } = useLoadAgedBalanceCompact();
  const { refreshBalance } = useRefreshBalance();

  if (agedBalanceCompact == null) return null;

  return (
    <Group>
      <Text size="sm" c="gray.5" fs="italic">
        {t(i18nKeys.ANALYTICS.LAST_UPDATED)}
        {toLowerCase(dayjs(agedBalanceCompact.computedAt).calendar().toLowerCase())}
      </Text>
      <Tooltip withArrow label={t(i18nKeys.ANALYTICS.REFRESH_BALANCE)} color="gray.6" arrowSize={7}>
        <ActionIcon onClick={() => refreshBalance()} color="gray.5" size="sm" variant="light">
          <IconRefresh />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};
