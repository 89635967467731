import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useLegacyAxiosInstance } from './utils';

const ExternalMailboxDataSchema = z.object({
  id: z.string(),
  attributes: z.object({
    host: z.string(),
    port: z.number(),
    ssl: z.boolean(),
    login: z.string(),
    is_microsoft: z.boolean().nullable(),
    signature: z.string().nullable(),
  }),
});

const ExternalMailboxMetadataSchema = z.object({
  pagination: z.object({
    current_page: z.number(),
    last_page: z.number(),
    page_limit: z.number(),
    total_objects: z.number(),
  }),
});

const ExternalMailboxConfigurationSchema = z.object({
  data: z.array(ExternalMailboxDataSchema),
  metadata: ExternalMailboxMetadataSchema,
});

export type ExternalMailboxes = z.infer<typeof ExternalMailboxConfigurationSchema>;
export type ExternalMailboxConfiguration = z.infer<typeof ExternalMailboxDataSchema>;

export async function loadExternalMailboxesFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/external_mailbox_configurations');

  return ExternalMailboxConfigurationSchema.parse(data);
}

export const useLoadExternalMailboxes = () => {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<ExternalMailboxes, ApiError>({
    queryKey: ['externalMailboxes'],
    queryFn: () => loadExternalMailboxesFn(axiosInstance),
  });

  return addResourceNameToQueryResult<ExternalMailboxes, ApiError, 'externalMailboxes'>(
    'externalMailboxes',
    queryResult,
  );
};
