import { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { upperCase } from 'lodash-es';
import { useHistory } from 'react-router';
import { isEmpty } from 'remeda';
import { Reports, useCreateReport, useLoadReports, useLoadViews } from 'shared/hooks';
import { PageTitle } from 'shared/layout';

import { css } from '@emotion/css';
import {
  Button,
  Card,
  Center,
  Divider,
  Group,
  Loader,
  Paper,
  Select,
  SimpleGrid,
  Stack,
  Stepper,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import {
  IconArrowRight,
  IconCalendarClock,
  IconFileAlert,
  IconFileDollar,
  IconInvoice,
  IconListCheck,
  IconReport,
  IconUsers,
} from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import { NameStep, PeriodicityStep, RecipientsStep } from './steps';

// TODO: extract remaining steps

const styles = {
  resourceButton: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: var(--mantine-radius-md);
    height: rem(90px);
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
    transition:
      box-shadow 200ms ease,
      transform 200ms ease,
      border-color 200ms ease;

    :hover {
      box-shadow: var(--mantine-shadow-sm);
      transform: scale(1.03);
    }
  `,
  resourceButtonText: css`
    transition: color 200ms ease;
  `,
};

const iconStyles = { size: 32, stroke: 1.5, color: 'var(--mantine-color-blue-6)' };

export const RESOURCES = [
  { slug: 'debtors', name: 'DEBTORS', icon: <IconUsers {...iconStyles} /> },
  { slug: 'invoices', name: 'INVOICES', icon: <IconFileDollar {...iconStyles} /> },
  {
    slug: 'postponable_invoices',
    name: 'REMINDERS',
    icon: <IconCalendarClock {...iconStyles} />,
  },
  { slug: 'credit_notes', name: 'CREDIT_NOTES', icon: <IconInvoice {...iconStyles} /> },
  { slug: 'tasks', name: 'TASKS', icon: <IconListCheck {...iconStyles} /> },
  {
    slug: 'actionable_invoices',
    name: 'ACTIONABLE_INVOICES',
    icon: <IconFileAlert {...iconStyles} />,
  },
] as const;

export const CreateReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const client = useQueryClient();
  const { createReport, isCreateReportLoading } = useCreateReport();

  const persisted = JSON.parse(localStorage.getItem('persist-report-creation') ?? 'null');
  const [step, setStep] = useState(persisted?.step ?? 0);
  const [highestStepVisited, setHighestStepVisited] = useState(persisted?.highestStepVisited ?? 0);
  const [subtitles, setSubtitles] = useState(persisted?.subtitles ?? { 0: '', 1: '', 2: '' });

  const setSubtitle = (index: number, subtitle: string) => {
    setSubtitles((state) => ({ ...state, [index]: subtitle }));
  };

  // it's only here to guarantee that the data will be in cache for the form validation function below
  // eslint-disable-next-line
  const {} = useLoadReports();

  useEffect(() => {
    if (step > highestStepVisited) setHighestStepVisited(step);
  }, [step]);

  // TODO: extract the form and expose the validation
  const form = useForm({
    validateInputOnChange: true,
    initialValues: persisted?.form ?? {
      name: '',
      viewId: null as string | null,
      resourceType: 'debtors' as (typeof RESOURCES)[number]['slug'],
      periodicity: 'weekly' as 'weekly' | 'monthly',
      daysWeek: [] as Array<number>,
      daysMonth: [] as Array<Date>,
      // TODO: rename collaboratorEmails, externalEmails
      collaborators: [] as Array<string>,
      emails: [] as Array<string>,
    },
    validate: {
      name: (value) => {
        const exportTasks: Reports = client.getQueryData(['reports']) ?? [];
        const exportTasksNames = exportTasks.map((task) => task.name);

        if (exportTasksNames.includes(value))
          return t(i18nKeys.REPORTS.NEW.STEPS.NAME.DUPLICATED_NAME);
        return null;
      },
      emails: (value) => {
        let error: null | string = null;

        value.forEach((email) => {
          if (isEmail(true)(email))
            error = `${email} ${t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.INVALID_EMAIL)}`;
        });

        return error;
      },
    },
  });

  useEffect(() => {
    localStorage.setItem(
      'persist-report-creation',
      JSON.stringify({
        form: form.values,
        subtitles,
        highestStepVisited,
        step,
      }),
    );
  }, [JSON.stringify(form.values), subtitles, highestStepVisited, step]);

  const { views, isViewsFetching } = useLoadViews({ resourceType: form.values.resourceType });

  const viewsForSelect = (views ?? []).map((view) => ({ value: view.id, label: view.name }));

  useEffect(() => {
    // The extra logic below is to ensure that when we don't override persisted state when the effect runs
    if (isViewsFetching) return;
    if (viewsForSelect.map((v) => v.value).includes(persisted.form.viewId))
      form.setFieldValue('viewId', persisted.form.viewId);
    else form.setFieldValue('viewId', viewsForSelect[0]?.value);
  }, [isViewsFetching]);

  // TODO: the content of form.values is untyped!
  // TODO: Write a DtO to centralize this code which is replicated in the edit modals
  const handleCreateReport = () => {
    createReport(
      {
        viewId: form.values.viewId!,
        name: form.values.name,
        emails: [...form.values.emails, ...form.values.collaborators],
        periodicity: {
          type: form.values.periodicity,
          days:
            form.values.periodicity === 'weekly'
              ? form.values.daysWeek
              : // todo: should all be dayjs
                form.values.daysMonth.map((date) => new Date(date).getDate()),
        },
      },
      {
        onSuccess: () => {
          history.push('/reports');
          localStorage.removeItem('persist-report-creation');
        },
      },
    );
  };

  const wasStepVisited = (index: number) => index <= highestStepVisited;

  return (
    <Stack>
      <PageTitle>
        {t(i18nKeys.REPORTS.NEW.TITLE)}
        <PageTitle.Actions>
          <Button
            onClick={() => {
              history.push('/reports');
              localStorage.removeItem('persist-report-creation');
            }}
            color="orange"
            variant="light"
          >
            {t(i18nKeys.REPORTS.NEW.CANCEL)}
          </Button>
        </PageTitle.Actions>
      </PageTitle>
      <Card flex={1} radius="md" shadow="sm">
        <Stepper
          active={step}
          onStepClick={(newStep) => {
            setStep(newStep);
            setSubtitle(0, form.values.name);
            if (wasStepVisited(1)) {
              setSubtitle(
                1,
                viewsForSelect.find((view) => view.value === form.values.viewId)?.label!,
              );
            }
            if (wasStepVisited(2))
              setSubtitle(2, t(i18nKeys.DATES.PERIOD_NAMES[upperCase(form.values.periodicity)]));
          }}
        >
          <Stepper.Step
            label={t(i18nKeys.REPORTS.NEW.STEP_LABELS.NAME)}
            description={subtitles[0]}
            allowStepSelect
          >
            <Center my="xl">
              <Stack miw="33%">
                <NameStep formProps={form.getInputProps('name')} />
                <Button
                  disabled={!form.values.name || form.errors.name != null}
                  w="100%"
                  rightSection={<IconArrowRight />}
                  onClick={() => {
                    setSubtitle(0, form.values.name);
                    setStep(1);
                  }}
                >
                  {t(i18nKeys.REPORTS.NEW.STEPS.ADVANCE)}
                </Button>
              </Stack>
            </Center>
          </Stepper.Step>
          <Stepper.Step
            label={t(i18nKeys.REPORTS.NEW.STEP_LABELS.DATA)}
            allowStepSelect={
              (highestStepVisited >= 1 && form.values.name !== '') || form.errors.name != null
            }
            description={subtitles[1]}
          >
            <Group my="xl" justify="center" align="start">
              <Stack w="33%">
                <Text size="lg" fw={450}>
                  {t(i18nKeys.REPORTS.NEW.STEPS.DATA.RESOURCE_TYPE)}
                </Text>
                <Paper p="xl" bg="gray.1" shadow="none">
                  <SimpleGrid cols={2}>
                    {RESOURCES.map((resource) => {
                      const isSelected = form.values.resourceType === resource.slug;
                      return (
                        <UnstyledButton
                          onClick={() => form.setFieldValue('resourceType', resource.slug)}
                          style={{
                            border: isSelected
                              ? '2px solid var(--mantine-color-blue-4)'
                              : '2px solid transparent',
                          }}
                          key={resource.slug}
                          p="sm"
                          className={styles.resourceButton}
                        >
                          {resource.icon}
                          <Text
                            mt="xs"
                            c={isSelected ? 'blue.6' : 'gray.8'}
                            fw={isSelected ? 500 : 400}
                            className={styles.resourceButtonText}
                          >
                            {t(i18nKeys.REPORTS.NEW.STEPS.DATA.RESOURCE_TYPES[resource.name])}
                          </Text>
                        </UnstyledButton>
                      );
                    })}
                  </SimpleGrid>
                </Paper>
              </Stack>
              <Divider orientation="vertical" mx="lg" />
              <Stack w="33%">
                <Text size="lg" fw={450}>
                  {t(i18nKeys.REPORTS.NEW.STEPS.DATA.VIEW)}
                </Text>
                <Group pos="relative">
                  <Select
                    {...form.getInputProps('viewId')}
                    w="100%"
                    disabled={isEmpty(viewsForSelect)}
                    checkIconPosition="right"
                    data={viewsForSelect}
                    searchable
                    allowDeselect={false}
                    placeholder="Select a view to export"
                  />
                  {isViewsFetching ? (
                    <Center
                      style={{ borderRadius: 'var(--mantine-radius-sm)' }}
                      pos="absolute"
                      w="100%"
                      h="100%"
                      bg="rgba(225, 225, 225, 0.9)"
                    >
                      <Loader size={25} />
                    </Center>
                  ) : null}
                </Group>
                <Text c="dimmed">{t(i18nKeys.REPORTS.NEW.STEPS.DATA.VIEW_SUBTITLE)}</Text>
                <Button
                  w="100%"
                  rightSection={<IconArrowRight />}
                  onClick={() => {
                    setSubtitle(
                      1,
                      viewsForSelect.find((view) => view.value === form.values.viewId)?.label!,
                    );
                    setStep(2);
                  }}
                >
                  {t(i18nKeys.REPORTS.NEW.STEPS.ADVANCE)}
                </Button>
              </Stack>
            </Group>
          </Stepper.Step>
          <Stepper.Step
            label={t(i18nKeys.REPORTS.NEW.STEP_LABELS.RECURRENCE)}
            allowStepSelect={highestStepVisited >= 2}
            description={subtitles[2]}
          >
            <Center my="xl">
              <Stack>
                <PeriodicityStep form={form} />
                <Button
                  disabled={isEmpty(form.values.daysMonth) && isEmpty(form.values.daysWeek)}
                  w="100%"
                  rightSection={<IconArrowRight />}
                  onClick={() => {
                    setSubtitle(
                      2,
                      t(i18nKeys.DATES.PERIOD_NAMES[upperCase(form.values.periodicity)]),
                    );
                    setStep(3);
                  }}
                >
                  {t(i18nKeys.REPORTS.NEW.STEPS.ADVANCE)}
                </Button>
              </Stack>
            </Center>
          </Stepper.Step>
          <Stepper.Step
            label={t(i18nKeys.REPORTS.NEW.STEP_LABELS.RECIPIENTS)}
            allowStepSelect={
              highestStepVisited >= 3 &&
              !(isEmpty(form.values.daysMonth) && isEmpty(form.values.daysWeek))
            }
          >
            {/* todo: wrap all steps the same */}
            <Center my="xl">
              <Stack w="33%" gap="lg">
                <RecipientsStep form={form} />
                <Button
                  loading={isCreateReportLoading}
                  disabled={
                    (isEmpty(form.values.collaborators) && isEmpty(form.values.emails)) ||
                    form.errors.emails != null
                  }
                  w="100%"
                  mt="xl"
                  rightSection={<IconReport stroke={1.5} />}
                  onClick={handleCreateReport}
                >
                  {t(i18nKeys.REPORTS.NEW.STEPS.CREATE)}
                </Button>
              </Stack>
            </Center>
          </Stepper.Step>
        </Stepper>
      </Card>
    </Stack>
  );
};
