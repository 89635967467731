import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import AddressBlock from 'shared/components/AddressBlock';
import Amount from 'shared/components/Amount';
import AYSModal from 'shared/components/AYSModal';
import BankAccount from 'shared/components/BankAccount';
import DateItem from 'shared/components/DateItem';
import DownloadInvoice from 'shared/components/DownloadInvoice';
import GradientBorder from 'shared/components/GradientBorder';
import HTML from 'shared/components/HTML/HTML';
import { IconName } from 'shared/components/Icon';
import InvoiceFooter from 'shared/components/InvoiceFooter';
import TvaNumber from 'shared/components/TvaNumber';
import EncodePaymentForm from 'shared/forms/EncodePaymentForm';
import SendInvoiceForm from 'shared/forms/SendInvoiceForm';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { currencySymbol, formatAmount, formatCommunication } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, showDialog, sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import LinkDocument from '../LinkDocument';

import TableDetails from './TableDetails';

import styleIdentifiers from './detail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  credit?: boolean;
  imported?: boolean;
};

// NB: This is essentially the overview of a draft invoice before validation

export default function Detail({ credit, imported }: Props) {
  const { t, currentLang } = useTranslation();
  const company = useSelector((state: StoreState) => state.account.company.data!);
  const invoice = useSelector((state: StoreState) => state.invoice.current?.data);
  const creditNote = useSelector((state: StoreState) => state.creditNote.current?.data);
  const { params } = useRouteMatch<{ id: any }>();
  const [pdf, setPdf] = useState();
  const history = useHistory();

  const data = credit ? creditNote : invoice;

  useEffect(() => {
    if (credit) creditNoteActions.detail({ id: params.id });
    else invoiceActions.detail({ id: params.id });
  }, []);

  useEffect(() => {
    if (data?.imported) {
      invoiceActions.document({
        id: params.id,
        document_type: 'invoice_pdf',
        callback: (doc) => {
          setPdf(doc);
        },
      });
    }
  }, [data?.imported]);

  const nextRef = useMemo(() => {
    const billingLog = data?.billing_log_attributes;
    return billingLog != null
      ? `${billingLog.prefix}-${billingLog.counter + 1} (${t('INVOICE.DRAFT')})`
      : '';
  }, [data]);

  // Only for invoice
  const submitPayment = (currency) => (values: Object) => {
    if (!data) return;

    invoiceActions.actionsAddPayment({
      id: data.id,
      data: {
        currency,
        ...values,
      },
      callback: () => {
        invoiceActions.detail({
          id: data.id,
          noReset: true,
          noLoading: true,
        });
        sideMenuHide();
      },
    });
  };

  // Only for invoice
  const addPayment = () => {
    sideMenuShow({
      content: (
        <EncodePaymentForm
          onSubmit={submitPayment(data?.currency!)}
          currency={data?.currency!}
          totalAmount={data?.total_tvac!}
        />
      ),
    });
  };

  const linkDocument = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      title: t(i18nKeys.INVOICE.JOIN_FILE),
      children: <LinkDocument invoice={data!} />,
    });
  };

  const deleteItem = () => {
    if (!data) return;

    sideMenuHide();

    showDialog({
      id: DialogShowId.CONFIRM,
      title: t(i18nKeys.ATTENTION),
      children: (
        <AYSModal
          text={t(credit ? 'INVOICE.AYS_TO_DELETE_CREDIT_NOTE' : 'AYS.INVOICE_ACTION_DELETE')}
          onConfirm={() => {
            (credit ? creditNoteActions : invoiceActions).delete({
              id: data.id,
              callback: () => {
                history.push(credit ? '/credit-notes/listing' : '/invoices/listing');
              },
            });
          }}
        />
      ),
    });
  };

  const sendInvoice = (values: any) => {
    if (!data) return;

    if (credit) {
      creditNoteActions.send({
        id: data.id,
        data: values,
        callback: () => {
          sideMenuHide();
          history.push('/credit-notes/listing');
        },
      });
    } else {
      invoiceActions.send({
        id: data.id,
        data: values,
        callback: () => sideMenuHide(),
      });
    }
  };

  const validate = () => {
    const entity = credit ? creditNote : invoice;

    if (!entity) return;

    sideMenuShow({
      unmount: true,
      content: <SendInvoiceForm onSubmit={sendInvoice} data={entity} credit={credit} />,
    });
  };

  const goToEditPage = () => {
    sideMenuHide();
    history.push(`/${credit ? 'credit-notes' : 'invoices'}/edit/${data!.id}`);
  };

  const customer = data?.debtor_attributes;
  const shouldHideVat = customer?.intracom || customer?.co_contractor;
  const remaining = (() => {
    if (data == null) return 0;
    if (data.remaining_balance !== 0) return data.remaining_balance;
    return data.total_tvac - data.already_paid_amount;
  })();

  return (
    <div className={styles('Detail')}>
      {data && (
        <>
          <div className={styles('head')}>
            <div className={styles('left')}>
              <DownloadInvoice text invoice={data} />
            </div>
            {!imported && (
              <>
                <Dropdown
                  sideMenuInMobile
                  sideMenuTitle={t(i18nKeys.FORM.ACTIONS)}
                  toggleContent
                  className={styles('actions-button')}
                  selectorContent={
                    <Button noMargin iconRight={IconName.ARROW_BOTTOM_ROUNDED}>
                      {t(i18nKeys.FORM.ACTIONS)}
                    </Button>
                  }
                >
                  {data.actions.includes('send_to_debtor') && (
                    <div className="dropdown-item" onClick={validate}>
                      <div>
                        {t(
                          credit
                            ? i18nKeys.INVOICING.SEND_CREDIT_NOTE
                            : i18nKeys.INVOICING.VALIDATE_INVOICE,
                        )}
                      </div>
                    </div>
                  )}
                  {data.actions.includes('add_payment') && (
                    <div className="dropdown-item" onClick={addPayment}>
                      <div>{t(i18nKeys.INVOICE.ADD_PAYMENT)}</div>
                    </div>
                  )}
                  {!data.paid && !credit && (
                    <div className="dropdown-item" onClick={linkDocument}>
                      <div>
                        {t(i18nKeys.INVOICE.JOIN_FILE)}
                        <div className={styles('text-circle', 'border', 'grey', 'ml-10')}>
                          {data.additional_files_attributes.length}
                        </div>
                      </div>
                    </div>
                  )}
                  {data.actions.includes('edit') && (
                    <div className="dropdown-item" onClick={goToEditPage}>
                      {t(i18nKeys.EDIT)}
                    </div>
                  )}
                  {data.actions.includes('delete') && (
                    <div className="dropdown-item" onClick={deleteItem}>
                      <div>{t(i18nKeys.DELETE)}</div>
                    </div>
                  )}
                </Dropdown>
                <div className={styles('actions')}>
                  {data.actions.includes('delete') && (
                    <Button
                      noMargin
                      label={t(i18nKeys.DELETE)}
                      color={ButtonColor.RED}
                      onClick={deleteItem}
                    />
                  )}
                  {data.actions.includes('edit') && (
                    <NavLink to={`/${credit ? 'credit-notes' : 'invoices'}/edit/${data.id}`}>
                      <Button noMargin label={t(i18nKeys.EDIT)} color={ButtonColor.GREY} />
                    </NavLink>
                  )}
                  {data.actions.includes('add_payment') && (
                    <Button
                      noMargin
                      label={t(i18nKeys.INVOICE.ADD_PAYMENT)}
                      color={ButtonColor.GREY}
                      onClick={addPayment}
                    />
                  )}
                  {!data.paid && !credit && (
                    <Button noMargin color={ButtonColor.GREY} onClick={linkDocument}>
                      {t(i18nKeys.INVOICE.JOIN_FILE)}
                      <div className={styles('text-circle', 'absolute', 'border', 'grey')}>
                        {data.additional_files_attributes.length}
                      </div>
                    </Button>
                  )}
                  {data.actions.includes('send_to_debtor') && (
                    <Button
                      noMargin
                      label={t(
                        credit
                          ? i18nKeys.INVOICING.SEND_CREDIT_NOTE
                          : i18nKeys.INVOICING.VALIDATE_INVOICE,
                      )}
                      color={ButtonColor.MAIN}
                      onClick={validate}
                      iconRight={IconName.MINIMAL_RIGHT}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {data.imported ? (
            <div className={styles('pdf')}>
              <Document file={pdf}>
                <Page renderAnnotationLayer={false} renderTextLayer={false} pageNumber={1} />
              </Document>
            </div>
          ) : (
            <div className={styles('invoice')}>
              <GradientBorder />
              <div className={styles('content')}>
                {company && (
                  <div className={styles('head')}>
                    {company.invoice_customization.logo_url && (
                      <img
                        className={styles('logo')}
                        src={company.invoice_customization.logo_url}
                        alt=""
                      />
                    )}
                    <div className={styles('title')} />
                  </div>
                )}
                <div className={styles('infos')}>
                  {company && (
                    <div className={styles('company-detail')}>
                      <div className={styles('name')}>{company.name}</div>
                      <AddressBlock address={company.address_attributes} />
                      <br />
                      {company.vat_number && (
                        <div className={styles('vat-number')}>
                          {t(i18nKeys.VAT)}: <TvaNumber value={company.vat_number} />
                        </div>
                      )}
                    </div>
                  )}
                  {customer && (
                    <div className={styles('main-detail', 'right')}>
                      <div className={styles('name')}>{customer.full_name}</div>
                      <AddressBlock address={customer.address_attributes} />
                      <br />
                      {customer.vat_number && (
                        <div className={styles('vat-number')}>
                          {t(i18nKeys.VAT)} : <TvaNumber value={customer.vat_number} />
                        </div>
                      )}
                      {customer.co_contractor && (
                        <div className={styles('customer-type')}>
                          {t(i18nKeys.INVOICE.REVERSE_CHARGE_COCONTRACTOR)}
                        </div>
                      )}
                      {customer.intracom && (
                        <div className={styles('customer-type')}>
                          {t(i18nKeys.INVOICE.INTRACOMMUNITY)}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={styles('invoice-infos')}>
                  <div>
                    <div className={styles('invoice-number')}>
                      {(!data.draft || nextRef) &&
                        `${t(i18nKeys.NR)} : ${data.draft ? nextRef : data.reference}`}
                    </div>
                    {remaining > 0 && (
                      <div className={styles('recap')}>
                        <span>
                          <HTML
                            className={styles('inline')}
                            html={t(i18nKeys.INVOICING.INVOICE_TEXT.GIVE_THE_AMOUNT, {
                              text: formatAmount(
                                remaining,
                                ',',
                                '.',
                                2,
                                currencySymbol(data.currency, company),
                              ),
                            })}
                          />
                          <b> {company && <BankAccount value={company.bank_account?.iban} />}</b>
                        </span>
                        {data.structured_communication && (
                          <HTML
                            className={styles('inline')}
                            html={t(i18nKeys.INVOICING.INVOICE_TEXT.WITH_COMMUNICATION, {
                              text: formatCommunication(data.structured_communication),
                            })}
                          />
                        )}
                        <HTML
                          className={styles('inline')}
                          html={t(i18nKeys.INVOICING.INVOICE_TEXT.BEFORE_DEADLINE, {
                            date: formattedDate(data.due_date, currentLang),
                          })}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles('right')}>
                    {data.structured_communication && (
                      <div className={styles('info')}>
                        <span className={styles('label')}>{t(i18nKeys.COMMUNICATION)} :</span>
                        <span>{formatCommunication(data.structured_communication)}</span>
                      </div>
                    )}
                    <div className={styles('info')}>
                      <span className={styles('label')}>{t(i18nKeys.INVOICING.INVOICE_DATE)}:</span>
                      <DateItem lg={currentLang} date={data.issue_date} format="DD/MM/YY" />
                    </div>
                    <div className={styles('info')}>
                      <span className={styles('label')}>{t(i18nKeys.DUE_DATE)} :</span>
                      <DateItem lg={currentLang} date={data.due_date} format="DD/MM/YY" />
                    </div>
                  </div>
                </div>
                <div className={styles('products-detail')}>
                  <TableDetails
                    noTva={shouldHideVat}
                    suffix={data.currency}
                    items={data.product_lines_attributes}
                  />
                </div>
                <div className={styles('summary')}>
                  <div className={styles('notes')}>
                    <span className={styles('main')}>{t(i18nKeys.NOTES)}</span>
                    {data.comment && <span className={styles('secondary')}>{data.comment}</span>}
                  </div>
                  <div className={styles('totals')}>
                    <div>
                      <div className={styles('main')}>
                        {t(i18nKeys.TOTAL_NOT_VAT_PRICE)} :
                        <Amount value={data.total_htva} suffix={data.currency} />
                      </div>
                      <div className={styles('secondary')}>
                        {t(i18nKeys.VAT)} :
                        <Amount value={data.total_tvac - data.total_htva} suffix={data.currency} />
                      </div>
                    </div>
                    <div className={styles('middle')}>
                      <div className={styles('main')}>
                        {t(i18nKeys.TOTAL_VAT_PRICE)} :
                        <Amount value={data.total_tvac} suffix={data.currency} />
                      </div>
                      <div className={styles('secondary')}>
                        {t(i18nKeys.INVOICING.ALREADY_PAID)} :
                        <Amount value={data.already_paid_amount} suffix={data.currency} />
                      </div>
                    </div>

                    <div className={styles('total')}>
                      <div className={styles('main')}>
                        {t(i18nKeys.INVOICING.TOTAL_OUTSTANDING_PAYMENTS)} :
                        <Amount value={remaining} suffix={data.currency} />
                      </div>
                      <div className={styles('secondary')}>
                        {t(i18nKeys.DUE_DATE)} :
                        <DateItem lg={currentLang} date={data.due_date} format="DD/MM/YY" />
                      </div>
                    </div>
                  </div>
                </div>
                <InvoiceFooter />
              </div>
              <GradientBorder bottom />
            </div>
          )}
        </>
      )}
    </div>
  );
}
