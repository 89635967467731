import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import DateItem from 'shared/components/DateItem';
import { ExternalMail } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';

import { Badge } from '@mantine/core';

import styleIdentifiers from './ExternalMailDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailDetailInfoRightProps = {
  externalMail: ExternalMail;
  setFullDetail?: () => void;
  isReply?: boolean;
};

export const ExternalMailDetailInfoRight = ({
  externalMail,
  setFullDetail,
  isReply = false,
}: ExternalMailDetailInfoRightProps) => {
  const { t, currentLang } = useTranslation();

  const {
    attributes: { date, status },
  } = externalMail;

  return (
    <div className={styles('info-right')}>
      <span className={styles('date')}>
        <DateItem
          date={new Date(date)}
          lg={currentLang}
          format="DD/MM/YY - HH:mm"
          className={styles('value', 'blue', 'bigger')}
        />
      </span>
      {!isReply && (
        <>
          <Badge variant="light" color={status === 'processed' ? 'green' : 'blue'}>
            {t(i18nKeys.MAILS.STATUS[status.toUpperCase()])}
          </Badge>
          <Button
            noShadow
            color={ButtonColor.GREY}
            label={t(i18nKeys.INVOICING.SEE_DETAIL)}
            noMargin
            small
            onClick={() => setFullDetail?.()}
          />
        </>
      )}
    </div>
  );
};
