import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import { CustomPreferencesFieldsName, PreferencesForm } from 'shared/forms/PreferencesForm';
import {
  CGV,
  CompanyEdit,
  CompanyTermsAndConditionsEdit,
  SalePreferencesFieldsName,
} from 'shared/forms/PreferencesForm/PreferencesForm.constants';
import { PageTitle } from 'shared/layout/PageTitle';
import { treatCompany } from 'shared/serializer';
import { accountActions } from 'store/account/account.actions';

import { Space } from '@mantine/core';

import { useGetCompany } from '../../../../shared/utils/selectors';

import { TaskSettings } from './TaskSettings';

export const PreferenceSettings = () => {
  const { t } = useTranslation();

  const company = useGetCompany()!;

  const defaultValues = { ...company } as CompanyEdit;

  const handleFilesUpdate = (files: CompanyTermsAndConditionsEdit[], res: any) => {
    const { terms_and_conditions_attributes } = treatCompany(res.data);

    files.forEach((file) => {
      accountActions.uploadTerms({
        ...file,
        ...terms_and_conditions_attributes.find((e) => e.id === file.id || e.name === file.name),
      });
    });
  };

  const handleUpdate = (values: CompanyEdit) => {
    const updateCompany: CompanyEdit = {
      ...defaultValues,
      ...omit(values, [CustomPreferencesFieldsName.SEND_COPY_EMAILS_INPUT]),
      [CGV]: values[CGV].map((e) => omit(e, SalePreferencesFieldsName.PDF_BLOB)),
    };

    accountActions.updateCompany({
      ...updateCompany,
      callback: (res) =>
        handleFilesUpdate(
          values[CGV].filter((e) => e[SalePreferencesFieldsName.PDF_BLOB]),
          res,
        ),
    });
  };

  return (
    <>
      <PageTitle>{t(i18nKeys.SETTINGS.PREFERENCES.TITLE)}</PageTitle>
      <PreferencesForm onSubmit={handleUpdate} company={company} />
      {company.package.can_use_tasks && (
        <>
          <Space h="md" />
          <TaskSettings />
        </>
      )}
    </>
  );
};
