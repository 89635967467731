import { useTranslation } from 'locales';
import { TABLE_HEADERS } from 'modules/Reminders/Reminder.constants';
import CustomTable from 'shared/components/CustomTable';

import { useLoadReminders } from '../api/use-load-index-reminders';

import { ReminderListItem } from './ReminderListItem';

interface ReminderProps {}

const EnhancedTable = CustomTable(ReminderListItem);

export const ReminderList = ({}: ReminderProps) => {
  const { t } = useTranslation();

  const { reminderIndex, isReminderIndexLoading, isReminderIndexFetching } = useLoadReminders();

  if (!reminderIndex) {
    return null;
  }

  const headers = TABLE_HEADERS.map(({ title, ...rest }) => ({
    title: t(title),
    ...rest,
  }));

  return (
    <EnhancedTable
      title="Sent reminders"
      notSortable
      headers={headers}
      noCheckbox
      items={reminderIndex}
      itemProps={{ reminderIndex }}
      loading={isReminderIndexLoading}
      loaded={!isReminderIndexLoading && !isReminderIndexFetching}
      showShadow
    />
  );
};
