import { Fragment, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';

import { RecoveryPlanAddStep } from './RecoveryPlanAddStep';
import { RecoveryPlanStepProps } from './RecoveryPlanStep.types';
import { RecoveryPlanStepLine } from './RecoveryPlanStepLine';

import styleIdentifiers from './RecoveryPlan.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const RecoveryPlanStepEl = ({
  add,
  array,
  arrayIndex,
  ...props
}: PropsWithChildren<RecoveryPlanStepProps>) => {
  const { t } = useTranslation();

  const {
    index,
    step: { id, before_due_date, step_type },
    onClickOnStep,
    stepHighlight,
    stepClassName,
    children,
    editMode,
    canClickPreventiveReminder,
    recoverPlan,
  } = props;

  const clickOnStep = (i) => () => {
    onClickOnStep?.(i);
  };

  const preventiveReminder = recoverPlan.recovery_steps.some(
    (e) => e.step_type === 'preventive_reminder',
  );

  const lastReminder = recoverPlan.recovery_steps.some((e) => e.step_type === 'last_reminder');

  const getClassName = () => {
    if (step_type === 'preventive_reminder') {
      return styles(
        'debt-collection-item',
        canClickPreventiveReminder && stepClassName,
        stepHighlight === index && 'highlight',
      );
    }
    return styles(
      'debt-collection-item',
      id && stepClassName,
      stepHighlight === index && 'highlight',
    );
  };

  const getTopButtonText = () => {
    if (!before_due_date) {
      return undefined;
    }

    if (preventiveReminder) {
      return t(i18nKeys.ADD_TASK);
    }

    return t(i18nKeys.ADD_STEP);
  };

  const getBottomButtonText = () => {
    if (before_due_date) {
      return undefined;
    }

    if (lastReminder) {
      return t(i18nKeys.ADD_TASK);
    }

    return t(i18nKeys.ADD_STEP);
  };

  return (
    <Fragment key={index}>
      {editMode && arrayIndex === 0 && (
        <div className={styles('add-item', 'timeline', before_due_date && 'before-due-date')}>
          {!before_due_date ? (
            <div className={styles('line')}>
              <RecoveryPlanAddStep onClick={() => add(index)} text={getTopButtonText()} />
            </div>
          ) : (
            <RecoveryPlanAddStep onClick={() => add(index)} text={getTopButtonText()} />
          )}
        </div>
      )}
      {!before_due_date && <div className={styles('delay')}>{children}</div>}
      <div className={getClassName()} onClick={clickOnStep(index)}>
        <div className={styles('timeline')}>
          <div className={styles('circle')}>{index + 1}</div>
          {arrayIndex !== array.length - 1 && (
            <div className={styles('line')}>
              {editMode && <RecoveryPlanAddStep onClick={() => add(index + 1)} />}
            </div>
          )}
        </div>
        <RecoveryPlanStepLine {...props} />
      </div>
      {before_due_date && <div className={styles('delay')}>{children}</div>}
      {editMode && arrayIndex === array.length - 1 && (
        <div className={styles('add-item', 'timeline', before_due_date && 'before-due-date')}>
          {before_due_date ? (
            <div className={styles('line')}>
              <RecoveryPlanAddStep onClick={() => add(index + 1)} text={getBottomButtonText()} />
            </div>
          ) : (
            <RecoveryPlanAddStep onClick={() => add(index + 1)} text={getBottomButtonText()} />
          )}
        </div>
      )}
    </Fragment>
  );
};
