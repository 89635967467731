import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import { FieldError } from 'react-hook-form';
import { Range } from 'react-range';
import { validationMessage } from 'shared/utils/validation';

import { useMantineTheme } from '@mantine/core';

import styleIdentifiers from './Slider.module.scss';

const styles = classNames.bind(styleIdentifiers);

type SliderProps = {
  noError?: boolean;
  error?: any;
  noErrorText?: boolean;
  maxVal: number;
  step: number;
  renderValue?: Function;
  currentValue: number;
  onSelect: Function;
};

export const Slider = ({
  noError,
  currentValue,
  error,
  noErrorText,
  maxVal,
  step,
  onSelect,
}: SliderProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const theme = useMantineTheme();

  useEffect(() => {
    if (currentValue || currentValue === 0) {
      setValue(currentValue);
    }
  }, [currentValue]);

  const showError = !noError && error;
  const onChange = (newValue) => {
    setValue(newValue[0]);
    onSelect(newValue[0]);
  };

  return (
    <div ref={sliderRef} className={styles('Slider', showError && 'error')}>
      <Range
        step={step}
        min={0}
        max={maxVal}
        values={[value]}
        onChange={onChange}
        renderMark={({ props, index }) =>
          index % 5 === 0 && (
            <div
              className={styles('range-label')}
              {...props}
              style={{
                position: 'absolute',
                top: '20px',
                color: '#000',
                fontSize: '12px',
                padding: '4px',
                borderRadius: '4px',
                whiteSpace: 'nowrap',
                background: '#f7f7f7',
                boxShadow: 'rgb(0 0 0 / 56%) 1px 1px 8px',
                transform: 'translate(-50%)',
                ...props.style,
              }}
            >
              {index * step} %
            </div>
          )
        }
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '6px',
              width: '100%',
              backgroundColor: '#ccc',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '20px',
              width: '20px',
              borderRadius: '20px',
              backgroundColor: theme.colors.blue[7],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}
          >
            <div
              className={styles('range-label')}
              style={{
                position: 'absolute',
                top: '-28px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                padding: '4px',
                borderRadius: '4px',
                backgroundColor: '#548BF4',
                whiteSpace: 'nowrap',
              }}
            >
              {Math.round(value)} %
            </div>
            )
          </div>
        )}
      />
      {/* <input type="range" className={styles('slider-input')} min="0" max={maxVal} step={step} value={value} onChange={selectStep} />
            <div className={styles('slider-wrapper')}>
                <div className={styles('slider-step-container')}>
                    {elements.map(value => (
                        <div key={value} className={styles('slider-step', hover && value === position && 'active')}>
                            <div className={styles('step-description')}>{renderValue ? renderValue(value) : value}</div>
                        </div>
                    ))}
                </div>
            </div> */}
      {/* <div className={styles('slider-value')} style={{ width: `${value === 0 ? 0 : (value / maxVal) * 100}%` }}>
                    <div className={styles('slider-value-indic')}></div>
                </div> */}
      {showError && !noErrorText && (
        <div className={styles('error-message')}>
          {t(
            validationMessage[(error as FieldError).type] || (error as FieldError).message || error,
          )}
        </div>
      )}
    </div>
  );
};
