import { removeAttributes } from 'shared/utils/view';
import { compareItems, flattenItem } from 'store/reducers';
import { Company } from 'types/store/account-state';

import { treatRecoveryPlan } from './settings.serializer';

export const treatCompany = (item: any): Company => {
  // @ts-ignore
  if (item == null) return null;
  const newItem = flattenItem(item, [], ['send_copy_emails', 'company_bank_accounts']);

  newItem.invoice_customization.billing_logs = newItem.invoice_customization.billing_logs.map(
    (billing_log) => removeAttributes(billing_log),
  );

  if (newItem.default_bank_account_id && newItem.bank_accounts_attributes) {
    newItem.bank_account = newItem.bank_accounts_attributes.find((it) =>
      compareItems(it, newItem.default_bank_account_id, 'id'),
    );
  } else if (newItem.can_edit_iban) {
    newItem.bank_account = {
      iban: newItem.iban,
      bic: newItem.bic,
    };
  }

  newItem.recovery_plan = newItem.recovery_plan.data
    ? treatRecoveryPlan(newItem.recovery_plan.data)
    : null;

  newItem.company_bank_accounts = newItem.company_bank_accounts.map((bank_account) =>
    removeAttributes(bank_account),
  );

  newItem.isFrenchClient = newItem.address_attributes.country_code === 'FR';

  return newItem;
};
