import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { Textarea } from 'shared/io';

import { InvoiceFormFieldValues, InvoiceFormSharedProps } from '../InvoiceForm.types';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type CommentFieldProps = InvoiceFormSharedProps;

export const CommentField = ({}: CommentFieldProps) => {
  const { t } = useTranslation();

  const { register } = useFormContext<InvoiceFormFieldValues>();

  return (
    <Textarea
      register={register('comment')}
      placeholder={t(i18nKeys.INVOICING.CREATE.NOTES_COMMENTS)}
      className={styles('textarea')}
      inputClassName={styles('value')}
      noBorder
      noMargin
      rows={6}
    />
  );
};
