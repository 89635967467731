import React from 'react';
import { SetReasonForm } from 'app/Private/Reasons/SetReasonForm/SetReasonForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { useIsMobile } from 'shared/hooks/utils';
import { Dropdown } from 'shared/io';
import { isConnectorActive } from 'shared/utils/connector';
import { invoiceActions } from 'store/invoice/invoice.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
} from 'store/view/view.actions';
import { Invoice, InvoiceActions } from 'types/store/invoice-state';

import styleIdentifiers from './markedInvoiceActions.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  element: React.ReactElement;
  invoice: Invoice;
};

enum Flags {
  DISPUTE = 'dispute',
  LOSE = 'lose',
  PAUSE = 'pause',
}

export default function MarkedInvoiceActions({ invoice, element }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const checkIfInactive = (type: InvoiceActions) => !invoice?.actions.includes(type);
  const hasActiveConnector = isConnectorActive();

  const reloadInvoice = () => {
    invoiceActions.info({
      id: invoice.id,
      noReset: true,
      noLoading: true,
    });
  };

  const handlePause = (values: Object) => {
    invoiceActions.actionsPause({
      id: invoice.id,
      data: values,
      callback: () => {
        reloadInvoice();
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  const handleLost = (values: Object) => {
    invoiceActions.actionsLost({
      id: invoice.id,
      data: values,
      callback: () => {
        reloadInvoice();
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  const handleDisputed = (values: any) => {
    invoiceActions.actionsDisputed({
      id: invoice.id,
      data: { ...values, dispute: true },
      callback: () => {
        reloadInvoice();
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  const toggleClose = () => {
    if (isMobile) sideMenuHide();
    invoiceActions.actionsPause({
      id: invoice.id,
      callback: reloadInvoice,
    });
  };

  const paid = () => {
    if (checkIfInactive('mark_as_paid')) return;
    if (isMobile) sideMenuHide();

    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      keepMountOnExit: true,
      title: t(i18nKeys.INVOICE.PAID),
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.INVOICE_ACTION_PAID)}
          onConfirm={() => {
            invoiceActions.actionsPaid({
              id: invoice.id,
              callback: reloadInvoice,
            });
          }}
        />
      ),
    });
  };

  const disputed = () => {
    if (isMobile) sideMenuHide();
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.INVOICE.DISPUTED),
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.INVOICE_ACTION_UNDISPUTED)}
          onConfirm={() => {
            invoiceActions.actionsDisputed({
              id: invoice.id,
              data: {
                dispute: false,
              },
              actionFailure: () => {
                dialogHide(DialogShowId.CUSTOM);
              },
              callback: () => {
                reloadInvoice();
                dialogHide(DialogShowId.CUSTOM);
              },
            });
          }}
        />
      ),
    });
  };

  const handleFlag = (actionType: string) => {
    switch (actionType) {
      case Flags.PAUSE:
        return handlePause;
      case Flags.LOSE:
        return handleLost;
      default:
        return handleDisputed;
    }
  };

  const flag = (actionType: string, title: string) => {
    if (isMobile) sideMenuHide();

    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(title),
      children: <SetReasonForm onSubmit={handleFlag(actionType)} reasonType={actionType} />,
    });
  };

  return (
    <Dropdown
      className={styles('marked-invoice-actions')}
      contentClass={styles('content-class')}
      selectorContent={element}
      toggleContent
      sideMenuInMobile
      sideMenuTitle={t(i18nKeys.INVOICE.CHANGE_STATUS)}
    >
      {!hasActiveConnector && (
        <div
          className={styles('dropdown-item', checkIfInactive('mark_as_paid') && 'disabled')}
          onClick={paid}
        >
          <div className={styles('item-content')}>
            <div className={styles('header')}>{t(i18nKeys.INVOICE.MARK_INVOICE_AS_PAID)}</div>
          </div>
        </div>
      )}
      <div
        className={styles('dropdown-item', checkIfInactive('mark_as_lost') && 'disabled')}
        onClick={() => flag(Flags.LOSE, i18nKeys.INVOICE.INVOICE_LOST)}
      >
        <div className={styles('item-content')}>
          <div className={styles('header')}>{t(i18nKeys.INVOICE.MARK_INVOICE_AS_LOST)}</div>
        </div>
      </div>

      {checkIfInactive('undispute') ? (
        <div
          className={styles('dropdown-item', checkIfInactive(Flags.DISPUTE) && 'disabled')}
          onClick={() => flag(Flags.DISPUTE, i18nKeys.INVOICE.DISPUTED)}
        >
          <div className={styles('item-content')}>
            <div className={styles('header')}>{t(i18nKeys.INVOICE.MARK_AS_DISPUTED)}</div>
          </div>
        </div>
      ) : (
        <div className={styles('dropdown-item')} onClick={disputed}>
          <div className={styles('item-content')}>
            <div className={styles('header')}>{t(i18nKeys.INVOICE.UNDO_DISPUTED)}</div>
          </div>
        </div>
      )}
      {!checkIfInactive('pause') && (
        <div
          className={styles('dropdown-item')}
          onClick={() => flag('pause', i18nKeys.CLIENT.PAUSE)}
        >
          <div className={styles('item-content')}>
            <div className={styles('header')}>{t(i18nKeys.INVOICE.PAUSE_THE_FOLLOW_UP)}</div>
          </div>
        </div>
      )}
      {!checkIfInactive('unpause') && (
        <div className={styles('dropdown-item')} onClick={toggleClose}>
          <div className={styles('item-content')}>
            <div className={styles('header')}>{t(i18nKeys.INVOICE.RESUME_FOLLUW_UP)}</div>
          </div>
        </div>
      )}
    </Dropdown>
  );
}
