import classNames from 'classnames/bind';

import styleIdentifiers from './Swiper.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  children: any[];
  activeTab: number;
};

export default function Swiper({ children, activeTab }: Props) {
  return (
    <div className={styles('swiper', `tab-${activeTab}`)}>
      {children.map((child, index) => (
        <div key={index}>{child}</div>
      ))}
    </div>
  );
}
