import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import { settingsActions } from 'store/settings/settings.actions';
import { StoreState } from 'types/storeTypes';

import { CustomizationSettingsCustomVariablesItem } from './CustomizationSettingsCustomVariablesItem';

const CustomVariablesTable = CustomTable(CustomizationSettingsCustomVariablesItem);

export const CustomizationSettingsCustomVariables = () => {
  const { t } = useTranslation();

  const customVariableList = useSelector((state: StoreState) => state.settings.customVariableList);

  const TABLE_HEADERS = [
    {
      key: 'name',
      title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.NAME),
    },
    {
      key: 'column_name',
      title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.COLUMN_NAME),
    },
    {
      key: 'var_type',
      title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.VAR_TYPE),
    },
    {
      key: 'model_type',
      title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.MODEL_TYPE),
    },
    {
      key: 'default_value',
      title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE),
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  return (
    <CustomVariablesTable
      headers={TABLE_HEADERS}
      noCheckbox
      noShadow
      loading={customVariableList.loading}
      loaded={customVariableList.loaded}
      loadFunc={settingsActions.customVariableListPage}
      items={customVariableList.pages || []}
      pagination={customVariableList.metadata?.pagination}
    />
  );
};
