import { PropsWithChildren } from 'react';
import { Id, TaskActionType, UpdateTasksStatusBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { TaskActionsAssign } from './TaskActionsAssign';
import { TaskActionsPostpone } from './TaskActionsPostpone';

import styleIdentifiers from '../TaskDetail/TaskDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskActionsProps = {
  taskIds: Id[];
  onClick: (data: UpdateTasksStatusBody) => void;
};

export const TaskActions = ({
  taskIds: task_ids,
  onClick,
  children,
}: PropsWithChildren<TaskActionsProps>) => {
  const { t } = useTranslation();

  const handlePostpone = () =>
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.TASK.ACTIONS.POSTPONE),
      children: (
        <TaskActionsPostpone
          onSubmit={({ days_postponed, ...data }) =>
            onClick({
              task_ids,
              action_type: TaskActionType.postpone,
              days_postponed: Number(days_postponed),
              ...data,
            })
          }
        />
      ),
    });

  const handleAssign = () =>
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.TASK.ACTIONS.ASSIGN),
      children: (
        <TaskActionsAssign
          onSubmit={(data) =>
            onClick({
              task_ids,
              action_type: TaskActionType.assign,
              ...data,
            })
          }
        />
      ),
    });

  const handleDelete = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS[`DELETE_TASK${task_ids.length > 1 ? 'S' : ''}`])}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            onClick({
              task_ids,
              action_type: TaskActionType.delete,
            })
          }
        />
      ),
    });

  return (
    <Dropdown
      sideMenuInMobile
      sideMenuTitle={t(i18nKeys.INVOICE.OTHER_ACTIONS)}
      selectorContent={
        <Button
          noShadow
          noMargin
          label={t(i18nKeys.FORM.ACTIONS)}
          className={styles('button')}
          color={ButtonColor.BLUE}
          iconRight={IconName.MINIMAL_DOWN}
        />
      }
    >
      {Object.keys(TaskActionType).map((key) => (
        <div
          key={key}
          className={styles('dropdown-item')}
          onClick={() => {
            switch (key) {
              case TaskActionType.postpone:
                handlePostpone();
                break;
              case TaskActionType.assign:
                handleAssign();
                break;
              case TaskActionType.delete:
                handleDelete();
                break;
              default:
                onClick({
                  task_ids,
                  action_type: TaskActionType[key],
                });
                break;
            }
          }}
        >
          {t(i18nKeys.TASK.ACTIONS[key.toUpperCase()])}
        </div>
      ))}
      {children}
    </Dropdown>
  );
};
