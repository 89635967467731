import { Id, LightInvoice } from 'api/models';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { Checkbox } from 'shared/io';
import { formattedDate } from 'shared/utils/view';

import styleIdentifiers from './StepsCalculation.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  item: LightInvoice;
  onCheck: Function;
  invoiceIds: Id[];
};

export default function PaymentPlanInvoiceItem({ item, onCheck, invoiceIds }: Props) {
  const { currentLang } = useTranslation();

  const {
    id,
    attributes: {
      currency,
      reference,
      issue_date,
      total_tvac,
      amount_already_paid,
      remaining_balance_with_fees,
      localized_money_object,
    },
  } = item;

  const checked = invoiceIds.includes(id);

  return (
    <tr key={id} className={styles('choose-invoice-item', checked && 'active')}>
      <td>
        <Checkbox
          name=""
          className={styles('checkbox')}
          checked={checked}
          onChange={() => onCheck(id)}
        />
      </td>
      <td className={styles('reference')}>{reference}</td>
      <td>{formattedDate(issue_date, currentLang)}</td>
      <td>
        <Amount
          localizedValue={total_tvac}
          value={localized_money_object.total_tvac}
          suffix={currency}
          className={styles('value', 'bigger')}
        />
      </td>
      <td>
        {amount_already_paid && (
          <Amount
            localizedValue={amount_already_paid}
            value={localized_money_object.amount_already_paid}
            suffix={currency}
            className={styles('value', 'bigger')}
          />
        )}
      </td>
      <td>
        <Amount
          localizedValue={remaining_balance_with_fees}
          value={localized_money_object.remaining_balance_with_fees}
          suffix={currency}
          className={styles('value', 'bigger')}
        />
      </td>
    </tr>
  );
}
