import { AxiosInstance } from 'axios';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Id } from '../../types';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

type Variables = {
  debtorId: Id;
};

export async function refreshMonitoringFn(axiosInstance: Promise<AxiosInstance>, { debtorId }) {
  const instance = await axiosInstance;
  return instance.post(`/debtors/${debtorId}/monitoring`);
}

export function useRefreshMonitoring() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, Variables>({
    mutationFn: (variables) => refreshMonitoringFn(axiosInstance, variables),
    onSuccess: (response, { debtorId }) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['monitoring', String(debtorId)],
      });
    },

    onError: (error) => {
      notifications.show({
        message: error?.response?.data?.error_message ?? error.message,
        color: 'red',
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, Variables, 'refreshMonitoring'>(
    'refreshMonitoring',
    mutation,
  );
}
