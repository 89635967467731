import { useCreateTaskType } from 'api';
import { CreateTaskBody, CreateTaskTypeBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CustomSelect } from 'shared/io';
import { appConstants } from 'store/app/app.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { RecoveryPlanStep } from 'types/recovery-plan';
import { StoreState } from 'types/storeTypes';

import { TaskTypeForm } from '../TaskTypeForm/TaskTypeForm';

import styleIdentifiers from './TaskFormType.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskFormTypeProps = {
  name?: 'task_type_id' | 'task_attributes.task_type_id';
  canAdd?: boolean;
  disabled?: boolean;
};

export const TaskFormType = ({
  name = 'task_type_id',
  canAdd = true,
  disabled = false,
}: TaskFormTypeProps) => {
  const { t } = useTranslation();

  const { task_types } = useSelector((state: StoreState) => state.app.constants);

  const { setValue } = useFormContext<CreateTaskBody | RecoveryPlanStep>();

  const dispatch = useDispatch();

  const { mutate: createTaskType } = useCreateTaskType();

  if (!canAdd) {
    return (
      <Controller
        rules={{ required: true }}
        name={name}
        render={() => (
          <CustomSelect
            selectClassName={styles('input')}
            keyText="description"
            keyValue="value"
            withBorder
            name={name}
            label={t(i18nKeys.TASK.ATTRIBUTES.TASK_TYPE)}
            items={task_types}
            notAllowed={disabled}
            filter
          />
        )}
      />
    );
  }

  const onSubmitTaskType = (data: CreateTaskTypeBody) =>
    createTaskType(
      { data },
      {
        onSuccess: ({ data: newTaskType }) => {
          dispatch({
            type: appConstants.constants.result,
            status: 'fulfilled',
            payload: {
              task_types: [
                ...task_types,
                {
                  description: newTaskType.attributes.name,
                  value: newTaskType.id,
                },
              ],
            },
          });
          setValue(name, newTaskType.id);
          dialogHide(DialogShowId.CUSTOM);
        },
      },
    );

  const addTaskType = () =>
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.ADD_TASK_TYPE),
      children: (
        <TaskTypeForm
          onSubmit={onSubmitTaskType}
          onCancel={() => dialogHide(DialogShowId.CUSTOM)}
        />
      ),
    });

  return (
    <div className={styles('field-type')}>
      <Controller
        rules={{ required: true }}
        name={name}
        render={() => (
          <CustomSelect
            selectClassName={styles('input')}
            keyText="description"
            keyValue="value"
            withBorder
            name={name}
            label={t(i18nKeys.TASK.ATTRIBUTES.TASK_TYPE)}
            items={task_types}
            notAllowed={disabled}
            filter
          />
        )}
      />
      <Button
        label="+"
        title={t(i18nKeys.ADD_TASK_TYPE)}
        onClick={addTaskType}
        disabled={disabled}
        noMargin
      />
    </div>
  );
};
