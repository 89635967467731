import { useState } from 'react';

import { Group, SegmentedControl } from '@mantine/core';
import { styled } from '@stitches/react';

import { ActionableTicker } from './tickers/ActionableTicker';
import { AtRiskTicker } from './tickers/AtRiskTicker';
import { DisputedTicker } from './tickers/DisputedTicker';
import { PausedTicker } from './tickers/PausedTicker';
import { TotalReceivableTicker } from './tickers/TotalReceivableTicker';

const SegmentedControlBackgroundOverride = styled('span', {
  '.mantine-SegmentedControl-root': {
    backgroundColor: 'var(--mantine-color-gray-2)',
  },
});

export type Format = 'percentage' | 'absolute';

export const TickersBanner = () => {
  const [format, setFormat] = useState<Format>('percentage');

  return (
    <Group my="lg" mt="md" w="100%" gap={20}>
      <Group flex={1} wrap="wrap" grow preventGrowOverflow={false}>
        <TotalReceivableTicker />
        <AtRiskTicker format={format} />
        <DisputedTicker format={format} />
        <PausedTicker format={format} />
        <ActionableTicker format={format} />
      </Group>
      <SegmentedControlBackgroundOverride>
        <SegmentedControl
          value={format}
          onChange={(value) => setFormat(value as Format)}
          size="sm"
          orientation="vertical"
          data={[
            { label: '%', value: 'percentage' },
            { label: '123', value: 'absolute' },
          ]}
        />
      </SegmentedControlBackgroundOverride>
    </Group>
  );
};
