import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const BalanceValueSchema = z.preprocess((val) => Number(val), z.number());

const BalanceSchema = z.object({
  '0': BalanceValueSchema,
  '30': BalanceValueSchema,
  '60': BalanceValueSchema,
  '90': BalanceValueSchema,
  notDue: BalanceValueSchema,
});

const AgedDebtorSchema = z.object({
  debtorId: z.number(),
  debtorName: z.string().min(1),
  credits: BalanceSchema,
  debits: BalanceSchema,
});

const AgedBalanceSchema = z
  .object({
    computedAt: z.string(),
    agedBalances: z.array(AgedDebtorSchema),
  })
  .transform(({ computedAt, agedBalances }) => ({ computedAt, data: agedBalances }));

const AgedBalanceCompactSchema = z.object({
  computedAt: z.string(),
  credits: BalanceSchema,
  debits: BalanceSchema,
});

export type Balance = z.infer<typeof BalanceSchema>;
export type AgedBalance = z.infer<typeof AgedBalanceSchema>;
export type AgedBalanceCompact = z.infer<typeof AgedBalanceCompactSchema>;

export async function loadAgedBalanceQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/analytics/aged_balances?compact=false', {
    params: {
      view_id: queryKey[2],
    },
  });
  return AgedBalanceSchema.parse(data);
}

export const useLoadAgedBalance = ({ viewId }: { viewId: string | null }) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<AgedBalance, ApiError>({
    queryKey: ['aged-balance', 'regular', viewId],
    queryFn: (context) => loadAgedBalanceQueryFn(axiosInstance, context),
    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<AgedBalance, ApiError, 'agedBalance'>(
    'agedBalance',
    queryResult,
  );
};

export async function loadAgedBalanceCompactQueryFn(
  axiosInstance: Promise<AxiosInstance>,
  { queryKey },
) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/analytics/aged_balances?compact=true', {
    params: {
      view_id: queryKey[2],
    },
  });
  return AgedBalanceCompactSchema.parse(data);
}

export const useLoadAgedBalanceCompact = ({ viewId }: { viewId?: string | null } = {}) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<AgedBalanceCompact, ApiError>({
    queryKey: ['aged-balance', 'compact', viewId],
    queryFn: (context) => loadAgedBalanceCompactQueryFn(axiosInstance, context),
    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<AgedBalanceCompact, ApiError, 'agedBalanceCompact'>(
    'agedBalanceCompact',
    queryResult,
  );
};
