import React from 'react';
import { GetDebtorTimelineParams, TimelineTypes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, CustomSelect, DateSelector } from 'shared/io';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './ClientDetailTabsTimeline.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientDetailTabsTimelineFilterProps = {
  params: GetDebtorTimelineParams;
  setParams: React.Dispatch<React.SetStateAction<GetDebtorTimelineParams>>;
};

export const ClientDetailTabsTimelineFilter = ({
  params,
  setParams,
}: ClientDetailTabsTimelineFilterProps) => {
  const { t } = useTranslation();

  const form = useForm<GetDebtorTimelineParams>({
    shouldUnregister: true,
    defaultValues: omit(params, ['page', 'page_limit', 'sort_by', 'sort_order']),
  });

  const types = Object.values(TimelineTypes).map((type) => ({
    value: type,
    description: t(i18nKeys.TIMELINE[type.toUpperCase()]),
  }));

  const { activity_categories } = useSelector((state: StoreState) => state.app.constants);

  const allParams = omit(params, ['page', 'page_limit', 'sort_by', 'sort_order']);

  const filtersNumber = Object.keys(allParams).filter((value) => value).length || 0;

  const resetFilters = () => {
    form.reset({});
    setParams({});
  };

  return (
    <FormProvider {...form}>
      <form className={styles('timeline-filter-form')}>
        <DateSelector
          className={styles('input', 'date-selector')}
          name="start_date"
          endName="end_date"
          placeholder={t(i18nKeys.OF)}
          endPlaceholder={t(i18nKeys.FORM.TO)}
          withBorder
          noMinDate
          label={t(i18nKeys.DATE)}
          handleChange={(value) => {
            setParams({ ...params, start_date: value });
          }}
          handleEndChange={(value) => {
            setParams({ ...params, end_date: value });
          }}
          noMargin
        />
        <Controller
          name="types"
          render={() => (
            <CustomSelect
              className={styles('large')}
              name="types"
              keyText="description"
              keyValue="value"
              items={types}
              label={t(i18nKeys.FORM.TYPE)}
              withBorder
              noMargin
              multiple
              onValueChanged={(value) => {
                setParams({
                  ...params,
                  types: value,
                });
              }}
            />
          )}
        />
        <Controller
          defaultValue=""
          name="category"
          render={() => (
            <CustomSelect
              className={styles('large')}
              name="category"
              keyText="description"
              keyValue="value"
              items={activity_categories}
              label={t(i18nKeys.FORM.CATEGORY)}
              withBorder
              noMargin
              multiple
              onValueChanged={(value) => {
                setParams({ ...params, category: value });
              }}
            />
          )}
        />
        {filtersNumber > 0 && (
          <Button
            className={styles('remove-filter', 'filter')}
            noMargin
            small
            iconSize="16px"
            color={ButtonColor.WHITE}
            onClick={resetFilters}
          >
            <Icon name={IconName.FILTER} />
            <Icon name={IconName.SMALL_REMOVE} />
          </Button>
        )}
      </form>
    </FormProvider>
  );
};
