import { AxiosInstance } from 'axios';
import { jotaiStore } from 'init/jotai';
import { useSetAtom } from 'jotai';
import { i18nKeys } from 'locales';
import { currentLanguageAtom, translationStateAtom } from 'locales/logic';
import { isDefined } from 'remeda';
import { LocaleSchema } from 'types';
import { z, ZodIssueCode } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult, ApiError, ApiResponse, useAxiosInstance } from './utils';

const MAX_FILE_SIZE = 5000000;
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

// https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
z.setErrorMap((issue, ctx) => {
  const { t } = jotaiStore.get(translationStateAtom);
  if (issue.code === ZodIssueCode.invalid_string && issue.validation === 'email') {
    return { message: t(i18nKeys.ERROR.INVALID_EMAIL) };
  }
  if (issue.code === ZodIssueCode.too_small && issue.minimum === 1) {
    return { message: t(i18nKeys.ERROR.FIELD_REQUIRED) };
  }
  return { message: ctx.defaultError };
});

const avatarSchema = z
  .any()
  .refine((file) => file.size <= MAX_FILE_SIZE, 'Max image size is 5MB.')
  .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), 'Invalid image type.')
  .nullable();

export const UpdateProfileFormSchema = z
  .object({
    avatarUrl: z.string().url().nullable(),
    email: z.string().email(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    locale: LocaleSchema,
    pageLimit: z.string().transform((val) => Number(val)),
    receiveDailyEmails: z.boolean(),
    receiveNotificationEmails: z.boolean(),
    receiveWeeklyEmails: z.boolean(),
    signature: z.string().nullable(),
    avatar: avatarSchema,
  })
  .partial();

export type UpdateProfileForm = z.infer<typeof UpdateProfileFormSchema>;

function createFormData(data: UpdateProfileForm) {
  const formData = new FormData();
  const parsedData = UpdateProfileFormSchema.parse(data);
  const hasAvatar = 'avatar' in parsedData;

  if (hasAvatar) {
    const { avatar } = parsedData;
    formData.append('avatar', avatar instanceof File ? avatar : '');
  }

  return { formData, hasAvatar };
}

export async function updateProfileFn(
  axiosInstance: Promise<AxiosInstance>,
  data: UpdateProfileForm,
) {
  const instance = await axiosInstance;
  const { formData, hasAvatar } = createFormData(data);

  if (hasAvatar) return instance.put('/profile', formData);

  return instance.put('/profile', UpdateProfileFormSchema.parse(data));
}

export function useUpdateProfile() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();
  const setCurrentLocale = useSetAtom(currentLanguageAtom);

  const mutation = useMutation({
    mutationFn: (data: UpdateProfileForm) => updateProfileFn(axiosInstance, data),
    onSuccess: (_, context) => {
      if (isDefined(context.locale)) setCurrentLocale(context.locale);
      const { t } = jotaiStore.get(translationStateAtom);

      notifications.show({
        message: t(i18nKeys.SETTINGS.PROFILE.SUCCESSFULLY_UPDATE),
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['profile'],
      });
    },
    onError: (error: ApiError) => {
      (error as any).response?.data.error_message.forEach((message: string) => {
        notifications.show({
          title: 'Error',
          color: 'red',
          message,
        });
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, UpdateProfileForm, 'updateProfile'>(
    'updateProfile',
    mutation,
  );
}
