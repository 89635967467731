import { Id } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { clone, mergeWith, subtract } from 'lodash-es';
import { useHistory } from 'react-router';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { AgedBalanceCompact, useAgedBalanceColors } from 'shared/hooks';
import { formatAmount } from 'shared/utils/normalization';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { Badge, Box, Button, useMantineTheme } from '@mantine/core';
import { ResponsivePie } from '@nivo/pie';
import { animated } from '@react-spring/web';

import { DetailedBalance } from '../DetailedBalance';

import styleIdentifiers from './outstandingGraphic.module.scss';

const styles = classNames.bind(styleIdentifiers);

type OutstandingGraphicProps = {
  agedBalanceCompact: AgedBalanceCompact;
  accountManagerId?: Id;
};

export function OutstandingGraphic({
  agedBalanceCompact,
  accountManagerId,
}: OutstandingGraphicProps) {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const colors = useAgedBalanceColors();
  const { push } = useHistory();

  const netBalance = mergeWith(
    clone(agedBalanceCompact.debits),
    agedBalanceCompact.credits,
    subtract,
  );

  const overdueAmount = netBalance['0'] + netBalance['30'] + netBalance['60'] + netBalance['90'];
  const notDueAmount = netBalance.notDue;
  const totalEngaged = overdueAmount + notDueAmount;

  const showBalanceDetailed = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.DASHBOARD.BALANCE_DETAIL),
      children: <DetailedBalance accountManagerId={accountManagerId} />,
    });
  };

  const pieData = [
    {
      id: 'overdue',
      label: t(i18nKeys.ANALYTICS.OVERDUE),
      value: overdueAmount,
      color: colors.totalDue,
      mantineColor: 'rgb(40, 40, 40, 0.8)',
      query: '/invoices/listing?page=1&status=late_unpaid,not_lost',
    },
    {
      id: 'not_due_yet',
      label: t(i18nKeys.ANALYTICS.NOT_DUE_YET),
      value: notDueAmount,
      color: colors.notDue,
      mantineColor: 'rgb(40, 40, 40, 0.8)',
      query: '/invoices/listing?status=unpaid,not_lost&max_days_late=0',
    },
  ];

  return (
    <Card
      className={styles('OutstandingGraphic')}
      title={t(i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TITLE)}
      infosRight={
        <Button variant="light" color="blue" size="compact-sm" onClick={showBalanceDetailed}>
          {t(i18nKeys.INVOICING.SEE_DETAIL)}
        </Button>
      }
    >
      <div className={styles('total')}>
        <div className={styles('label')}>
          {t(i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TOTAL_COMMITTED)}
        </div>
        <div className={styles('amount')}>
          <Box c="dark.5">
            <Amount value={totalEngaged} />
          </Box>
        </div>
      </div>
      <Box w="100%" h="230px">
        <ResponsivePie
          onClick={(d) => push(d.data.query)}
          onMouseEnter={(_datum, event) => {
            event.currentTarget.style.cursor = 'pointer';
          }}
          tooltip={() => null}
          margin={{ top: 10, bottom: 30 }}
          data={pieData}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={4}
          colors={{ datum: 'data.color' }}
          enableArcLinkLabels={false}
          arcLabelsComponent={({ datum, label, style }) => (
            <animated.g transform={style.transform}>
              <foreignObject width="100" height="25" overflow="visible" pointerEvents="none">
                <Badge
                  p="xs"
                  style={{
                    transform: 'translateX(-50%) translateY(-15px)',
                  }}
                  radius="sm"
                  size="md"
                  color={datum.data.mantineColor}
                  variant="filled"
                  fw={500}
                >
                  {formatAmount(label, '.', ' ')}
                </Badge>
              </foreignObject>
            </animated.g>
          )}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              translateX: 15,
              translateY: 30,
              itemWidth: 110,
              itemHeight: 20,
              symbolSize: 12,
              symbolShape: 'circle',
              itemTextColor: theme.colors.gray[6],
            },
          ]}
        />
      </Box>
    </Card>
  );
}
