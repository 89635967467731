import {
  BaseApiActionsType,
  buildActions,
  makeReqCons,
  makeResourceConstants,
} from 'store/actions';

const prefix = 'product';

export const productConstants = {
  ...makeResourceConstants(prefix),
  search: makeReqCons(`${prefix}_search`),
};

type ProductActions = BaseApiActionsType & {
  search: Function;
  searchRes: Function;
};

// Autogenerated actions according to constants
export const productActions = buildActions<ProductActions>('', productConstants, {});
