import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { CustomSelect, DateSelector, SwitchInput } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import { DescVal, StoreState } from 'types/storeTypes';

import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName, IntegrationFormProps } from '../Integration.constants';
import { getHorusConnectUrl, getIntegrationRights } from '../Integration.utils';
import { IntegrationForm } from '../IntegrationForm';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum HorusFormFieldsName {
  IMPORT_INTEGRATION = 'use_horus_import_integration',
  EXPORT_INTEGRATION = 'use_horus_export_integration',
  PAYMENT_MATCHING = 'use_horus_payment_matching',
  FOLDER_ID = 'folder_id',
}

export type HorusFormFields = {
  [HorusFormFieldsName.FOLDER_ID]?: string;
  [HorusFormFieldsName.IMPORT_INTEGRATION]: boolean;
  [HorusFormFieldsName.EXPORT_INTEGRATION]: boolean;
  [HorusFormFieldsName.PAYMENT_MATCHING]: boolean;
  [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: Date;
  [IntegrationFormFieldsName.DEBTOR_UPDATE]: boolean;
  [IntegrationFormFieldsName.AUTOMATIC_SYNC]: boolean;
};

export type HorusFormProps = IntegrationFormProps<HorusFormFields>;

export const HorusForm = ({ onSubmit, onboarding, initialValues }: HorusFormProps) => {
  const { t, currentLang } = useTranslation();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const [companies, setCompanies] = useState<DescVal[]>([]);
  const [preventSubmit, setPreventSubmit] = useState<boolean>(false);

  const { isConfigure, isConnected } = getIntegrationRights(Integration.HORUS, company);

  const form = useForm<HorusFormFields>({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (isConnected && !isConfigure) {
      if (onboarding) {
        onBoardingActions.horusFetchCompanies({
          callback: (data: DescVal[]) => setCompanies(data),
        });
      } else {
        accountActions.horusFetchCompanies({
          callback: (data: DescVal[]) => setCompanies(data),
        });
      }
    }
  }, [isConnected, isConfigure, onboarding]);

  const { handleSubmit, control, watch, setValue } = form;

  const submit = (values: HorusFormFields) => {
    if (preventSubmit) {
      showDialog({
        id: DialogShowId.CONFIRM,
        title: t(i18nKeys.CONFIRM),
        children: (
          <AYSModal
            text={t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE)}
            onConfirm={() => {
              setPreventSubmit(false);
              onSubmit(values);
            }}
          />
        ),
      });
    } else {
      onSubmit(values);
    }
  };

  const debtorUpdate = watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
  const importIntegration = watch(HorusFormFieldsName.IMPORT_INTEGRATION);

  useEffect(() => {
    if (importIntegration) {
      setValue(
        IntegrationFormFieldsName.ISSUE_DATE_LIMIT,
        initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          : new Date(),
      );
    }
  }, [importIntegration, initialValues, setValue]);

  if (!isConnected) {
    return (
      <IntegrationForm<HorusFormFields>
        integration={Integration.HORUS}
        onSubmit={handleSubmit(submit)}
        summary={i18nKeys.FORM.HORUS.SUMMARY}
        connectUrl={getHorusConnectUrl(currentLang as string)}
        form={form}
      />
    );
  }

  return (
    <IntegrationForm<HorusFormFields>
      integration={Integration.HORUS}
      onSubmit={handleSubmit(submit)}
      summary={i18nKeys.FORM.HORUS.SUMMARY}
      form={form}
    >
      {!isConfigure && (
        <div className={styles('select-company')}>
          <p>{t(i18nKeys.FORM.HORUS.IRREVERSIBLE_ACTION)}</p>
          <p className={styles('warning')}>{t(i18nKeys.FORM.WARNING_IRREVERSIBLE_ACTION)}</p>
          <div className={styles('grid-row')}>
            <div className={styles(onboarding ? 'col-12' : 'col-6')}>
              <Controller
                defaultValue=""
                name={HorusFormFieldsName.FOLDER_ID}
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name={HorusFormFieldsName.FOLDER_ID}
                    selectClassName={styles('company-select')}
                    errorClassName={styles('invoice-select-error')}
                    items={companies}
                    keyText="name"
                    keyValue="id"
                    label={t(i18nKeys.FORM.HORUS.SELECT_COMPANY, {
                      text: Integration.HORUS,
                    })}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles('grid-row')}>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={HorusFormFieldsName.IMPORT_INTEGRATION}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                  text: Integration.HORUS,
                })}
              />
            )}
          />
          {importIntegration && (
            <>
              <div className={styles('col-12')}>
                <DateSelector
                  name={IntegrationFormFieldsName.ISSUE_DATE_LIMIT}
                  className={styles('issue-date')}
                  noMinDate
                  label={t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT)}
                  placeholder={t(i18nKeys.ISSUE_DATE)}
                  endPlaceholder={t(i18nKeys.FORM.TO)}
                  required
                  handleChange={(date: Date) => {
                    if (!onboarding) {
                      setPreventSubmit(true);
                    }
                    setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                  }}
                />
              </div>
              <div className={styles('col-12')}>
                <Controller
                  control={control}
                  name={IntegrationFormFieldsName.DEBTOR_UPDATE}
                  render={({ field: { ref, ...values } }) => (
                    <SwitchInput
                      {...values}
                      inversed
                      className={styles('switch-button', 'issue-date')}
                      label={t(
                        debtorUpdate
                          ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                          : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR,
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={HorusFormFieldsName.PAYMENT_MATCHING}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                  text: Integration.HORUS,
                })}
              />
            )}
          />
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={IntegrationFormFieldsName.AUTOMATIC_SYNC}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC)}
              />
            )}
          />
        </div>
      </div>
    </IntegrationForm>
  );
};
