import { useState } from 'react';
import { ExternalMailStatus } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import DateItem from 'shared/components/DateItem';
import { Icon, IconName } from 'shared/components/Icon';
import { ExternalMail, useDeleteExternalMail, useUpdateExternalMail } from 'shared/hooks';
import { ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';
import { TableItemProps } from 'types/table';

import { Badge, Group, Indicator } from '@mantine/core';

import { ExternalMailAction } from '../ExternalMailActions/ExternalMailAction';
import { ExternalMailActionDebtor } from '../ExternalMailActions/ExternalMailActionDebtor';

import styleIdentifiers from './ExternalMailList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type EnhanceExternalMailItem = ExternalMail & {
  checked: boolean;
};

type ExternalMailListItemProps = TableItemProps<EnhanceExternalMailItem> & {
  action: OnClickFunction;
};

export const ExternalMailListItem = ({ item, action }: ExternalMailListItemProps) => {
  const { t, currentLang } = useTranslation();
  const history = useHistory();

  const [isHovered, setIsHovered] = useState(false);
  const { updateExternalMail } = useUpdateExternalMail();
  const { deleteExternalMail } = useDeleteExternalMail();

  const {
    id,
    checked: isChecked,
    attributes: {
      date,
      subject,
      from_mail,
      from_name,
      read: isRead,
      status,
      debtor,
      has_attachments: hasAttachments,
      reply_count,
    },
  } = item;

  const goToDetails = () => {
    history.push(`/mails/${id}`);
  };

  const handleAssign = () =>
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.TASK.ACTIONS.ASSIGN),
      children: (
        <ExternalMailAction
          externalMail={item}
          onSubmit={(data) => {
            updateExternalMail(
              { id, debtor_id: data.debtor_id },
              { onSuccess: () => dialogHide(DialogShowId.CUSTOM) },
            );
          }}
        >
          <ExternalMailActionDebtor />
        </ExternalMailAction>
      ),
    });

  const handleChangeStatus = () => {
    const newStatus =
      status === ExternalMailStatus.processed
        ? ExternalMailStatus.unprocessed
        : ExternalMailStatus.processed;
    updateExternalMail({ id, status: newStatus });
  };

  const handleDelete = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_EXTERNAL_MAIL)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteExternalMail(
              { id },
              {
                onSuccess: () => {
                  dialogHide(DialogShowId.CONFIRM);
                },
              },
            )
          }
        />
      ),
    });

  const today = new Date().setHours(0, 0, 0, 0);

  const parsedDate = new Date(date);

  const dayDate = () => {
    if ((parsedDate as any) - today < 0) return 'DD/MM/YY - ';
    return '';
  };

  return (
    <tr
      className={styles(
        'mails-list-item',
        isRead ? 'read' : 'unread',
        reply_count > 0 && `reply-${reply_count}`,
        isHovered && 'hover',
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <td>
        {action && (
          <div onClick={action} className={styles('box', 'checkbox', isChecked && 'checked')} />
        )}
      </td>
      <td onClick={goToDetails}>
        <Group wrap="nowrap">
          {reply_count > 0 ? <Indicator /> : null}
          <span
            title={t(i18nKeys.EXTERNAL_MAIL.ATTACHMENTS)}
            className={styles('attachments')}
            role="img"
            aria-label="paper-clip"
          >
            {hasAttachments ? <>📎</> : <>⠀⠀</>}
          </span>
          {subject}
        </Group>
      </td>
      <td>
        {from_name && (
          <>
            <span>{from_name}</span>
            <br />
          </>
        )}
        <a href={`mailto:${from_mail}`}>{from_mail}</a>
        {debtor && (
          <>
            <br />
            <NavLink to={`/clients/${debtor.id}`}>{debtor.attributes.full_name}</NavLink>
          </>
        )}
      </td>
      <td onClick={goToDetails}>
        <Badge variant="light" color={status === 'processed' ? 'green' : 'blue'}>
          {t(i18nKeys.MAILS.STATUS[status.toUpperCase()])}
        </Badge>
      </td>
      <td onClick={goToDetails}>
        {dayDate() && (
          <DateItem
            date={parsedDate}
            lg={currentLang}
            format="DD/MM/YY"
            className={styles('value', 'blue', 'bigger')}
          />
        )}
        <DateItem
          date={parsedDate}
          lg={currentLang}
          format="HH:mm"
          className={styles('value', 'blue', 'bigger')}
        />
      </td>
      <td>
        <div className={styles('actions')}>
          <span onClick={handleAssign} title={t(i18nKeys.TASK.ACTIONS.ASSIGN)}>
            <Icon name={IconName.USER} size="18px" className={styles('action')} />
          </span>
          <span
            onClick={handleChangeStatus}
            title={t(
              i18nKeys.EXTERNAL_MAIL.ACTIONS[
                `${status === ExternalMailStatus.processed ? 'UN' : ''}PROCESS`
              ],
            )}
          >
            <Icon
              name={IconName[status === ExternalMailStatus.processed ? 'SIMPLE_REMOVE' : 'CHECK']}
              size="18px"
              className={styles('action')}
            />
          </span>
          <span onClick={handleDelete} title={t(i18nKeys.DELETE)}>
            <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
          </span>
        </div>
      </td>
    </tr>
  );
};
