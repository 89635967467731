import { PropsWithChildren, ReactNode } from 'react';
import { makeAc } from 'store/actions';

export enum DialogShowId {
  CUSTOM = 'custom',
  INVOICE = 'invoice',
  CONFIRM = 'confirm',
}

export enum DialogShowSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  FULL = 'full',
}

export const viewActionTypes = {
  dialog: {
    show: 'DIALOG_SHOW',
    hide: 'DIALOG_HIDE',
  },
  notification: {
    show: 'NOTIFICATION_SHOW',
    hide: 'NOTIFICATION_HIDE',
    error: 'NOTIFICATION_ERROR',
    success: 'NOTIFICATION_SUCCESS',
  },
  sideMenu: {
    show: 'SIDE_MENU_SHOW',
    hide: 'SIDE_MENU_HIDE',
    setAskBeforeClose: 'SIDE_MENU_ASK_BEFORE_CLOSE',
  },
};

export type ShowDialogOptions = PropsWithChildren<{
  id: DialogShowId;
  size?: DialogShowSize;
  keepMountOnExit?: boolean;
  title?: string;
  messages?: string;
  onClose?: Function;
}>;

export type SideMenuOptions = {
  askBeforeClose?: boolean;
  unmount?: boolean;
  content: ReactNode;
};

type ShowDialog = (showDialogOptions: ShowDialogOptions) => {};

type DialogHide = (id: DialogShowId) => {};

type SideMenuShow = (sideMenuOptions: SideMenuOptions) => {};

export const showDialog = makeAc(viewActionTypes.dialog.show) as ShowDialog;
export const dialogHide = makeAc(viewActionTypes.dialog.hide) as DialogHide;
export const success = makeAc(viewActionTypes.notification.success);
export const error = makeAc(viewActionTypes.notification.error);
export const sideMenuShow = makeAc(viewActionTypes.sideMenu.show) as SideMenuShow;
export const sideMenuSetAskBeforeClose = makeAc(viewActionTypes.sideMenu.setAskBeforeClose);
export const sideMenuHide = makeAc(viewActionTypes.sideMenu.hide);
