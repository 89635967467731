import { AxiosError, AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const PaymentSchema = z.discriminatedUnion('isLate', [
  z.object({ isLate: z.literal(true), clientPortalLink: z.string().min(1) }),
  z.object({ isLate: z.literal(false), clientPortalLink: z.null() }),
]);

const CompanySchema = z.object({
  id: z.number(),
  name: z.string().min(1),
  subdomain: z.string().min(1),
  payment: PaymentSchema,
});

export type CompanyV2 = z.infer<typeof CompanySchema>;

export async function loadCompanyQueryFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;
  const { data } = await instance.get('/company');

  return CompanySchema.parse(data);
}

export function useLoadCompany() {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<CompanyV2, ApiError>({
    queryKey: ['company'],
    queryFn: () => loadCompanyQueryFn(axiosInstance),
  });

  return addResourceNameToQueryResult<CompanyV2, AxiosError, 'company'>('company', queryResult);
}
