import { useTenant } from 'app/TenantValidator';
import { i18nKeys, useTranslation } from 'locales';
import { isNullish } from 'remeda';
import { useLoadCompany } from 'shared/hooks';
import { useGetCompany } from 'shared/utils/selectors';

import { Alert, Button, Flex } from '@mantine/core';
import { styled } from '@stitches/react';
import { IconAlertTriangle, IconExternalLink } from '@tabler/icons-react';

// Aless doesn't like how the button makes the alert "too tall"
const BannerHeightFix = styled('div', {
  '.mantine-Alert-title': { marginBottom: '0px' },
});

export const WarningBanners = () => {
  const { t } = useTranslation();
  const company = useGetCompany()!;
  const { company: companyV2 } = useLoadCompany();
  const tenant = useTenant();

  if (!tenant.onboarding.isComplete) return null;

  return (
    <>
      {companyV2?.payment.isLate && companyV2?.payment.clientPortalLink && (
        <BannerHeightFix>
          <Alert
            m="md"
            variant="filled"
            color="red"
            title={t(i18nKeys.PAYMENT_BANNER.TITLE)}
            icon={<IconAlertTriangle />}
          >
            <Flex justify="space-between" align="center">
              {t(i18nKeys.PAYMENT_BANNER.LATE_PAYMENT_BANNER_TEXT)}
              <Button
                color="white"
                variant="outline"
                rightSection={<IconExternalLink size="22px" />}
                component="a"
                target="_blank"
                href={companyV2.payment.clientPortalLink}
              >
                {t(i18nKeys.PAYMENT_BANNER.GO_TO_PAYMENT_PORTAL)}
              </Button>
            </Flex>
          </Alert>
        </BannerHeightFix>
      )}

      {isNullish(company.vat_number) && (
        <Alert color="red" title="Late payment" m="md" icon={<IconAlertTriangle />}>
          {t(i18nKeys.VAT_NUMBER_MISSING)}{' '}
          <a href="/settings/company">{t(i18nKeys.NAV.SETTINGS).toLowerCase()}</a>
        </Alert>
      )}

      {company.ponto_integration.active && !company.ponto_integration.synced && (
        <Alert title="Ponto integration" color="red" m="md" icon={<IconAlertTriangle />}>
          <span>
            {t(i18nKeys.PONTO_NOT_SYNCED)}
            <a href="https://myponto.com/" target="_blank" rel="noreferrer">
              My Ponto
            </a>
          </span>
        </Alert>
      )}
    </>
  );
};
