import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export const CompanyWebCredentialsSchema = z.union([
  z.object({
    login: z.string(),
    password: z.string(),
  }),
  z.object({
    login: z.null(),
    password: z.null(),
  }),
]);

export type CompanyWebCredentials = z.infer<typeof CompanyWebCredentialsSchema>;

export async function saveCompanyWebCredentialsFn(
  axiosInstance: Promise<AxiosInstance>,
  data: CompanyWebCredentials,
) {
  const instance = await axiosInstance;
  return instance.put('integrations/company-web', CompanyWebCredentialsSchema.parse(data));
}

export function useSaveCompanyWebCredentials() {
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, CompanyWebCredentials>({
    mutationFn: (data: CompanyWebCredentials) => saveCompanyWebCredentialsFn(axiosInstance, data),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    CompanyWebCredentials,
    'saveCompanyWebCredentials'
  >('saveCompanyWebCredentials', mutation);
}
