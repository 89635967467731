import {
  AmountLocalizedMoneyObject,
  LightInvoice,
  MatchedPayment,
} from 'types/store/invoice-state';

import { LinkInvoiceFormField, LinkInvoiceFormMathFields } from './LinkInvoiceForm.types';

export const getLinkInvoiceFormFieldLine = (
  { id }: LightInvoice,
  matchedPayments: MatchedPayment[] = [],
): LinkInvoiceFormField => ({
  invoice_id: Number(id),
  amount: matchedPayments.find((e) => e.invoice_attributes.id === id)?.amount || 0,
});

export const getInvoicesFieldsTotal = (invoicesFields: LinkInvoiceFormField[]) =>
  invoicesFields.reduce((acc, e) => acc + Number(e.amount), 0);

export const getCurrentValues = (
  fields: LinkInvoiceFormField[] | undefined = [],
  { total_tvac }: AmountLocalizedMoneyObject,
): LinkInvoiceFormMathFields => {
  let currentTotal = fields.reduce((acc, e) => acc + parseFloat(String(e.amount)), 0) * 100;
  let maxTotal = Math.abs(total_tvac) * 100;
  let restTotal = maxTotal - currentTotal;

  currentTotal /= 100;
  maxTotal /= 100;
  restTotal /= 100;

  return {
    currentTotal,
    maxTotal,
    restTotal: restTotal > 0 ? restTotal : 0,
    canAdd: currentTotal < maxTotal,
    canSubmit: currentTotal <= maxTotal,
  };
};
