/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type PlanType = (typeof PlanType)[keyof typeof PlanType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanType = {
  payment: 'payment',
  recovery: 'recovery',
} as const;
