import { PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';
import { Icon, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';

import styleIdentifiers from './FormSection.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  title: string;
  onAdd?: Function;
  onToggle?: Function;
  className?: string;
  noLine?: boolean;
  startClosed?: boolean;
  checkbox?: boolean;
  isOpen?: boolean;
};

/**
 *
 * @deprecated Do not use this component. If possible, replace it with a Mantine accordion
 */
export default function FormSection({
  children,
  startClosed,
  isOpen,
  title,
  onAdd,
  onToggle,
  className,
  noLine,
  checkbox,
}: PropsWithChildren<Props>) {
  const [isOpenInternalState, setIsOpenInternalState] = useState(!startClosed);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsOpenInternalState(isOpen);
    }
  }, [isOpen]);

  const toggle = (forceOpen?: boolean) => {
    onToggle?.(forceOpen || !isOpenInternalState);
    setIsOpenInternalState(forceOpen || !isOpenInternalState);
  };

  return (
    <div className={styles(className, 'form-section')}>
      <div className={styles('title')}>
        <div className={styles('name')} onClick={() => toggle()}>
          {title}
        </div>
        {!noLine && <div className={styles('line')} onClick={() => toggle()} />}
        <div className={styles('actions')}>
          {onAdd && (
            <Icon
              name={IconName.PLUS}
              onClick={() => {
                toggle(true);
                onAdd();
              }}
              className={styles('action')}
            />
          )}
          {checkbox ? (
            <Checkbox checked={isOpenInternalState} onChange={toggle} noMargin />
          ) : (
            <Icon
              name={IconName.ARROW_BOTTOM_ROUNDED}
              onClick={() => toggle()}
              className={styles('icon', isOpenInternalState && 'open')}
            />
          )}
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: isOpenInternalState ? 1 : 0,
          height: isOpenInternalState ? 'auto' : 0,
        }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        style={{ overflow: isOpenInternalState ? 'unset' : 'hidden' }} // Important for height animations
      >
        {children}
      </motion.div>
    </div>
  );
}
