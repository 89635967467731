import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { isDefined } from 'remeda';
import Logo from 'shared/components/Logo';
import { useValidateTenant } from 'shared/hooks';
import ApiService from 'shared/service/api.service';

import { Button, Center, Group, TextInput } from '@mantine/core';
import { useField } from '@mantine/form';

import styleIdentifiers from './badCompany.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function BadCompany() {
  const history = useHistory();
  const { t } = useTranslation();
  const subdomain = useField({ initialValue: '' });

  const { tenant } = useValidateTenant(ApiService.tenant);

  // If for some reason we naviagted to this page explicitly while on a correct tenant
  useEffect(() => {
    if (isDefined(tenant)) history.push('/dashboard');
  }, [isDefined(tenant)]);

  const onClickNavigateToTenant = () => {
    const urlWithNewTenant = window.location.origin.replace(
      `${ApiService.tenant}.`,
      `${subdomain.getValue()}.`,
    );
    window.location.assign(`${urlWithNewTenant}/dashboard`);
  };

  return (
    <div className={styles('server-error-page')}>
      <div className={styles('content-wrapper')}>
        <div className={styles('content', 'card')}>
          <h1>{t(i18nKeys.LOGIN.BAD_SUBDOMAIN)}</h1>
          <p>
            {t(i18nKeys.LOGIN.VERIFY_SUBDOMAIN)} : <b>{ApiService.tenant}</b>
          </p>
          <Center>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onClickNavigateToTenant();
              }}
            >
              <Group>
                <TextInput autoFocus {...subdomain.getInputProps()} placeholder="subdomain" />
                <Button onClick={onClickNavigateToTenant}>Go</Button>
              </Group>
            </form>
          </Center>
        </div>
        <Logo className={styles('logo')} type="blue" />
      </div>
    </div>
  );
}
