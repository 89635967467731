import { i18nKeys, useTranslation } from 'locales';
import { isDefined } from 'remeda';
import { STEP_TO_STEP_THEME } from 'shared';
import { CollectionStep, CollectionStepIndex } from 'shared/components/CollectionStep';
import { useEnhancedNavigation } from 'shared/hooks/utils';
import { PageTitle } from 'shared/layout';
import { useLocalizedCurrencyFormatter } from 'shared/utils/normalization';

import { Badge, Box, Button, Drawer, Stack, Text } from '@mantine/core';
import { useDisclosure, useHotkeys } from '@mantine/hooks';
import { IconFilter, IconFilterOff } from '@tabler/icons-react';

import { useLoadKanbanDebtors } from '../api';
import { DebtorKanbanCard, Kanban, KanbanLoadingState } from '../components';
import { DebtorKanbanFilters } from '../components/debtor/DebtorKanbanFilters';
import { countActiveFilters } from '../utils/filters';

export const DebtorKanban = () => {
  const { t } = useTranslation();
  const formatter = useLocalizedCurrencyFormatter();
  const { columns, filtersForm } = useLoadKanbanDebtors();
  const [isFiltersDrawerOpen, filtersDrawerHandlers] = useDisclosure(false);
  const enhancedNavigate = useEnhancedNavigation();

  useHotkeys([['f', filtersDrawerHandlers.toggle]]);

  const isFetching = Object.values(columns).some((column) => column.isDebtorColumnFetching);
  const areAllColumnsPopulated = Object.values(columns).every((column) =>
    isDefined(column.debtorColumn),
  );

  if (!areAllColumnsPopulated)
    return <KanbanLoadingState title={t(i18nKeys.KANBAN.DEBTOR.TITLE)} columnCount={6} />;

  const activeFiltersCount = countActiveFilters(filtersForm.values, ['column']);

  const cols = Object.values(columns).map((column, colIndex) => {
    const cards = column.debtorColumn!.debtors.map((debtor, invoiceIndex) => (
      <Kanban.Card key={invoiceIndex} onClick={enhancedNavigate(`/clients/${debtor.id}/balance`)}>
        <DebtorKanbanCard debtor={debtor} />
      </Kanban.Card>
    ));

    return (
      <Kanban.Column
        isLoading={isFetching}
        key={colIndex}
        title={
          <Stack gap="sm">
            <CollectionStep step={colIndex as CollectionStepIndex} />
            <Text fw="bold" size="16px" c="gray.7">
              {formatter.format(column.debtorColumn!.total)}
            </Text>
          </Stack>
        }
        color={STEP_TO_STEP_THEME[colIndex].color}
      >
        {cards}
      </Kanban.Column>
    );
  });

  return (
    <>
      {/* Fixed height necessary to constrain Kanban columns, This is the natural height of the component without actions */}
      <Box h="60px">
        <PageTitle>
          {t(i18nKeys.KANBAN.DEBTOR.TITLE)}
          <PageTitle.Actions>
            <Button.Group>
              <Button
                variant="default"
                onClick={filtersDrawerHandlers.open}
                leftSection={<IconFilter stroke={1.5} size={18} />}
                rightSection={
                  <Badge color={activeFiltersCount > 0 ? 'blue' : 'gray'}>
                    {activeFiltersCount}
                  </Badge>
                }
              >
                {t(i18nKeys.FILTER)}
              </Button>
              {activeFiltersCount > 0 && (
                <Button variant="default" onClick={filtersForm.reset}>
                  <IconFilterOff stroke={1.5} size={18} />
                </Button>
              )}
            </Button.Group>
          </PageTitle.Actions>
        </PageTitle>
      </Box>
      <Box h="calc(100% - 60px)">
        <Kanban minColumnWidth={180}>{cols}</Kanban>
      </Box>
      <Drawer
        position="right"
        opened={isFiltersDrawerOpen}
        onClose={filtersDrawerHandlers.close}
        title={t(i18nKeys.FILTERS)}
        keepMounted
        padding="lg"
        styles={{
          title: {
            fontSize: '1.5rem',
          },
        }}
      >
        <DebtorKanbanFilters form={filtersForm} />
      </Drawer>
    </>
  );
};
