import axios from 'axios';
import apiService from 'shared/service/api.service';
import { config } from 'store/constants';
import urlJoin from 'url-join';

import { useAuth0 } from '@auth0/auth0-react';

const legacyApiUrl = urlJoin(apiService.baseUrl, 'private_api');
const apiV2Url = urlJoin(apiService.baseUrl, 'private_api/v2');

export function usePublicAxiosInstance() {
  return axios.create({
    baseURL: apiV2Url,
    headers: {
      tenant: apiService.tenant,
    },
  });
}

export async function useAxiosInstance() {
  const { getAccessTokenSilently } = useAuth0();
  const token = await getAccessTokenSilently({
    authorizationParams: {
      audience: config.auth0.audience,
      scope: 'openid profile email offline_access',
    },
  });

  return axios.create({
    baseURL: apiV2Url,
    headers: {
      tenant: apiService.tenant,
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function useLegacyAxiosInstance() {
  const { getAccessTokenSilently } = useAuth0();
  const token = await getAccessTokenSilently({
    authorizationParams: {
      audience: config.auth0.audience,
      scope: 'openid profile email offline_access',
    },
  });

  return axios.create({
    baseURL: legacyApiUrl,
    headers: {
      tenant: apiService.tenant,
      Authorization: `Bearer ${token}`,
    },
  });
}
