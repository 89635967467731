import classNames from 'classnames/bind';

import styleIdentifiers from './loader.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function RecovrLogoLoader() {
  return (
    <div className={styles('loader')}>
      <div className={styles('logo')}>
        <div className={styles('circle', 'top')} />
        <div className={styles('circle', 'middle')} />
        <div className={styles('circle', 'left')} />
        <div className={styles('circle', 'right')} />
        <div className={styles('circle', 'left-bottom')} />
        <div className={styles('circle', 'right-bottom')} />
        <div className={styles('circle', 'bottom')} />
        <div className={styles('line', 'top-left')} />
        <div className={styles('line', 'top-right')} />
        <div className={styles('line', 'vertical', 'left-side')} />
        <div className={styles('line', 'vertical', 'right-side')} />
        <div className={styles('line', 'vertical', 'middle-top')} />
        <div className={styles('line', 'bottom-left')} />
        <div className={styles('line', 'bottom-right')} />
        <div className={styles('line', 'middle-top-right')} />
        <div className={styles('line', 'middle-bottom-right')} />
        <div className={styles('line', 'middle-bottom-left')} />
        <div className={styles('line', 'middle-top-left')} />
      </div>
    </div>
  );
}

export const RecoverSpinner = () => (
  <div className={styles('logo')}>
    <div className={styles('circle', 'top')} />
    <div className={styles('circle', 'middle')} />
    <div className={styles('circle', 'left')} />
    <div className={styles('circle', 'right')} />
    <div className={styles('circle', 'left-bottom')} />
    <div className={styles('circle', 'right-bottom')} />
    <div className={styles('circle', 'bottom')} />
    <div className={styles('line', 'top-left')} />
    <div className={styles('line', 'top-right')} />
    <div className={styles('line', 'vertical', 'left-side')} />
    <div className={styles('line', 'vertical', 'right-side')} />
    <div className={styles('line', 'vertical', 'middle-top')} />
    <div className={styles('line', 'bottom-left')} />
    <div className={styles('line', 'bottom-right')} />
    <div className={styles('line', 'middle-top-right')} />
    <div className={styles('line', 'middle-bottom-right')} />
    <div className={styles('line', 'middle-bottom-left')} />
    <div className={styles('line', 'middle-top-left')} />
  </div>
);
