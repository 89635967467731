import { ContactPerson, CreateContactPersonBody } from 'api/models';

export const getInitialValues = (
  contactPerson?: ContactPerson,
): Partial<CreateContactPersonBody> => {
  if (!contactPerson) {
    return {
      main_contact: false,
    };
  }

  return { ...contactPerson.attributes };
};
