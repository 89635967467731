import { i18nKeys, TranslateFunction } from 'locales';
import { FieldErrors } from 'react-hook-form';
import {
  TermsAndConditionsFixFee,
  TermsAndConditionsInterest,
  TermsAndConditionsPenaltyClause,
} from 'types/store/account-state';
import { DescVal } from 'types/storeTypes';

import {
  CalculationType,
  CGV,
  CompanyTermsAndConditionsEdit,
  InterestCalculationType,
  SalePreferencesCGVFieldsName,
  SalePreferencesFields,
  SalePreferencesFieldsName,
} from '../PreferencesForm.constants';

export const getCalculationTypeOptions = (t: TranslateFunction): DescVal<CalculationType>[] =>
  Object.keys(CalculationType).map((key) => ({
    description: t(i18nKeys.CALCULATION_TYPE[key]),
    value: CalculationType[key as keyof CalculationType],
  }));

export const getInterestCalculationTypeOptions = (
  t: TranslateFunction,
): DescVal<InterestCalculationType>[] =>
  Object.keys(InterestCalculationType).map((key) => ({
    description: t(i18nKeys.INTEREST_CALCULATION_TYPE[key]),
    value: InterestCalculationType[key as keyof InterestCalculationType],
  }));

export const getFieldErrors = (errors: FieldErrors<SalePreferencesFields>, index: number) => {
  const cgvErrors = errors[CGV];
  return cgvErrors && Array.isArray(cgvErrors) && cgvErrors[index] ? cgvErrors[index] : {};
};

export const getDefaultFixFee = (): TermsAndConditionsFixFee => ({
  value: 0,
});

export const getDefaultInterest = (asDefault = false): TermsAndConditionsInterest => ({
  period: CalculationType.ANNUAL,
  value: asDefault ? 8 : 0,
});

export const getDefaultPenaltyClause = (asDefault = false): TermsAndConditionsPenaltyClause => ({
  type: InterestCalculationType.PERCENTAGE,
  minimum: asDefault ? 40 : 0,
  value: asDefault ? 10 : 0,
});

export const getDefaultCgvValues = (asDefault = false): CompanyTermsAndConditionsEdit => ({
  [SalePreferencesFieldsName.DEFAULT]: asDefault,
  [SalePreferencesFieldsName.NAME]: asDefault ? 'General' : '',
  [SalePreferencesFieldsName.MINIMUM_DELAY]: asDefault ? 31 : 0,
  [SalePreferencesFieldsName.HAS_PDF]: false,
  ...(asDefault
    ? {
        [SalePreferencesCGVFieldsName.PENALTY_CLAUSE]: getDefaultPenaltyClause(asDefault),
        [SalePreferencesCGVFieldsName.INTEREST]: getDefaultInterest(asDefault),
      }
    : undefined),
});
