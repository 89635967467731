import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import CustomTable from 'shared/components/CustomTable';
import ThirdpartyCaseListItem from 'shared/components/ThirdpartyCaseListItem';
import ThirdpartyCasesFiltersForm from 'shared/forms/ThirdpartyCasesFiltersForm';
import { useGetThirdPartyCaseState } from 'shared/utils/selectors';
import { thirdPartyCaseActions } from 'store/thirdpartyCase/thirdpartycase.actions';
import { ThirdPartyCase } from 'types/store/thirdparty-case-state';

import styleIdentifiers from './ThirdpartyCaseListing.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EnhancedTable = CustomTable(ThirdpartyCaseListItem, ThirdpartyCasesFiltersForm);

export default function ThirdpartyCaseList() {
  const { t } = useTranslation();
  const { list: thirdPartyCases } = useGetThirdPartyCaseState();

  const THIRD_PARTY_CASES_TABLE_HEADERS = [
    {
      key: 'case_type',
      title: t(i18nKeys.CASE_TYPE),
      width: 140,
    },
    {
      key: 'case_reference',
      title: t(i18nKeys.FORM.REFERENCE),
      width: 160,
    },
    {
      key: 'created_at',
      title: t(i18nKeys.CREATION_DATE),
      width: 140,
    },
    {
      key: 'updated_at',
      title: t(i18nKeys.LAST_UPDATE),
      width: 140,
    },
    {
      key: 'debtor_name',
      title: t(i18nKeys.COMMON.CLIENT),
      width: 160,
    },
    {
      key: 'status',
      title: t(i18nKeys.STATUS),
      width: 120,
    },
    {
      key: 'total_tvac',
      title: t(i18nKeys.TOTAL_VAT_PRICE),
    },
    {
      key: 'remaining_balance',
      title: t(i18nKeys.BALANCE),
    },
  ];

  const handleFilters = (filters) => {
    const newFilters = {
      ...filters,
    };

    // need to be linked
    if (
      (newFilters.total_tvac_operator && !newFilters.total_tvac) ||
      (!newFilters.total_tvac_operator && newFilters.total_tvac)
    )
      return false;

    // need to be linked
    if (
      (newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
      (!newFilters.remaining_balance_operator && newFilters.remaining_balance)
    )
      return false;

    return newFilters;
  };

  const orderField = (field: string) => (field.indexOf('debtor') >= 0 ? 'debtor_name' : field);

  const customKey = (tp_case: ThirdPartyCase) => tp_case.case_type + tp_case.id;

  return (
    <div className={styles('Listing')}>
      <EnhancedTable
        title={t(i18nKeys.THIRD_PARTY_CASES.THIRD_PARTY_CASES_LIST)}
        showShadow
        orderField={orderField}
        loadFunc={thirdPartyCaseActions.listPage}
        loading={thirdPartyCases.loading}
        loaded={thirdPartyCases.loaded}
        pagination={thirdPartyCases.metadata && thirdPartyCases.metadata.pagination}
        headers={THIRD_PARTY_CASES_TABLE_HEADERS}
        handleFilters={handleFilters}
        items={thirdPartyCases.pages}
        customKey={customKey}
        noCheckbox
      />
    </div>
  );
}
