import { useDeleteTaskType, useUpdateTaskType } from 'api';
import { CreateTaskTypeBody, TaskType } from 'api/models';
import { TaskTypeForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { TableItemProps } from 'types/table';

import styleIdentifiers from './TaskTypeListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskTypeListItemProps = TableItemProps<TaskType> & {
  refetch: () => void;
};

export const TaskTypeListItem = ({ item, refetch }: TaskTypeListItemProps) => {
  const { t } = useTranslation();

  const { mutate: deleteTaskType } = useDeleteTaskType();
  const { mutate: updateTaskType } = useUpdateTaskType();

  const {
    id,
    attributes: { name, is_deletable },
  } = item;

  const handleUpdate = () =>
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(item ? i18nKeys.EDIT_TASK_TYPE : i18nKeys.ADD_TASK_TYPE),
      children: (
        <TaskTypeForm
          taskType={item}
          onCancel={() => dialogHide(DialogShowId.CUSTOM)}
          onSubmit={(data: CreateTaskTypeBody) =>
            updateTaskType(
              { id, data },
              {
                onSuccess: () => {
                  refetch();
                  dialogHide(DialogShowId.CUSTOM);
                },
              },
            )
          }
        />
      ),
    });

  const handleDelete = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_TASK_TYPE)}
          onConfirm={() => {
            deleteTaskType(
              { id },
              {
                onSuccess: () => {
                  refetch();
                  dialogHide(DialogShowId.CONFIRM);
                },
              },
            );
          }}
        />
      ),
    });

  return (
    <tr className={styles('custom-variable-item')}>
      <td />
      <td>{id}</td>
      <td>{name}</td>
      <td>
        {t(
          !is_deletable
            ? i18nKeys.SETTINGS.TASKS.TASK_DEFAULT
            : i18nKeys.SETTINGS.TASKS.TASK_CUSTOM,
        )}
      </td>
      <td className={styles('actions')}>
        {is_deletable && (
          <>
            <span onClick={handleUpdate}>
              <Icon name={IconName.PENCIL} size="18px" className={styles('action')} />
            </span>
            <span onClick={handleDelete}>
              <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
            </span>
          </>
        )}
      </td>
    </tr>
  );
};
