import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Dropdown } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { OnClickFunction } from 'types/html-type';

import styleIdentifiers from './tooltip.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  /* If false, the tooltip will simply render its children.
  Use this to simplify cases where the tooltip is not always displayed,
  e.g. when it informs the user of a feature gate */
  shouldExist?: boolean;
  direction?: DropdownDirection;
  contentClassName?: string;
  selectorClass?: string;
  contentClass?: string;
  className?: string;
  text?: string;
  item?: any;
  icon?: IconName;
  iconClassName?: string;
  size?: string;
  color?: IconColor;
  arrow?: boolean;
  onClick?: OnClickFunction;
  oneLine?: boolean;
  arrowClassName?: string;
  style?: React.CSSProperties;
};

export default function Tooltip(props: PropsWithChildren<Props>) {
  const {
    arrow,
    direction,
    contentClassName,
    children,
    text,
    item,
    icon,
    iconClassName,
    size,
    color,
    shouldExist = true,
    style,
    onClick,
  } = props;

  let element = item;
  if ((!element && icon) || iconClassName) {
    element = (
      <Icon onClick={onClick} name={icon!} size={size} color={color} className={iconClassName} />
    );
  }

  if (!shouldExist) {
    return <div style={style}>{element}</div>;
  }

  return (
    <Dropdown
      containerStyle={style}
      selectorContent={element}
      {...props}
      noArrow={!arrow}
      direction={direction}
      hover
    >
      <div className={styles('tooltip', contentClassName)}>
        {text && <HTML html={text} />}
        {children}
      </div>
    </Dropdown>
  );
}
