import { Icon } from 'shared/components/Icon';

import { css, cx } from '@emotion/css';
import { Box, Group, Stack, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';

import { IntegrationCard } from './Integrations.constants';

const styles = {
  card: css`
    cursor: pointer;
    flex-wrap: nowrap;
    position: relative;
    border-radius: var(--mantine-radius-md);
    border: 1px solid var(--mantine-color-gray-3);
    opacity: 1;
    transition:
      box-shadow 100ms ease,
      transform 100ms ease;

    :hover {
      box-shadow: var(--mantine-shadow-sm);
      transform: scale(1.01);
    }
  `,
  cardDisabled: css`
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  `,
};

type IntegrationsCardProps = {
  integration: IntegrationCard;
  onClick: (integration: IntegrationCard) => void;
};

export const IntegrationsCard = ({ integration, onClick }: IntegrationsCardProps) => {
  const theme = useMantineTheme();
  const { title, icon, description, canActivate, isActive } = integration;

  const iconElement = typeof icon === 'string' ? <Icon name={icon} size="50px" /> : icon;

  const canReallyActivate = canActivate || isActive;

  return (
    <UnstyledButton
      disabled={!canReallyActivate}
      p="md"
      className={cx(styles.card, !canReallyActivate && styles.cardDisabled)}
      onClick={() => {
        if (canReallyActivate) onClick(integration);
      }}
    >
      <Group style={{ flexWrap: 'nowrap' }}>
        <Box w={50} mx="md">
          {iconElement}
        </Box>
        <Stack>
          <Text size="lg" fw="bold">
            {title}
          </Text>
          <Text c="dimmed">{description}</Text>
        </Stack>
      </Group>
      {isActive && (
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <IconCircleCheckFilled color={theme.colors.blue[5]} stroke={1.5} />
        </div>
      )}
    </UnstyledButton>
  );
};
