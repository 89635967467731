import {
  BaseApiActionsType,
  buildActions,
  makeReqCons,
  makeResourceConstants,
} from 'store/actions';
import { ReduxFunction } from 'types/redux';
import { SagaPayload } from 'types/saga-payload';

const prefix = 'client';

export const clientConstants = {
  ...makeResourceConstants(prefix),
  search: makeReqCons(`${prefix}_SEARCH`),
  assignToAccManager: makeReqCons(`${prefix}_ASSIGN_TO_USER`),
  assignMultipleToUser: makeReqCons(`${prefix}_ASSIGN_MULTIPLE_TO_USER`),
  getFinancialData: makeReqCons(`${prefix}_GET_FINANCIAl_DATA`),
  comment: makeReqCons(`${prefix}_COMMENT`),
  pauseDebtor: makeReqCons(`${prefix}_PAUSE_DEBTOR`),
  deleteMultiple: makeReqCons(`${prefix}_DELETE_MULTIPLE`),
  pauseMultiple: makeReqCons(`${prefix}_PAUSE_MULTIPLE`),
  batchAction: makeReqCons(`${prefix}_BATCH_ACTION`),

  getAllToConfirm: makeReqCons(`${prefix}_GET_ALL_TO_CONFIRM`),
  getToConfirm: makeReqCons(`${prefix}_GET_TO_CONFIRM`),
  validateAllImportedClients: makeReqCons(`${prefix}_VALIDATE_ALL_TO_CONFIRM`),
  deleteClientToConfirm: makeReqCons(`${prefix}_DELETE_TO_CONFIRM`),
};

type ClientActions = BaseApiActionsType & {
  search: Function;
  searchRes: Function;
  assignMultipleToUser: ReduxFunction;
  assignMultipleToUserRes: Function;
  assignToAccManager: ReduxFunction;
  assignToAccManagerRes: Function;
  comment: Function;
  commentRes: Function;
  getFinancialData: ReduxFunction;
  getFinancialDataRes: Function;
  pauseDebtor: (options: SagaPayload) => {};
  pauseDebtorRes: () => {};
  deleteMultiple: Function;
  pauseMultiple: Function;
  batchAction: ReduxFunction;
  batchActionRes: Function;
  getAllToConfirm: ReduxFunction;
  getAllToConfirmRes: Function;
  getToConfirm: ReduxFunction;
  getToConfirmRes: Function;
  validateAllImportedClients: ReduxFunction;
  validateAllImportedClientsRes: Function;
  deleteClientToConfirm: ReduxFunction;
  deleteClientToConfirmRes: Function;
};

export const clientActions = buildActions<ClientActions>('', clientConstants, {});
