import { removeAttributes } from 'shared/utils/view';
import { baseReducerListPage, handlePageRequest, handlePageResponse } from 'store/reducers';
import { ReduxAction } from 'types/redux';
import { SettingsState } from 'types/store/settings-state';

import { settingsConstants } from './settings.actions';

const initialState: SettingsState = {
  templateList: baseReducerListPage,
  recoveryPlanList: baseReducerListPage,
  customVariableList: baseReducerListPage,
};

const reducer = (state: SettingsState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case settingsConstants.templateListPage.request:
      return handlePageRequest(state, action, 'templateList');
    case settingsConstants.templateListPage.result:
      return handlePageResponse(state, action, 'templateList', removeAttributes);
    case settingsConstants.recoveryPlanListPage.request:
      return handlePageRequest(state, action, 'recoveryPlanList');
    case settingsConstants.recoveryPlanListPage.result:
      return handlePageResponse(state, action, 'recoveryPlanList', removeAttributes);
    case settingsConstants.customVariableListPage.request:
      return handlePageRequest(state, action, 'customVariableListPage');
    case settingsConstants.customVariableListPage.result:
      return handlePageResponse(state, action, 'customVariableList', removeAttributes);
    default:
      return state;
  }
};

export default reducer;
