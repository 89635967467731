import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UpdateUserForm, User } from 'shared/hooks';
import { Button, SwitchInput } from 'shared/io';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './editCollaboratorForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type EditCollaboratorFormProps = { onSubmit: onSubmitFunction; initialValues: User };

export default function EditCollaboratorForm({
  initialValues,
  onSubmit,
}: EditCollaboratorFormProps) {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);

  const { watch, handleSubmit, control } = useForm<UpdateUserForm>({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  const isAdmin = watch('permissions.admin');
  const isActive = watch('isActive');

  return (
    <form className={styles('EditCollaboratorForm')} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles('head')}>
        <div>{initialValues.name.full}</div>
      </div>
      <div className={styles('content')}>
        <div className={styles('role')}>
          <h3> {t(i18nKeys.FORM.EDIT_STAFF.TITLE)}</h3>
          <Controller
            control={control}
            name="isActive"
            render={({ field: { ref, ...values } }) => (
              <SwitchInput noMargin {...values} label={t(i18nKeys.SETTINGS.STAFF.ACTIVE)} />
            )}
          />
          {isActive && (
            <>
              <hr />
              {constants.user_roles.map((item, index) => {
                if (item.value !== 'admin' && isAdmin) return null;
                return (
                  <Controller
                    key={index}
                    control={control}
                    name={`permissions.${item.value}`}
                    render={({ field: { ref, ...values } }) => (
                      <SwitchInput {...values} label={item.description} />
                    )}
                  />
                );
              })}
            </>
          )}
        </div>
        <hr className={styles('content-separator')} />
        <div className={styles('role')}>
          <h3>{t(i18nKeys.SETTINGS.STAFF.RECEIVE_EMAIL)}</h3>
          <Controller
            control={control}
            name="preferences.emails.daily"
            render={({ field: { ref, ...values } }) => (
              <SwitchInput {...values} noMargin label={t(i18nKeys.SETTINGS.STAFF.DAILY)} />
            )}
          />
          <hr />
          <Controller
            control={control}
            name="preferences.emails.weekly"
            render={({ field: { ref, ...values } }) => (
              <SwitchInput {...values} noMargin label={t(i18nKeys.SETTINGS.STAFF.WEEKLY)} />
            )}
          />
          <hr />
          <Controller
            control={control}
            name="preferences.emails.notifications"
            render={({ field: { ref, ...values } }) => (
              <SwitchInput {...values} noMargin label={t(i18nKeys.SETTINGS.STAFF.NOTIFICATION)} />
            )}
          />
        </div>
      </div>
      <div className={styles('button-wrapper')}>
        <Button noShadow noMargin label={t(i18nKeys.SAVE)} type="submit" />
      </div>
    </form>
  );
}
