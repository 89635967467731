import { useEffect } from 'react';
import { isNullish, partition } from 'remeda';
import { useLoadLightUsers, useUpdateReport } from 'shared/hooks';
import { Report } from 'shared/hooks/use-load-report';

import { Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';

import { RecipientsStep } from '../../CreateReport/steps';

interface EditReportRecipientsModalProps {
  isOpen: boolean;
  onClose: () => void;
  report: Report;
}

export const EditReportRecipientsModal = ({
  isOpen,
  onClose,
  report,
}: EditReportRecipientsModalProps) => {
  const { updateReport, isUpdateReportLoading } = useUpdateReport();
  const { users } = useLoadLightUsers();

  const form = useForm({
    initialValues: {
      collaborators: [] as Array<string>,
      emails: [] as Array<string>,
    },
  });

  useEffect(() => {
    if (isNullish(users)) return;

    const userEmails = users.map(({ email }) => email);
    const [collaborators, emails] = partition(report.emails, (email) =>
      userEmails?.includes(email),
    );

    form.setValues({
      collaborators,
      emails,
    });
  }, [users]);

  // TODO: add the same validation as for the wizard steps
  const handleSave = () => {
    updateReport(
      {
        id: report.id,
        emails: [...form.values.emails, ...form.values.collaborators],
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Modal opened={isOpen} onClose={onClose} title="Edit name" size="auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <RecipientsStep form={form} />
        <Group w="100%" justify="end" mt="xl">
          <Button variant="light" color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" loading={isUpdateReportLoading}>
            Save
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
