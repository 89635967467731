import { CreateWorkflowBody, PlanType, StepType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { StoreState } from 'types/storeTypes';

import { WorkflowStepsTarget } from '../../Workflows.constants';
import { getWorkflowRights, isAfterDueDate, isBeforeDueDate } from '../WorkflowForm.utils';

import { WorkflowFormStepsItemDelay } from './WorkflowFormStepsItemDelay';
import { WorkflowFormStepsItemDetail } from './WorkflowFormStepsItemDetail';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsItemProps = {
  planType: PlanType;
  name: WorkflowStepsTarget;
  index: number;
  edit: () => void;
  remove: () => void;
};

export const WorkflowFormStepsItem = ({
  planType,
  name,
  index,
  edit,
  remove,
}: WorkflowFormStepsItemProps) => {
  const { t } = useTranslation();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const { canEditStep, canDeleteStep } = getWorkflowRights(company, planType);

  const { watch, setValue } = useFormContext<CreateWorkflowBody>();

  const { id, delay, step_type, _destroy } = watch(`${name}.${index}`);

  const isFirstReminder = step_type === StepType.first_reminder;

  // TODO: For RecoveryPlan
  // const isIntermediateReminder = step_type === StepType.intermediate_reminder;
  // const isLastReminder = step_type === StepType.last_reminder;

  // const invoicesCount = undefined;
  // const lateFeesStep = undefined;

  const handleRemove = (i) => (id ? setValue(`${name}.${i}._destroy`, true) : remove());

  if (_destroy) return <></>;

  return (
    <>
      {isAfterDueDate(name) && (
        <WorkflowFormStepsItemDelay
          onClick={canEditStep ? edit : undefined}
          delay={delay}
          top
          after
        >
          {/* {lateFeesStep && (isIntermediateReminder || isLastReminder) && (
            <>
              <RadioButton
                canRemove
                noMargin
                value={lateFeesStep}
                name="fee_starting_step"
                items={[{ value: 1, label: t(i18nKeys.LATE_FEES) }]}
              />
            </>
          )} */}
        </WorkflowFormStepsItemDelay>
      )}
      <WorkflowFormStepsItemDetail name={name} index={index}>
        <button
          type="button"
          className={styles('opacity')}
          title={t(i18nKeys.EDIT)}
          disabled={!canEditStep}
          onClick={canEditStep ? edit : undefined}
        >
          <Icon name={IconName.PENCIL} />
        </button>
        {!isFirstReminder && (
          <button
            type="button"
            className={styles('red', 'opacity')}
            title={t(i18nKeys.DELETE)}
            disabled={!canDeleteStep}
            onClick={() => (canDeleteStep ? handleRemove(index) : undefined)}
          >
            <Icon name={IconName.TRASH_SIMPLE} />
          </button>
        )}
        {/* {Number.isInteger(invoicesCount) && (
          <div
            className={styles('info')}
            title={t(i18nKeys.PLAN.INVOICES_EXECUTED_STEP)}
          >
            {invoicesCount}
            <Icon name={IconName.INVOICE} color={IconColor.RED} />
          </div>
        )} */}
      </WorkflowFormStepsItemDetail>
      {isBeforeDueDate(name) && (
        <WorkflowFormStepsItemDelay
          onClick={canEditStep ? edit : undefined}
          delay={delay}
          bottom
          before
        />
      )}
    </>
  );
};
