import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKey, i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import HTML from 'shared/components/HTML/HTML';
import { Button } from 'shared/io';

import { Integration } from '../Integrations.constants';

import styleIdentifiers from './IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export type IntegrationFormContainerProps<Y extends object> = {
  integration: Integration;
  form: UseFormReturn<Y, object>;
  onSubmit: (e: React.SyntheticEvent) => Promise<void>;
  summary?: i18nKey;
  connectUrl?: string;
};

export const IntegrationForm = <T extends object>({
  integration,
  form,
  onSubmit,
  summary,
  children,
  connectUrl,
}: PropsWithChildren<IntegrationFormContainerProps<T>>) => {
  const { t } = useTranslation();
  const { errorMessage } = qs.parse(location.search);
  const errors = !Array.isArray(errorMessage) ? [errorMessage] : errorMessage;

  return (
    <FormProvider<T> {...form}>
      <form className={styles('integration-form')} onSubmit={onSubmit}>
        {summary && <HTML className={styles('summary')} html={t(summary)} />}
        {children}
        {!!errors.length && (
          <div className={styles('errors-messages')}>
            {errors.map((e, i) => (
              <div key={`error-${i}`}>
                <span className={styles('error-message')}>{e}</span>
              </div>
            ))}
          </div>
        )}
        <div className={styles('button-wrapper')}>
          {connectUrl ? (
            <Button
              className={styles('connect-button')}
              onClick={() => window.open(connectUrl, '_blank')}
              label={t(i18nKeys.FORM.INTEGRATION.CONNECT_ACCOUNT, {
                text: integration,
              })}
            />
          ) : (
            <Button label={t(i18nKeys.FORM.VALIDATE)} type="submit" />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
