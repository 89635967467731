import { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { last, omit } from 'remeda';
import { Id } from 'types';
import { v4 } from 'uuid';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const DebtorBalanceParamsSchema = z.object({
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  types: z.array(z.string()),
  showSettled: z.boolean(),
});

const ApiAmountSchema = z.preprocess((val) => Number(val ?? 0), z.number());

const MatchedCreditNoteSchema = z.object({
  reference: z.string(),
  issueDate: z
    .string()
    .transform((date) => dayjs(date))
    .optional(),
  amount: ApiAmountSchema,
});

const PaymentSchema = z
  .object({
    amount: ApiAmountSchema,
    remainingBalance: ApiAmountSchema,
    date: z.string().transform((date) => dayjs(date)),
    communication: z.string().nullable(),
  })
  .transform((data) => ({
    ...omit(data, ['date']),
    issueDate: data.date,
    id: v4(),
  }));

const MatchedPaymentSchema = z
  .object({
    amount: ApiAmountSchema,
    remainingBalance: ApiAmountSchema,
    date: z
      .string()
      .transform((date) => dayjs(date))
      .nullable(),
    communication: z.string().nullable(),
    isLoss: z.boolean(),
  })
  .transform((data) => ({
    ...omit(data, ['date']),
    issueDate: data.date,
  }));

const InvoiceSchema = z.object({
  id: z.number(),
  reference: z.string().nullable().default(''),
  paid: z.boolean(),
  totalTvac: ApiAmountSchema,
  totalHtva: ApiAmountSchema,
  remainingBalance: ApiAmountSchema,
  remainingLateFees: ApiAmountSchema,
  lateFees: ApiAmountSchema,
  penaltyClauseAmount: ApiAmountSchema,
  issueDate: z.string().transform((date) => dayjs(date)),
  dueDate: z.string(),
  status: z.string(),
  matchedPayments: z.array(MatchedPaymentSchema),
  creditNotes: z.array(MatchedCreditNoteSchema),
});

const CreditNoteSchema = InvoiceSchema.pick({
  id: true,
  reference: true,
  paid: true,
  totalTvac: true,
  totalHtva: true,
  remainingBalance: true,
  issueDate: true,
  dueDate: true,
  status: true,
}).extend({
  invoices: z.array(z.any()),
  payments: z.array(PaymentSchema).optional(),
});

const DebtorBalanceSchema = z.object({
  invoices: z.array(InvoiceSchema),
  payments: z.array(PaymentSchema),
  creditNotes: z.array(CreditNoteSchema),
});

export type DebtorBalance = z.infer<typeof DebtorBalanceSchema>;
export type DebtorBalanceInvoice = z.infer<typeof InvoiceSchema>;
export type DebtorBalancePayment = z.infer<typeof PaymentSchema>;
export type DebtorBalanceMatchedPayment = z.infer<typeof PaymentSchema>;
export type DebtorBalanceCreditNote = z.infer<typeof CreditNoteSchema>;
export type DebtorBalanceParams = z.infer<typeof DebtorBalanceParamsSchema>;

export async function loadDebtorBalanceFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;
  const showSettled = last(queryKey);

  const { data } = await instance.get(`/debtors/${queryKey[1]}/balance?showSettled=${showSettled}`);
  return DebtorBalanceSchema.parse(data);
}

export function useLoadDebtorBalance(debtorId: Id, showSettled?: boolean) {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<DebtorBalance, ApiError>({
    queryKey: ['debtors', debtorId, 'balance', showSettled ?? false] as const,
    queryFn: (context) => loadDebtorBalanceFn(axiosInstance, context),
    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<DebtorBalance, ApiError, 'debtorBalance'>(
    'debtorBalance',
    queryResult,
  );
}
