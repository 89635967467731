import { useSelector } from 'react-redux';
import { Company } from 'types/store/account-state';
import { Debtor } from 'types/store/client-state';
import { StoreState } from 'types/storeTypes';

export const getCgv = (company?: Company, debtor?: Debtor) =>
  company?.terms_and_conditions_attributes.find(
    (e) => Number(e.id) === Number(debtor?.terms_and_conditions_id),
  ) || company?.terms_and_conditions_attributes?.find((e) => e.default === true);

export const useGetConstants = () => useSelector((state: StoreState) => state.app.constants);

export const useGetDialogs = () => useSelector((state: StoreState) => state.view.dialogs);

export const useGetCompany = () => useSelector((state: StoreState) => state.account.company.data);

export const useGetViewsByResource = () =>
  useSelector((_state: StoreState) => _state.app.viewsByResource);

export const useGetCompanyState = () => useSelector((state: StoreState) => state.account.company);

export const useGetClientState = () => useSelector((state: StoreState) => state.client);

export const useGetInvoiceState = () => useSelector((state: StoreState) => state.invoice);

export const useGetThirdPartyCaseState = () =>
  useSelector((state: StoreState) => state.thirdpartyCase);
