import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import OnboardingItem from 'shared/components/OnboardingItem';
import PaymentForm from 'shared/forms/PaymentForm';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { StepProps } from '../OnBoarding.types';

import Twikey from './Twikey';

import styleIdentifiers from './paymentMethod.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function PaymentMethod({ onValidStep }: StepProps) {
  const { t } = useTranslation();

  const callback = () => {
    dialogHide(DialogShowId.CUSTOM);
    onValidStep();
  };

  const addStripe = (data) => {
    onBoardingActions.stripe({
      callback,
      data,
    });
  };

  const chooseStripe = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.CREDIT_CARD),
      children: <PaymentForm onSubmit={addStripe} />,
    });
  };

  const chooseTwikey = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.TITLE),
      children: <Twikey onboarding onSubmit={() => onValidStep()} />,
    });
  };

  const chooseLater = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      children: (
        <AYSModal
          text={t(i18nKeys.ONBOARDING[1].AYS_TO_NOT_ADD_PAYMENT_METHOD)}
          onConfirm={() => {
            onBoardingActions.noPayment({
              callback: () => {
                dialogHide(DialogShowId.CONFIRM);
                onValidStep();
              },
            });
          }}
        />
      ),
    });
  };

  return (
    <div className={styles('payment-method')}>
      <div className={styles('steps')}>
        <OnboardingItem
          title={t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.TITLE)}
          icon={IconName.DOMICILIATION}
          onClick={chooseTwikey}
          paddingBottom
          description={t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.DESCRIPTION)}
        />
        <OnboardingItem
          title={t(i18nKeys.CREDIT_CARD)}
          icon={IconName.CREDIT_CARD}
          onClick={chooseStripe}
          paddingBottom
          description={t(i18nKeys.ONBOARDING[1].CREDIT_CARD.DESCRIPTION)}
        />
        <OnboardingItem
          title={t(i18nKeys.ADD_LATER)}
          icon={IconName.LATER}
          onClick={chooseLater}
          paddingBottom
        />
      </div>
    </div>
  );
}
