import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useLocation, useParams } from 'react-router';
import { isEmpty, isNonNull, isNullish } from 'remeda';
import { useGetCompany } from 'shared/utils/selectors';

import { Box, Button, Center, Divider, Group, Loader, Stack, Text, Image } from '@mantine/core';
import {
  IconArrowRight,
  IconExternalLink,
  IconPlugConnected,
  IconQuestionMark,
} from '@tabler/icons-react';

import { useLoadMonitoringData } from '../../../shared/hooks/use-load-monitoring-data';

import { ClientScore } from './components/ClientScore';
import { CreditLimit } from './components/CreditLimit';
import { MonitoringStatusActions } from './components/MonitoringStatusActions';
import { UpdateMonitoringDataButton } from './components/UpdateMonitoringDataButton';
import { WarningsOverview } from './components/WarningsOverview';

import CompanyWebLogo from '../../../shared/components/Integrations/images/companyweb.png';

type DebtorInfoProps = {
  hasVatNumber: boolean;
};

export const Monitoring = ({ hasVatNumber }: DebtorInfoProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const company = useGetCompany()!;
  const { id: debtorId } = useParams<{ id: string }>();
  const { monitoring, isMonitoringLoading, monitoringError } = useLoadMonitoringData(debtorId);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isExperimentalEnabled = queryParams.get('experimental') === 'true';

  const companyWebCredentials = company.company_web_credentials;
  const isMonitoringEnabled =
    isNonNull(companyWebCredentials.login) && isNonNull(companyWebCredentials.password);

  if (!company.package?.can_use_monitoring) {
    return (
      <Stack align="center">
        <Text>{t(i18nKeys.ACCOUNT_LIMITATION)}</Text>
        <Button onClick={() => window.Intercom('show')} leftSection={<IconQuestionMark />}>
          {t(i18nKeys.MORE_INFO)}
        </Button>
      </Stack>
    );
  }

  if (!isMonitoringEnabled) {
    return (
      <Stack align="center">
        <Text size="lg">{t(i18nKeys.DEBTOR.MONITORING.NOT_ENABLED)}</Text>
        <Button
          onClick={() => history.push('/settings/integrations')}
          leftSection={<IconPlugConnected stroke={1.5} />}
          rightSection={<IconArrowRight stroke={1.5} />}
        >
          {t(i18nKeys.DEBTOR.MONITORING.TO_SETTINGS)}
        </Button>
          <Group pt={10} w="100%" justify='right'><Image w={40} src={CompanyWebLogo} style={{ filter: 'grayscale(1)' }} /> Powered by CompanyWeb</Group>

      </Stack>
    );
  }

  if (monitoringError) {
    return (
      <Stack align="center">
        <Text size="lg">
          {monitoringError?.response?.data?.error_message ?? monitoringError.message}
        </Text>
      </Stack>
    );
  }

  if (isMonitoringLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (isNullish(monitoring)) {
    return (
      <Stack align="center">
        <Text size="lg">{t(i18nKeys.DEBTOR.MONITORING.NOT_EXISTING)}</Text>
        <Box w={150} mt="lg">
          <UpdateMonitoringDataButton hasVatNumber={hasVatNumber} debtorId={Number(debtorId)} />
        </Box>
      </Stack>
    );
  }

  return (
    <Stack>
      <MonitoringStatusActions
        isExperimentalEnabled={isExperimentalEnabled}
        isMonitoringActive={monitoring.isMonitoringActive}
        updatedAt={monitoring.updatedAt}
        hasVatNumber={hasVatNumber}
        debtorId={debtorId}
      />
      <Center mt="lg">
        <Stack w={{ base: '100%', md: '95%', lg: '90%', xl: '80%' }}>
          <Group justify="space-between" align="flex-start" gap={20}>
            <Box w={{ base: '100%', md: '45%' }}>
              <ClientScore score={monitoring.score} />
              <Center>
                <Button
                  variant="light"
                  component="a"
                  href={monitoring.reportUrl}
                  mt="xs"
                  target="_blank"
                  rightSection={<IconExternalLink size={16} stroke={1.5} />}
                >
                  {t(i18nKeys.DEBTOR.MONITORING.FULL_REPORT)}
                </Button>
              </Center>
            </Box>
            <Divider orientation="vertical" />
            <Box w={{ base: '100%', md: '45%' }}>
              <CreditLimit creditLimit={monitoring.creditLimit} />
            </Box>
          </Group>
          {!isEmpty(monitoring.warnings) && (
            <>
              <Divider w="100%" my="xl" />
              <Center>
                <WarningsOverview
                  warnings={monitoring.warnings}
                  warningsURL={monitoring.warningsUrl}
                />
              </Center>
            </>
          )}
          <Group w="100%" justify='right'><Image src={CompanyWebLogo} style={{ filter: 'grayscale(1)' }} /></Group>
        </Stack>
      </Center>
    </Stack>
  );
};
