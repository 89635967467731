import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon, IconColor, IconName } from 'shared/components/Icon';

import { SendingMediaIcon } from '../SendingMedium';

import { RecoveryPlanStepLineProps } from './RecoveryPlanStep.types';

import styleIdentifiers from './RecoveryPlan.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const RecoveryPlanStepLine = ({
  index,
  step,
  editMode,
  editStep,
  deleteStep,
}: RecoveryPlanStepLineProps) => {
  const { t } = useTranslation();

  const { sending_media, template, step_type, fee_step_reached, invoices_count, task_attributes } =
    step;

  return (
    <div className={styles('step')}>
      <div className={styles('step-template')}>
        <div className={styles('template')}>
          <div className={styles('template-name')}>
            {template ? template.name : task_attributes.title}
          </div>
          <div className={styles('template-type')}>
            {t(
              template
                ? i18nKeys.TEMPLATE_TYPES[template.step_type.toUpperCase()]
                : i18nKeys.TASK_GENERATION,
            )}
          </div>
          {fee_step_reached && (
            <div className={styles('late-fees')}>{t(i18nKeys.LATE_FEES_INCLUDED)}</div>
          )}
        </div>
        {template ? (
          <div className={styles('actions')} onClick={editStep && editStep!(index)}>
            {sending_media.map(({ name, force_sending }, i) =>
              i === 0 ? (
                <div className={styles('action')} key={`${name}-${i}`}>
                  <Icon name={SendingMediaIcon[name]} />
                </div>
              ) : (
                <React.Fragment key={`${name}-${i}`}>
                  <span>{t(String(force_sending) === 'true' ? i18nKeys.AND : i18nKeys.OR)}</span>
                  <div className={styles('action')}>
                    <Icon name={SendingMediaIcon[name]} className={styles('ml-4')} />
                  </div>
                </React.Fragment>
              ),
            )}
          </div>
        ) : (
          task_attributes.mandatory === 'true' && (
            <div className={styles('action', 'red')} title={t(i18nKeys.TASK.ATTRIBUTES.MANDATORY)}>
              <Icon name={IconName.ALERT_CIRCLE} />
            </div>
          )
        )}
      </div>
      <div className={styles('line-action')}>
        {editMode && (
          <>
            <div
              className={styles('action', 'add')}
              onClick={editStep!(index, typeof task_attributes !== 'undefined')}
            >
              <Icon name={IconName.PENCIL} />
            </div>
            {step_type !== 'first_reminder' && deleteStep && (
              <div className={styles('action', 'red')} onClick={deleteStep(index)}>
                <Icon name={IconName.TRASH_SIMPLE} />
              </div>
            )}
          </>
        )}
        <div
          className={styles('action', 'invoice')}
          title={t(i18nKeys.PLAN.INVOICES_EXECUTED_STEP)}
        >
          {invoices_count || 0} <Icon name={IconName.INVOICE} color={IconColor.RED} />
        </div>
      </div>
    </div>
  );
};
