import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor } from 'shared/io';
import { formatCommunication } from 'shared/utils/normalization';
import { downloadFile, formattedDate, removeAttributes } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DebtorReactionInvoice } from 'types/debtor';
import { AttributesType } from 'types/genericType';
import { OnClickFunction } from 'types/html-type';

import DebtorReactionForm from './DebtorReactionForm';

import styleIdentifiers from './InvoiceDetailDebtor.module.scss';

const styles = classNames.bind(styleIdentifiers);

interface AdditionalFile {
  id: number | string;
  file_name: string;
}

type InvoiceDetailDebtorProps = {
  isInvoicesTabActive: boolean;
  invoice: DebtorReactionInvoice;
  payInvoice?: OnClickFunction;
  credit?: boolean;
  canUseOnlinePayment?: boolean;
  isFrenchClient?: boolean;
  canUseDigiteal?: boolean;
};

export default function InvoiceDetailDebtor({
  invoice,
  isInvoicesTabActive,
  payInvoice,
  credit,
  canUseOnlinePayment,
  isFrenchClient = true,
  canUseDigiteal = false,
}: InvoiceDetailDebtorProps) {
  const { t, currentLang } = useTranslation();
  const [additionalFiles, setAdditionalFiles] = useState<Array<AdditionalFile>>([]);

  useEffect(() => {
    invoiceActions.getAdditionalFiles({
      id: invoice.id,
      noFeedback: true,
      data: {
        document_type: 'invoice_additional_pdf',
        token: invoice.debtor_document_token,
      },
      callback: ({ data }) => {
        setAdditionalFiles(data.map((file: AttributesType) => removeAttributes(file)));
      },
    });
  }, []);

  const downloadAdditionalFile = (additionalFile: AdditionalFile) => () => {
    invoiceActions.document({
      id: invoice.id,
      noLoading: true,
      public: true,
      fileUpload: true,
      document_type: 'invoice_additional_pdf',
      data: {
        token: invoice.debtor_document_token,
        additional_file_id: additionalFile.id,
      },
      callback: (data: any) => {
        downloadFile(data, additionalFile.file_name);
      },
    });
  };

  const handleDownload = (type: 'pdf' | 'xml') => () => {
    invoiceActions.document({
      public: true,
      id: invoice.id,
      data: {
        token: invoice.debtor_document_token,
      },
      type: type === 'xml' ? 'invoice_ubl' : 'invoice_pdf',
    });
  };

  const comment = ({ comment: _comment }: any) => {
    invoiceActions.debtorReactionComment({
      id: invoice.id,
      data: { comment: _comment, token: invoice.debtor_reaction_token },
      success: i18nKeys.DEBTOR.SUCCESSFULLY_COMMENT,
      callback: () => {},
    });
  };

  const renderInfo = (text, info) => (
    <div className={styles('key-text')}>
      <div className={styles('attribute')}>{t(text)}: </div>
      <div className={styles('value')}>{info}</div>
    </div>
  );

  return (
    <div className={styles('download-document')}>
      <div className={styles('head')}>{invoice.reference}</div>
      <div className={styles('content')}>
        {credit ? (
          <>
            <h3>{t(i18nKeys.DEBTOR.REACTION.CREDIT_NOTE_DETAILS)}</h3>
            {renderInfo(i18nKeys.FORM.REFERENCE, invoice.reference)}
            {Array.isArray(invoice.linked_invoices_references) && (
              <>
                {renderInfo(
                  i18nKeys.INVOICE.LINK_INVOICES,
                  invoice.linked_invoices_references.toString(),
                )}
              </>
            )}
            {renderInfo(i18nKeys.ISSUE_DATE, formattedDate(invoice.issue_date, currentLang))}
            {renderInfo(
              i18nKeys.INVOICE.DISCOUNT,
              <Amount
                localizedValue={invoice.total_discount_amount}
                value={invoice.localized_money_object.total_discount_amount}
                suffix={invoice.currency}
                debtor={invoice.debtor}
              />,
            )}
            {renderInfo(
              i18nKeys.TOTAL_NOT_VAT_PRICE,
              <Amount
                localizedValue={invoice.total_htva}
                value={invoice.localized_money_object.total_htva}
                suffix={invoice.currency}
                debtor={invoice.debtor}
              />,
            )}
            {renderInfo(
              i18nKeys.TOTAL_VAT_PRICE,
              <Amount
                localizedValue={invoice.total_tvac}
                value={invoice.localized_money_object.total_tvac}
                suffix={invoice.currency}
                debtor={invoice.debtor}
              />,
            )}
            {renderInfo(
              i18nKeys.BALANCE,
              <Amount
                localizedValue={invoice.remaining_balance}
                value={invoice.localized_money_object.remaining_balance}
                suffix={invoice.currency}
                debtor={invoice.debtor}
              />,
            )}
          </>
        ) : (
          <>
            <h3>{t(i18nKeys.INVOICE.DETAILS)}</h3>
            {renderInfo(i18nKeys.ISSUE_DATE, formattedDate(invoice.issue_date, currentLang))}
            {!isInvoicesTabActive &&
              renderInfo(i18nKeys.DUE_DATE, formattedDate(invoice.due_date, currentLang))}
            {renderInfo(
              i18nKeys.TOTAL_VAT_PRICE,
              <Amount
                localizedValue={invoice.total_tvac}
                value={invoice.localized_money_object.total_tvac}
                suffix={invoice.currency}
                debtor={invoice.debtor}
              />,
            )}
            {renderInfo(
              i18nKeys.INVOICE.TOTAL_LATE_FEES,
              <Amount
                localizedValue={invoice.late_fees}
                value={invoice.localized_money_object.late_fees}
                suffix={invoice.currency}
                debtor={invoice.debtor}
              />,
            )}
            {!isInvoicesTabActive &&
              renderInfo(
                i18nKeys.BALANCE,
                <Amount
                  localizedValue={invoice.remaining_balance_with_fees}
                  value={invoice.localized_money_object.remaining_balance_with_fees}
                  suffix={invoice.currency}
                  debtor={invoice.debtor}
                />,
              )}
            {renderInfo(
              i18nKeys.COMMUNICATION,
              formatCommunication(invoice.structured_communication),
            )}
          </>
        )}
        {(invoice.has_pdf || invoice.has_ubl || (invoice.additional_files ?? []).length > 0) && (
          <>
            <hr className={styles('content-separator')} />
            <h3>{t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT)}</h3>

            {invoice.has_pdf && (
              <div className={styles('item')} onClick={handleDownload('pdf')}>
                <div>
                  <Icon name={IconName.PDF} className={styles('icon')} />
                  <span className={styles('content-txt')}>
                    {t(credit ? i18nKeys.CREDIT_NOTE : i18nKeys.COMMON.INVOICE)} (PDF)
                  </span>
                </div>
                <div>
                  <Icon name={IconName.DOWNLOAD} className={styles('icon')} />
                </div>
              </div>
            )}

            {invoice.has_ubl && (
              <div className={styles('item')} onClick={handleDownload('xml')}>
                <div>
                  <Icon name={IconName.XML} className={styles('icon')} />
                  <span className={styles('content-txt')}>
                    {t(credit ? i18nKeys.CREDIT_NOTE : i18nKeys.COMMON.INVOICE)} (UBL)
                  </span>
                </div>
                <div>
                  <Icon name={IconName.DOWNLOAD} className={styles('icon')} />
                </div>
              </div>
            )}

            {additionalFiles.map((additionalFile, index) => (
              <div
                key={index}
                onClick={downloadAdditionalFile(additionalFile)}
                className={styles('item')}
              >
                <Icon name={IconName.PDF} className={styles('icon')} />
                <span>{additionalFile.file_name} </span>
                <Icon name={IconName.DOWNLOAD} className={styles('icon')} />
              </div>
            ))}
          </>
        )}
        {!credit && (
          <>
            <hr className={styles('content-separator')} />
            <h3>{t(i18nKeys.INVOICE.COMMENT)}</h3>
            <DebtorReactionForm onSubmit={comment} />
          </>
        )}
      </div>
      {payInvoice && canUseOnlinePayment && (isFrenchClient ? canUseDigiteal : true) && (
        <div className={styles('button-wrapper')}>
          <Button
            className={styles('paid-button')}
            noMargin
            color={ButtonColor.GREEN}
            iconLeft={IconName.CREDIT_CARD}
            iconSize="20px"
            label={t(i18nKeys.DEBTOR.REACTION.PAY)}
            onClick={payInvoice}
          />
        </div>
      )}
    </div>
  );
}
