import { useContext } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import Percentage from 'shared/components/Percentage';

import InvoiceDetailContext from '../InvoiceDetail.context';

import styleIdentifiers from '../ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function InvoiceDebtorSituation() {
  const { t } = useTranslation();

  const { data, clientData } = useContext(InvoiceDetailContext);

  const { terms_and_conditions, currency } = data;

  return (
    <Card className={styles('box')} title={t(i18nKeys.INVOICE.CLIENT_SUMMARY)}>
      <div className={styles('item')}>
        <span className={styles('subtitle')}>{t(i18nKeys.INVOICE.AVERAGE_AUTHORIZED_DELAY)}</span>
        <div className={styles('value', 'bigger', 'blue')}>
          {`${clientData?.average_payment_delay || '-'} ${t(i18nKeys.DAY)} / ${
            terms_and_conditions?.minimum_payment_terms_delay || '-'
          } ${t(i18nKeys.DAY)}`}
        </div>
      </div>
      <div className={styles('item')}>
        <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.DETAIL.LATE_PAYMENT_RATE)}</span>
        <Percentage
          value={clientData?.delay_rate || 0}
          className={styles('value', 'bigger', 'blue')}
        />
      </div>
      <div className={styles('item')}>
        <span className={styles('subtitle')}>{t(i18nKeys.INVOICE.REVENUES)}</span>
        <Amount
          value={clientData?.financial_data?.annual_ca}
          suffix={currency}
          className={styles('value', 'blue', 'bigger')}
        />
      </div>
      <div className={styles('item')}>
        <span className={styles('subtitle')}>{t(i18nKeys.INVOICE.AMOUNT_DUE)}</span>
        <Amount
          value={clientData?.financial_data?.overdue_amount}
          suffix={currency}
          className={styles('value', 'bigger')}
        />
      </div>
    </Card>
  );
}
