import { Id } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import moment from 'moment';
import Conversation from 'shared/components/ActivityDetail/Conversation';
import { EmailDeliveryStatusCompact } from 'shared/components/ActivityDetail/EmailDeliveryStatusCompact';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';
import { Activity } from 'types/store/activity';
import { NextStepInformation } from 'types/store/invoice-state';
import { MetaData } from 'types/storeTypes';

import ToHandleDetail from '..';

import styleIdentifiers from './InvoiceTimeline.module.scss';

const styles = classNames.bind(styleIdentifiers);

const activityType = {
  payment: 'wallet',
  comment: 'comment',
  callcenter: 'phone-call',
  pause: 'pause',
  lawyer: 'law',
  bailiff: 'balance',
  formal_notice: 'formal-notice',
  preference: 'sending-options',
  reminders: 'send',
  import: 'import-file',
  warning: 'alert-circle-exc',
  task: 'info',
  time: 'later',
  valid: 'check',
  update: 'customization',
};

type Props = {
  activities: Activity[];
  nextAction?: any | NextStepInformation;
  metadata?: MetaData;
  seeMore?: OnClickFunction;
  invoiceId?: number;
  onClickActivity?: (id: Id) => void;
};

export default function InvoiceTimeline({
  activities,
  nextAction,
  metadata,
  seeMore,
  invoiceId,
  onClickActivity,
}: Props) {
  const { t, currentLang } = useTranslation();

  const downloadDocument = (item) => () => {
    invoiceActions.document({
      id: item.tracked_id,
      type: item.document_type,
      ...(item.document_id ? { document_id: item.document_id } : {}),
    });
  };

  const showCreditNote = (item: Activity) => () => {
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail isCreditNote id={item.linked_credit_note_id} modal />,
    });
  };

  const showConversation = (activity: Activity) => () => {
    const title = t(
      (activity.activity_type === 'lawyer' && i18nKeys.INVOICE.LAWYER_CASE) ||
        (activity.activity_type === 'callcenter' && i18nKeys.INVOICE.CALLCENTER_CASE) ||
        (activity.tracked_type === 'Invoice' && i18nKeys.ACTIVITIES.UPDATE_STATUS_INVOICE) ||
        i18nKeys.NO_TEXT,
    );

    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title,
      children: (
        <Conversation
          className={styles('conversation')}
          conversation={activity.invoice_feedback!}
          invoiceId={invoiceId}
          onSubmit={() => {}}
        />
      ),
    });
  };

  const getGapDate = (index: number) => {
    if (index + 1 !== activities.length) {
      const currentActivity = moment(activities[index].date).startOf('day');
      const nextActivity = activities[index + 1].date;
      if (!currentActivity.isSame(nextActivity, 'day')) {
        return `+ ${t(i18nKeys.DAYS, {
          count: Math.ceil(moment.duration(currentActivity.diff(nextActivity)).asDays()),
        })}`;
      }
    }
    return '';
  };

  if (activities == null) return null;

  return (
    <Card
      title={`${t(i18nKeys.INVOICE.ACTIVITIES)}`}
      className={styles('invoice-timeline')}
      classContent={styles('card-body')}
    >
      {nextAction && (
        <div className={styles('activity', 'next-action')}>
          <div className={styles('info')}>
            <div className={styles('author')}>{t(i18nKeys.INVOICE.NEXT_STEP)}</div>
            <div>{formattedDate(new Date(), currentLang)}</div>
          </div>
          <div className={styles('timeline')}>
            <div className={styles('circle')} />
            <div className={styles('line')} />
          </div>

          <div className={styles('description')}>
            <HTML html={nextAction} />
          </div>
        </div>
      )}
      {activities.map((activity, index) => (
        <div key={activity.id} className={styles('activity')}>
          <div className={styles('info')}>
            <div className={styles('author')}>{activity.author}</div>
            <div>{formattedDate(activity.date, currentLang, 'DD/MM/YYYY, LT')}</div>
          </div>
          <div className={styles('timeline')}>
            <div className={styles('circle')}>
              <Icon name={activityType[activity.activity_type] || 'invoice'} />
            </div>
            <div className={styles('line')}>
              <span className={styles('gap-date')}>{getGapDate(index)}</span>
            </div>
          </div>

          <div
            className={styles('description', onClickActivity != null && 'clickable')}
            onClick={() => onClickActivity?.(activity.id)}
          >
            <HTML html={activity.description} />
            {activity.comment && (
              <span>
                <span className={styles('subtitle')}>{t(i18nKeys.COMMENT)}</span> :{' '}
                <span className={styles('comment')}>{activity.comment}</span>
              </span>
            )}
            {activity.reason && (
              <span>
                <span className={styles('subtitle')}>{t(i18nKeys.REASON)}</span> :{' '}
                <span className={styles('comment')}>{activity.reason}</span>
              </span>
            )}
            {activity.end_pause_at && (
              <div>
                <span className={styles('subtitle')}>{t(i18nKeys.END_OF_PAUSE)}</span> :{' '}
                <DateItem lg={currentLang} date={activity.end_pause_at} />
              </div>
            )}
            {activity.payment_information && (
              <div className={styles('payment-info')}>
                <div>
                  <b>{t(i18nKeys.AMOUNT)} : </b>
                  <Amount
                    localizedValue={activity.payment_information.amount}
                    value={activity.payment_information.localized_money_object.amount}
                    suffix={activity.payment_information.currency}
                  />
                </div>
                <div>
                  <b>{t(i18nKeys.DATE)} : </b>
                  <DateItem lg={currentLang} date={activity.payment_information.date} />
                </div>
                <div>
                  <b>{t(i18nKeys.MODALITY)} : </b>
                  <span>{activity.payment_information.modality}</span>
                </div>
                {activity.payment_information.notes && (
                  <div>
                    <b>{t(i18nKeys.NOTES)} : </b>
                    <span>{activity.payment_information.notes}</span>
                  </div>
                )}
                {activity.payment_information.counterparty_name && (
                  <div>
                    <b>{t(i18nKeys.ACTIVITIES.COUNTERPART)} : </b>
                    <span>{activity.payment_information.counterparty_name}</span>
                  </div>
                )}
                {activity.payment_information.external_id && (
                  <div>
                    <b>{t(i18nKeys.ACTIVITIES.EXTERNAL_ID)} : </b>
                    <span>{activity.payment_information.external_id}</span>
                  </div>
                )}
              </div>
            )}
            <div className={styles('more')}>
              {activity.linked_credit_note_id && (
                <Button
                  className={styles('show-conversation')}
                  iconRight={IconName.EYE}
                  onClick={(e) => {
                    e.stopPropagation();
                    showCreditNote(activity)();
                  }}
                  label={t(i18nKeys.ACTIVITIES.SEE_CREDIT_NOTE)}
                  small
                  noMargin
                  color={ButtonColor.WHITE}
                />
              )}
              {activity.invoice_feedback && (
                <Button
                  className={styles('show-conversation')}
                  iconRight={IconName.EYE}
                  onClick={(e) => {
                    e.stopPropagation();
                    showConversation(activity)();
                  }}
                  label={t(i18nKeys.SHOW_CONVERSATION)}
                  small
                  noMargin
                  color={ButtonColor.WHITE}
                />
              )}
              {activity.email_statuses && (
                <EmailDeliveryStatusCompact statuses={activity.email_statuses} />
              )}
              {activity.has_document && (
                <Icon
                  name={IconName.PDF}
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadDocument(activity)();
                  }}
                  className={styles('download-document')}
                  title={t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT)}
                />
              )}
            </div>
          </div>
        </div>
      ))}

      {metadata?.pagination.current_page !== metadata?.pagination.last_page && (
        <div className={styles('see-more')} onClick={seeMore}>
          {t(i18nKeys.SEE_MORE)} <Icon name={IconName.ARROW_BOTTOM_ROUNDED} />
        </div>
      )}
    </Card>
  );
}
