import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import { Product } from 'types/store/product-state';

import { InvoiceFormSharedProps } from '../InvoiceForm.types';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ProductItemProps = InvoiceFormSharedProps & {
  item: Product;
};

export const ProductItem = ({
  item: { name, price_htva },
  company,
  constants,
  currency,
}: ProductItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles('product-item')}>
      <div>{name}</div>
      <div>
        {t(i18nKeys.EXCL_VAT_PRICE)}:{' '}
        {formatAmount(price_htva, ',', '.', 2, currencySymbol(currency, company))}
      </div>
    </div>
  );
};
