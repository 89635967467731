import React from 'react';

import { Box, Group } from '@mantine/core';

interface DetailRowProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const DetailRow = ({ left, right }: DetailRowProps) => (
  <Group w="100%">
    <Box ta="right" flex={1}>
      {left}
    </Box>
    <Box flex={1}>{right}</Box>
  </Group>
);
