import classNames from 'classnames/bind';
import { omit } from 'remeda';
import { useGetDialogs } from 'shared/utils/selectors';

import Modal from '../Modal';

import styleIdentifiers from './modals.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function Modals() {
  const dialogs = useGetDialogs();

  return (
    <div className={styles('modals')}>
      {Object.keys(omit(dialogs, ['count'])).map((key) => (
        <Modal dialog={{ ...dialogs[key] }} key={key} />
      ))}
    </div>
  );
}
