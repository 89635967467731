import { ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { InfiniteScrollCustom } from 'shared/components/InfiniteScroll';
import { useCombinedRefs, usePrevious } from 'shared/utils/hooks';
import { validationMessage } from 'shared/utils/validation';
import { compareItems } from 'store/reducers';
import { OnChangeEvent } from 'types/html-event';

import styleIdentifiers from './CustomSelect.module.scss';

const styles = classNames.bind(styleIdentifiers);

type CustomSelectProps = {
  canAdd?: boolean;
  canUnselect?: boolean;
  className?: string;
  CustomInput?: Function;
  customInputClassname?: string;
  debounce?: number;
  defaultValue?: any;
  errorClassName?: string;
  filter?: boolean;
  forceCleaningSelectTag?: boolean;
  hideOnLoading?: boolean;
  infiniteScroll?: boolean;
  info?: string;
  inputValidation?: Function;
  itemClassName?: string;
  itemRendering?: Function;
  items: any[];
  itemsClassName?: string;
  keyText?: string;
  keyValue?: string;
  label?: ReactNode;
  listClassName?: string;
  load?: Function;
  multiple?: boolean;
  name: string;
  noArrow?: boolean;
  noBorder?: boolean;
  noError?: boolean;
  noMargin?: boolean;
  noSuggestions?: boolean;
  noTags?: boolean;
  notAllowed?: boolean;
  onChange?: (value: any) => void;
  onChangeFilter?: (value: string) => void;
  onInputChange?: Function;
  onSelectItem?: Function;
  onValueChanged?: (value: any) => void;
  placeholder?: string;
  placeholderFilter?: string;
  registerMultipleInput?: any;
  removeAll?: string;
  selectClassName?: string;
  shadow?: boolean;
  size?: string;
  tabIndex?: number;
  valueClassName?: string;
  valueRendering?: Function;
  white?: boolean;
  withBorder?: boolean;
};

const reducer = (prevState: any, updatedProperty: any) => ({
  ...prevState,
  ...updatedProperty,
});

let tmpCursor;

export const CustomSelect = ({
  canAdd,
  canUnselect = true,
  className,
  CustomInput,
  customInputClassname,
  debounce,
  errorClassName,
  filter,
  forceCleaningSelectTag = false,
  hideOnLoading,
  infiniteScroll,
  info,
  inputValidation,
  itemClassName,
  itemRendering,
  items,
  itemsClassName,
  keyText,
  keyValue,
  label,
  listClassName,
  load,
  multiple,
  name,
  noArrow,
  noBorder,
  noError,
  noMargin,
  noSuggestions,
  noTags,
  notAllowed,
  onChange,
  onChangeFilter,
  onInputChange,
  onSelectItem,
  onValueChanged,
  placeholder,
  placeholderFilter,
  registerMultipleInput,
  removeAll,
  selectClassName,
  shadow,
  size,
  tabIndex,
  valueClassName,
  valueRendering,
  white,
  withBorder,
  ...rest
}: CustomSelectProps) => {
  const { t } = useTranslation();
  const [state, setState] = useReducer(reducer, {
    value: {
      text: '',
      value: multiple ? [] : null,
    },
    isDropdownActive: false,
    cursor: -1,
    list: [],
    filter: null,
  });
  const { value, isDropdownActive, cursor, loading, filterValue, list } = state;

  const prevState = usePrevious<any>(state)!;

  const [timeoutDebounce, setTimeoutDebounce] = useState();
  const {
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
  } = useFormContext();

  const error = !noError && (get(errors, name) || get(errors, `${name}input`));
  const inputVal = watch(name) || '';

  useEffect(() => {
    handleValue();
  }, [state.list, inputVal]);

  useEffect(() => {
    handleItems();
  }, [items, state.filterValue]);

  useEffect(() => {
    onChangeFilter?.(filterValue);
  }, [filterValue]);

  useEffect(() => {
    if (
      load &&
      isDropdownActive &&
      !infiniteScroll &&
      (filter
        ? prevState.filterValue !== state.filterValue || !prevState.isDropdownActive
        : JSON.stringify(prevState.value.text) !== JSON.stringify(state.value.text) ||
          !prevState.isDropdownActive)
    ) {
      getList(filter ? state.filterValue : value.text);
    }
  }, [state.isDropdownActive, JSON.stringify(state.value), state.filterValue]);

  useEffect(() => {
    if (isDropdownActive) {
      tmpCursor = cursor;
      document.addEventListener('keydown', handleKey);
      document.addEventListener('mouseup', handleClickOutside);
    } else {
      document.removeEventListener('keydown', handleKey);
      document.removeEventListener('mouseup', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleKey);
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [isDropdownActive, inputVal, list.length]);

  useEffect(() => {
    if (!isDropdownActive) return;

    if (filter) {
      requestAnimationFrame(() => {
        filterRef.current?.focus();
      });
    } else if (CustomInput && customInputRef.current) customInputRef.current!.focus();
    else if (multiple && !valueRendering && canAdd) multipleInputRef.current!.focus();
  }, [isDropdownActive]);

  const node = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLInputElement>(null);
  const multipleInputRef = useRef<HTMLInputElement>(null);
  const customInputRef = useRef<HTMLInputElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);
  const combinedRefs = useCombinedRefs(registerMultipleInput?.ref, multipleInputRef);

  // MARK: - Handle key
  const handleKey = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();

        tmpCursor = tmpCursor !== list.length - 1 ? tmpCursor + 1 : list.length - 1;
        if (tmpCursor > 0) itemsRef.current!.scrollTop = itemsRef.current!.scrollTop + 47;

        setState({ cursor: tmpCursor });
        break;
      case 'ArrowUp':
        event.preventDefault();

        tmpCursor = tmpCursor !== (removeAll ? -1 : 0) ? tmpCursor - 1 : tmpCursor;
        if (tmpCursor < items.length - 1)
          itemsRef.current!.scrollTop = itemsRef.current!.scrollTop - 47;

        setState({ cursor: tmpCursor });
        break;
      case 'Enter':
        event.preventDefault();

        if (tmpCursor < list.length) {
          if (removeAll && tmpCursor === -1) selectValue([]);
          else if (tmpCursor >= 0) selectItem(list[tmpCursor]);
        } else {
          tmpCursor = -1;
          setState({ cursor: -1 });
        }
        break;
      case 'Escape':
        setIsDropdownActive(false);
        break;
      default:
        break;
    }
  };

  const getList = (valueToSearch: { text: string }) => {
    if (load && isDropdownActive) {
      setState({ loading: true });

      if (timeoutDebounce) clearTimeout(timeoutDebounce);
      const timeout = setTimeout((): void => load(valueToSearch), debounce || 500);
      setTimeoutDebounce(timeout as any);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (isDropdownActive && !node?.current!.contains(event.target as any)) {
      setIsDropdownActive(false);
    }
  };

  const handleItems = () => {
    const newItems =
      filterValue && !infiniteScroll
        ? (items || []).filter(
            (it) =>
              (it[keyText as any] || it)
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .indexOf(
                  filterValue
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, ''),
                ) !== -1,
          )
        : items || [];

    setState({
      loading: false,
      list: newItems.map((it) => ({
        text: it[keyText as any] ?? it,
        value: it[keyValue as any] ?? it,
        item: it,
      })),
    });
  };

  // MARK: - Handle value
  const handleValue = (val?: any) => {
    let stateValue;
    let newValue;

    if (multiple) {
      stateValue = [];
      newValue = val || Array.isArray(inputVal) ? inputVal : [inputVal];

      if (canAdd) stateValue = newValue || [];
      else {
        list.forEach((item) => {
          newValue.forEach((inputItem) => {
            if (compareItems(inputItem, item)) {
              stateValue.push(item);
            }
          });
        });
      }

      setState({
        value: {
          value: stateValue,
          text: value?.text || '',
        },
      });

      return;
    }

    newValue = val || inputVal;
    stateValue = list.find((item) => compareItems(val || inputVal, item));
    const newStateValue = stateValue || {
      text: (keyText && newValue?.[keyText]) || newValue,
      value: (keyValue && newValue?.[keyValue]) || newValue,
    };

    if (
      (!CustomInput && stateValue) ||
      !(CustomInput && newStateValue?.text === state.value.text)
    ) {
      setState({ value: newStateValue });

      if (CustomInput && customInputRef?.current)
        customInputRef.current.value = newStateValue.value;
    }
  };

  const hasValue = () => (multiple ? inputVal?.length > 0 : !!inputVal);

  const toggleIsDropdownActive = () => setIsDropdownActive(!isDropdownActive);

  const setIsDropdownActive = (newValue: boolean) => setState({ isDropdownActive: newValue });

  const editValue = (_value) => {
    setValue(name, _value, { shouldDirty: true });
    onValueChanged?.(_value);
    onChange?.(_value);
  };

  // MARK: - Select value
  const selectValue = (item: any) => {
    if (multiple) {
      const current: any[] = (() => {
        if (Array.isArray(inputVal)) return inputVal.slice();
        if (inputVal) return [inputVal];
        return [];
      })();

      const foundItemIndex = current.findIndex((it) => it === item.value);

      if (foundItemIndex !== -1) {
        current.splice(foundItemIndex, 1);
        editValue([...current]);
      } else {
        const newValue = item.value ?? item;
        current.push(newValue);
        editValue([...current]);
        if (forceCleaningSelectTag) setState({ value: { ...value, text: '' } });
      }
      return;
    }

    if ((inputVal === item?.value || inputVal === item) && canUnselect) {
      editValue(null);
      setState({ cursor: -1 });
    } else {
      editValue(item.value);

      const cursorPlace = list.findIndex((it) => it.value === item.value);
      setState({ cursor: load ? -1 : cursorPlace });
      if (customInputRef) customInputRef.current?.setAttribute('value', item.text);
    }
    setIsDropdownActive(false);
  };

  const compare = (_value, item) => {
    if (!item || !_value) return false;
    if (JSON.stringify(item) === JSON.stringify(_value)) return true;
    if (_value === item) return true;
    if (item.id && item.id === _value) return true;
    if (item.value && item.value === _value) return true;
    if ((item.id && item.id === _value.id) || (item.value && item.value === _value.value))
      return true;
    return false;
  };

  const onMultipleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ value: { ...value, text: e.target.value } });
  };

  const addTag = () => {
    const inputError = inputValidation ? inputValidation(value.text) : false;
    if (inputError && inputError !== true) {
      setError(name, { type: 'custom', message: inputError });
    } else {
      clearErrors(name);
      selectValue(value.text);
      setState({ errors: null });
    }
  };

  const removeTags = (index: number) => {
    const newValue = value.value;
    if (canAdd) {
      newValue.splice(index, 1);
      editValue(newValue);
    } else selectValue(value.value[index]);
  };

  const handleKeyDown = (e: OnChangeEvent) => {
    const text = value?.text.trim();
    if (e.key === 'Enter' || e.keyCode === 32) {
      e.preventDefault();
      if (text) addTag();
    }
  };

  const checkItem = (item) => {
    if (multiple) return value.value.find((it) => compare(it, item));
    return compare(value, item);
  };

  const selectItem = (item) => {
    selectValue(item);
    onSelectItem?.(item);
  };

  const onFilterChange = (e: OnChangeEvent) => {
    const val = e.currentTarget.value;
    setState({ filterValue: val });
  };

  const onCustomInputChange = (val) => {
    setValue(name, val);
    onInputChange?.(val);
  };

  const renderItems = (children) =>
    infiniteScroll ? (
      <InfiniteScrollCustom
        fwRef={itemsRef}
        className={styles('items-wrapper', listClassName)}
        load={getList}
      >
        {children}
      </InfiniteScrollCustom>
    ) : (
      <div ref={itemsRef} className={styles('items-wrapper', listClassName)}>
        {children}
      </div>
    );

  // MARK: - Render
  return (
    <div
      ref={node}
      data-cy={name}
      onClick={toggleIsDropdownActive}
      onBlur={(e) => {
        if (e.relatedTarget) setIsDropdownActive(false);
      }}
      className={styles(
        'CustomSelect',
        selectClassName,
        withBorder && 'with-border',
        isDropdownActive && !notAllowed && 'active',
        noMargin && 'no-margin',
        'normal',
        shadow && 'shadow',
        noArrow && 'no-arrow',
        error && 'error',
      )}
    >
      {label && <div className={styles('label-input')}>{label}</div>}
      {multiple && !noTags && (
        <div className={styles('multiple')}>
          <div className={styles('values-wrapper')}>
            {value?.value.map((it, index) => (
              <div key={index}>
                <span>{it?.text || (keyText && it[keyText as any]) || it}</span>
                <Icon
                  name={IconName.SMALL_REMOVE}
                  title={t(i18nKeys.DELETE)}
                  onClick={() => removeTags(index)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        className={styles(
          'input-wrapper',
          'with-icon',
          className,
          white && 'white',
          'normal',
          !noBorder && 'bordered',
          withBorder && 'with-border',
          shadow && 'shadow',
          error && 'error',
          error && errorClassName,
          noMargin && 'no-margin',
          hasValue() && 'visited',
          notAllowed && 'not-allowed',
        )}
      >
        <div
          className={styles(
            'value',
            'no-padding',
            multiple && 'multiple',
            notAllowed && 'disabled-value',
            valueClassName,
          )}
        >
          {(() => {
            if (CustomInput) {
              return (
                <CustomInput
                  {...rest}
                  inputRef={customInputRef}
                  noMargin
                  defaultValue={state.value.text}
                  noBorder
                  noError
                  className={styles('custom-input', customInputClassname)}
                  placeholder={placeholder}
                  size={size}
                  name={name}
                  onValueChanged={onCustomInputChange}
                  autoComplete="off"
                />
              );
            }

            if (valueRendering) {
              if (!value || !(value.value || value.text)) {
                return <span className={styles('placeholder')}>{placeholder}</span>;
              }
              return valueRendering(value);
            }

            if (multiple) {
              if (canAdd) {
                return (
                  <input
                    {...registerMultipleInput}
                    ref={combinedRefs}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    placeholder={placeholder}
                    value={value?.text || ''}
                    onChange={onMultipleTextChanged}
                  />
                );
              }
              return <span className={styles('placeholder')}>{placeholder}</span>;
            }

            if (!value || !(value.value || value.text)) {
              return <span className={styles('placeholder')}>{placeholder}</span>;
            }

            return value.text;
          })()}
        </div>
        {!noSuggestions && (
          <div
            className={styles('items', size, itemsClassName, {
              'with-filter': filter,
            })}
          >
            {filter && (
              <input
                ref={filterRef}
                tabIndex={tabIndex}
                className={styles('filter')}
                name="filter_input"
                placeholder={placeholderFilter || t(i18nKeys.ACTIONS.FILTER)}
                autoComplete="off"
                onChange={onFilterChange}
              />
            )}
            {(!hideOnLoading || !loading) &&
              renderItems(
                <>
                  {removeAll && (
                    <div className={styles('item')} onClick={() => editValue(multiple ? [] : '')}>
                      {removeAll}
                    </div>
                  )}
                  {list.map((item: any, key: any) => (
                    <div
                      className={styles(
                        'item',
                        checkItem(item) && 'active',
                        itemClassName,
                        cursor === key && 'hoverArrow',
                      )}
                      key={key}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectItem(item);
                      }}
                    >
                      {itemRendering ? itemRendering(item) : item.text}
                    </div>
                  ))}
                </>,
              )}

            {loading
              ? !infiniteScroll && <div className={styles('item')}>{t(i18nKeys.LOADING)}</div>
              : list.length === 0 && <div className={styles('item')}>{t(i18nKeys.NO_RESULT)}</div>}
          </div>
        )}
        <div className={styles('icon', 'arrow')}>
          {multiple &&
            value.value &&
            (value.text ? (
              <Icon name={IconName.PLUS} className={styles('add-icon')} onClick={addTag} />
            ) : (
              value.value.length > 1 && <div className={styles('indic')}>{value.value.length}</div>
            ))}
          {!noArrow && (
            <Icon name={IconName.ARROW_BOTTOM_ROUNDED} className={styles('arrow-icon')} />
          )}
        </div>
      </div>
      {info && <div className={styles('info')}>{info}</div>}
      {error && (
        <div className={styles('error-message')}>
          {t(error.message || validationMessage[error.type])}
        </div>
      )}
    </div>
  );
};
