import { PropsWithChildren } from 'react';
import { RecoveryStepTaskAttributes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon, IconName } from 'shared/components/Icon';

import { isTrue } from '../WorkflowForm.utils';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsItemDetailTaskProps = {
  task: RecoveryStepTaskAttributes;
};

export const WorkflowFormStepsItemDetailTask = ({
  task,
  children,
}: PropsWithChildren<WorkflowFormStepsItemDetailTaskProps>) => {
  const { t } = useTranslation();
  const { title, mandatory } = task;

  return (
    <div className={styles('description')}>
      <div>
        <h3>{title}</h3>
        <p>{t(i18nKeys.TASK_GENERATION)}</p>
      </div>
      {isTrue(mandatory) && (
        <div className={styles('info', 'red')} title={t(i18nKeys.TASK.ATTRIBUTES.MANDATORY)}>
          <Icon name={IconName.ALERT_CIRCLE} />
        </div>
      )}
      {children}
    </div>
  );
};
