import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLoadLightUsers } from 'shared/hooks';
import { Button, ButtonColor, CustomSelect } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { User } from 'types/store/colleague-state';

import UserInfo from '../UserInfo';

import styleIdentifiers from './ChooseCollaboratorModal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ChooseCollaboratorModalProps = {
  onSubmit: Function;
  initialValues?: User | null;
};

// TODO: Too close in functionality to AccountManagerSelector. Refactor to reuse code.
const ChooseCollaboratorModal = ({ onSubmit, initialValues }: ChooseCollaboratorModalProps) => {
  const { t } = useTranslation();
  const { users = [] } = useLoadLightUsers();
  const { isLoading: isInvoiceLoading } = useGetInvoiceState()!;
  const { isLoading: isClientLoading } = useGetClientState()!;
  const isLoading = isInvoiceLoading || isClientLoading;

  const form = useForm({
    shouldUnregister: true,
    defaultValues: { account_manager_id: initialValues?.id },
  });

  return (
    <div className={styles('choose-collaborator-modal')}>
      <FormProvider {...form}>
        <Controller
          defaultValue=""
          name="account_manager_id"
          render={() => (
            <CustomSelect
              noMargin
              selectClassName={styles('select')}
              itemRendering={(props) => <UserInfo picture {...props} />}
              valueRendering={(props) => <UserInfo inline {...props} />}
              keyValue="id"
              items={users}
              name="account_manager_id"
            />
          )}
        />
      </FormProvider>
      <div className={styles('button-wrapper')}>
        <Button
          isLoading={isLoading}
          label={t(i18nKeys.DELETE_ACCOUNT_MANAGER)}
          color={ButtonColor.GREY}
          onClick={() => {
            onSubmit({ account_manager_id: null });
          }}
        />
        <Button
          isLoading={isLoading}
          label={t(i18nKeys.CONFIRM)}
          onClick={() => {
            onSubmit(form.getValues());
          }}
        />
      </div>
    </div>
  );
};

export default ChooseCollaboratorModal;
