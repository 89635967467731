import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AddressBlock from 'shared/components/AddressBlock';
import TvaNumber from 'shared/components/TvaNumber';

import { InvoiceFormSharedProps } from '../InvoiceForm.types';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type CompanyDetailsProps = InvoiceFormSharedProps;

export const CompanyDetails = ({ company }: CompanyDetailsProps) => {
  const { t } = useTranslation();

  const { name, address_attributes, vat_number } = company;

  return (
    <div className={styles('company-detail')}>
      <div className={styles('name')}>{name}</div>
      <AddressBlock address={address_attributes} />
      <br />
      <div>
        {t(i18nKeys.VAT)} : <TvaNumber value={vat_number} />
      </div>
    </div>
  );
};
