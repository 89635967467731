import { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const ReportSchema = z.object({
  id: z.string(),
  name: z.string().min(1),
  periodicity: z.object({
    type: z.enum(['weekly', 'monthly']),
    days: z.array(z.number()),
  }),
  lastExecutedAt: z
    .string()
    .nullable()
    .transform((data) => (data == null ? data : dayjs(data))),
});

// The bare report is not exported because its definition conflicts with the full Report type defined in use-load-report.ts
const ReportsSchema = z
  .object({
    exportTasks: z.array(ReportSchema),
  })
  .transform((data) => data.exportTasks);

export type Reports = z.infer<typeof ReportsSchema>;

export async function loadReportsQueryFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/reports/export_tasks');
  return ReportsSchema.parse(data);
}

export function useLoadReports() {
  const axiosInstance = useAxiosInstance();
  const queryResult = useQuery<Reports, ApiError>({
    queryKey: ['reports'],
    queryFn: () => loadReportsQueryFn(axiosInstance),
  });

  return addResourceNameToQueryResult<Reports, unknown, 'reports'>('reports', queryResult);
}
