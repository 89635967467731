import { removeAttributes } from 'shared/utils/view';
import { Activity } from 'types/store/activity';

export const treatActivity = (item: any): Activity => {
  const activity = removeAttributes(item);
  if (activity.invoice_feedback) {
    activity.invoice_feedback = removeAttributes(activity.invoice_feedback);
    activity.invoice_feedback.conversation = removeAttributes(
      activity.invoice_feedback.conversation,
    );
    activity.invoice_feedback.conversation.messages =
      activity.invoice_feedback.conversation.messages.map((message) => removeAttributes(message));
  }
  return activity;
};
