import { BaseApiActionsType, buildActions, makeResourceConstants } from 'store/actions';

const prefix = 'thirdparty_case';

export const thirdPartyCaseConstants = {
  ...makeResourceConstants(prefix),
};
type ThirdPartyCaseActions = BaseApiActionsType;

export const thirdPartyCaseActions = buildActions<ThirdPartyCaseActions>(
  '',
  thirdPartyCaseConstants,
  {},
);
