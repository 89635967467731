import { AxiosInstance } from 'axios';
import { ResourceType } from 'types/resource-types';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export async function removeFavoriteViewFn(axiosInstance: Promise<AxiosInstance>, { viewId }) {
  const instance = await axiosInstance;
  return instance.put(`views/${viewId}/remove-favorite`);
}

export function useRemoveFavoriteView() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<
    ApiResponse,
    ApiError,
    { viewId: string; resourceType: ResourceType }
  >({
    mutationFn: (variables) => removeFavoriteViewFn(axiosInstance, variables),
    onSuccess: (response, { resourceType }) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['views', resourceType],
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    { viewId: string; resourceType: ResourceType },
    'removeFavoriteView'
  >('removeFavoriteView', mutation);
}
