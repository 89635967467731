import { useCreateWorkflow } from 'api';
import { CreateWorkflowBody, PlanType } from 'api/models';
import classNames from 'classnames/bind';
import { useHistory, useRouteMatch } from 'react-router';

import { WorkflowForm } from './WorkflowForm';
import { formatValues } from './WorkflowForm.utils';

import styleIdentifiers from './WorkflowForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const WorkflowFormAdd = () => {
  const {
    params: { planType },
  } = useRouteMatch<{ planType: PlanType }>();
  const history = useHistory();

  const { mutate: createWorkflow, isPending } = useCreateWorkflow();

  const onSubmit = (data: CreateWorkflowBody) =>
    createWorkflow(
      { data: formatValues(data) },
      {
        onSuccess: (response) => {
          history.push(`/settings/workflows/${planType}/edit/${response.data.id}`);
        },
      },
    );

  return (
    <div className={styles('workflows-add')}>
      <WorkflowForm planType={planType} onSubmit={onSubmit} isLoading={isPending} />
    </div>
  );
};
