import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isDefined } from 'remeda';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import ProductForm from 'shared/forms/ProductForm';
import { Button } from 'shared/io';
import { productActions } from 'store/product/product.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import ProductListItem from './ProductListItem';

import styleIdentifiers from './listing.module.scss';

const styles = classNames.bind(styleIdentifiers);
const EnhancedTable = CustomTable(ProductListItem);

export default function Listing() {
  const history = useHistory();

  const { t } = useTranslation();
  const productData = useSelector((state: StoreState) => state.product.list);
  if (!isDefined(productData)) return null;

  const TABLE_HEADERS = [
    {
      key: 'id',
      title: 'id',
      width: 40,
    },
    {
      key: 'name',
      title: t(i18nKeys.INVOICE.PRODUCT),
    },
    {
      key: 'description',
      title: t(i18nKeys.DESCRIPTION),
    },
    {
      width: 120,
      key: 'price_htva',
      title: t(i18nKeys.TOTAL_NOT_VAT_PRICE),
    },
    {
      width: 130,
      key: 'vat_rate',
      title: t(i18nKeys.INVOICE.VAT_RATE),
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  const newProduct = () => {
    sideMenuShow({
      unmount: true,
      content: <ProductForm onSubmit={submitProduct} />,
    });
  };

  const submitProduct = (values: any) => {
    productActions.add({
      data: values,
      callback: () => {
        // handleUpdate();
        history.push('/products');
        sideMenuHide();
      },
    });
  };

  return (
    <div className={styles('Listing')}>
      <EnhancedTable
        notSortable
        title={t(i18nKeys.INVOICING.PRODUCT.TITLE)}
        actions={
          <Button
            className={styles('action-button')}
            noMargin
            label={t(i18nKeys.NEW_PRODUCT)}
            onClick={newProduct}
            iconRight={IconName.MINIMAL_RIGHT}
          />
        }
        loadFunc={productActions.listPage}
        loading={productData.loading}
        loaded={productData.loaded}
        pagination={productData.metadata && productData.metadata.pagination}
        headers={TABLE_HEADERS}
        items={productData.pages || []}
        showShadow
        noCheckbox
      />
    </div>
  );
}
