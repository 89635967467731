import { OnBoarding } from 'app/Private/Onboarding';
import { i18nKeys, useTranslation } from 'locales';
import { capitalize } from 'lodash-es';
import qs from 'query-string';
import { Redirect, Route, Switch } from 'react-router';
import { useAuthenticate } from 'shared/hooks';
import { success } from 'store/view/view.actions';

export const OnboardingWrapper = () => {
  const { t } = useTranslation();
  const { canProceedIntoAuthenticatedDomain } = useAuthenticate();

  if (!canProceedIntoAuthenticatedDomain) return null;

  return (
    <Switch>
      <Route path="/" component={OnBoarding} />
      <Route
        path="/callback"
        render={() => {
          const queryParams = qs.parse(location.search);
          const integration = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
          if (JSON.parse(qs.parse(location.search).successfulConnection as any)) {
            success({
              data: t(i18nKeys.FORM.SUCCESSFULLY_CONNEXION, {
                text: capitalize(integration),
              }),
            });
          }

          return (
            <Redirect
              to={`/onboarding?integration=${integration}&${
                queryParams.errorMessage ? `errorMessage=${queryParams.errorMessage}` : ''
              }`}
            />
          );
        }}
      />
    </Switch>
  );
};
