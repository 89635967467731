import { AxiosInstance, AxiosResponse } from 'axios';
import { z } from 'zod';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, useLegacyAxiosInstance } from './utils';

type BatchResponse = AxiosResponse<{ logs: BatchUpdateExternalMailResponse[] }>;

export enum ActionTypes {
  assign = 'assign',
  process = 'process',
  unprocess = 'unprocess',
  read = 'read',
  unread = 'unread',
  delete = 'delete',
}

const BatchUpdateExternalMailResponseSchema = z.object({
  id: z.number(),
  key: z.string(),
  message: z.string(),
  error_message: z.string().optional(),
});

const BatchUpdateExternalMailSchema = z.object({
  action_type: z.nativeEnum(ActionTypes),
  external_mail_ids: z.array(z.string()),
  debtor_id: z.string().optional(),
});

export type BatchUpdateExternalMail = z.infer<typeof BatchUpdateExternalMailSchema>;
export type BatchUpdateExternalMailResponse = z.infer<typeof BatchUpdateExternalMailResponseSchema>;

export async function batchUpdateExternalMails(
  axiosInstance: Promise<AxiosInstance>,
  data: BatchUpdateExternalMail,
) {
  const instance = await axiosInstance;
  return instance.post('external_mails/batch', BatchUpdateExternalMailSchema.parse(data));
}

export function useBatchUpdateExternalMails() {
  const client = useQueryClient();
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation<BatchResponse, ApiError, BatchUpdateExternalMail>({
    mutationFn: (data: any) => batchUpdateExternalMails(axiosInstance, data),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['externalMails'] });
    },
  });

  return addActionNameToMutationResult<
    BatchResponse,
    ApiError,
    BatchUpdateExternalMail,
    'batchUpdateExternalMails'
  >('batchUpdateExternalMails', mutation);
}
