import { useEffect } from 'react';
import { ContactPerson, CreateContactPersonBody, Notification } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import FormSection from 'shared/forms/FormSection';
import { Button, Checkbox, CustomSelect, Input } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import { DescVal } from 'types/storeTypes';

import { getInitialValues } from './ContactPersonForm.utils';
import { ContactPersonFormAddress } from './ContactPersonFormAddress';
import { ContactPersonFormEmails } from './ContactPersonFormEmails';
import { ContactPersonFormPhones } from './ContactPersonFormPhones';

import styleIdentifiers from './ContactPersonForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ContactPersonFormProps = {
  onSubmit: SubmitHandler<CreateContactPersonBody>;
  contactPerson?: ContactPerson;
  close?: () => void;
  inModal?: boolean;
};

export const ContactPersonForm = ({
  onSubmit,
  contactPerson,
  close,
  inModal,
}: ContactPersonFormProps) => {
  const { t } = useTranslation();

  const defaultValues = {
    ...getInitialValues(contactPerson),
  };

  const form = useForm<CreateContactPersonBody>({
    defaultValues,
  });

  const {
    watch,
    register,
    setValue,
    formState: { isDirty, errors },
    handleSubmit,
  } = form;

  useEffect(() => {
    sideMenuSetAskBeforeClose(isDirty);
  }, [isDirty]);

  const notificationsItems = Object.values(Notification)
    .filter((value) => value !== Notification.Debtor)
    .map((value) => ({
      description: t(i18nKeys.CONTACT_PERSON.NOTIFICATIONS[value.toUpperCase()]),
      value,
    })) as DescVal<Notification>[];

  register('notifications');

  const notifications = watch('notifications') || [];

  const onCheck = (value: Notification) => {
    setValue(
      'notifications',
      notifications.includes(value)
        ? notifications.filter((e) => e !== value)
        : [...notifications, value],
      { shouldTouch: true, shouldDirty: true, shouldValidate: true },
    );
  };

  return (
    <FormProvider {...form}>
      <form
        className={styles('contact-person-form', inModal && 'in-modal')}
        onSubmit={handleSubmit(onSubmit)}
      >
        {!inModal && (
          <div className={styles('head')}>
            {contactPerson ? t(i18nKeys.CONTACT_PERSON.EDIT) : t(i18nKeys.CONTACT_PERSON.ADD)}
            {close && (
              <div className={styles('close')} onClick={close}>
                <Icon name={IconName.SIMPLE_REMOVE} size="20px" />
              </div>
            )}
          </div>
        )}
        <div className={styles('content')}>
          <div className={styles('grid-row')}>
            <div className={styles('col-6')}>
              <Input
                label={t(i18nKeys.FORM.FIRSTNAME)}
                errorMessage={get(errors, 'first_name')}
                register={register('first_name', {
                  required: true,
                })}
                withBorder
                noMargin
              />
            </div>
            <div className={styles('col-6')}>
              <Input
                label={t(i18nKeys.FORM.LASTNAME)}
                errorMessage={get(errors, 'last_name')}
                register={register('last_name', {
                  required: true,
                })}
                withBorder
                noMargin
              />
            </div>
            <div className="col-6">
              <Checkbox
                label={t(i18nKeys.CONTACT_PERSON.MAIN_CONTACT)}
                errorMessage={get(errors, 'main_contact')}
                register={register('main_contact')}
                watch={watch}
              />
            </div>
            <div className="col-6">
              <Controller
                name="locale"
                render={() => (
                  <CustomSelect
                    label={t(i18nKeys.FORM.CLIENT.CONTACT_LANG)}
                    name="locale"
                    keyText="description"
                    keyValue="value"
                    selectClassName={styles('input')}
                    items={AVAILABLE_LANGUAGES}
                    withBorder
                  />
                )}
              />
            </div>
            <div className={styles('col-12')}>
              <Input
                label={t(i18nKeys.FORM.JOB_TITLE)}
                errorMessage={get(errors, 'job_title')}
                register={register('job_title')}
                withBorder
              />
            </div>
            <ContactPersonFormEmails />
            <ContactPersonFormPhones contactPerson={contactPerson} />
            <ContactPersonFormAddress contactPerson={contactPerson} />
            <FormSection title={t(i18nKeys.FORM.NOTIFICATIONS)}>
              {notificationsItems.map(({ value, description }) => (
                <Checkbox
                  key={value}
                  label={description}
                  checked={notifications.includes(value)}
                  onChange={() => {
                    onCheck(value);
                  }}
                />
              ))}
            </FormSection>
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button type="submit" label={t(i18nKeys.SAVE)} disabled={!isDirty} noMargin />
        </div>
      </form>
    </FormProvider>
  );
};
