import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, get, useFormContext } from 'react-hook-form';
import { CustomSelect, Input } from 'shared/io';

import styleIdentifiers from './AddressForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function AddressForm() {
  const { currentLang } = useTranslation();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const countriesForSelect = Object.entries(countries.getNames(currentLang)).map(
    ([value, description]) => ({ value, description }),
  );

  return (
    <>
      <div className={styles('col-6', 'street-name')}>
        <Input
          errorMessage={get(errors, 'addressAttributes.streetName')}
          className={styles('input')}
          withBorder
          register={register('addressAttributes.streetName', {
            required: true,
          })}
          type="text"
          label={t(i18nKeys.FORM.ADDRESS.STREET)}
        />
      </div>
      <div className={styles('col-3')}>
        <Input
          className={styles('input')}
          withBorder
          register={register('addressAttributes.streetNumber')}
          type="text"
          label={t(i18nKeys.NUMBER)}
        />
      </div>
      <div className="col-6">
        <Input
          errorMessage={get(errors, 'addressAttributes.zipCode')}
          className={styles('input')}
          withBorder
          register={register('addressAttributes.zipCode', {
            required: true,
          })}
          type="text"
          label={t(i18nKeys.FORM.ADDRESS.POSTAL_CODE)}
        />
      </div>
      <div className="col-6">
        <Input
          errorMessage={get(errors, 'addressAttributes.city')}
          className={styles('input')}
          withBorder
          register={register('addressAttributes.city', {
            required: true,
          })}
          type="text"
          label={t(i18nKeys.FORM.ADDRESS.CITY)}
        />
      </div>
      <div className="col-12">
        <Controller
          defaultValue=""
          name="addressAttributes.countryCode"
          rules={{ required: true }}
          render={() => (
            <CustomSelect
              selectClassName={styles('input')}
              keyText="description"
              keyValue="value"
              withBorder
              name="addressAttributes.countryCode"
              filter
              items={countriesForSelect}
              label={t(i18nKeys.FORM.ADDRESS.COUNTRY)}
            />
          )}
        />
      </div>
    </>
  );
}
