import { Comment } from 'api/models';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { useTranslation } from 'locales';
import DateItem from 'shared/components/DateItem';

import styleIdentifiers from './CommentItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type CommentProps = {
  comment: Comment;
};

export const CommentItem = ({ comment }: CommentProps) => {
  const { currentLang } = useTranslation();

  const {
    attributes: { date, author, comment: message },
  } = comment;

  return (
    <div className={styles('CommentItem')}>
      <div className={styles('comment-info')}>
        <span className={styles('date')}>
          <DateItem date={dayjs(date).toDate()} lg={currentLang} format="LLL" />
        </span>
        <div className={styles('from')}>{author}</div>
      </div>
      <div className={styles('comment')}>{message}</div>
    </div>
  );
};
