/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type StepType = (typeof StepType)[keyof typeof StepType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StepType = {
  preventive_reminder: 'preventive_reminder',
  first_reminder: 'first_reminder',
  intermediate_reminder: 'intermediate_reminder',
  last_reminder: 'last_reminder',
  task_generation: 'task_generation',
} as const;
