import classNames from 'classnames/bind';
import { useGetConstants } from 'shared/utils/selectors';

import styleIdentifiers from './statusItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  step: number;
  status?: string;
  className?: string;
  isLate?: boolean;
};
export default function StatusItem({ className, step, isLate, status }: Props) {
  const constants = useGetConstants();

  const description = constants.step_collection_names?.[step]?.description;

  // Sentry 171 - It seems this can happen when the private constants are not loaded yet,
  // probably through a race condition when navigating directly to this page from login.
  // Skipping this render and waiting for the request to complete should fix it.
  if (constants.step_collection_names == null) return null;

  return (
    <div className={styles('StatusItem', `step-${step + 1}`, className)}>
      <div className={styles('label')}>{status ?? description ?? ''}</div>
      <div className={styles('indicator')}>
        {(step > 0 || isLate) &&
          new Array(6)
            .fill(0)
            .map((it, key) => (
              <span
                key={it.id || key}
                className={styles('indic', step < 5 && key <= step && 'selected')}
              />
            ))}
      </div>
    </div>
  );
}
