import { removeAttributes } from 'shared/utils/view';
import {
  baseReducerInfinitePage,
  baseReducerListPage,
  flattenItem,
  handlePageRequest,
  handlePageResponse,
  handleResponse,
} from 'store/reducers';
import { transactionConstants as events } from 'store/transaction/transaction.actions';
import { ReduxAction } from 'types/redux';
import { Reconciliation, TransactionState } from 'types/store/transaction-state';

const initialState: TransactionState = {
  paymentHistory: baseReducerListPage,
  reconciliation: {
    invoices: baseReducerInfinitePage,
    payments: baseReducerInfinitePage,
    selectedInvoices: [],
    selectedPayments: [],
    loaded: false,
    loading: false,
  },
};

const handleMetadata = (
  type: 'invoices' | 'payments',
  reconciliation: Reconciliation,
  state: TransactionState,
  deleteCount,
) => {
  const newTotalObject = state.reconciliation[type].data.length - deleteCount;
  return {
    total: reconciliation.payments.metadata.total,
    pagination: {
      current_page: Math.ceil((state.reconciliation.payments.data.length - deleteCount) / 20),
      last_page: Math.ceil(newTotalObject / 20),
      total_objects: newTotalObject,
      page_limit: 20,
    },
  };
};

const handleSelectReconciliation = (state, action) => {
  const name = action.payload.type === 'invoice' ? 'invoices' : 'payments';
  const selectedName = action.payload.type === 'invoice' ? 'selectedInvoices' : 'selectedPayments';
  const selectedList: any[] = state.reconciliation[selectedName] || [];

  const list = state.reconciliation && state.reconciliation[name].data.slice();

  if (!list) return state;

  const listIndex = selectedList.findIndex((item) => item.id === action.payload.id);
  if (listIndex !== -1) {
    list.find((item) => item.id === action.payload.id).checked = false;
    selectedList.splice(listIndex, 1);
  } else {
    list.find((item) => item.id === action.payload.id).checked = true;
    selectedList.push(
      state.reconciliation[name].data.find((item) => item.id === action.payload.id),
    );
  }
  return {
    ...state,
    reconciliation: {
      ...state.reconciliation,
      [name]: {
        ...state.reconciliation[name],
        data: list,
      },
      [selectedName]: selectedList,
    },
  };
};

const handleManualResponseItem = (type: 'payments' | 'invoices', state, payload) => {
  const items = state.reconciliation[type].data.slice();

  let deletedItemsCount = 0;

  let elDeleted = 0;
  const itemsLength = items.length;
  for (let i = 0; i < itemsLength; i++) {
    if (items[i - elDeleted].inactive) {
      items.splice(i - elDeleted, 1);
      elDeleted++;
    }
  }

  payload[type].forEach((payloadItem) => {
    if (
      type === 'invoices'
        ? +payloadItem.attributes.remaining_late_fees +
            +payloadItem.attributes.remaining_balance ===
          0
        : +payloadItem.attributes.remaining_balance === 0
    ) {
      const removedItemIndex = items.findIndex((item) => payloadItem.id === item.id);
      items[removedItemIndex].inactive = true;

      deletedItemsCount++;
    } else {
      const index = items.findIndex((unpaid_invoice) => payloadItem.id === unpaid_invoice.id);
      items[index] = removeAttributes(payloadItem);
    }
  });

  return {
    items,
    deletedItemsCount,
  };
};

// In case of success
const handleManualResponse = (
  state: TransactionState,
  { payload, status },
): TransactionState | false => {
  if (status !== 'fulfilled') return state;

  const payments = handleManualResponseItem('payments', state, payload);
  const invoices = handleManualResponseItem('invoices', state, payload);

  const res = {
    ...state,
    reconciliation: {
      ...state.reconciliation,
      payments: {
        ...state.reconciliation.payments,
        data: !payments.items.some((payment) => !payment.inactive) ? [] : payments.items,
        metadata: handleMetadata(
          'payments',
          state.reconciliation,
          state,
          payments.deletedItemsCount,
        ),
      },
      invoices: {
        ...state.reconciliation.invoices,
        data: !invoices.items.some((invoice) => !invoice.inactive) ? [] : invoices.items,
        metadata: handleMetadata(
          'invoices',
          state.reconciliation,
          state,
          invoices.deletedItemsCount,
        ),
      },
      selectedInvoices: [],
      selectedPayments: [],
    },
  };

  return res;
};

const handleIgnorePayment = (
  state: TransactionState,
  { requestData },
): TransactionState | false => {
  const { reconciliation } = state;

  let payments = reconciliation.payments.data.slice();

  const ids: number[] = requestData.payment_ids;
  for (let index = payments.length - 1; index >= 0; index--) {
    if (payments[index].inactive) {
      payments.splice(index, 1);
    } else if (ids.indexOf(payments[index].id) !== -1) {
      payments[index] = {
        ...payments[index],
        inactive: true,
      };
    }
  }

  if (!payments.some((payment) => !payment.inactive)) {
    payments = [];
  }

  return {
    ...state,
    reconciliation: {
      ...reconciliation,
      selectedPayments: [],
      payments: {
        ...state.reconciliation.payments,
        data: payments,
        metadata: handleMetadata('payments', reconciliation, state, ids.length),
      },
    },
  };
};

const handleUnignorePayment = (
  state: TransactionState,
  { requestData },
): TransactionState | false => {
  const { paymentHistory } = state;

  const payments = paymentHistory.pages;

  const ids: number[] = requestData.payment_ids;
  for (const paymentId of ids) {
    payments.find((payment) => payment.id === paymentId)!.ignored = false;
  }

  return {
    ...state,
    paymentHistory: {
      ...paymentHistory,
    },
  };
};

/*
================================
HANDLE SELECTED ITEMS
================================
*/
const handleSelectedItems = (
  state: TransactionState,
  action,
  name: 'invoices' | 'payments',
): Reconciliation => {
  const selectedName = name === 'invoices' ? 'selectedInvoices' : 'selectedPayments';

  const newReconciliationState: Reconciliation = handlePageResponse(
    state.reconciliation,
    action,
    name,
    removeAttributes,
    { infiniteScroll: true },
  );

  for (const selectedItem of newReconciliationState[selectedName]) {
    const itemIndex = newReconciliationState[name].data.findIndex(
      (item) => selectedItem.id === item.id,
    );

    if (itemIndex === -1) {
      newReconciliationState[name].data.push(selectedItem as any);
    } else {
      newReconciliationState[name].data[itemIndex].checked = true;
    }
  }

  return newReconciliationState;
};

const resetReconciliationSelection = (state: TransactionState) => {
  const reconciliation = { ...state.reconciliation };

  reconciliation.selectedInvoices = [];
  reconciliation.selectedPayments = [];

  (reconciliation.invoices.data ?? []).forEach((invoice) => {
    invoice.checked = false;
  });

  (reconciliation.payments.data ?? []).forEach((payment) => {
    payment.checked = false;
  });

  return { ...state, reconciliation };
};

const reducer = (state: TransactionState = initialState, action: ReduxAction) => {
  switch (action.type) {
    // Payment history
    case events.paymentHistory.result:
      return handleResponse(state, action, 'paymentHistory', flattenItem);
    case events.paymentHistoryPage.request:
      return handlePageRequest(state, action, 'paymentHistory');
    case events.paymentHistoryPage.result:
      return handlePageResponse(state, action, 'paymentHistory', flattenItem);
    // reconciliation
    case events.getReconciliation.request:
      return {
        ...state,
        reconciliation: handlePageRequest(state.reconciliation, action, 'invoices'),
      };
    case events.getReconciliation.result:
      return {
        ...state,
        reconciliation: handleSelectedItems(state, action, 'invoices'),
      };
    case events.getReconciliationPayments.request:
      return {
        ...state,
        reconciliation: handlePageRequest(state.reconciliation, action, 'payments'),
      };
    case events.getReconciliationPayments.result:
      return {
        ...state,
        reconciliation: handleSelectedItems(state, action, 'payments'),
      };
    case events.selectReconciliation:
      return handleSelectReconciliation(state, action);
    case events.manualReconciliation.request:
      const res = handleManualResponse(state, action);
      if (res === false) return res;
      return {
        paymentHistory: res.paymentHistory,
        reconciliation: {
          ...res.reconciliation,
          loading: true,
        },
      };
    case events.manualReconciliation.result:
      const res2 = handleManualResponse(state, action);
      if (res2 === false) return res2;
      return {
        paymentHistory: res2.paymentHistory,
        reconciliation: {
          ...res2.reconciliation,
          loading: false,
        },
      };
    case events.ignorePayments.result:
      return handleIgnorePayment(state, action);
    case events.unignorePayments.result:
      return handleUnignorePayment(state, action);
    case events.resetReconciliationSelection.request:
      return resetReconciliationSelection(state);
    default:
      return state;
  }
};

export default reducer;
