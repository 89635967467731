import { Comments } from 'app/Private/Comments';
import { ContactPersonsList } from 'app/Private/ContactPersons';
import CustomVariables from 'app/Private/CustomVariables/CustomVariables';
import CustomVariablesForm from 'app/Private/CustomVariables/CustomVariablesForm/CustomVariablesForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { treatClient } from 'shared/serializer';
import { clientActions } from 'store/client/client.actions';
import { dialogHide, DialogShowId, showDialog, sideMenuShow } from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import { ClientDetailTabProps } from '../ClientDetail.types';
import { PaymentDelayModal } from '../PaymentDelayModal';

import { TabInfoDebtor } from './TabInfoDebtor';
import { TabInfoPayment } from './TabInfoPayment';

import styleIdentifiers from '../ClientDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const TabInfo = ({ debtor, refetch }: ClientDetailTabProps) => {
  const { t } = useTranslation();

  const {
    id,
    attributes: { custom_variables, comments },
  } = debtor;

  const editClient = (data: Partial<Debtor>) => {
    clientActions.update({
      data: {
        ...data,
        id,
      },
      callback: () => dialogHide(DialogShowId.CUSTOM),
    });
  };

  const handlePaymentDelay = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      title: t(i18nKeys.CLIENT.DETAIL.CHANGE_TERMS_AND_CONDITIONS),
      children: <PaymentDelayModal onSubmit={editClient} initialValues={treatClient(debtor)} />,
    });
  };

  const handleCustomVariables = () => {
    sideMenuShow({
      unmount: true,
      content: <CustomVariablesForm onSubmit={editClient} initialValues={treatClient(debtor)} />,
    });
  };

  const submitComment = (values) => {
    clientActions.comment({
      ...values,
      id,
      callback: refetch,
    });
  };

  return (
    <div className={styles('tab-item')}>
      <TabInfoDebtor debtor={treatClient(debtor)} reload={refetch} />
      {Object.keys(custom_variables).length > 0 && (
        <CustomVariables data={custom_variables} handleSettings={handleCustomVariables} />
      )}
      <TabInfoPayment debtor={treatClient(debtor)} handleSettings={handlePaymentDelay} />
      <Comments comments={comments} submitComment={submitComment} />
      <div className={styles('contact-card', 'card')}>
        <ContactPersonsList debtor={treatClient(debtor)} />
      </div>
    </div>
  );
};
