import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useForm } from 'react-hook-form';
import { Invitation, useSendInvitation } from 'shared/hooks';
import { Button, Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import { sideMenuHide } from 'store/view/view.actions';

import styleIdentifiers from './inviteCollaboratorForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function InviteCollaboratorForm() {
  const { t } = useTranslation();
  const { sendInvitation, isSendInvitationLoading } = useSendInvitation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Invitation>();

  return (
    <form
      className={styles('InviteCollaboratorForm')}
      onSubmit={handleSubmit((newUserData: Invitation) =>
        sendInvitation(newUserData, {
          onSuccess: sideMenuHide,
        }),
      )}
    >
      <div className={styles('head')}>{t(i18nKeys.FORM.INVITE_STAFF.TITLE)}</div>
      <div className={styles('content')}>
        <Input
          errorMessage={errors.firstName}
          className={styles('input')}
          withBorder
          noMargin
          register={register('firstName', { required: true })}
          type="text"
          label={t(i18nKeys.FORM.FIRSTNAME)}
        />
        <Input
          errorMessage={errors.lastName}
          className={styles('input')}
          withBorder
          register={register('lastName', { required: true })}
          type="text"
          label={t(i18nKeys.FORM.LASTNAME)}
        />
        <Input
          errorMessage={errors.email}
          className={styles('input')}
          withBorder
          register={register('email', {
            required: true,
            validate: {
              email,
            },
          })}
          type="text"
          label={t(i18nKeys.EMAIL)}
        />
      </div>
      <div className={styles('button-wrapper')}>
        <Button
          disabled={isSendInvitationLoading}
          noShadow
          noMargin
          label={t(i18nKeys.SAVE)}
          type="submit"
        />
      </div>
    </form>
  );
}
