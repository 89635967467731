import { App } from 'app';
import BadCompany from 'app/Public/BadCompany';
import { MaintenancePage } from 'app/Public/MaintenancePage';
import { ServerError } from 'app/Public/ServerError';
import { TenantValidator } from 'app/TenantValidator';
import { Providers } from 'init/Providers';
import { createRoot } from 'react-dom/client';
import { Route, Switch } from 'react-router';

import { createClient } from '@vercel/edge-config';

import './init';

const vercelEdgeConfigClient = createClient(
  'https://edge-config.vercel.com/ecfg_jzw3wg3c82bicsstshb6aokonddq?token=7576ac8f-20c5-4133-8424-4b320d10f510',
  { cache: 'force-cache' }, // The default setting for this value causes issues in dev mode. This works and I don't know why because their documentation is crap.
);

const render = async () => {
  const rootHtmlElement = document.getElementById('root');
  if (!rootHtmlElement) return;

  const root = createRoot(rootHtmlElement);

  const isMaintenanceMode = await vercelEdgeConfigClient.get('isMaintenanceMode');
  if (isMaintenanceMode) {
    root.render(
      <Providers>
        <MaintenancePage />
      </Providers>,
    );
    return;
  }

  root.render(
    <Providers>
      <Switch>
        <Route path="/server-error" component={ServerError} />
        <Route exact path="/bad-company" component={BadCompany} />
        <TenantValidator>
          <App />
        </TenantValidator>
      </Switch>
    </Providers>,
  );
};

render();
