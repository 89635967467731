import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import { ActionTypes, BatchUpdateExternalMail } from 'shared/hooks';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { ExternalMailBatchActionsAssign } from './ExternalMailBatchActionsAssign';

import styleIdentifiers from './ExternalMailBatchActions.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailBatchActionsProps = {
  emailIds: string[];
  onClick: (data: BatchUpdateExternalMail) => void;
};

export const ExternalMailBatchActions = ({
  emailIds: external_mail_ids,
  onClick,
  children,
}: PropsWithChildren<ExternalMailBatchActionsProps>) => {
  const { t } = useTranslation();

  const handleAssign = () =>
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.EXTERNAL_MAIL.ACTIONS.ASSIGN),
      children: (
        <ExternalMailBatchActionsAssign
          onSubmit={(data) =>
            onClick({
              external_mail_ids,
              action_type: ActionTypes.assign,
              ...data,
            })
          }
        />
      ),
    });

  const handleDelete = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS[`DELETE_EXTERNAL_MAIL${external_mail_ids.length > 1 ? 'S' : ''}`])}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            onClick({
              external_mail_ids,
              action_type: ActionTypes.delete,
            })
          }
        />
      ),
    });

  return (
    <Dropdown
      sideMenuInMobile
      sideMenuTitle={t(i18nKeys.INVOICE.OTHER_ACTIONS)}
      selectorContent={
        <Button
          noShadow
          noMargin
          label={t(i18nKeys.FORM.ACTIONS)}
          className={styles('button')}
          color={ButtonColor.BLUE}
          iconRight={IconName.MINIMAL_DOWN}
        />
      }
    >
      {Object.keys(ActionTypes).map((key) => (
        <div
          key={key}
          className={styles('dropdown-item')}
          onClick={() => {
            switch (key) {
              case ActionTypes.assign:
                handleAssign();
                break;
              case ActionTypes.delete:
                handleDelete();
                break;
              default:
                onClick({
                  external_mail_ids,
                  action_type: ActionTypes[key],
                });
                break;
            }
          }}
        >
          {t(i18nKeys.EXTERNAL_MAIL.ACTIONS[key.toUpperCase()])}
        </div>
      ))}
      {children}
    </Dropdown>
  );
};
