import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RouteChildrenProps } from 'react-router';
import { Button } from 'shared/io';
import { useGetCompany } from 'shared/utils/selectors';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import { PontoForm, PontoFormFields } from './PontoForm';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Ponto = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();

  const company = useGetCompany()!;

  const [isEditting, setIsEditting] = useState(false);

  const { isActive, canActivate } = getIntegrationRights(Integration.PONTO, company);

  useEffect(() => {
    if (!canActivate && !isActive) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, isActive, replace]);

  if (!company?.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  const handleSubmit = (data: PontoFormFields) => {
    onBoardingActions.ponto({
      settings: true,
      data,
      callback: () => {
        INTEGRATION_UPDATE_CALLBACK();
        setIsEditting(false);
      },
    });
  };

  return (
    <article className={styles('ponto')}>
      <IntegrationHeader name={Integration.PONTO} goBack={goBack} />
      {!isEditting && isActive ? (
        <>
          <div className={styles('row')}>
            <div className={styles('attribute')}>
              {t(i18nKeys.SETTINGS.INTEGRATIONS.PONTO.SECRET_CODE)}:
            </div>
            <div className={styles('value')}>
              *****{company.ponto_integration.ponto_client_secret}
            </div>
          </div>
          <div className={styles('row')}>
            <div className={styles('attribute')}>{t(i18nKeys.SETTINGS.CLIENT_ID)}:</div>
            <div className={styles('value')}>*****{company.ponto_integration.ponto_client_id}</div>
          </div>
          <div className={styles('button-wrapper')}>
            <Button label={t(i18nKeys.EDIT)} onClick={() => setIsEditting((current) => !current)} />
          </div>
        </>
      ) : (
        <PontoForm onSubmit={handleSubmit} />
      )}
    </article>
  );
};
