import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { UploadZone } from 'shared/io';

import styleIdentifiers from './ColorLogoPartialForm.module.scss';
import { ColorInput } from '@mantine/core';

const styles = classNames.bind(styleIdentifiers);

export default function ColorLogoPartialForm() {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div className={styles('color-logo-ions-partial-form')}>
      <div className={styles('col', 'company-logo')}>
        <h4>{t(i18nKeys.FORM.INVOICING.YOUR_LOGO)}</h4>
        <div className={styles('upload-zone')}>
          <Controller
            defaultValue=""
            name="logo"
            control={control}
            render={({ field: { ref, ...values } }) => (
              <UploadZone
                {...values}
                accept="image/jpeg, image/png"
                img
                logo
                info={t(i18nKeys.FORM.INVOICING.IMPORT_LOGO)}
                subinfo={t(i18nKeys.FORM.INVOICING.FILE_FORMAT)}
                errorMessage={t(i18nKeys.ERROR.INVALID_FILE_FORMAT)}
                small
                noMargin
                noResize
                error={errors.logo}
                showError
              />
            )}
          />
        </div>
      </div>
      <div className={styles('col', 'company-color')}>
        <h4>{t(i18nKeys.FORM.INVOICING.YOUR_COLOR)}</h4>
        <Controller
          name="color"
          defaultValue=""
          render={({ field: { ref, ...values } }) => <ColorInput {...values} />}
        />
      </div>
    </div>
  );
}
