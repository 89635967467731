import { useDeleteTask } from 'api';
import { Task } from 'api/models';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuShow,
} from 'store/view/view.actions';
import { TableItemProps } from 'types/table';

import { TaskSideMenu } from '../TaskForm/TaskSideMenu';

type EnhancedTableTask = Task & {
  checked: boolean;
};

type TaskListItemActionsProps = TableItemProps<EnhancedTableTask> & {
  refetch: () => void;
};

export const TaskListItemActions = ({ item, refetch }: TaskListItemActionsProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id } = item;

  const { mutate: deleteTaskMutation } = useDeleteTask();

  const updateTask = (e) => {
    e.stopPropagation();
    sideMenuShow({
      unmount: true,
      content: <TaskSideMenu taskId={item.id} refetch={refetch} />,
    });
  };

  const deleteTask = (e) => {
    e.stopPropagation();
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_TASK)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteTaskMutation(
              { id },
              {
                onSuccess: () => {
                  history.push('/tasks');
                  dialogHide(DialogShowId.CONFIRM);
                  refetch();
                },
              },
            )
          }
        />
      ),
    });
  };

  return (
    <>
      <Icon onClick={updateTask} name={IconName.SETTINGS_GEAR} size="18px" />
      <Icon onClick={deleteTask} name={IconName.TRASH_SIMPLE} size="18px" />
    </>
  );
};
