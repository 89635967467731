import { useState } from 'react';
import { UpdateFooterSchema, useLoadInvoiceFooter, useUpdateInvoiceFooter } from 'shared/hooks';

import { LoadingOverlay, SimpleGrid } from '@mantine/core';

import { EditFooterItemModal } from './EditFooterItemModal';
import { FooterItem } from './FooterItem';

export default function InvoiceFooterCustomization() {
  const { invoiceFooter, isInvoiceFooterLoading } = useLoadInvoiceFooter();
  const { updateInvoiceFooter } = useUpdateInvoiceFooter();
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);

  if (isInvoiceFooterLoading || invoiceFooter == null) return <LoadingOverlay visible />;

  const { fields } = invoiceFooter;

  return (
    <>
      <SimpleGrid cols={3} spacing="lg">
        {fields.map((item, index) => (
          <FooterItem key={index} item={item} onClickEdit={() => setSelectedItemIndex(index)} />
        ))}
      </SimpleGrid>
      <EditFooterItemModal
        onClose={() => setSelectedItemIndex(null)}
        isOpen={selectedItemIndex != null}
        item={selectedItemIndex != null ? fields[selectedItemIndex] : null}
        onSave={(newItem) =>
          updateInvoiceFooter(
            UpdateFooterSchema.parse(
              invoiceFooter.fields.toSpliced(selectedItemIndex!, 1, newItem),
            ),
            { onSuccess: () => setSelectedItemIndex(null) },
          )
        }
      />
    </>
  );
}
