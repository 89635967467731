import { Currency, PaymentSteps } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { currencySymbol } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';

import styleIdentifiers from './PaymentPlanDetailTable.module.scss';

const styles = classNames.bind(styleIdentifiers);

type PaymentPlanDetailTableProps = {
  paymentSteps: PaymentSteps[];
  currency?: Currency;
};

export const PaymentPlanDetailTable = ({ paymentSteps, currency }: PaymentPlanDetailTableProps) => {
  const { t, currentLang } = useTranslation();

  const totalAmount = paymentSteps.reduce((acc, { attributes: { amount } }) => acc + +amount, 0);

  const totalRemaining = paymentSteps.reduce(
    (acc, { attributes: { remaining_balance } }) => acc + +remaining_balance,
    0,
  );

  const totalPaid = totalAmount - totalRemaining;

  return (
    <Card
      title={t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.PAYMENT_STEPS)}
      className={styles('payment-steps')}
    >
      <table>
        <thead>
          <tr>
            <th>{t(i18nKeys.DATE)}</th>
            <th>{t(i18nKeys.INVOICE.DAY_OF_DELAY)}</th>
            <th>{t(i18nKeys.TOTAL_VAT_PRICE)}</th>
            <th>{t(i18nKeys.INVOICING.ALREADY_PAID)}</th>
            <th>
              {t(i18nKeys.BALANCE_CURRENCY, {
                text: currencySymbol(currency),
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {paymentSteps.map(
            ({ id, attributes: { amount, due_date, remaining_balance, days_late } }) => (
              <tr className={styles('step-line')} key={id}>
                <td>{formattedDate(due_date, currentLang)}</td>
                <td>{days_late || '-'}</td>
                <td>
                  <Amount localizedValue={amount} value={amount} suffix={currency} />
                </td>
                <td>
                  <Amount
                    localizedValue={amount - remaining_balance}
                    value={amount - remaining_balance}
                    suffix={currency}
                  />
                </td>
                <td>
                  <Amount
                    localizedValue={remaining_balance}
                    value={remaining_balance}
                    suffix={currency}
                  />
                </td>
              </tr>
            ),
          )}
        </tbody>
        {paymentSteps.length > 1 && (
          <tfoot>
            <tr className={styles('total-line')}>
              <td />
              <td />
              <td>
                <Amount localizedValue={totalAmount} value={totalAmount} suffix={currency} />
              </td>
              <td>
                <Amount localizedValue={totalPaid} value={totalPaid} suffix={currency} />
              </td>
              <td>
                <Amount localizedValue={totalRemaining} value={totalRemaining} suffix={currency} />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </Card>
  );
};
