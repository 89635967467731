import { AxiosInstance } from 'axios';
import { omit } from 'remeda';
import { string, z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

export const LightUserSchema = z
  .object({
    email: z.string().email(),
    firstName: string().nonempty(),
    id: z.number(),
    isActive: z.boolean(),
    lastName: string().nonempty(),
    profilePicture: z.string().url().nullable(),
  })
  .transform((data) => ({
    ...omit(data, ['firstName', 'lastName']),
    name: {
      full: `${data.firstName} ${data.lastName}`,
      first: data.firstName,
      last: data.lastName,
      initials: `${data.firstName[0]}${data.lastName[0]}`,
    },
  }));

const LightUsersSchema = z.array(LightUserSchema);

export type LightUser = z.infer<typeof LightUserSchema>;
type LightUsers = z.infer<typeof LightUsersSchema>;

export async function loadLightUsersQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get(
    `/users?${new URLSearchParams({
      fullResponse: 'false',
      showInactive: queryKey[1].loadInactiveUsers.toString(),
    }).toString()}`,
  );

  return LightUsersSchema.parse(data);
}

export const useLoadLightUsers = (loadInactiveUsers: boolean = false) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<LightUsers, ApiError>({
    queryKey: ['users', { loadInactiveUsers }] as const,
    queryFn: (context) => loadLightUsersQueryFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<LightUsers, unknown, 'users'>('users', queryResult);
};
