import { AxiosError, AxiosInstance } from 'axios';
import { LocaleSchema } from 'types';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, usePublicAxiosInstance } from './utils';

const OnboardingStepSchema = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
]);

const OnboardingStatusSchema = z.discriminatedUnion('isComplete', [
  z.object({ isComplete: z.literal(false), currentStep: OnboardingStepSchema }),
  z.object({ isComplete: z.literal(true), currentStep: z.null() }),
]);

const TenantSchema = z.object({
  locale: LocaleSchema,
  companyName: z.string().min(1),
  domain: z.string(),
  onboarding: OnboardingStatusSchema,
  customBranding: z
    .object({
      name: z.string().nullable(),
      logo: z.string().nullable(),
      color: z.string().nullable(),
    })
    .nullable(),
});

export type Tenant = z.infer<typeof TenantSchema>;

export async function validateTenantFn(axiosInstance: AxiosInstance, subdomain: string) {
  const { data } = await axiosInstance.get(`/tenant?name=${subdomain}`);
  data.domain = subdomain;

  let res: Tenant;
  try {
    res = TenantSchema.parse(data);
  } catch (error) {
    console.error(error?.toString());
    throw new Error('Tenant data is invalid');
  }

  return res;
}

export const useValidateTenant = (subdomain: string) => {
  const axiosInstance = usePublicAxiosInstance();

  const queryResult = useQuery({
    queryKey: ['tenant'],
    retry: false,
    queryFn: () => validateTenantFn(axiosInstance, subdomain),
    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<Tenant, AxiosError, 'tenant'>('tenant', queryResult);
};
