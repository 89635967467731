import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Card from 'shared/components/Card';
import { ProductLine } from 'types/store/invoice-state';

import ProductsDetailItem from './ProductsDetailItem';

import styleIdentifiers from './ProductsDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  products: ProductLine[];
};

export default function ProductsDetail({ products = [] }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles('ProductsDetail')}>
      <Card
        title={t(i18nKeys.INVOICE.INVOICE_PRODUCTS)}
        classContent={styles('card-body')}
        className={styles('product')}
      >
        <table>
          <thead>
            <tr>
              <th className={styles('big')}>{t(i18nKeys.INVOICE.PRODUCT)}</th>
              <th>{t(i18nKeys.INVOICE.QTE)}</th>
              <th>{t(i18nKeys.PRICE)}</th>
              <th>{t(i18nKeys.TOTAL_NOT_VAT_PRICE)}</th>
              <th>{t(i18nKeys.INVOICE.DISCOUNT)}</th>
              <th>{t(i18nKeys.VAT)}</th>
              <th className={styles('right')}>{t(i18nKeys.TOTAL_VAT_PRICE)}</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <ProductsDetailItem key={product.id} productLine={product} />
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
}
