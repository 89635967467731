import { useGetReasons } from 'api';
import { ReasonListItem } from 'app/Private/Tasks/TaskTypeList/ReasonListItem';
import { i18nKeys, useTranslation } from 'locales';
import CustomTable from 'shared/components/CustomTable';

const ReasonsTable = CustomTable(ReasonListItem);

export const CustomizationsSettingsReasons = () => {
  const { t } = useTranslation();

  const {
    data: reasonsListResponse,
    isLoading: reasonLoading,
    isSuccess: reasonSuccess,
  } = useGetReasons();

  const TABLE_HEADERS = [
    {
      key: 'id',
      title: 'ID',
    },
    {
      key: 'name',
      title: t(i18nKeys.NAME),
    },
    {
      key: 'reason_type',
      title: t(i18nKeys.FORM.TYPE),
    },
    {
      key: 'creation',
      title: t(i18nKeys.CREATION),
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  return (
    <ReasonsTable
      notSortable
      headers={TABLE_HEADERS}
      items={reasonsListResponse?.data || []}
      pagination={reasonsListResponse?.metadata?.pagination}
      loading={reasonLoading}
      loaded={reasonSuccess}
      noCheckbox
      noShadow
    />
  );
};
