/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type SendingMediaNames = (typeof SendingMediaNames)[keyof typeof SendingMediaNames];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SendingMediaNames = {
  email: 'email',
  post: 'post',
  registered_post: 'registered_post',
} as const;
