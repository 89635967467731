import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, CustomSelect, Input, Textarea } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';
import { onSubmitFunction } from 'types/react-hook-form';
import { Product } from 'types/store/product-state';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './productForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  close?: OnClickFunction;
  onSubmit: onSubmitFunction;
  initialValues?: Partial<Product>;
};

export default function ProductForm({ onSubmit, initialValues, close }: Props) {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);

  const form = useForm({
    shouldUnregister: true,
    defaultValues: initialValues,
  });
  const {
    register,
    unregister,
    formState: { isDirty, errors },
    handleSubmit,
  } = form;

  useEffect(() => {
    if (initialValues?.id) {
      register('id', { value: initialValues.id });
    }
    return () => {
      unregister('id');
    };
  }, []);

  useEffect(() => {
    sideMenuSetAskBeforeClose(isDirty);
  }, [isDirty]);

  return (
    <FormProvider {...form}>
      <form className={styles('ProductForm')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('head')}>
          {initialValues && initialValues.id && t(i18nKeys.FORM.PRODUCT.EDIT)}
          {(!initialValues || !initialValues.id) && t(i18nKeys.NEW_PRODUCT)}
          {close && (
            <div className={styles('close')} onClick={close}>
              <Icon name={IconName.SIMPLE_REMOVE} size="20px" />
            </div>
          )}
        </div>
        <div className={styles('content')}>
          <div className={styles('grid-row')}>
            <div className={styles('col-12')}>
              <Input
                register={register('name', { required: true })}
                errorMessage={errors.name}
                noMargin
                withBorder
                label={t(i18nKeys.INVOICE.PRODUCT)}
              />
            </div>
            <div className={styles('col-12')}>
              <Textarea
                register={register('description')}
                className={styles('input')}
                withBorder
                label={t(i18nKeys.DESCRIPTION)}
              />
            </div>
            <div className={styles('col-12')}>
              <Input
                register={register('price_htva', { required: true, min: 0 })}
                errorMessage={errors.price_htva}
                className={styles('input')}
                withBorder
                type="number"
                step="0.01"
                label={t(i18nKeys.PRICE)}
              />
            </div>
            <div className={styles('col-12')}>
              <Controller
                defaultValue=""
                rules={{ required: true }}
                name="tax_category_code"
                render={() => (
                  <CustomSelect
                    selectClassName={styles('input')}
                    keyText="description"
                    keyValue="value"
                    withBorder
                    name="tax_category_code"
                    label={t(i18nKeys.INVOICE.VAT_RATE)}
                    items={constants.tax_categories}
                    filter
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button noShadow noMargin label={t(i18nKeys.SAVE)} type="submit" />
        </div>
      </form>
    </FormProvider>
  );
}
