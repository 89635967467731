import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Checkbox, Input } from 'shared/io';

import { Group, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import { CGV, CompanyEdit, SalePreferencesFieldsName } from '../PreferencesForm.constants';

import { getDefaultCgvValues, getFieldErrors } from './SalePreferencesPartialForm.utils';
import { SalePreferencesPartialFormItems } from './SalePreferencesPartialFormItems';
import { SalePreferencesPartialFormUpload } from './SalePreferencesPartialFormUpload';

import styleIdentifiers from './SalePreferencesPartialForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  api?: boolean;
  onboarding?: boolean;
};

export const SalePreferencesPartialForm = ({ onboarding = false, api = false }: Props) => {
  const { t } = useTranslation();

  const {
    watch,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<CompanyEdit>();

  const { fields, append, remove } = useFieldArray<CompanyEdit>({
    name: CGV,
  });

  const values = watch(CGV);

  if (onboarding) {
    register(`${CGV}.0.${SalePreferencesFieldsName.DEFAULT}`);
  }

  const removeField = (index: number) => {
    if (values[index]?.id) {
      setValue(
        `${CGV}.${index}.${SalePreferencesFieldsName.DESTROY}`,
        true as never, // React hook form typescript fix
        {
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    } else {
      remove(index);
    }
    if (values[index].default) {
      setValue(
        `${CGV}.${values.findIndex((e) => !e[SalePreferencesFieldsName.DESTROY])}.${
          SalePreferencesFieldsName.DEFAULT
        }`,
        true,
        {
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    }
  };

  const setAsPrimary = (index: number) => {
    values.forEach((value, i: number) => {
      setValue(`${CGV}.${i}.${SalePreferencesFieldsName.DEFAULT}`, i === index);
    });
  };

  const notDestroyedValues = values.filter((e) => !e[SalePreferencesFieldsName.DESTROY]);

  const deletable = (index: number) => {
    const tos = values[index];
    return (!tos.default && tos.deletable) || !tos?.id;
  };

  return (
    <ul className={styles('sale-conditions-partial-form')}>
      {fields.map((field, i) => {
        const fieldErrors = getFieldErrors(errors, i);

        register(`${CGV}.${i}.${SalePreferencesFieldsName.ID}`);

        if (watch(`${CGV}.${i}.${SalePreferencesFieldsName.DESTROY}`)) {
          return undefined;
        }

        return (
          <li key={field.id} className={styles('grid-row')}>
            <div className={styles(!api ? 'col-4' : 'col-6')}>
              <Input
                noMargin
                type="text"
                label={t(i18nKeys.NAME)}
                errorMessage={fieldErrors?.name}
                register={register(`${CGV}.${i}.${SalePreferencesFieldsName.NAME}`, {
                  required: true,
                  validate: (value) =>
                    getValues(CGV)
                      .filter((e, index) => i !== index && !e[SalePreferencesFieldsName.DESTROY])
                      .map((e) => e[SalePreferencesFieldsName.NAME])
                      .includes(value)
                      ? i18nKeys.ERROR.MUST_BE_DIFFERENT
                      : undefined,
                })}
              />
            </div>
            {!api && (
              <div className={styles('col-4')}>
                <Input
                  noMargin
                  type="number"
                  min="0"
                  label={t(i18nKeys.FORM.PREFERENCES.DEFAULT_PAYMENT_DELAY)}
                  errorMessage={fieldErrors?.minimum_payment_terms_delay}
                  register={register(`${CGV}.${i}.${SalePreferencesFieldsName.MINIMUM_DELAY}`, {
                    required: true,
                    min: 0,
                  })}
                />
              </div>
            )}
            {!onboarding && (
              <div className={styles('col-3')}>
                <Checkbox
                  noMargin
                  watch={watch}
                  className={styles('input', 'checkbox-default')}
                  label={t(i18nKeys.ACTIONS.USE_DEFAULT)}
                  disabled={notDestroyedValues.length <= 1}
                  onChange={() => {
                    setAsPrimary(i);
                  }}
                  register={register(`${CGV}.${i}.${SalePreferencesFieldsName.DEFAULT}`)}
                />
              </div>
            )}
            {notDestroyedValues.length > 1 && deletable(i) && (
              <div className={styles('col-1')}>
                <div className={styles('delete')}>
                  <Icon name={IconName.TRASH_SIMPLE} onClick={() => removeField(i)} size="22px" />
                </div>
              </div>
            )}
            <Group mt="md">
              <Checkbox
                noMargin
                watch={watch}
                label={t(i18nKeys.ACTIONS.USE_BELGIAN_LAW)}
                register={register(`${CGV}.${i}.${SalePreferencesFieldsName.USE_BELGIAN_LAW}`)}
              />
              <Tooltip label={t(i18nKeys.ACTIONS.TOOLTIP)}>
                <IconInfoCircle />
              </Tooltip>
            </Group>
            {onboarding ? (
              <div className={styles('col-12')}>
                <SalePreferencesPartialFormItems parent={i} />
              </div>
            ) : (
              <>
                <div className={styles('col-10')} style={{ width: '80%' }}>
                  <SalePreferencesPartialFormItems parent={i} />
                </div>
                <div className={styles('col-2')} style={{ width: '20%' }}>
                  <SalePreferencesPartialFormUpload parent={i} />
                </div>
              </>
            )}
          </li>
        );
      })}
      {!onboarding && (
        <Button
          color={ButtonColor.BLUE}
          label={t(i18nKeys.ACTIONS.ADD_SALE_CONDITION)}
          iconRight={IconName.PLUS}
          onClick={() => append(getDefaultCgvValues())}
        />
      )}
    </ul>
  );
};
