import { PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import Swiper from 'react-swipe';
import { useIsMobile } from 'shared/hooks/utils';

import { useIsFirstRender } from '@mantine/hooks';

import { Icon, IconName } from '../Icon';

import styleIdentifiers from './Tabs.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  items: any[];
  className?: string;
  onChange?: (newTabIndex: number, oldTabIndex: number) => void;
  tabIndex?: number;
  disableScroll?: boolean;
  activeClassName?: string;
  noRounded?: boolean;
  noBody?: boolean;
};

export default function Tabs({
  items,
  children,
  className,
  tabIndex,
  onChange,
  disableScroll,
  activeClassName,
  noRounded,
  noBody = false,
}: PropsWithChildren<Props>) {
  const isMobile = useIsMobile();
  const [currentIndex, setCurrentIndex] = useState(tabIndex || 0);
  const isFirstRender = useIsFirstRender();
  let reactSwipeEl;

  useEffect(() => {
    if (tabIndex && !isFirstRender) setActive(tabIndex)();
  }, [tabIndex]);

  const setActive = (index: number) => () => {
    if (!noBody) reactSwipeEl.slide(index);
    onChange?.(index, currentIndex);
    setCurrentIndex(index);
  };

  const nextSlide = (e) => {
    e.stopPropagation();
    onChange?.(currentIndex + 1, currentIndex);
    setCurrentIndex(currentIndex + 1);
    if (!noBody) reactSwipeEl.next();
  };

  const previousSlide = (e) => {
    e.stopPropagation();
    onChange?.(currentIndex - 1, currentIndex);
    setCurrentIndex(currentIndex - 1);
    if (!noBody) {
      reactSwipeEl.prev();
    }
  };

  return (
    <div className={styles('tabs', noRounded && 'no-rounded', noBody && 'noBody', className)}>
      <div
        className={styles('header')}
        style={{
          transform: isMobile ? `translate( -${currentIndex}00%)` : '',
        }}
      >
        {items.map((item, index) => (
          <div
            className={styles(
              'header-item',
              currentIndex === index && 'active',
              currentIndex === index && activeClassName,
            )}
            key={index}
            onClick={setActive(index)}
          >
            {index !== 0 && (
              <Icon
                name={IconName.MINIMAL_LEFT}
                onClick={previousSlide}
                className={styles('prev')}
              />
            )}
            <div className={styles('children')}>{item}</div>

            {items.length - 1 !== currentIndex && (
              <Icon name={IconName.MINIMAL_RIGHT} onClick={nextSlide} className={styles('next')} />
            )}

            <div className={styles('line')} />
          </div>
        ))}
      </div>
      {!noBody && (
        <div className={styles('body')}>
          <Swiper
            className={styles('swiper')}
            swipeOptions={{ continuous: false, disableScroll, speed: 400 }}
            ref={(el) => (reactSwipeEl = el)}
          >
            {children}
          </Swiper>
        </div>
      )}
    </div>
  );
}
