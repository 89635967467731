import { Id, PaymentPlanComplete } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { formattedDate } from 'shared/utils/view';
import { sideMenuShow } from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import PaymentPlanDetailDebtor from '../PaymentPlanDetailDebtor';

import styleIdentifiers from './DebtorReactionPaymentPlansList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  items: PaymentPlanComplete[];
  data: any;
};

export default function DebtorReactionPaymentPlansList({ items, data }: Props) {
  const { t, currentLang } = useTranslation();

  const showDetails = (id: Id) => {
    const paymentPlan = items.find((item) => item.id === id);
    sideMenuShow({
      unmount: true,
      content: <PaymentPlanDetailDebtor paymentPlan={paymentPlan!} />,
    });
  };

  const getStatus = (completed) => (completed ? i18nKeys.COMPLETED : i18nKeys.CANCELLED);

  return (
    <div className={styles('debtor-reaction-payment-plans-list')}>
      <div className={styles('payment-plan-list')}>
        {items?.length > 0 ? (
          items.map(
            ({
              id,
              attributes: {
                remaining_balance,
                currency,
                created_at,
                next_due_date,
                cancelled,
                completed,
              },
            }) => (
              <div
                key={id}
                className={styles(
                  'payment-plan-item',
                  cancelled && 'cancelled',
                  completed && 'completed',
                )}
              >
                <div className={styles('payment-plan-info')} onClick={() => showDetails(id)}>
                  <div>
                    <div className={styles('reference')}>
                      {`${t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)} #${id}`}
                    </div>
                    <div className={styles('amount')}>
                      <Amount
                        localizedValue={remaining_balance}
                        value={remaining_balance}
                        suffix={currency}
                        debtor={data.debtor_data as Debtor}
                      />
                    </div>
                  </div>
                  <div className={styles('date')}>
                    <div>
                      <span className={styles('payment-plan-subtitle')}>
                        {t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.CREATED_AT)}{' '}
                      </span>
                      {formattedDate(created_at, currentLang)}
                    </div>
                    <div>
                      {cancelled || completed ? (
                        <>{t(getStatus(completed))}</>
                      ) : (
                        <>
                          <span className={styles('payment-plan-subtitle')}>
                            {t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.NEXT_DUE_DATE)}{' '}
                          </span>
                          {formattedDate(next_due_date, currentLang)}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles('actions-container')} onClick={() => showDetails(id)}>
                  <Icon name={IconName.EYE} />
                </div>
              </div>
            ),
          )
        ) : (
          <div className={styles('no-payment-plan')}>{t(i18nKeys.NO_RESULT)}</div>
        )}
      </div>
    </div>
  );
}
