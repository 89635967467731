import { Fragment } from 'react';
import { CreateWorkflowBody, PlanType, WorkflowStepsAttributes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import { WorkflowStepsTarget } from '../../Workflows.constants';
import { WorkflowFormStep } from '../Step/WorkflowFormStep';
import {
  getAvailableTemplates,
  getWorkflowRights,
  isAfterDueDate,
  isBeforeDueDate,
} from '../WorkflowForm.utils';

import { WorkflowFormStepsButton } from './WorkflowFormStepsButton';
import { WorkflowFormStepsItem } from './WorkflowFormStepsItem';
import { WorkflowFormStepsLine } from './WorkflowFormStepsLine';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsProps = {
  planType: PlanType;
  name: WorkflowStepsTarget;
};

export const WorkflowFormSteps = ({ planType, name }: WorkflowFormStepsProps) => {
  const { t } = useTranslation();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const { canAddStep, canEditStep } = getWorkflowRights(company, planType);

  const { watch, setValue } = useFormContext<CreateWorkflowBody>();

  const { fields, append, remove, insert, prepend } = useFieldArray<CreateWorkflowBody>({
    name,
  });

  const edit = (index: number) => {
    if (!canEditStep) {
      return false;
    }

    const availableTemplates = getAvailableTemplates(planType, name, watch(name), index);

    return sideMenuShow({
      unmount: true,
      content: (
        <WorkflowFormStep
          planType={planType}
          templates={availableTemplates}
          step={watch(`${name}.${index}`)}
          onSubmit={(data) => {
            setValue(`${name}.${index}`, data);
            sideMenuHide();
          }}
        />
      ),
    });
  };

  const add = (index: number, action: (data: WorkflowStepsAttributes) => void) => {
    if (!canAddStep) {
      return false;
    }

    const availableTemplates = getAvailableTemplates(planType, name, watch(name), index);

    return sideMenuShow({
      unmount: true,
      content: (
        <WorkflowFormStep
          planType={planType}
          templates={availableTemplates}
          onSubmit={(data) => {
            action(data);
            sideMenuHide();
          }}
        />
      ),
    });
  };

  const getButtonLabel = (index: number) =>
    getAvailableTemplates(planType, name, watch(name), index).length
      ? t(i18nKeys.ADD_STEP)
      : t(i18nKeys.ADD_TASK);

  return (
    <section className={styles('workflows-form-steps')}>
      {fields.map((step, i) => (
        <Fragment key={step.id}>
          {i === 0 ? (
            <WorkflowFormStepsLine bottom top={isAfterDueDate(name)}>
              <WorkflowFormStepsButton
                title={getButtonLabel(0)}
                onClick={() => add(0, (data) => prepend(data))}
                disabled={!canAddStep}
                label={isBeforeDueDate(name)}
              />
            </WorkflowFormStepsLine>
          ) : (
            <WorkflowFormStepsLine top bottom>
              <WorkflowFormStepsButton
                title={getButtonLabel(i)}
                onClick={() => add(i, (data) => insert(i, data))}
                disabled={!canAddStep}
              />
            </WorkflowFormStepsLine>
          )}
          <WorkflowFormStepsItem
            planType={planType}
            name={name}
            index={i}
            edit={() => edit(i)}
            remove={() => remove(i)}
          />
          {i === fields.length - 1 && (
            <WorkflowFormStepsLine top bottom={isBeforeDueDate(name)}>
              <WorkflowFormStepsButton
                title={getButtonLabel(i + 1)}
                onClick={() => add(i + 1, (data) => append(data))}
                disabled={!canAddStep}
                label={isAfterDueDate(name)}
              />
            </WorkflowFormStepsLine>
          )}
        </Fragment>
      ))}
      {!fields.length && (
        <WorkflowFormStepsLine top={isAfterDueDate(name)} bottom={isBeforeDueDate(name)}>
          <WorkflowFormStepsButton
            title={getButtonLabel(0)}
            onClick={() => add(0, (data) => append(data))}
            disabled={!canAddStep}
            label
          />
        </WorkflowFormStepsLine>
      )}
    </section>
  );
};
