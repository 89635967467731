import classNames from 'classnames/bind';
import { useLoadInvoiceFooter } from 'shared/hooks';

import styleIdentifiers from './invoiceFooter.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function InvoiceFooter() {
  const { invoiceFooter } = useLoadInvoiceFooter();

  if (invoiceFooter == null) return null;

  return (
    <footer className={styles('InvoiceFooter', 'invoice')}>
      {invoiceFooter.fields.map((field, index) => (
        <div key={index}>
          {(() => {
            if (field == null) return '';
            if (typeof field === 'string') return field;
            if (field.content != null) return field.content;
            return '';
          })()}
        </div>
      ))}
    </footer>
  );
}
