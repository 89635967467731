import React from 'react';

import { ActionIcon, Card, Center, Group, Overlay, Text } from '@mantine/core';
import { useClickOutside, useDisclosure, useHotkeys } from '@mantine/hooks';
import { styled } from '@stitches/react';
import { IconHelpCircleFilled, IconX } from '@tabler/icons-react';

const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  top: '16px',
  right: '16px',
});
interface AnalyticCardProps
  extends React.PropsWithChildren<{
    title: string;
    helpContent?: React.ReactNode;
    actions?: React.ReactNode;
  }> {}

export const AnalyticsCard = ({ title, helpContent, actions, children }: AnalyticCardProps) => {
  const [isHelpOpen, helpHandlers] = useDisclosure();

  const ref = useClickOutside(helpHandlers.close);
  useHotkeys([['Escape', helpHandlers.close]]);

  return (
    <Card radius="md" shadow="sm">
      <Card.Section inheritPadding withBorder py="xs" mb="md">
        <Group justify="space-between">
          <Group gap="xs">
            <Text fw={500} size="lg" ff="Roboto">
              {title}
            </Text>
            {helpContent != null && (
              <ActionIcon variant="transparent" color="blue.4" onClick={helpHandlers.open}>
                <IconHelpCircleFilled stroke={1.5} />
              </ActionIcon>
            )}
          </Group>
          {actions != null && actions}
        </Group>
      </Card.Section>
      {isHelpOpen && (
        <Overlay ref={ref} p="xl" c="white" zIndex={1} backgroundOpacity={0.6} blur={7}>
          <CloseButtonWrapper>
            <ActionIcon variant="transparent" color="white" onClick={helpHandlers.close}>
              <IconX stroke={1.5} />
            </ActionIcon>
          </CloseButtonWrapper>
          <Center>{helpContent}</Center>
        </Overlay>
      )}
      {children}
    </Card>
  );
};
