import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect, MaskInput } from 'shared/io';
import { maskVat } from 'shared/utils/mask.utils';

import { TvaTypes } from './VatInput.types';

import styleIdentifiers from './VatInput.module.scss';

const styles = classNames.bind(styleIdentifiers);

type VatInputProps = {
  name: string;
  label?: string;
  className?: string;
  vatRequired?: boolean;
  withBorder?: boolean;
  tabIndex?: number;
  defaultValue?: any;
  error?: any;
  noDropdownMargin?: boolean;
};

export const VatInput = ({ vatRequired, error, noDropdownMargin, ...props }: VatInputProps) => {
  const { className, ...rest } = props;

  const { t } = useTranslation();
  const { watch, control } = useFormContext();
  const vatType = watch('tva_type');

  const items = [
    ...Object.keys(TvaTypes).map((tvaType) => ({ text: tvaType, value: tvaType })),
    { text: t(i18nKeys.OTHER), value: 'other' },
  ];

  return (
    <div className={styles('vat-input', props.withBorder && 'border')}>
      <Controller
        name="tva_type"
        render={() => (
          <CustomSelect
            {...rest}
            keyText="text"
            keyValue="value"
            label={t(i18nKeys.FORM.VAT_FORMAT)}
            selectClassName={styles(noDropdownMargin ? '' : 'dropdown')}
            name="tva_type"
            items={items}
          />
        )}
      />

      <Controller
        control={control}
        name={rest.name}
        rules={{
          validate: (val) =>
            vatRequired &&
            (val
              ? val.indexOf('_') > -1
                ? 'ERROR.NOT_FULLY_FILLED'
                : true
              : 'ERROR.FIELD_REQUIRED'),
        }}
        render={({ field: { ref, ...values } }) => (
          <MaskInput
            {...values}
            {...rest}
            className={className}
            error={error}
            numberOnly={!!maskVat[vatType]}
            mask={maskVat[vatType]}
          />
        )}
      />
    </div>
  );
};
