import { settingsActions } from 'store/settings/settings.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { CustomVariable } from 'types/custom-variable-state';

import CustomVariableForm from './CustomVariableForm';

type Props = {
  customVariable?: CustomVariable;
  callback?: Function;
  sideMenuProps?: any;
};

export default function EditCustomVariable({ customVariable, sideMenuProps }: Props) {
  const submitCustomVariable = (values: CustomVariable) => {
    if (customVariable?.id) {
      settingsActions.setCustomVariable({
        id: customVariable.id,
        data: {
          ...values,
        },
        callback: sideMenuHide,
      });
    } else {
      settingsActions.addCustomVariable({
        data: values,
        callback: sideMenuHide,
      });
    }
  };

  const showSideMenu = () => {
    const init = {
      ...customVariable,
    } as any;

    sideMenuShow({
      unmount: true,
      noBackgroundClose: true,
      ...sideMenuProps,
      content: <CustomVariableForm onSubmit={submitCustomVariable} initialValues={init} />,
    });
  };

  showSideMenu();
}
