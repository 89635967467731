import { useState } from 'react';
import { useDeleteTask, useUpdateTask } from 'api';
import { CreateTaskBody, Task } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';
import { TableItemProps } from 'types/table';

import { getAssigneeFullName, getTaskTypeDescription } from '../Task.utils';
import { TaskForm } from '../TaskForm/TaskForm';

import { TasksListItemStatus } from './TasksListItemStatus';

import styleIdentifiers from './TasksListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskListItemProps = TableItemProps<Task> & {
  refetch: () => void;
};

export const TaskListItem = ({ item, refetch }: TaskListItemProps) => {
  const { t, currentLang } = useTranslation();
  const history = useHistory();

  const { task_types } = useSelector((state: StoreState) => state.app.constants);

  const [hover, setHover] = useState(false);

  const {
    id,
    attributes: { title, debtor, status, due_date_adjusted, status_key },
  } = item;

  const { mutate: updateTaskMutation } = useUpdateTask();
  const { mutate: deleteTaskMutation } = useDeleteTask();

  const updateTask = () =>
    sideMenuShow({
      unmount: true,
      content: (
        <TaskForm
          task={item}
          onSubmit={(data: CreateTaskBody) =>
            updateTaskMutation(
              { id, data },
              {
                onSuccess: () => {
                  refetch();
                  sideMenuHide();
                },
              },
            )
          }
        />
      ),
    });

  const deleteTask = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_TASK)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteTaskMutation(
              { id },
              {
                onSuccess: () => {
                  history.push('/tasks');
                  dialogHide(DialogShowId.CONFIRM);
                  refetch();
                },
              },
            )
          }
        />
      ),
    });

  const goToDetails = () => {
    history.push(`/tasks/${id}`);
  };

  return (
    <tr
      className={styles('task-list-item', hover && 'hover')}
      onMouseEnter={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={id}
    >
      <td />
      <td onClick={goToDetails}>{title}</td>
      <td onClick={goToDetails}>{getTaskTypeDescription(task_types, item)}</td>
      {debtor ? (
        <td className={styles('debtor')}>
          <NavLink to={`/clients/${debtor.id}`}>{debtor.attributes.full_name}</NavLink>
        </td>
      ) : (
        <td onClick={goToDetails}>-</td>
      )}
      <td onClick={goToDetails}>
        <TasksListItemStatus status={status} statusKey={status_key} />
      </td>
      <td onClick={goToDetails}>{getAssigneeFullName(item)}</td>
      {due_date_adjusted ? (
        <td onClick={goToDetails}>{formattedDate(new Date(due_date_adjusted), currentLang)}</td>
      ) : (
        <td onClick={goToDetails}>-</td>
      )}
      <td className={styles('actions')}>
        <span onClick={updateTask}>
          <Icon name={IconName.SETTINGS_GEAR} size="18px" className={styles('action')} />
        </span>
        <span onClick={deleteTask}>
          <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
        </span>
      </td>
    </tr>
  );
};
