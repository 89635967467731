import { Route, Switch } from 'react-router';

import { CreateReport, Report, ReportsIndex } from './pages';

export const Reports = () => (
  <Switch>
    <Route path="/reports/new" component={CreateReport} />
    <Route path="/reports/:reportId" component={Report} />
    <Route path="/reports" component={ReportsIndex} />
  </Switch>
);
