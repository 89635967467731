import { useForm, zodResolver } from '@mantine/form';

import {
  LoadDebtorColumnVariables,
  LoadDebtorColumnVariablesSchema,
  useLoadDebtorColumn,
} from './use-load-debtor-column';

export function useLoadKanbanDebtors() {
  const filtersForm = useForm<LoadDebtorColumnVariables>({
    validate: zodResolver(LoadDebtorColumnVariablesSchema),
    initialValues: {
      accountManagerId: null,
      minSaldo: '',
      maxSaldo: '',
      fullName: '',
      column: 'late', // placholder for type correctness, overwritten below,
    },
  });

  const filters = filtersForm.getValues();

  const late = useLoadDebtorColumn({ ...filters, column: 'late' });
  const firstReminder = useLoadDebtorColumn({ ...filters, column: 'first_reminder' });
  const lastReminder = useLoadDebtorColumn({ ...filters, column: 'last_reminder' });
  const formalNotice = useLoadDebtorColumn({ ...filters, column: 'formal_notice' });
  const thirdPartyCase = useLoadDebtorColumn({ ...filters, column: 'third_party_case' });
  const disputed = useLoadDebtorColumn({ ...filters, column: 'disputed' });

  return {
    columns: { late, firstReminder, lastReminder, formalNotice, thirdPartyCase, disputed },
    filtersForm,
  };
}
