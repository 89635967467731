import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group'; // ES6
import { Icon, IconName } from 'shared/components/Icon';
import { useGetDialogs } from 'shared/utils/selectors';
import { animationClassList } from 'shared/utils/view';
import { dialogHide, ShowDialogOptions } from 'store/view/view.actions';

import styleIdentifiers from './modal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  dialog: ShowDialogOptions & {
    active: boolean;
  };
};

export default function Modal({ dialog }: Props) {
  const { count } = useGetDialogs();
  const [zIndex, setZIndex] = useState(0);
  const nodeRef = useRef(null);

  useEffect(() => {
    if (dialog.active) {
      document.addEventListener('keydown', handleKeyPress);
    } else {
      document.removeEventListener('keydown', handleKeyPress);
      dialog.onClose?.();
    }
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [dialog.active]);

  useEffect(() => {
    if (!dialog.active) setZIndex(0);
    else setZIndex(count);
  }, [dialog.active]);

  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      if (dialog.active) {
        dialogClose();
      }
    }
  };

  const dialogClose = () => {
    dialogHide(dialog.id);
    dialog?.onClose?.();
  };

  return (
    <CSSTransition
      in={dialog.active}
      nodeRef={nodeRef}
      timeout={400}
      mountOnEnter
      unmountOnExit={!dialog.keepMountOnExit}
      classNames={animationClassList('modal', styles)}
    >
      <div
        ref={nodeRef}
        style={{ zIndex, position: 'fixed' }}
        className={styles('Modal', dialog.id, 'absolute-fill')}
      >
        <div className={styles('background')} onClick={dialogClose} />
        <div className={styles('wrapper', dialog.size)}>
          <div className={styles('close')} onClick={dialogClose}>
            <Icon name={IconName.SIMPLE_REMOVE} />
          </div>
          {dialog.title && <div className={styles('title')}>{dialog.title}</div>}
          {dialog.children}
        </div>
      </div>
    </CSSTransition>
  );
}
