import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useLocation } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { useIsMobile } from 'shared/hooks/utils';
import { Dropdown, Input } from 'shared/io';
import { useGetViewsByResource } from 'shared/utils/selectors';
import { ResourceType } from 'types/resource-types';
import { TableView } from 'types/views';

import { Text, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconStarFilled } from '@tabler/icons-react';

import { quickSearchKey } from '../CustomTable.utils';
import { ViewSettingsModal } from '../ViewSettings';

import styleIdentifiers from './CustomTableTitle.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  title: any;
  actions: any;
  tableName: ResourceType;
  submit: Function;
  invalidateViews: () => void;
};

export default function CustomTableTitle({
  title,
  actions,
  tableName,
  submit,
  invalidateViews,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  const theme = useMantineTheme();

  const tableViews = useGetViewsByResource()[tableName];

  const [currentViewId, setCurrentViewId] = useState();
  const [currentTabIndex, _setCurrentTabIndex] = useState(0);
  const [searchedView, setSearchedView] = useState('');

  // Float the favorite view if any to the first position
  const orderedViews = (() => {
    const favoriteViewIndex = tableViews.findIndex((view) => view.is_favorite);

    if (favoriteViewIndex === -1) {
      return tableViews;
    }

    return [
      tableViews[favoriteViewIndex],
      ...tableViews.filter((_, index) => index !== favoriteViewIndex),
    ];
  })();

  useEffect(() => {
    if (location.hash !== currentViewId) {
      setCurrentViewId(location.hash.slice(1) as any);
    }
  }, [location.hash]);

  const openViewModal = () => {
    modals.open({
      title: t(i18nKeys.VIEWS.SETTINGS.TITLE),
      size: '800px',
      centered: true,
      onClose: invalidateViews,
      children: <ViewSettingsModal resourceType={tableName! as ResourceType} />,
    });
  };

  const filtersToUrl = (view) => {
    const newFilters = {};
    view.filter_fields.forEach((filter) => {
      if (
        filter.applied_filter.operator === 'null' ||
        filter.applied_filter.operator === 'not_null'
      ) {
        newFilters[`${filter.name}_handler`] = filter.applied_filter.operator;
      } else {
        if (filter.filter_type === 'date') {
          newFilters[`${filter.name}_start`] = filter.applied_filter.value[0];
          newFilters[`${filter.name}_end`] = filter.applied_filter.value[1];
          delete newFilters[filter.name];
        } else if (filter.filter_type === 'number') {
          newFilters[`min_${filter.name}`] = filter.applied_filter.value[0];
          newFilters[`max_${filter.name}`] = filter.applied_filter.value[1];
          delete newFilters[filter.name];
        } else {
          newFilters[filter.name] = filter.applied_filter.value;
        }
        if (filter.applied_filter.operator) {
          newFilters[`${filter.name}_operator`] = filter.applied_filter.operator;
        }
      }
    });
    return newFilters;
  };

  const setQuickSearchValue = (value) => {
    const input = document.querySelector("[data-quick-search='1']") as any;
    if (input) {
      input.value = value;
    }
  };

  const changeView = (view: TableView) => () => {
    const urlFilters = Object.entries({
      ...filtersToUrl(view),
    });
    history.push(
      `${location.pathname}${urlFilters.reduce(
        (acc, [key, value]) => `${acc}${key}=${value}&`,
        '?',
      )}${`#${view.id}`}`,
    );
    let quickSearchValue = '';
    urlFilters.forEach((filter) => {
      if (filter[0] === 'quick_search') {
        quickSearchValue = filter[1] as string;
      }
    });
    setQuickSearchValue(quickSearchValue);
  };

  const setCurrentTabIndex = (nbre) => () => {
    _setCurrentTabIndex(currentTabIndex + nbre);
  };

  const filteredViews = (views) =>
    searchedView
      ? views.filter((view) => view.name.toLowerCase().indexOf(searchedView.toLowerCase()) !== -1)
      : views;

  const optionsMenu = () => (
    <div className={styles('options')}>
      <Dropdown toggleContent selectorContent={<Icon name={IconName.MINIMAL_DOWN} />}>
        <div className={styles('dropdown-input')}>
          <Input
            name="searched_view"
            autoComplete="off"
            placeholder={t(i18nKeys.FORM.SEARCH)}
            noMargin
            onValueChanged={setSearchedView}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </div>
        {filteredViews(tableViews).map((view) => (
          <div key={view.id} className={styles('dropdown-item')} onClick={changeView(view)}>
            {view.name}
          </div>
        ))}
      </Dropdown>
      <Icon name={IconName.SETTINGS_GEAR} onClick={openViewModal} />
    </div>
  );

  return (
    <div className={styles('custom-table-title', tableName && 'table-view')}>
      <div className={styles('tab-container')}>
        <div className={styles('title')}>
          <h1>{title}</h1>
          {tableViews && isMobile && optionsMenu()}
        </div>
        {tableViews && (
          <>
            <div className={styles('tab-wrapper')}>
              {currentTabIndex > 0 && (
                <div className={styles('tab-item')} onClick={setCurrentTabIndex(-1)}>
                  <Icon name={IconName.MINIMAL_LEFT} />
                </div>
              )}
              {orderedViews.slice(currentTabIndex, currentTabIndex + 3).map((view) => (
                <div
                  key={view.id}
                  className={styles('tab-item', view.id === currentViewId && 'active')}
                  onClick={changeView(view)}
                >
                  {view.is_favorite && <IconStarFilled size={20} color={theme.colors.gray[6]} />}
                  <Text ml={view.is_favorite ? 8 : 0}>{view.name}</Text>
                </div>
              ))}
              {currentTabIndex < tableViews.length - 3 && (
                <div className={styles('tab-item')} onClick={setCurrentTabIndex(1)}>
                  <Icon name={IconName.MINIMAL_RIGHT} />
                </div>
              )}
              <div className={styles('border-mobile')} />
            </div>
            {!isMobile && optionsMenu()}
          </>
        )}
      </div>
      <div className={styles('actions-container', isMobile && 'space-between')}>
        {isMobile && (
          <div className={styles('quick-search')}>
            <Input
              noMargin
              quickSearch
              shadow
              type="text"
              placeholder={t(i18nKeys.FORM.QUICK_SEARCH.PLACEHOLDER)}
              onValueChanged={submit}
            />
            <Tooltip
              icon={IconName.INFO}
              size="14px"
              text={t(i18nKeys.FORM.QUICK_SEARCH.TOOLTIP[quickSearchKey(tableName)])}
            />
          </div>
        )}
        <div className={styles('actions')}>{actions}</div>
      </div>
    </div>
  );
}
