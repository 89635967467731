import { AxiosInstance, AxiosResponse } from 'axios';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, useLegacyAxiosInstance } from './utils';

type SuccessResponse = AxiosResponse<{ message: string }>;

export async function manuallySyncExternalEmails(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  return instance.post('fetch_external_mails');
}

export function useManuallySyncExternalMails() {
  const client = useQueryClient();
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation<SuccessResponse, ApiError>({
    mutationFn: () => manuallySyncExternalEmails(axiosInstance),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({ queryKey: ['externalMails'] });
    },
    onError: (error) => {
      notifications.show({
        message: error.toString(),
        color: 'red',
      });
    },
  });

  return addActionNameToMutationResult<
    SuccessResponse,
    ApiError,
    void,
    'manuallySyncExternalEmails'
  >('manuallySyncExternalEmails', mutation);
}
