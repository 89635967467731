import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon, IconName } from 'shared/components/Icon';

import styleIdentifiers from './Button.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum ButtonColor {
  GREY = 'grey',
  GREEN = 'green',
  RED = 'red',
  TRANSPARENT = 'transparent',
  WHITE = 'white',
  BLUE = 'blue',
  MAIN = 'main-color',
  ORANGE = 'orange',
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label?: string;
  color?: ButtonColor;
  iconSize?: string;
  iconLeft?: IconName;
  iconRight?: IconName;
  noMargin?: boolean;
  noShadow?: boolean;
  className?: string;
  upperCircle?: number;
  disabledClick?: boolean;
  small?: boolean;
  isLoading?: boolean;
  childrenBefore?: boolean;
};

export const Button = ({
  type = 'button',
  iconSize = '13px',
  label,
  color,
  iconLeft,
  iconRight,
  noMargin,
  noShadow,
  className,
  upperCircle,
  disabledClick,
  small,
  disabled,
  isLoading = false,
  children,
  childrenBefore = false,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const { t } = useTranslation();

  return (
    <button
      type={type}
      disabled={isLoading || (disabled && !disabledClick)}
      className={styles(
        'Button',
        (iconLeft || iconRight) && 'with-icon',
        (isLoading || disabled) && 'disabled',
        noMargin && 'no-margin',
        isLoading && 'loading',
        !noShadow && 'shadow',
        small && 'small',
        className,
        color,
      )}
      {...rest}
    >
      {iconLeft && (
        <span className={styles('icon-left')}>
          <Icon name={iconLeft} size={iconSize} />
        </span>
      )}
      {isLoading ? (
        <>{t(i18nKeys.LOADING)}</>
      ) : (
        <>
          {childrenBefore && children}
          {label && <span>{label}</span>}
          {!childrenBefore && children}
        </>
      )}
      {iconRight && (
        <span className={styles('icon-right')}>
          <Icon name={iconRight} size={iconSize} />
        </span>
      )}
      {upperCircle && (
        <span className={`${styles('text-circle', 'absolute')} ${color}`}>{upperCircle}</span>
      )}
    </button>
  );
};
