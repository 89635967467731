import { useEffect } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { isEmpty, isNonNull, isNullish } from 'remeda';
import { CompanyWebCredentialsSchema, useSaveCompanyWebCredentials } from 'shared/hooks';
import { useGetCompany } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';

import { ActionIcon, Button, Center, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconLink, IconQuestionMark, IconUnlink } from '@tabler/icons-react';

export const CompanyWeb = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { company_web_credentials: companyWebCredentials } = useGetCompany()!;
  const { saveCompanyWebCredentials, isSaveCompanyWebCredentialsLoading } =
    useSaveCompanyWebCredentials();

  const company = useGetCompany()!;

  const form = useForm({
    initialValues: {
      login: companyWebCredentials.login ?? '',
      password: companyWebCredentials.password ?? '',
    },
  });

  useEffect(() => {
    form.setFieldValue('login', companyWebCredentials.login ?? '');
    form.setFieldValue('password', companyWebCredentials.password ?? '');
  }, [companyWebCredentials]);

  const isCompanyWebConnected =
    isNonNull(companyWebCredentials.login) && isNonNull(companyWebCredentials.password);

  const isSaveButtonDisabled =
    isNullish(form.values.login) ||
    isEmpty(form.values.login) ||
    isNullish(form.values.password) ||
    isEmpty(form.values.password);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = CompanyWebCredentialsSchema.parse(form.values);
    saveCompanyWebCredentials(data, {
      onSuccess: () => {
        accountActions.getCompany();
      },
    });
  };

  const handleReset = () => {
    saveCompanyWebCredentials(
      { password: null, login: null },
      {
        onSuccess: () => {
          accountActions.getCompany();
        },
      },
    );
  };

  return (
    <>
      <Group mb="xl">
        <ActionIcon
          onClick={() => history.push('/settings/integrations')}
          size="md"
          variant="light"
        >
          <IconArrowLeft stroke={1.5} />
        </ActionIcon>
        <Text size="lg" fw="bold">
          CompanyWeb
        </Text>
      </Group>
      <Center>
        {!company.package?.can_use_monitoring ? (
          <Stack align="center">
            <Text>{t(i18nKeys.ACCOUNT_LIMITATION)}</Text>
            <Button onClick={() => window.Intercom('show')} leftSection={<IconQuestionMark />}>
              {t(i18nKeys.MORE_INFO)}
            </Button>
          </Stack>
        ) : (
          <Stack w="33%">
            <form onSubmit={handleSubmit}>
              <TextInput
                {...form.getInputProps('login')}
                disabled={isCompanyWebConnected}
                label="Username"
                placeholder="Username"
                mb="md"
              />
              <TextInput
                {...form.getInputProps('password')}
                disabled={isCompanyWebConnected}
                label="Password"
                placeholder="Password"
                mb={38}
              />
              {isCompanyWebConnected ? (
                <Button
                  leftSection={<IconUnlink stroke={1.5} />}
                  color="red"
                  w="100%"
                  onClick={handleReset}
                >
                  Clear
                </Button>
              ) : (
                <Button
                  leftSection={<IconLink stroke={1.5} />}
                  disabled={isSaveButtonDisabled}
                  loading={isSaveCompanyWebCredentialsLoading}
                  w="100%"
                  type="submit"
                >
                  Save
                </Button>
              )}
            </form>
          </Stack>
        )}
      </Center>
    </>
  );
};
