import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import EditClient from 'shared/action-component/EditClient';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { reloadCustomView } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from '../ClientsList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type NoCheckBoxProps = {
  exportCSV: () => void;
  duplicatesCount: number;
};

export const NoCheckBox = ({ exportCSV, duplicatesCount }: NoCheckBoxProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const newClient = () => {
    EditClient({
      callback: () => {
        reloadCustomView(clientActions.listPageRes);
      },
    });
  };

  const goToDuplicates = () => {
    history.push('/client/duplicates');
  };

  return (
    <>
      <Dropdown
        sideMenuInMobile
        sideMenuTitle={t(i18nKeys.FORM.ACTIONS)}
        className={styles('actions-dropdown')}
        toggleContent
        selectorContent={
          <Button
            className={styles('action')}
            noMargin
            label={t(i18nKeys.FORM.ACTIONS)}
            iconRight={IconName.ARROW_BOTTOM_ROUNDED}
          />
        }
      >
        <div className="dropdown-item" onClick={newClient}>
          <div>{t(i18nKeys.NEW_CLIENT)}</div>
        </div>
        <div className="dropdown-item" onClick={exportCSV}>
          <div>{t(i18nKeys.EXPORT_CSV)}</div>
        </div>
      </Dropdown>
      {company.unvalidated_debtors_count > 0 && (
        <Button noMargin onClick={() => history.push('/client/to-confirm')}>
          {t(i18nKeys.IMPORT_TO_CONFIRM)}
          <div className={styles('text-circle', 'absolute', 'border', 'blue')}>
            {company.unvalidated_debtors_count}
          </div>
        </Button>
      )}
      {duplicatesCount !== 0 && (
        <Button
          noMargin
          className={styles('button')}
          label={t(i18nKeys.DUPLICATES)}
          onClick={goToDuplicates}
        >
          <div className={styles('text-circle', 'absolute', 'border', 'blue')}>
            {duplicatesCount}
          </div>
        </Button>
      )}
      <Button
        noMargin
        className={styles('button')}
        label={t(i18nKeys.EXPORT)}
        onClick={exportCSV}
      />
      <Button
        noMargin
        className={styles('button')}
        label={t(i18nKeys.NEW_CLIENT)}
        onClick={newClient}
        color={ButtonColor.BLUE}
      />
    </>
  );
};
