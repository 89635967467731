import { useState } from 'react';
import classNames from 'classnames/bind';

import { Icon, IconName } from '../Icon';

import styleIdentifiers from './CopyToClipboard.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  textToCopy: string;
  text: string;
  custom?: boolean;
};

export default function CopyToClipboard({ textToCopy, text, custom }: Props) {
  const [showCopied, setShowCopied] = useState(false);
  const copyOnClipBoard = () => {
    const textField = document.createElement('textarea');
    textField.innerText = textToCopy;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 1500);
  };

  return (
    <span className={styles('copy-to-clipboard', custom && 'custom')} onClick={copyOnClipBoard}>
      {text} <Icon name={IconName.COPY} />
      <span className={styles('copied', showCopied && 'show')}>
        copié <Icon name={IconName.CHECK} />
      </span>
    </span>
  );
}
