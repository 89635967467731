import { matchPath, useHistory, useLocation } from 'react-router';
import { isDefined, isNonNullish } from 'remeda';

import { css } from '@emotion/css';
import { NavLink, Text } from '@mantine/core';

import { MenuItem } from '../types';

import { MenuItemBadges } from './MenuItemBadges';

export type NavMenuItemProps = MenuItem;

export const NavMenuItem = ({
  label,
  Icon,
  path,
  pathSuffix,
  unreadCount,
  tagLabel,
  isHidden,
  onClick,
  onNavigate,
}: NavMenuItemProps) => {
  const history = useHistory();
  const location = useLocation();

  if (isHidden) return null;

  if (isDefined(path)) {
    const doesMatchCurrentPath = isNonNullish(matchPath(location.pathname, { path, exact: false }));

    return (
      <NavLink
        onClick={() => {
          history.push(path.concat(pathSuffix ?? ''));
          onNavigate?.();
        }}
        className={styles.navLink}
        classNames={{
          root: styles.navLinkRoot(doesMatchCurrentPath),
        }}
        label={
          <Text c="#4D4D4D" fw={doesMatchCurrentPath ? 500 : undefined}>
            {label}
          </Text>
        }
        leftSection={<Icon color="#67869B" stroke="1.7" size={20} />}
        rightSection={<MenuItemBadges unreadCount={unreadCount} tagLabel={tagLabel} />}
      />
    );
  }

  return (
    <NavLink
      onClick={onClick}
      className={styles.navLink}
      label={<Text c="#4D4D4D">{label}</Text>}
      leftSection={<Icon color="#67869B" stroke="1.7" size={20} />}
    />
  );
};

export const styles = {
  navLinkRoot: (isActive: boolean = false) => css`
    box-sizing: border-box;
    background-color: ${isActive ? '#F2F5F7' : 'unset'} !important;
    border: ${isActive ? '1px solid #CCD7DE' : '1px solid transparent'} !important;
    border-radius: 8px;
  `,
  navLink: css`
    font-weight: 400;
    margin-bottom: 4px;

    &:hover {
      background-color: #f2f5f7 !important;
    }
  `,
};
