import { i18nKeys, useTranslation } from 'locales';
import { useEnhancedNavigation } from 'shared/hooks/utils';
import { useLocalizedCurrencyFormatter } from 'shared/utils/normalization';

import { css } from '@emotion/css';
import { ActionIcon, Group, Stack, Text } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';

import { KanbanDebtor } from '../../api/use-load-debtor-column';

const styles = {
  floatingIconTrigger: css`
    position: relative;

    [data-floating-icon] {
      position: absolute;
      top: 0px;
      right: 0px;
      opacity: 0;
      transition: opacity 100ms;
      pointer-events: none;
    }

    &:hover {
      [data-floating-icon] {
        opacity: 1;
        pointer-events: all;
      }
    }
  `,
};

interface InvoiceKanbanCardProps {
  debtor: KanbanDebtor;
}

export const DebtorKanbanCard = ({ debtor }: InvoiceKanbanCardProps) => {
  const { t } = useTranslation();
  const formatter = useLocalizedCurrencyFormatter();
  const enhancedNavigate = useEnhancedNavigation();

  const { name, remainingBalance, unpaidInvoicesCount } = debtor;

  return (
    <Stack className={styles.floatingIconTrigger}>
      <ActionIcon
        size="sm"
        variant="default"
        data-floating-icon
        onClick={enhancedNavigate(`/kanban/invoices?debtorName=${debtor.name}`)}
      >
        <IconFilter style={{ width: '70%', height: '70%' }} stroke={1.5} />
      </ActionIcon>
      <Group justify="space-between" align="top">
        <Stack gap={4}>
          <Text size="sm" fw={600}>
            {name}
          </Text>
        </Stack>
      </Group>
      <Group justify="space-between">
        <Stack gap={0}>
          <Text size="xs" c="dimmed" fw={350}>
            {t(i18nKeys.KANBAN.BALANCE)}
          </Text>
          <Text size="xs" c="blue.7" fw={600}>
            {formatter.format(remainingBalance)}
          </Text>
        </Stack>
        <Stack gap={0} align="flex-end">
          <Text size="xs" c="dimmed" fw={350}>
            {t(i18nKeys.KANBAN.LATE_INVOICES)}
          </Text>
          <Text size="xs" fw={600}>
            {unpaidInvoicesCount}
          </Text>
        </Stack>
      </Group>
    </Stack>
  );
};
