import { AxiosInstance } from 'axios';
import { isNonNullish, pick, pickBy } from 'remeda';
import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from 'shared/hooks/utils';
import { z } from 'zod';

import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

// TODO: repeated code, extract
export const KANBAN_DEBTOR_COLUMNS = [
  'late',
  'first_reminder',
  'last_reminder',
  'formal_notice',
  'third_party_case',
  'disputed',
] as const;

export type KanbanDebtorColumn = (typeof KANBAN_DEBTOR_COLUMNS)[number];

// #region Schemas
const KanbanDebtorSchema = z.object({
  id: z.number(),
  name: z.string(),
  remainingBalance: z.coerce.number(),
  unpaidInvoicesCount: z.number(),
});

const DebtorColumnSchema = z.object({
  debtors: z.array(KanbanDebtorSchema),
  total: z.coerce.number(),
});

export const LoadDebtorColumnVariablesSchema = z.object({
  accountManagerId: z.coerce.number().optional().nullable(),
  column: z.enum([...KANBAN_DEBTOR_COLUMNS]),
  fullName: z.string().optional(),
  minSaldo: z.union([z.number(), z.string()]).optional(),
  maxSaldo: z.union([z.number(), z.string()]).optional(),
});
// #endregion

// #region Exports
export type KanbanDebtor = z.infer<typeof KanbanDebtorSchema>;
export type DebtorColumn = z.infer<typeof DebtorColumnSchema>;
export type LoadDebtorColumnVariables = z.infer<typeof LoadDebtorColumnVariablesSchema>;
// #endregion

// #region Hook
export async function loadDebtorColumnQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;
  const { data } = await instance.get('kanban/debtors', { params: queryKey[1] });
  return DebtorColumnSchema.parse(data);
}

export const useLoadDebtorColumn = (_variables: LoadDebtorColumnVariables) => {
  const axiosInstance = useAxiosInstance();

  const variablesCompacted = pickBy(
    _variables,
    (val) => isNonNullish(val) && !isEmptyArrayOrString(val),
  );
  let variables: unknown;

  try {
    variables = LoadDebtorColumnVariablesSchema.parse(variablesCompacted);
  } catch (error) {
    variables = pick(_variables, ['column']) as LoadDebtorColumnVariables;
    console.error(error?.toString() ?? error);
    captureException(error);
  }

  const queryResult = useQuery<DebtorColumn, ApiError>({
    queryKey: ['debtor-kanban', variables],
    queryFn: (context) => loadDebtorColumnQueryFn(axiosInstance, context),
    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<DebtorColumn, ApiError, 'debtorColumn'>(
    'debtorColumn',
    queryResult,
  );
};
// #endregion

function isEmptyArrayOrString(arr: unknown) {
  if (Array.isArray(arr)) return arr.length === 0;
  if (typeof arr === 'string') return arr === '';

  return false;
}
