import classNames from 'classnames/bind';
import { PatternFormat } from 'react-number-format';

import styleIdentifiers from './tvaNumber.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  value: any;
  className?: string;
};

export default function TvaNumber({ className, value }: Props) {
  if (value && value.indexOf('BE') !== -1) {
    return (
      <span className={styles('TvaNumber', className)}>
        BE
        <PatternFormat value={value} displayType="text" format="#### ### ###" />
      </span>
    );
  }
  return value;
}
