import { GetTasksParams } from 'api/models';
import { i18nKeys } from 'locales';

export type TasksListFiltersFields = Omit<
  GetTasksParams,
  'page' | 'page_limit' | 'sort_by' | 'sort_order'
>;

export const GetTasksParamsFilters = {
  TITLE: 'title',
  TASK_TYPE_ID: 'task_type_id',
  DEBTOR: 'debtor',
  STATUS: 'status',
  ASSIGNEE: 'assignee',
  DUE_DATE: 'due_date',
};

export const TABLE_HEADERS = [
  {
    key: GetTasksParamsFilters.TITLE,
    title: i18nKeys.TASK.ATTRIBUTES.TITLE,
  },
  {
    key: GetTasksParamsFilters.TASK_TYPE_ID,
    title: i18nKeys.TASK.ATTRIBUTES.TASK_TYPE,
  },
  {
    key: GetTasksParamsFilters.DEBTOR,
    title: i18nKeys.TASK.ATTRIBUTES.DEBTOR,
  },
  {
    key: GetTasksParamsFilters.STATUS,
    title: i18nKeys.TASK.ATTRIBUTES.STATUS,
  },
  {
    key: GetTasksParamsFilters.ASSIGNEE,
    title: i18nKeys.TASK.ATTRIBUTES.ASSIGNEE,
  },
  {
    key: GetTasksParamsFilters.DUE_DATE,
    title: i18nKeys.DUE_DATE,
  },
  {
    title: i18nKeys.FORM.ACTIONS,
    width: 100,
  },
];
