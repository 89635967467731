import { AxiosInstance } from 'axios';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export async function archiveReportFn(axiosInstance: Promise<AxiosInstance>, id: string) {
  const instance = await axiosInstance;

  const data = instance.put(`/reports/export_tasks/${id}/archive`);
  return data;
}

export function useArchiveReport() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, string>({
    mutationFn: (id) => archiveReportFn(axiosInstance, id),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['reports'],
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, string, 'archiveReport'>(
    'archiveReport',
    mutation,
  );
}
