import { SendingMediaNames } from 'api/models';
import { removeAttributes } from 'shared/utils/view';
import { flattenItem } from 'store/reducers';
import { Debtor } from 'types/store/client-state';

import { treatRecoveryPlan } from './settings.serializer';

export const treatClient = (item: any) => {
  const newItem: Debtor = flattenItem(
    item,
    [],
    ['emails', 'account_manager', 'default_credit_note_template', 'default_invoice_template'],
  );

  if (newItem.account_manager) newItem.account_manager = removeAttributes(newItem.account_manager);
  if (newItem.default_credit_note_template) {
    newItem.default_credit_note_template = removeAttributes(newItem.default_credit_note_template);
  }
  if (newItem.default_invoice_template)
    newItem.default_invoice_template = removeAttributes(newItem.default_invoice_template);
  if ((newItem as any).recovery_plan) {
    newItem.recovery_plan =
      (newItem as any).recovery_plan.data === undefined
        ? (newItem as any).recovery_plan
        : (newItem as any).recovery_plan.data
          ? treatRecoveryPlan((newItem as any).recovery_plan.data)
          : null;
  }

  const sendingMedia = (newItem?.sending_media_attributes || []).map(({ name }) => name);

  if (sendingMedia.includes(SendingMediaNames.email)) newItem.byMail = true;
  if (sendingMedia.includes(SendingMediaNames.post)) newItem.byPost = true;

  return newItem;
};
