import { i18nKeys, useTranslation } from 'locales';

import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { IconLockAccess, IconStar, IconStarFilled } from '@tabler/icons-react';

import { ViewV2 } from '../../../../hooks';

interface ViewIconProps {
  view: ViewV2;
  onFavorite: (viewId: string) => void;
  onRemoveFavorite: (viewId: string) => void;
}

export const ViewIcon = ({ view, onFavorite, onRemoveFavorite }: ViewIconProps) => {
  const { t } = useTranslation();

  const theme = useMantineTheme();

  if (view.isDefault) {
    return (
      <Tooltip withArrow label={t(i18nKeys.VIEWS.SETTINGS.DEFAULT_LABEL)}>
        <IconLockAccess stroke={1.3} size="32px" color={theme.colors.gray[6]} />
      </Tooltip>
    );
  }

  return (
    <Tooltip disabled={view.isFavorite} withArrow label={t(i18nKeys.VIEWS.SETTINGS.MAKE_FAVORITE)}>
      {view.isFavorite ? (
        <ActionIcon variant="transparent" onClick={() => onRemoveFavorite(view.id)}>
          <IconStarFilled />
        </ActionIcon>
      ) : (
        <ActionIcon variant="transparent" onClick={() => onFavorite(view.id)}>
          <IconStar stroke={1.5} color={theme.colors.gray[6]} />
        </ActionIcon>
      )}
    </Tooltip>
  );
};
