import { useEffect, useState } from 'react';
import { useUpdateInvoice } from 'api';
import { Id } from 'api/models';
import CustomVariables from 'app/Private/CustomVariables/CustomVariables';
import CustomVariablesForm from 'app/Private/CustomVariables/CustomVariablesForm/CustomVariablesForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ActivityDetail from 'shared/components/ActivityDetail';
import Conversation from 'shared/components/ActivityDetail/Conversation';
import Card from 'shared/components/Card';
import DebtorInfo from 'shared/components/DebtorInfo';
import { Icon, IconName } from 'shared/components/Icon';
import UnmatchPayment from 'shared/components/InvoiceActionsDropdown/UnmatchPayment';
import UnmatchCreditNote from 'shared/components/InvoiceActionsDropdown/UnmatchPayment/UnmatchCreditNote';
import StatusItem from 'shared/components/StatusItem';
import { Button } from 'shared/io';
import { treatActivity } from 'shared/serializer';
import { accountActions } from 'store/account/account.actions';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';
import { InvoiceFeedback } from 'types/store/activity';
import { CreditNote } from 'types/store/credit-note-state';
import { Invoice, NextStepInformation } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import InvoiceDebtorSituation from './InvoiceDebtorSituation/InvoiceDebtorSituation';
import InvoiceDetailComment from './InvoiceDetailComment/InvoiceDetailComment';
import InvoiceDetailHeader from './InvoiceDetailHeader/InvoiceDetailHeader';
import InvoiceTimeline from './InvoiceTimeline/InvoiceTimeline';
import InvoiceDetailContext from './InvoiceDetail.context';
import InvoiceInfo from './InvoiceInfo';
import InvoiceRecoveryPlan from './InvoiceRecoveryPlan';
import { LatePaymentInfo } from './LatePaymentInfo';
import ProductsDetail from './ProductsDetail';

import styleIdentifiers from './ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

const nextStepTitle = {
  Callcenter: 'THIRD_PARTY_CASES.CASE_TYPE.CALLCENTER',
  Lawyer: 'THIRD_PARTY_CASES.CASE_TYPE.LAWYER',
  Bailiff: 'THIRD_PARTY_CASES.CASE_TYPE.BAILIFF',
  'Debt collector': 'THIRD_PARTY_CASES.CASE_TYPE.DEBT_COLLECTOR', // I know. -Leo.
  common: 'INVOICE.NEXT_STEP',
};

type Props = {
  // todo: refactor: the parent should not be responsible for providing the type of the entity
  isCreditNote?: boolean;
  id?: number | string;
  modal?: boolean;
};

export default function ToHandleDetail({ isCreditNote, id, modal }: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedActivityId, setSelectedActivityId] = useState<Id | null>();
  const { params } = useRouteMatch<{ id: string; activity_id: string }>();
  const { mutate: updateInvoice } = useUpdateInvoice();

  const creditNote = useSelector((state: StoreState) => state.creditNote.current?.data);
  const invoice = useSelector((state: StoreState) => state.invoice.current?.data);
  const activities = useSelector(
    (state: StoreState) => state[isCreditNote ? 'creditNote' : 'invoice'].currentActivity?.data,
  );
  const activitiesMetadata = useSelector(
    (state: StoreState) => state[isCreditNote ? 'creditNote' : 'invoice'].currentActivity?.metadata,
  );

  const data = isCreditNote ? creditNote : invoice;

  useEffect(() => {
    const idResource = id ?? params.id;
    const action = isCreditNote ? creditNoteActions : invoiceActions;

    action.info({
      id: idResource,
      callback: ({ data: _data }) => {
        if (_data.attributes.draft) {
          if (modal) dialogHide(DialogShowId.INVOICE);
          history.push(`/${isCreditNote ? 'credit-notes' : 'invoices'}/detail/${idResource}`);
        }
      },
    });
  }, []);

  const queryString = qs.parse(location.search);
  const activityId = queryString.activityId ?? selectedActivityId;

  useEffect(() => {
    if (activityId) {
      accountActions.activityDetail({
        id: Number(activityId),
        callback: (res: any) => {
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            children: <ActivityDetail activity={treatActivity(res.data)} />,
            onClose: () => {
              setSelectedActivityId(null);
              const url = new URL(window.location.href);
              url.searchParams.delete('activityId');
              window.history.pushState({}, '', url.toString());
            },
          });
        },
      });
    }
  }, [activityId]);

  useEffect(() => {
    if (queryString.show_case && data && activities) {
      history.push(location.pathname);
      // eslint-disable-next-line
      showConversation(data)();
    }
  }, [activities, data]);

  if (!data) {
    return null;
  }

  const reloadInfo = () => {
    if (isCreditNote) {
      creditNoteActions.info({
        id: data.id,
        noReset: true,
        noLoading: true,
      });
    } else {
      invoiceActions.info({
        id: data.id,
        noReset: true,
        noLoading: true,
      });
    }
  };

  const getMoreActivities = () => {
    invoiceActions.getActivityPage({
      id: data.id,
      noLoading: true,
      data: {
        page: activitiesMetadata.pagination.current_page + 1,
      },
    });
  };

  const onSendMessage = () => {
    reloadInfo();
  };

  const showConversation = (currentInvoice: Invoice) => () => {
    if (activities) {
      const invoiceFeedback = activities?.find(
        (activity) => activity.invoice_feedback,
      )?.invoice_feedback;

      const title = (currentInvoice as any).next_step_information.type;

      showDialog({
        id: DialogShowId.CUSTOM,
        size: DialogShowSize.MEDIUM,
        title: t(nextStepTitle[title]),
        children: (
          <div style={{ padding: 20 }}>
            {invoiceFeedback != null ? (
              <Conversation
                invoiceId={data.id}
                onSubmit={onSendMessage}
                conversation={invoiceFeedback!}
              />
            ) : (
              <Conversation
                onSubmit={onSendMessage}
                invoiceId={data.id}
                conversation={
                  {
                    conversation: (currentInvoice.next_step_information as NextStepInformation)
                      .conversation!,
                    id: (currentInvoice.next_step_information as NextStepInformation)
                      .invoice_feedback_id,
                  } as InvoiceFeedback
                }
              />
            )}
          </div>
        ),
      });
    }
  };

  const unmatchPayment = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(i18nKeys.PAYMENT),
      children: isCreditNote ? (
        <UnmatchCreditNote reload={reloadInfo} creditNote={data as CreditNote} />
      ) : (
        <UnmatchPayment reload={reloadInfo} invoice={data as Invoice} />
      ),
    });
  };

  const clientData = data?.debtor_attributes;

  const editInvoice = (values) => {
    if (isCreditNote) {
      creditNoteActions.update({
        data: {
          id: data.id,
          ...values,
        },
        callback: () => {
          sideMenuHide();
          (reloadInfo as any)();
        },
      });
    } else {
      updateInvoice(
        { id: data.id, data: values },
        {
          onSuccess: () => {
            sideMenuHide();
            (reloadInfo as any)();
          },
        },
      );
    }
  };

  const handleCustomVariables = () => {
    sideMenuShow({
      unmount: true,
      content: <CustomVariablesForm onSubmit={editInvoice} initialValues={data} />,
    });
  };

  return (
    <InvoiceDetailContext.Provider
      value={{
        data,
        credit: isCreditNote,
        unmatchPayment,
        clientData,
        reloadInfo,
      }}
    >
      <div className={styles('ToHandleDetail', data && modal && 'modal')}>
        {data && !data.draft && (
          <div className={styles('invoice-wrapper')}>
            <InvoiceDetailHeader />
            <Card noHead className={styles('information-card')}>
              {isCreditNote || data.step_collection === null ? (
                <div className={styles('check')}>
                  {data.status} {data.paid && <Icon name={IconName.CHECK} />}
                  {!isCreditNote &&
                    (data.next_step_information as NextStepInformation)?.conversation && (
                      <Button
                        onClick={showConversation(data)}
                        noMargin
                        noShadow
                        small
                        label={t(i18nKeys.SHOW_CONVERSATION)}
                      />
                    )}
                </div>
              ) : (
                <>
                  <span>
                    <StatusItem step={data.step_collection} isLate />
                  </span>
                  <span>
                    {data.status}
                    {(data.next_step_information as NextStepInformation)?.conversation && (
                      <Button
                        onClick={showConversation(data)}
                        noMargin
                        noShadow
                        small
                        label={t(i18nKeys.SHOW_CONVERSATION)}
                      />
                    )}
                  </span>
                </>
              )}
            </Card>
            <div className={styles('line-wrapper')}>
              <InvoiceInfo modal={modal} />
              <LatePaymentInfo />
              {clientData && (
                <>
                  <DebtorInfo clientData={clientData} invoiceData={data} />
                  <InvoiceDebtorSituation />
                </>
              )}
            </div>
            {Object.keys(data.custom_variables).length > 0 && (
              <CustomVariables
                data={data.custom_variables}
                handleSettings={handleCustomVariables}
                invoiceView
              />
            )}
            <InvoiceTimeline
              nextAction={
                !data.paid && (data.next_step_information as NextStepInformation)?.description
              }
              metadata={activitiesMetadata}
              activities={activities}
              invoiceId={data.id}
              seeMore={getMoreActivities}
              onClickActivity={setSelectedActivityId}
            />
            {!isCreditNote && (
              <>
                <InvoiceRecoveryPlan />
                <InvoiceDetailComment />
              </>
            )}
            {data.product_lines_attributes.length > 0 && (
              <ProductsDetail products={data.product_lines_attributes} />
            )}
          </div>
        )}
      </div>
    </InvoiceDetailContext.Provider>
  );
}
