/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type Operator = (typeof Operator)[keyof typeof Operator];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Operator = {
  equal_to: 'equal_to',
  other_than: 'other_than',
  less_than: 'less_than',
  less_than_or_equal_to: 'less_than_or_equal_to',
  greater_than: 'greater_than',
  greater_than_or_equal_to: 'greater_than_or_equal_to',
} as const;
