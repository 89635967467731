import { Button, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

const styles = {
  root: {
    height: 40,
    width: '100%',
  },
  inner: {
    justifyContent: 'flex-start',
  },
  label: {
    fontFamily: 'inherit',
  },
};

export type NavBackButtonProps = {
  label: string;
  onClick: () => void;
};

export const NavBackButton = ({ label, onClick }: NavBackButtonProps) => (
  <Button
    variant="default"
    fullWidth
    mb={4}
    styles={styles}
    radius={8}
    leftSection={<IconArrowLeft color="#4D4D4D" size={16} />}
    onClick={onClick}
  >
    <Text c="#4D4D4D">{label}</Text>
  </Button>
);
