import { jotaiStore } from 'init';
import { i18nKeys, translationStateAtom } from 'locales';
import { all, call, takeEvery } from 'redux-saga/effects';
import { viewActionTypes as events } from 'store/view/view.actions';

import { notifications } from '@mantine/notifications';

function* DisplaySuccess({ payload }: any) {
  const { t } = jotaiStore.get(translationStateAtom);

  yield notifications.show({
    title: t(i18nKeys.SUCCESS),
    color: 'green',
    message: `${payload.text ? t(payload.text) : ''}\n${
      typeof payload.data === 'string' ? payload.data : ''
    }`,
  });
}

function* DisplayErrors({ payload }: any) {
  const { t } = jotaiStore.get(translationStateAtom);

  if (!payload.data && !payload.text) return;

  let concatenatedMessages = null;
  if (Array.isArray(payload.data)) {
    concatenatedMessages = payload.data.join('\n');
  }

  yield notifications.show({
    title: t(i18nKeys.ERROR.ERROR),
    color: 'red',
    message: `${payload.text ? t(payload.text) : ''}\n${
      typeof payload.data === 'string' ? payload.data : concatenatedMessages
    }`,
  });
}

function* ViewWatchers() {
  yield all([
    yield takeEvery(events.notification.error, DisplayErrors),
    yield takeEvery(events.notification.success, DisplaySuccess),
  ]);
}

export default function* saga() {
  yield call(ViewWatchers);
}
