import React from 'react';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useParams } from 'react-router';
import { chunk, isEmpty, partition } from 'remeda';
import { useArchiveReport, useLoadLightUsers } from 'shared/hooks';
import { useLoadReport } from 'shared/hooks/use-load-report';
import { PageTitle } from 'shared/layout';

import { css } from '@emotion/css';
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Group,
  List,
  Pagination,
  Space,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { upperFirst, useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import {
  IconArrowLeft,
  IconAt,
  IconCalendarMonth,
  IconEdit,
  IconFileDownload,
  IconReport,
  IconRotate,
  IconTrash,
  IconUser,
} from '@tabler/icons-react';

import {
  EditReportNameModal,
  EditReportRecipientsModal,
  EditReportRecurrenceModal,
} from './components';

const DAY_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const styles = {
  listIconFix: css`
    .mantine-List-itemWrapper {
      align-items: normal;
    }
  `,
};

export const Report = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useMantineTheme();
  const { reportId } = useParams<{ reportId: string }>();
  const { report, isReportLoading } = useLoadReport(reportId);
  const { users = [] } = useLoadLightUsers();
  const { archiveReport, isArchiveReportLoading } = useArchiveReport();
  const [isEmailListUnfolded, setIsEmailListUnfolded] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [isEditNameModalOpen, editNameModalHandlers] = useDisclosure(false);
  const [isEditRecurrenceModalOpen, editRecurrenceModalHandlers] = useDisclosure(false);
  const [isEditRecipientsModalOpen, editRecipientsModalHandlers] = useDisclosure(false);

  if (isReportLoading) {
    return <div>Loading...</div>;
  }

  if (report == null) {
    console.error('Report empty even though loading is done');
    return null;
  }

  const handleDelete = () => {
    modals.openConfirmModal({
      centered: true,
      title: t(i18nKeys.REPORTS.SHOW.DELETE_REPORT),
      children: <Text>{t(i18nKeys.REPORTS.SHOW.AYS_DELETE)}</Text>,
      labels: { cancel: t(i18nKeys.CANCEL), confirm: t(i18nKeys.DELETE) },
      confirmProps: { color: 'red', loading: isArchiveReportLoading },
      onConfirm: () => archiveReport(report.id),
      onCancel: modals.closeAll,
    });
  };

  const days =
    report.periodicity.type === 'weekly'
      ? report.periodicity.days
          .map((day) => t(i18nKeys.DATES.DAY_NAMES[DAY_OF_WEEK[day - 1].toUpperCase()]))
          .join(', ')
      : report.periodicity.days
          .map((day) => {
            const fakeDate = dayjs().month(0).date(day);
            return fakeDate.format('Do');
          })
          .join(', ');

  const userEmails = users.map((user) => user.email);
  const [internalEmails, externalEmails] = partition(report.emails, (email) =>
    userEmails.includes(email),
  );
  const sortedEmails = [...internalEmails, ...externalEmails];
  const iconExternalEmail = <IconAt stroke={1.25} size="1rem" color={theme.colors.gray[5]} />;

  const exportsSorted = [...report.exports].reverse();

  const pages = chunk(exportsSorted, 20);

  return (
    <>
      <EditReportNameModal
        isOpen={isEditNameModalOpen}
        onClose={editNameModalHandlers.close}
        report={report}
      />
      <EditReportRecurrenceModal
        isOpen={isEditRecurrenceModalOpen}
        onClose={editRecurrenceModalHandlers.close}
        report={report}
      />
      <EditReportRecipientsModal
        isOpen={isEditRecipientsModalOpen}
        onClose={editRecipientsModalHandlers.close}
        report={report}
      />
      <PageTitle>
        <ActionIcon onClick={() => history.push('/reports')} size="lg" variant="light">
          <IconArrowLeft stroke={1.5} />
        </ActionIcon>
        {report.name}
        <PageTitle.Actions>
          <Button
            onClick={handleDelete}
            color="red"
            rightSection={<IconTrash size={18} stroke={1.5} />}
          >
            {t(i18nKeys.DELETE)}
          </Button>
        </PageTitle.Actions>
      </PageTitle>
      <Card radius="md" shadow="sm">
        <Card.Section inheritPadding withBorder py="xs" mb="md">
          <Text fw={500} size="lg" ff="Roboto">
            {t(i18nKeys.REPORTS.SHOW.DETAILS)}
          </Text>
        </Card.Section>
        <Group align="start">
          <Box mt={-5} mr="lg">
            <IconReport size="120" stroke={0.5} color={theme.colors.blue[6]} />
          </Box>
          <Box flex={1}>
            <Group align="top" justify="space-between">
              <Text size="lg" c="dimmed" fw={500} mb="sm">
                {t(i18nKeys.REPORTS.SHOW.NAME)}
              </Text>
              <Button
                onClick={editNameModalHandlers.open}
                size="xs"
                color="blue.4"
                variant="light"
                leftSection={<IconEdit size={16} stroke={1.5} />}
              >
                {t(i18nKeys.REPORTS.INDEX.ACTIONS.EDIT)}
              </Button>
            </Group>
            <Text>{report.name}</Text>
          </Box>
          <Divider orientation="vertical" />
          <Box flex={1.5}>
            <Group align="top" justify="space-between">
              <Text size="lg" c="dimmed" fw={500} mb="sm">
                {t(i18nKeys.REPORTS.SHOW.RECURRENCE)}
              </Text>
              {/* TODO: extract button */}
              <Button
                onClick={editRecurrenceModalHandlers.open}
                size="xs"
                color="blue.4"
                variant="light"
                leftSection={<IconEdit size={16} stroke={1.5} />}
              >
                {t(i18nKeys.REPORTS.INDEX.ACTIONS.EDIT)}
              </Button>
            </Group>
            <Group mb="xs" gap="xs">
              <IconRotate color={theme.colors.gray[5]} stroke={1.7} size="1.25rem" />
              <Text c="gray.8">
                {t(i18nKeys.REPORTS.RECURRENCE[report.periodicity.type.toUpperCase()])}
              </Text>
            </Group>
            <Group gap="xs" wrap="nowrap" align="start">
              {/* div required here so the svg doesn't get squished by the text when it waps */}
              <div>
                <IconCalendarMonth
                  color={theme.colors.gray[5]}
                  stroke={1.7}
                  size={20}
                  style={{ marginTop: '2px' }}
                />
              </div>
              <Text c="gray.8" span>
                {days}
              </Text>
            </Group>
          </Box>
          <Divider orientation="vertical" />
          <Box flex={1.5}>
            <Group align="top" justify="space-between">
              <Text size="lg" c="dimmed" fw={500} mb="sm">
                {t(i18nKeys.REPORTS.SHOW.RECIPIENTS)}
              </Text>
              <Button
                onClick={editRecipientsModalHandlers.open}
                size="xs"
                color="blue.4"
                variant="light"
                leftSection={<IconEdit size={16} stroke={1.5} />}
              >
                {t(i18nKeys.REPORTS.INDEX.ACTIONS.EDIT)}
              </Button>
            </Group>
            <List
              className={styles.listIconFix}
              mb={4}
              spacing={6}
              c="gray.8"
              size="sm"
              center
              icon={<IconUser size="1rem" stroke={1.25} color={theme.colors.gray[5]} />}
            >
              {sortedEmails.slice(0, isEmailListUnfolded ? Infinity : 4).map((email) => (
                <List.Item
                  key={email}
                  icon={externalEmails.includes(email) ? iconExternalEmail : undefined}
                >
                  {email}
                </List.Item>
              ))}
            </List>
            {sortedEmails.length > 4 && (
              <Anchor onClick={() => setIsEmailListUnfolded((state) => !state)} size="sm">
                {isEmailListUnfolded ? 'See less' : `See ${sortedEmails.length - 4} more`}
              </Anchor>
            )}
          </Box>
        </Group>
      </Card>
      <Space h="lg" />
      <Card radius="md" shadow="sm">
        <Card.Section inheritPadding withBorder py="xs">
          <Text fw={500} size="lg" ff="Roboto">
            Exports
          </Text>
        </Card.Section>
        <Card.Section withBorder={!isEmpty(pages)}>
          {isEmpty(pages) ? (
            <Center h={200}>
              <Text>{t(i18nKeys.REPORTS.SHOW.NO_EXPORTS_YET)}</Text>
            </Center>
          ) : (
            <Table verticalSpacing="xs" striped>
              <Table.Tbody>
                {pages[page - 1].map(({ executionDate, file }) => (
                  <Table.Tr key={executionDate.toString()}>
                    <Table.Td>
                      <Text>
                        {upperFirst(executionDate.format('dddd MMMM D, YYYY [at] hh:mm'))}
                      </Text>
                    </Table.Td>
                    <Table.Td ta="right">
                      <Button
                        component="a"
                        href={file}
                        size="xs"
                        variant="light"
                        rightSection={<IconFileDownload stroke={1.5} size="1.2rem" />}
                      >
                        {t(i18nKeys.DOWNLOAD)}
                      </Button>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          )}
        </Card.Section>
        <Card.Section
          inheritPadding
          withBorder
          py="xs"
          style={{ display: isEmpty(report.exports) ? 'none' : 'block' }}
        >
          <Group w="100%" justify="flex-end">
            <Pagination
              disabled={pages.length === 1}
              total={pages.length}
              value={page}
              onChange={setPage}
              size="sm"
            />
          </Group>
        </Card.Section>
      </Card>
    </>
  );
};
