import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Button, ButtonColor } from 'shared/io';
import { OnClickFunction } from 'types/html-type';

import styleIdentifiers from './FiltersSideMenu.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onRemoveFilter: OnClickFunction;
};

export default function FiltersSideMenu({ children, onRemoveFilter }: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  return (
    <div className={styles('filters-side-menu')}>
      <div className={styles('head')}>{t(i18nKeys.FILTER)}</div>
      <div className={styles('content')}>{children}</div>
      <div className={styles('button-wrapper')}>
        <Button
          onClick={onRemoveFilter}
          color={ButtonColor.GREY}
          noMargin
          label={t(i18nKeys.FORM.DELETE_FILTERS)}
        />
      </div>
    </div>
  );
}
