import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  addActionNameToMutationResult,
  ApiError,
  ApiResponse,
  useLegacyAxiosInstance,
} from './utils';

export const UpdateTaskFormSchema = z.object({ autoclose_tasks: z.boolean() });

export type UpdateTaskForm = z.infer<typeof UpdateTaskFormSchema>;

export async function updateTaskFn(axiosInstance: Promise<AxiosInstance>, data: UpdateTaskForm) {
  const instance = await axiosInstance;

  return instance.put('/company_configurations', UpdateTaskFormSchema.parse(data));
}

export function useUpdateTaskSettings() {
  const client = useQueryClient();
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation({
    mutationFn: (data: UpdateTaskForm) => updateTaskFn(axiosInstance, data),
    onSuccess: (_) => {
      notifications.show({
        message: 'Task updated',
        color: 'green',
      });
      client.invalidateQueries({ queryKey: ['taskSettings'] });
    },
    onError: (error: ApiError) => {
      (error as any).response?.data.error_message.forEach((message: string) => {
        notifications.show({
          title: 'Error',
          color: 'red',
          message,
        });
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, UpdateTaskForm, 'updateTaskSetting'>(
    'updateTaskSetting',
    mutation,
  );
}
