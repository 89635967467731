import { useState } from 'react';
import { DebtorComplete } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AddressBlock from 'shared/components/AddressBlock';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Button, Checkbox, DropdownDirection } from 'shared/io';

import styleIdentifiers from './AttributesSelection.module.scss';

const styles = classNames.bind(styleIdentifiers);

type AttributesSelectionProps = {
  baseDebtorId: string;
  debtors: DebtorComplete[];
  onSubmit: Function;
};

export const AttributesSelection = ({
  baseDebtorId,
  debtors,
  onSubmit,
}: AttributesSelectionProps) => {
  const { t } = useTranslation();

  const [vatToKeep, setVatToKeep] = useState<string>(baseDebtorId);
  const [emailsToKeep, setEmailsToKeep] = useState<string[]>([baseDebtorId]);
  const [phonesToKeep, setPhonesToKeep] = useState<string[]>([baseDebtorId]);
  const [addressToKeep, setAddressToKeep] = useState<string>(baseDebtorId);
  const [bankAccountsToKeep, setBankAccountsToKeep] = useState<string[]>([baseDebtorId]);

  const baseDebtor = debtors.find((debtor) => debtor.id === baseDebtorId);
  const mergedDebtors = debtors.filter((debtor) => debtor.id !== baseDebtorId);
  const findDebtor = (id) => debtors.find((debtor) => debtor.id === id);
  const keptDebtors = (ids) => debtors.filter((debtor) => ids.includes(debtor.id));

  const addressToKeepAttributes = findDebtor(addressToKeep)!.attributes.address?.attributes;

  const { street_name, street_number, street_box, zip_code, country } = {
    ...addressToKeepAttributes,
  };
  const streetString = `${street_name} ${street_number} ${
    street_box && t(i18nKeys.FORM.ADDRESS.BOX)
  } ${street_box}, ${zip_code} ${country}`;

  const renderBic = (bankAccount, withSpan = false) => {
    const bic = bankAccount.bic || bankAccount.attributes?.bic;
    if (!bic) return '';
    return withSpan ? (
      <>
        <span>BIC: </span>
        {bic}
      </>
    ) : (
      `(${bic})`
    );
  };

  return (
    <div className={styles('confirm-merge-modal')}>
      <p className={styles('info')}>{t(i18nKeys.CLIENT.MERGE.DESCRIPTION)}</p>
      <table>
        <thead>
          <tr>
            <th />
            {debtors.map((debtor) => (
              <th key={debtor.id}>{debtor.attributes.full_name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t(i18nKeys.VAT_NUMBER)}</td>
            {debtors.map((debtor) => (
              <td key={debtor.id} className={styles(!debtor.attributes.vat_number && 'empty')}>
                {debtor.attributes.vat_number ? (
                  <Checkbox
                    noMargin
                    checked={debtor.id === vatToKeep}
                    label={debtor.attributes.vat_number}
                    onChange={() => {
                      setVatToKeep(debtor.id as string);
                    }}
                  />
                ) : (
                  '#N/A'
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>{t(i18nKeys.EMAILS)}</td>
            {debtors.map((debtor) => (
              <td
                key={debtor.id}
                className={styles(debtor.attributes.emails.length === 0 && 'empty')}
              >
                {debtor.attributes.emails.length > 0 ? (
                  <Checkbox
                    noMargin
                    checked={emailsToKeep.includes(debtor.id as string)}
                    label={debtor.attributes.emails.join(', ')}
                    onChange={() => {
                      let arr = Object.values(emailsToKeep);
                      if (arr.includes(debtor.id as string)) {
                        arr = arr.filter((el) => el !== debtor.id);
                      } else {
                        arr.push(debtor.id as string);
                      }
                      setEmailsToKeep(arr);
                    }}
                  />
                ) : (
                  '#N/A'
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>{t(i18nKeys.PHONES)}</td>
            {debtors.map((debtor) => (
              <td
                key={debtor.id}
                className={styles(debtor.attributes.phones.length === 0 && 'empty')}
              >
                {debtor.attributes.phones.length > 0 ? (
                  <Checkbox
                    noMargin
                    checked={phonesToKeep.includes(debtor.id as string)}
                    label={debtor.attributes.phones
                      .map((phone) => phone.attributes.number)
                      .join(', ')}
                    onChange={() => {
                      let arr = Object.values(phonesToKeep);
                      if (arr.includes(debtor.id as string)) {
                        arr = arr.filter((el) => el !== debtor.id);
                      } else {
                        arr.push(debtor.id as string);
                      }
                      setPhonesToKeep(arr);
                    }}
                  />
                ) : (
                  '#N/A'
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>{t(i18nKeys.ADDRESS)}</td>
            {debtors.map((debtor) => (
              <td
                key={debtor.id}
                className={styles(!debtor.attributes.address?.attributes && 'empty')}
              >
                {debtor.attributes.address?.attributes ? (
                  <Checkbox
                    noMargin
                    checked={debtor.id === addressToKeep}
                    label={
                      <AddressBlock
                        address={debtor.attributes.address}
                        className={styles('address')}
                        clean
                      />
                    }
                    onChange={() => {
                      setAddressToKeep(debtor.id as string);
                    }}
                  />
                ) : (
                  '#N/A'
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>{t(i18nKeys.COMMON.BANK_ACCOUNT)}</td>
            {debtors.map((debtor) => (
              <td
                key={debtor.id}
                className={styles(debtor.attributes.debtor_bank_accounts.length === 0 && 'empty')}
              >
                {debtor.attributes.debtor_bank_accounts.length > 0 ? (
                  <Checkbox
                    noMargin
                    checked={bankAccountsToKeep.includes(debtor.id as string)}
                    onChange={() => {
                      let arr = Object.values(bankAccountsToKeep);
                      if (arr.includes(debtor.id as string)) {
                        arr = arr.filter((el) => el !== debtor.id);
                      } else {
                        arr.push(debtor.id as string);
                      }
                      setBankAccountsToKeep(arr);
                    }}
                  >
                    {(debtor.attributes.debtor_bank_accounts as any).map((bankAccount) => (
                      <p key={bankAccount.id} className={styles('bank-accounts')}>
                        <span>IBAN: </span>
                        {bankAccount.iban || bankAccount.attributes?.iban}{' '}
                        {renderBic(bankAccount, true)}
                      </p>
                    ))}
                  </Checkbox>
                ) : (
                  '#N/A'
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <div className={styles('additional-info')}>
        <h3>
          {mergedDebtors.map((debtor) => debtor.attributes.full_name).join(', ')}{' '}
          {t(i18nKeys.WILL_BE_MERGED.SINGLE)} {baseDebtor?.attributes.full_name}
        </h3>
        <ol>
          <li>
            <div style={{ textAlign: 'left' }}>
              {t(i18nKeys.WILL_BE_MERGED.FINAL_DATA)}
              <ul>
                <li>
                  <b>{t(i18nKeys.VAT_NUMBER)}</b>: {findDebtor(vatToKeep)!.attributes.vat_number}
                </li>
                <li>
                  <b>{t(i18nKeys.EMAILS)}</b>:{' '}
                  {keptDebtors(emailsToKeep)
                    .map((debtor) => debtor.attributes.emails.join(', '))
                    .join(', ')}
                </li>
                <li>
                  <b>{t(i18nKeys.PHONES)}</b>:{' '}
                  {keptDebtors(phonesToKeep)
                    .map((debtor) =>
                      debtor.attributes.phones.map((phone) => phone.attributes.number).join(', '),
                    )
                    .join(', ')}
                </li>
                <li>
                  <b>{t(i18nKeys.ADDRESS)}</b>: {addressToKeepAttributes && streetString}
                </li>
                <li>
                  <b>{t(i18nKeys.COMMON.BANK_ACCOUNT)}</b>:{' '}
                  {keptDebtors(bankAccountsToKeep)
                    .map((debtor) =>
                      (debtor.attributes.debtor_bank_accounts as any)
                        .map(
                          (bankAccount) =>
                            `${bankAccount.iban || bankAccount.attributes.iban} ${renderBic(
                              bankAccount,
                            )}`,
                        )
                        .join(', '),
                    )
                    .join(', ')}
                </li>
              </ul>
            </div>
          </li>
          <li>
            <p>
              {t(i18nKeys.WILL_BE_MERGED.MODELS_MERGED)}{' '}
              {t(i18nKeys.WILL_BE_MERGED.OF_OTHER_CLIENT)}{' '}
              {t(i18nKeys.WILL_BE_MERGED.WILL_BE_ASSOCIATED_TO)}{' '}
              <b>{baseDebtor?.attributes.full_name}</b>
            </p>
          </li>
        </ol>
      </div>
      <div className={styles('button-wrapper')}>
        <Tooltip
          icon={IconName.INFO}
          iconClassName={styles('icon')}
          direction={DropdownDirection.TOP}
        >
          {t(i18nKeys.FORM.WARNING_IRREVERSIBLE_ACTION)}
        </Tooltip>
        <Button
          noMargin
          label={t(i18nKeys.CONFIRM)}
          disabled={
            phonesToKeep.length === 0 ||
            emailsToKeep.length === 0 ||
            vatToKeep === '' ||
            addressToKeep === ''
          }
          onClick={() =>
            onSubmit({
              base_debtor_id: baseDebtorId,
              debtors_to_delete_id: debtors.map((d) => d.id).filter((id) => id !== baseDebtorId),
              vat_number: vatToKeep,
              emails: emailsToKeep,
              phones: phonesToKeep,
              address: addressToKeep,
              bank_accounts: bankAccountsToKeep,
            })
          }
        />
      </div>
    </div>
  );
};
