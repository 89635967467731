import { OnBoardingState } from 'types/store/onboarding-state';
import { APIReturn } from 'types/storeTypes';

import { onBoardingConstants } from './onBoarding.actions';

export const initialState: OnBoardingState = {
  codaboxMessage: '',
  isLoading: false,
};

const reducer = (state = initialState, action: APIReturn) => {
  switch (action.type) {
    case onBoardingConstants.changeCodaboxMessage.request:
      return {
        ...state,
        codaboxMessage: action.payload,
      };
    case onBoardingConstants.codaboxToken.request:
      return {
        ...state,
        isLoading: true,
      };
    case onBoardingConstants.codaboxToken.result:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
