import { globalCss } from '@stitches/react';

import 'styles/main.module.scss';
// Mantine
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/tiptap/styles.css';

globalCss({
  // Fix for faulty spotlight css
  '[data-selected="true"]': {
    color: 'white !important',
    backgroundColor: '#228be6 !important',
  },
})();
