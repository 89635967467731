import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, get, useForm } from 'react-hook-form';
import { Button, ButtonColor, CustomSelect, Input, VatInput } from 'shared/io';
import { appActions } from 'store/app/app.actions';
import { onSubmitFunction } from 'types/react-hook-form';

import AddressForm from './AddressForm/AddressForm';

import styleIdentifiers from './GeneralInfoForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
};

// STEP 1
export default function GeneralInfoForm({ onSubmit }: Props) {
  const { t } = useTranslation();

  const form = useForm({ shouldUnregister: true });

  const { watch, setValue } = form;

  const vatNumber = watch('vatNumber');

  const getAddress = () => {
    appActions.fetchAddress({
      data: {
        vat_number: vatNumber,
      },
      callback: (res: any) => {
        const keys = Object.keys(res);
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i];
          const elem = res[key];
          if (key === 'company_name') continue;
          const camelCaseKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
          setValue(`addressAttributes.${camelCaseKey}`, elem);
        }
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form className={styles('general-info-form')} onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid-row">
          <div className={styles('col-12', 'flex')}>
            <div className={styles('col-6')}>
              <Input
                errorMessage={get(form.formState.errors, 'companyEmail')}
                className={styles('input')}
                withBorder
                register={form.register('companyEmail', {
                  required: true,
                })}
                type="text"
                label={t(i18nKeys.COMPANY_EMAIL)}
              />
            </div>
            <div className={styles('col-6')}>
              <Input
                errorMessage={get(form.formState.errors, 'phoneNumber')}
                className={styles('input')}
                withBorder
                register={form.register('phoneNumber', {
                  required: true,
                })}
                type="text"
                label={t(i18nKeys.PHONE)}
              />
            </div>
            <div className={styles('col-6')}>
              <CustomSelect
                selectClassName={styles('input')}
                keyText="description"
                keyValue="value"
                withBorder
                name="locale"
                items={[
                  { value: 'FR', description: 'Francais' },
                  { value: 'EN', description: 'English' },
                  { value: 'NL', description: 'Nederlands' },
                ]}
                label={t(i18nKeys.COMPANY_LOCALE)}
              />
            </div>
            <div className={styles('col-6')}>
              <CustomSelect
                selectClassName={styles('input')}
                keyText="description"
                keyValue="value"
                withBorder
                name="currency"
                items={[
                  { description: 'Euro', value: 'EUR' },
                  { description: 'US Dollar', value: 'USD' },
                  { description: 'British Pound', value: 'GBP' },
                ]}
                label={t(i18nKeys.CURRENCY)}
              />
            </div>
          </div>
          <div className={styles('col-12')}>
            <div className={styles('col-6')}>
              <VatInput
                className={styles('input', 'vat-input')}
                withBorder
                name="vatNumber"
                label={t(i18nKeys.FORM.VAT)}
                noDropdownMargin
              />
              {typeof vatNumber === 'string' && !vatNumber.includes('_') && (
                <div className={styles('info')}>
                  <Button
                    noMargin
                    noShadow
                    color={ButtonColor.BLUE}
                    className={styles('link')}
                    label={t(i18nKeys.FORM.CLIENT.PRE_FILL)}
                    onClick={getAddress}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <AddressForm />
          </div>
        </div>
        <Button
          className={styles('button')}
          label={t(i18nKeys.ONBOARDING.NEXT_STEP)}
          type="submit"
        />
      </form>
    </FormProvider>
  );
}
