import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CustomSelect, Input } from 'shared/io';
import { OnChangeEvent } from 'types/html-event';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './AmountFields.module.scss';

const styles = classNames.bind(styleIdentifiers);

type AmountFieldsProps = {
  className?: string;
  label?: string;
  labelOperator?: any;
  placeholder?: string;
  name: string;
  operatorName?: string;
  noMin?: boolean;
  onChange?: OnChangeEvent;
};

export const AmountFields = ({
  className,
  label,
  labelOperator,
  placeholder,
  name,
  operatorName,
  noMin,
  onChange,
}: AmountFieldsProps) => {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);
  const operator_name = operatorName || `${name}_operator`;

  const { register } = useFormContext();

  return (
    <div className={styles('AmountFields', className)}>
      {label && <div className={styles('label-input')}>{label}</div>}
      <div className={styles('fields')}>
        <Controller
          defaultValue=""
          name={operator_name}
          render={() => (
            <CustomSelect
              onValueChanged={onChange}
              selectClassName={styles('operator')}
              size="small"
              noMargin
              items={constants.operators}
              name={operator_name}
              placeholder={labelOperator || t(i18nKeys.FORM.CHOICE)}
              keyText="description"
              keyValue="value"
              noBorder
            />
          )}
        />
        <Input
          className={styles('value')}
          register={register(name)}
          type="number"
          step="0.01"
          defaultValue=""
          min={!noMin ? '0' : undefined}
          placeholder={placeholder || t(i18nKeys.FORM.NUMBER)}
          onValueChanged={onChange}
          noBorder
        />
      </div>
    </div>
  );
};
