import { CreateReasonBody, Reason } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, ButtonColor, CustomSelect, Input } from 'shared/io';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './ReasonForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ReasonFormProps = {
  reason?: Reason;
  selectedType?: string;
  onSubmit: SubmitHandler<CreateReasonBody>;
  onCancel?: () => void;
};

export const ReasonForm = ({ reason, onSubmit, onCancel, selectedType }: ReasonFormProps) => {
  const { t } = useTranslation();

  const { reason_types } = useSelector((state: StoreState) => state.app.constants);

  const defaultValues = {
    name: reason?.attributes.name,
    reason_type: selectedType || reason?.attributes.reason_type,
  };

  const form = useForm<CreateReasonBody>({
    shouldUnregister: true,
    defaultValues,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;

  return (
    <FormProvider {...form}>
      <form className={styles('reason-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('content')}>
          <Input
            register={register('name', {
              required: true,
            })}
            errorMessage={get(errors, 'name')}
            noMargin
            withBorder
            label={t(i18nKeys.NAME)}
          />
        </div>
        <div className={styles('content')}>
          <Controller
            defaultValue=""
            name="reason_type"
            render={() => (
              <CustomSelect
                selectClassName={styles('input')}
                keyText="description"
                keyValue="value"
                items={reason_types}
                withBorder
                name="reason_type"
                label={t(i18nKeys.FORM.TYPE)}
                notAllowed={!!selectedType}
              />
            )}
          />
        </div>
        <div className={styles('button-wrapper', 'buttons')}>
          <Button color={ButtonColor.GREY} label={t(i18nKeys.CANCEL)} onClick={onCancel} />
          <Button type="submit" label={t(i18nKeys.SAVE)} />
        </div>
      </form>
    </FormProvider>
  );
};
