import { AxiosInstance } from 'axios';
import { ResourceType } from 'types/resource-types';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export async function createViewFn(
  axiosInstance: Promise<AxiosInstance>,
  { resourceType }: { resourceType: ResourceType }, // todo: estract to variables
): Promise<ApiResponse> {
  const instance = await axiosInstance;

  return instance.post(`views/${resourceType}`);
}

export function useCreateView() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, { resourceType: ResourceType }>({
    mutationFn: (variables) => createViewFn(axiosInstance, variables),
    onSuccess: (response, { resourceType }) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['views', resourceType],
      });
    },
    onError: (error: ApiError) => {
      notifications.show({
        color: 'red',
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    { resourceType: ResourceType },
    'createView'
  >('createView', mutation);
}
