import { i18nKeys, useTranslation } from 'locales';

import { Button, Tooltip } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';

import { useRefreshMonitoring } from '../../../../shared/hooks';
import { Id } from '../../../../types';

interface UpdateMonitoringDataProps {
  hasVatNumber: boolean;
  debtorId: Id;
}

export const UpdateMonitoringDataButton = ({
  hasVatNumber,
  debtorId,
}: UpdateMonitoringDataProps) => {
  const { t } = useTranslation();
  const { refreshMonitoring, isRefreshMonitoringLoading } = useRefreshMonitoring();

  return (
    <Tooltip label={t(i18nKeys.DEBTOR.MONITORING.NO_VAT)} disabled={hasVatNumber}>
      <Button
        variant="light"
        leftSection={<IconRefresh stroke={1.5} />}
        disabled={!hasVatNumber}
        loading={isRefreshMonitoringLoading}
        onClick={() => refreshMonitoring({ debtorId })}
      >
        {t(i18nKeys.DEBTOR.MONITORING.UPDATE_DATA)}
      </Button>
    </Tooltip>
  );
};
