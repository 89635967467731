import { Comment } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';

import { CommentForm } from './CommentForm/CommentForm';
import { CommentItem } from './CommentItem/CommentItem';

import styleIdentifiers from './Comments.module.scss';

const styles = classNames.bind(styleIdentifiers);

type CommentsProps = {
  comments?: Comment[];
  submitComment: Function;
};

export const Comments = ({ comments, submitComment }: CommentsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles('comment-card', 'card')}>
      <h3>{t(i18nKeys.NOTES)}</h3>
      {comments && comments.map((comment) => <CommentItem key={comment.id} comment={comment} />)}
      <CommentForm
        onSubmit={submitComment}
        labelText={t(i18nKeys.NEW_NOTE)}
        label={t(i18nKeys.ACTIONS.ADD)}
      />
    </div>
  );
};
