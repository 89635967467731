import { UpdateTasksStatusBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Input } from 'shared/io';

import styleIdentifiers from './TaskActions.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskActionsPostponeFields = Pick<UpdateTasksStatusBody, 'days_postponed'>;

type TaskActionsPostponeProps = {
  onSubmit: SubmitHandler<TaskActionsPostponeFields>;
};

export const TaskActionsPostpone = ({ onSubmit }: TaskActionsPostponeProps) => {
  const { t } = useTranslation();

  const form = useForm<TaskActionsPostponeFields>({
    shouldUnregister: true,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;

  return (
    <FormProvider {...form}>
      <form className={styles('task-action-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('content')}>
          <Input
            type="number"
            label={t(i18nKeys.DAY)}
            register={register('days_postponed', {
              required: true,
            })}
            errorMessage={get(errors, 'days_postponed')}
            noMargin
            withBorder
            min="1"
          />
        </div>
        <div className={styles('button-wrapper', 'buttons')}>
          <Button type="submit" label={t(i18nKeys.SAVE)} />
        </div>
      </form>
    </FormProvider>
  );
};
