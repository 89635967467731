import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { colleagueActions, colleagueConstants as events } from 'store/colleague/colleague.actions';

import { sendApi } from '../sagas';

function* colleagueWatchers() {
  yield all([
    yield takeLatest(events.add.request, sendApi(api.colleague.add, colleagueActions.addRes)),
  ]);
}

export default function* saga() {
  yield call(colleagueWatchers);
}
