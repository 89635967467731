import { useState } from 'react';
import { useIgnoreDuplicates } from 'api';
import { SimilarDebtorPair } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router-dom';
import { Button, ButtonColor } from 'shared/io';
import { OnClickFunction } from 'types/html-type';
import { TableItemProps } from 'types/table';

import styleIdentifiers from './DuplicatesClientListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type DuplicatesClientListItemProps = SimilarDebtorPair & {
  checked?: boolean;
};
type Props = TableItemProps<DuplicatesClientListItemProps> & {
  action: OnClickFunction;
  refetch: Function;
};

export default function DuplicatesClientListItem({ item, action, refetch }: Props) {
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);
  const history = useHistory();

  const { id, checked, debtors } = item;

  const goDetail = () => {
    history.push(`/client-to-merge/${id}`);
  };

  const { mutate: ignoreDuplicates } = useIgnoreDuplicates();

  const ignore = (e) => {
    e.stopPropagation();
    ignoreDuplicates(
      {
        id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  return (
    <tr
      className={styles('client-list-item', hover && 'hover')}
      onMouseEnter={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={goDetail}
    >
      <td>
        {action && (
          <div onClick={action} className={styles('box', 'checkbox', checked && 'checked')} />
        )}
      </td>
      {debtors.map(({ id: debtorId, attributes: { full_name, emails } }) => (
        <td key={debtorId}>
          {full_name}
          <br />
          <span className={styles('email')}>{emails[0]}</span>
        </td>
      ))}
      <td className={styles('Actions')}>
        <Button
          onClick={ignore}
          color={ButtonColor.GREY}
          className={styles('delete-imported')}
          label={t(i18nKeys.CLIENT.MERGE.IGNORE)}
          noMargin
          small
        />
      </td>
    </tr>
  );
}
