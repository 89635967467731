import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Input } from 'shared/io';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { OnClickFunction } from 'types/html-type';
import { CreditNote } from 'types/store/credit-note-state';
import { LightInvoice } from 'types/store/invoice-state';

import { LinkInvoiceFormFields } from './LinkInvoiceForm.types';
import { getCurrentValues } from './LinkInvoiceForm.utils';

import styleIdentifiers from './linkInvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ItemProps = {
  index: number;
  creditNote: CreditNote;
  invoices: LightInvoice[];
  item: FieldArrayWithId<LinkInvoiceFormFields, 'invoices_to_link', 'id'>;
  reload?: OnClickFunction;
};

export const LinkInvoiceFormTableItem = ({
  item: field,
  index,
  creditNote,
  invoices,
  reload,
}: ItemProps) => {
  const { setValue, register, watch } = useFormContext<LinkInvoiceFormFields>();

  const { t, currentLang } = useTranslation();

  const invoice = invoices.find((e) => Number(e.id) === field.invoice_id);

  if (!invoice) {
    return null;
  }

  const {
    id: invoiceId,
    issue_date,
    reference,
    remaining_balance,
    remaining_balance_with_fees,
    currency,
    localized_money_object: {
      remaining_balance_with_fees: remainingBalanceWithFeesLocalized,
      remaining_balance: remainingBalanceLocalized,
    },
  } = invoice;

  const { canAdd, restTotal, canSubmit } = getCurrentValues(
    watch('invoices_to_link'),
    creditNote.localized_money_object,
  );

  const addAmount = () => {
    setValue(
      `invoices_to_link.${index}.amount`,
      remaining_balance_with_fees < restTotal ? remaining_balance_with_fees : restTotal,
    );
  };

  const max = remaining_balance_with_fees;

  const isLinked = creditNote.matched_payments_attributes.some(
    (e) => e.invoice_attributes.id === invoiceId,
  );

  const unlinkCn = () => {
    creditNoteActions.unlinkToInvoices({
      id: creditNote.id,
      data: {
        unlink_invoice: invoiceId,
      },
      callback: () => {
        if (reload) {
          (reload as any)();
        }
      },
    });
  };

  return (
    <tr key={`${field.id}${reference}`}>
      <td />
      <td>
        <Link to={`/invoices/${invoiceId}`} target="_blank">
          {reference}
        </Link>
      </td>
      <td>
        {issue_date && (
          <DateItem
            date={issue_date}
            lg={currentLang}
            format="DD/MM/YY"
            className={styles('value', 'blue', 'bigger')}
          />
        )}
      </td>
      <td>
        <Amount
          localizedValue={remaining_balance}
          value={remainingBalanceLocalized}
          suffix={currency}
          className={styles('value', 'bigger')}
        />
      </td>
      <td>
        <Amount
          localizedValue={remaining_balance_with_fees}
          value={remainingBalanceWithFeesLocalized}
          suffix={currency}
          className={styles('value', 'bigger')}
        />
      </td>
      <td>
        {!isLinked ? (
          <Button
            small
            noMargin
            color={ButtonColor.GREY}
            onClick={addAmount}
            iconRight={IconName.MINIMAL_RIGHT}
            label={t(i18nKeys.ACTIONS.ADD)}
            disabled={!canAdd || !canSubmit}
          />
        ) : (
          <Button
            small
            noMargin
            color={ButtonColor.RED}
            onClick={() => unlinkCn()}
            iconRight={IconName.MINIMAL_RIGHT}
            label={t(i18nKeys.INVOICE.UNLINK)}
          />
        )}
      </td>
      <td>
        {isLinked ? (
          <Input
            type="number"
            step="0.01"
            noMargin
            label={t(i18nKeys.AMOUNT)}
            placeholder={String(max)}
            disabled
            register={register(`invoices_to_link.${index}.${'amount'}`, {
              required: true,
            })}
          />
        ) : (
          <Input
            type="number"
            min="0"
            max={max}
            step="0.01"
            noMargin
            label={t(i18nKeys.AMOUNT)}
            placeholder={String(max)}
            onValueChanged={(e) => {
              if (!e) {
                setValue(`invoices_to_link.${index}.${'amount'}`, 0);
              }
            }}
            register={register(`invoices_to_link.${index}.${'amount'}`, {
              required: true,
              min: 0,
              max,
            })}
          />
        )}
      </td>
    </tr>
  );
};
