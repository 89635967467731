import { createRef, Fragment, Reducer, useEffect, useMemo, useReducer, useState } from 'react';
import { PlanType, StepType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import AYSModal from 'shared/components/AYSModal';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconName } from 'shared/components/Icon';
import Tabs from 'shared/components/Tabs';
import { Button, ButtonColor, Checkbox, CustomSelect, Dropdown, Input } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { reducerState } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
} from 'store/view/view.actions';
import { en, fr, nl } from 'suneditor/src/lang';
import SunEditorCore from 'suneditor/src/lib/core';
import { SunEditorOptions } from 'suneditor/src/options';
import SunEditor from 'suneditor-react';
import { AVAILABLE_LANGUAGES, Locale } from 'types';
import { DescVal } from 'types/genericType';
import { TemplateVariable } from 'types/store/app-state';
import { StoreState } from 'types/storeTypes';
import { FullTemplate, TemplateBody } from 'types/template';

import { styled } from '@stitches/react';

import SelectStatementType from './SelectStatementType';
import Swiper from './Swiper';
import TemplateSendingOptions from './TemplateSendingOptions';

import '~/../node_modules/suneditor/src/assets/css/suneditor-contents.css'; // Import Sun Editor's CSS File
import '~/../node_modules/suneditor/src/assets/css/suneditor.css'; // Import Sun Editor's CSS File
import styleIdentifiers from './TemplateEditor.module.scss';

const languages = { fr, en, nl };

const styles = classNames.bind(styleIdentifiers);

const VariablesTitle = styled('div', {
  margin: '20px 0px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  justifyContent: 'center',
  '& span': { display: 'none' },
  pointerEvents: 'none',
  transition: 'all .2s ease-in-out;',
  '&:hover': {
    gap: '20px;',
  },
  variants: {
    isClickable: {
      true: {
        '& span': { display: 'unset' },
        cursor: 'pointer',
        pointerEvents: 'unset',
      },
    },
  },
});

const VariablesList = styled('div', {
  '& >div': {
    borderTop: '1px solid #e8eaee',
    padding: '10px',
    fontSize: '0.875rem',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&:last-of-type': {
      borderBottom: '1px solid #e8eaee',
    },
  },
});

type State = Reducer<
  {
    email: BodyTemplate;
    post: BodyTemplate;
    grouped_type: string;
    template: FullTemplate | null;
    canSet: boolean;
    editedText: { lang: Locale; body: string; sendingMedium: string } | null;
    activeSendingMedium: 'email' | 'post';
    lang: number;
    isDirty: boolean;
    sendingMediumAvailables: string[];
  },
  any
>;

type BodyTemplate = {
  fr: (TemplateBody & { changed: boolean }) | null;
  nl: (TemplateBody & { changed: boolean }) | null;
  en: (TemplateBody & { changed: boolean }) | null;
};

const setOptions: SunEditorOptions = {
  resizingBar: false,
  attributesWhitelist: { all: 'style' },
  buttonList: [
    [
      'undo',
      'redo',
      'font',
      'fontSize',
      'formatBlock',
      'paragraphStyle',
      'blockquote',
      'bold',
      'underline',
      'italic',
      'strike',
      'subscript',
      'superscript',
      'fontColor',
      'hiliteColor',
      'removeFormat',
      'outdent',
      'indent',
      'align',
      'horizontalRule',
      'list',
      'lineHeight',
      'table',
      'link',
      'image',
      'showBlocks',
      'codeView',
    ],
  ],
};

export default function TemplateEditor() {
  const { t, currentLang } = useTranslation();
  const {
    template_grouped_types,
    recovery_plan_template_variables,
    payment_plan_template_variables,
    payment_step_types,
    recovery_step_types,
  } = useSelector((state: StoreState) => state.app.constants);
  const company = useSelector((state: StoreState) => state.account.company.data!);

  const availableCustomVariables = useSelector(
    (state: StoreState) => state.account.company?.data?.custom_variables_attributes,
  );

  const [state, setState] = useReducer<State>(reducerState, {
    email: { fr: {}, nl: {}, en: {} } as any,
    post: { fr: {}, nl: {}, en: {} } as any,
    grouped_type: '',
    template: null,
    canSet: company.package.can_edit_template,
    editedText: null,
    activeSendingMedium: 'email',
    lang: 0,
    isDirty: false,
    sendingMediumAvailables: ['email'],
  });

  const [templateVariableGroup, setTemplateVariablesGroup] = useState<Array<DescVal>>();

  const form = useForm({ shouldUnregister: true });
  const {
    register,
    reset,
    trigger,
    getValues,
    formState: { dirtyFields },
    watch,
  } = form;
  const [elRefs, setElRefs] = useState<{ current: SunEditorCore }[]>([]);
  const { params } = useRouteMatch<{ id: any }>();
  const history = useHistory();

  const isDirty = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    settingsActions.getTemplate({
      id: params.id,
      callback: ({ data }) => {
        const treatedTemplate = treatTemplate(data);
        const editedTemplates: any = {};

        const sendingMediumAvailables = ['email'];
        if (treatedTemplate.letter_template) {
          sendingMediumAvailables.push('post');
        }
        for (const sendingMedium of sendingMediumAvailables) {
          editedTemplates[sendingMedium] = {};
          for (const lang of AVAILABLE_LANGUAGES) {
            editedTemplates[sendingMedium][lang.value] = treatedTemplate[
              sendingMedium === 'email' ? 'email_template' : 'letter_template'
            ].find((templateBody) => templateBody.locale === lang.value);
          }
        }
        editedTemplates.grouped_type = editedTemplates.email.fr.grouped_type;

        setState({
          ...editedTemplates,
          template: treatedTemplate,
          sendingMediumAvailables,
        });
        reset({
          ...treatedTemplate,
        });
      },
    });
    setElRefs((references) =>
      Array(AVAILABLE_LANGUAGES.length * 2)
        .fill(null)
        .map((_, i) => references[i] || createRef()),
    );
  }, []);

  const templateVariables = useMemo(() => {
    if (state.template) {
      const variables =
        state.template.plan_type === PlanType.payment
          ? payment_plan_template_variables
          : recovery_plan_template_variables;
      const tempVariables = variables[state.template.step_type].slice() as TemplateVariable[];
      if (state.activeSendingMedium === 'post') {
        tempVariables.splice(
          tempVariables.findIndex((group) => group.value === 'email'),
          1,
        );
      }
      if (!state.grouped_type && state.template.plan_type !== PlanType.payment) {
        tempVariables.splice(
          tempVariables.findIndex((group) => group.value === 'grouped'),
          1,
        );
      }
      if (availableCustomVariables!.length > 0) {
        tempVariables.push({
          description: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE),
          value: 'custom_variables',
          variables: availableCustomVariables!.map((cusVar) => ({
            description: cusVar.name,
            value: `${cusVar.model_type}_${cusVar.column_name}`,
          })),
        });
      }
      return tempVariables;
    }
    return [];
  }, [state.activeSendingMedium, state.grouped_type, state.template]);

  useEffect(() => {
    if (state.editedText) {
      setContent(state.editedText.lang, state.editedText.body, state.editedText.sendingMedium);
    }
  }, [state.editedText]);

  const duplicate = () => {
    settingsActions.duplicateTemplate({
      id: params.id,
      callback: ({ data }) => {
        history.push(`/settings/document-customization/edit/${data.id}`);
        sideMenuHide();
      },
    });
  };

  const updateTemplate = async (callback) => {
    const isValid = await trigger();
    if (isValid) {
      const formValues = getValues();

      const serializeTemplateBodies = (templateBodies, sendingMedium) =>
        templateBodies.map((value: any) => {
          const templateBody = { ...value };
          if (state.activeSendingMedium !== sendingMedium) {
            delete templateBody.body;
          }
          templateBody.grouped_type = state.grouped_type || null;

          return templateBody;
        });

      let template_bodies = [];
      state.sendingMediumAvailables.forEach((sm) => {
        template_bodies = template_bodies.concat(
          serializeTemplateBodies(Object.values(state[sm]), sm),
        );
      });

      settingsActions.setTemplate({
        id: state.template!.id,
        data: {
          ...formValues,
          id: state.template!.id,
          step_type: formValues.step_type,
          template_bodies,
        },
        callback: () => {
          sideMenuHide();
          callback?.();
          const editedTemplates = { [state.activeSendingMedium]: {} };
          for (const lang of AVAILABLE_LANGUAGES) {
            editedTemplates[state.activeSendingMedium][lang.value] = {
              ...state[state.activeSendingMedium][lang.value],
              changed: false,
            };
          }
          reset(formValues);
          setState({ ...editedTemplates, isDirty: false });
        },
      });
    }
  };

  const save = (saveAndQuit: boolean) => (callback) => {
    const variables =
      state.template!.plan_type === PlanType.payment
        ? payment_plan_template_variables
        : recovery_plan_template_variables;

    const warning =
      state.template!.step_type === 'credit_note'
        ? variables.credit_note_warning
        : state.grouped_type || state.template!.step_type === 'formal_notice'
          ? variables.grouped_presence_warning
          : variables.presence_warning;

    const missingKeys: any = { fr: [], nl: [], en: [] };

    if (warning) {
      for (const requiredKey of warning) {
        if (!(state.activeSendingMedium === 'post' && requiredKey.value === 'debtor_portal_link')) {
          for (const { value } of AVAILABLE_LANGUAGES) {
            const element = document.createElement('div');
            element.innerHTML = state[state.activeSendingMedium][value]?.body ?? '';
            if (element.innerText.indexOf(`{{${requiredKey.value}}}`) === -1) {
              missingKeys[value].push(requiredKey);
            }
            element.remove();
          }
        }
      }
    }

    if (
      AVAILABLE_LANGUAGES.reduce((acc, lang) => acc + missingKeys[lang.value].length, 0) > 0 &&
      state.template?.plan_type !== PlanType.payment
    ) {
      showDialog({
        id: DialogShowId.CONFIRM,
        size: DialogShowSize.MEDIUM,
        title: t(i18nKeys.ATTENTION),
        keepMountOnExit: true,
        children: (
          <AYSModal
            text={
              <div className={styles('ays-modal')}>
                <p>{t(i18nKeys.MISSING_VAR)} :</p>
                {AVAILABLE_LANGUAGES.map(
                  (av_lang) =>
                    missingKeys[av_lang.value].length > 0 && (
                      <Fragment key={av_lang.value}>
                        <div className={styles('lang')}>{av_lang.description} : </div>
                        <div className={styles('missing-var')}>
                          {missingKeys[av_lang.value].map((key) => (
                            <div key={key.value}>{key.description}</div>
                          ))}
                        </div>
                      </Fragment>
                    ),
                )}
              </div>
            }
            confirmButtonText={t(i18nKeys.CONTINUE_ANYWAY)}
            onConfirm={() => {
              updateTemplate(saveAndQuit && callback);
            }}
          />
        ),
      });
    } else {
      updateTemplate(saveAndQuit && callback);
    }
  };

  const setContent = (lang: Locale, text, sendingMedium) => {
    if (state[sendingMedium][lang].body !== text) {
      setState({
        isDirty: true,
        [sendingMedium]: {
          ...state[sendingMedium],
          [lang]: {
            ...state[sendingMedium][lang],
            body: text,
            changed: true,
          },
        },
      });
    }
  };

  const onChangeSendingMedium = (index) => {
    setState({ activeSendingMedium: index === 0 ? 'email' : 'post' });
  };

  const previewTemplate = () => {
    const formValues = getValues();
    settingsActions.previewTemplate({
      id: state.template!.id,
      download: state.activeSendingMedium === 'post',
      data: {
        ...state[state.activeSendingMedium][AVAILABLE_LANGUAGES[state.lang].value],
        grouped_type: state.grouped_type || undefined,
        template_type: state.activeSendingMedium,
        step_type: state.template!.step_type,
        include_pdf: formValues.include_pdf,
        include_ubl: formValues.include_ubl,
      },
      callback: sideMenuHide,
    });
  };

  const refIndex = useMemo(
    () => state.lang + (state.activeSendingMedium === 'post' ? 3 : 0),
    [state.lang, state.activeSendingMedium],
  );

  const addText = (text) => () => {
    const suneditorElement = document.querySelectorAll(
      '.sun-editor .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable',
    )[refIndex] as any;
    const scrollEl = document.querySelectorAll(
      'div[class="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"]',
    )[refIndex] as any;
    const { scrollTop } = scrollEl;
    suneditorElement.focus();
    scrollEl.scrollTo(0, scrollTop);
    elRefs[refIndex].current.insertHTML(text, true, true, true);
  };

  const getSunEditorInstance = (lg, activeSendingMedium) => (sunEditor: SunEditorCore) => {
    elRefs[lg + (activeSendingMedium === 'post' ? 3 : 0)].current = sunEditor;
  };

  const onTextChange = (lg, sendingMedium) => (body) => {
    setState({
      editedText: { lang: lg, body, sendingMedium },
    });
  };

  const onChangeLang = (lang) => {
    setState({
      lang,
    });
  };

  const onBlur = (e) => {
    e.preventDefault();
    elRefs[refIndex].current.core.getRange();
  };

  const setSendingOptions = () => {
    const lang = AVAILABLE_LANGUAGES[state.lang];
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: `${t(i18nKeys.INVOICE.SENDING_OPTIONS)} (${lang.description})`,
      children: (
        <TemplateSendingOptions
          state={state.email}
          lang={lang}
          setState={setState}
          defaultFrom={company.default_from}
          customDomain={company.custom_domain}
        />
      ),
    });
  };

  const editorContainer = (sendingMedium) => (
    <div
      className={styles(
        'template-container',
        sendingMedium,
        state.activeSendingMedium !== sendingMedium && 'hidden',
      )}
    >
      {state.template && (
        <>
          <Tabs
            className={styles('lang-tabs')}
            disableScroll
            noRounded
            tabIndex={state.lang}
            onChange={onChangeLang}
            items={AVAILABLE_LANGUAGES.map((language) => (
              <div key={language.value} className={styles('tab-item')}>
                <div /> <div>{language.description}</div>
                {state[sendingMedium][language.value]!.changed && (
                  <div className={styles('edit-circle')} />
                )}
              </div>
            ))}
          >
            {[]}
          </Tabs>
          <div className={styles('body-template-container')}>
            <div style={{ overflow: 'hidden' }}>
              <Swiper activeTab={state.lang}>
                {AVAILABLE_LANGUAGES.map((language, lgIndex) => (
                  <div key={language.value} className={styles('template-item')}>
                    <SunEditor
                      getSunEditorInstance={getSunEditorInstance(lgIndex, sendingMedium)}
                      setContents={state[sendingMedium][language.value]!.body}
                      onChange={onTextChange(language.value, sendingMedium)}
                      onBlur={onBlur}
                      lang={languages[currentLang]}
                      disable={!state.canSet}
                      setOptions={{
                        ...setOptions,
                        defaultStyle: 'font-family: Verdana',
                      }}
                    />
                  </div>
                ))}
              </Swiper>
            </div>

            {state.canSet && (
              <div className={styles('variable-container')}>
                <div style={{ padding: '0px 20px' }}>
                  {sendingMedium === 'email' && templateVariableGroup == null && (
                    <Button
                      label={t(i18nKeys.INVOICE.SENDING_OPTIONS)}
                      onClick={setSendingOptions}
                    />
                  )}
                  <VariablesTitle
                    isClickable={templateVariableGroup != null}
                    onClick={() => setTemplateVariablesGroup(undefined)}
                  >
                    <Icon size="20px" name={IconName.CIRCLE_LEFT} />
                    <h3>{t(i18nKeys.VARIABLES)}</h3>
                  </VariablesTitle>
                </div>
                {templateVariableGroup == null ? (
                  <div style={{ padding: '0px 20px' }}>
                    {templateVariables.map((group) => (
                      <Button
                        key={group.description}
                        style={{ marginBottom: '10px' }}
                        label={group.description}
                        noMargin
                        iconRight={IconName.MINIMAL_RIGHT}
                        color={ButtonColor.WHITE}
                        onClick={() => setTemplateVariablesGroup(group.variables)}
                      />
                    ))}
                  </div>
                ) : (
                  <VariablesList>
                    {templateVariableGroup.map((variable) => (
                      <div key={variable.description} onClick={addText(`{{${variable.value}}}`)}>
                        <div className={styles('description')}>{variable.description}</div>
                        <div className={styles('value')}>
                          {'{{'}
                          {variable.value}
                          {'}}'}
                        </div>
                      </div>
                    ))}
                  </VariablesList>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );

  const showStatements = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.FULL,
      keepMountOnExit: true,
      title: t(i18nKeys.TEMPLATE.WHICH_TO_INCLUDE),
      children: (
        <SelectStatementType
          onSelectAction={(grouped_type) => {
            const newState = { grouped_type } as any;
            if (newState.grouped_type !== (state.template?.email_template[0].grouped_type || '')) {
              newState.isDirty = true;
            }

            setState(newState);
            dialogHide(DialogShowId.CUSTOM);
          }}
          initialValue={state.template!.email_template[0].grouped_type}
        />
      ),
    });
  };

  const typeItems = (
    state.template && state.template.plan_type === PlanType.payment
      ? payment_step_types.template_types
      : recovery_step_types.template_types
  ).filter((el) => el.value !== StepType.task_generation);

  return (
    <div className={styles('settings')}>
      <div className={styles('template-editor')}>
        <NavigationPrompt when={isDirty || state.isDirty} />
        <div className={styles('head')}>
          <h1>{t(i18nKeys.TEMPLATE_EDITION)}</h1>
          <div className={styles('actions')}>
            <Dropdown
              className={styles('dropdown')}
              sideMenuInMobile
              sideMenuTitle={t(i18nKeys.FORM.ACTIONS)}
              selectorContent={
                <Button
                  noMargin
                  iconRight={IconName.ARROW_BOTTOM_ROUNDED}
                  label={t(i18nKeys.FORM.ACTIONS)}
                />
              }
            >
              <div className={styles('dropdown-item')} onClick={previewTemplate}>
                {t(i18nKeys.SHOW_TEMPLATE)}
              </div>
              <div className={styles('dropdown-item')} onClick={duplicate}>
                {t(i18nKeys.DUPLICATE)}
              </div>
              <div className={styles('dropdown-item')} onClick={save(false)}>
                {t(i18nKeys.SAVE)}
              </div>
            </Dropdown>
            <Button
              noMargin
              label={t(i18nKeys.SHOW_TEMPLATE)}
              onClick={previewTemplate}
              color={ButtonColor.GREY}
            />
            <Button noMargin label={t(i18nKeys.DUPLICATE)} onClick={duplicate} />
            <Button
              onClick={save(false)}
              disabled={!state.canSet}
              noMargin
              color={ButtonColor.MAIN}
              label={t(i18nKeys.SAVE)}
            />
          </div>
        </div>
        <div className={styles('template-name')}>
          <div className={styles('input-wrapper')}>
            <Input
              register={register('name', { required: true })}
              readOnly={!state.canSet}
              noMargin
              withBorder
              label={t(i18nKeys.TEMPLATE_NAME)}
            />
            <FormProvider {...form}>
              <Controller
                defaultValue=""
                name="step_type"
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name="step_type"
                    size="small"
                    items={typeItems}
                    keyText="description"
                    keyValue="value"
                    withBorder
                    noMargin
                    label={t(i18nKeys.FORM.TYPE)}
                  />
                )}
              />
            </FormProvider>
          </div>
          {state.template &&
            state.template.plan_type !== PlanType.payment &&
            (state.template.step_type === 'invoice' ||
            state.template.step_type === 'credit_note' ? (
              <div className={styles('attachement-container')}>
                <div className={styles('grouped-type', 'with-border', 'no-margin')}>
                  <Checkbox
                    register={register('include_pdf')}
                    noMargin
                    label={t(i18nKeys.TEMPLATE.ATTACH_FILE, { type: 'PDF' })}
                    watch={watch}
                  />
                </div>
                <div className={styles('grouped-type', 'with-border', 'no-margin')}>
                  <Checkbox
                    register={register('include_ubl')}
                    noMargin
                    label={t(i18nKeys.TEMPLATE.ATTACH_FILE, { type: 'UBL' })}
                    watch={watch}
                  />
                </div>
              </div>
            ) : (
              state.template.step_type !== 'formal_notice' &&
              state.template.step_type !== 'additional_reminder' &&
              state.template.step_type !== 'preventive_reminder' &&
              (state.grouped_type ? (
                <div className={styles('grouped-type', 'with-border', 'no-margin')}>
                  <div className={styles('label-input')}> {t(i18nKeys.STATEMENT)}</div>
                  <div className={styles('grouped-type-value')}>
                    {
                      template_grouped_types.find((item) => item.value === state.grouped_type)!
                        .description
                    }{' '}
                    <Icon name={IconName.PENCIL} onClick={showStatements} />
                  </div>
                </div>
              ) : (
                <Button label={t(i18nKeys.ADD_STATEMENT)} noMargin onClick={showStatements} />
              ))
            ))}
        </div>

        <Tabs
          noRounded
          className={styles('sending-medium')}
          items={state.sendingMediumAvailables.map((sm) => (
            <div className={styles('tab-item')}>
              <div /> <div>{t(i18nKeys[sm.toUpperCase()])}</div>
              {(state[sm].en!.changed || state[sm].nl!.changed || state[sm].fr!.changed) && (
                <div className={styles('edit-circle')} />
              )}
            </div>
          ))}
          onChange={onChangeSendingMedium}
          tabIndex={state.sendingMediumAvailables.indexOf(state.activeSendingMedium)}
        >
          {[]}
        </Tabs>
        {editorContainer('email')}
        {editorContainer('post')}
      </div>
    </div>
  );
}
