import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'remeda';
import { Button, Checkbox, RadioButton } from 'shared/io';
import { dialogHide, DialogShowId } from 'store/view/view.actions';

import styleIdentifiers from './ExportCsv.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  downloadCsv: Function;
  wrappers: any[];
  allFields: any;
  selectAllLabel: string;
  selectFilteredLabel: string;
  shouldHideFilterOption?: boolean;
  overrideDefaultSelection?: Array<string>;
};

export function ExportCsv({
  downloadCsv,
  wrappers,
  allFields,
  selectFilteredLabel,
  selectAllLabel,
  overrideDefaultSelection = [],
  shouldHideFilterOption = false,
}: Props) {
  const { t } = useTranslation();

  const [checkboxWrappers, setCheckboxWrappers] = useState<any>({
    selectAll: false,
  });

  const form = useForm<{
    attributes: Record<string, boolean>;
    export_with_filters: boolean;
  }>({
    shouldUnregister: true,
    defaultValues: {
      attributes: {},
      export_with_filters: false,
    },
  });

  const { getValues, register, watch, reset, control } = form;

  // Required for the useEffect below to trigger re-renders
  watch('attributes');

  useEffect(() => {
    if (isEmpty(overrideDefaultSelection)) return;

    overrideDefaultSelection.forEach((field) => form.setValue(`attributes.${field}`, true));
  }, [overrideDefaultSelection]);

  const resetForm = (fields, value) => {
    if (value !== undefined) {
      const formValues: any = getValues();
      const resetValues = {
        attributes: {},
      };

      for (const field of fields) {
        resetValues.attributes[field.value] = value;
      }

      reset({
        ...formValues,
        attributes: {
          ...formValues.attributes,
          ...resetValues.attributes,
        },
      } as any);
    }
  };

  const setCheckFields =
    (type: 'selectAll' | 'selectDebtor' | 'selectInvoice') => (value: boolean) => {
      resetForm(
        type === 'selectAll' ? allFields : wrappers.find(({ name }) => name === type).fields,
        value,
      );

      const wrapperValue = {};

      wrappers.forEach(({ name }) => {
        wrapperValue[name] = type === 'selectAll' || type === name ? value : checkboxWrappers[name];
      });

      setCheckboxWrappers({
        selectAll: type === 'selectAll' ? value : checkboxWrappers.selectAll,
        ...wrapperValue,
      });
    };

  const submit = () => {
    dialogHide(DialogShowId.CUSTOM);
    downloadCsv(getValues());
  };

  return (
    <FormProvider {...form}>
      <div className={styles('export-csv')}>
        {!shouldHideFilterOption && (
          <Controller
            control={control}
            name="export_with_filters"
            render={({ field: { ref, ...values } }) => (
              <RadioButton
                {...values}
                className={styles('export-mode-container')}
                items={[
                  { value: false, label: selectAllLabel },
                  { value: true, label: selectFilteredLabel },
                ]}
              />
            )}
          />
        )}

        <div className={styles('summary')}>
          {t(i18nKeys.INVOICING.EXPORT_CSV.PLEASE_SELECT_FIELDS)}
        </div>

        <div>
          <Checkbox
            checked={checkboxWrappers.selectAll}
            onChange={setCheckFields('selectAll')}
            label={t(i18nKeys.FORM.SELECT_ALL)}
            name="selectAll"
          />
        </div>

        {wrappers.map(({ fields, name, text }) =>
          fields.length === 0 ? (
            ''
          ) : (
            <div key={name}>
              <div className={styles('subtitle')}>
                <Checkbox
                  checked={checkboxWrappers[name]}
                  noMargin
                  name={name}
                  onChange={() => setCheckFields(name)(!checkboxWrappers[name])}
                />
                {text}
              </div>
              <div className={styles('atttribute-list')}>
                {fields.map((field) => (
                  <div key={field.value} className={styles('attribute-item')}>
                    <Checkbox
                      register={register(`attributes[${field.value}]` as any)}
                      watch={watch}
                      label={field.description}
                    />
                  </div>
                ))}
              </div>
            </div>
          ),
        )}

        <div className={styles('button-wrapper')}>
          <Button label={t(i18nKeys.INVOICE.DOWNLOAD_CSV)} onClick={submit} />
        </div>
      </div>
    </FormProvider>
  );
}
