import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';

import styleIdentifiers from './RadioButton.module.scss';

const styles = classNames.bind(styleIdentifiers);

type RadioButtonProps = {
  className?: string;
  error?: string;
  disabled?: boolean;
  onChange: Function;
  name: string;
  noMargin?: boolean;
  items: { value: any; label?: any }[];
  canRemove?: boolean;
  labelStyle?: string;
  value?: any;
};

export const RadioButton = ({
  onChange,
  name,
  noMargin,
  error,
  items,
  className,
  disabled,
  canRemove,
  labelStyle,
  value,
}: RadioButtonProps) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const val = value !== undefined ? value : form.watch(name);

  const onSetValue = (newValue) => () => {
    onChange(canRemove && val === items[0].value ? null : newValue);
  };
  return (
    <div
      className={styles(
        'RadioButton',
        className,
        error && 'error',
        disabled && 'disabled',
        noMargin && 'no-margin',
      )}
    >
      {items.map((radioItem, index) => (
        <div
          className={styles('label')}
          key={radioItem.value || index}
          onClick={onSetValue(radioItem.value)}
        >
          <div className={styles('radio-button-circle', radioItem.value == val && 'checked')} />
          {radioItem.label && (
            <span className={styles('textLabel', labelStyle)}>{radioItem.label}</span>
          )}
        </div>
      ))}

      {error && <div className={styles('error-message')}> {t(error)}</div>}
    </div>
  );
};
