import { useGetDebtorSendingOptions } from 'api';
import { Id, SendingMediaNames } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import RecovrLogoLoader from 'shared/components/Loader';
import AddressPartialForm from 'shared/forms/partial/AddressPartialForm';
import SendInvoiceFormEmail from 'shared/forms/SendInvoiceForm/SendInvoiceFormEmail';
import { Button, Checkbox } from 'shared/io';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { SendingOptions } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from '../ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InvoiceSendingOptionsProps = {
  invoiceId: Id;
  sendingOptions: SendingOptions;
  debtorId: Id;
};
export default function InvoiceSendingOptions({
  invoiceId,
  sendingOptions,
  debtorId,
}: InvoiceSendingOptionsProps) {
  const { t } = useTranslation();
  const { sending_medium } = useSelector((state: StoreState) => state.app.constants);

  const { data: sendingOptionsResponse, isError, isLoading } = useGetDebtorSendingOptions(debtorId);

  const mediaEnabled = (medium) =>
    Boolean((sendingOptions as any).sending_media_attributes.find(({ name }) => name === medium));

  const defaultValues = {
    emails: sendingOptions.emails as string[],
    addresses_attributes: sendingOptions.addresses_attributes,
    send_email: mediaEnabled(SendingMediaNames.email),
    send_post: mediaEnabled(SendingMediaNames.post),
    send_registered_post: mediaEnabled(SendingMediaNames.registered_post),
  };

  const form = useForm({
    defaultValues,
  });

  const { watch, register } = form;

  if (isLoading && !sendingOptionsResponse) {
    return <RecovrLogoLoader />;
  }

  if (isError || !sendingOptionsResponse) {
    return <span>Error</span>;
  }

  const { addresses, emails } = sendingOptionsResponse;

  const sendEmail = watch('send_email');
  const sendPost = watch('send_post');
  const sendRegisteredPost = watch('send_registered_post');

  const saveSendingOptions = () => {
    invoiceActions.setSendingOptions({
      id: invoiceId as number,
      data: {
        emails: watch('emails'),
        addresses_attributes: watch('addresses_attributes'),
        send_email: sendEmail,
        send_post: sendPost,
        send_registered_post: sendRegisteredPost,
      },
      callback: () => {
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form className={styles('invoice-sending-options-form')}>
        <div className={styles('send-by')}>
          <div className={styles('label-input')}>{t(i18nKeys.INVOICE.SEND_REMINDERS_BY)}</div>
          <div className={styles('sending-media')}>
            {sending_medium.map(({ value, description }) => (
              <Checkbox
                key={value}
                register={register(`send_${value}`)}
                watch={watch}
                label={<span>{description}</span>}
                disabled={
                  (value === SendingMediaNames.post && watch('send_registered_post')) ||
                  (value === SendingMediaNames.registered_post && watch('send_post'))
                }
              />
            ))}
          </div>
        </div>
        {sendEmail && (
          <div className={styles('section')}>
            <div className={styles('input-wrapper')}>
              <SendInvoiceFormEmail emails={emails} />
            </div>
          </div>
        )}
        {(sendPost || sendRegisteredPost) && (
          <div className={styles('section')}>
            <div className={styles('input-wrapper')}>
              <AddressPartialForm addresses={addresses} />
            </div>
          </div>
        )}
        <div className={styles('button-wrapper')}>
          <Button onClick={saveSendingOptions} noShadow label={t(i18nKeys.CONFIRM)} />
        </div>
      </form>
    </FormProvider>
  );
}
