import { SendingMediaNames } from 'api/models';
import { push } from 'connected-react-router';
import { i18nKeys } from 'locales';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { invoiceFormToData } from 'shared/serializer';
import { api } from 'store/apis';
import { invoiceActions, invoiceConstants as events } from 'store/invoice/invoice.actions';
import { success } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import { downloadFile, extractFilename } from '../../shared/utils/view';
import { apiCall, sendApi } from '../sagas';

function* InvoiceAdd({ payload }: any) {
  const constants = yield select((state: StoreState) => state.app.constants);

  const { data } = payload;

  const response = yield apiCall({
    api: api.invoice.add,
    data: invoiceFormToData(data, constants),
    actionRes: invoiceActions.addRes,
  });

  if (response && response.status === 200) {
    const newInvoice = response.data && response.data.data;

    if (newInvoice) {
      yield put(push(`/invoices/detail/${newInvoice.id}`));
    } else {
      yield put(push('/invoices/listing'));
    }

    yield success({
      text: i18nKeys.INVOICE.SUCCESSFUL_CREATION,
    });
  }
}

function* InvoiceUpdate({ payload }: any) {
  const constants = yield select((state: StoreState) => state.app.constants);

  const { id, data } = payload;

  const response = yield apiCall({
    api: api.invoice.update,
    id,
    data: invoiceFormToData(data, constants),
    actionRes: invoiceActions.updateRes,
    callback: payload.callback,
  });

  if (response.status === 200) {
    if (response.data.data.attributes.draft) {
      yield put(push(`/invoices/detail/${id}`));
    }

    yield success({
      text: i18nKeys.INVOICE.SUCCESSFUL_UPDATE,
    });
  }
}

function* invoiceInfo({ payload }: any) {
  const { callback, ...info } = payload;
  yield invoiceActions.detail(payload);
  yield invoiceActions.activity(info);
}

/**
 * Other specific actions
 */

function* invoiceSend({ payload }: any) {
  const data = payload?.data;

  if (data.by_mail && data.by_post) {
    data.sending_media = [
      { name: SendingMediaNames.email },
      {
        name: data.by_registered_post ? SendingMediaNames.registered_post : SendingMediaNames.post,
      },
    ];
  } else if (data.by_mail) {
    data.sending_media = [{ name: SendingMediaNames.email }];
  } else {
    data.sending_media = [
      {
        name: data.by_registered_post ? SendingMediaNames.registered_post : SendingMediaNames.post,
      },
    ];
  }

  if (data.recovery_plan_id.id) {
    data.recovery_plan_id = data.recovery_plan_id.id;
  }

  const response = yield apiCall({
    api: api.invoice.send,
    id: payload.id,
    data,
    callback: payload.callback,
  });

  if (response && response.status === 200) {
    yield put(push('/invoices/listing'));
  }

  yield invoiceActions.sendRes();
}

function* sendToThirdParty({ payload }: any) {
  yield apiCall({
    api: api.invoice.sendToThirdParty,
    id: payload.id,
    data: payload.data,
    callback: payload.callback,
    actionRes: invoiceActions.actionsThirdPartyRes,
  });
}

// Download documents (see constants)
function* downloadDocument({ payload }: any) {
  const type = payload.type || payload.document_type || payload.data.document_type;
  const request = yield call(apiCall, {
    api: payload.public ? api.invoice.publicDocument : api.invoice.document,
    raw: true,
    fileUpload: payload.fileUpload,
    id: payload.id,
    noLoading: payload.noLoading,
    noFeedback: true,
    data: {
      ...payload.data,
      document_type: type,
      ...(payload.third_party_case_id ? { third_party_case_id: payload.third_party_case_id } : {}),
      ...(payload.document_id != null ? { document_id: payload.document_id } : null),
    },
    callback: payload.callback,
    errors: 'ERROR.DOC_NOT_AVAILABLE',
  });

  if (request && request.status === 200 && !payload.callback) {
    const filename = extractFilename(request.headers);
    downloadFile(
      request.data,
      filename || `recovr.invoice-${payload.id}-${type}.${type === 'invoice_ubl' ? 'xml' : 'pdf'}`,
    );
  }
}

function* importCSV({ payload }: any) {
  const nbreInvoiceByPartition = 500;
  const invoicesPartionned: any[] = [];
  const length = Math.ceil(payload.data.invoices.length / nbreInvoiceByPartition);

  for (let i = 0; i < length; i++) {
    const result = yield apiCall({
      api: api.invoice.import.csv,
      data: {
        invoices: payload.data.invoices.slice(
          i * nbreInvoiceByPartition,
          i * nbreInvoiceByPartition + nbreInvoiceByPartition,
        ),
      },
      actionRes: invoiceActions.importInvoiceCSVRes,
      noLoading: true,
    });
    invoicesPartionned.push(result);
  }

  payload.callback({
    data: invoicesPartionned.reduce(
      (acc, res) => (res?.status === 200 ? acc.concat(res.data.data) : acc),
      [],
    ),
  });
}

function* InvoiceWatchers() {
  yield all([
    yield takeLatest(
      events.listPage.request,
      sendApi(api.invoice.list, invoiceActions.listPageRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(events.getInvoices.request, sendApi(api.invoice.list)),
    yield takeLatest(events.detail.request, sendApi(api.invoice.detail, invoiceActions.detailRes)),
    yield takeLatest(events.deleteMultiple.request, sendApi(api.invoice.deleteMultiple)),
    yield takeLatest(events.add.request, InvoiceAdd),
    yield takeLatest(events.update.request, InvoiceUpdate),
    yield takeLatest(events.delete.request, sendApi(api.invoice.delete, invoiceActions.deleteRes)),
    yield takeLatest(events.createInvoicePdf.request, sendApi(api.invoice.import.pdf)),
    yield takeLatest(events.info.request, invoiceInfo),
    yield takeLatest(
      events.setSendingOptions.request,
      sendApi(api.invoice.setSendingOptions, invoiceActions.setSendingOptionsRes),
    ),
    yield takeLatest(
      events.toHandle.request,
      sendApi(api.invoice.toHandle, invoiceActions.toHandleRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(
      events.postponable.request,
      sendApi(api.invoice.postponable, invoiceActions.postponableRes, (payload) => ({
        noLoading: true,
        data: payload,
      })),
    ),
    yield takeLatest(
      events.activity.request,
      sendApi(api.invoice.activity, invoiceActions.activityRes),
    ),
    yield takeLatest(
      events.getActivityPage.request,
      sendApi(api.invoice.activity, invoiceActions.getActivityPageRes),
    ),
    yield takeLatest(events.previewTemplate.request, sendApi(api.invoice.previewTemplate)),
    yield takeLatest(events.send.request, invoiceSend),
    yield takeLatest(
      events.getAdditionalFiles.request,
      sendApi(api.invoice.additionalFile.detail, undefined, (payload) => ({
        ...payload,
        errors: 'ERROR.DOC_NOT_AVAILABLE',
      })),
    ),
    yield takeLatest(
      events.addAdditionalFile.request,
      sendApi(api.invoice.additionalFile.add, invoiceActions.addAdditionalFileRes, (payload) => ({
        ...payload,
        fileUpload: true,
        success: 'SUCCESSFULLY_FILE_UPLOAD',
        error: 'ERROR.ERROR',
      })),
    ),
    yield takeLatest(
      events.deleteAdditionalFile.request,
      sendApi(api.invoice.additionalFile.delete, invoiceActions.deleteAdditionalFileRes),
    ),
    // pdf
    yield takeLatest(
      events.addOriginalInvoice.request,
      sendApi(api.invoice.additionalFile.add, undefined, (payload) => ({
        ...payload,
        fileUpload: true,
        success: 'SUCCESSFULLY_FILE_UPLOAD',
      })),
    ),
    yield takeLatest(
      events.updateOriginalFile.request,
      sendApi(api.invoice.updateOriginalFile, invoiceActions.updateOriginalFileRes, (payload) => ({
        ...payload,
        fileUpload: true,
        success: 'SUCCESSFULLY_FILE_UPLOAD',
      })),
    ),
    yield takeLatest(events.document.request, downloadDocument),
    yield takeLatest(events.importInvoiceCSV.request, importCSV),
    yield takeLatest(events.getGroupedInvoices.request, sendApi(api.invoice.getGroupedInvoices)),
    yield takeLatest(
      events.getAllToConfirm.request,
      sendApi(api.invoice.import.getAllToConfirm, invoiceActions.getAllToConfirmRes, (payload) => ({
        data: { ...payload },
        noLoading: true,
      })),
    ),
    yield takeLatest(events.getToConfirm.request, sendApi(api.invoice.import.getToConfirm)),
    yield takeLatest(
      events.deleteInvoiceToConfirm.request,
      sendApi(api.invoice.import.deleteToConfirm, invoiceActions.deleteInvoiceToConfirmRes),
    ),
    yield takeLatest(
      events.validateAllImportedInvoices.request,
      sendApi(api.invoice.import.validateAll, invoiceActions.validateAllImportedInvoicesRes),
    ),
    yield takeLatest(events.copy.request, sendApi(api.invoice.copy, invoiceActions.detailRes)),
    yield takeLatest(events.assignToAccManager.request, sendApi(api.invoice.assignToAccManager)),
    yield takeLatest(events.getRecoveryPlans.request, sendApi(api.invoice.getRecoveryPlans)),
    yield takeLatest(events.setRecoveryPlan.request, sendApi(api.invoice.setRecoveryPlan)),
    // actions
    yield takeLatest(events.actions.ignoreLateFees.request, sendApi(api.invoice.ignoreLateFees)),
    yield takeLatest(
      events.actions.postponeMultiple.request,
      sendApi(api.invoice.postponeMultiple),
    ),
    yield takeLatest(
      events.actions.forceReminder.request,
      sendApi(api.invoice.forceReminder, invoiceActions.actionsForceReminderRes),
    ),
    yield takeLatest(
      events.actions.comment.request,
      sendApi(api.invoice.comment, invoiceActions.actionsCommentRes),
    ),
    yield takeLatest(
      events.actions.conversationMessage.request,
      sendApi(api.invoice.conversationMessage, (payload) => ({
        ...payload,
        success: 'INVOICE.SUCCESSFULLY_MESSAGE_SENT',
      })),
    ),
    yield takeLatest(
      events.actions.addPayment.request,
      sendApi(api.invoice.addPayment, invoiceActions.actionsAddPaymentRes, (payload) => ({
        ...payload,
        success: 'INVOICE.SUCCESSFULLY_ADDED_PAYMENT',
      })),
    ),
    yield takeLatest(
      events.debtorReactionQrCode.request,
      sendApi(api.invoice.debtorReaction.qrCode),
    ),
    // mark invoice as
    yield takeLatest(events.actions.paid.request, sendApi(api.invoice.paid)),
    yield takeLatest(events.actions.lost.request, sendApi(api.invoice.lost)),
    yield takeLatest(events.actions.pause.request, sendApi(api.invoice.pause)),
    yield takeLatest(events.actions.disputed.request, sendApi(api.invoice.disputed)),
    yield takeLatest(
      events.actions.batchAction.request,
      sendApi(api.invoice.batchAction, invoiceActions.actionsBatchActionRes),
    ),
    // Actions invoice to handle
    yield takeLatest(
      events.actions.additionalReminder.request,
      sendApi(api.invoice.additionalReminder, invoiceActions.actionsAdditionalReminderRes),
    ),
    yield takeLatest(events.actions.callcenter.request, sendApi(api.invoice.callcenter)),
    yield takeLatest(
      events.actions.formalNotice.request,
      sendApi(api.invoice.createFormalNotice, invoiceActions.actionsFormalNoticeRes),
    ),
    yield takeLatest(events.actions.lawyer.request, sendApi(api.invoice.lawyer)),
    yield takeLatest(events.actions.closeLawyer.request, sendApi(api.invoice.closeLawyer)),
    yield takeLatest(events.actions.bailiff.request, sendApi(api.invoice.bailiff)),
    yield takeLatest(events.actions.closeBailiff.request, sendApi(api.invoice.closeBailiff)),
    yield takeLatest(
      events.actions.markAsToBeProccesed.request,
      sendApi(api.invoice.markAsToBeProccesed),
    ),
    yield takeLatest(events.actions.thirdParty.request, sendToThirdParty),
    // debtor reaction
    yield takeLatest(events.debtorReaction.request, sendApi(api.invoice.debtorReaction.getUnpaid)),
    yield takeLatest(
      events.debtorReactionComment.request,
      sendApi(api.invoice.debtorReaction.comment),
    ),
    yield takeLatest(
      events.debtorReactionInvoices.request,
      sendApi(api.invoice.debtorReaction.getInvoices),
    ),
    yield takeLatest(
      events.debtorReactionCreditNotes.request,
      sendApi(api.invoice.debtorReaction.getCreditNotes),
    ),
    yield takeLatest(
      events.getGroupedInvoicePaymentInfo.request,
      sendApi(api.invoice.debtorReaction.getGroupedInvoicePaymentInfo),
    ),
  ]);
}

export default function* saga() {
  yield call(InvoiceWatchers);
}
