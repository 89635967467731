// todo: probably a duplicate of flattenItem or similar nightmarish util function we have

import { Activity } from 'types/store/activity';

export const formatActivities = (activities) => ({
  metadata: activities.metadata,
  activities: activities.data.map(({ date, ...rest }) => ({
    ...rest,
    date: date as unknown as Date,
  })) as Activity[],
});
