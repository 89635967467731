import axios from 'axios';
import { config } from 'store/constants';

class ApiService {
  tenant: string;

  baseUrl: string;

  domain: string;

  constructor(subdomain: string) {
    this.tenant = subdomain;
    this.baseUrl = config.api.baseUrl;
    this.domain = config.api.domain;
  }

  static axiosCall(url, request) {
    return axios(url, request)
      .then((response) => response)
      .catch((error) => error.response);
  }

  call(path, type, options: any = {}) {
    const url = this.baseUrl + path;

    let headers = {
      ...options.headers,
      tenant: this.tenant,
      'Content-Type': 'application/json',
    };

    let { data } = options;

    if (options.fileUpload) {
      headers = {
        ...headers,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data;',
      };

      const formData = new FormData();

      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        formData.append(key, data[key]);
      }
      data = formData;
    }

    // Request
    const request: any = {};
    request.method = type;
    request.headers = headers;

    request.data = data;
    request.params = data;

    if (options.fileUpload) {
      request.onUploadProgress = function (event) {
        if (typeof options.progress === 'function') {
          const progress = Math.round((event.loaded / event.total) * 100);
          options.progress(progress);
        }
      };
    }
    return ApiService.axiosCall(url, request);
  }
}

let instance: ApiService;

function getInstance() {
  if (instance) return instance;

  const { href } = window.location;
  const subdomain = href.substring(href.indexOf('://') + 3, href.indexOf('.'));

  if (!subdomain) throw new Error('Bad subdomain');

  instance = new ApiService(subdomain);

  return instance;
}

export default getInstance();
