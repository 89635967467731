import React from 'react';
import { i18nKeys, useTranslation } from 'locales';

import { Box, Button, Card, Flex, Text } from '@mantine/core';

interface MantineSettingsCardProps {
  title: string;
  children: React.ReactNode;
  onSave?: VoidFunction;
  saveButtonLabel?: string;
}

export const MantineSettingsCard = ({
  title,
  children,
  onSave,
  saveButtonLabel,
}: MantineSettingsCardProps) => {
  const { t } = useTranslation();
  return (
    <Card h="100%" withBorder radius="sm" shadow="sm">
      <Card.Section inheritPadding withBorder py="xs" mb="md">
        <Text fw={500} size="lg" ff="Roboto">
          {title}
        </Text>
      </Card.Section>
      <Box h="100%">{children}</Box>
      <Card.Section inheritPadding withBorder py="xs" mt="lg">
        <Flex w="100%" justify="flex-end">
          <Button
            fw={500}
            disabled={onSave == null}
            type="submit"
            variant="filled"
            color="blue"
            onClick={onSave}
          >
            {saveButtonLabel ?? t(i18nKeys.SAVE)}
          </Button>
        </Flex>
      </Card.Section>
    </Card>
  );
};
