import { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Button, ButtonColor } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';

import styleIdentifiers from './AYSModal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  text: ReactNode;
  onCancel?: OnClickFunction;
  confirmButtonText?: string;
  customButtonText?: string;
  customAction?: OnClickFunction;
  onConfirm?: OnClickFunction;
  confirmButtonColor?: ButtonColor;
  cancelButtonText?: string;
};

export default function AysModal({
  text,
  customButtonText,
  customAction,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor,
  onCancel,
  onConfirm,
}: Props) {
  const { t } = useTranslation();

  const { isLoading: isInvoiceLoading } = useGetInvoiceState()!;
  const { isLoading: isClientLoading } = useGetClientState()!;
  const isLoading = isInvoiceLoading || isClientLoading;

  const onClickOnButton = (e) => {
    e.preventDefault();
    dialogHide(DialogShowId.CONFIRM);
  };

  return (
    <div className={styles('ays-modal')}>
      {text}
      <form onSubmit={onClickOnButton}>
        {onCancel && (
          <Button
            color={ButtonColor.GREY}
            type="submit"
            label={cancelButtonText || t(i18nKeys.CANCEL)}
            onClick={onCancel}
          />
        )}
        {customAction && <Button type="submit" label={customButtonText} onClick={customAction} />}
        {onConfirm && (
          <Button
            isLoading={isLoading}
            type="submit"
            color={confirmButtonColor}
            label={confirmButtonText || t(i18nKeys.CONFIRM)}
            onClick={onConfirm}
          />
        )}
      </form>
    </div>
  );
}
