import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { accountActions } from 'store/account/account.actions';
import { StoreState } from 'types/storeTypes';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK, IntegrationFormFieldsName } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import { BillitForm, BillitFormFields, BillitFormFieldsName } from './BillitForm';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Billit = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const { isActive, canActivate, isConnected } = getIntegrationRights(Integration.BILLIT, company);

  useEffect(() => {
    if (!canActivate) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, replace]);

  if (!company?.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  const handleSubmit = (data: BillitFormFields) => {
    accountActions.updateBillitSettings({
      data,
      callback: INTEGRATION_UPDATE_CALLBACK,
    });
  };

  const {
    tp_accounting: {
      use_import,
      debtor_update,
      issue_date_limit,
      automatic_sync,
      use_payment_matching,
    },
  } = company;

  return (
    <article className={styles('billit')}>
      <IntegrationHeader
        name={Integration.BILLIT}
        goBack={goBack}
        isActive={isActive}
        isConnected={isConnected}
      />
      <BillitForm
        onSubmit={handleSubmit}
        initialValues={{
          [BillitFormFieldsName.IMPORT_INTEGRATION]: use_import,
          [BillitFormFieldsName.PAYMENT_MATCHING]: use_payment_matching,
          [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: issue_date_limit,
          [IntegrationFormFieldsName.DEBTOR_UPDATE]: debtor_update,
          [IntegrationFormFieldsName.AUTOMATIC_SYNC]: automatic_sync,
        }}
      />
    </article>
  );
};
