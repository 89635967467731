import { i18nKeys } from 'locales';
import { call } from 'redux-saga/effects';
import { error, success } from 'store/view/view.actions';
import { ReduxActionOptions } from 'types/redux';
import { SagaPayload } from 'types/saga-payload';

import apiService from '../shared/service/api.service';

export function* apiCall(options) {
  options.headers = {};

  if (options.allOrigin) {
    options.headers['Access-Control-Allow-Origin'] = '*';
  }

  options.name = apiService.tenant;
  const response = yield call(options.api, options);
  const requestData = options.data;

  if (!response) {
    if (!options.noFeedback) {
      yield error({
        text: i18nKeys.ERROR.SERVER_NOT_RESPONDING,
      });
    }

    if (options.actionFailure) {
      yield options.actionFailure();
    }

    if (options.actionRes) {
      yield options.actionRes({}, 'rejected', requestData);
    }

    // todo: could produce a not found error
    return null;
  }

  const data = response.data || null;

  if (response.status >= 400) {
    if (response.status === 401) {
      if (!options.noFeedback) {
        yield error({
          data: data && (data.error_message || data.errors),
        });
      }

      return response;
    }

    if (!options.noFeedback) {
      yield error({
        data:
          data && (data.error_message || (data.errors && data.errors.full_messages) || data.errors),
      });
    }

    if (options.actionFailure) {
      yield options.actionFailure(response, requestData);
    }

    if (options.actionRes) {
      yield options.actionRes(response, 'rejected', requestData);
    }

    return response;
  }

  if (response.status === 200) {
    if (options.actionRes) {
      yield options.actionRes(data, 'fulfilled', requestData, options.reset, options.id);
    }

    if (options.callback) {
      options.callback(data);
    }

    if (!options.noSuccess && !options.noFeedback && (data.message || options.success)) {
      yield success({
        [data.message ? 'data' : 'text']: data.message || options.success,
      });
    }
  }

  return response;
}

export const sendApi = (
  api,
  actionRes?: Function,
  customPayload?: (payload: SagaPayload) => SagaPayload,
) =>
  function* (action: ReduxActionOptions) {
    const { id, ...apiPayload } =
      (customPayload ? customPayload(action.payload) : action.payload) || {};

    yield apiCall({
      api,
      actionRes,
      id,
      ...apiPayload,
    });
  };

export default apiCall;
