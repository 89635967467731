import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { isDefined } from 'remeda';
import Logo from 'shared/components/Logo';
import { useValidateTenant } from 'shared/hooks';
import apiService from 'shared/service/api.service';

import styleIdentifiers from './ServerError.module.scss';

const styles = classNames.bind(styleIdentifiers);

export function ServerError() {
  const { t } = useTranslation();
  const history = useHistory();
  const { tenant } = useValidateTenant(apiService.tenant);

  useEffect(() => {
    if (isDefined(tenant)) history.push('/');
  }, [tenant, history]);

  return (
    <div className={styles('server-error-page')}>
      <div className={styles('content-wrapper')}>
        <div className={styles('content', 'card')}>
          <h1>{t(i18nKeys.ERROR.SERVER_NOT_RESPONDING)}</h1>
          <p>{t(i18nKeys.ERROR.REFRESH_PAGE_IF_ERROR_PERSIST)}</p>
        </div>
        <Logo className={styles('logo')} type="blue" />
      </div>
    </div>
  );
}
