import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AmountFields, DateSelector, Input } from 'shared/io';
import { currencySymbol } from 'shared/utils/normalization';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './ReconciliationPaymentFilter.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  className?: string;
};

export default function ReconciliationPaymentFilter({ onSubmit, className }: Props) {
  const { t } = useTranslation();
  const company = useSelector((state: StoreState) => state.account.company.data);
  const constants = useSelector((state: StoreState) => state.app.constants);
  const { register, getValues, handleSubmit, setValue } = useFormContext();
  const [timeout, setTimeoutDebounce] = useState();
  const submit = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    const newTimeout = setTimeout((): void => {
      onSubmit(getValues());
    }, 500);

    setTimeoutDebounce(newTimeout as any);
  };

  return (
    <form
      className={styles('reconciliation-payment-filter', className)}
      onSubmit={handleSubmit(submit)}
    >
      <div className={styles('fields')}>
        <DateSelector
          className={styles('input')}
          withBorder
          shadow
          noMinDate
          label={t(i18nKeys.FORM.PAYMENT_DATE)}
          placeholder={t(i18nKeys.OF)}
          endPlaceholder={t(i18nKeys.FORM.TO)}
          endName="end_date"
          name="start_date"
          handleChange={(date) => {
            setValue('start_date', date);
            submit();
          }}
          handleEndChange={(date) => {
            setValue('end_date', date);
            submit();
          }}
        />
        <Input
          onValueChanged={submit}
          register={register('reconciliation_detail')}
          noMargin
          withBorder
          shadow
          type="text"
          label={t(i18nKeys.DETAILS)}
        />
        <AmountFields
          onChange={submit}
          className={styles('spacing')}
          operatorName="operator"
          name="amount"
          label={t(i18nKeys.AMOUNT_CURRENCY, {
            text: currencySymbol(undefined, company),
          })}
        />
      </div>
    </form>
  );
}
