/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  ActivitiesListResponse,
  AuthenticateDomainBody,
  AutomaticAssignationResponse,
  AutomaticAssignationsListResponse,
  AvailableConditionsListResponse,
  BadRequestResponse,
  CancelPaymentPlanBody,
  ContactPersonResponseResponse,
  ContactPersonsResponseResponse,
  CreateAutomaticAssignationBody,
  CreateContactPersonBody,
  CreatePaymentPlanBody,
  CreatePaymentStepCalculationBody,
  CreateReasonBody,
  CreateRecoveryPlanBody,
  CreateTaskBody,
  CreateTaskCommentBody,
  CreateTaskTypeBody,
  CreateTemplateBody,
  CreateWorkflowBody,
  DebtorBasicResponse,
  DebtorDetailsResponse,
  DebtorSendingOptionsResponse,
  DebtorTimelineResponse,
  DebtorsDetailedListResponse,
  DebtorsListResponse,
  DeclinePaymentPlanRequestBody,
  DeleteUnvalidatedImportsBody,
  DisputeDebtorBody,
  DomainResponseResponse,
  GetActivitiesParams,
  GetAutomaticAssignationsParams,
  GetAvailableConditionsParams,
  GetDebtorParams,
  GetDebtorTimelineParams,
  GetDebtorsParams,
  GetDebtorsToConfirmParams,
  GetInvoicesToConfirmParams,
  GetPaymentPlansParams,
  GetPrivateConstantsParams,
  GetProductsParams,
  GetReasonsParams,
  GetTasksParams,
  GetTemplatesParams,
  Id,
  InvoiceCompleteResponse,
  InvoicesListResponse,
  JWTResponseResponse,
  MergeSimilarDebtorsBody,
  NoContentResponse,
  PaymentPlanCompleteResponse,
  PaymentPlanResponse,
  PaymentPlansListResponse,
  PaymentStepCalculationResponse,
  ProductsListResponse,
  ReasonResponse,
  ReasonsListResponse,
  ReconcilePaymentsAndInvoicesBody,
  ReconciliationResponseResponse,
  RecoveryPlanResponse,
  RecoveryPlansListResponse,
  RequestPaymentPlanBody,
  SearchInvoicesParams,
  SearchInvoicesResponse,
  ServerErrorResponse,
  SimilarDebtorsLightListResponse,
  SuccessResponse,
  TaskActionsResponse,
  TaskResponse,
  TaskTypeResponse,
  TaskTypesListResponse,
  TasksListResponse,
  TemplateResponse,
  TemplatesListResponse,
  UnauthorizedResponse,
  UpdateInvoiceBody,
  UpdatePaymentStepCalculationBody,
  UpdateTasksStatusBody,
  UpdateTemplateBody,
  UploadDebtorCSVBody,
  UploadInvoiceCSVBody,
  WorkflowResponse,
  WorkflowsListResponse,
} from './models';
import { customInstance } from './mutator/custom-instance';
import type { ErrorType } from './mutator/custom-instance';

/**
 * @summary Get activities for query parameters
 */
export const getActivities = (params?: GetActivitiesParams, signal?: AbortSignal) => {
  return customInstance<ActivitiesListResponse>({
    url: `/private_api/activities`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetActivitiesQueryKey = (params?: GetActivitiesParams) => {
  return [`/private_api/activities`, ...(params ? [params] : [])] as const;
};

export const getGetActivitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getActivities>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetActivitiesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActivitiesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivities>>> = ({ signal }) =>
    getActivities(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActivities>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getActivities>>>;
export type GetActivitiesQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetActivities<
  TData = Awaited<ReturnType<typeof getActivities>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetActivitiesParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActivities<
  TData = Awaited<ReturnType<typeof getActivities>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetActivitiesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActivities<
  TData = Awaited<ReturnType<typeof getActivities>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetActivitiesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get activities for query parameters
 */

export function useGetActivities<
  TData = Awaited<ReturnType<typeof getActivities>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetActivitiesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetActivitiesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPrivateConstants = (params?: GetPrivateConstantsParams, signal?: AbortSignal) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/private_constants`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetPrivateConstantsQueryKey = (params?: GetPrivateConstantsParams) => {
  return [`/private_api/private_constants`, ...(params ? [params] : [])] as const;
};

export const getGetPrivateConstantsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPrivateConstants>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetPrivateConstantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPrivateConstants>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPrivateConstantsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPrivateConstants>>> = ({ signal }) =>
    getPrivateConstants(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPrivateConstants>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPrivateConstantsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPrivateConstants>>
>;
export type GetPrivateConstantsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetPrivateConstants<
  TData = Awaited<ReturnType<typeof getPrivateConstants>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetPrivateConstantsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPrivateConstants>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getPrivateConstants>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPrivateConstants<
  TData = Awaited<ReturnType<typeof getPrivateConstants>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetPrivateConstantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPrivateConstants>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPrivateConstants>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPrivateConstants<
  TData = Awaited<ReturnType<typeof getPrivateConstants>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetPrivateConstantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPrivateConstants>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPrivateConstants<
  TData = Awaited<ReturnType<typeof getPrivateConstants>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetPrivateConstantsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPrivateConstants>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPrivateConstantsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get debtors for query parameters
 */
export const getDebtors = (params?: GetDebtorsParams, signal?: AbortSignal) => {
  return customInstance<DebtorsListResponse>({
    url: `/private_api/debtors`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetDebtorsQueryKey = (params?: GetDebtorsParams) => {
  return [`/private_api/debtors`, ...(params ? [params] : [])] as const;
};

export const getGetDebtorsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof getDebtors>>, GetDebtorsParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getDebtors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getDebtors>>,
        QueryKey,
        GetDebtorsParams['page']
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDebtorsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDebtors>>,
    QueryKey,
    GetDebtorsParams['page']
  > = ({ signal, pageParam }) =>
    getDebtors({ ...params, page: pageParam || params?.['page'] }, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getDebtors>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getDebtors>>,
    QueryKey,
    GetDebtorsParams['page']
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDebtorsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDebtors>>>;
export type GetDebtorsInfiniteQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetDebtorsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof getDebtors>>, GetDebtorsParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetDebtorsParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getDebtors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getDebtors>>,
        QueryKey,
        GetDebtorsParams['page']
      >
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtors>>, TError, TData, QueryKey>,
        'initialData'
      >;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof getDebtors>>, GetDebtorsParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getDebtors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getDebtors>>,
        QueryKey,
        GetDebtorsParams['page']
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getDebtors>>,
          TError,
          TData,
          QueryKey
        >,
        'initialData'
      >;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof getDebtors>>, GetDebtorsParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getDebtors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getDebtors>>,
        QueryKey,
        GetDebtorsParams['page']
      >
    >;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get debtors for query parameters
 */

export function useGetDebtorsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof getDebtors>>, GetDebtorsParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getDebtors>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getDebtors>>,
        QueryKey,
        GetDebtorsParams['page']
      >
    >;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDebtorsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetDebtorsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtors>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDebtorsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDebtors>>> = ({ signal }) =>
    getDebtors(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDebtors>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDebtorsQueryResult = NonNullable<Awaited<ReturnType<typeof getDebtors>>>;
export type GetDebtorsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetDebtors<
  TData = Awaited<ReturnType<typeof getDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetDebtorsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtors>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtors>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtors<
  TData = Awaited<ReturnType<typeof getDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtors>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtors>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtors<
  TData = Awaited<ReturnType<typeof getDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtors>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get debtors for query parameters
 */

export function useGetDebtors<
  TData = Awaited<ReturnType<typeof getDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtors>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDebtorsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get debtor details
 */
export const getDebtor = (id: string | number, params?: GetDebtorParams, signal?: AbortSignal) => {
  return customInstance<DebtorDetailsResponse>({
    url: `/private_api/debtors/${id}`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetDebtorQueryKey = (id: string | number, params?: GetDebtorParams) => {
  return [`/private_api/debtors/${id}`, ...(params ? [params] : [])] as const;
};

export const getGetDebtorQueryOptions = <
  TData = Awaited<ReturnType<typeof getDebtor>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params?: GetDebtorParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtor>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDebtorQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDebtor>>> = ({ signal }) =>
    getDebtor(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDebtor>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDebtorQueryResult = NonNullable<Awaited<ReturnType<typeof getDebtor>>>;
export type GetDebtorQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetDebtor<
  TData = Awaited<ReturnType<typeof getDebtor>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params: undefined | GetDebtorParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtor>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtor>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtor<
  TData = Awaited<ReturnType<typeof getDebtor>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params?: GetDebtorParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtor>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtor>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtor<
  TData = Awaited<ReturnType<typeof getDebtor>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params?: GetDebtorParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtor>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get debtor details
 */

export function useGetDebtor<
  TData = Awaited<ReturnType<typeof getDebtor>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params?: GetDebtorParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtor>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDebtorQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Mark a debtor as disputed
 */
export const disputeDebtor = (
  id: string | number,
  disputeDebtorBody: DisputeDebtorBody,
  signal?: AbortSignal,
) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/debtors/${id}/dispute_debtor`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: disputeDebtorBody,
    signal,
  });
};

export const getDisputeDebtorMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disputeDebtor>>,
    TError,
    { id: string | number; data: DisputeDebtorBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof disputeDebtor>>,
  TError,
  { id: string | number; data: DisputeDebtorBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof disputeDebtor>>,
    { id: string | number; data: DisputeDebtorBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return disputeDebtor(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DisputeDebtorMutationResult = NonNullable<Awaited<ReturnType<typeof disputeDebtor>>>;
export type DisputeDebtorMutationBody = DisputeDebtorBody;
export type DisputeDebtorMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Mark a debtor as disputed
 */
export const useDisputeDebtor = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disputeDebtor>>,
    TError,
    { id: string | number; data: DisputeDebtorBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof disputeDebtor>>,
  TError,
  { id: string | number; data: DisputeDebtorBody },
  TContext
> => {
  const mutationOptions = getDisputeDebtorMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a debtor's emails and activities sorted by date
 */
export const getDebtorTimeline = (
  id: string | number,
  params?: GetDebtorTimelineParams,
  signal?: AbortSignal,
) => {
  return customInstance<DebtorTimelineResponse>({
    url: `/private_api/debtors/${id}/timeline`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetDebtorTimelineQueryKey = (
  id: string | number,
  params?: GetDebtorTimelineParams,
) => {
  return [`/private_api/debtors/${id}/timeline`, ...(params ? [params] : [])] as const;
};

export const getGetDebtorTimelineQueryOptions = <
  TData = Awaited<ReturnType<typeof getDebtorTimeline>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params?: GetDebtorTimelineParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorTimeline>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDebtorTimelineQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDebtorTimeline>>> = ({ signal }) =>
    getDebtorTimeline(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDebtorTimeline>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDebtorTimelineQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDebtorTimeline>>
>;
export type GetDebtorTimelineQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetDebtorTimeline<
  TData = Awaited<ReturnType<typeof getDebtorTimeline>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params: undefined | GetDebtorTimelineParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorTimeline>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtorTimeline>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorTimeline<
  TData = Awaited<ReturnType<typeof getDebtorTimeline>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params?: GetDebtorTimelineParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorTimeline>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtorTimeline>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorTimeline<
  TData = Awaited<ReturnType<typeof getDebtorTimeline>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params?: GetDebtorTimelineParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorTimeline>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a debtor's emails and activities sorted by date
 */

export function useGetDebtorTimeline<
  TData = Awaited<ReturnType<typeof getDebtorTimeline>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  params?: GetDebtorTimelineParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorTimeline>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDebtorTimelineQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get products for query parameters
 */
export const getProducts = (params?: GetProductsParams, signal?: AbortSignal) => {
  return customInstance<ProductsListResponse>({
    url: `/private_api/products`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetProductsQueryKey = (params?: GetProductsParams) => {
  return [`/private_api/products`, ...(params ? [params] : [])] as const;
};

export const getGetProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetProductsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProducts>>> = ({ signal }) =>
    getProducts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProducts>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getProducts>>>;
export type GetProductsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetProducts<
  TData = Awaited<ReturnType<typeof getProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetProductsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetProducts<
  TData = Awaited<ReturnType<typeof getProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetProductsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetProducts<
  TData = Awaited<ReturnType<typeof getProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetProductsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get products for query parameters
 */

export function useGetProducts<
  TData = Awaited<ReturnType<typeof getProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetProductsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetProductsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Gets an invoice
 */
export const getInvoices = (id: string | number, signal?: AbortSignal) => {
  return customInstance<InvoiceCompleteResponse>({
    url: `/private_api/invoices/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetInvoicesQueryKey = (id: string | number) => {
  return [`/private_api/invoices/${id}`] as const;
};

export const getGetInvoicesQueryOptions = <
  TData = Awaited<ReturnType<typeof getInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInvoicesQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoices>>> = ({ signal }) =>
    getInvoices(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInvoices>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoices>>>;
export type GetInvoicesQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetInvoices<
  TData = Awaited<ReturnType<typeof getInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInvoices<
  TData = Awaited<ReturnType<typeof getInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInvoices<
  TData = Awaited<ReturnType<typeof getInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Gets an invoice
 */

export function useGetInvoices<
  TData = Awaited<ReturnType<typeof getInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetInvoicesQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Updates an invoice
 */
export const updateInvoice = (id: string | number, updateInvoiceBody: UpdateInvoiceBody) => {
  return customInstance<InvoiceCompleteResponse>({
    url: `/private_api/invoices/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInvoiceBody,
  });
};

export const getUpdateInvoiceMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInvoice>>,
    TError,
    { id: string | number; data: UpdateInvoiceBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInvoice>>,
  TError,
  { id: string | number; data: UpdateInvoiceBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInvoice>>,
    { id: string | number; data: UpdateInvoiceBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateInvoice(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof updateInvoice>>>;
export type UpdateInvoiceMutationBody = UpdateInvoiceBody;
export type UpdateInvoiceMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Updates an invoice
 */
export const useUpdateInvoice = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInvoice>>,
    TError,
    { id: string | number; data: UpdateInvoiceBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInvoice>>,
  TError,
  { id: string | number; data: UpdateInvoiceBody },
  TContext
> => {
  const mutationOptions = getUpdateInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search invoices for query parameters
 */
export const searchInvoices = (params?: SearchInvoicesParams, signal?: AbortSignal) => {
  return customInstance<SearchInvoicesResponse>({
    url: `/private_api/credit_note/invoices`,
    method: 'GET',
    params,
    signal,
  });
};

export const getSearchInvoicesQueryKey = (params?: SearchInvoicesParams) => {
  return [`/private_api/credit_note/invoices`, ...(params ? [params] : [])] as const;
};

export const getSearchInvoicesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof searchInvoices>>, SearchInvoicesParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: SearchInvoicesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchInvoices>>,
        TError,
        TData,
        Awaited<ReturnType<typeof searchInvoices>>,
        QueryKey,
        SearchInvoicesParams['page']
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchInvoicesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchInvoices>>,
    QueryKey,
    SearchInvoicesParams['page']
  > = ({ signal, pageParam }) =>
    searchInvoices({ ...params, page: pageParam || params?.['page'] }, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchInvoices>>,
    TError,
    TData,
    Awaited<ReturnType<typeof searchInvoices>>,
    QueryKey,
    SearchInvoicesParams['page']
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type SearchInvoicesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchInvoices>>
>;
export type SearchInvoicesInfiniteQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useSearchInvoicesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchInvoices>>, SearchInvoicesParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | SearchInvoicesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchInvoices>>,
        TError,
        TData,
        Awaited<ReturnType<typeof searchInvoices>>,
        QueryKey,
        SearchInvoicesParams['page']
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchInvoices>>,
          TError,
          TData,
          QueryKey
        >,
        'initialData'
      >;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSearchInvoicesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchInvoices>>, SearchInvoicesParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: SearchInvoicesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchInvoices>>,
        TError,
        TData,
        Awaited<ReturnType<typeof searchInvoices>>,
        QueryKey,
        SearchInvoicesParams['page']
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchInvoices>>,
          TError,
          TData,
          QueryKey
        >,
        'initialData'
      >;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSearchInvoicesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchInvoices>>, SearchInvoicesParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: SearchInvoicesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchInvoices>>,
        TError,
        TData,
        Awaited<ReturnType<typeof searchInvoices>>,
        QueryKey,
        SearchInvoicesParams['page']
      >
    >;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Search invoices for query parameters
 */

export function useSearchInvoicesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof searchInvoices>>, SearchInvoicesParams['page']>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: SearchInvoicesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchInvoices>>,
        TError,
        TData,
        Awaited<ReturnType<typeof searchInvoices>>,
        QueryKey,
        SearchInvoicesParams['page']
      >
    >;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSearchInvoicesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSearchInvoicesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: SearchInvoicesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchInvoices>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchInvoicesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchInvoices>>> = ({ signal }) =>
    searchInvoices(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchInvoices>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type SearchInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof searchInvoices>>>;
export type SearchInvoicesQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useSearchInvoices<
  TData = Awaited<ReturnType<typeof searchInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | SearchInvoicesParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchInvoices>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof searchInvoices>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSearchInvoices<
  TData = Awaited<ReturnType<typeof searchInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: SearchInvoicesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchInvoices>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof searchInvoices>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useSearchInvoices<
  TData = Awaited<ReturnType<typeof searchInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: SearchInvoicesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchInvoices>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Search invoices for query parameters
 */

export function useSearchInvoices<
  TData = Awaited<ReturnType<typeof searchInvoices>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: SearchInvoicesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchInvoices>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getSearchInvoicesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get recovery plans list
 */
export const getRecoveryPlans = (signal?: AbortSignal) => {
  return customInstance<RecoveryPlansListResponse>({
    url: `/private_api/recovery_plans`,
    method: 'GET',
    signal,
  });
};

export const getGetRecoveryPlansQueryKey = () => {
  return [`/private_api/recovery_plans`] as const;
};

export const getGetRecoveryPlansQueryOptions = <
  TData = Awaited<ReturnType<typeof getRecoveryPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlans>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRecoveryPlansQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecoveryPlans>>> = ({ signal }) =>
    getRecoveryPlans(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRecoveryPlans>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetRecoveryPlansQueryResult = NonNullable<Awaited<ReturnType<typeof getRecoveryPlans>>>;
export type GetRecoveryPlansQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetRecoveryPlans<
  TData = Awaited<ReturnType<typeof getRecoveryPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlans>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getRecoveryPlans>>, TError, TData>,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetRecoveryPlans<
  TData = Awaited<ReturnType<typeof getRecoveryPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlans>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getRecoveryPlans>>, TError, TData>,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetRecoveryPlans<
  TData = Awaited<ReturnType<typeof getRecoveryPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlans>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get recovery plans list
 */

export function useGetRecoveryPlans<
  TData = Awaited<ReturnType<typeof getRecoveryPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlans>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetRecoveryPlansQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a recovery plan
 */
export const createRecoveryPlan = (
  createRecoveryPlanBody: CreateRecoveryPlanBody,
  signal?: AbortSignal,
) => {
  return customInstance<RecoveryPlanResponse>({
    url: `/private_api/recovery_plans`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createRecoveryPlanBody,
    signal,
  });
};

export const getCreateRecoveryPlanMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRecoveryPlan>>,
    TError,
    { data: CreateRecoveryPlanBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createRecoveryPlan>>,
  TError,
  { data: CreateRecoveryPlanBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createRecoveryPlan>>,
    { data: CreateRecoveryPlanBody }
  > = (props) => {
    const { data } = props ?? {};

    return createRecoveryPlan(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateRecoveryPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof createRecoveryPlan>>
>;
export type CreateRecoveryPlanMutationBody = CreateRecoveryPlanBody;
export type CreateRecoveryPlanMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a recovery plan
 */
export const useCreateRecoveryPlan = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRecoveryPlan>>,
    TError,
    { data: CreateRecoveryPlanBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createRecoveryPlan>>,
  TError,
  { data: CreateRecoveryPlanBody },
  TContext
> => {
  const mutationOptions = getCreateRecoveryPlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a email domain stored in DB
 */
export const getEmailDomain = (signal?: AbortSignal) => {
  return customInstance<DomainResponseResponse>({
    url: `/private_api/email_domain`,
    method: 'GET',
    signal,
  });
};

export const getGetEmailDomainQueryKey = () => {
  return [`/private_api/email_domain`] as const;
};

export const getGetEmailDomainQueryOptions = <
  TData = Awaited<ReturnType<typeof getEmailDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailDomain>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmailDomainQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmailDomain>>> = ({ signal }) =>
    getEmailDomain(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEmailDomain>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetEmailDomainQueryResult = NonNullable<Awaited<ReturnType<typeof getEmailDomain>>>;
export type GetEmailDomainQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetEmailDomain<
  TData = Awaited<ReturnType<typeof getEmailDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailDomain>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getEmailDomain>>, TError, TData>,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmailDomain<
  TData = Awaited<ReturnType<typeof getEmailDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailDomain>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getEmailDomain>>, TError, TData>,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmailDomain<
  TData = Awaited<ReturnType<typeof getEmailDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailDomain>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a email domain stored in DB
 */

export function useGetEmailDomain<
  TData = Awaited<ReturnType<typeof getEmailDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailDomain>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetEmailDomainQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a domain from Sendgrid
 */
export const getDomain = (id: string | number, signal?: AbortSignal) => {
  return customInstance<DomainResponseResponse>({
    url: `/private_api/sendgrid/authenticate_domains/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetDomainQueryKey = (id: string | number) => {
  return [`/private_api/sendgrid/authenticate_domains/${id}`] as const;
};

export const getGetDomainQueryOptions = <
  TData = Awaited<ReturnType<typeof getDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDomain>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDomainQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDomain>>> = ({ signal }) =>
    getDomain(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDomain>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDomainQueryResult = NonNullable<Awaited<ReturnType<typeof getDomain>>>;
export type GetDomainQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetDomain<
  TData = Awaited<ReturnType<typeof getDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDomain>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getDomain>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDomain<
  TData = Awaited<ReturnType<typeof getDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDomain>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getDomain>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDomain<
  TData = Awaited<ReturnType<typeof getDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDomain>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a domain from Sendgrid
 */

export function useGetDomain<
  TData = Awaited<ReturnType<typeof getDomain>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDomain>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDomainQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update a domain from Sendgrid
 */
export const updateDomain = (id: string | number) => {
  return customInstance<DomainResponseResponse>({
    url: `/private_api/sendgrid/authenticate_domains/${id}`,
    method: 'PUT',
  });
};

export const getUpdateDomainMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDomain>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateDomain>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateDomain>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return updateDomain(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateDomainMutationResult = NonNullable<Awaited<ReturnType<typeof updateDomain>>>;

export type UpdateDomainMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a domain from Sendgrid
 */
export const useUpdateDomain = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDomain>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateDomain>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getUpdateDomainMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a domain
 */
export const deleteDomain = (id: string | number) => {
  return customInstance<NoContentResponse>({
    url: `/private_api/sendgrid/authenticate_domains/${id}`,
    method: 'DELETE',
  });
};

export const getDeleteDomainMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteDomain>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteDomain>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteDomain>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteDomain(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteDomainMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDomain>>>;

export type DeleteDomainMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete a domain
 */
export const useDeleteDomain = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteDomain>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteDomain>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteDomainMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Authenticate a domain
 */
export const authenticateDomain = (
  authenticateDomainBody: AuthenticateDomainBody,
  signal?: AbortSignal,
) => {
  return customInstance<DomainResponseResponse>({
    url: `/private_api/sendgrid/authenticate_domains`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: authenticateDomainBody,
    signal,
  });
};

export const getAuthenticateDomainMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticateDomain>>,
    TError,
    { data: AuthenticateDomainBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authenticateDomain>>,
  TError,
  { data: AuthenticateDomainBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authenticateDomain>>,
    { data: AuthenticateDomainBody }
  > = (props) => {
    const { data } = props ?? {};

    return authenticateDomain(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticateDomainMutationResult = NonNullable<
  Awaited<ReturnType<typeof authenticateDomain>>
>;
export type AuthenticateDomainMutationBody = AuthenticateDomainBody;
export type AuthenticateDomainMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Authenticate a domain
 */
export const useAuthenticateDomain = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticateDomain>>,
    TError,
    { data: AuthenticateDomainBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authenticateDomain>>,
  TError,
  { data: AuthenticateDomainBody },
  TContext
> => {
  const mutationOptions = getAuthenticateDomainMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a debtor sending options
 */
export const getDebtorSendingOptions = (id: string | number, signal?: AbortSignal) => {
  return customInstance<DebtorSendingOptionsResponse>({
    url: `/private_api/debtors/${id}/sending_options`,
    method: 'GET',
    signal,
  });
};

export const getGetDebtorSendingOptionsQueryKey = (id: string | number) => {
  return [`/private_api/debtors/${id}/sending_options`] as const;
};

export const getGetDebtorSendingOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDebtorSendingOptions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorSendingOptions>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDebtorSendingOptionsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDebtorSendingOptions>>> = ({
    signal,
  }) => getDebtorSendingOptions(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDebtorSendingOptions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDebtorSendingOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDebtorSendingOptions>>
>;
export type GetDebtorSendingOptionsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetDebtorSendingOptions<
  TData = Awaited<ReturnType<typeof getDebtorSendingOptions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorSendingOptions>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getDebtorSendingOptions>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorSendingOptions<
  TData = Awaited<ReturnType<typeof getDebtorSendingOptions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorSendingOptions>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getDebtorSendingOptions>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorSendingOptions<
  TData = Awaited<ReturnType<typeof getDebtorSendingOptions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorSendingOptions>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a debtor sending options
 */

export function useGetDebtorSendingOptions<
  TData = Awaited<ReturnType<typeof getDebtorSendingOptions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorSendingOptions>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDebtorSendingOptionsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a recovery plan
 */
export const getRecoveryPlan = (id: string | number, signal?: AbortSignal) => {
  return customInstance<RecoveryPlanResponse>({
    url: `/private_api/recovery_plans/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetRecoveryPlanQueryKey = (id: string | number) => {
  return [`/private_api/recovery_plans/${id}`] as const;
};

export const getGetRecoveryPlanQueryOptions = <
  TData = Awaited<ReturnType<typeof getRecoveryPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlan>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRecoveryPlanQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecoveryPlan>>> = ({ signal }) =>
    getRecoveryPlan(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRecoveryPlan>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetRecoveryPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getRecoveryPlan>>>;
export type GetRecoveryPlanQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetRecoveryPlan<
  TData = Awaited<ReturnType<typeof getRecoveryPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlan>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getRecoveryPlan>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetRecoveryPlan<
  TData = Awaited<ReturnType<typeof getRecoveryPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlan>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getRecoveryPlan>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetRecoveryPlan<
  TData = Awaited<ReturnType<typeof getRecoveryPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlan>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a recovery plan
 */

export function useGetRecoveryPlan<
  TData = Awaited<ReturnType<typeof getRecoveryPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecoveryPlan>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetRecoveryPlanQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update a recovery plan
 */
export const updateRecoveryPlan = (
  id: string | number,
  createRecoveryPlanBody: CreateRecoveryPlanBody,
) => {
  return customInstance<RecoveryPlanResponse>({
    url: `/private_api/recovery_plans/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createRecoveryPlanBody,
  });
};

export const getUpdateRecoveryPlanMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRecoveryPlan>>,
    TError,
    { id: string | number; data: CreateRecoveryPlanBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateRecoveryPlan>>,
  TError,
  { id: string | number; data: CreateRecoveryPlanBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateRecoveryPlan>>,
    { id: string | number; data: CreateRecoveryPlanBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateRecoveryPlan(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRecoveryPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRecoveryPlan>>
>;
export type UpdateRecoveryPlanMutationBody = CreateRecoveryPlanBody;
export type UpdateRecoveryPlanMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a recovery plan
 */
export const useUpdateRecoveryPlan = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRecoveryPlan>>,
    TError,
    { id: string | number; data: CreateRecoveryPlanBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateRecoveryPlan>>,
  TError,
  { id: string | number; data: CreateRecoveryPlanBody },
  TContext
> => {
  const mutationOptions = getUpdateRecoveryPlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a recovery plan
 */
export const deleteRecoveryPlan = (id: string | number) => {
  return customInstance<NoContentResponse>({
    url: `/private_api/recovery_plans/${id}`,
    method: 'DELETE',
  });
};

export const getDeleteRecoveryPlanMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRecoveryPlan>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteRecoveryPlan>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteRecoveryPlan>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteRecoveryPlan(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteRecoveryPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteRecoveryPlan>>
>;

export type DeleteRecoveryPlanMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete a recovery plan
 */
export const useDeleteRecoveryPlan = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRecoveryPlan>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteRecoveryPlan>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteRecoveryPlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get reasons list
 */
export const getReasons = (params?: GetReasonsParams, signal?: AbortSignal) => {
  return customInstance<ReasonsListResponse>({
    url: `/private_api/reasons`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetReasonsQueryKey = (params?: GetReasonsParams) => {
  return [`/private_api/reasons`, ...(params ? [params] : [])] as const;
};

export const getGetReasonsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReasons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetReasonsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReasons>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReasonsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReasons>>> = ({ signal }) =>
    getReasons(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReasons>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetReasonsQueryResult = NonNullable<Awaited<ReturnType<typeof getReasons>>>;
export type GetReasonsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetReasons<
  TData = Awaited<ReturnType<typeof getReasons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetReasonsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReasons>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getReasons>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetReasons<
  TData = Awaited<ReturnType<typeof getReasons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetReasonsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReasons>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getReasons>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetReasons<
  TData = Awaited<ReturnType<typeof getReasons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetReasonsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReasons>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get reasons list
 */

export function useGetReasons<
  TData = Awaited<ReturnType<typeof getReasons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetReasonsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReasons>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetReasonsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a reason
 */
export const createReason = (createReasonBody: CreateReasonBody, signal?: AbortSignal) => {
  return customInstance<ReasonResponse>({
    url: `/private_api/reasons`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createReasonBody,
    signal,
  });
};

export const getCreateReasonMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReason>>,
    TError,
    { data: CreateReasonBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReason>>,
  TError,
  { data: CreateReasonBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReason>>,
    { data: CreateReasonBody }
  > = (props) => {
    const { data } = props ?? {};

    return createReason(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReasonMutationResult = NonNullable<Awaited<ReturnType<typeof createReason>>>;
export type CreateReasonMutationBody = CreateReasonBody;
export type CreateReasonMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a reason
 */
export const useCreateReason = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReason>>,
    TError,
    { data: CreateReasonBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReason>>,
  TError,
  { data: CreateReasonBody },
  TContext
> => {
  const mutationOptions = getCreateReasonMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update a reason
 */
export const updateReason = (id: string | number, createReasonBody: CreateReasonBody) => {
  return customInstance<ReasonResponse>({
    url: `/private_api/reasons/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createReasonBody,
  });
};

export const getUpdateReasonMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReason>>,
    TError,
    { id: string | number; data: CreateReasonBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReason>>,
  TError,
  { id: string | number; data: CreateReasonBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReason>>,
    { id: string | number; data: CreateReasonBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateReason(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReasonMutationResult = NonNullable<Awaited<ReturnType<typeof updateReason>>>;
export type UpdateReasonMutationBody = CreateReasonBody;
export type UpdateReasonMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a reason
 */
export const useUpdateReason = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReason>>,
    TError,
    { id: string | number; data: CreateReasonBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReason>>,
  TError,
  { id: string | number; data: CreateReasonBody },
  TContext
> => {
  const mutationOptions = getUpdateReasonMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a reason
 */
export const deleteReason = (id: string | number) => {
  return customInstance<NoContentResponse>({ url: `/private_api/reasons/${id}`, method: 'DELETE' });
};

export const getDeleteReasonMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReason>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReason>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReason>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteReason(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReasonMutationResult = NonNullable<Awaited<ReturnType<typeof deleteReason>>>;

export type DeleteReasonMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete a reason
 */
export const useDeleteReason = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReason>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReason>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteReasonMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get invoices to confirm list
 */
export const getInvoicesToConfirm = (params?: GetInvoicesToConfirmParams, signal?: AbortSignal) => {
  return customInstance<InvoicesListResponse>({
    url: `/private_api/import_invoices`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetInvoicesToConfirmQueryKey = (params?: GetInvoicesToConfirmParams) => {
  return [`/private_api/import_invoices`, ...(params ? [params] : [])] as const;
};

export const getGetInvoicesToConfirmQueryOptions = <
  TData = Awaited<ReturnType<typeof getInvoicesToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetInvoicesToConfirmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getInvoicesToConfirm>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInvoicesToConfirmQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoicesToConfirm>>> = ({ signal }) =>
    getInvoicesToConfirm(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInvoicesToConfirm>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetInvoicesToConfirmQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvoicesToConfirm>>
>;
export type GetInvoicesToConfirmQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetInvoicesToConfirm<
  TData = Awaited<ReturnType<typeof getInvoicesToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetInvoicesToConfirmParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getInvoicesToConfirm>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getInvoicesToConfirm>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInvoicesToConfirm<
  TData = Awaited<ReturnType<typeof getInvoicesToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetInvoicesToConfirmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getInvoicesToConfirm>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInvoicesToConfirm>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInvoicesToConfirm<
  TData = Awaited<ReturnType<typeof getInvoicesToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetInvoicesToConfirmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getInvoicesToConfirm>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get invoices to confirm list
 */

export function useGetInvoicesToConfirm<
  TData = Awaited<ReturnType<typeof getInvoicesToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetInvoicesToConfirmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getInvoicesToConfirm>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetInvoicesToConfirmQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Upload invoices CSV
 */
export const uploadInvoiceCSV = (
  uploadInvoiceCSVBody: UploadInvoiceCSVBody,
  signal?: AbortSignal,
) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/import_invoices`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: uploadInvoiceCSVBody,
    signal,
  });
};

export const getUploadInvoiceCSVMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadInvoiceCSV>>,
    TError,
    { data: UploadInvoiceCSVBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadInvoiceCSV>>,
  TError,
  { data: UploadInvoiceCSVBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadInvoiceCSV>>,
    { data: UploadInvoiceCSVBody }
  > = (props) => {
    const { data } = props ?? {};

    return uploadInvoiceCSV(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadInvoiceCSVMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadInvoiceCSV>>
>;
export type UploadInvoiceCSVMutationBody = UploadInvoiceCSVBody;
export type UploadInvoiceCSVMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Upload invoices CSV
 */
export const useUploadInvoiceCSV = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadInvoiceCSV>>,
    TError,
    { data: UploadInvoiceCSVBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadInvoiceCSV>>,
  TError,
  { data: UploadInvoiceCSVBody },
  TContext
> => {
  const mutationOptions = getUploadInvoiceCSVMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete an unvalidated invoice
 */
export const deleteUnvalidatedInvoice = (id: string | number) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/import_invoices/${id}`,
    method: 'DELETE',
  });
};

export const getDeleteUnvalidatedInvoiceMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUnvalidatedInvoice>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUnvalidatedInvoice>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUnvalidatedInvoice>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUnvalidatedInvoice(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUnvalidatedInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUnvalidatedInvoice>>
>;

export type DeleteUnvalidatedInvoiceMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete an unvalidated invoice
 */
export const useDeleteUnvalidatedInvoice = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUnvalidatedInvoice>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUnvalidatedInvoice>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteUnvalidatedInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get debtors to confirm list
 */
export const getDebtorsToConfirm = (params?: GetDebtorsToConfirmParams, signal?: AbortSignal) => {
  return customInstance<DebtorsListResponse>({
    url: `/private_api/import_debtors`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetDebtorsToConfirmQueryKey = (params?: GetDebtorsToConfirmParams) => {
  return [`/private_api/import_debtors`, ...(params ? [params] : [])] as const;
};

export const getGetDebtorsToConfirmQueryOptions = <
  TData = Awaited<ReturnType<typeof getDebtorsToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsToConfirmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorsToConfirm>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDebtorsToConfirmQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDebtorsToConfirm>>> = ({ signal }) =>
    getDebtorsToConfirm(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDebtorsToConfirm>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDebtorsToConfirmQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDebtorsToConfirm>>
>;
export type GetDebtorsToConfirmQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetDebtorsToConfirm<
  TData = Awaited<ReturnType<typeof getDebtorsToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetDebtorsToConfirmParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorsToConfirm>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtorsToConfirm>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorsToConfirm<
  TData = Awaited<ReturnType<typeof getDebtorsToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsToConfirmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorsToConfirm>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtorsToConfirm>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorsToConfirm<
  TData = Awaited<ReturnType<typeof getDebtorsToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsToConfirmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorsToConfirm>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get debtors to confirm list
 */

export function useGetDebtorsToConfirm<
  TData = Awaited<ReturnType<typeof getDebtorsToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetDebtorsToConfirmParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorsToConfirm>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDebtorsToConfirmQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Upload a debtor CSV
 */
export const uploadDebtorCSV = (uploadDebtorCSVBody: UploadDebtorCSVBody, signal?: AbortSignal) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/import_debtors`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: uploadDebtorCSVBody,
    signal,
  });
};

export const getUploadDebtorCSVMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadDebtorCSV>>,
    TError,
    { data: UploadDebtorCSVBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadDebtorCSV>>,
  TError,
  { data: UploadDebtorCSVBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadDebtorCSV>>,
    { data: UploadDebtorCSVBody }
  > = (props) => {
    const { data } = props ?? {};

    return uploadDebtorCSV(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadDebtorCSVMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadDebtorCSV>>
>;
export type UploadDebtorCSVMutationBody = UploadDebtorCSVBody;
export type UploadDebtorCSVMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Upload a debtor CSV
 */
export const useUploadDebtorCSV = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadDebtorCSV>>,
    TError,
    { data: UploadDebtorCSVBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadDebtorCSV>>,
  TError,
  { data: UploadDebtorCSVBody },
  TContext
> => {
  const mutationOptions = getUploadDebtorCSVMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get debtor to confirm details
 */
export const getDebtorToConfirm = (id: string | number, signal?: AbortSignal) => {
  return customInstance<DebtorBasicResponse>({
    url: `/private_api/import_debtors/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetDebtorToConfirmQueryKey = (id: string | number) => {
  return [`/private_api/import_debtors/${id}`] as const;
};

export const getGetDebtorToConfirmQueryOptions = <
  TData = Awaited<ReturnType<typeof getDebtorToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorToConfirm>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDebtorToConfirmQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDebtorToConfirm>>> = ({ signal }) =>
    getDebtorToConfirm(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDebtorToConfirm>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDebtorToConfirmQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDebtorToConfirm>>
>;
export type GetDebtorToConfirmQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetDebtorToConfirm<
  TData = Awaited<ReturnType<typeof getDebtorToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorToConfirm>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtorToConfirm>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorToConfirm<
  TData = Awaited<ReturnType<typeof getDebtorToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDebtorToConfirm>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getDebtorToConfirm>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDebtorToConfirm<
  TData = Awaited<ReturnType<typeof getDebtorToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorToConfirm>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get debtor to confirm details
 */

export function useGetDebtorToConfirm<
  TData = Awaited<ReturnType<typeof getDebtorToConfirm>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDebtorToConfirm>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDebtorToConfirmQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Delete an unvalidated debtor
 */
export const deleteUnvalidatedDebtor = (id: string | number) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/import_debtors/${id}`,
    method: 'DELETE',
  });
};

export const getDeleteUnvalidatedDebtorMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUnvalidatedDebtor>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUnvalidatedDebtor>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUnvalidatedDebtor>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUnvalidatedDebtor(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUnvalidatedDebtorMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUnvalidatedDebtor>>
>;

export type DeleteUnvalidatedDebtorMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete an unvalidated debtor
 */
export const useDeleteUnvalidatedDebtor = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUnvalidatedDebtor>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUnvalidatedDebtor>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteUnvalidatedDebtorMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get similar debtors list
 */
export const getSimilarDebtors = (signal?: AbortSignal) => {
  return customInstance<SimilarDebtorsLightListResponse>({
    url: `/private_api/similar_debtors`,
    method: 'GET',
    signal,
  });
};

export const getGetSimilarDebtorsQueryKey = () => {
  return [`/private_api/similar_debtors`] as const;
};

export const getGetSimilarDebtorsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSimilarDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSimilarDebtors>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSimilarDebtorsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSimilarDebtors>>> = ({ signal }) =>
    getSimilarDebtors(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSimilarDebtors>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSimilarDebtorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSimilarDebtors>>
>;
export type GetSimilarDebtorsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetSimilarDebtors<
  TData = Awaited<ReturnType<typeof getSimilarDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSimilarDebtors>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getSimilarDebtors>>, TError, TData>,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSimilarDebtors<
  TData = Awaited<ReturnType<typeof getSimilarDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSimilarDebtors>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSimilarDebtors>>, TError, TData>,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSimilarDebtors<
  TData = Awaited<ReturnType<typeof getSimilarDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSimilarDebtors>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get similar debtors list
 */

export function useGetSimilarDebtors<
  TData = Awaited<ReturnType<typeof getSimilarDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSimilarDebtors>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSimilarDebtorsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Merge similar debtors
 */
export const mergeSimilarDebtors = (
  mergeSimilarDebtorsBody: MergeSimilarDebtorsBody,
  signal?: AbortSignal,
) => {
  return customInstance<DebtorBasicResponse>({
    url: `/private_api/similar_debtors`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: mergeSimilarDebtorsBody,
    signal,
  });
};

export const getMergeSimilarDebtorsMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof mergeSimilarDebtors>>,
    TError,
    { data: MergeSimilarDebtorsBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof mergeSimilarDebtors>>,
  TError,
  { data: MergeSimilarDebtorsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof mergeSimilarDebtors>>,
    { data: MergeSimilarDebtorsBody }
  > = (props) => {
    const { data } = props ?? {};

    return mergeSimilarDebtors(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MergeSimilarDebtorsMutationResult = NonNullable<
  Awaited<ReturnType<typeof mergeSimilarDebtors>>
>;
export type MergeSimilarDebtorsMutationBody = MergeSimilarDebtorsBody;
export type MergeSimilarDebtorsMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Merge similar debtors
 */
export const useMergeSimilarDebtors = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof mergeSimilarDebtors>>,
    TError,
    { data: MergeSimilarDebtorsBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof mergeSimilarDebtors>>,
  TError,
  { data: MergeSimilarDebtorsBody },
  TContext
> => {
  const mutationOptions = getMergeSimilarDebtorsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete unvalidated imports (debtors or invoices)
 */
export const deleteUnvalidatedImports = (
  deleteUnvalidatedImportsBody: DeleteUnvalidatedImportsBody,
) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/unvalidated_imports`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: deleteUnvalidatedImportsBody,
  });
};

export const getDeleteUnvalidatedImportsMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUnvalidatedImports>>,
    TError,
    { data: DeleteUnvalidatedImportsBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUnvalidatedImports>>,
  TError,
  { data: DeleteUnvalidatedImportsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUnvalidatedImports>>,
    { data: DeleteUnvalidatedImportsBody }
  > = (props) => {
    const { data } = props ?? {};

    return deleteUnvalidatedImports(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUnvalidatedImportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUnvalidatedImports>>
>;
export type DeleteUnvalidatedImportsMutationBody = DeleteUnvalidatedImportsBody;
export type DeleteUnvalidatedImportsMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete unvalidated imports (debtors or invoices)
 */
export const useDeleteUnvalidatedImports = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUnvalidatedImports>>,
    TError,
    { data: DeleteUnvalidatedImportsBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUnvalidatedImports>>,
  TError,
  { data: DeleteUnvalidatedImportsBody },
  TContext
> => {
  const mutationOptions = getDeleteUnvalidatedImportsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get similar debtors to given id
 */
export const getMergeableDebtors = (id: string | number, signal?: AbortSignal) => {
  return customInstance<DebtorsDetailedListResponse>({
    url: `/private_api/similar_debtors/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetMergeableDebtorsQueryKey = (id: string | number) => {
  return [`/private_api/similar_debtors/${id}`] as const;
};

export const getGetMergeableDebtorsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMergeableDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMergeableDebtors>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMergeableDebtorsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMergeableDebtors>>> = ({ signal }) =>
    getMergeableDebtors(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMergeableDebtors>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetMergeableDebtorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMergeableDebtors>>
>;
export type GetMergeableDebtorsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetMergeableDebtors<
  TData = Awaited<ReturnType<typeof getMergeableDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMergeableDebtors>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getMergeableDebtors>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetMergeableDebtors<
  TData = Awaited<ReturnType<typeof getMergeableDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMergeableDebtors>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getMergeableDebtors>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetMergeableDebtors<
  TData = Awaited<ReturnType<typeof getMergeableDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMergeableDebtors>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get similar debtors to given id
 */

export function useGetMergeableDebtors<
  TData = Awaited<ReturnType<typeof getMergeableDebtors>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMergeableDebtors>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetMergeableDebtorsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Ignore duplicates
 */
export const ignoreDuplicates = (id: string | number) => {
  return customInstance<NoContentResponse>({
    url: `/private_api/similar_debtors/${id}`,
    method: 'DELETE',
  });
};

export const getIgnoreDuplicatesMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ignoreDuplicates>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ignoreDuplicates>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ignoreDuplicates>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return ignoreDuplicates(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type IgnoreDuplicatesMutationResult = NonNullable<
  Awaited<ReturnType<typeof ignoreDuplicates>>
>;

export type IgnoreDuplicatesMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Ignore duplicates
 */
export const useIgnoreDuplicates = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ignoreDuplicates>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof ignoreDuplicates>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getIgnoreDuplicatesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get task types list
 */
export const getTaskTypes = (signal?: AbortSignal) => {
  return customInstance<TaskTypesListResponse>({
    url: `/private_api/task_types`,
    method: 'GET',
    signal,
  });
};

export const getGetTaskTypesQueryKey = () => {
  return [`/private_api/task_types`] as const;
};

export const getGetTaskTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getTaskTypes>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTaskTypesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskTypes>>> = ({ signal }) =>
    getTaskTypes(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTaskTypes>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTaskTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskTypes>>>;
export type GetTaskTypesQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetTaskTypes<
  TData = Awaited<ReturnType<typeof getTaskTypes>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTaskTypes<
  TData = Awaited<ReturnType<typeof getTaskTypes>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTaskTypes<
  TData = Awaited<ReturnType<typeof getTaskTypes>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get task types list
 */

export function useGetTaskTypes<
  TData = Awaited<ReturnType<typeof getTaskTypes>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTaskTypesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a task type
 */
export const createTaskType = (createTaskTypeBody: CreateTaskTypeBody, signal?: AbortSignal) => {
  return customInstance<TaskTypeResponse>({
    url: `/private_api/task_types`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createTaskTypeBody,
    signal,
  });
};

export const getCreateTaskTypeMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTaskType>>,
    TError,
    { data: CreateTaskTypeBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTaskType>>,
  TError,
  { data: CreateTaskTypeBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTaskType>>,
    { data: CreateTaskTypeBody }
  > = (props) => {
    const { data } = props ?? {};

    return createTaskType(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTaskTypeMutationResult = NonNullable<Awaited<ReturnType<typeof createTaskType>>>;
export type CreateTaskTypeMutationBody = CreateTaskTypeBody;
export type CreateTaskTypeMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a task type
 */
export const useCreateTaskType = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTaskType>>,
    TError,
    { data: CreateTaskTypeBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTaskType>>,
  TError,
  { data: CreateTaskTypeBody },
  TContext
> => {
  const mutationOptions = getCreateTaskTypeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update a task type
 */
export const updateTaskType = (id: string | number, createTaskTypeBody: CreateTaskTypeBody) => {
  return customInstance<TaskTypeResponse>({
    url: `/private_api/task_types/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createTaskTypeBody,
  });
};

export const getUpdateTaskTypeMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTaskType>>,
    TError,
    { id: string | number; data: CreateTaskTypeBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTaskType>>,
  TError,
  { id: string | number; data: CreateTaskTypeBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTaskType>>,
    { id: string | number; data: CreateTaskTypeBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateTaskType(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTaskTypeMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskType>>>;
export type UpdateTaskTypeMutationBody = CreateTaskTypeBody;
export type UpdateTaskTypeMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a task type
 */
export const useUpdateTaskType = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTaskType>>,
    TError,
    { id: string | number; data: CreateTaskTypeBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTaskType>>,
  TError,
  { id: string | number; data: CreateTaskTypeBody },
  TContext
> => {
  const mutationOptions = getUpdateTaskTypeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a task type
 */
export const deleteTaskType = (id: string | number) => {
  return customInstance<NoContentResponse>({
    url: `/private_api/task_types/${id}`,
    method: 'DELETE',
  });
};

export const getDeleteTaskTypeMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTaskType>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTaskType>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTaskType>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteTaskType(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTaskTypeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskType>>>;

export type DeleteTaskTypeMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete a task type
 */
export const useDeleteTaskType = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTaskType>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTaskType>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteTaskTypeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get tasks list
 */
export const getTasks = (params?: GetTasksParams, signal?: AbortSignal) => {
  return customInstance<TasksListResponse>({
    url: `/private_api/tasks`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetTasksQueryKey = (params?: GetTasksParams) => {
  return [`/private_api/tasks`, ...(params ? [params] : [])] as const;
};

export const getGetTasksQueryOptions = <
  TData = Awaited<ReturnType<typeof getTasks>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetTasksParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTasks>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTasksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTasks>>> = ({ signal }) =>
    getTasks(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTasks>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getTasks>>>;
export type GetTasksQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetTasks<
  TData = Awaited<ReturnType<typeof getTasks>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetTasksParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTasks>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getTasks>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTasks<
  TData = Awaited<ReturnType<typeof getTasks>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetTasksParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTasks>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTasks>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTasks<
  TData = Awaited<ReturnType<typeof getTasks>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetTasksParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTasks>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get tasks list
 */

export function useGetTasks<
  TData = Awaited<ReturnType<typeof getTasks>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetTasksParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTasks>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTasksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a task
 */
export const createTask = (createTaskBody: CreateTaskBody, signal?: AbortSignal) => {
  return customInstance<TaskResponse>({
    url: `/private_api/tasks`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createTaskBody,
    signal,
  });
};

export const getCreateTaskMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTask>>,
    TError,
    { data: CreateTaskBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTask>>,
  TError,
  { data: CreateTaskBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTask>>,
    { data: CreateTaskBody }
  > = (props) => {
    const { data } = props ?? {};

    return createTask(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTaskMutationResult = NonNullable<Awaited<ReturnType<typeof createTask>>>;
export type CreateTaskMutationBody = CreateTaskBody;
export type CreateTaskMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a task
 */
export const useCreateTask = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTask>>,
    TError,
    { data: CreateTaskBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTask>>,
  TError,
  { data: CreateTaskBody },
  TContext
> => {
  const mutationOptions = getCreateTaskMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a task
 */
export const getTask = (id: string | number, signal?: AbortSignal) => {
  return customInstance<TaskResponse>({ url: `/private_api/tasks/${id}`, method: 'GET', signal });
};

export const getGetTaskQueryKey = (id: string | number) => {
  return [`/private_api/tasks/${id}`] as const;
};

export const getGetTaskQueryOptions = <
  TData = Awaited<ReturnType<typeof getTask>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTaskQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTask>>> = ({ signal }) =>
    getTask(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTask>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getTask>>>;
export type GetTaskQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetTask<
  TData = Awaited<ReturnType<typeof getTask>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTask<
  TData = Awaited<ReturnType<typeof getTask>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTask<
  TData = Awaited<ReturnType<typeof getTask>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a task
 */

export function useGetTask<
  TData = Awaited<ReturnType<typeof getTask>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTaskQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update a task
 */
export const updateTask = (id: string | number, createTaskBody: CreateTaskBody) => {
  return customInstance<TaskResponse>({
    url: `/private_api/tasks/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createTaskBody,
  });
};

export const getUpdateTaskMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTask>>,
    TError,
    { id: string | number; data: CreateTaskBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTask>>,
  TError,
  { id: string | number; data: CreateTaskBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTask>>,
    { id: string | number; data: CreateTaskBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateTask(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTaskMutationResult = NonNullable<Awaited<ReturnType<typeof updateTask>>>;
export type UpdateTaskMutationBody = CreateTaskBody;
export type UpdateTaskMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a task
 */
export const useUpdateTask = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTask>>,
    TError,
    { id: string | number; data: CreateTaskBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTask>>,
  TError,
  { id: string | number; data: CreateTaskBody },
  TContext
> => {
  const mutationOptions = getUpdateTaskMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a task
 */
export const deleteTask = (id: string | number) => {
  return customInstance<NoContentResponse>({ url: `/private_api/tasks/${id}`, method: 'DELETE' });
};

export const getDeleteTaskMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTask>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTask>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTask>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteTask(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTaskMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTask>>>;

export type DeleteTaskMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete a task
 */
export const useDeleteTask = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTask>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTask>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteTaskMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create a comment on a task
 */
export const createTaskComment = (
  id: string | number,
  createTaskCommentBody: CreateTaskCommentBody,
  signal?: AbortSignal,
) => {
  return customInstance<TaskResponse>({
    url: `/private_api/tasks/${id}/comments`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createTaskCommentBody,
    signal,
  });
};

export const getCreateTaskCommentMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTaskComment>>,
    TError,
    { id: string | number; data: CreateTaskCommentBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTaskComment>>,
  TError,
  { id: string | number; data: CreateTaskCommentBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTaskComment>>,
    { id: string | number; data: CreateTaskCommentBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return createTaskComment(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTaskCommentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTaskComment>>
>;
export type CreateTaskCommentMutationBody = CreateTaskCommentBody;
export type CreateTaskCommentMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a comment on a task
 */
export const useCreateTaskComment = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTaskComment>>,
    TError,
    { id: string | number; data: CreateTaskCommentBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTaskComment>>,
  TError,
  { id: string | number; data: CreateTaskCommentBody },
  TContext
> => {
  const mutationOptions = getCreateTaskCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update tasks status
 */
export const updateTasksStatus = (
  updateTasksStatusBody: UpdateTasksStatusBody,
  signal?: AbortSignal,
) => {
  return customInstance<TaskActionsResponse>({
    url: `/private_api/tasks/update_status`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateTasksStatusBody,
    signal,
  });
};

export const getUpdateTasksStatusMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTasksStatus>>,
    TError,
    { data: UpdateTasksStatusBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTasksStatus>>,
  TError,
  { data: UpdateTasksStatusBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTasksStatus>>,
    { data: UpdateTasksStatusBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateTasksStatus(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTasksStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTasksStatus>>
>;
export type UpdateTasksStatusMutationBody = UpdateTasksStatusBody;
export type UpdateTasksStatusMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update tasks status
 */
export const useUpdateTasksStatus = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTasksStatus>>,
    TError,
    { data: UpdateTasksStatusBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTasksStatus>>,
  TError,
  { data: UpdateTasksStatusBody },
  TContext
> => {
  const mutationOptions = getUpdateTasksStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get available conditions list
 */
export const getAvailableConditions = (
  params?: GetAvailableConditionsParams,
  signal?: AbortSignal,
) => {
  return customInstance<AvailableConditionsListResponse>({
    url: `/private_api/available_conditions`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetAvailableConditionsQueryKey = (params?: GetAvailableConditionsParams) => {
  return [`/private_api/available_conditions`, ...(params ? [params] : [])] as const;
};

export const getGetAvailableConditionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAvailableConditions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetAvailableConditionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAvailableConditions>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAvailableConditionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableConditions>>> = ({ signal }) =>
    getAvailableConditions(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAvailableConditions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAvailableConditionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailableConditions>>
>;
export type GetAvailableConditionsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetAvailableConditions<
  TData = Awaited<ReturnType<typeof getAvailableConditions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetAvailableConditionsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAvailableConditions>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAvailableConditions>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAvailableConditions<
  TData = Awaited<ReturnType<typeof getAvailableConditions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetAvailableConditionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAvailableConditions>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAvailableConditions>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAvailableConditions<
  TData = Awaited<ReturnType<typeof getAvailableConditions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetAvailableConditionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAvailableConditions>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetAvailableConditions<
  TData = Awaited<ReturnType<typeof getAvailableConditions>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetAvailableConditionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAvailableConditions>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAvailableConditionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get automatic assignations list
 */
export const getAutomaticAssignations = (
  params?: GetAutomaticAssignationsParams,
  signal?: AbortSignal,
) => {
  return customInstance<AutomaticAssignationsListResponse>({
    url: `/private_api/automatic_assignations`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetAutomaticAssignationsQueryKey = (params?: GetAutomaticAssignationsParams) => {
  return [`/private_api/automatic_assignations`, ...(params ? [params] : [])] as const;
};

export const getGetAutomaticAssignationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAutomaticAssignations>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetAutomaticAssignationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignations>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAutomaticAssignationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAutomaticAssignations>>> = ({
    signal,
  }) => getAutomaticAssignations(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAutomaticAssignations>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAutomaticAssignationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAutomaticAssignations>>
>;
export type GetAutomaticAssignationsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetAutomaticAssignations<
  TData = Awaited<ReturnType<typeof getAutomaticAssignations>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetAutomaticAssignationsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignations>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAutomaticAssignations>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAutomaticAssignations<
  TData = Awaited<ReturnType<typeof getAutomaticAssignations>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetAutomaticAssignationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignations>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAutomaticAssignations>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAutomaticAssignations<
  TData = Awaited<ReturnType<typeof getAutomaticAssignations>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetAutomaticAssignationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignations>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetAutomaticAssignations<
  TData = Awaited<ReturnType<typeof getAutomaticAssignations>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetAutomaticAssignationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignations>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAutomaticAssignationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create an automatic assignation
 */
export const createAutomaticAssignation = (
  createAutomaticAssignationBody: CreateAutomaticAssignationBody,
  signal?: AbortSignal,
) => {
  return customInstance<AutomaticAssignationResponse>({
    url: `/private_api/automatic_assignations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createAutomaticAssignationBody,
    signal,
  });
};

export const getCreateAutomaticAssignationMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAutomaticAssignation>>,
    TError,
    { data: CreateAutomaticAssignationBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAutomaticAssignation>>,
  TError,
  { data: CreateAutomaticAssignationBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAutomaticAssignation>>,
    { data: CreateAutomaticAssignationBody }
  > = (props) => {
    const { data } = props ?? {};

    return createAutomaticAssignation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAutomaticAssignationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createAutomaticAssignation>>
>;
export type CreateAutomaticAssignationMutationBody = CreateAutomaticAssignationBody;
export type CreateAutomaticAssignationMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export const useCreateAutomaticAssignation = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAutomaticAssignation>>,
    TError,
    { data: CreateAutomaticAssignationBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAutomaticAssignation>>,
  TError,
  { data: CreateAutomaticAssignationBody },
  TContext
> => {
  const mutationOptions = getCreateAutomaticAssignationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get an automatic assignation
 */
export const getAutomaticAssignation = (id: string | number, signal?: AbortSignal) => {
  return customInstance<AutomaticAssignationResponse>({
    url: `/private_api/automatic_assignations/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetAutomaticAssignationQueryKey = (id: string | number) => {
  return [`/private_api/automatic_assignations/${id}`] as const;
};

export const getGetAutomaticAssignationQueryOptions = <
  TData = Awaited<ReturnType<typeof getAutomaticAssignation>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignation>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAutomaticAssignationQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAutomaticAssignation>>> = ({
    signal,
  }) => getAutomaticAssignation(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAutomaticAssignation>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAutomaticAssignationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAutomaticAssignation>>
>;
export type GetAutomaticAssignationQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetAutomaticAssignation<
  TData = Awaited<ReturnType<typeof getAutomaticAssignation>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignation>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAutomaticAssignation>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAutomaticAssignation<
  TData = Awaited<ReturnType<typeof getAutomaticAssignation>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignation>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAutomaticAssignation>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAutomaticAssignation<
  TData = Awaited<ReturnType<typeof getAutomaticAssignation>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignation>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetAutomaticAssignation<
  TData = Awaited<ReturnType<typeof getAutomaticAssignation>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAutomaticAssignation>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAutomaticAssignationQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update an automatic assignation
 */
export const updateAutomaticAssignation = (
  id: string | number,
  createAutomaticAssignationBody: CreateAutomaticAssignationBody,
) => {
  return customInstance<AutomaticAssignationResponse>({
    url: `/private_api/automatic_assignations/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createAutomaticAssignationBody,
  });
};

export const getUpdateAutomaticAssignationMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAutomaticAssignation>>,
    TError,
    { id: string | number; data: CreateAutomaticAssignationBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAutomaticAssignation>>,
  TError,
  { id: string | number; data: CreateAutomaticAssignationBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAutomaticAssignation>>,
    { id: string | number; data: CreateAutomaticAssignationBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateAutomaticAssignation(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAutomaticAssignationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAutomaticAssignation>>
>;
export type UpdateAutomaticAssignationMutationBody = CreateAutomaticAssignationBody;
export type UpdateAutomaticAssignationMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export const useUpdateAutomaticAssignation = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAutomaticAssignation>>,
    TError,
    { id: string | number; data: CreateAutomaticAssignationBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAutomaticAssignation>>,
  TError,
  { id: string | number; data: CreateAutomaticAssignationBody },
  TContext
> => {
  const mutationOptions = getUpdateAutomaticAssignationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete an automatic assignation
 */
export const deleteAutomaticAssignation = (id: string | number) => {
  return customInstance<NoContentResponse>({
    url: `/private_api/automatic_assignations/${id}`,
    method: 'DELETE',
  });
};

export const getDeleteAutomaticAssignationMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAutomaticAssignation>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAutomaticAssignation>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAutomaticAssignation>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteAutomaticAssignation(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAutomaticAssignationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAutomaticAssignation>>
>;

export type DeleteAutomaticAssignationMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export const useDeleteAutomaticAssignation = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAutomaticAssignation>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAutomaticAssignation>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteAutomaticAssignationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getContactPersons = (id: string | number, signal?: AbortSignal) => {
  return customInstance<ContactPersonsResponseResponse>({
    url: `/private_api/debtors/${id}/contact_persons`,
    method: 'GET',
    signal,
  });
};

export const getGetContactPersonsQueryKey = (id: string | number) => {
  return [`/private_api/debtors/${id}/contact_persons`] as const;
};

export const getGetContactPersonsQueryOptions = <
  TData = Awaited<ReturnType<typeof getContactPersons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPersons>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactPersonsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactPersons>>> = ({ signal }) =>
    getContactPersons(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getContactPersons>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetContactPersonsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContactPersons>>
>;
export type GetContactPersonsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetContactPersons<
  TData = Awaited<ReturnType<typeof getContactPersons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPersons>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getContactPersons>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetContactPersons<
  TData = Awaited<ReturnType<typeof getContactPersons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPersons>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getContactPersons>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetContactPersons<
  TData = Awaited<ReturnType<typeof getContactPersons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPersons>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetContactPersons<
  TData = Awaited<ReturnType<typeof getContactPersons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPersons>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetContactPersonsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createContactPerson = (
  id: string | number,
  createContactPersonBody: CreateContactPersonBody,
  signal?: AbortSignal,
) => {
  return customInstance<ContactPersonResponseResponse>({
    url: `/private_api/debtors/${id}/contact_persons`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createContactPersonBody,
    signal,
  });
};

export const getCreateContactPersonMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContactPerson>>,
    TError,
    { id: string | number; data: CreateContactPersonBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createContactPerson>>,
  TError,
  { id: string | number; data: CreateContactPersonBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createContactPerson>>,
    { id: string | number; data: CreateContactPersonBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return createContactPerson(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateContactPersonMutationResult = NonNullable<
  Awaited<ReturnType<typeof createContactPerson>>
>;
export type CreateContactPersonMutationBody = CreateContactPersonBody;
export type CreateContactPersonMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export const useCreateContactPerson = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContactPerson>>,
    TError,
    { id: string | number; data: CreateContactPersonBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createContactPerson>>,
  TError,
  { id: string | number; data: CreateContactPersonBody },
  TContext
> => {
  const mutationOptions = getCreateContactPersonMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getContactPerson = (
  id: string | number,
  contactPersonId: Id,
  signal?: AbortSignal,
) => {
  return customInstance<ContactPersonResponseResponse>({
    url: `/private_api/debtors/${id}/contact_persons/${contactPersonId}`,
    method: 'GET',
    signal,
  });
};

export const getGetContactPersonQueryKey = (id: string | number, contactPersonId: Id) => {
  return [`/private_api/debtors/${id}/contact_persons/${contactPersonId}`] as const;
};

export const getGetContactPersonQueryOptions = <
  TData = Awaited<ReturnType<typeof getContactPerson>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  contactPersonId: Id,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPerson>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactPersonQueryKey(id, contactPersonId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactPerson>>> = ({ signal }) =>
    getContactPerson(id, contactPersonId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(id && contactPersonId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getContactPerson>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type GetContactPersonQueryResult = NonNullable<Awaited<ReturnType<typeof getContactPerson>>>;
export type GetContactPersonQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetContactPerson<
  TData = Awaited<ReturnType<typeof getContactPerson>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  contactPersonId: Id,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPerson>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getContactPerson>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetContactPerson<
  TData = Awaited<ReturnType<typeof getContactPerson>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  contactPersonId: Id,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPerson>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getContactPerson>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetContactPerson<
  TData = Awaited<ReturnType<typeof getContactPerson>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  contactPersonId: Id,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPerson>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetContactPerson<
  TData = Awaited<ReturnType<typeof getContactPerson>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  contactPersonId: Id,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getContactPerson>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetContactPersonQueryOptions(id, contactPersonId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateContactPerson = (
  id: string | number,
  contactPersonId: Id,
  createContactPersonBody: CreateContactPersonBody,
) => {
  return customInstance<ContactPersonResponseResponse>({
    url: `/private_api/debtors/${id}/contact_persons/${contactPersonId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createContactPersonBody,
  });
};

export const getUpdateContactPersonMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContactPerson>>,
    TError,
    { id: string | number; contactPersonId: Id; data: CreateContactPersonBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateContactPerson>>,
  TError,
  { id: string | number; contactPersonId: Id; data: CreateContactPersonBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateContactPerson>>,
    { id: string | number; contactPersonId: Id; data: CreateContactPersonBody }
  > = (props) => {
    const { id, contactPersonId, data } = props ?? {};

    return updateContactPerson(id, contactPersonId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateContactPersonMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateContactPerson>>
>;
export type UpdateContactPersonMutationBody = CreateContactPersonBody;
export type UpdateContactPersonMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export const useUpdateContactPerson = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContactPerson>>,
    TError,
    { id: string | number; contactPersonId: Id; data: CreateContactPersonBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateContactPerson>>,
  TError,
  { id: string | number; contactPersonId: Id; data: CreateContactPersonBody },
  TContext
> => {
  const mutationOptions = getUpdateContactPersonMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteContactPerson = (id: string | number, contactPersonId: Id) => {
  return customInstance<NoContentResponse>({
    url: `/private_api/debtors/${id}/contact_persons/${contactPersonId}`,
    method: 'DELETE',
  });
};

export const getDeleteContactPersonMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteContactPerson>>,
    TError,
    { id: string | number; contactPersonId: Id },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteContactPerson>>,
  TError,
  { id: string | number; contactPersonId: Id },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteContactPerson>>,
    { id: string | number; contactPersonId: Id }
  > = (props) => {
    const { id, contactPersonId } = props ?? {};

    return deleteContactPerson(id, contactPersonId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteContactPersonMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteContactPerson>>
>;

export type DeleteContactPersonMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export const useDeleteContactPerson = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteContactPerson>>,
    TError,
    { id: string | number; contactPersonId: Id },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteContactPerson>>,
  TError,
  { id: string | number; contactPersonId: Id },
  TContext
> => {
  const mutationOptions = getDeleteContactPersonMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create a payment step calculation
 */
export const createPaymentStepCalculation = (
  createPaymentStepCalculationBody: CreatePaymentStepCalculationBody,
  signal?: AbortSignal,
) => {
  return customInstance<PaymentStepCalculationResponse>({
    url: `/private_api/payment_step_calculations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createPaymentStepCalculationBody,
    signal,
  });
};

export const getCreatePaymentStepCalculationMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPaymentStepCalculation>>,
    TError,
    { data: CreatePaymentStepCalculationBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPaymentStepCalculation>>,
  TError,
  { data: CreatePaymentStepCalculationBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPaymentStepCalculation>>,
    { data: CreatePaymentStepCalculationBody }
  > = (props) => {
    const { data } = props ?? {};

    return createPaymentStepCalculation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePaymentStepCalculationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPaymentStepCalculation>>
>;
export type CreatePaymentStepCalculationMutationBody = CreatePaymentStepCalculationBody;
export type CreatePaymentStepCalculationMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a payment step calculation
 */
export const useCreatePaymentStepCalculation = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPaymentStepCalculation>>,
    TError,
    { data: CreatePaymentStepCalculationBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPaymentStepCalculation>>,
  TError,
  { data: CreatePaymentStepCalculationBody },
  TContext
> => {
  const mutationOptions = getCreatePaymentStepCalculationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update a payment step calculation
 */
export const updatePaymentStepCalculation = (
  id: string | number,
  updatePaymentStepCalculationBody: UpdatePaymentStepCalculationBody,
) => {
  return customInstance<PaymentStepCalculationResponse>({
    url: `/private_api/payment_step_calculations/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updatePaymentStepCalculationBody,
  });
};

export const getUpdatePaymentStepCalculationMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePaymentStepCalculation>>,
    TError,
    { id: string | number; data: UpdatePaymentStepCalculationBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePaymentStepCalculation>>,
  TError,
  { id: string | number; data: UpdatePaymentStepCalculationBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePaymentStepCalculation>>,
    { id: string | number; data: UpdatePaymentStepCalculationBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updatePaymentStepCalculation(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePaymentStepCalculationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePaymentStepCalculation>>
>;
export type UpdatePaymentStepCalculationMutationBody = UpdatePaymentStepCalculationBody;
export type UpdatePaymentStepCalculationMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a payment step calculation
 */
export const useUpdatePaymentStepCalculation = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePaymentStepCalculation>>,
    TError,
    { id: string | number; data: UpdatePaymentStepCalculationBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePaymentStepCalculation>>,
  TError,
  { id: string | number; data: UpdatePaymentStepCalculationBody },
  TContext
> => {
  const mutationOptions = getUpdatePaymentStepCalculationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get payment plans list
 */
export const getPaymentPlans = (params?: GetPaymentPlansParams, signal?: AbortSignal) => {
  return customInstance<PaymentPlansListResponse>({
    url: `/private_api/payment_plans`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetPaymentPlansQueryKey = (params?: GetPaymentPlansParams) => {
  return [`/private_api/payment_plans`, ...(params ? [params] : [])] as const;
};

export const getGetPaymentPlansQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetPaymentPlansParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlans>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentPlansQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentPlans>>> = ({ signal }) =>
    getPaymentPlans(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentPlans>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPaymentPlansQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentPlans>>>;
export type GetPaymentPlansQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetPaymentPlans<
  TData = Awaited<ReturnType<typeof getPaymentPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetPaymentPlansParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlans>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getPaymentPlans>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPaymentPlans<
  TData = Awaited<ReturnType<typeof getPaymentPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetPaymentPlansParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlans>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPaymentPlans>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPaymentPlans<
  TData = Awaited<ReturnType<typeof getPaymentPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetPaymentPlansParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlans>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get payment plans list
 */

export function useGetPaymentPlans<
  TData = Awaited<ReturnType<typeof getPaymentPlans>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetPaymentPlansParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlans>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPaymentPlansQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a payment plan
 */
export const createPaymentPlan = (
  createPaymentPlanBody: CreatePaymentPlanBody,
  signal?: AbortSignal,
) => {
  return customInstance<PaymentPlanResponse>({
    url: `/private_api/payment_plans`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createPaymentPlanBody,
    signal,
  });
};

export const getCreatePaymentPlanMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPaymentPlan>>,
    TError,
    { data: CreatePaymentPlanBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPaymentPlan>>,
  TError,
  { data: CreatePaymentPlanBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPaymentPlan>>,
    { data: CreatePaymentPlanBody }
  > = (props) => {
    const { data } = props ?? {};

    return createPaymentPlan(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePaymentPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPaymentPlan>>
>;
export type CreatePaymentPlanMutationBody = CreatePaymentPlanBody;
export type CreatePaymentPlanMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a payment plan
 */
export const useCreatePaymentPlan = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPaymentPlan>>,
    TError,
    { data: CreatePaymentPlanBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPaymentPlan>>,
  TError,
  { data: CreatePaymentPlanBody },
  TContext
> => {
  const mutationOptions = getCreatePaymentPlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a payment plan
 */
export const getPaymentPlan = (id: string | number, signal?: AbortSignal) => {
  return customInstance<PaymentPlanCompleteResponse>({
    url: `/private_api/payment_plans/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetPaymentPlanQueryKey = (id: string | number) => {
  return [`/private_api/payment_plans/${id}`] as const;
};

export const getGetPaymentPlanQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlan>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentPlanQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentPlan>>> = ({ signal }) =>
    getPaymentPlan(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentPlan>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPaymentPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentPlan>>>;
export type GetPaymentPlanQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetPaymentPlan<
  TData = Awaited<ReturnType<typeof getPaymentPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlan>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getPaymentPlan>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPaymentPlan<
  TData = Awaited<ReturnType<typeof getPaymentPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlan>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPaymentPlan>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPaymentPlan<
  TData = Awaited<ReturnType<typeof getPaymentPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlan>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a payment plan
 */

export function useGetPaymentPlan<
  TData = Awaited<ReturnType<typeof getPaymentPlan>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentPlan>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPaymentPlanQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update a payment plan
 */
export const updatePaymentPlan = (
  id: string | number,
  createPaymentPlanBody: CreatePaymentPlanBody,
) => {
  return customInstance<PaymentPlanResponse>({
    url: `/private_api/payment_plans/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createPaymentPlanBody,
  });
};

export const getUpdatePaymentPlanMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePaymentPlan>>,
    TError,
    { id: string | number; data: CreatePaymentPlanBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePaymentPlan>>,
  TError,
  { id: string | number; data: CreatePaymentPlanBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePaymentPlan>>,
    { id: string | number; data: CreatePaymentPlanBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updatePaymentPlan(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePaymentPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePaymentPlan>>
>;
export type UpdatePaymentPlanMutationBody = CreatePaymentPlanBody;
export type UpdatePaymentPlanMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a payment plan
 */
export const useUpdatePaymentPlan = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePaymentPlan>>,
    TError,
    { id: string | number; data: CreatePaymentPlanBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePaymentPlan>>,
  TError,
  { id: string | number; data: CreatePaymentPlanBody },
  TContext
> => {
  const mutationOptions = getUpdatePaymentPlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Cancel a payment plan
 */
export const cancelPaymentPlan = (
  id: string | number,
  cancelPaymentPlanBody: CancelPaymentPlanBody,
  signal?: AbortSignal,
) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/payment_plans/${id}/cancel`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cancelPaymentPlanBody,
    signal,
  });
};

export const getCancelPaymentPlanMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelPaymentPlan>>,
    TError,
    { id: string | number; data: CancelPaymentPlanBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelPaymentPlan>>,
  TError,
  { id: string | number; data: CancelPaymentPlanBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelPaymentPlan>>,
    { id: string | number; data: CancelPaymentPlanBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return cancelPaymentPlan(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelPaymentPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelPaymentPlan>>
>;
export type CancelPaymentPlanMutationBody = CancelPaymentPlanBody;
export type CancelPaymentPlanMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Cancel a payment plan
 */
export const useCancelPaymentPlan = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelPaymentPlan>>,
    TError,
    { id: string | number; data: CancelPaymentPlanBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof cancelPaymentPlan>>,
  TError,
  { id: string | number; data: CancelPaymentPlanBody },
  TContext
> => {
  const mutationOptions = getCancelPaymentPlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get workflows list
 */
export const getWorkflows = (signal?: AbortSignal) => {
  return customInstance<WorkflowsListResponse>({
    url: `/private_api/workflows`,
    method: 'GET',
    signal,
  });
};

export const getGetWorkflowsQueryKey = () => {
  return [`/private_api/workflows`] as const;
};

export const getGetWorkflowsQueryOptions = <
  TData = Awaited<ReturnType<typeof getWorkflows>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflows>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflows>>> = ({ signal }) =>
    getWorkflows(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getWorkflows>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetWorkflowsQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflows>>>;
export type GetWorkflowsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetWorkflows<
  TData = Awaited<ReturnType<typeof getWorkflows>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflows>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getWorkflows>>, TError, TData>,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetWorkflows<
  TData = Awaited<ReturnType<typeof getWorkflows>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflows>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getWorkflows>>, TError, TData>,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetWorkflows<
  TData = Awaited<ReturnType<typeof getWorkflows>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflows>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get workflows list
 */

export function useGetWorkflows<
  TData = Awaited<ReturnType<typeof getWorkflows>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflows>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetWorkflowsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a workflow
 */
export const createWorkflow = (createWorkflowBody: CreateWorkflowBody, signal?: AbortSignal) => {
  return customInstance<WorkflowResponse>({
    url: `/private_api/workflows`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createWorkflowBody,
    signal,
  });
};

export const getCreateWorkflowMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createWorkflow>>,
    TError,
    { data: CreateWorkflowBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createWorkflow>>,
  TError,
  { data: CreateWorkflowBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createWorkflow>>,
    { data: CreateWorkflowBody }
  > = (props) => {
    const { data } = props ?? {};

    return createWorkflow(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof createWorkflow>>>;
export type CreateWorkflowMutationBody = CreateWorkflowBody;
export type CreateWorkflowMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a workflow
 */
export const useCreateWorkflow = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createWorkflow>>,
    TError,
    { data: CreateWorkflowBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createWorkflow>>,
  TError,
  { data: CreateWorkflowBody },
  TContext
> => {
  const mutationOptions = getCreateWorkflowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a workflow
 */
export const getWorkflow = (id: string | number, signal?: AbortSignal) => {
  return customInstance<WorkflowResponse>({
    url: `/private_api/workflows/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetWorkflowQueryKey = (id: string | number) => {
  return [`/private_api/workflows/${id}`] as const;
};

export const getGetWorkflowQueryOptions = <
  TData = Awaited<ReturnType<typeof getWorkflow>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflow>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflow>>> = ({ signal }) =>
    getWorkflow(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getWorkflow>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetWorkflowQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflow>>>;
export type GetWorkflowQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetWorkflow<
  TData = Awaited<ReturnType<typeof getWorkflow>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflow>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getWorkflow>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetWorkflow<
  TData = Awaited<ReturnType<typeof getWorkflow>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflow>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getWorkflow>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetWorkflow<
  TData = Awaited<ReturnType<typeof getWorkflow>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflow>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a workflow
 */

export function useGetWorkflow<
  TData = Awaited<ReturnType<typeof getWorkflow>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflow>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetWorkflowQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update a workflow
 */
export const updateWorkflow = (id: string | number, createWorkflowBody: CreateWorkflowBody) => {
  return customInstance<WorkflowResponse>({
    url: `/private_api/workflows/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createWorkflowBody,
  });
};

export const getUpdateWorkflowMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateWorkflow>>,
    TError,
    { id: string | number; data: CreateWorkflowBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateWorkflow>>,
  TError,
  { id: string | number; data: CreateWorkflowBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateWorkflow>>,
    { id: string | number; data: CreateWorkflowBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateWorkflow(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflow>>>;
export type UpdateWorkflowMutationBody = CreateWorkflowBody;
export type UpdateWorkflowMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a workflow
 */
export const useUpdateWorkflow = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateWorkflow>>,
    TError,
    { id: string | number; data: CreateWorkflowBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateWorkflow>>,
  TError,
  { id: string | number; data: CreateWorkflowBody },
  TContext
> => {
  const mutationOptions = getUpdateWorkflowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a workflow
 */
export const deleteWorkflow = (id: string | number) => {
  return customInstance<NoContentResponse>({
    url: `/private_api/workflows/${id}`,
    method: 'DELETE',
  });
};

export const getDeleteWorkflowMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteWorkflow>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteWorkflow>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteWorkflow>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteWorkflow(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkflow>>>;

export type DeleteWorkflowMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete a workflow
 */
export const useDeleteWorkflow = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteWorkflow>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteWorkflow>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteWorkflowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Templates list
 */
export const getTemplates = (params?: GetTemplatesParams, signal?: AbortSignal) => {
  return customInstance<TemplatesListResponse>({
    url: `/private_api/templates`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetTemplatesQueryKey = (params?: GetTemplatesParams) => {
  return [`/private_api/templates`, ...(params ? [params] : [])] as const;
};

export const getGetTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetTemplatesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTemplatesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplates>>> = ({ signal }) =>
    getTemplates(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTemplates>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplates>>>;
export type GetTemplatesQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetTemplates<
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params: undefined | GetTemplatesParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTemplates<
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetTemplatesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTemplates<
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetTemplatesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Templates list
 */

export function useGetTemplates<
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  params?: GetTemplatesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTemplatesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a template
 */
export const createTemplate = (createTemplateBody: CreateTemplateBody, signal?: AbortSignal) => {
  return customInstance<TemplateResponse>({
    url: `/private_api/templates`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createTemplateBody,
    signal,
  });
};

export const getCreateTemplateMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTemplate>>,
    TError,
    { data: CreateTemplateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTemplate>>,
  TError,
  { data: CreateTemplateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTemplate>>,
    { data: CreateTemplateBody }
  > = (props) => {
    const { data } = props ?? {};

    return createTemplate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof createTemplate>>>;
export type CreateTemplateMutationBody = CreateTemplateBody;
export type CreateTemplateMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Create a template
 */
export const useCreateTemplate = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTemplate>>,
    TError,
    { data: CreateTemplateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTemplate>>,
  TError,
  { data: CreateTemplateBody },
  TContext
> => {
  const mutationOptions = getCreateTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a template
 */
export const getTemplate = (id: string | number, signal?: AbortSignal) => {
  return customInstance<TemplateResponse>({
    url: `/private_api/templates/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetTemplateQueryKey = (id: string | number) => {
  return [`/private_api/templates/${id}`] as const;
};

export const getGetTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof getTemplate>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplate>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTemplateQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplate>>> = ({ signal }) =>
    getTemplate(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTemplate>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplate>>>;
export type GetTemplateQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetTemplate<
  TData = Awaited<ReturnType<typeof getTemplate>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplate>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getTemplate>>, TError, TData>,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTemplate<
  TData = Awaited<ReturnType<typeof getTemplate>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplate>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTemplate>>, TError, TData>,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTemplate<
  TData = Awaited<ReturnType<typeof getTemplate>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplate>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a template
 */

export function useGetTemplate<
  TData = Awaited<ReturnType<typeof getTemplate>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(
  id: string | number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplate>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTemplateQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update a template
 */
export const updateTemplate = (id: string | number, updateTemplateBody: UpdateTemplateBody) => {
  return customInstance<TemplateResponse>({
    url: `/private_api/templates/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateTemplateBody,
  });
};

export const getUpdateTemplateMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTemplate>>,
    TError,
    { id: string | number; data: UpdateTemplateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTemplate>>,
  TError,
  { id: string | number; data: UpdateTemplateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTemplate>>,
    { id: string | number; data: UpdateTemplateBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateTemplate(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof updateTemplate>>>;
export type UpdateTemplateMutationBody = UpdateTemplateBody;
export type UpdateTemplateMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Update a template
 */
export const useUpdateTemplate = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTemplate>>,
    TError,
    { id: string | number; data: UpdateTemplateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTemplate>>,
  TError,
  { id: string | number; data: UpdateTemplateBody },
  TContext
> => {
  const mutationOptions = getUpdateTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a template
 */
export const deleteTemplate = (id: string | number) => {
  return customInstance<NoContentResponse>({
    url: `/private_api/templates/${id}`,
    method: 'DELETE',
  });
};

export const getDeleteTemplateMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTemplate>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTemplate>>,
  TError,
  { id: string | number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTemplate>>,
    { id: string | number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteTemplate(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTemplate>>>;

export type DeleteTemplateMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Delete a template
 */
export const useDeleteTemplate = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTemplate>>,
    TError,
    { id: string | number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTemplate>>,
  TError,
  { id: string | number },
  TContext
> => {
  const mutationOptions = getDeleteTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Request a payment plan for debtor portal
 */
export const requestPaymentPlan = (
  requestPaymentPlanBody: RequestPaymentPlanBody,
  signal?: AbortSignal,
) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/request_payment_plan`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: requestPaymentPlanBody,
    signal,
  });
};

export const getRequestPaymentPlanMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestPaymentPlan>>,
    TError,
    { data: RequestPaymentPlanBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof requestPaymentPlan>>,
  TError,
  { data: RequestPaymentPlanBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestPaymentPlan>>,
    { data: RequestPaymentPlanBody }
  > = (props) => {
    const { data } = props ?? {};

    return requestPaymentPlan(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestPaymentPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestPaymentPlan>>
>;
export type RequestPaymentPlanMutationBody = RequestPaymentPlanBody;
export type RequestPaymentPlanMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Request a payment plan for debtor portal
 */
export const useRequestPaymentPlan = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestPaymentPlan>>,
    TError,
    { data: RequestPaymentPlanBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof requestPaymentPlan>>,
  TError,
  { data: RequestPaymentPlanBody },
  TContext
> => {
  const mutationOptions = getRequestPaymentPlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Decline a payment plan request make from debtor portal
 */
export const declinePaymentPlanRequest = (
  declinePaymentPlanRequestBody: DeclinePaymentPlanRequestBody,
  signal?: AbortSignal,
) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/decline_payment_plan_request`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: declinePaymentPlanRequestBody,
    signal,
  });
};

export const getDeclinePaymentPlanRequestMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof declinePaymentPlanRequest>>,
    TError,
    { data: DeclinePaymentPlanRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof declinePaymentPlanRequest>>,
  TError,
  { data: DeclinePaymentPlanRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof declinePaymentPlanRequest>>,
    { data: DeclinePaymentPlanRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return declinePaymentPlanRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeclinePaymentPlanRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof declinePaymentPlanRequest>>
>;
export type DeclinePaymentPlanRequestMutationBody = DeclinePaymentPlanRequestBody;
export type DeclinePaymentPlanRequestMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Decline a payment plan request make from debtor portal
 */
export const useDeclinePaymentPlanRequest = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof declinePaymentPlanRequest>>,
    TError,
    { data: DeclinePaymentPlanRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof declinePaymentPlanRequest>>,
  TError,
  { data: DeclinePaymentPlanRequestBody },
  TContext
> => {
  const mutationOptions = getDeclinePaymentPlanRequestMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get the current user encoded JWT
 */
export const getEmbedUserJWT = (signal?: AbortSignal) => {
  return customInstance<JWTResponseResponse>({ url: `/private_api/jwt`, method: 'GET', signal });
};

export const getGetEmbedUserJWTQueryKey = () => {
  return [`/private_api/jwt`] as const;
};

export const getGetEmbedUserJWTQueryOptions = <
  TData = Awaited<ReturnType<typeof getEmbedUserJWT>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmbedUserJWT>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmbedUserJWTQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmbedUserJWT>>> = ({ signal }) =>
    getEmbedUserJWT(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEmbedUserJWT>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetEmbedUserJWTQueryResult = NonNullable<Awaited<ReturnType<typeof getEmbedUserJWT>>>;
export type GetEmbedUserJWTQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

export function useGetEmbedUserJWT<
  TData = Awaited<ReturnType<typeof getEmbedUserJWT>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmbedUserJWT>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getEmbedUserJWT>>, TError, TData>,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmbedUserJWT<
  TData = Awaited<ReturnType<typeof getEmbedUserJWT>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmbedUserJWT>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getEmbedUserJWT>>, TError, TData>,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmbedUserJWT<
  TData = Awaited<ReturnType<typeof getEmbedUserJWT>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmbedUserJWT>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get the current user encoded JWT
 */

export function useGetEmbedUserJWT<
  TData = Awaited<ReturnType<typeof getEmbedUserJWT>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmbedUserJWT>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetEmbedUserJWTQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Reconcile payments and invoices
 */
export const manualReconciliation = (
  reconcilePaymentsAndInvoicesBody: ReconcilePaymentsAndInvoicesBody,
  signal?: AbortSignal,
) => {
  return customInstance<ReconciliationResponseResponse>({
    url: `/private_api/manual_reconciliation`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: reconcilePaymentsAndInvoicesBody,
    signal,
  });
};

export const getManualReconciliationMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manualReconciliation>>,
    TError,
    { data: ReconcilePaymentsAndInvoicesBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof manualReconciliation>>,
  TError,
  { data: ReconcilePaymentsAndInvoicesBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof manualReconciliation>>,
    { data: ReconcilePaymentsAndInvoicesBody }
  > = (props) => {
    const { data } = props ?? {};

    return manualReconciliation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ManualReconciliationMutationResult = NonNullable<
  Awaited<ReturnType<typeof manualReconciliation>>
>;
export type ManualReconciliationMutationBody = ReconcilePaymentsAndInvoicesBody;
export type ManualReconciliationMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Reconcile payments and invoices
 */
export const useManualReconciliation = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manualReconciliation>>,
    TError,
    { data: ReconcilePaymentsAndInvoicesBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof manualReconciliation>>,
  TError,
  { data: ReconcilePaymentsAndInvoicesBody },
  TContext
> => {
  const mutationOptions = getManualReconciliationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Reset the accounting integration
 */
export const resetAccountingIntegration = (signal?: AbortSignal) => {
  return customInstance<SuccessResponse>({
    url: `/private_api/accounting_integrations/reset`,
    method: 'POST',
    signal,
  });
};

export const getResetAccountingIntegrationMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetAccountingIntegration>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetAccountingIntegration>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetAccountingIntegration>>,
    void
  > = () => {
    return resetAccountingIntegration();
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetAccountingIntegrationMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetAccountingIntegration>>
>;

export type ResetAccountingIntegrationMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ServerErrorResponse
>;

/**
 * @summary Reset the accounting integration
 */
export const useResetAccountingIntegration = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetAccountingIntegration>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetAccountingIntegration>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getResetAccountingIntegrationMutationOptions(options);

  return useMutation(mutationOptions);
};

export * from './index';
