import { useContext } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import Card from 'shared/components/Card';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import MoveRecoveryPlanInvoiceModal from 'shared/components/MoveRecoveryPlanInvoiceModal';
import { Button, ButtonColor } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { RecoveryPlan } from 'types/recovery-plan';
import { StoreState } from 'types/storeTypes';

import InvoiceDetailContext from '../InvoiceDetail.context';

import styleIdentifiers from '../ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function InvoiceRecoveryPlan() {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);

  const { data, reloadInfo } = useContext(InvoiceDetailContext);

  const setRecoveryPlan = () => {
    settingsActions.recoveryPlanList({
      callback: (res) => {
        const plans = res.data.map((plan) => treatRecoveryPlan(plan));
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.MEDIUM,
          title: t(i18nKeys.PLAN.SELECT_PLAN),
          children: (
            <ChooseRecoveryPlanModal
              recoverPlans={plans}
              onClick={(planId) => {
                settingsActions.getRecoveryPlan({
                  id: planId,
                  callback: async (plan) => {
                    const newPlan: RecoveryPlan = treatRecoveryPlan(plan.data);
                    const stepsSwitch = await new Promise((resolve) => {
                      if (data.recovery_step_id) {
                        MoveRecoveryPlanInvoices({
                          title: t(i18nKeys.PLAN.INVOICES),
                          newPlan,
                          oldPlanId: data.recovery_plan_id,
                          currentStep: data.recovery_information.step_order,
                          onSubmit: resolve,
                        });
                      } else {
                        showDialog({
                          id: DialogShowId.CUSTOM,
                          size: DialogShowSize.LARGE,
                          title: t(i18nKeys.PLAN.MOVE_INVOICES),
                          children: (
                            <MoveRecoveryPlanInvoiceModal onSubmit={resolve} plan={newPlan} />
                          ),
                        });
                      }
                    });
                    settingsActions.switchPlansInvoices({
                      data: {
                        scope: 'invoice',

                        plans_switch: [
                          {
                            from: data.recovery_step_id ? data.recovery_plan_id : null,
                            to: planId,
                            steps_switch: data.recovery_step_id
                              ? stepsSwitch
                              : [
                                  {
                                    from: null,
                                    to: newPlan.recovery_steps[stepsSwitch as number].id,
                                  },
                                ],
                          },
                        ],
                        invoice_ids: [data.id],
                      },
                      callback: () => {
                        (reloadInfo as any)();
                        settingsActions.recoveryPlanList();
                        dialogHide(DialogShowId.CUSTOM);
                      },
                    });
                    dialogHide(DialogShowId.CUSTOM);
                  },
                });
              }}
            />
          ),
        });
      },
    });
  };

  // Sentry 1A0
  const sendingOptionAttributes = data.sending_options_attributes?.sending_media ?? [];

  const sendingMedia =
    sendingOptionAttributes?.length > 0
      ? data.sending_options_attributes.sending_media
      : (data.recovery_information?.sending_media ?? []);

  return (
    <Card
      title={t(i18nKeys.RECOVERY_PLAN)}
      infosRight={
        data.actions.indexOf('switch_plan') !== -1 && (
          <Button
            noMargin
            color={ButtonColor.GREY}
            label={t(i18nKeys.EDIT)}
            onClick={setRecoveryPlan}
          />
        )
      }
      className={styles('box', 'recovery-plan')}
    >
      {data.recovery_step_id ? (
        <>
          <div className={styles('item', 'block')}>
            <span className={styles('subtitle')}>{t(i18nKeys.NAME)}</span>
            <div className={styles('value', 'medium')}>
              {data.recovery_information.recovery_plan_name}
            </div>
          </div>
          <div className={styles('item', 'block')}>
            <span className={styles('subtitle')}> {t(i18nKeys.NEXT_STEP)}</span>
            <div className={styles('value', 'medium')}>
              <div>
                {t(i18nKeys.ONBOARDING.STEP)} {data.recovery_information.step_order + 1}
              </div>
            </div>
          </div>
          <div className={styles('item', 'block', 'subtitle-item')}>
            <span className={styles('subtitle')}>
              {data.recovery_information.task_title
                ? t(i18nKeys.COMMON.TASK)
                : t(i18nKeys.COMMON.TEMPLATE)}
            </span>
            <div className={styles('value', 'medium')}>
              <div>
                {data.recovery_information.template_name || data.recovery_information.task_title}
              </div>
            </div>
          </div>
          {!data.recovery_information.task_title && (
            <div className={styles('item', 'block', 'subtitle-item')}>
              <span className={styles('subtitle')}>{t(i18nKeys.DEBTOR.SENDING_MEDIUMS)}</span>
              <div className={styles('value', 'medium')}>
                <div>
                  {sendingMedia.map(
                    (sendingMedium, index) =>
                      `${index !== 0 ? ' - ' : ''}${
                        constants.sending_medium.find((item) => sendingMedium.name === item.value)!
                          .description
                      }`,
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={styles('item', 'block')}>
          <span className={styles('subtitle')}>{t(i18nKeys.PLAN.EXECUTED)}</span>
          <div className={styles('value', 'medium')}>
            {data.recovery_information.recovery_plan_name}
          </div>
        </div>
      )}
    </Card>
  );
}
