import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon, IconName } from 'shared/components/Icon';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsItemDelayProps = {
  delay: string | number;
  onClick?: () => void;
  top?: boolean;
  bottom?: boolean;
  after?: boolean;
  before?: boolean;
};

export const WorkflowFormStepsItemDelay = ({
  delay: count,
  onClick,
  top = false,
  bottom = false,
  after = false,
  before = false,
  children,
}: PropsWithChildren<WorkflowFormStepsItemDelayProps>) => {
  const { t } = useTranslation();

  return (
    <div className={styles('delay', top && 'top', bottom && 'bottom', children && 'children')}>
      <div>
        {after && (
          <>{`+ ${t(i18nKeys.DAY_S, {
            count,
          })}`}</>
        )}
        {before && (
          <>{`- ${t(i18nKeys.DAY_S, {
            count,
          })}`}</>
        )}
        {onClick && <Icon name={IconName.PENCIL} onClick={onClick} />}
        {!!children && <div className={styles('children')}>{children}</div>}
      </div>
    </div>
  );
};
