import { PlanType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { RECOVER_ACTION_TYPE } from '../EditDebtCollectionCustomization';

import styleIdentifiers from './RecoverAction.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  planType: PlanType;
  onSelectAction: Function;
};

export default function RecoverAction({ onSelectAction, planType }: Props) {
  const { t } = useTranslation();

  const onChooseTemlateType = (action: RECOVER_ACTION_TYPE) => () => {
    if (
      planType === PlanType.recovery &&
      (action === RECOVER_ACTION_TYPE.LAST_REMINDER ||
        action === RECOVER_ACTION_TYPE.INTERMEDIATE_REMINDER ||
        action === RECOVER_ACTION_TYPE.ADDITIONAL_REMINDER)
    ) {
      showDialog({
        id: DialogShowId.CONFIRM,
        size: DialogShowSize.SMALL,
        title: t(i18nKeys.LATE_FEES),
        children: (
          <AYSModal
            text={t(i18nKeys.TEMPLATE_WITH_LATE_FEES)}
            cancelButtonText={t(i18nKeys.WITHOUT_FEES)}
            confirmButtonText={t(i18nKeys.WITH_FEES)}
            onConfirm={() => {
              onSelectAction(action, true);
            }}
            onCancel={() => {
              onSelectAction(action, false);
            }}
          />
        ),
      });
    } else {
      onSelectAction(action);
    }
  };
  return (
    <div className={styles('recover-action')}>
      <div className={styles('head')}>{t(i18nKeys.ADD_DOCUMENT)}</div>
      <div className={styles('content')}>
        {planType === PlanType.recovery ? (
          <>
            <div
              className={styles('action')}
              onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.INVOICE)}
            >
              <Icon name={IconName.INVOICE} />
              <span>{t(i18nKeys.TEMPLATE_TYPES.INVOICE)}</span>
            </div>
            <div
              className={styles('action')}
              onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.CREDIT_NOTE)}
            >
              <Icon name={IconName.CREDIT_NOTE} />
              <span>{t(i18nKeys.TEMPLATE_TYPES.CREDIT_NOTE)}</span>
            </div>
          </>
        ) : (
          <>
            <div
              className={styles('action')}
              onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.PLAN_CREATION)}
            >
              <Icon name={IconName.PAYMENT_BOOK} />
              <span>{t(i18nKeys.TEMPLATE_TYPES.PLAN_CREATION)}</span>
            </div>
            <div
              className={styles('action')}
              onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.PLAN_UPDATE)}
            >
              <Icon name={IconName.PAYMENT_BOOK} />
              <span>{t(i18nKeys.TEMPLATE_TYPES.PLAN_UPDATE)}</span>
            </div>
            <div
              className={styles('action')}
              onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.PLAN_CANCEL)}
            >
              <Icon name={IconName.PAYMENT_BOOK} />
              <span>{t(i18nKeys.TEMPLATE_TYPES.PLAN_CANCEL)}</span>
            </div>
          </>
        )}
        <div
          className={styles('action')}
          onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.PREVENTIVE_REMINDER)}
        >
          <Icon name={IconName.SEND} />
          <span>{t(i18nKeys.TEMPLATE_TYPES.PREVENTIVE_REMINDER)}</span>
        </div>
        <div
          className={styles('action')}
          onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.FIRST_REMINDER)}
        >
          <Icon name={IconName.SEND} />
          <span>{t(i18nKeys.TEMPLATE_TYPES.FIRST_REMINDER)}</span>
        </div>
        <div
          className={styles('action')}
          onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.INTERMEDIATE_REMINDER)}
        >
          <Icon name={IconName.SEND} />
          <span>{t(i18nKeys.TEMPLATE_TYPES.INTERMEDIATE_REMINDER)}</span>
        </div>
        <div
          className={styles('action')}
          onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.LAST_REMINDER)}
        >
          <Icon name={IconName.SEND} />
          <span>{t(i18nKeys.TEMPLATE_TYPES.LAST_REMINDER)}</span>
        </div>
        {planType === PlanType.recovery && (
          <>
            <div
              className={styles('action')}
              onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.ADDITIONAL_REMINDER)}
            >
              <Icon name={IconName.SEND} />
              <span>{t(i18nKeys.TEMPLATE_TYPES.ADDITIONAL_REMINDER)}</span>
            </div>
            <div
              className={styles('action')}
              onClick={onChooseTemlateType(RECOVER_ACTION_TYPE.FORMAL_NOTICE)}
            >
              <Icon name={IconName.LAW} />
              <span>{t(i18nKeys.TEMPLATE_TYPES.FORMAL_NOTICE)}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
