import { useState } from 'react';
import { useDeleteUnvalidatedDebtor } from 'api';
import { DebtorBasic } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router-dom';
import { Button, ButtonColor } from 'shared/io';
import { TableItemProps } from 'types/table';

import styleIdentifiers from './clientListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientListItemProps = DebtorBasic & {
  checked?: boolean;
};
type Props = TableItemProps<ClientListItemProps> & {
  notValidate: boolean;
  isLoading: boolean;
  refetch: Function;
};

export default function ClientListItem({ item, notValidate, isLoading, refetch }: Props) {
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);
  const history = useHistory();

  const {
    id,
    attributes: { full_name, vat_number, emails, phones, address, csv_updated },
  } = item;

  const { mutate: deleteUnvalidatedDebtor } = useDeleteUnvalidatedDebtor();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const goDetail = () => {
    if (!isDeleting && !isLoading) {
      history.push(`/client-to-${notValidate ? 'confirm' : 'merge'}/${id}`);
    }
  };

  const deleteClient = (e) => {
    if (!isDeleting && !isLoading) {
      e.stopPropagation();
      setIsDeleting(true);
      deleteUnvalidatedDebtor(
        { id },
        {
          onError: () => {
            setIsDeleting(false);
          },
          onSuccess: () => {
            refetch();
          },
        },
      );
    }
  };

  return (
    <tr
      className={styles('client-list-item', hover && 'hover')}
      onMouseEnter={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={goDetail}
    >
      <td />
      <td>{full_name}</td>
      <td>{vat_number}</td>
      <td>{emails.join(', ')}</td>
      <td>{phones[0]?.attributes?.number}</td>
      <td>{address?.attributes?.city}</td>
      <td>{address?.attributes?.country}</td>
      {notValidate && !csv_updated && (
        <td className={styles('Actions')}>
          <Button
            onClick={deleteClient}
            color={ButtonColor.GREY}
            label={t(i18nKeys.DELETE)}
            noMargin
            small
            disabled={isLoading || isDeleting}
          />
        </td>
      )}
    </tr>
  );
}
