import { useCallback, useEffect, useLayoutEffect } from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import InvoiceFooter from 'shared/components/InvoiceFooter';
import { getCgv, useGetCompany, useGetConstants } from 'shared/utils/selectors';

import {
  BillingLogField,
  CommentField,
  CompanyDetails,
  DebtorDetails,
  DebtorField,
  DueDateField,
  IssueDateField,
  TotalsDetails,
} from './components';
import {
  InvoiceFormFieldValues,
  InvoiceFormProps,
  InvoiceFormSharedProps,
} from './InvoiceForm.types';
import { getCurrency, getDefaultDueDate, getDefaultIssueDate, isNoTva } from './InvoiceForm.utils';
import { InvoiceFormContainer } from './InvoiceFormContainer';
import InvoiceLines from './InvoiceLines';

import styleIdentifiers from './InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const InvoiceForm = (props: InvoiceFormProps) => {
  const { t } = useTranslation();
  const constants = useGetConstants();
  const company = useGetCompany() ?? undefined;

  const { initialValues, isCreditNote = false, creditNote, invoice } = props;
  const defaultValues = {
    issue_date: getDefaultIssueDate().toISOString(),
    due_date: getDefaultDueDate(company).toISOString(),
    ...initialValues,
  };

  const form = useForm<InvoiceFormFieldValues>({
    defaultValues,
  });

  const { setValue, watch, reset } = form;

  const debtor = watch('debtor_attributes');
  const issueDate = watch('issue_date');

  const noTva = isNoTva(debtor);
  const currency = getCurrency(company, debtor, invoice || creditNote);

  useLayoutEffect(() => {
    reset({
      issue_date: getDefaultIssueDate().toISOString(),
      due_date: getDefaultDueDate(company).toISOString(),
      ...initialValues,
    });
  }, [initialValues]);

  const setDueDate = useCallback(
    (date: string | Date, paymentDelay?: number) => {
      const delay = paymentDelay || getCgv(company, debtor)?.minimum_payment_terms_delay || 0;

      if (delay) {
        setValue('due_date', dayjs(date).add(delay, 'day').toISOString());
      }
    },
    [company, debtor, setValue],
  );

  useEffect(() => {
    if (issueDate) {
      if (initialValues.issue_date !== issueDate) setDueDate(issueDate);
    }
  }, [issueDate, debtor, setDueDate]);

  const sharedProps = {
    constants,
    company,
    isCreditNote,
    currency,
    noTva,
  } as InvoiceFormSharedProps;

  return (
    <FormProvider {...form}>
      <InvoiceFormContainer {...props} {...sharedProps}>
        <header>
          <div className={styles('head')}>
            {company && (
              <img className={styles('logo')} src={company.invoice_customization.logo_url} alt="" />
            )}
            <div className={styles('title')} />
          </div>
          <div className={styles('infos')}>
            {company && <CompanyDetails {...sharedProps} />}
            <div className={styles('main-detail', 'right')}>
              <div className={styles('input-button')}>
                <DebtorField {...sharedProps} />
              </div>
              {debtor && <DebtorDetails {...sharedProps} debtor={debtor} />}
            </div>
          </div>
        </header>
        <section className={styles('invoice-infos')}>
          <div>
            <div className={styles('invoice-number')}>
              <div className={styles('billing_book')}>
                {t(i18nKeys.INVOICING.CREATE.BILLING_BOOK)}
              </div>
              {company && <BillingLogField {...sharedProps} />}
            </div>
          </div>
          <div className={styles('right')}>
            <div className={styles('info')}>
              <span className={styles('label')}>{t(i18nKeys.DATE)}:</span>
              <IssueDateField {...sharedProps} />
            </div>
            <div className={styles('info')}>
              <span className={styles('label')}>{t(i18nKeys.DUE_DATE)}:</span>
              <DueDateField {...sharedProps} />
            </div>
          </div>
        </section>
        <InvoiceLines {...sharedProps} initialValues={initialValues} />
        <section className={styles('summary')}>
          <div>
            <CommentField {...sharedProps} />
          </div>
          <TotalsDetails {...sharedProps} />
        </section>
        {company && <InvoiceFooter />}
      </InvoiceFormContainer>
    </FormProvider>
  );
};
