import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import SendingMedia from 'shared/components/SendingMedium/SendingMedia';
import FormSection from 'shared/forms/FormSection';
import { Button, ButtonColor } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { settingsActions } from 'store/settings/settings.actions';
import { RecoverActionType, RecoveryPlanStep } from 'types/recovery-plan';
import { StoreState } from 'types/storeTypes';
import { Template } from 'types/template';

import styleIdentifiers from './RecoveryStepForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type RecoveryStepFormSendReminderProps = {
  availableTemplateTypes: RecoverActionType[];
};

export const RecoveryStepFormSendReminder = ({
  availableTemplateTypes,
  children,
}: PropsWithChildren<RecoveryStepFormSendReminderProps>) => {
  const { t } = useTranslation();

  const {
    sending_medium = [],
    recovery_step_types: { recovery_plan_steps: templateTypes = [] },
  } = useSelector((state: StoreState) => state.app.constants);

  const [show, setShow] = useState<RecoverActionType>();
  const [templateList, setTemplateList] = useState<Template[]>([]);

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<RecoveryPlanStep>();

  register('step_type', { required: true });
  register('template', { required: true });
  register('template_id', { required: true });

  const template = watch('template');
  const sending_media = watch('sending_media') || [];

  const getTemplates = (step_type: RecoverActionType) => () => {
    settingsActions.templateList({
      data: {
        step_type,
      },
      noLoading: true,
      callback: ({ data }) => {
        setTemplateList(data.map((item) => treatTemplate(item)));
        setShow(step_type);
      },
    });
  };

  const setTemplate = (newTemplate?: Template) => {
    setValue('template', newTemplate as any);
    setValue('template_id', newTemplate?.id as any);
    setValue('step_type', newTemplate?.step_type as any);
  };

  const addMedium = () => {
    const defaultSendingMedium = (sending_medium.filter(
      (e) => !sending_media.some((el) => e.value === el.name),
    ) || sending_medium)[0];

    if (sending_media.length < sending_medium.length) {
      setValue(
        'sending_media',
        [
          ...sending_media,
          {
            name: defaultSendingMedium.value,
            order: sending_media.length + 1,
            force_sending: 'true',
          },
        ],
        {
          shouldTouch: true,
        },
      );
    }
  };

  const filteredTemplateTypes = templateTypes.filter(({ value }) =>
    availableTemplateTypes.includes(value),
  );

  useEffect(() => {
    if (template) {
      setValue('template_id', template.id);
    }
  }, [template, setValue]);

  return (
    <>
      <FormSection title={t(i18nKeys.DEBTOR.SENDING_MEDIUMS)}>
        <SendingMedia fieldName="sending_media" className={styles('input')} />
        {sending_media.length < sending_medium.length && (
          <Button
            color={ButtonColor.GREY}
            label={t(i18nKeys.ACTIONS.ADD)}
            iconRight={IconName.PLUS}
            onClick={addMedium}
            small
          />
        )}
      </FormSection>
      {children}
      <FormSection title={t(i18nKeys.COMMON.TEMPLATE)} className={styles('form-section')}>
        <div className={styles('actions')}>
          {errors.template && (
            <div className={styles('error-message', 'error')}>
              {t(i18nKeys.PLAN.SELECT_TEMPLATE)}
            </div>
          )}
          {filteredTemplateTypes.map(({ description, value }) => (
            <Fragment key={value}>
              <div
                onClick={getTemplates(value)}
                className={styles(
                  'action',
                  show && 'show-list',
                  show !== value && 'hide',
                  template && 'selected',
                )}
              >
                <div className={styles('template-name')}>
                  <Icon name={IconName.SEND} />
                  <span>{description}</span>
                  <Icon
                    name={IconName.SMALL_REMOVE}
                    className={styles('remove-icon')}
                    onClick={() => setTemplate()}
                  />
                </div>
                {show === value && (
                  <div className={styles('recovr-list')}>
                    {template ? (
                      <div key={template.id} className={styles('choosed')}>
                        <div className={styles('name')}> {template.name}</div>
                      </div>
                    ) : (
                      <>
                        {templateList.length ? (
                          <>
                            {templateList.map((item) => (
                              <div key={item.id} onClick={() => setTemplate(item)}>
                                <div className={styles('name')}>{item.name}</div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className={styles('no-result')}>{t(i18nKeys.NO_RESULT)}</div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </Fragment>
          ))}
        </div>
      </FormSection>
    </>
  );
};
