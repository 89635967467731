import React from 'react';
import { OnClickFunction } from 'types/html-type';
import { Debtor } from 'types/store/client-state';
import { Invoice } from 'types/store/invoice-state';

export type InvoiceDetailContextType = {
  data: Invoice;
  credit?: boolean;
  noValidate?: boolean;
  clientData?: Debtor;
  linkCreditNote?: OnClickFunction;
  settleCreditNote?: OnClickFunction;
  unmatchPayment?: OnClickFunction;
  reloadInfo: OnClickFunction;
};

const InvoiceDetailContext = React.createContext<InvoiceDetailContextType>(
  {} as InvoiceDetailContextType,
);

export default InvoiceDetailContext;
