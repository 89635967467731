import { useEffect, useState } from 'react';
import { useCreateReason, useGetReasons } from 'api';
import { CreateReasonBody } from 'api/models';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, CustomSelect, DateSelector } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { Checkbox } from '@mantine/core';

import { ReasonForm } from '../ReasonForm/ReasonForm';

import styleIdentifiers from './SetReasonForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type SetReasonFormProps = {
  onSubmit: SubmitHandler<{ reason_id: string; end_at: string }>;
  reasonType: string;
};

export const SetReasonForm = ({ onSubmit, reasonType }: SetReasonFormProps) => {
  const { t } = useTranslation();
  const { isLoading: isInvoiceLoading } = useGetInvoiceState()!;
  const { isLoading: isClientLoading } = useGetClientState()!;
  const isLoading = isInvoiceLoading || isClientLoading;
  const [hasEndDate, setHasEndDate] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string>();

  useEffect(() => {
    setHasEndDate(selectedReason === 'promise_of_payment');
  }, [selectedReason]);

  const { data: reasonsListResponse } = useGetReasons({
    reason_type: reasonType,
  });

  const form = useForm<{ reason_id: string; end_at: string }>({
    shouldUnregister: true,
  });

  const { handleSubmit, setValue } = form;

  const minDueDate = dayjs().add(1, 'day');

  const reasons =
    reasonsListResponse?.data.map((e) => ({
      description: e.attributes.name,
      value: e.id,
      uid: e.attributes.uid,
    })) || [];

  const [newReasonsList, setNewReasonsList] = useState<any>(undefined);

  const { mutate: createReason } = useCreateReason();

  const onSubmitReason = (data: CreateReasonBody) =>
    createReason(
      { data },
      {
        onSuccess: ({ data: newReason }) => {
          setNewReasonsList([
            ...reasons,
            {
              description: newReason.attributes.name,
              value: newReason.id,
            },
          ]);
          setValue('reason_id', newReason.id.toString());
          dialogHide(DialogShowId.CONFIRM);
        },
      },
    );

  const addReason = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.ADD_REASON),
      children: (
        <ReasonForm
          onSubmit={onSubmitReason}
          selectedType={reasonType}
          onCancel={() => dialogHide(DialogShowId.CONFIRM)}
        />
      ),
    });

  return (
    <FormProvider {...form}>
      <form className={styles('reason-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('field-type')}>
          <Controller
            rules={{ required: true }}
            name="reason_id"
            render={() => (
              <CustomSelect
                selectClassName={styles('input')}
                keyText="description"
                keyValue="value"
                withBorder
                name="reason_id"
                label={t(i18nKeys.FORM.REASON)}
                items={newReasonsList || reasons}
                filter
                onChange={(value) => {
                  const selectedItem = reasons.find((item) => item.value === value);
                  setSelectedReason(selectedItem?.uid);
                }}
              />
            )}
          />
          <Button label="+" title={t(i18nKeys.ADD_TASK_TYPE)} onClick={addReason} noMargin />
        </div>
        {reasonType === 'pause' && (
          <div className={styles('content')}>
            <Checkbox
              mt="xl"
              size="sm"
              label={t(i18nKeys.FORM.ADD_END_DATE)}
              checked={hasEndDate}
              onChange={(event) => setHasEndDate(event.currentTarget.checked)}
            />
            <AnimatePresence>
              {hasEndDate && (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  transition={{ duration: 0.3 }}
                  exit={{ height: 0 }}
                  key="container"
                >
                  <Controller
                    name="end_at"
                    render={() => (
                      <DateSelector
                        label={t(i18nKeys.FORM.END_DATE)}
                        name="end_at"
                        withBorder
                        required
                        className={styles('input', 'date-selector')}
                        inputClassName={styles('value')}
                        errorClassName={styles('error')}
                        minDate={minDueDate}
                        placeholder={t(i18nKeys.FORM.SELECT_DATE)}
                        handleChange={(value) => {
                          setValue('end_at', value);
                        }}
                      />
                    )}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
        <div className={styles('button-wrapper', 'buttons')}>
          <Button type="submit" isLoading={isLoading} label={t(i18nKeys.CONFIRM)} />
        </div>
      </form>
    </FormProvider>
  );
};
