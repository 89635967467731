import { GetPaymentPlansParams } from 'api/models';
import { i18nKeys } from 'locales';

export enum PaymentPlanStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type PaymentPlansListFiltersFields = Omit<
  GetPaymentPlansParams,
  'page' | 'page_limit' | 'sort_by' | 'sort_order'
>;

export const TABLE_HEADERS = [
  { key: 'id', title: i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ID },
  {
    key: 'debtor',
    title: i18nKeys.COMMON.CLIENT,
  },
  {
    key: 'invoices',
    title: '',
  },
  {
    key: 'status',
    title: i18nKeys.STATUS,
  },
  {
    key: 'next_step',
    title: i18nKeys.NEXT_DEADLINE,
  },
  {
    title: i18nKeys.FORM.ACTIONS,
    width: 100,
  },
];
