import classNames from 'classnames/bind';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import { LightInvoice } from 'types/store/invoice-state';

import { InvoiceFormSharedProps } from '../InvoiceForm.types';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type LightInvoiceItemProps = InvoiceFormSharedProps & {
  item: LightInvoice;
};

export const LightInvoiceItem = ({
  item: { reference, total_tvac, remaining_balance_with_fees },
  company,
  currency,
}: LightInvoiceItemProps) => (
  <div className={styles('invoice-item')}>
    <div className={styles('reference')}>{reference}</div>
    <div className={styles('comp')}>
      {total_tvac && (
        <span>{formatAmount(total_tvac, ',', '.', 2, currencySymbol(currency, company))}</span>
      )}
      {total_tvac && remaining_balance_with_fees && ' / '}
      {remaining_balance_with_fees && (
        <b>
          {formatAmount(
            -remaining_balance_with_fees,
            ',',
            '.',
            2,
            currencySymbol(currency, company),
          )}
        </b>
      )}
    </div>
  </div>
);
