import { useState } from 'react';
import classnames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Button, ButtonColor } from 'shared/io';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';

import styleIdentifiers from './Twikey.module.scss';

const styles = classnames.bind(styleIdentifiers);

type Props = {
  onSubmit: Function;
  onboarding?: boolean;
};

export function Twikey({ onSubmit, onboarding }: Props) {
  const [twikeyUrl, setTwikeyUrl] = useState('');

  const { t, currentLang } = useTranslation();

  const onClick = () => {
    (onboarding ? onBoardingActions.twikey : settingsActions.addTwikey)({
      data: {
        locale: currentLang,
      },
      callback: (data) => {
        setTwikeyUrl(data.twikey_url);
      },
    });
  };

  const finishStep = () => {
    dialogHide(DialogShowId.CUSTOM);
    onSubmit();
  };

  return (
    <div className={styles('twikey')}>
      {twikeyUrl ? (
        <span>
          {t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.TO_CONTINUE)}{' '}
          <a href={twikeyUrl} onClick={finishStep} target="_blank" rel="noreferrer">
            {t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.CLICK_ON_LINK)}
          </a>
        </span>
      ) : (
        <span>
          {t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.CONFIRM)}
          <div className={styles('button-wrapper')}>
            <Button
              color={ButtonColor.GREY}
              label={t(i18nKeys.NO)}
              onClick={() => dialogHide(DialogShowId.CUSTOM)}
            />
            <Button label={t(i18nKeys.YES)} onClick={onClick} />
          </div>
        </span>
      )}
    </div>
  );
}
