import { useGetTemplates } from 'api';
import { GetTemplatesParams, Template, WorkflowStepsAttributes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import RecovrLogoLoader from 'shared/components/Loader';

import styleIdentifiers from './WorkflowFormStep.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepReminderTemplateProps = {
  params: GetTemplatesParams;
  onClick: (template?: Template) => void;
  disabled?: boolean;
};

export const WorkflowFormStepReminderTemplate = ({
  params,
  onClick,
  disabled = false,
}: WorkflowFormStepReminderTemplateProps) => {
  const { t } = useTranslation();

  const { watch } = useFormContext<WorkflowStepsAttributes>();

  const { data: templatesListResponse, isLoading, isError } = useGetTemplates(params);

  if (isLoading && !templatesListResponse) {
    return <RecovrLogoLoader />;
  }

  if (isError || !templatesListResponse) {
    return <span>Error</span>;
  }

  const { data: templates } = templatesListResponse;

  if (disabled || !templates.length) {
    return (
      <div className={styles('recovr-list')}>
        <div className={styles('no-result')}>{t(i18nKeys.NO_RESULT)}</div>
      </div>
    );
  }

  const currentTemplate = templates.find((el) => el.id === watch('template')?.id);

  if (currentTemplate) {
    return (
      <div className={styles('recovr-list')}>
        <div onClick={() => onClick(undefined)} className={styles('choosed')}>
          <div className={styles('name')}>{currentTemplate.attributes.name}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles('recovr-list')}>
      {templates.map((template) => (
        <div key={`template-${template}`} onClick={() => onClick(template)}>
          <div className={styles('name')}>{template.attributes.name}</div>
        </div>
      ))}
    </div>
  );
};
