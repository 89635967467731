import { Currency, PaymentPlanComplete } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { formatCommunication } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';

import PaymentPlanInvoicesDebtor from './PaymentPlanInvoicesDebtor/PaymentPlanInvoicesDebtor';
import PaymentStepsDebtor from './PaymentStepsDebtor/PaymentStepsDebtor';

import styleIdentifiers from './PaymentPlanDetailDebtor.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  paymentPlan: PaymentPlanComplete;
};

export default function PaymentPlanDetailDebtor({ paymentPlan }: Props) {
  const { t, currentLang } = useTranslation();

  const renderInfo = (text, info) => (
    <div className={styles('key-text')}>
      <div className={styles('attribute')}>{t(text)}: </div>
      <div className={styles('value')}>{info}</div>
    </div>
  );

  const {
    id,
    attributes: {
      remaining_balance,
      payment_steps_attributes,
      communication,
      completed,
      cancelled,
      created_at,
      next_due_date,
      invoices,
      currency,
      with_fees,
    },
  } = paymentPlan;

  return (
    <div className={styles('side-menu-info')}>
      <div className={styles('head')}>{`${t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)} #${id}`}</div>
      <div className={styles('content')}>
        <h3>{t(i18nKeys.DETAILS)}</h3>
        {renderInfo(i18nKeys.COMMUNICATION, formatCommunication(communication))}
        {renderInfo(i18nKeys.ISSUE_DATE, formattedDate(created_at, currentLang))}
        {!completed &&
          !cancelled &&
          renderInfo(i18nKeys.NEXT_DEADLINE, formattedDate(next_due_date, currentLang))}
        {!completed &&
          renderInfo(
            i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT,
            <Amount
              localizedValue={remaining_balance}
              value={remaining_balance}
              suffix={currency}
            />,
          )}
        <hr className={styles('content-separator')} />
        <PaymentStepsDebtor
          paymentSteps={payment_steps_attributes}
          currency={currency as Currency}
        />
        <hr className={styles('content-separator')} />
        <PaymentPlanInvoicesDebtor
          withFees={with_fees}
          invoices={invoices!}
          currency={currency as Currency}
        />
      </div>
    </div>
  );
}
