import { useMemo } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';
import { formattedDate } from 'shared/utils/view';

import styleIdentifiers from './ChooseInvoiceItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  item: any;
  formValues: any;
  toggleAdd: Function;
};

type State = { id: number; add?: boolean; add_fees?: boolean };

export default function ChooseGroupedItem({ item, formValues, toggleAdd }: Props) {
  const { t, currentLang } = useTranslation();

  const {
    id,
    type,
    remaining_balance,
    amount,
    itemType,
    reference,
    payment_modality_translated,
    communication,
    received_at,
    issue_date,
    value_date,
    total_tvac,
    localized_money_object,
    currency,
    updated_amounts,
    late_fees,
    already_paid_amount,
    paused,
    disputed,
  } = item;

  const isInvoice = type === 'fees_preview_invoice';

  const invoice = useMemo<State>(
    () => formValues[itemType].find((value) => id === value.id)!,
    [formValues],
  );

  const saldoComputation = (item, localized = false) => {
    const priceObject = localized ? localized_money_object : item!;
    let value = 0;

    if (!isInvoice && type !== 'grouped_credit_note') {
      value = (remaining_balance || amount) * -1;
    } else {
      value = priceObject.remaining_balance;
    }

    return value;
  };

  return (
    <tr
      key={`${itemType}${id}`}
      className={styles('choose-invoice-item', {
        positive: isInvoice,
        active: invoice.add,
      })}
    >
      <td className={styles('flex')}>
        <Checkbox
          name=""
          className={styles('checkbox')}
          checked={invoice.add}
          onChange={toggleAdd(itemType, id)}
        />
        {paused && <Icon name={IconName.PAUSE} title={t(i18nKeys.CLIENT.FILTER.PAUSED)} />}
        {disputed && <Icon name={IconName.LAW} title={t(i18nKeys.FOLLOW_UP.SUMMARY.CONFLICT)} />}
      </td>
      <td>
        {isInvoice
          ? t(i18nKeys.COMMON.INVOICE)
          : type === 'grouped_credit_note'
            ? t(i18nKeys.CREDIT_NOTE)
            : t(i18nKeys.PAYMENT)}
      </td>
      <td className={styles('reference')}>
        {reference ||
          payment_modality_translated ||
          communication ||
          formattedDate(received_at, currentLang)}
      </td>
      <td>{formattedDate(issue_date || value_date, currentLang)}</td>
      <td>
        <Amount
          localizedValue={total_tvac}
          value={localized_money_object.total_tvac}
          suffix={currency}
          className={styles('value', 'bigger')}
        />
      </td>
      <td>
        {already_paid_amount && (
          <Amount
            localizedValue={already_paid_amount}
            value={localized_money_object.already_paid_amount}
            suffix={currency}
            className={styles('value', 'bigger')}
          />
        )}
      </td>
      <td>
        <Amount
          localizedValue={saldoComputation(item)}
          value={saldoComputation(item, true)}
          suffix={currency}
          className={styles('value', 'bigger')}
        />
      </td>
    </tr>
  );
}
