import axios from 'axios';
import { omit } from 'remeda';
import apiService from 'shared/service/api.service';

export const REDUX_AXIOS_INSTANCE = axios.create();

// MARK: axios call
function axiosCall(url, request) {
  return REDUX_AXIOS_INSTANCE(url, request)
    .then((response) => response)
    .catch((error) => error.response);
}

// MARK: API call logic
export function call(path, type, options) {
  const url = !options.raw ? apiService.baseUrl + path : path;

  let headers = {
    'Content-Type': 'application/json',
    tenant: apiService.tenant,
    ...omit(options.headers, ['content-length']),
  } as any;

  let { data } = options;
  if (options.fileUpload) {
    headers = {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data;',
    };

    const formData = new FormData();

    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      formData.append(key, data[key]);
    }
    data = formData;
  }

  // Request
  const request: any = {};
  request.method = type;
  request.headers = headers;

  request.data = data;
  request.params = type === 'GET' ? options.data : options.params;

  if (options.fileUpload) {
    request.onUploadProgress = function (event) {
      if (typeof options.progress === 'function') {
        const progress = Math.round((event.loaded / event.total) * 100);
        options.progress(progress);
      }
    };
  }

  return axiosCall(url, request);
}

// MARK: - Download logic
export function downloadBlob(path, method, options) {
  const headers = options.headers || {};
  if (options.public) headers.tenant = 'public';
  else headers.tenant = apiService.tenant;

  // Request
  const request: any = {};
  request.method = method || 'GET';
  request.headers = omit(headers, ['content-length']);
  request.responseType = 'blob';

  request.data = options.data;
  request.params = options.params;

  return axiosCall(apiService.baseUrl + path, request);
}

export function download(path, method, options) {
  const url = apiService.baseUrl + path;

  const headers = options.headers || {};
  if (options.public) headers.tenant = 'public';
  else headers.tenant = apiService.tenant;

  // Request
  const request: any = {};
  request.method = method || 'GET';
  request.headers = omit(headers, ['content-length']);
  request.responseType = 'blob';

  request.data = options.data;
  request.params = options.params;

  // TODO: This method of triggering the download does not seem to work on some embedded browsers,
  // like the ones produced by opening a link fron inside Slack or an email client
  return axiosCall(url, request);
}

// MARK: - CRUD
export const crud = (name: string) => ({
  list: (options) => call(`${name}`, 'GET', options),
  detail: (options) => call(`${name}/${options.id}`, 'GET', options),
  add: (options) => call(`${name}`, 'POST', options),
  update: (options) => call(`${name}/${options.id}`, 'PUT', options),
  delete: (options) => call(`${name}/${options.id}`, 'DELETE', options),
});
