import { Fragment, Reducer, useEffect, useMemo, useReducer } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon, IconName } from 'shared/components/Icon';
import { RecoveryPlan } from 'shared/components/RecoveryPlan';
import { Button, ButtonColor } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { reducerState } from 'shared/utils/view';
import { error } from 'store/view/view.actions';
import { RecoveryPlan as RecoveryPlanType } from 'types/recovery-plan';

import styleIdentifiers from './MoveRecoveryPlanInvoicesModal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  newPlan: RecoveryPlanType;
  oldPlan: RecoveryPlanType;
  onSubmit: Function;
  currentStep?: number;
  allStepRequired?: boolean;
};
type State = {
  oldStepChoosed: number | null;
  arrows: (number | null)[];
};

export default function MoveRecoveryPlanInvoicesModal({
  newPlan,
  oldPlan,
  onSubmit,
  currentStep,
  allStepRequired,
}: Props) {
  const { t } = useTranslation();
  const { isLoading: isInvoiceLoading } = useGetInvoiceState()!;
  const { isLoading: isClientLoading } = useGetClientState()!;
  const isLoading = isInvoiceLoading || isClientLoading;

  const oldPlanHasPrevReminder = useMemo(
    () => oldPlan.recovery_steps[0].step_type === 'preventive_reminder',
    [oldPlan],
  );
  const newPlanHasPrevReminder = useMemo(
    () => newPlan.recovery_steps[0].step_type === 'preventive_reminder',
    [newPlan],
  );

  const initArrow = () => {
    const diffIndex =
      newPlanHasPrevReminder !== oldPlanHasPrevReminder ? (newPlanHasPrevReminder ? 1 : -1) : 0;
    return oldPlan.recovery_steps.map((step, index) => {
      if (typeof currentStep === 'number' && currentStep !== index) {
        return null;
      }

      const newPlanIndex = index + diffIndex;

      let arrowIndex: number | null = null;

      if (newPlanIndex >= 0) {
        if (
          index >= oldPlan.recovery_steps.length - 1 &&
          oldPlan.recovery_steps[index].step_type === 'last_reminder'
        ) {
          arrowIndex =
            newPlan.recovery_steps[newPlan.recovery_steps.length - 1].step_type === 'last_reminder'
              ? newPlan.recovery_steps.length - 1
              : null;
        } else if (newPlanIndex >= newPlan.recovery_steps.length) {
          arrowIndex =
            oldPlan.recovery_steps[index].step_type === 'last_reminder' &&
            newPlan.recovery_steps[newPlan.recovery_steps.length - 1].step_type === 'last_reminder'
              ? newPlan.recovery_steps.length - 1
              : null;
        } else {
          arrowIndex =
            oldPlan.recovery_steps[index].step_type ===
            newPlan.recovery_steps[newPlanIndex].step_type
              ? newPlanIndex
              : null;
        }
      }
      // If a step is not created yet, user can't move his invoices in this step
      return arrowIndex !== null && newPlan.recovery_steps[arrowIndex].id ? arrowIndex : null;
    });
  };

  const [state, setState] = useReducer<Reducer<State, any>>(reducerState, {
    oldStepChoosed: currentStep !== undefined ? currentStep : null,
    arrows: initArrow(),
  });
  useEffect(() => {
    setState({
      oldStepChoosed: currentStep !== undefined ? currentStep : null,
      arrows: initArrow(),
    });
  }, [newPlan, oldPlan]);

  const onClickOnStep = (index) => {
    setState({
      oldStepChoosed:
        currentStep !== undefined ? currentStep : index === state.oldStepChoosed ? null : index,
    });
  };
  const onClickonNewStep = (index) => {
    if (
      newPlan.recovery_steps[index].id &&
      state.oldStepChoosed !== null &&
      (index === 0
        ? newPlan.recovery_steps[0].step_type !== 'preventive_reminder' ||
          (state.oldStepChoosed === 0 &&
            oldPlan.recovery_steps[0].step_type === 'preventive_reminder')
        : true)
    ) {
      const arrows = state.arrows.slice();
      arrows[state.oldStepChoosed] = index;
      setState({
        arrows,
      });
    }
  };

  const removeArrow = (index: number) => () => {
    const arrows = state.arrows.slice();
    arrows[index] = null;
    setState({
      arrows,
    });
  };

  const submit = () => {
    if (allStepRequired && state.arrows.some((arrow) => arrow === null)) {
      error({
        text: i18nKeys.PLAN.ASSOCIATE_ALL_STEPS,
      });
    } else {
      const payload = {};

      state.arrows.forEach((to, from) => {
        if (state.arrows[from] !== null) {
          const toId = newPlan.recovery_steps[to!].id!;
          const fromId = oldPlan.recovery_steps[from].id!;
          if (payload[toId]) {
            payload[toId].push(fromId);
          } else {
            payload[toId] = [fromId];
          }
        }
      });
      const steps_switch: any[] = [];
      for (const to in payload) {
        steps_switch.push({
          to,
          from: payload[to],
        });
      }
      onSubmit(steps_switch);
    }
  };
  return (
    <div className={styles('move-recovery-plan-invoices')}>
      <div className={styles('header')}>
        <div>{t(i18nKeys.PLAN.TRANSFERT_INVOICES)}</div>
        <Button
          isLoading={isLoading}
          label={t(i18nKeys.FORM.VALIDATE)}
          color={ButtonColor.MAIN}
          noMargin
          onClick={submit}
        />
      </div>
      <div className={styles('plans-wrapper')}>
        <div className={styles('plan')}>
          <div className={styles('plan-name')}>{oldPlan.name}</div>
          <RecoveryPlan
            recoverPlan={oldPlan}
            onClickOnStep={currentStep! > -1 ? undefined : onClickOnStep}
            stepHighlight={currentStep ?? state.oldStepChoosed}
            stepClassName={styles('old-step')}
          />
        </div>

        <div className={styles('space')}>
          {state.arrows.map((newStep, index) => {
            if (typeof newStep !== 'number') {
              return null;
            }
            const stepGap = index - newStep;
            const endArrow =
              25 +
              (newStep === 0 && newPlanHasPrevReminder ? 65 : 160) +
              140 * newStep +
              (stepGap === 0 ? 0 : (stepGap / Math.abs(stepGap)) * 20);
            return (
              <Fragment key={index}>
                {!allStepRequired && currentStep === undefined && (
                  <div
                    className={styles('remove-button')}
                    onClick={removeArrow(index)}
                    style={{
                      top: (oldPlanHasPrevReminder && index === 0 ? 90 : 185) + 140 * index,
                    }}
                  >
                    <Icon name={IconName.SMALL_REMOVE} />
                  </div>
                )}
                <svg>
                  <defs>
                    <marker
                      id="arrow"
                      markerWidth="10"
                      markerHeight="10"
                      refX="2"
                      refY="6"
                      orient="auto"
                    >
                      <path d="M0,3 L0,9 L4,6 L0,3" />
                    </marker>
                  </defs>
                  <path
                    d={`M0,${
                      (oldPlanHasPrevReminder && index === 0 ? 90 : 185) + 140 * index
                    } L80,${endArrow}`}
                    style={{
                      strokeWidth: '3px',
                      fill: 'none',
                      markerEnd: 'url(#arrow)',
                    }}
                  />
                </svg>
              </Fragment>
            );
          })}
        </div>
        <div className={styles('plan')}>
          <div className={styles('plan-name')}>{newPlan.name}</div>
          <RecoveryPlan
            recoverPlan={newPlan}
            messageInfo={t(i18nKeys.PLAN.STEP_MUST_BE_CREATED)}
            stepClassName={state.oldStepChoosed !== null && styles('active-step')}
            onClickOnStep={onClickonNewStep}
            canClickPreventiveReminder={
              state.oldStepChoosed === 0 &&
              oldPlan.recovery_steps[0].step_type === 'preventive_reminder'
            }
          />
        </div>
      </div>
    </div>
  );
}
