import { Card as MantineCard, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { styled } from '@stitches/react';

const TickerCard = styled('div', {
  userSelect: 'none',
  transition: 'all ease-in-out 150ms',
  minWidth: 'fit-content',
  flex: 1,
  variants: {
    clickable: {
      true: {
        '&:hover': {
          transform: 'translateY(-2px)',
        },
      },
    },
  },
});

export interface BaseTickerProps {
  label: string;
  value?: string;
  suffix?: string;
  url?: string;
}

export const BaseTicker = ({ label, value, suffix, url }: BaseTickerProps) => {
  if (value == null) {
    return (
      <TickerCard>
        <MantineCard radius="md" shadow="sm" p="md" h="110px" w="170px">
          <LoadingOverlay visible zIndex={1} />
        </MantineCard>
      </TickerCard>
    );
  }

  return (
    <TickerCard clickable={url != null}>
      <MantineCard<'a' | 'div'>
        radius="md"
        shadow="sm"
        h="110px"
        {...(url != null ? { component: 'a', href: url, target: '_blank' } : {})}
      >
        <Stack gap="md">
          <Stack>
            <Text ff="Roboto" c="gray.5" size="sm" fw="bold">
              {label}
            </Text>
            <Group gap="5px" align="end" ff="Roboto">
              <Text size="36px" c="blue" fw="bold">
                {value}
              </Text>
              {suffix != null && (
                <Text size="28px" c="dimmed" tt="uppercase" fw="bold">
                  {suffix}
                </Text>
              )}
            </Group>
          </Stack>
        </Stack>
      </MantineCard>
    </TickerCard>
  );
};
