import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const DisputedInvoicesSchema = z
  .object({
    totalInvoicesDisputed: z.preprocess((val) => Number(val), z.number()),
  })
  .transform(({ totalInvoicesDisputed }) => totalInvoicesDisputed);

export type DisputedInvoices = z.infer<typeof DisputedInvoicesSchema>;

export async function loadDisputedInvoicesFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;
  const { data } = await instance.get('analytics/disputed_invoices');

  return DisputedInvoicesSchema.parse(data);
}

export function useLoadDisputedInvoices() {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<DisputedInvoices, ApiError>({
    queryKey: ['disputed-invoices'],
    queryFn: () => loadDisputedInvoicesFn(axiosInstance),
  });

  return addResourceNameToQueryResult<DisputedInvoices, ApiError, 'disputedInvoices'>(
    'disputedInvoices',
    queryResult,
  );
}
