import { useEffect, useRef, useState } from 'react';
import { Id } from 'api/models';
import { CommentForm } from 'app/Private/Comments';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import DateItem from 'shared/components/DateItem';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { useProfile } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { removeAttributes } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { Conversation as ConversationType, InvoiceFeedback } from 'types/store/activity';

import styleIdentifiers from './Conversation.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  caseId?: Id;
  conversation: InvoiceFeedback;
  onSubmit: onSubmitFunction;
  className?: string;
  invoiceId?: number;
  canNotComment?: boolean;
};

export default function Conversation({
  caseId,
  conversation,
  onSubmit,
  className,
  invoiceId,
  canNotComment,
}: Props) {
  const profile = useProfile();
  const { t, currentLang } = useTranslation();
  const [messages, setMessages] = useState<ConversationType>(conversation.conversation);

  const messageWrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    messageWrapperRef.current!.scrollTop = messageWrapperRef.current!.scrollHeight;
  }, [messages]);

  const submitMessage = ({ attachment, comment }: { comment: string; attachment: File }) => {
    invoiceActions.actionsConversationMessage({
      id: conversation.id,
      fileUpload: true,
      data: {
        body: comment,
        attachment,
      },
      callback: ({ data }) => {
        onSubmit();
        const newMessages = { ...messages };
        newMessages.messages.push(removeAttributes(data));
        setMessages(newMessages);
      },
    });
  };

  const downloadSentLetter = () => {
    invoiceActions.document({
      id: invoiceId,
      document_type: 'lawyer_case_pdf',
      third_party_case_id: caseId,
    });
  };

  return (
    <div className={styles('conversation', className)}>
      {conversation.has_tp_case_pdf && (
        <div>
          <Button
            className={styles('download-button')}
            noMargin
            color={ButtonColor.GREY}
            label={t(i18nKeys.DOWNLOAD_SENT_LETTER)}
            onClick={downloadSentLetter}
          />
        </div>
      )}
      <div className={styles('message-container')} ref={messageWrapperRef}>
        {messages.messages.map((message, key) => (
          <div
            key={message.id || key}
            className={styles(
              'message',
              message.is_system && 'system',
              message.owner && 'right',
              message.author === `${profile.name.full}` && 'user',
            )}
          >
            <div className={styles('message-wrapper')}>
              <div className={styles('message-info')}>
                <span className={styles('date')}>
                  <DateItem date={message.date} lg={currentLang} format="LLL" />
                </span>
                <span className={styles('from')}>
                  {message.is_system ? 'Robot' : message.author}
                </span>
              </div>
              <div className={styles('message')}>
                <HTML html={message.body} />
              </div>
              {message.attachment_url && (
                <div className={styles('actions')}>
                  <a href={message.attachment_url} target="_blank" rel="noopener noreferrer">
                    <Icon name={IconName.FILE} />
                    {message.attachment_name}
                    {/* {t(i18nKeys.SEE_FILE)} */}
                  </a>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {!canNotComment && (
        <CommentForm
          withFile
          onSubmit={submitMessage}
          labelText={t(i18nKeys.NEW_MESSAGE)}
          label={t(i18nKeys.ACTIVITIES.SEND_MESSAGE)}
        />
      )}
    </div>
  );
}
