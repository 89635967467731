import { useState } from 'react';
import {
  useCreateAutomaticAssignation,
  useGetAutomaticAssignations,
  useGetAvailableConditions,
} from 'api';
import {
  AssignableModel,
  AssignableTo,
  CreateAutomaticAssignationBody,
  GetAutomaticAssignationsParams,
  GetAvailableConditionsParams,
} from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import { useProfile } from 'shared/hooks';
import { Button } from 'shared/io';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import { AutomaticAssignationForm } from '../Forms/AutomaticAssignationForm';

import { AutomaticAssignationListItem } from './AutomaticAssignationsListItem';

import styleIdentifiers from './AutomaticAssignationsList.module.scss';

const styles = classNames.bind(styleIdentifiers);
const EnhancedTable = CustomTable(AutomaticAssignationListItem);

type Props = {
  tabItems: Array<any>;
  defaultType: AssignableTo;
};

export const AutomaticAssignationsList = ({ tabItems, defaultType }: Props) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const [params, setParams] = useState<GetAutomaticAssignationsParams>({
    model_to_assign: AssignableModel.invoice,
    assign_to_type: AssignableTo[defaultType],
    page_limit: profile.preferences.itemsPerPage,
    page: 1,
  });

  const {
    data: automaticAssignationsListResponse,
    isLoading,
    isFetched,
    refetch,
  } = useGetAutomaticAssignations(params);

  const headers = [
    {
      key: 'model_to_assign',
      title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.MODEL_TO_ASSIGN),
      width: 200,
    },
    {
      key: 'name',
      title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGN_TO),
      width: 300,
    },
    {
      key: 'assignation_conditions',
      title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGNATION_CONDITIONS),
      width: 400,
    },
    {
      key: 'order',
      title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ORDER),
      width: 100,
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  const [conditionParams, setConditionParams] = useState<GetAvailableConditionsParams>({
    model_type: params.model_to_assign,
  });

  const { data: availableConditionsListResponse } = useGetAvailableConditions(conditionParams);

  const availableConditions = availableConditionsListResponse?.data || [];

  const { mutate: createAutomaticAssignationMutation } = useCreateAutomaticAssignation();

  const addAutomaticAssignation = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <AutomaticAssignationForm
          availableConditions={availableConditions}
          prefill={{
            modelToAssign: params.model_to_assign,
            assignToType: params.assign_to_type,
          }}
          onSubmit={(data: CreateAutomaticAssignationBody) =>
            createAutomaticAssignationMutation(
              { data },
              {
                onSuccess: () => {
                  sideMenuHide();
                  refetch();
                },
              },
            )
          }
        />
      ),
    });
  };

  const changeView = (data: Partial<GetAutomaticAssignationsParams>) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...data,
    }));
    setConditionParams(() => ({
      model_type: data.model_to_assign,
    }));
  };

  const isActive = ({ model_to_assign, assign_to_type }) =>
    params.assign_to_type === assign_to_type && params.model_to_assign === model_to_assign;

  return (
    <div className={styles('listing')}>
      <div className={styles('head')}>
        <h3>{t(i18nKeys.AUTOMATIC_ASSIGNATION)}</h3>
        <Button
          noMargin
          label={t(i18nKeys.ACTIONS.ADD)}
          onClick={addAutomaticAssignation}
          iconRight={IconName.PLUS}
          disabled={!company.package.can_use_automatic_assignations}
        />
      </div>
      <EnhancedTable
        notSortable
        className={styles('listing__table')}
        headers={headers}
        items={automaticAssignationsListResponse?.data || []}
        pagination={automaticAssignationsListResponse?.metadata.pagination}
        loading={isLoading}
        loaded={isFetched}
        noCheckbox
        itemProps={{
          refetch,
          metadata: automaticAssignationsListResponse?.metadata,
          availableConditions,
        }}
        noShadow
        customTabs={
          <div className={styles('tabs')}>
            {tabItems.map(({ key, ...rest }) => (
              <div
                key={key}
                className={styles('tab', isActive(rest) && 'active')}
                onClick={() => changeView(rest)}
              >
                {t(key)}
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
};
