import { i18nKeys, useTranslation } from 'locales';

import { Button, Group } from '@mantine/core';

interface ModalFooterProps {
  onCancel: VoidFunction;
  onSave: VoidFunction;
  // On some modals, we remove the body padding to achieve a flush effect on the content, but the footer
  // still needs some spacing from the edge.
  withOwnPadding?: boolean;
  isDisabled?: boolean;
}

export const ModalFooter = ({
  onCancel,
  onSave,
  isDisabled = false,
  withOwnPadding = false,
}: ModalFooterProps) => {
  const { t } = useTranslation();

  return (
    <Group justify="flex-end" gap="md" w="100%" mt="md" pr={withOwnPadding ? 'md' : '0px'}>
      <Button color="orange" variant="subtle" onClick={onCancel}>
        {t(i18nKeys.CANCEL)}
      </Button>
      <Button disabled={isDisabled} color="blue" onClick={onSave}>
        {t(i18nKeys.SAVE)}
      </Button>
    </Group>
  );
};
