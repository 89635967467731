import { useContext } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router-dom';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import ChooseCollaboratorModal from 'shared/components/ChooseCollaboratorModal';
import DateItem from 'shared/components/DateItem';
import DownloadInvoice from 'shared/components/DownloadInvoice';
import { Icon, IconName } from 'shared/components/Icon';
import { useProfile } from 'shared/hooks';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { LightInvoice } from 'types/store/invoice-state';

import LinkDocument from '../../LinkDocument';
import ImportOriginalInvoice from '../ImportOriginalInvoice';
import InvoiceDetailContext from '../InvoiceDetail.context';
import ToHandleDetail from '..';

import styleIdentifiers from '../ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  modal?: boolean;
};

export default function InvoiceInfo({ modal }: Props) {
  const { t, currentLang } = useTranslation();
  const profile = useProfile();
  const { data, credit, noValidate, reloadInfo } = useContext(InvoiceDetailContext);
  const history = useHistory();

  const seePdf = () => {
    dialogHide(DialogShowId.INVOICE);
    history.push(`/invoices/detail/${data.id}`);
  };
  const sendOriginalInvoice = () => {
    if (!credit) {
      invoiceActions.detail({ id: data.id });
    }
    dialogHide(DialogShowId.CUSTOM);
  };

  const openLinkDocument = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      title: t(i18nKeys.INVOICE.JOIN_FILE),
      children: <LinkDocument invoice={data} />,
    });
  };

  const importOriginalInvoice = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      title: t(i18nKeys.INVOICE.ORIGINAL_FILE),
      children: <ImportOriginalInvoice onEditOriginalFile={sendOriginalInvoice} invoice={data} />,
    });
  };

  const showInvoice = (linkedInvoice: LightInvoice) => {
    if (!modal) {
      history.push(`/invoices/${linkedInvoice.id}`);
    } else {
      showDialog({
        id: DialogShowId.INVOICE,
        size: DialogShowSize.LARGE,
        children: <ToHandleDetail id={linkedInvoice.id} modal />,
      });
    }
  };

  const editAccountManager = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CHOOSE_ACCOUNT_MANAGER),
      children: (
        <ChooseCollaboratorModal
          initialValues={data.account_manager}
          onSubmit={(values) => {
            invoiceActions.assignToAccManager({
              id: data.id,
              data: values,
              callback: () => {
                (reloadInfo as any)();
                dialogHide(DialogShowId.CUSTOM);
              },
            });
          }}
        />
      ),
    });
  };

  const unlinkCn = (linkedInvoice: LightInvoice) => {
    creditNoteActions.unlinkToInvoices({
      id: data.id,
      data: {
        unlink_invoice: linkedInvoice.id,
      },
      callback: () => {
        dialogHide(DialogShowId.CUSTOM);
        (reloadInfo as any)();
      },
    });
  };

  return (
    <Card
      actionHead={data.type === 'invoice' ? seePdf : undefined}
      title={t(credit ? 'CREDIT_NOTE' : 'COMMON.INVOICE')}
      className={styles('invoice-info', 'box')}
    >
      <div>
        <div className={styles('item')}>
          <span className={styles('subtitle')}>{t(i18nKeys.FORM.REFERENCE)}</span>
          <span className={styles('value', 'blue', 'bigger')}>{data.reference}</span>
        </div>
        {credit && !!data?.matched_payments_attributes.length && (
          <div className={styles('item')}>
            <span className={styles('subtitle')}>
              {t(
                data?.matched_payments_attributes.length > 1
                  ? i18nKeys.INVOICE.LINK_INVOICES
                  : i18nKeys.INVOICE.LINK_INVOICE,
              )}
            </span>
            <span
              className={styles('value', 'blue', 'bigger', 'clickable')}
              style={{ flexDirection: 'column' }}
            >
              {data.matched_payments_attributes.map(({ invoice_attributes }, i) => {
                const key = `${invoice_attributes.id}${i}`;
                return (
                  <span key={key} style={{ display: 'flex', alignItems: 'center' }}>
                    <span onClick={() => showInvoice(invoice_attributes)}>
                      {invoice_attributes.reference}
                    </span>
                    <Icon
                      name={IconName.UNLINK}
                      onClick={() => unlinkCn(invoice_attributes)}
                      className={styles('ml-10')}
                      title={t(i18nKeys.INVOICE.UNLINK)}
                    />
                  </span>
                );
              })}
            </span>
          </div>
        )}
        <div className={styles('item')}>
          <span className={styles('subtitle')}>{t(i18nKeys.ISSUE_DATE)}</span>
          <DateItem
            date={data.issue_date}
            lg={currentLang}
            format="DD/MM/YY"
            className={styles('value', 'blue', 'bigger')}
          />
        </div>
        <div className={styles('item')}>
          <span className={styles('subtitle')}>{t(i18nKeys.TOTAL_VAT_PRICE)}</span>
          <Amount
            localizedValue={data.total_tvac}
            value={data.localized_money_object.total_tvac}
            suffix={data.currency}
            className={styles('value', 'bigger')}
          />
        </div>
        <div className={styles('item')}>
          <span className={styles('subtitle')}>{t(i18nKeys.ACCOUNT_MANAGER)}</span>
          <div className={styles('value')}>
            {data.account_manager
              ? `${data.account_manager.first_name} ${data.account_manager.last_name}`
              : t(i18nKeys.NOT_ASSIGNED)}
            {profile?.isAdmin && (
              <Icon
                name={IconName.PENCIL}
                className={styles('ml-10')}
                onClick={editAccountManager}
              />
            )}
          </div>
        </div>
      </div>

      {!noValidate && (
        <div className={styles('card-header')}>
          <DownloadInvoice
            importOriginalInvoice={importOriginalInvoice}
            className={styles('icon')}
            invoice={data}
            ubl={data.has_ubl}
          />

          {data.additional_files_attributes && (
            <div
              className={styles('inline')}
              onClick={openLinkDocument}
              title={t(i18nKeys.INVOICE.ADDITIONAL_DOCUMENT)}
            >
              <Icon name={IconName.ADDITIONAL_DOCUMENT} className={styles('icon')} />
              <span className={styles('text-circle', 'text-circle-top')}>
                {data.additional_files_attributes.length}
              </span>
            </div>
          )}
        </div>
      )}
    </Card>
  );
}
