import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export const UpdateFooterSchema = z.array(
  z
    .union([z.null(), z.string(), z.object({ field: z.string(), content: z.string() })])
    .transform((val) => (val == null ? false : val)),
);

export type UpdateFooter = z.infer<typeof UpdateFooterSchema>;

export async function updateInvoiceFooterFn(
  axiosInstance: Promise<AxiosInstance>,
  data: UpdateFooter,
) {
  const instance = await axiosInstance;
  return instance.put('invoice_footer_customisation', {
    fields: data,
  });
}

export function useUpdateInvoiceFooter() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, UpdateFooter>({
    mutationFn: (data: UpdateFooter) => updateInvoiceFooterFn(axiosInstance, data),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['invoice-footer'],
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, UpdateFooter, 'updateInvoiceFooter'>(
    'updateInvoiceFooter',
    mutation,
  );
}
