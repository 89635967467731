import { useProfile } from 'shared/hooks';
import apiService from 'shared/service/api.service';

import { Avatar, Center, Menu, Text } from '@mantine/core';

export const TenantSwitcher = () => {
  const profile = useProfile();

  return (
    <>
      {profile.accessibleTenants.map((tenant) => {
        const isSelected = tenant.subdomain === apiService.tenant;

        return (
          <Menu.Item
            key={tenant.id}
            onClick={() => window.location.assign(tenant.url)}
            leftSection={
              <Avatar radius={6} size={24} src={tenant.logo} name={tenant.companyName} />
            }
            styles={isSelected ? { item: { backgroundColor: '#F2F5F7' } } : undefined}
          >
            {isSelected ? (
              <Center inline>
                <Text>{tenant.companyName}</Text>
                <Text ml={5} c="#67869B">
                  · Connecté
                </Text>
              </Center>
            ) : (
              <Text>{tenant.companyName}</Text>
            )}
          </Menu.Item>
        );
      })}
    </>
  );
};
