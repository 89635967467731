import classNames from 'classnames/bind';
import { PontoForm, PontoFormFields } from 'shared/components/Integrations';

import styleIdentifiers from './Ponto.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: (data: PontoFormFields) => void;
};

export default function Ponto({ onSubmit }: Props) {
  return (
    <div className={styles('Ponto')}>
      <PontoForm onSubmit={onSubmit} />
    </div>
  );
}
