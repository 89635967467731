import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const ActionableInvoicesSchema = z
  .object({
    totalInvoicesActionable: z.preprocess((val) => Number(val), z.number()),
  })
  .transform(({ totalInvoicesActionable }) => totalInvoicesActionable);

export type ActionableInvoices = z.infer<typeof ActionableInvoicesSchema>;

export async function loadActionableInvoicesFn(
  axiosInstance: Promise<AxiosInstance>,
): Promise<ActionableInvoices> {
  const instance = await axiosInstance;
  const { data } = await instance.get('analytics/actionable_invoices');
  return ActionableInvoicesSchema.parse(data);
}

export function useLoadActionableInvoices() {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<ActionableInvoices, ApiError>({
    queryKey: ['actionable-invoices'],
    queryFn: () => loadActionableInvoicesFn(axiosInstance),
  });

  return addResourceNameToQueryResult<ActionableInvoices, ApiError, 'actionableInvoices'>(
    'actionableInvoices',
    queryResult,
  );
}
