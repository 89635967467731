import { useUpdateReport } from 'shared/hooks';
import { Report } from 'shared/hooks/use-load-report';

import { Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';

import { PeriodicityStep } from '../../CreateReport/steps';

interface EditReportRecurrenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  report: Report;
}

export const EditReportRecurrenceModal = ({
  isOpen,
  onClose,
  report,
}: EditReportRecurrenceModalProps) => {
  const { updateReport, isUpdateReportLoading } = useUpdateReport();

  const daysWeek = report.periodicity.type === 'weekly' ? report.periodicity.days : [];
  const daysMonth =
    report.periodicity.type === 'monthly'
      ? report.periodicity.days.map((date) => {
          const d = new Date('01-01-2024');
          d.setDate(date);
          return d;
        })
      : [];

  const form = useForm({
    initialValues: {
      periodicity: report.periodicity.type,
      daysWeek,
      daysMonth,
    },
  });

  const handleSave = () => {
    updateReport(
      {
        id: report.id,
        periodicity: {
          type: form.values.periodicity,
          days:
            form.values.periodicity === 'weekly'
              ? form.values.daysWeek
              : form.values.daysMonth.map((date) => date.getDate()),
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Modal opened={isOpen} onClose={onClose} title="Edit name" size="auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <PeriodicityStep form={form} />
        <Group w="100%" justify="end" mt="xl">
          <Button variant="light" color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" loading={isUpdateReportLoading}>
            Save
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
