import { useEffect } from 'react';
import { useLoadCompany, useProfile } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import { Environment, getEnv, isOneOfEnvs } from 'shared/utils';

export function useBootIntercom() {
  const profile = useProfile();
  const { company: companyV2 } = useLoadCompany()!;

  useEffect(() => {
    if (
      companyV2 != null &&
      profile != null &&
      window.Intercom != null &&
      isOneOfEnvs(Environment.PRODUCTION)
    ) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: __INTERCOM_APP_ID__,
        name: profile.name.full,
        company: {
          id: companyV2?.id,
          name: `${companyV2.name}${
            companyV2.name.toLowerCase() === apiService.tenant ? '' : `(${apiService.tenant})`
          }`,
        },
        user_id: profile.id,
        email: profile.email,
        environment: getEnv(),
        user_hash: profile.intercomHash,
      });
      window.Intercom('update', { z_index: 9 });
    }
  }, [profile, companyV2]);
}
