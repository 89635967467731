import { useEffect, useState } from 'react';
import CustomVariables from 'app/Private/CustomVariables';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DebtorAdditionalInfo from 'shared/components/DebtorAdditionalInfo';
import DebtorInfo from 'shared/components/DebtorInfo';
import { Icon, IconName } from 'shared/components/Icon';
import { treatClient } from 'shared/serializer';
import { clientActions } from 'store/client/client.actions';
import { Debtor } from 'types/store/client-state';

import styleIdentifiers from './ClientsImportedDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const ClientsImportedDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { params } = useRouteMatch<{ id: string }>();
  const [importedClient, setImportedClient] = useState<Debtor>();

  useEffect(() => {
    clientActions.getToConfirm({
      id: params.id as any,
      callback: ({ data }) => {
        setImportedClient(treatClient(data) as any);
      },
    });
  }, []);

  const goToList = () => {
    history.push('/client/to-confirm');
  };

  return (
    <div className={styles('imported-client-detail')}>
      {importedClient && (
        <div className={styles('client-wrapper')}>
          <div className={styles('title-line')}>
            <Icon name={IconName.MINIMAL_LEFT} onClick={goToList} />{' '}
            <h1>{importedClient.full_name || importedClient.id}</h1>
          </div>
          <div className={styles('line')}>
            <DebtorInfo
              clientData={importedClient}
              imported
              label={t(i18nKeys.CLIENT.GENERAL_INFO)}
            />
            <DebtorAdditionalInfo clientData={importedClient} imported />
          </div>
          {Object.keys(importedClient.custom_variables).length > 0 && (
            <CustomVariables data={importedClient.custom_variables} invoiceView />
          )}
        </div>
      )}
    </div>
  );
};
