import { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadViewsV2 } from 'shared/hooks';
import { useScrollShadow } from 'shared/hooks/ui/use-scroll-shadow';
import { ResourceType } from 'types/resource-types';

import { Box, Button, Group, LoadingOverlay, ScrollArea, Space, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { useCreateView } from '../../../hooks/use-create-view';

import { ViewSettings } from './components';

type Props = {
  resourceType: ResourceType;
};

export function ViewSettingsModal({ resourceType }: Props) {
  const { t } = useTranslation();
  const { views, isViewsLoading } = useLoadViewsV2({ resourceType });
  const { createView, isCreateViewLoading } = useCreateView();
  const { topShadow, bottomShadow, scrollableRef } = useScrollShadow(views?.length, 'dark');

  const [shouldScrollToNewElement, setShouldScrollToNewElement] = useState(false);

  useEffect(() => {
    if (shouldScrollToNewElement) {
      scrollableRef.current?.scrollTo({
        top: scrollableRef.current!.scrollHeight,
        behavior: 'smooth',
      });
      setShouldScrollToNewElement(false);
    }
  }, [views?.length]);

  return (
    <Stack>
      <ScrollArea
        px="sm"
        viewportRef={scrollableRef}
        viewportProps={{ style: { maxHeight: 'min(900px, calc(100vh - 250px))' } }}
        bg="gray.2"
        style={(theme) => ({ borderRadius: theme.radius.sm })}
      >
        {topShadow}
        {bottomShadow}
        {isViewsLoading ? (
          <Box h={450}>
            <LoadingOverlay visible overlayProps={{ backgroundOpacity: 0 }} />
          </Box>
        ) : (
          <>
            <Space h="sm" />
            <Stack>
              {views?.map((view) => (
                <ViewSettings
                  key={view.id}
                  view={view}
                  onDuplicate={() => setShouldScrollToNewElement(true)}
                />
              ))}
            </Stack>
            <Space h="sm" />
          </>
        )}
      </ScrollArea>
      <Group justify="end">
        <Button
          disabled={isViewsLoading}
          loading={isCreateViewLoading}
          rightSection={<IconPlus strokeWidth={1.5} size={20} />}
          onClick={() => {
            createView({ resourceType }, { onSuccess: () => setShouldScrollToNewElement(true) });
          }}
        >
          {t(i18nKeys.VIEWS.SETTINGS.NEW)}
        </Button>
      </Group>
    </Stack>
  );
}
