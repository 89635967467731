import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { useGetClientState } from 'shared/utils/selectors';
import { settingsActions } from 'store/settings/settings.actions';

import styleIdentifiers from './ChooseDefaultTemplate.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ChooseDefaultTemplateProps = {
  onSubmit: Function;
};

export const ChooseDefaultTemplate = ({ onSubmit }: ChooseDefaultTemplateProps) => {
  const { t } = useTranslation();
  const { isLoading: isClientLoading } = useGetClientState()!;
  const [templates, setTemplates] = useState([]);
  const form = useForm();

  const loadTemplates = () => {
    form.setValue('template_id', '');
    settingsActions.templateList({
      data: {
        step_type: form.getValues().template_type,
      },
      callback: ({ data }) => {
        setTemplates(data.map((item) => treatTemplate(item)));
      },
    });
  };

  const submit = () => {
    onSubmit(form.getValues());
  };

  return (
    <div className={styles('choose-default-template')}>
      <FormProvider {...form}>
        <Controller
          name="template_type"
          defaultValue=""
          render={() => (
            <CustomSelect
              selectClassName={styles('input')}
              onValueChanged={loadTemplates}
              noMargin
              keyText="description"
              keyValue="value"
              withBorder
              name="template_type"
              items={[
                {
                  description: t(i18nKeys.COMMON.INVOICE),
                  value: 'invoice',
                },
                {
                  description: t(i18nKeys.CREDIT_NOTE),
                  value: 'credit_note',
                },
              ]}
              label={t(i18nKeys.TEMPLATE_TYPE)}
            />
          )}
        />
        <Controller
          name="template_id"
          defaultValue=""
          render={() => (
            <CustomSelect
              selectClassName={styles('input')}
              keyText="name"
              keyValue="id"
              withBorder
              name="template_id"
              items={templates}
              label={t(i18nKeys.TEMPLATES)}
            />
          )}
        />
      </FormProvider>
      <div className={styles('button-wrapper')}>
        <Button label={t(i18nKeys.FORM.VALIDATE)} onClick={submit} isLoading={isClientLoading} />
      </div>
    </div>
  );
};
