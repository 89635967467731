import { useReducer } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { isDefined } from 'remeda';
import { reducerState } from 'shared/utils/view';

import { ActionIcon, Center, Group, Stack, Table, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

import TableFooter from '../CustomTable/TableFooter';

type Props = {
  data: Array<any>;
};

export default function ListResModal({ data }: Props) {
  const { t } = useTranslation();

  const [{ showElements, currentPage, feedback }, setState] = useReducer(reducerState, {
    showElements: data.slice(0, 20),
    currentPage: 1,
    feedback: data.reduce(
      (acc, { error_message }) => {
        if (error_message) {
          acc.error++;
        } else {
          acc.success++;
        }
        return acc;
      },
      { error: 0, success: 0 },
    ),
  });

  const setPage = ({ selected }) => {
    setState({
      currentPage: selected + 1,
      showElements: data.slice(selected * 20, (selected + 1) * 20),
    });
  };

  return (
    <Stack align="stretch" justify="center" mih="100px" p="20px">
      <Group w="100%" justify="space-around" mb="sm">
        {feedback.success > 0 && (
          <Text c="green">{t(i18nKeys.SUCCESSFULL_ACTIONS, { count: feedback.success })}</Text>
        )}
        {feedback.error > 0 && (
          <Text c="red">{t(i18nKeys.FAILED_ACTIONS, { count: feedback.error })}</Text>
        )}
      </Group>
      <Group mah="65vh" style={{ overflowY: 'auto' }}>
        <Table withRowBorders={false}>
          <Table.Tbody>
            {showElements.map(({ id, key, error_message, message, title }) => (
              <Table.Tr key={id}>
                <Table.Td w="45%" ta="left">
                  <Text fw={600} fz={isDefined(key) ? 'md' : 'sm'} lineClamp={1}>
                    {key ?? title}
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Center>
                    <ActionIcon
                      style={{ cursor: 'default' }}
                      variant="transparent"
                      color={error_message ? 'red' : 'green'}
                    >
                      {error_message ? <IconX stroke={1.5} size={20} /> : <IconCheck size={20} />}
                    </ActionIcon>
                  </Center>
                </Table.Td>
                <Table.Td>
                  <Text ta="left" size="sm" c="grey.5">
                    {error_message ?? message}
                  </Text>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Group>
      {data.length > 20 && (
        <TableFooter
          currentPage={currentPage}
          noShadow
          itemsLength={showElements.length}
          noPageLimit
          setPage={setPage}
          pagination={{
            current_page: currentPage,
            last_page: Math.ceil(data.length / 20),
            page_limit: 20,
            total_objects: data.length,
          }}
        />
      )}
    </Stack>
  );
}
