import { i18nKeys, useTranslation } from 'locales';

import { css } from '@emotion/css';
import { Box, Checkbox, Divider, Group, Paper, Radio, Text, UnstyledButton } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';

const styles = {
  calendar: css`
    width: fit-content;
    [data-weekend] {
      color: black;
    }

    .mantine-DatePicker-calendarHeader {
      display: none;
    }

    .mantine-DatePicker-day {
      border-radius: var(--mantine-radius-sm);
      :hover {
        background-color: var(--mantine-color-gray-1);
      }
    }

    [data-selected] {
      color: white;

      :hover {
        background-color: var(--mantine-color-blue-6);
      }
    }
  `,
  dayOfWeekCheckbox: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    text-align: center;
    border-radius: var(--mantine-radius-md);
    background-color: var(--mantine-color-gray-0);
    cursor: pointer;
    padding: var(--mantine-spacing-md);
    margin-top: var(--mantine-spacing-xs);
    margin-bottom: var(--mantine-spacing-xs);
    width: 100%;
  `,
};

interface FormType {
  periodicity: 'weekly' | 'monthly';
  daysWeek: Array<number>;
  daysMonth: Array<Date>;
}

interface PeriodicityStepProps {
  form: UseFormReturnType<FormType>;
}

export const PeriodicityStep = ({ form }: PeriodicityStepProps) => {
  const { t } = useTranslation();

  const handleToggleDay = (day: number) => {
    if (form.values.daysWeek.includes(day)) {
      form.setFieldValue(
        'daysWeek',
        form.values.daysWeek.filter((d) => d !== day),
      );
    } else {
      form.setFieldValue('daysWeek', [...form.values.daysWeek, day]);
    }
  };

  const daysInWeek = [
    i18nKeys.DATES.DAY_NAMES.MONDAY,
    i18nKeys.DATES.DAY_NAMES.TUESDAY,
    i18nKeys.DATES.DAY_NAMES.WEDNESDAY,
    i18nKeys.DATES.DAY_NAMES.THURSDAY,
    i18nKeys.DATES.DAY_NAMES.FRIDAY,
    i18nKeys.DATES.DAY_NAMES.SATURDAY,
    i18nKeys.DATES.DAY_NAMES.SUNDAY,
  ].map((day) => t(day));

  return (
    <Group align="start">
      <Box
        onClick={
          form.values.periodicity === 'monthly'
            ? () => {
                form.setFieldValue('periodicity', 'weekly');
                form.setFieldValue('daysMonth', []);
              }
            : () => {}
        }
      >
        <Radio
          readOnly
          styles={{ body: { alignItems: 'center' } }}
          checked={form.values.periodicity === 'weekly'}
          label={
            <Text fw={500} size="lg">
              {t(i18nKeys.REPORTS.NEW.STEPS.RECURRENCE.WEEKLY_TITLE)}
            </Text>
          }
        />
        <Box
          ml={34}
          style={{
            opacity: form.values.periodicity === 'monthly' ? 0.25 : 1,
            pointerEvents: form.values.periodicity === 'monthly' ? 'none' : 'auto',
          }}
        >
          {daysInWeek.map((day, index) => (
            <UnstyledButton
              key={day}
              onClick={() => handleToggleDay(index + 1)}
              className={styles.dayOfWeekCheckbox}
            >
              <Checkbox readOnly mr="md" checked={form.values.daysWeek.includes(index + 1)} />
              <Text>{day}</Text>
            </UnstyledButton>
          ))}
        </Box>
      </Box>
      <Divider orientation="vertical" mx="lg" />
      <Box
        onClick={
          form.values.periodicity === 'weekly'
            ? () => {
                form.setFieldValue('periodicity', 'monthly');
                form.setFieldValue('daysWeek', []);
              }
            : () => {}
        }
      >
        <Radio
          readOnly
          onClick={() => form.setFieldValue('periodicity', 'monthly')}
          styles={{ body: { alignItems: 'center' } }}
          checked={form.values.periodicity === 'monthly'}
          label={
            <Text fw={500} size="lg">
              {t(i18nKeys.REPORTS.NEW.STEPS.RECURRENCE.MONTHLY_TITLE)}
            </Text>
          }
        />
        <div style={{ opacity: form.values.periodicity === 'weekly' ? 0.25 : 1 }}>
          <Paper p="md" ml={34} mt="sm" bg="gray.0" shadow="none" w="fit-content">
            <DatePicker
              style={{
                pointerEvents: form.values.periodicity === 'weekly' ? 'none' : 'auto',
              }}
              value={form.values.daysMonth}
              className={styles.calendar}
              styles={{
                calendarHeader: { display: 'none' },
              }}
              // todo: Extract the reference date
              defaultDate={new Date('01-01-2024')}
              hideOutsideDates
              hideWeekdays
              type="multiple"
              onChange={(val) => form.setFieldValue('daysMonth', val)}
            />
          </Paper>
          <Text w={300} ml={34} mt="xs" c="dimmed" size="xs">
            {t(i18nKeys.REPORTS.NEW.STEPS.RECURRENCE.MONTHLY_END_OF_MONTH_DISCLAIMER)}
          </Text>
          <Text w={300} ml={34} mt="xs" c="dimmed" size="xs">
            {t(i18nKeys.REPORTS.NEW.STEPS.RECURRENCE.LAST_DAY_DISCLAIMER)}
          </Text>
        </div>
      </Box>
    </Group>
  );
};
