import { UpdateTasksStatusBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'shared/io';

import { ExternalMailActionDebtor } from '../ExternalMailActions/ExternalMailActionDebtor';

import styleIdentifiers from './ExternalMailBatchActions.module.scss';

const styles = classNames.bind(styleIdentifiers);

export type ExternalMailBatchActionsAssignFields = Pick<UpdateTasksStatusBody, 'user_id'>;

type ExternalMailBatchActionsAssignProps = {
  onSubmit: SubmitHandler<ExternalMailBatchActionsAssignFields>;
};

export const ExternalMailBatchActionsAssign = ({
  onSubmit,
}: ExternalMailBatchActionsAssignProps) => {
  const { t } = useTranslation();

  const form = useForm<ExternalMailBatchActionsAssignFields>({
    shouldUnregister: true,
  });

  const { handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <form
        className={styles('external-mail-batch-actions-form')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles('content')}>
          <ExternalMailActionDebtor />
        </div>
        <div className={styles('button-wrapper', 'buttons')}>
          <Button type="submit" label={t(i18nKeys.SAVE)} />
        </div>
      </form>
    </FormProvider>
  );
};
