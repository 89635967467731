import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useSearchInvoices } from 'api';
import { SearchInvoicesParams } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Button } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getSearchInvoicesUrlParams } from 'shared/utils/hooks';
import { clientActions } from 'store/client/client.actions';

import {
  CustomProductLine,
  InvoiceFormFieldValues,
  InvoiceFormSharedProps,
} from '../InvoiceForm.types';
import { getProductLine } from '../InvoiceForm.utils';

import InvoiceProductLine from './InvoiceProductLine/InvoiceProductLine';
import InvoiceProductLineInvoice from './InvoiceProductLine/InvoiceProductLineInvoice';

import styleIdentifiers from './invoiceLines.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InvoiceLinesProps = InvoiceFormSharedProps & {
  initialValues?: Partial<InvoiceFormFieldValues>;
};

export default function InvoiceLines(props: InvoiceLinesProps) {
  const { noTva, currency, isCreditNote, initialValues } = props;
  const { t } = useTranslation();

  const { control, watch, setValue } = useFormContext<InvoiceFormFieldValues>();

  const { append, remove, fields } = useFieldArray({
    control,
    shouldUnregister: true,
    name: 'product_lines_attributes',
  });

  const location = useLocation();
  const paramsQuery = getSearchInvoicesUrlParams(location);
  const debtor = watch('debtor_attributes');
  const productLines = watch('product_lines_attributes');

  const [params, setParams] = useState<SearchInvoicesParams>({
    currency,
  });

  const { data: invoicesResponse } = useSearchInvoices(params);

  const addProduct = useCallback(
    (data: Partial<CustomProductLine> = {}) =>
      append(getProductLine(noTva, { isInvoice: false, ...data })),
    [append, noTva],
  );

  const addInvoice = useCallback(
    (data: Partial<CustomProductLine> = {}) =>
      append(
        getProductLine(noTva, {
          isInvoice: true,
          ...data,
        }),
      ),
    [append, noTva],
  );

  useLayoutEffect(() => {
    if (Object.keys(paramsQuery).length) {
      setParams((currentParams) => ({
        ...currentParams,
        ...paramsQuery,
        reference: (paramsQuery?.reference || [])[0],
      }));
    }
  }, []);

  useEffect(() => {
    if (!fields.length && !isCreditNote) {
      addProduct();
    }
  }, [fields, isCreditNote, addProduct]);

  useEffect(() => {
    if (invoicesResponse) {
      const invoices = invoicesResponse.data.filter((e) =>
        (paramsQuery?.reference || []).includes(e.attributes.reference),
      );

      const debtorId = invoices.length ? invoices[0].attributes?.debtor?.id : undefined;

      if (debtorId) {
        clientActions.detail({
          id: Number(debtorId),
          callback: ({ data: dataDebtor }) => {
            const findedDebtor = treatClient(dataDebtor);

            if (!findedDebtor) {
              return;
            }

            setValue('debtor_attributes', findedDebtor);
          },
        });
      }

      invoices.forEach((e) => {
        if (e.attributes?.debtor?.id === debtorId) {
          addInvoice({
            linked_invoice_id: Number(e.id),
            total_htva: -e.attributes.total_htva,
            total_tvac: -e.attributes.total_tvac,
            product_attributes: {
              name: e.attributes.reference,
              tax_category_code: '00',
              current_unity_price_htva: -e.attributes.total_htva,
            },
          });
        }
      });
    }
  }, [invoicesResponse]);

  useEffect(() => {
    if (debtor && noTva) {
      productLines
        ?.filter((e) => !e.isInvoice)
        .forEach((product_line, index) => {
          setValue(`product_lines_attributes.${index}.product_attributes.tax_category_code`, '00');
        });
    }
  }, [debtor, noTva, productLines, setValue]);

  return (
    <div className={styles('InvoiceLines')}>
      <table>
        <thead>
          <tr>
            <td className={styles('name')}>{t(i18nKeys.INVOICE.PRODUCT)}</td>
            <td>{t(i18nKeys.INVOICE.QTE)}</td>
            <td className={styles('price')}>{t(i18nKeys.PRICE)}</td>
            <td>{t(i18nKeys.INVOICING.CREATE.DISCOUNT_PERCENTAGE)}</td>
            <td className={styles('amount')}>{t(i18nKeys.TOTAL_NOT_VAT_PRICE)}</td>
            <td>{t(i18nKeys.INVOICING.CREATE.VAT_PERCENTAGE)}</td>
            <td className={styles('amount')}>{t(i18nKeys.TOTAL_VAT_PRICE)}</td>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) =>
            field.isInvoice ? (
              <InvoiceProductLineInvoice
                {...props}
                key={index}
                lineIndex={index}
                remove={remove}
                selected={(productLines ?? [])
                  .filter((e) => e.isInvoice && e.linked_invoice_id)
                  .map((e) => Number(e.linked_invoice_id))}
              />
            ) : (
              <InvoiceProductLine
                {...props}
                initialValues={initialValues}
                key={index}
                field={field}
                removeProductLine={remove}
                lineIndex={index}
              />
            ),
          )}
        </tbody>
      </table>
      <div className={styles('button-wrapper')}>
        <Button
          noMargin
          label={t(i18nKeys.INVOICING.CREATE.ADD_PRODUCT)}
          onClick={() => addProduct()}
        />
        {isCreditNote && (
          <Button
            noMargin
            label={t(i18nKeys.ADD_INVOICE)}
            onClick={() =>
              addInvoice({
                product_attributes: {
                  name: '',
                  tax_category_code: '00',
                  current_unity_price_htva: 0,
                },
              })
            }
          />
        )}
      </div>
    </div>
  );
}
