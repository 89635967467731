import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import ColorLogoPartialForm from 'shared/forms/InvoiceSettingsForm/ColorLogoPartialForm/ColorLogoPartialForm';
import { Button } from 'shared/io';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './ColorLogoForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
};

export default function ColorLogoForm({ onSubmit }: Props) {
  const { t } = useTranslation();

  const form = useForm({ shouldUnregister: true });

  return (
    <FormProvider {...form}>
      <form className={styles('color-logo-collection-form')} onSubmit={form.handleSubmit(onSubmit)}>
        <ColorLogoPartialForm />
        <Button
          className={styles('button')}
          label={t(i18nKeys.ONBOARDING.NEXT_STEP)}
          type="submit"
        />
      </form>
    </FormProvider>
  );
}
