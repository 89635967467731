import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { initial, last } from 'lodash-es';
import { invert, isNullish, omit } from 'remeda';
import { AgedBalanceOverTime, useAgedBalanceColors } from 'shared/hooks';
import { useAgedBalanceAccentColors } from 'shared/hooks/utils';
import {
  useLocalizedCurrencyFormatter,
  useSafeLocalizedCompactCurrencyFormatter,
} from 'shared/utils/normalization';

import { Box, Checkbox, Divider, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ResponsiveLine } from '@nivo/line';
import { styled } from '@stitches/react';

const Dot = styled('div', {
  width: 12,
  height: 12,
  borderRadius: '50%',
});

interface AgedBalanceOverTimeChartProps {
  agedBalanceOverTime?: AgedBalanceOverTime;
}

export const AgedBalanceOverTimeChart = ({
  agedBalanceOverTime,
}: AgedBalanceOverTimeChartProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const colors = useAgedBalanceColors();
  const formatter = useLocalizedCurrencyFormatter();
  const compactFormatter = useSafeLocalizedCompactCurrencyFormatter();
  const form = useForm({
    initialValues: {
      '0': true,
      '1': true,
      '2': true,
      '3': true,
      '4': true,
    },
  });

  if (isNullish(agedBalanceOverTime)) return null;

  const lines = Object.entries(omit(colors, ['totalDue'])).map(([key, color]) => ({
    id: t(i18nKeys.ANALYTICS.AGED_BALANCE[key]),
    color,
    data: Object.entries(agedBalanceOverTime.data).map(([date, balance]) => ({
      x: date,
      y: balance.debits[key] - balance.credits[key],
      color,
    })),
  }));

  const linesOrdered = [last(lines)!, ...initial(lines)];
  const indicesToHide = Object.entries(form.values).reduce((acc, [index, flag]) => {
    if (!flag) return [...acc, Number(index)];
    return acc;
  }, [] as Array<number>);

  const linesFiltered = linesOrdered.filter((_, index) => !indicesToHide.includes(index));
  const isOnlyOneLineEnabled = linesFiltered.length === 1;

  return (
    <>
      <Box w="100%" h={460}>
        <ResponsiveLine
          yScale={{
            stacked: true,
            type: 'linear',
          }}
          colors={(d) => d.color}
          margin={{ top: 10, left: 85, bottom: 50, right: 50 }}
          data={linesFiltered}
          isInteractive
          enableSlices="x"
          enableGridX
          lineWidth={4}
          pointSymbol={CustomPointSymbol}
          yFormat={(val) => formatter.format(Number(val))}
          pointLabel={() => 'test'}
          axisBottom={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: -25,
            format: (date) => dayjs(date).format('DD MMM'),
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 20,
            format: (value) => compactFormatter.format(value),
          }}
          curve="monotoneX"
          enableArea
          areaOpacity={0.7}
          sliceTooltip={({ slice }) => {
            const total = slice.points[0].data.yStacked;

            return (
              <Box
                p="md"
                bg="white"
                style={{
                  borderRadius: 'var(--mantine-radius-xs)',
                  boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.05)',
                }}
              >
                <Group>
                  <Stack>
                    {slice.points.map((point) => (
                      <Group key={point.id}>
                        <Dot style={{ backgroundColor: point.serieColor }} />
                        <Text my="0">{point.serieId}</Text>
                      </Group>
                    ))}
                    <Divider my="xs" w="115%" />
                    <Group mt={0}>
                      <Dot style={{ opacity: 0 }} />
                      <Text my="0">Total</Text>
                    </Group>
                  </Stack>
                  <Stack>
                    {slice.points.map((point) => (
                      <Text my="0" fw="bold" key={point.id}>
                        {formatter.format(Number(point.data.y))}
                      </Text>
                    ))}
                    <Divider my="xs" />
                    <Text mt={0} fw="bold">
                      {formatter.format(Number(total))}
                    </Text>
                  </Stack>
                </Group>
              </Box>
            );
          }}
          theme={{
            grid: {
              line: {
                stroke: theme.colors.gray[3],
                strokeDasharray: 7,
                strokeDashoffset: 15,
              },
            },
            labels: {
              text: {
                fontSize: 15,
                fontWeight: 500,
                fontFamily: 'Work Sans',
                fill: theme.colors.gray[6],
              },
            },
            text: {
              fontSize: 14,
              fontFamily: 'Work Sans',
              fill: theme.colors.gray[6],
            },
          }}
        />
      </Box>
      <Group w="100%" mt="sm" mb="md" justify="center" gap="xl">
        {linesOrdered.map((line, index) => (
          <Checkbox
            size="xs"
            style={{ cursor: 'pointer' }}
            key={index}
            {...form.getInputProps(String(index), { type: 'checkbox' })}
            label={line.id}
            color={line.color}
            disabled={isOnlyOneLineEnabled && form.values[String(index)]}
          />
        ))}
      </Group>
    </>
  );
};

const CustomPointSymbol = ({ color }) => {
  const colors = useAgedBalanceColors();
  const accentColors = useAgedBalanceAccentColors();

  const colorsReversed = invert(colors);
  const keyForThisLine = colorsReversed[color];
  const accentColor = accentColors[keyForThisLine];

  return (
    <g>
      <circle r={7} fill={accentColor} />
      <circle r={5} strokeWidth={2} stroke="white" fill={color} />
    </g>
  );
};
