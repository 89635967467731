import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Input } from 'shared/io';
import { email } from 'shared/utils/validation';

import styleIdentifiers from './Emails.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  className?: string;
  formArray: any[];
  setFormDirty: Function;
};

export default function Emails({ className, formArray, setFormDirty }: Props) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    reset,
    getValues,
  } = useFormContext();

  const deleteLine = (index) => () => {
    const emails = formArray.slice();
    emails.splice(index, 1);
    if (emails.length === 0) {
      emails.push({ email: '' });
    }
    reset({
      ...getValues(),
      emails,
    });
    setFormDirty();
  };

  const mainEmailIndex = watch('mainEmailIndex');
  return (
    <div className={styles('emails')}>
      <div className={styles('description')}>{t(i18nKeys.FORM.CLIENT.SELECT_RADIO_BUTTON)}</div>
      {formArray?.map((item, index) => (
        <div className={styles('email-item')} key={index}>
          <div
            className={styles('radio-button-circle', mainEmailIndex == index && 'checked')}
            onClick={() => {
              setValue('mainEmailIndex', index, { shouldDirty: true });
            }}
          />
          <Input
            errorMessage={errors.emails && errors.emails[index]?.email}
            defaultValue={formArray[index]?.email}
            register={register(`emails.${index}.email`, {
              validate: { email },
            })}
            className={className}
            label={t(i18nKeys.EMAIL)}
            type="text"
            withBorder
          />
          <Icon name={IconName.TRASH_SIMPLE} onClick={deleteLine(index)} />
        </div>
      ))}
    </div>
  );
}
