import { AxiosInstance } from 'axios';
import { string, z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ExternalMailAttributesSchema } from './use-load-external-mail';
import { addResourceNameToQueryResult, ApiError, useLegacyAxiosInstance } from './utils';

const ExternalMailsSearchParamsSchema = z.object({
  page: z.coerce.number().optional(),
  page_limit: z.coerce.number().optional(),
  sort_by: string().optional(),
  sort_order: string().optional(),
  date_before: string().optional(),
  date_after: string().optional(),
  from_mail: string().optional(),
  to_mail: string().optional(),
  subject: string().optional(),
  status: string().optional(),
  body: string().optional(),
  debtor_id: string().optional(),
  external_mailbox_id: string().optional(),
  account_manager_id: string().optional(),
});

const ExternalMailMetadata = z.object({
  pagination: z.object({
    current_page: z.number(),
    last_page: z.number(),
    page_limit: z.number(),
    total_objects: z.number(),
  }),
  total: z.number(),
});

const LightExternalMailSchema = z.object({
  id: z.string(),
  attributes: ExternalMailAttributesSchema.omit({
    body: true,
    reply_mails: true,
    attachments: true,
  }),
});

const ExternalMailsSchema = z.object({
  data: z.array(LightExternalMailSchema),
  metadata: ExternalMailMetadata,
});

export type ExternalMails = z.infer<typeof ExternalMailsSchema>;
export type ExternalMailsSearchParams = z.infer<typeof ExternalMailsSearchParamsSchema>;

export async function loadExternalMailsFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/external_mails', { params: queryKey[1] });

  return ExternalMailsSchema.parse(data);
}

export function useLoadExternalMails(params?: ExternalMailsSearchParams) {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<ExternalMails, ApiError>({
    queryKey: ['externalMails', params] as const,
    queryFn: (context) => loadExternalMailsFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<ExternalMails, ApiError, 'externalMails'>(
    'externalMails',
    queryResult,
  );
}
