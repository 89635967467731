import { useState } from 'react';
import { useGetDebtorsInfinite } from 'api';
import { CreatePaymentStepCalculationBody, DebtorBasic, Id } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { flat, map, pipe } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';

import styleIdentifiers from './StepsCalculation.module.scss';

const styles = classNames.bind(styleIdentifiers);

type StepsCalculationDebtorProps = {
  name?: 'debtor_id';
  setCurrentDebtor: React.Dispatch<React.SetStateAction<DebtorBasic | undefined>>;
};

export const StepsCalculationDebtor = ({
  name = 'debtor_id',
  setCurrentDebtor,
}: StepsCalculationDebtorProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<CreatePaymentStepCalculationBody>();
  const [selectSearchValue, setSelectSearchValue] = useState<string>();
  const { data: debtorsResponse, fetchNextPage } = useGetDebtorsInfinite(
    { name: selectSearchValue },
    {
      query: {
        getNextPageParam,
      },
    },
  );

  const pages = debtorsResponse?.pages ?? [];

  const concatenatedDebtors = pipe(
    pages,
    map((e) => e.data),
    flat(),
  );

  return (
    <Controller
      name={name}
      rules={{ required: true }}
      render={() => (
        <CustomSelect
          selectClassName={styles('input')}
          filter
          withBorder
          noMargin
          infiniteScroll
          items={concatenatedDebtors.map(treatClient)}
          keyValue="id"
          keyText="full_name"
          onChange={(id) => {
            setCurrentDebtor(
              concatenatedDebtors.find((e: { id: Id }) => e.id === id) as DebtorBasic,
            );
            setValue('invoice_ids', []);
          }}
          onChangeFilter={setSelectSearchValue}
          name={name}
          label={t(i18nKeys.COMMON.CLIENT)}
          load={fetchNextPage}
        />
      )}
    />
  );
};
