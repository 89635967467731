import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const DsoSchema = z.record(z.coerce.string().datetime({ offset: true }), z.number().nullable());

export type Dso = z.infer<typeof DsoSchema>;

export async function loadDsoFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/analytics/dsos');
  return DsoSchema.parse(data);
}

export function useLoadDso() {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Dso, ApiError>({
    queryKey: ['dso'],
    queryFn: () => loadDsoFn(axiosInstance),
  });

  return addResourceNameToQueryResult<Dso, ApiError, 'dso'>('dso', queryResult);
}
