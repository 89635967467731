/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type AssignableModel = (typeof AssignableModel)[keyof typeof AssignableModel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignableModel = {
  invoice: 'invoice',
  debtor: 'debtor',
} as const;
