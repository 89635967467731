import { treatRecoveryPlan } from 'shared/serializer';
import { settingsActions } from 'store/settings/settings.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { RecoveryPlan } from 'types/recovery-plan';

import MoveRecoveryPlanInvoicesModal from './MoveRecoveryPlanInvoicesModal';

type Props = {
  onSubmit: Function;
  newPlan?: RecoveryPlan;
  oldPlan?: RecoveryPlan;
  newPlanId?: number;
  oldPlanId?: number;
  title: string;
  currentStep?: number;
  debtor_id?: number;
  allStepRequired?: boolean;
  companyDefault?: boolean;
};

export default function MoveRecoveryPlanInvoices({
  title,
  allStepRequired,
  newPlan,
  newPlanId,
  oldPlan,
  oldPlanId,
  onSubmit,
  currentStep,
  debtor_id,
  companyDefault,
}: Props) {
  const initialValues = {
    oldPlan,
    newPlan,
  };

  const showModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.FULL,
      title,
      children: (
        <MoveRecoveryPlanInvoicesModal
          allStepRequired={allStepRequired}
          currentStep={
            currentStep === -1 ? initialValues.oldPlan!.recovery_steps.length - 1 : currentStep
          }
          newPlan={initialValues.newPlan!}
          oldPlan={initialValues.oldPlan!}
          onSubmit={onSubmit}
        />
      ),
    });
  };

  if (initialValues.oldPlan && initialValues.newPlan) {
    showModal();
  } else {
    if (!initialValues.oldPlan) {
      settingsActions.getRecoveryPlan({
        id: oldPlanId,
        data: {
          debtor_id,
          companyDefault,
        },
        callback: ({ data }) => {
          initialValues.oldPlan = treatRecoveryPlan(data);
          if (initialValues.newPlan) {
            showModal();
          }
        },
      });
    }
    if (!initialValues.newPlan) {
      settingsActions.getRecoveryPlan({
        id: newPlanId,
        data: {
          companyDefault,
        },
        callback: ({ data }) => {
          initialValues.newPlan = treatRecoveryPlan(data);
          if (initialValues.oldPlan) {
            showModal();
          }
        },
      });
    }
  }
}
