import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BankAccount from 'shared/components/BankAccount';
import Card from 'shared/components/Card';
import { FinancialData } from 'shared/hooks/use-load-dashboard';
import { CustomSelect } from 'shared/io';
import { StoreState } from 'types/storeTypes';

import BankStatusAmount from './BankStatusAmount';

import styleIdentifiers from './bankStatus.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  data: FinancialData;
};

export default function BankStatus({ data }: Props) {
  const { t } = useTranslation();
  const company = useSelector((state: StoreState) => state.account.company.data!);

  const form = useForm({ shouldUnregister: true });

  const { saldo, saldoOneWeek, saldoOneMonth } = data;

  const balance = saldo[form.getValues().bank_accounts] ?? saldo.total;
  const { currency } = company;

  return (
    <Card title={t(i18nKeys.DASHBOARD.BANK_STATUS.TITLE)} className={styles('BankStatus')}>
      <div className={styles('status')}>
        {company.bank_accounts_attributes && (
          <div className={styles('item')}>
            {company.bank_accounts_attributes?.length > 1 ? (
              <FormProvider {...form}>
                <Controller
                  name="bank_accounts"
                  defaultValue=""
                  render={() => (
                    <CustomSelect
                      name="bank_accounts"
                      removeAll={t(i18nKeys.FORM.SELECT_ALL)}
                      valueClassName={styles('select-value')}
                      items={company.bank_accounts_attributes}
                      valueRendering={(value) => (
                        <span className={styles('label')}>
                          {value.value
                            ? t(i18nKeys.DASHBOARD.BANK_STATUS.ACCOUNT_BALANCE, {
                                text: value.text,
                              })
                            : t(i18nKeys.DASHBOARD.BANK_STATUS.ALL_ACCOUNTS_BALANCE)}
                        </span>
                      )}
                      itemRendering={({ item }) => (
                        <BankAccount value={item.iban} image={item.bic} small />
                      )}
                      keyText="iban"
                      keyValue="id"
                      noBorder
                      noMargin
                    />
                  )}
                />
              </FormProvider>
            ) : (
              <div className={styles('label')}>{t(i18nKeys.BALANCE)}</div>
            )}
            <div className={styles('value')}>
              <BankStatusAmount data={balance} currency={currency} />
            </div>
          </div>
        )}
        <div className={styles('item')}>
          <div className={styles('label')}>{t(i18nKeys.DASHBOARD.BANK_STATUS.WEEK_FORECAST)}</div>
          <div className={styles('value', 'color-grey')}>
            <BankStatusAmount data={saldoOneWeek} currency={currency} />
          </div>
        </div>
        <div className={styles('item')}>
          <div className={styles('label')}>{t(i18nKeys.DASHBOARD.BANK_STATUS.MONTH_FORECAST)}</div>
          <div className={styles('value', 'main-color')}>
            <BankStatusAmount data={saldoOneMonth} currency={currency} />
          </div>
        </div>
      </div>
    </Card>
  );
}
