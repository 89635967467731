import { useCreateContactPerson } from 'api';
import { CreateContactPersonBody, Id } from 'api/models';
import { ContactPersonForm } from 'app/Private/ContactPersons';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { Icon, IconName } from '../Icon';

import styleIdentifiers from './AddContactPersonButton.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  refetch: () => void;
  debtorId: Id;
  withMargin?: boolean;
};

export default function AddContactPersonButton({ refetch, debtorId, withMargin }: Props) {
  const { t } = useTranslation();

  const { mutate: createContactPersonMutation } = useCreateContactPerson();

  const addContactPerson = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.CONTACT_PERSON.ADD),
      children: (
        <ContactPersonForm
          inModal
          onSubmit={(cpData: CreateContactPersonBody) =>
            createContactPersonMutation(
              { id: debtorId, data: cpData },
              {
                onSuccess: () => {
                  refetch();
                  dialogHide(DialogShowId.CONFIRM);
                },
              },
            )
          }
        />
      ),
    });

  return (
    <div
      className={styles('add-contact-person', withMargin && 'with-margin')}
      onClick={addContactPerson}
    >
      <Icon name={IconName.PLUS} /> <p>{t(i18nKeys.ADD_CONTACT_PERSON)}</p>
    </div>
  );
}
