import { PlanType } from 'api/models';
import { RECOVER_ACTION_TYPE } from 'app/Private/Templates/EditDebtCollectionCustomization/EditDebtCollectionCustomization';
import { buildActions, makeReqCons } from 'store/actions';
import { ReduxFunction, ReduxFunctionData } from 'types/redux';

const prefix = 'settings';

export const settingsConstants = {
  editDebtCollection: makeReqCons(`${prefix}_edit_debt_collection`),
  addTwikey: makeReqCons(`${prefix}_add_twikey`),
  // template
  templateListPage: makeReqCons(`${prefix}_template_list_page`),
  templateList: makeReqCons(`${prefix}_template_list`),
  getTemplate: makeReqCons(`${prefix}_get_template`),
  addTemplate: makeReqCons(`${prefix}_add_template`),
  setTemplate: makeReqCons(`${prefix}_set_template`),
  deleteTemplate: makeReqCons(`${prefix}_delete_template`),
  duplicateTemplate: makeReqCons(`${prefix}_duplicate_template`),
  previewTemplate: makeReqCons(`${prefix}_preview_template`),

  // recovery plan
  recoveryPlanListPage: makeReqCons(`${prefix}_recovery_plan_list_page`),
  recoveryPlanList: makeReqCons(`${prefix}_recovery_plan_list`),
  getRecoveryPlan: makeReqCons(`${prefix}_get_recovery_plan`),
  addRecoveryPlan: makeReqCons(`${prefix}_add_recovery_plan`),
  setRecoveryPlan: makeReqCons(`${prefix}_set_recovery_plan`),
  deleteRecoveryPlan: makeReqCons(`${prefix}_delete_recovery_plan`),
  duplicateRecoveryPlan: makeReqCons(`${prefix}_duplicate_recovery-plan`),
  getPlansInvoices: makeReqCons(`${prefix}_get_plans_invoices`),
  switchPlansInvoices: makeReqCons(`${prefix}_switch_plan_invoices`),

  // custom variable
  customVariableListPage: makeReqCons(`${prefix}_custom_variable_list_page`),
  customVariableList: makeReqCons(`${prefix}_custom_variable_list`),
  getCustomVariable: makeReqCons(`${prefix}_get_custom_variable`),
  addCustomVariable: makeReqCons(`${prefix}_add_custom_variable`),
  setCustomVariable: makeReqCons(`${prefix}_set_custom_variable`),
  deleteCustomVariable: makeReqCons(`${prefix}_delete_custom_variable`),
};

type SettingsActions = {
  addTwikey: ReduxFunction;
  addTwikeyRes: Function;
  editDebtCollection: ReduxFunction;
  editDebtCollectionRes: Function;

  // template
  templateListPage: Function;
  templateListPageRes: Function;
  templateList: ReduxFunction;
  templateListRes: Function;
  addTemplate: ReduxFunctionData<{
    name: string;
    step_type: RECOVER_ACTION_TYPE;
    with_fees?: boolean;
    plan_type: PlanType;
  }>;
  addTemplateRes: Function;
  setTemplate: ReduxFunction;
  setTemplateRes: Function;
  getTemplate: ReduxFunction;
  getTemplateRes: Function;
  deleteTemplate: ReduxFunction;
  deleteTemplateRes: Function;
  duplicateTemplate: ReduxFunction;
  duplicateTemplateRes: Function;
  previewTemplate: Function;
  previewTemplateRes: Function;

  recoveryPlanListPage: ReduxFunction;
  recoveryPlanListPageRes: Function;
  recoveryPlanList: ReduxFunction;
  recoveryPlanListRes: Function;
  addRecoveryPlan: ReduxFunction;
  addRecoveryPlanRes: Function;
  setRecoveryPlan: ReduxFunction;
  setRecoveryPlanRes: Function;
  getRecoveryPlan: ReduxFunction;
  getRecoveryPlanRes: Function;
  deleteRecoveryPlan: ReduxFunction;
  deleteRecoveryPlanRes: Function;
  duplicateRecoveryPlan: ReduxFunction;
  duplicateRecoveryPlanRes: Function;
  switchPlansInvoices: ReduxFunction;
  switchPlansInvoicesRes: Function;
  getPlansInvoices: ReduxFunction;
  getPlansInvoicesRes: Function;

  customVariableListPage: ReduxFunction;
  customVariableListPageRes: Function;
  customVariableList: ReduxFunction;
  customVariableListRes: Function;
  getCustomVariable: ReduxFunction;
  getCustomVariableRes: Function;
  addCustomVariable: ReduxFunctionData<{
    name: string;
    var_type: string;
    model_type: string;
    display?: string;
    api: boolean;
    webhook: boolean;
    default_value: string;
  }>;
  addCustomVariableRes: Function;
  setCustomVariable: ReduxFunction;
  setCustomVariableRes: Function;
  deleteCustomVariable: ReduxFunction;
  deleteCustomVariableRes: Function;
};

const settingsActions = buildActions<SettingsActions>('', settingsConstants, {});

export { settingsActions };
