import { AxiosInstance, AxiosResponse } from 'axios';
import { i18nKeys, useTranslation } from 'locales';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, useAxiosInstance } from './utils';

type SuccessResponse = AxiosResponse<{ notifiedEmail: string }>;

export async function refreshBalanceFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;
  return instance.post('analytics/aged-balance/refresh');
}

export function useRefreshBalance() {
  const { t } = useTranslation();
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<SuccessResponse, ApiError>({
    mutationFn: () => refreshBalanceFn(axiosInstance),
    onSuccess: (response) => {
      setTimeout(
        () => {
          client.refetchQueries({ queryKey: ['aged-balance'] });
        },
        5 * 60 * 1000,
      );
      notifications.show({
        message: t(i18nKeys.ANALYTICS.REFRESH_BALANCE_SUCCESS, {
          email: response.data.notifiedEmail,
        }),
        color: 'blue',
      });
    },
    onError: () => {
      notifications.show({
        message: t(i18nKeys.ANALYTICS.REFRESH_BALANCE_ERROR),
        color: 'blue',
      });
    },
  });

  return addActionNameToMutationResult<SuccessResponse, ApiError, void, 'refreshBalance'>(
    'refreshBalance',
    mutation,
  );
}
