import { useState } from 'react';
import { PaymentPlan } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Icon, IconName } from 'shared/components/Icon';
import { TableItemProps } from 'types/table';

import { computeNextStepDate, computeStatus } from '../PaymentPlan.utils';

import styleIdentifiers from './PaymentPlansListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type EnhancedTablePaymentPlan = PaymentPlan;

type PaymentPlanListItemProps = TableItemProps<EnhancedTablePaymentPlan>;

export const PaymentPlanListItem = ({ item }: PaymentPlanListItemProps) => {
  const { t, currentLang } = useTranslation();
  const history = useHistory();

  const [hover, setHover] = useState(false);

  const {
    id,
    attributes: {
      cancelled,
      completed,
      debtor,
      invoices_count,
      current_step,
      payment_steps_attributes,
    },
  } = item;

  const updatePaymentPlan = (e) => {
    e.stopPropagation();
    history.push(`/payment-plan/${id}/edit`);
  };

  const goToDetails = () => {
    history.push(`/payment-plan/${id}`);
  };

  const currentStep = payment_steps_attributes.find((step) => step.id === current_step?.toString());

  const translationKey = invoices_count! > 1 ? i18nKeys.INVOICES : i18nKeys.COMMON.INVOICE;

  const STATUS = computeStatus(cancelled, completed);

  return (
    <tr
      className={styles('payment-plan-list-item', hover && 'hover')}
      onMouseEnter={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={id}
      onClick={goToDetails}
    >
      <td />
      <td>{id}</td>
      <td className={styles('debtor')}>
        <NavLink onClick={(e) => e.stopPropagation()} to={`/clients/${debtor.id}`}>
          {debtor.attributes.full_name}
        </NavLink>
      </td>
      <td>{`${invoices_count} ${t(translationKey).toLowerCase()}`}</td>
      <td>{t(i18nKeys[STATUS])}</td>
      <td>{computeNextStepDate(STATUS, currentLang, currentStep)}</td>
      <td className={styles('actions')}>
        {!cancelled && !completed && (
          <span onClick={updatePaymentPlan}>
            <Icon name={IconName.SETTINGS_GEAR} size="18px" className={styles('action')} />
          </span>
        )}
      </td>
    </tr>
  );
};
