import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import Cropper from 'shared/components/Cropper';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import Percentage from 'shared/components/Percentage';
import { Button } from 'shared/io';
import { validationMessage } from 'shared/utils/validation';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  error as notifyError,
  showDialog,
} from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';

import cameraIcon from './assets/photo-camera.svg';

import styleIdentifiers from './UploadZone.module.scss';

const styles = classNames.bind(styleIdentifiers);

type UploadZoneProps = {
  progress?: any;
  accept: string;
  info?: string;
  subinfo?: string;
  className?: string;
  icon?: IconName;
  profile?: boolean;
  small?: boolean;
  img?: boolean;
  roundedPreview?: boolean;
  noMargin?: boolean;
  pdf?: boolean;
  showError?: boolean;
  multiple?: boolean;
  noResize?: boolean;
  noPreview?: boolean;
  logo?: boolean;
  action?: OnClickFunction;
  errorMessage?: string;
  onChange?: Function;
  onRemove?: Function;
  value?: any;
  error?: FieldError | string;
  inert?: boolean;
};

export const UploadZone = ({
  accept,
  info,
  subinfo,
  className,
  profile,
  small,
  img,
  roundedPreview,
  progress,
  onRemove,
  noMargin,
  showError,
  value,
  pdf,
  multiple,
  noResize,
  noPreview,
  errorMessage,
  onChange,
  action,
  icon = IconName.CLOUD_DOWNLOAD,
  error,
  logo,
  inert = false,
}: UploadZoneProps) => {
  const { t } = useTranslation();

  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if (img && value) setUrl(value);
  }, [value, img]);

  const onDropAccepted = (acceptedFiles) => {
    if (multiple) {
      onChange!(acceptedFiles);
    } else {
      const file = acceptedFiles[0];
      setUrl('');

      if (img) {
        // read file
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsBinaryString = reader.result as string;
          if (!noResize) {
            resizeImage(fileAsBinaryString);
          } else {
            setUrl(fileAsBinaryString);
          }
        };
        reader.readAsDataURL(file);
      } else if (pdf) {
        setUrl(URL.createObjectURL(file));
      }
      onChange?.(file);
    }
  };

  const onDropRejected = () => {
    notifyError({
      data: [errorMessage || t(i18nKeys.ERROR.CHOOSE_FILE_NOT_ACCEPTED)],
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    multiple,
  });

  const setCropped = (_url: string) => {
    setUrl(_url);
    dialogHide(DialogShowId.CUSTOM);
  };

  const removeFile = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    onChange!(null);
    setUrl('');
    onRemove?.();
  };

  const resizeImage = (_url: string) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      keepMountOnExit: true,
      children: <Cropper aspectRatio={2.6} src={_url} action={setCropped} options={{}} />,
    });
  };

  const file = value;

  return (
    <div
      className={styles(
        'UploadZone',
        className,
        noMargin && 'no-margin',
        error && showError && 'error',
        profile && 'profile',
        logo && 'logo',
        roundedPreview && 'rounded',
        isDragActive && 'document-hover',
      )}
    >
      {!noPreview && url ? (
        img ? (
          <div className={styles('preview-wrapper', logo && 'logo')}>
            <div className={styles('preview')}>
              <img src={url} alt="" />
              <div className={styles('delete', 'absolute-fill')} onClick={removeFile}>
                <div className={styles('button')}>
                  <Icon name={IconName.TRASH_SIMPLE} color={IconColor.WHITE} size="16px" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles('preview-wrapper')}>
            <div className={styles('preview')}>
              <Document file={url}>
                <Page
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  width={660}
                  pageNumber={1}
                />
              </Document>
              <div className={styles('delete')} onClick={removeFile}>
                <div className={styles('button')}>
                  <Icon name={IconName.TRASH_SIMPLE} color={IconColor.WHITE} size="16px" />
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          <div
            {...getRootProps({
              className: styles(
                'Dropzone',
                error && showError && 'error',
                small && 'small',
                profile && 'drop-profile',
              ),
            })}
          >
            {inert ? null : <input {...getInputProps({ className: styles('dropdown-input') })} />}
            {profile ? (
              <img src={cameraIcon} alt="icon" className={styles('camera')} />
            ) : (
              <div className={styles('content')}>
                <Icon name={icon} className={styles('icon')} size="34px" />
                {file && noPreview ? (
                  <div className={styles('info', 'with-preview')}>
                    {!multiple && <span>{file.name}</span>}
                    {multiple && (
                      <span>
                        {t(i18nKeys.FILE_NUMBER)} {file.length}
                      </span>
                    )}

                    <div onClick={removeFile} className={styles('button-delete')}>
                      <Icon name={IconName.SIMPLE_REMOVE} />
                    </div>

                    {action && (
                      <Button
                        className={styles('import-button')}
                        label={t(i18nKeys.NEXT)}
                        onClick={action}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <div className={styles('info', small && 'small')}>
                      {info || t(i18nKeys.FORM.CHOOSE_FILE)}
                    </div>
                    {subinfo && (
                      <div className={styles('subinfo', small && 'small')}>{subinfo}</div>
                    )}
                    {error && showError && (
                      <div className={styles('error-message')}>
                        {t(
                          validationMessage[(error as FieldError).type] ||
                            (error as FieldError).message ||
                            error,
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          {file && progress?.active && (
            <div className={styles('progress-wrapper')}>
              <div
                className={styles('progress-content', 'value')}
                style={{ width: `${progress.value}%` }}
              >
                <Percentage value={progress.value} />
              </div>
            </div>
          )}
          {file && progress?.errors && (
            <div className={styles('progress-errors')}>
              <div className={styles('title-error')}>{t(i18nKeys.ERROR.IMPORT_ERROR)}</div>
              {progress.errors.map((item, index) => (
                <div key={index} className={styles('error')}>
                  <HTML html={item} />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
