import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getCreditNoteFormValues, InvoiceForm } from 'shared/forms/InvoiceForm';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { StoreState } from 'types/storeTypes';

export default function ManageCreditNote({
  match: { params },
  history,
}: RouteComponentProps<{ id?: string }>) {
  const creditNote = useSelector(
    (state: StoreState) => state.creditNote.current?.data || undefined,
  );

  useEffect(() => {
    if (params.id) {
      creditNoteActions.detail({
        id: Number(params.id),
      });
    }
  }, [params]);

  const callback = ({ data: { id } }) =>
    history.push(id ? `/credit-notes/detail/${id}` : '/credit-notes/listing');

  if (!params.id || !creditNote) {
    return (
      <InvoiceForm
        initialValues={getCreditNoteFormValues()}
        creditNote={creditNote}
        isCreditNote
        submit={(data) =>
          creditNoteActions.add({
            data,
            callback,
          })
        }
      />
    );
  }

  return (
    <InvoiceForm
      initialValues={getCreditNoteFormValues(creditNote)}
      creditNote={creditNote}
      isCreditNote
      submit={(data) =>
        creditNoteActions.update({
          data: {
            id: creditNote.id,
            ...data,
          },
          callback,
        })
      }
    />
  );
}
