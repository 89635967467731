import { removeAttributes } from 'shared/utils/view';
import { colleagueConstants as events } from 'store/colleague/colleague.actions';
import { baseReducerListPage, handleAddResponse, handleDeleteResponse } from 'store/reducers';
import { ReduxAction } from 'types/redux';
import { Colleague, ColleagueState } from 'types/store/colleague-state';

export const initialState: ColleagueState = {
  list: baseReducerListPage,
};

export const treatColleague = (item: Object): Colleague => {
  const newItem = removeAttributes(item);
  newItem.roles.forEach((it) => {
    newItem[it] = true;
  });

  return newItem;
};

const reducer = (state: ColleagueState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case events.add.result:
      return handleAddResponse(state, action, 'list', treatColleague);
    // delete
    case events.delete.result:
      return handleDeleteResponse(state, action, 'list');
    default:
      return state;
  }
};

export default reducer;
