import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';

import styleIdentifiers from './tpCaseStatusItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  className?: string;
  status: 'open' | 'close' | 'notOpen';
};

export default function TpCaseStatusItem({ className, status }: Props) {
  const { t } = useTranslation();

  const availableStatus = {
    notOpen: {
      step: 1,
      text: t(i18nKeys.THIRD_PARTY_CASES.NOT_STARTED),
    },
    open: {
      step: 2,
      text: t(i18nKeys.THIRD_PARTY_CASES.STARTED),
    },
    close: {
      step: 3,
      text: t(i18nKeys.THIRD_PARTY_CASES.CLOSED),
    },
  };
  const { step } = availableStatus[status];

  return (
    <div className={styles('tp-case-status-item', `step-${step + 1}`, className)}>
      <div className={styles('label')}>{availableStatus[status].text}</div>
      <div className={styles('indicator')}>
        {new Array(3).fill(0).map((it, key) => (
          <span key={key} className={styles('indic', key <= step - 1 && 'selected')} />
        ))}
      </div>
    </div>
  );
}
