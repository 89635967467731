import { PlanType, Workflow } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';

import styleIdentifiers from './WorkflowsList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowsListItemProps = {
  planType: PlanType;
  workflow: Workflow;
};

export const WorkflowsListItem = ({ planType, workflow }: WorkflowsListItemProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    id,
    attributes: { name, steps_count, payment_plans_using, default: isDefault },
  } = workflow;

  const updateWorkflow = () => {
    history.push(`/settings/workflows/${planType}/edit/${id}`);
  };

  return (
    <div className={styles('workflows-list-item', 'card')} onClick={() => updateWorkflow()}>
      <div className={styles('title')}>
        <h4 title={name}>{name}</h4>
        {isDefault && <div className={styles('label', 'badge')}>{t(i18nKeys.DEFAULT)}</div>}
      </div>
      <div className={styles('content')}>
        <div>{t(i18nKeys.N_STEP, { count: steps_count })} </div>
        {payment_plans_using === 0 ? (
          <div> {t(i18nKeys.UNUSED)}</div>
        ) : (
          <div>
            {payment_plans_using}{' '}
            <Icon title={t(i18nKeys.PLAN.INVOICES_NUMBER_INCLUDED)} name={IconName.INVOICE} />{' '}
          </div>
        )}
      </div>
    </div>
  );
};
