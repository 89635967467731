import { useFormContext } from 'react-hook-form';

import { InvoiceFormFieldValues } from './InvoiceForm.types';

export const useInvoiceFormTotals = () => {
  const { watch } = useFormContext<InvoiceFormFieldValues>();

  const productLines = watch('product_lines_attributes');

  let totalHtva = 0;
  let totalHtvaNoDiscount = 0;
  let totalTvac = 0;
  let totalVat = 0;

  if (productLines == null) {
    return {
      totalTvac,
      totalHtva,
      totalVat,
      totalDiscount: totalHtvaNoDiscount - totalHtva,
    };
  }

  productLines.forEach((item) => {
    if (!item) return;

    const quantity = item.quantity || 0;
    const product = item.product_attributes || {};
    const price = product.current_unity_price_htva || 0;

    totalHtvaNoDiscount += price * quantity;
    totalHtva += item.total_htva;
    totalTvac += item.total_tvac;
    totalVat += item.total_tvac - item.total_htva;
  });

  return {
    totalTvac,
    totalHtva,
    totalVat,
    totalDiscount: totalHtvaNoDiscount - totalHtva,
  };
};
