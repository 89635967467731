import { DialogShowId, viewActionTypes as events } from 'store/view/view.actions';
import { ViewState } from 'types/store/view-state';
import { APIReturn } from 'types/storeTypes';

export const initialState: ViewState = {
  dialogs: {
    count: 0,
    [DialogShowId.CONFIRM]: {
      active: false,
    },
    [DialogShowId.CUSTOM]: {
      active: false,
    },
    [DialogShowId.INVOICE]: {
      active: false,
    },
  },
  sideMenu: {
    active: false,
  },
};

const reducer = (state = initialState, action: APIReturn) => {
  let item;

  switch (action.type) {
    case events.dialog.show:
      item = action.payload;
      item.active = true;
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          count: state.dialogs.count + 1,
          [item.id]: item,
        },
      };
    case events.dialog.hide:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          count: state.dialogs.count - 1,
          [action.payload]: { ...state.dialogs[action.payload], active: false },
        },
      };
    case events.sideMenu.show:
      return {
        ...state,
        sideMenu: {
          ...action.payload,
          active: true,
        },
      };
    case events.sideMenu.setAskBeforeClose:
      return {
        ...state,
        sideMenu: {
          ...state.sideMenu,
          askBeforeClose: action.payload,
        },
      };
    case events.sideMenu.hide:
      return {
        ...state,
        sideMenu: { ...state.sideMenu, active: false },
      };
    default:
      return state;
  }
};

export default reducer;
