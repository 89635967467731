import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { ReconciliationInvoice, ReconciliationPayment } from 'types/store/transaction-state';

import styleIdentifiers from './reconciliationItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  item: ReconciliationInvoice | ReconciliationPayment;
  disabled?: boolean;
  overrideSelection?: boolean;
  action: Function;
};

export default function ReconciliationItem({
  item,
  disabled = false,
  overrideSelection = false,
  action,
}: Props) {
  const { t, currentLang } = useTranslation();
  const handleClick = () => {
    if (action) action(item);
  };

  return (
    <tr
      className={styles(
        'ReconciliationItem',
        (overrideSelection || item.checked) && 'checked',
        (item as ReconciliationInvoice).suggested_invoice && 'suggested',
        disabled && 'disabled',
      )}
      key={item.id}
    >
      <td className={styles('date')} onClick={handleClick}>
        <DateItem
          lg={currentLang}
          date={
            item.type === 'invoice'
              ? (item as ReconciliationInvoice).issue_date
              : (item as ReconciliationPayment).value_date
          }
        />
        {(item as ReconciliationInvoice).suggested_invoice && (
          <div className="label">{t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.SUGGESTION)}</div>
        )}
      </td>
      <td className={styles('details')} onClick={handleClick}>
        <div className={styles('details-name')}>
          {item.type === 'invoice'
            ? (item as ReconciliationInvoice).debtor_name
            : (item as ReconciliationPayment).counterparty_name}
        </div>
        {item.type === 'invoice' && (
          <div className={styles('details-com')}>{(item as ReconciliationInvoice).reference}</div>
        )}
        <div className={styles('details-com')}>
          <span>Com: </span>
          {item.type === 'invoice'
            ? (item as ReconciliationInvoice).structured_communication
            : (item as ReconciliationPayment).communication}
        </div>
      </td>
      <td className={styles('amount')} onClick={handleClick}>
        {(item as ReconciliationPayment).amount && (
          <div>
            <Amount
              localizedValue={(item as ReconciliationPayment).amount}
              value={item.localized_money_object.amount}
              suffix={item.currency}
              direction={DropdownDirection.BOTTOM}
            />
          </div>
        )}
        {item.type === 'invoice' ? (
          <div>
            <Amount
              localizedValue={item.remaining_balance}
              value={item.localized_money_object.remaining_balance}
              suffix={item.currency}
              direction={DropdownDirection.BOTTOM}
            />
          </div>
        ) : (
          item.remaining_balance !== (item as ReconciliationPayment).amount && (
            <div className={styles(item.type !== 'invoice' && 'remaining-balance-payment')}>
              {t(i18nKeys.BALANCE)}:{' '}
              <Amount
                localizedValue={item.remaining_balance}
                value={item.localized_money_object.remaining_balance}
                suffix={item.currency}
                direction={DropdownDirection.BOTTOM}
              />
            </div>
          )
        )}

        {(item as ReconciliationInvoice).remaining_late_fees > 0 && (
          <div className={styles('late-fees')}>
            + {t(i18nKeys.LATE_FEES)}:{' '}
            <Amount
              localizedValue={(item as ReconciliationInvoice).remaining_late_fees}
              value={(item as ReconciliationInvoice).localized_money_object.remaining_late_fees}
              suffix={item.currency}
              direction={DropdownDirection.BOTTOM}
            />
          </div>
        )}
      </td>
    </tr>
  );
}
