import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, UploadZone } from 'shared/io';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Invoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './ImportOriginalInvoice.module.scss';

const styles = classNames.bind(styleIdentifiers);

const Viewer = ({ url }) => {
  const [pdfPagesAmount, setPdfPagesAmount] = useState(0);

  return (
    <Document
      file={url}
      onLoadSuccess={(args) => {
        setPdfPagesAmount(args._pdfInfo.numPages);
      }}
    >
      {Array(pdfPagesAmount)
        .fill(null)
        .map((_, index) => (
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            key={index}
            className={styles('pdf-pages')}
            width={1100}
            pageIndex={index}
          />
        ))}
    </Document>
  );
};

type Props = {
  onEditOriginalFile: Function;
  invoice: Invoice;
};

export default function ImportOriginalInvoice({ onEditOriginalFile, invoice }: Props) {
  const { t } = useTranslation();
  const isLoadingInvoice = useSelector((state: StoreState) => state.invoice.isLoading);
  const isLoadingCreditNote = useSelector((state: StoreState) => state.creditNote.isLoading);
  const [editMode, _setEditMode] = useState(!invoice.has_pdf);
  const [pdfFile, setPdfFile] = useState();
  const [error, setError] = useState('');
  const onAddFile = (file) => {
    setError('');
    setPdfFile(file);
  };

  const downloadPdf = () => {
    invoiceActions.document({
      id: invoice!.id,
      type: 'invoice_pdf',
    });
  };

  const viewPdf = () => {
    invoiceActions.document({
      id: invoice!.id,
      type: 'invoice_pdf',
      callback: (data) => {
        const url = URL.createObjectURL(data);
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.LARGE,
          title: invoice!.reference!,
          children: <Viewer url={url} />,
        });
      },
    });
  };

  const setEditMode = (_editMode: boolean) => () => {
    _setEditMode(_editMode);
  };

  const submit = () => {
    if (pdfFile) {
      (invoice.type === 'credit_note' ? creditNoteActions : invoiceActions).updateOriginalFile({
        id: invoice.id,
        data: {
          file: pdfFile,
        },
        callback: () => {
          onEditOriginalFile?.();
        },
      });
    } else {
      setError('ERROR.FIELD_REQUIRED');
    }
  };
  return (
    <form className={styles('import-original-invoice')}>
      {editMode ? (
        <div>
          <div className={styles('content')}>
            <UploadZone
              noMargin
              accept=".pdf"
              info={t(i18nKeys.INVOICING.IMPORT.ORIGINAL_FILE)}
              subinfo={t(i18nKeys.FORM.FILE_FORMAT_PDF)}
              noPreview
              onChange={onAddFile}
              value={pdfFile}
              showError
              error={error}
            />
          </div>
          <div className={styles('button-wrapper')}>
            {invoice.has_pdf && (
              <Button
                color={ButtonColor.GREY}
                iconLeft={IconName.MINIMAL_LEFT}
                label={t(i18nKeys.BACK)}
                onClick={setEditMode(false)}
              />
            )}
            <Button
              label={t(i18nKeys.IMPORT)}
              onClick={submit}
              isLoading={isLoadingCreditNote || isLoadingInvoice}
            />
          </div>
        </div>
      ) : (
        <div className={styles('button-container')}>
          <Button
            color={ButtonColor.GREEN}
            iconLeft={IconName.PDF}
            onClick={downloadPdf}
            label={t(i18nKeys.INVOICE.DOWNLOAD_PDF)}
          />
          <Button
            color={ButtonColor.BLUE}
            iconLeft={IconName.EYE}
            onClick={viewPdf}
            label={t(i18nKeys.INVOICE.VIEW_PDF)}
          />
          {invoice.actions.indexOf('update_pdf') !== -1 && (
            <Button onClick={setEditMode(true)} label={t(i18nKeys.INVOICE.EDIT_ORGINAL_FILE)} />
          )}
        </div>
      )}
    </form>
  );
}
