import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Input } from 'shared/io';
import { formatBankAccount } from 'shared/utils/normalization';

import styleIdentifiers from './BankAccounts.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  formArray: any[];
  className: string;
  setFormDirty: Function;
};

export default function BankAccounts({ formArray, className, setFormDirty }: Props) {
  const { register, setValue, reset, getValues } = useFormContext();

  const deleteLine = (index) => () => {
    const bank_accounts = formArray.slice();
    bank_accounts.splice(index, 1);
    if (bank_accounts.length === 0) {
      bank_accounts.push({ iban: '', bic: '' });
    }
    reset({
      ...getValues(),
      debtor_bank_accounts_attributes: bank_accounts,
    });
    setFormDirty();
  };
  const onSetBankAccount = (name) => (value) => {
    setValue(name, formatBankAccount(value.target.value));
  };

  return (
    <div className={styles('BankAccounts')}>
      {formArray?.map((item, index) => (
        <div className={styles('field-wrapper')} key={index}>
          <div>
            <Input
              register={register(`debtor_bank_accounts_attributes.${index}.iban`)}
              defaultValue={formArray[index]?.iban}
              className={className}
              label="IBAN"
              type="text"
              withBorder
              onChange={onSetBankAccount(`debtor_bank_accounts_attributes.${index}.iban`)}
            />
          </div>
          <div>
            <Input
              register={register(`debtor_bank_accounts_attributes.${index}.bic`)}
              defaultValue={formArray[index]?.bic}
              className={className}
              label="BIC"
              type="text"
              withBorder
            />
          </div>
          <Icon name={IconName.TRASH_SIMPLE} onClick={deleteLine(index)} />
        </div>
      ))}
    </div>
  );
}
