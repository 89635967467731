import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { isDefined } from 'remeda';
import AYSModal from 'shared/components/AYSModal';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import ListResModal from 'shared/components/DeleteModal';
import { IconName } from 'shared/components/Icon';
import { Button, Dropdown } from 'shared/io';
import { isConnectorActive } from 'shared/utils/connector';
import { queryParamParser, reloadCustomView } from 'shared/utils/view';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { DialogShowId, DialogShowSize, showDialog, sideMenuHide } from 'store/view/view.actions';
import { Filters } from 'types/genericType';
import { Invoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import { ExportCreditNoteCsv } from './ExportCreditNoteCsv';

import styleIdentifiers from './CreditNoteListing.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = RouteComponentProps;
type DebtorFilter = {
  debtor_name: number;
  total_tvac_operator: string;
  total_tvac: number;
  remaining_balance: number;
  remaining_balance_operator: string;
  debtor: any;
};

export default function CreditNoteListing({ history }: Props) {
  const creditNoteData = useSelector((state: StoreState) => state.creditNote.list);
  const { t } = useTranslation();
  const [selectedCreditNotes, setSelectedCreditNotes] = useState<Invoice[]>([]);
  const [areAllItemsAcrossPagesSelected, setAreAllItemsAcrossPagesSelected] = useState(false);

  const hasActiveConnector = isConnectorActive();

  const handleFilters = (filters: Filters<DebtorFilter>) => {
    if (filters.debtor) {
      filters.debtor = undefined;
    }
    const newFilters: Filters<DebtorFilter> = {
      ...filters,
    };

    // need to be linked
    if (
      (newFilters.total_tvac_operator && !newFilters.total_tvac) ||
      (!newFilters.total_tvac_operator && newFilters.total_tvac)
    )
      return false;

    // need to be linked
    if (
      (newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
      (!newFilters.remaining_balance_operator && newFilters.remaining_balance)
    )
      return false;

    return newFilters;
  };

  const newCreditNote = () => {
    creditNoteActions.detailReset();

    history.push('/credit-notes/create');
  };

  if (!isDefined(creditNoteData)) return null;

  const exportCsv = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.EXPORT_CSV),
      children: <ExportCreditNoteCsv />,
    });
  };

  const batchAction = (action_type, action) => () => {
    sideMenuHide();
    const data = {} as any;
    if (areAllItemsAcrossPagesSelected) {
      data.filter_params = queryParamParser(qs.parse(location.search));
      data.view_id = location.hash.slice(1);
    } else {
      data.credit_notes_ids = selectedCreditNotes.map((invoice) => invoice.id);
    }
    action((values) => {
      creditNoteActions.batchAction({
        data: {
          ...data,
          ...values,
          action_type,
        },
        callback: (data) => {
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.RESULT),
            children: <ListResModal data={data} />,
          });
          reloadCustomView(creditNoteActions.listPageRes);
        },
      });
    });
  };

  const aysModal = (action_type) => (onSubmit) => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(
            selectedCreditNotes.length === 1 && !areAllItemsAcrossPagesSelected
              ? i18nKeys.AYS[`CN_ACTION_${action_type.toUpperCase()}`]
              : i18nKeys.AYS[`CNS_ACTION_${action_type.toUpperCase()}`],
          )}
          onConfirm={() => onSubmit({})}
        />
      ),
    });
  };

  const showCreditNote = (item) => () => {
    history.push(`/credit-notes/${item.id}`);
  };

  return (
    <div className={styles('Listing')}>
      <CustomTableViews
        title={t(i18nKeys.INVOICING.CREDIT_NOTE_BOOK)}
        tableName="credit_notes"
        onClickRow={showCreditNote}
        actions={
          selectedCreditNotes.length > 0 ? (
            <Dropdown
              sideMenuInMobile
              sideMenuTitle={t(i18nKeys.FORM.ACTIONS)}
              selectorContent={
                <Button
                  noMargin
                  iconRight={IconName.ARROW_BOTTOM_ROUNDED}
                  label={t(i18nKeys.FORM.ACTIONS)}
                />
              }
            >
              <div
                className={styles('dropdown-item')}
                onClick={batchAction('settle', aysModal('settle'))}
              >
                {t(i18nKeys.INVOICE.SETTLE)}
              </div>
              <div
                className={styles('dropdown-item')}
                onClick={batchAction('delete', aysModal('delete'))}
              >
                {t(i18nKeys.DELETE)}
              </div>
            </Dropdown>
          ) : (
            <>
              <Button
                noMargin
                label={t(i18nKeys.EXPORT_CSV)}
                onClick={exportCsv}
                className={styles('action-button')}
              />
              {!hasActiveConnector && (
                <Button
                  noMargin
                  className={styles('action-button')}
                  label={t(i18nKeys.INVOICING.ADD_CREDIT_NOTE)}
                  onClick={newCreditNote}
                  iconRight={IconName.MINIMAL_RIGHT}
                />
              )}
            </>
          )
        }
        isLoading={!creditNoteData.loaded}
        pagination={creditNoteData.metadata?.pagination}
        handleFilters={handleFilters}
        items={creditNoteData.pages || []}
        currentPage={creditNoteData.currentPage}
        onChangeSelectionCallback={(values) => setSelectedCreditNotes(values as Array<Invoice>)}
        onChangeSelectItemsAcrossAllPagesCallback={setAreAllItemsAcrossPagesSelected}
        callbackAction={creditNoteActions.listPageRes}
      />
    </div>
  );
}
