import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, UploadZone } from 'shared/io';
import { accountActions } from 'store/account/account.actions';

import { CGV, CompanyEdit, SalePreferencesFieldsName } from '../PreferencesForm.constants';

import { getFieldErrors } from './SalePreferencesPartialForm.utils';

import styleIdentifiers from '../PreferencesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onboarding?: boolean;
  parent?: number;
};

export const SalePreferencesPartialFormUpload = ({ onboarding, parent = 0 }: Props) => {
  const { t } = useTranslation();

  const {
    watch,
    formState: { errors },
  } = useFormContext<CompanyEdit>();

  const key = `${CGV}.${parent}.${SalePreferencesFieldsName.PDF_BLOB}` as const;

  const hasPdf = watch(`${CGV}.${parent}.${SalePreferencesFieldsName.HAS_PDF}` as const);

  const fieldErrors = getFieldErrors(errors, parent);

  const uploadZoneProps = {
    className: styles('upload-zone__el'),
    accept: 'application/pdf',
    info: t(i18nKeys.FORM.PREFERENCES.IMPORT_GENERAL_CONDITIONS),
    subinfo: t(i18nKeys.FORM.FILE_FORMAT_PDF),
    noResize: true,
    noMargin: true,
    noPreview: true,
    error: fieldErrors?.pdf,
    showError: true,
  };

  if (onboarding) {
    return (
      <Controller
        name={key}
        render={({ field: { ref, ...values } }) => <UploadZone {...values} {...uploadZoneProps} />}
      />
    );
  }

  return (
    <div className={styles('upload-zone')}>
      <Controller
        name={key}
        render={({ field: { ref, ...values } }) => <UploadZone {...values} {...uploadZoneProps} />}
      />
      {hasPdf && (
        <Button
          type="button"
          className={styles('upload-zone__pdf')}
          label={t(i18nKeys.FORM.PREFERENCES.READ_CONDITIONS)}
          noShadow
          small
          onClick={() => accountActions.getTerms({ id: watch(`${CGV}.${parent}.id`) })}
        />
      )}
    </div>
  );
};
