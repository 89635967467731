import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useForm } from 'react-hook-form';
import { Button, Textarea } from 'shared/io';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './debtorReactionForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
};

export default function DebtorReactionForm({ onSubmit }: Props) {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ shouldUnregister: true });
  const submit = (value) => {
    onSubmit(value);
    setValue('comment', '');
  };

  return (
    <form className={styles('DebtorReactionForm')} onSubmit={handleSubmit(submit)}>
      <Textarea
        error={errors.comment}
        register={register('comment', { required: true })}
        rows={5}
        withBorder
        label={t(i18nKeys.COMMENT)}
      />
      <div className={styles('buttons')}>
        <Button label={t(i18nKeys.SUBMIT)} type="submit" />
      </div>
    </form>
  );
}
