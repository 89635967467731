import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import AYSModal from 'shared/components/AYSModal';
import { DateSelector, SwitchInput } from 'shared/io';
import { DialogShowId, showDialog } from 'store/view/view.actions';

import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName, IntegrationFormProps } from '../Integration.constants';
import { IntegrationForm } from '../IntegrationForm';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum DBasicsFormFieldsName {
  IMPORT_INTEGRATION = 'use_dbasics_import_integration',
  PAYMENT_MATCHING = 'use_dbasics_payment_matching',
}

export type DBasicsFormFields = {
  [DBasicsFormFieldsName.IMPORT_INTEGRATION]: boolean;
  [DBasicsFormFieldsName.PAYMENT_MATCHING]: boolean;
  [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: Date;
  [IntegrationFormFieldsName.DEBTOR_UPDATE]: boolean;
  [IntegrationFormFieldsName.AUTOMATIC_SYNC]: boolean;
};

export type DBasicsFormProps = IntegrationFormProps<DBasicsFormFields>;

export const DBasicsForm = ({ onSubmit, onboarding, initialValues }: DBasicsFormProps) => {
  const { t } = useTranslation();

  const [shouldPreventSubmit, setShouldPreventSubmit] = useState<boolean>(false);

  const form = useForm<DBasicsFormFields>({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  const { handleSubmit, control, watch, setValue } = form;

  const submit = (values: DBasicsFormFields) => {
    if (shouldPreventSubmit) {
      showDialog({
        id: DialogShowId.CONFIRM,
        title: t(i18nKeys.CONFIRM),
        children: (
          <AYSModal
            text={t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE)}
            onConfirm={() => {
              setShouldPreventSubmit(false);
              onSubmit(values);
            }}
          />
        ),
      });
    } else {
      onSubmit(values);
    }
  };

  const debtorUpdate = watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
  const importIntegration = watch(DBasicsFormFieldsName.IMPORT_INTEGRATION);

  useEffect(() => {
    if (importIntegration) {
      setValue(
        IntegrationFormFieldsName.ISSUE_DATE_LIMIT,
        initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          : new Date(),
      );
    }
  }, [importIntegration, initialValues, setValue]);

  useEffect(() => {
    form.reset(initialValues);
  }, [initialValues]);

  return (
    <IntegrationForm<DBasicsFormFields>
      integration={Integration.DBASICS}
      onSubmit={handleSubmit(submit)}
      summary={i18nKeys.FORM.DBASICS.SUMMARY}
      form={form}
    >
      <div className={styles('grid-row')}>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={DBasicsFormFieldsName.IMPORT_INTEGRATION}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                  text: Integration.DBASICS,
                })}
              />
            )}
          />
          {importIntegration && (
            <>
              <div className={styles('col-12')}>
                <DateSelector
                  name={IntegrationFormFieldsName.ISSUE_DATE_LIMIT}
                  className={styles('issue-date')}
                  noMinDate
                  label={t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT)}
                  placeholder={t(i18nKeys.ISSUE_DATE)}
                  endPlaceholder={t(i18nKeys.FORM.TO)}
                  required
                  handleChange={(date: Date) => {
                    if (!onboarding) {
                      setShouldPreventSubmit(true);
                    }
                    setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                  }}
                />
              </div>
              <div className={styles('col-12')}>
                <Controller
                  control={control}
                  name={IntegrationFormFieldsName.DEBTOR_UPDATE}
                  render={({ field: { ref, ...values } }) => (
                    <SwitchInput
                      {...values}
                      inversed
                      className={styles('switch-button', 'issue-date')}
                      label={t(
                        debtorUpdate
                          ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                          : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR,
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={DBasicsFormFieldsName.PAYMENT_MATCHING}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                  text: Integration.DBASICS,
                })}
              />
            )}
          />
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={IntegrationFormFieldsName.AUTOMATIC_SYNC}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC)}
              />
            )}
          />
        </div>
      </div>
    </IntegrationForm>
  );
};
