import { Redirect, Route, Switch } from 'react-router';

import { DebtorKanban, InvoicesKanban } from './pages';

export const Kanban = () => (
  <Switch>
    <Route path="/kanban/invoices" component={InvoicesKanban} />
    <Route path="/kanban/debtors" component={DebtorKanban} />
    <Redirect to="/kanban/invoices" />
  </Switch>
);
