import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from 'store/root.reducer';

export const browserHistory = createBrowserHistory();
const connectedReducers = (hist) => combineReducers({ ...reducers, router: connectRouter(hist) });
export const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  connectedReducers(browserHistory),
  applyMiddleware(sagaMiddleware, routerMiddleware(browserHistory as any)),
);
