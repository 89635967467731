import { removeAttributes } from 'shared/utils/view';
import { ThirdPartyCaseDetails } from 'types/store/thirdparty-case-state';

export const treatTpCase = (item: ThirdPartyCaseDetails): ThirdPartyCaseDetails => {
  const thirdpartyCase = removeAttributes(item);
  if (thirdpartyCase.invoice_feedback) {
    thirdpartyCase.invoice_feedback = removeAttributes(thirdpartyCase.invoice_feedback);
    if (thirdpartyCase.invoice_feedback.conversation) {
      thirdpartyCase.invoice_feedback.conversation = removeAttributes(
        thirdpartyCase.invoice_feedback.conversation,
      );
      thirdpartyCase.invoice_feedback.conversation.messages =
        thirdpartyCase.invoice_feedback.conversation.messages.map((message) =>
          removeAttributes(message),
        );
    }
  }
  if (thirdpartyCase.attached_invoices) {
    thirdpartyCase.attached_invoices.details = thirdpartyCase.attached_invoices.details.map(
      (invoice) => removeAttributes(invoice),
    );
  }
  return thirdpartyCase;
};
