import { useIsMobile } from 'shared/hooks/utils';

import { ActionIcon, Box, Center } from '@mantine/core';
import { IconMenu2 } from '@tabler/icons-react';

interface MobileMenuButtonProps {
  onClickOpenNavbar: () => void;
}

export const MobileMenuButton = ({ onClickOpenNavbar }: MobileMenuButtonProps) => {
  const isMobile = useIsMobile();

  if (!isMobile) return null;

  return (
    <Box
      onClick={onClickOpenNavbar}
      bg="white"
      w={50}
      h={50}
      pos="absolute"
      bottom={20}
      right={-60}
      p={4}
      style={{
        borderRadius: '100%',
        border: '1px solid #EDEDED',
        boxShadow: 'var(--mantine-shadow-md)',
      }}
    >
      <Center h="100%">
        <ActionIcon variant="transparent">
          <IconMenu2 />
        </ActionIcon>
      </Center>
    </Box>
  );
};
