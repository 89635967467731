import { AxiosInstance } from 'axios';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useLegacyAxiosInstance } from './utils';

export type DeleteExternalMailboxVariables = {
  id: string;
};

export async function deleteExternalMailboxFn(
  axiosInstance: Promise<AxiosInstance>,
  variables: DeleteExternalMailboxVariables,
): Promise<ApiResponse> {
  const { id } = variables;
  const instance = await axiosInstance;

  return instance.delete(`external_mailbox_configurations/${id}`);
}

export function useDeleteExternalMailbox() {
  const client = useQueryClient();
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, DeleteExternalMailboxVariables>({
    mutationFn: (variables) => deleteExternalMailboxFn(axiosInstance, variables),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({ queryKey: ['externalMailboxes'] });
    },
    onError: (error: ApiError) => {
      notifications.show({
        color: 'red',
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    DeleteExternalMailboxVariables,
    'deleteExternalMailbox'
  >('deleteExternalMailbox', mutation);
}
