import { AxiosInstance } from 'axios';
import { Id } from 'types';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export async function resendInvitationFn(axiosInstance: Promise<AxiosInstance>, { userId }) {
  const instance = await axiosInstance;
  return instance.put(`send_invitations/${userId}`);
}

export function useResendInvitation() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, Id>({
    mutationFn: (userId) => resendInvitationFn(axiosInstance, { userId }),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, Id, 'resendInvitation'>(
    'resendInvitation',
    mutation,
  );
}
