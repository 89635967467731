import { useSearchInvoices } from 'api';
import { Id } from 'api/models';

type InvoiceReferencesProps = {
  ids: Id[];
};

export default function InvoiceReferences({ ids }: InvoiceReferencesProps) {
  const params = {
    ids,
  };

  const { data: invoicesResponse } = useSearchInvoices(params);

  const invoices = invoicesResponse?.data || [];

  const references = invoices.map(({ attributes: { reference } }) => reference).join(' / ');

  return <>{references}</>;
}
