import { useTranslation } from 'locales';
import { formattedDate } from 'shared/utils/view';
import { TableItemProps } from 'types/table';

import { Box, Text } from '@mantine/core';

import { ReminderItem } from '../api/use-load-index-reminders';

type ReminderListItemProps = TableItemProps<ReminderItem>;

export const ReminderListItem = ({ item, noCheckbox }: ReminderListItemProps) => {
  const { currentLang } = useTranslation();

  return (
    <tr>
      <td>
        {!noCheckbox && (
          <div>
            <Box w={0} />
          </div>
        )}
      </td>
      <td>
        <Text fz={14}>{item.reference}</Text>
      </td>
      <td>
        <Text fz={14}>{formattedDate(item.sentAt, currentLang)}</Text>
      </td>
      <td>
        <Text fz={14}>{item.debtorFullName}</Text>
      </td>
      <td>
        <Text fz={14}>{item.reminderType}</Text>
      </td>
    </tr>
  );
};
