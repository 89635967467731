import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { isDefined } from 'remeda';
import Amount from 'shared/components/Amount';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';
import { formattedDate, removeAttributes } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { DebtorReactionInvoice } from 'types/debtor';
import { Debtor } from 'types/store/client-state';
import { MetaData } from 'types/storeTypes';

import { Center, Pagination } from '@mantine/core';

import InvoiceDetailDebtor from '../InvoiceDetailDebtor';

import styleIdentifiers from './DebtorReactionInvoicesList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  items: any[];
  isInvoicesTabActive: boolean;
  data: any;
  queryStrings: any;
  paymentLink: Function;
  setData: Function;
  onCheckboxChange: Function;
  metadata?: MetaData;
  credit?: boolean;
  billingStatement?: boolean;
  canUseOnlinePayment?: boolean;
  canUsePaymentPlan?: boolean;
  canRequestPaymentPlan?: boolean;
  isFrenchClient?: boolean;
  canUseDigiteal?: boolean;
  hasPaymentOptions?: boolean;
};

export default function DebtorReactionInvoicesList({
  items,
  isInvoicesTabActive,
  setData,
  onCheckboxChange,
  data,
  queryStrings,
  paymentLink,
  credit = false,
  metadata,
  billingStatement,
  canUseOnlinePayment,
  canUsePaymentPlan,
  canRequestPaymentPlan,
  isFrenchClient,
  canUseDigiteal,
  hasPaymentOptions = false,
}: Props) {
  const { t, currentLang } = useTranslation();
  const { params } = useRouteMatch<{ id: any }>();

  const { register, watch } = useFormContext();

  const openDownloadDocument = (invoice: DebtorReactionInvoice) => () => {
    sideMenuShow({
      unmount: true,
      content: (
        <InvoiceDetailDebtor
          credit={credit}
          payInvoice={
            invoice.remaining_balance_with_fees > 0
              ? () => {
                  sideMenuHide();
                  paymentLink([invoice]);
                }
              : undefined
          }
          invoice={invoice}
          isInvoicesTabActive={isInvoicesTabActive}
          canUseOnlinePayment={canUseOnlinePayment}
          isFrenchClient={isFrenchClient}
          canUseDigiteal={canUseDigiteal}
        />
      ),
    });
  };

  const setPage = ({ selected }) => {
    invoiceActions.debtorReactionInvoices({
      id: params.id,
      data: {
        page: selected + 1,
        token: queryStrings.document_token,
      },
      callback: (paidInvoices) => {
        paidInvoices.data = paidInvoices.data.map((invoices) => removeAttributes(invoices));
        setData({
          ...data!,
          paidInvoices,
        });
      },
    });
  };

  const getPrice = (invoice, localized = false) => {
    const baseObject = localized ? invoice.localized_money_object : invoice;
    if (credit) {
      return baseObject.total_tvac;
    }
    if (isInvoicesTabActive) {
      return baseObject.total_tvac;
    }
    return baseObject.remaining_balance_with_fees;
  };

  return (
    <div className={styles('debtor-reaction-invoices-list')}>
      <div className={styles('invoice-list')}>
        {billingStatement && <div className={styles('subtitle')}>{t(i18nKeys.INVOICES)}</div>}
        {items?.length > 0 ? (
          items.map((invoice) => (
            <div key={invoice.id} className={styles('invoice-item', invoice.is_late && 'late')}>
              {hasPaymentOptions &&
                !credit &&
                !isInvoicesTabActive &&
                (canUseOnlinePayment || (canUsePaymentPlan && canRequestPaymentPlan)) && (
                  <div className={styles('checkbox')}>
                    <Checkbox
                      watch={watch}
                      noMargin
                      register={register(`${invoice.id}`)}
                      onChange={onCheckboxChange(invoice)}
                    />
                  </div>
                )}
              <div className={styles('invoice-info')} onClick={openDownloadDocument(invoice)}>
                <div>
                  <div className={styles('reference')}>
                    {canUsePaymentPlan && canRequestPaymentPlan && (
                      <Icon
                        title={t(
                          i18nKeys.TOOLTIP[
                            `CAN_${
                              invoice.eligible_for_payment_plan ? '' : 'NOT_'
                            }BE_INCLUDED_IN_PAYMENT_PLAN`
                          ],
                        )}
                        name={
                          IconName[invoice.eligible_for_payment_plan ? 'CHECK' : 'SIMPLE_REMOVE']
                        }
                        color={IconColor[invoice.eligible_for_payment_plan ? 'GREEN' : 'RED']}
                      />
                    )}{' '}
                    <p>{invoice.reference}</p>
                  </div>
                  <div className={styles('amount')}>
                    <Amount
                      localizedValue={getPrice(invoice)}
                      value={getPrice(invoice, true)}
                      suffix={invoice.currency}
                      debtor={data.debtor_data as Debtor}
                    />
                  </div>
                </div>
                <div className={styles('date')}>
                  <div>
                    <span className={styles('invoice-subtitle')}>
                      {t(i18nKeys.INVOICE.ISSUE_ON)}{' '}
                    </span>{' '}
                    {formattedDate(invoice.issue_date, currentLang)}
                  </div>
                  {!credit && isInvoicesTabActive ? (
                    +invoice.remaining_balance_with_fees === 0 && (
                      <div className={styles('green')}>{t(i18nKeys.INVOICE.PAID)}</div>
                    )
                  ) : (
                    <div>
                      <span className={styles('invoice-subtitle')}>
                        {t(i18nKeys.INVOICE.DUE_ON)}{' '}
                      </span>
                      {formattedDate(invoice.due_date, currentLang)}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles('actions-container')} onClick={openDownloadDocument(invoice)}>
                {invoice.has_pdf && <Icon name={IconName.DOWNLOAD} />}
              </div>
            </div>
          ))
        ) : (
          <div className={styles('no-invoice')}>
            {t(
              credit
                ? i18nKeys.NO_RESULT
                : isInvoicesTabActive
                  ? i18nKeys.DEBTOR.REACTION.NO_PAID_INVOICE
                  : i18nKeys.DEBTOR.REACTION.NO_INVOICE_TO_PAY,
            )}
          </div>
        )}
        {data && billingStatement && (
          <>
            {data.credit_notes.length > 0 && (
              <>
                <div className={styles('subtitle')}>{t(i18nKeys.NAV.CREDIT_NOTES)}</div>

                {data.credit_notes.map((cn) => (
                  <div key={cn.id} className={styles('invoice-item')}>
                    {hasPaymentOptions && canUseOnlinePayment && (
                      <div className={styles('checkbox')}>
                        <Checkbox
                          register={register(cn.id)}
                          watch={watch}
                          noMargin
                          onChange={onCheckboxChange(cn)}
                        />
                      </div>
                    )}
                    <div className={styles('invoice-info')} onClick={openDownloadDocument(cn)}>
                      <div>
                        <div className={styles('reference')}>{cn.reference}</div>
                        <div className={styles('amount')}>
                          <Amount
                            localizedValue={cn.remaining_balance}
                            value={cn.localized_money_object.remaining_balance}
                            suffix={cn.currency}
                            debtor={data.debtor_data as Debtor}
                          />
                        </div>
                      </div>
                      <div className={styles('date')}>
                        <div>
                          <span className={styles('invoice-subtitle')}>
                            {t(i18nKeys.INVOICE.ISSUE_ON)}{' '}
                          </span>{' '}
                          {formattedDate(cn.issue_date, currentLang)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {data.payments_sum !== 0 && (
              <>
                <div className={styles('subtitle')}>
                  {t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)}
                </div>

                <div className={styles('invoice-item', 'payment')}>
                  {hasPaymentOptions && (
                    <div className={styles('checkbox')}>
                      <Checkbox
                        register={register('payment')}
                        watch={watch}
                        noMargin
                        onChange={onCheckboxChange({
                          remaining_balance_with_fees: data.payments_sum,
                          id: 'payment',
                        })}
                      />
                    </div>
                  )}
                  <div className={styles('invoice-info')}>
                    <div>
                      <div className={styles('reference')}>{t(i18nKeys.RECEIVED_PAYMENT_S)}</div>
                      <div className={styles('amount')}>
                        <Amount
                          value={data.payments_sum}
                          suffix={data.debtor_data.currency}
                          debtor={data.debtor_data as Debtor}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {isDefined(metadata?.pagination?.last_page) && (
        <Center>
          <Pagination
            total={metadata.pagination.last_page}
            onChange={(pageIndex) => setPage({ selected: pageIndex - 1 })}
            value={metadata?.pagination.current_page}
            size="sm"
          />
        </Center>
      )}
    </div>
  );
}
