import { useEffect } from 'react';
import { useAuthenticateDomain, useDeleteDomain, useGetEmailDomain } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Input } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import { CustomDomainFields, CustomDomainFieldsName } from '../PreferencesForm.constants';

import { DnsSettings } from './DnsSettings';

import styleIdentifiers from '../PreferencesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const CustomDomainPartialForm = () => {
  const { t } = useTranslation();

  const {
    package: { can_use_custom_domain },
  } = useSelector((state: StoreState) => state.account.company.data!);

  const { data: customDomainResponse, refetch } = useGetEmailDomain();

  const { mutate: authenticateDomain } = useAuthenticateDomain();
  const { mutate: deleteDomain } = useDeleteDomain();

  const { register, watch, setValue } = useFormContext<CustomDomainFields>();

  const { data: customDomain } = customDomainResponse || {};

  const onSubmit = () => {
    authenticateDomain(
      { data: { custom_domain: watch('custom_domain') } },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  useEffect(() => {
    if (customDomain) {
      setValue(CustomDomainFieldsName.CUSTOM_DOMAIN, customDomain.attributes.domain);
    }
  }, [customDomain]);

  const openDnsSettings = () => {
    if (customDomain) {
      showDialog({
        id: DialogShowId.CUSTOM,
        size: DialogShowSize.MEDIUM,
        title: t(i18nKeys.CUSTOM_DOMAIN.DNS_SETTINGS),
        children: <DnsSettings domainId={customDomain.id} />,
      });
    }
  };

  const handleDeleteDomain = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_DOMAIN)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteDomain(
              { id: customDomain!.id },
              {
                onSuccess: () => {
                  refetch();
                },
              },
            )
          }
        />
      ),
    });
  };

  const customDomainValue = watch('custom_domain');
  // https://regexr.com/3au3g
  const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
  const isDomainValid = customDomain && customDomain.attributes.domain_valid;

  return (
    <div className={styles('col-12')}>
      <h4 className={styles('margin')}>{t(i18nKeys.CUSTOM_DOMAIN.SECTION_TITLE)}</h4>
      <div className={styles('custom-domain-partial-form')}>
        <div className={styles('domain-setup')}>
          <Input
            register={register(CustomDomainFieldsName.CUSTOM_DOMAIN)}
            label={t(i18nKeys.CUSTOM_DOMAIN.NAME)}
            disabled={!can_use_custom_domain || Boolean(customDomain)}
            title={can_use_custom_domain ? '' : t(i18nKeys.NOT_INCLUDED)}
            noMargin
          />
          <div className={styles('domain-button-wrapper')}>
            {customDomain ? (
              <>
                <Button
                  onClick={handleDeleteDomain}
                  label={(<Icon name={IconName.TRASH_SIMPLE} />) as any}
                  color={ButtonColor.RED}
                  className={styles('delete-button')}
                  small
                  noMargin
                />
                <Button
                  onClick={openDnsSettings}
                  label={t(i18nKeys.CUSTOM_DOMAIN.VERIFY)}
                  color={ButtonColor.BLUE}
                  small
                  noMargin
                />
              </>
            ) : (
              <Button
                onClick={onSubmit}
                label={t(i18nKeys.CUSTOM_DOMAIN.AUTHENTICATE)}
                color={ButtonColor.GREEN}
                noMargin
                small
                disabled={
                  customDomainValue === '' ||
                  customDomainValue === null ||
                  !domainRegex.test(customDomainValue)
                }
              />
            )}
          </div>
        </div>
        {customDomain && (
          <div className={styles('default-from')}>
            <Input
              register={register(CustomDomainFieldsName.DEFAULT_FROM)}
              label={t(i18nKeys.CUSTOM_DOMAIN.DEFAULT_FROM)}
              noMargin
              disabled={!isDomainValid}
              placeholder={
                isDomainValid ? 'domain.com' : t(i18nKeys.CUSTOM_DOMAIN.NOT_YET_VERIFIED)
              }
            />
            <div className={styles('at-domain')}>
              <p>@{customDomain.attributes.domain}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
