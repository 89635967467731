import { useContext } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import { Icon, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import EncodePaymentForm from 'shared/forms/EncodePaymentForm';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { isConnectorActive } from 'shared/utils/connector';
import { formattedDate } from 'shared/utils/view';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';

import InvoiceDetailContext from '../InvoiceDetail.context';

import LatePaymentInfoTermsModal from './LatePaymentInfoTermsModal';

import styleIdentifiers from '../ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export function LatePaymentInfo() {
  const { t, currentLang } = useTranslation();
  const {
    data,
    credit: isCreditNote,
    unmatchPayment,
    clientData,
    reloadInfo,
    // TODO: invert this flag
    // eslint-disable-next-line @typescript-eslint/naming-convention
    noValidate,
  } = useContext(InvoiceDetailContext);

  const history = useHistory();
  const hasActiveConnector = isConnectorActive();
  const canShowInvoiceButtons = !noValidate && !isCreditNote && !hasActiveConnector;

  const submitPayment = (invoiceId, reload, currency) => (values: Object) => {
    invoiceActions.actionsAddPayment({
      id: invoiceId,
      data: {
        currency,
        ...values,
      },
      callback: () => {
        reload();
        sideMenuHide();
      },
    });
  };

  const addPayment = (invoiceId, reload) => () => {
    sideMenuShow({
      unmount: true,
      content: (
        <EncodePaymentForm
          onSubmit={submitPayment(invoiceId, reload, data.currency)}
          currency={data.currency}
          amountWithoutFees={data.remaining_balance_with_fees - data.late_fees}
          totalAmount={data.remaining_balance_with_fees}
        />
      ),
    });
  };

  const createCreditNote = (reference) => () => {
    dialogHide(DialogShowId.INVOICE);
    creditNoteActions.detailReset();
    history.push(`/credit-notes/create?reference=${reference}`);
  };

  const canUnmatch = (() => {
    if (isCreditNote) return Number(data.remaining_balance) !== Number(data.total_tvac);
    if (noValidate) return false;
    if (data.actions.includes('unmatch_payment')) return true;
    if ((data.credit_notes_attributes ?? []).length > 0) return true; // Sentry 181

    return false;
  })();

  const showCgvDetails = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.SALE_CONDITION_APPLIED),
      children: <LatePaymentInfoTermsModal invoice={data} />,
    });
  };

  return (
    <Card
      title={t(i18nKeys.INVOICE.PAYMENT_INFO)}
      className={styles('box', 'late-payment-info')}
      actionHead={canUnmatch ? unmatchPayment : undefined}
      infosRight={
        canUnmatch && (
          <div onClick={unmatchPayment}>
            <Icon name={IconName.EYE} size="22px" />
          </div>
        )
      }
    >
      <div className={styles('item-container')}>
        <div className={styles('item')}>
          <span className={styles('subtitle')}>{t(i18nKeys.DUE_DATE)}</span>
          <span className={styles('value', 'due-date')}>
            <DateItem
              date={data.due_date}
              lg={currentLang}
              format="DD/MM/YY"
              className={styles('value', 'blue', 'bigger')}
            />
            <span className={styles('value', 'medium', 'red', 'ml-10')}>
              + {t(i18nKeys.DAYS, { count: data.days_late || 0 })}
            </span>
          </span>
        </div>
        {!data.paid && clientData?.average_payment_delay !== null && (
          <div className={styles('item')}>
            <span className={styles('subtitle')}>{t(i18nKeys.INVOICE.ESTIMATED_PAYMENT_DATE)}</span>
            <div>
              {data.issue_date ? (
                <span className={styles('value', 'bigger', 'blue')}>
                  {formattedDate(
                    data.issue_date.unix() * 1000 +
                      1000 * 60 * 60 * 24 * clientData!.average_payment_delay,
                    currentLang,
                    'DD/MM/YY',
                  )}
                </span>
              ) : (
                <span className={styles('value', 'bigger', 'blue')}>-</span>
              )}
            </div>
          </div>
        )}
        {!isCreditNote && (
          <div className={styles('item')}>
            <span className={styles('subtitle')}>{t(i18nKeys.INVOICE.TOTAL_LATE_FEES)}</span>
            <div className={styles('value')}>
              <Amount
                localizedValue={data.late_fees}
                value={data.localized_money_object.late_fees}
                suffix={data.currency}
                className={styles('bigger')}
              />
              <Tooltip
                className={styles('ml-10')}
                direction={DropdownDirection.LEFT}
                icon={IconName.INFO}
              >
                <div className={styles('payment-fees-tooltip')}>
                  {data.interest_amount > 0 && (
                    <div className={styles('item')}>
                      <span className={styles('subtitle')}>
                        {t(i18nKeys.INVOICE.INTEREST)}{' '}
                        {data.late_fees_calculated_at &&
                          t(i18nKeys.INVOICE.CALCULATE_DATE, {
                            date: formattedDate(data.late_fees_calculated_at, currentLang),
                          })}
                      </span>
                      <Amount
                        value={data.interest_amount}
                        suffix={data.currency}
                        className={styles('value', 'bigger')}
                        tooltip={false}
                        localizedValue={data.localized_money_object.interest_amount}
                      />
                    </div>
                  )}
                  {data.fix_fee_amount > 0 && (
                    <div className={styles('item')}>
                      <span className={styles('subtitle')}>{t(i18nKeys.FIX_FEE_AMOUNT)}</span>
                      <Amount
                        value={data.fix_fee_amount}
                        suffix={data.currency}
                        className={styles('value', 'bigger')}
                        tooltip={false}
                        localizedValue={data.localized_money_object.fix_fee_amount}
                      />
                    </div>
                  )}
                  {data.penalty_clause_amount > 0 && (
                    <div className={styles('item')}>
                      <span className={styles('subtitle')}>{t(i18nKeys.PENALTY_CLAUSE)}</span>
                      <Amount
                        value={data.penalty_clause_amount}
                        suffix={data.currency}
                        className={styles('value', 'bigger')}
                        tooltip={false}
                        localizedValue={data.localized_money_object.penalty_clause_amount}
                      />
                    </div>
                  )}

                  <div className={styles('item')}>
                    <span className={styles('subtitle')}>{t(i18nKeys.SALE_CONDITION)}</span>
                    <span onClick={showCgvDetails} className={styles('value', 'medium', 'link')}>
                      {t(i18nKeys.SHOW_CGV)}
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        )}

        <div className={styles('item')}>
          {data.remaining_balance_with_fees === data.remaining_balance || isCreditNote ? (
            <>
              <span className={styles('subtitle')}>{t(i18nKeys.BALANCE)}</span>
              <span>
                <Amount
                  localizedValue={data.remaining_balance}
                  value={data.localized_money_object.remaining_balance}
                  suffix={data.currency}
                  className={styles('value', 'bigger')}
                />
              </span>
            </>
          ) : (
            <>
              <span className={styles('subtitle')}>{t(i18nKeys.INVOICE.BALANCE_FEES)}</span>
              <span className={styles('value', 'inline', 'bigger')}>
                <Amount
                  localizedValue={data.remaining_balance}
                  value={data.localized_money_object.remaining_balance}
                  suffix={data.currency}
                  style={{ display: ' inline-block' }}
                />
                {' / '}
                <Amount
                  localizedValue={data.remaining_balance_with_fees}
                  value={data.localized_money_object.remaining_balance_with_fees}
                  suffix={data.currency}
                  style={{ display: ' inline-block' }}
                />
              </span>
            </>
          )}
        </div>
      </div>
      {canShowInvoiceButtons && (
        <div className={styles('card-header')}>
          <div
            title={t(i18nKeys.INVOICE.CREATE_CREDIT_NOTE)}
            className={styles('icon', !data.total_htva && 'disabled')}
            onClick={createCreditNote(data.reference)}
          >
            <Icon name={IconName.CREDIT_NOTE} className={styles('icon')} />
          </div>

          <div
            title={t(i18nKeys.INVOICE.ADD_PAYMENT)}
            className={styles(!data.actions?.includes('add_payment') && 'disabled')}
            onClick={addPayment(data.id, reloadInfo)}
          >
            <Icon name={IconName.ADD_PAYMENT} className={styles('icon')} />
          </div>
        </div>
      )}
    </Card>
  );
}
