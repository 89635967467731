import { filter, isTruthy, partition, prop } from 'remeda';

import { SpotlightActionData, SpotlightActionGroupData } from '@mantine/spotlight';

import { Menu, MenuItem } from './types';

type NewType = Menu;

export const isMenu = (item: MenuItem | Menu): item is NewType => 'parent' in item;

/**
 * Finds the relevant menu or submenu based on the given location path.
 * Used to display the correct menu when the app loads on a specific page other than the dashboard
 */
export const findMenuForLocation = (menu: Menu, locationPath: string): Menu | null => {
  const [submenus, navItems] = partition(menu.items, isMenu);

  const pathsOfItemsInMenu = filter(navItems.map(prop('path')), isTruthy);
  if (pathsOfItemsInMenu.some((path) => locationPath.includes(path))) return menu;

  for (const submenu of submenus) {
    const parentMenu = findMenuForLocation(submenu, locationPath);
    if (parentMenu) return parentMenu;
  }

  return null;
};

export function menuToSpotlightActions(menu: Menu, navigate: (url: string) => void): Array<any> {
  return menu.items.reduce(
    (acc, item) => {
      if (isMenu(item)) {
        return [...acc, ...menuToSpotlightActions(item, navigate)];
      }

      return [
        ...acc,
        {
          id: item.label,
          label: item.label,
          onClick: () => navigate(item.path ?? ''),
        },
      ];
    },
    [] as Array<SpotlightActionData | SpotlightActionGroupData>,
  );
}
