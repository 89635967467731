import { useEffect } from 'react';
import { TaskFormDebtor } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { isNil, omit, omitBy } from 'lodash-es';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AccountManagerSelector } from 'shared/components';
import { ExternalMailsSearchParams } from 'shared/hooks';
import { CustomSelect, DateSelector, Input } from 'shared/io';

import { EXTERNAL_MAIL_STATUSES, ExternalMailListFiltersFields } from '../ExternalMail.constants';

import { ExternalMailBoxForm } from './ExternalMailBoxForm';

import styleIdentifiers from './ExternalMailList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailListFiltersProps = {
  onSubmit: SubmitHandler<ExternalMailListFiltersFields>;
  initialValues: ExternalMailsSearchParams;
  filterObject: {
    reset: () => void;
  };
};

export const ExternalMailListFilters = ({
  onSubmit,
  initialValues,
  filterObject,
}: ExternalMailListFiltersProps) => {
  const { t } = useTranslation();

  const form = useForm<ExternalMailListFiltersFields>({
    shouldUnregister: true,
    defaultValues: omit(initialValues, ['page', 'page_limit', 'sort_by', 'sort_order']),
  });

  const { setValue, register, handleSubmit, watch, getValues } = form;

  const values = watch();

  useEffect(() => {
    filterObject.reset = () => {
      Object.keys(getValues()).forEach((key) =>
        form.setValue(key as keyof ExternalMailListFiltersFields, ''),
      );
    };
  }, [filterObject, form, getValues, setValue]);

  useEffect(() => {
    onSubmit(omitBy(values, (v) => isNil(v) || (Array.isArray(v) && !v.length) || v === ''));
  }, [values, onSubmit]);

  const getIsoDate = (date: string) => dayjs(date).format('YYYY-MM-DD');

  const externalMailStatuses = EXTERNAL_MAIL_STATUSES.map(({ description, ...rest }) => ({
    description: t(description),
    ...rest,
  }));

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles('external-mail-filter-form')}>
        <div className={styles('container-fields')}>
          <div className={styles('fields')}>
            <ExternalMailBoxForm />
            <div className={styles('large')}>
              <Input
                register={register('subject')}
                type="text"
                label={t(i18nKeys.SUBJECT)}
                withBorder
                timeout={500}
              />
            </div>
            <div className={styles('large')}>
              <Input
                register={register('body')}
                type="text"
                label={t(i18nKeys.BODY)}
                withBorder
                timeout={500}
              />
            </div>
            <div className={styles('large')}>
              <Input
                type="text"
                label={t(i18nKeys.FROM)}
                register={register('from_mail')}
                withBorder
                timeout={500}
              />
            </div>
            <Controller
              name="status"
              render={() => (
                <CustomSelect
                  name="status"
                  keyText="description"
                  keyValue="value"
                  removeAll={t(i18nKeys.CLIENT.FILTER.ALL)}
                  items={externalMailStatuses}
                  label={t(i18nKeys.TASK.ATTRIBUTES.STATUS)}
                  withBorder
                  multiple
                />
              )}
            />
            <AccountManagerSelector name="account_manager_id" showMargin />
            <TaskFormDebtor
              extraOptions={[{ id: 'unassigned', full_name: t(i18nKeys.VIEW_UNASSIGNED_EMAILS) }]}
            />
            <DateSelector
              name="date_after"
              endName="date_before"
              label={t(i18nKeys.DATE)}
              className={styles('input', 'date-selector')}
              handleChange={(value) => {
                setValue('date_after', getIsoDate(value));
              }}
              handleEndChange={(value) => {
                setValue('date_before', getIsoDate(value));
              }}
              placeholder={t(i18nKeys.OF)}
              endPlaceholder={t(i18nKeys.FORM.TO)}
              withBorder
              noMinDate
              forcePlacement
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
