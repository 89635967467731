import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller } from 'react-hook-form';
import { ExternalMailboxConfiguration, useLoadExternalMailboxes } from 'shared/hooks';
import { CustomSelect } from 'shared/io';

import styleIdentifiers from './ExternalMailList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskFormDebtorProps = {
  name?: 'external_mailbox_id';
  currentMailbox?: ExternalMailboxConfiguration;
};

export const ExternalMailBoxForm = ({
  name = 'external_mailbox_id',
  currentMailbox,
}: TaskFormDebtorProps) => {
  const { t } = useTranslation();

  const { externalMailboxes } = useLoadExternalMailboxes();

  const mailboxes = externalMailboxes?.data || [];

  const concatedMailboxes = (currentMailbox ? [currentMailbox] : [])
    .concat(mailboxes.filter((e) => e.id !== currentMailbox?.id))
    .map((e) => ({ id: e.id, ...e.attributes }));

  return (
    <Controller
      name={name}
      render={() => (
        <CustomSelect
          selectClassName={styles('input')}
          withBorder
          items={concatedMailboxes}
          keyValue="id"
          keyText="login"
          name={name}
          label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE)}
        />
      )}
    />
  );
};
