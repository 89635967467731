/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type TaskStatus = (typeof TaskStatus)[keyof typeof TaskStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskStatus = {
  all: 'all',
  toDo: 'to_do',
  pending: 'pending',
  skipped: 'skipped',
  done: 'done',
} as const;
