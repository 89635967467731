import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RecoveryPlan } from 'shared/components/RecoveryPlan';
import { RecoveryPlan as RecoveryPlanType } from 'types/recovery-plan';

import styleIdentifiers from './MoveRecoveryPlanInvoiceModal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  plan: RecoveryPlanType;
  onSubmit: Function;
};

export default function MoveRecoveryPlanInvoiceModal({ plan, onSubmit }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles('move-recovery-plan-invoice')}>
      <p>{t(i18nKeys.PLAN.SELECT_STEP)}</p>
      <RecoveryPlan
        recoverPlan={plan}
        messageInfo={t(i18nKeys.PLAN.STEP_MUST_BE_CREATED)}
        stepClassName={styles('cursor-pointer')}
        onClickOnStep={onSubmit}
        canClickPreventiveReminder={false}
      />
    </div>
  );
}
