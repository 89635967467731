import { i18nKeys, useTranslation } from 'locales';

import { TextInput } from '@mantine/core';

interface NameStepProps {
  formProps: Object;
}

export const NameStep = ({ formProps }: NameStepProps) => {
  const { t } = useTranslation();

  return (
    <TextInput
      label={t(i18nKeys.REPORTS.NEW.STEPS.NAME.FORM_TITLE)}
      description={t(i18nKeys.REPORTS.NEW.STEPS.NAME.FORM_SUBTITLE)}
      placeholder={t(i18nKeys.REPORTS.NEW.STEPS.NAME.PLACEHOLDER)}
      w="100%"
      {...formProps}
    />
  );
};
