import { useState } from 'react';
import { useDeleteUnvalidatedInvoice } from 'api';
import { InvoiceComplete } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router-dom';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import StatusItem from 'shared/components/StatusItem';
import { Button, ButtonColor, DropdownDirection } from 'shared/io';
import { TableItemProps } from 'types/table';

import styleIdentifiers from './invoiceListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InvoiceListItemProps = TableItemProps<InvoiceComplete> & {
  isLoading: boolean;
  refetch: Function;
};

export default function InvoiceListItem({
  item,
  noCheckbox,
  isLoading,
  refetch,
}: InvoiceListItemProps) {
  const { t, currentLang } = useTranslation();

  const [isHovered, setHover] = useState(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const history = useHistory();

  const { mutate: deleteUnvalidatedInvoice } = useDeleteUnvalidatedInvoice();

  const {
    id,
    attributes: {
      debtor,
      reference,
      due_date,
      issue_date,
      days_late,
      step_collection,
      total_tvac,
      localized_money_object,
      currency,
      remaining_balance,
      csv_updated,
      status,
    },
  } = item;

  const goDetail = () => {
    if (!isDeleting && !isLoading) {
      history.push(`/invoice/to-confirm/${id}`);
    }
  };

  const deleteInvoice = (e) => {
    e.stopPropagation();
    if (!isDeleting && !isLoading) {
      e.stopPropagation();
      setIsDeleting(true);
      deleteUnvalidatedInvoice(
        { id },
        {
          onError: () => {
            setIsDeleting(false);
          },
          onSuccess: () => {
            refetch();
          },
        },
      );
    }
  };

  return (
    <tr
      className={styles('InvoiceListItem', isHovered && 'hover', item && status)}
      onMouseEnter={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={goDetail}
    >
      <td className={styles('CheckBoxContainer', noCheckbox && 'small')} />
      <td className={styles('InvoiceNumber')}>{reference}</td>

      <td className={styles('Date')}>
        <DateItem lg={currentLang} date={issue_date as any} />
      </td>

      <td className={styles('Date')}>
        <DateItem lg={currentLang} date={due_date as any} />
      </td>

      <td className={styles('Client')}>{debtor && debtor.attributes.full_name}</td>

      <td className={styles('Delay')}>
        {days_late > 1 && t(i18nKeys.DAYS, { count: days_late })}
        {days_late === 1 && t(i18nKeys.ONE_DAY)}
        {!days_late && '-'}
      </td>
      <td className={styles('StatusContainer')}>
        <StatusItem status={status} step={step_collection as any} />
      </td>
      <td className={styles('Amount')}>
        <Amount
          localizedValue={total_tvac}
          value={localized_money_object.total_tvac}
          suffix={currency}
          direction={DropdownDirection.TOP_LEFT}
        />
      </td>
      <td className={styles('Amount')}>
        <Amount
          localizedValue={remaining_balance}
          value={localized_money_object.remaining_balance}
          suffix={currency}
          direction={DropdownDirection.TOP_LEFT}
        />
      </td>
      <td className={styles('Actions')}>
        {!csv_updated && (
          <Button
            onClick={deleteInvoice}
            color={ButtonColor.GREY}
            label={t(i18nKeys.DELETE)}
            noMargin
            small
            disabled={isLoading || isDeleting}
          />
        )}
      </td>
    </tr>
  );
}
