import { useEffect } from 'react';
import { isNonNullish } from 'remeda';
import { useGetCompanyState } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';

import { useAuthenticate } from './use-authenticate';

export function useLoadCompanyV1() {
  const { auth0AccessToken } = useAuthenticate();

  const { loaded: isCompanyV1Loaded, data: companyV1 } = useGetCompanyState();

  useEffect(() => {
    if (isNonNullish(auth0AccessToken)) accountActions.getCompany();
  }, [auth0AccessToken]);

  return { isCompanyV1Loaded, companyV1 };
}
