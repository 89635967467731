import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { pathOr } from 'remeda';
import HTML from 'shared/components/HTML/HTML';
import { ThirdParties, ThirdPartyType } from 'shared/hooks';
import { Button, CustomSelect } from 'shared/io';
import { OnClickFunction } from 'types/html-type';
import { onSubmitFunction } from 'types/react-hook-form';
import { Invoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './selectThirdPartyForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  onSubmitGcollect?: onSubmitFunction;
  selectedThirdPartyType: ThirdPartyType;
  editClient?: OnClickFunction;
  invoice?: Invoice;
  thirdParties: ThirdParties;
};

export default function SelectThirdPartyForm({
  onSubmit,
  onSubmitGcollect,
  selectedThirdPartyType,
  invoice,
  editClient,
  thirdParties,
}: Props) {
  const { t } = useTranslation();
  const isLoading = useSelector((state: StoreState) => state.invoice.isLoading);
  const form = useForm({ shouldUnregister: true });
  const { watch } = form;

  const { availableCompanies } = thirdParties[selectedThirdPartyType];
  const selectedThirdPartyCompanyId = watch('third_party_company_id');
  const selectedThirdPartyCompany = availableCompanies.find(
    (company) => company.id === selectedThirdPartyCompanyId,
  );
  // @ts-ignore bad type inference on the isGcollect property
  const isGcollect = pathOr(selectedThirdPartyCompany, ['isGcollect'], false);

  const hasPhone =
    selectedThirdPartyType === ThirdPartyType.CALL_CENTER && invoice
      ? invoice.debtor_attributes.phones_attributes.length > 0
      : true;

  return (
    <>
      {selectedThirdPartyType === ThirdPartyType.CALL_CENTER && !hasPhone ? (
        <div className={styles('callcenter-form')}>
          <div>{t(i18nKeys.FORM.CLIENT.CALLCENTER_PHONE_REQUIRED)}</div>
          <Button onClick={editClient} label={t(i18nKeys.FORM.CLIENT.EDIT_RECORD)} />
        </div>
      ) : (
        <FormProvider {...form}>
          <form
            className={styles('select-third-party-form')}
            onSubmit={form.handleSubmit(isGcollect ? onSubmitGcollect! : onSubmit)}
          >
            <div className={styles('wrapper')}>
              {selectedThirdPartyType === ThirdPartyType.BAILIFF && (
                <div className={styles('description')}>
                  <HTML html={t(i18nKeys.FORM.CONFIRM_BAILIFF.DESCRIPTION)} />
                </div>
              )}
              <div className={styles('content')}>
                <Controller
                  defaultValue=""
                  rules={{ required: true }}
                  name="third_party_company_id"
                  render={() => (
                    <CustomSelect
                      items={thirdParties[selectedThirdPartyType].availableCompanies}
                      name="third_party_company_id"
                      placeholder={t(i18nKeys.SELECT)}
                      keyText="name"
                      keyValue="id"
                      className={styles('input')}
                    />
                  )}
                />
              </div>
              <div className={styles('button-wrapper')}>
                <Button
                  isLoading={isLoading}
                  label={t(i18nKeys.CONFIRM)}
                  type="submit"
                  className={styles('button')}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
}
