import { useGetTaskTypes } from 'api';
import { TaskTypeListItem } from 'app/Private/Tasks/TaskTypeList/TaskTypeListItem';
import { i18nKeys, useTranslation } from 'locales';
import CustomTable from 'shared/components/CustomTable';

const TaskTypesTable = CustomTable(TaskTypeListItem);

export const CustomizationsSettingsTaskTypes = () => {
  const { t } = useTranslation();

  const { data: taskTypesListResponse, isLoading, isSuccess, refetch } = useGetTaskTypes();

  const TABLE_HEADERS = [
    {
      key: 'id',
      title: t(i18nKeys.SETTINGS.TASKS.TASK_ID),
    },
    {
      key: 'name',
      title: t(i18nKeys.SETTINGS.TASKS.TASK_VALUE),
    },
    {
      key: 'creation',
      title: t(i18nKeys.CREATION),
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  return (
    <TaskTypesTable
      notSortable
      headers={TABLE_HEADERS}
      items={taskTypesListResponse?.data || []}
      pagination={taskTypesListResponse?.metadata?.pagination}
      itemProps={{ refetch }}
      loading={isLoading}
      loaded={isSuccess}
      noCheckbox
      noShadow
    />
  );
};
