import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import { addActionNameToMutationResult, ApiError, ApiResponse, useAxiosInstance } from './utils';

export const AcceptGcollectRatesVariablesSchema = z.object({
  thirdPartyCaseIds: z.array(z.number()),
  acceptRates: z.boolean(),
});

export type AcceptGcollectRatesVariables = z.infer<typeof AcceptGcollectRatesVariablesSchema>;

async function acceptGcollectRatesMutationFn(
  axiosInstance: Promise<AxiosInstance>,
  vars: AcceptGcollectRatesVariables,
) {
  const instance = await axiosInstance;

  const data = instance.post(
    'gcollect_cases/accept_rates',
    AcceptGcollectRatesVariablesSchema.parse(vars),
  );
  return data;
}

export function useAcceptGcollectRates() {
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation({
    mutationFn: (vars: AcceptGcollectRatesVariables) =>
      acceptGcollectRatesMutationFn(axiosInstance, vars),
    onError: (error: ApiError) => {
      console.error(error);
      notifications.show({
        title: 'Error',
        color: 'red',
        message: error.response?.data.error_message,
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    AcceptGcollectRatesVariables,
    'acceptGcollectRates'
  >('acceptGcollectRates', mutation);
}
