import { removeAttributes } from 'shared/utils/view';
import {
  baseReducerData,
  baseReducerListPage,
  handlePageRequest,
  handlePageResponse,
} from 'store/reducers';
import { thirdPartyCaseConstants as events } from 'store/thirdpartyCase/thirdpartycase.actions';
import { ReduxAction } from 'types/redux';
import { ThirdPartyCaseState } from 'types/store/thirdparty-case-state';

export const initialState: ThirdPartyCaseState = {
  list: baseReducerListPage,
  current: baseReducerData as any,
};

const reducer = (state: ThirdPartyCaseState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case events.listPage.request:
      return handlePageRequest(state, action, 'list');
    // listing
    case events.listPage.result:
      return handlePageResponse(state, action, 'list', removeAttributes);
    default:
      return state;
  }
};

export default reducer;
