import { useMantineTheme } from '@mantine/core';

export function useAgedBalanceColors() {
  const theme = useMantineTheme();

  return {
    notDue: '#8ED4C7',
    totalDue: theme.colors.cyan[9],
    0: theme.colors.blue[2],
    30: theme.colors.blue[3],
    60: theme.colors.blue[5],
    90: theme.colors.blue[7],
  } as const;
}

export function useAgedBalanceAccentColors() {
  const theme = useMantineTheme();

  return {
    totalDue: theme.colors.cyan[9],
    notDue: theme.colors.teal[5],
    0: theme.colors.blue[5],
    30: theme.colors.blue[7],
    60: theme.colors.blue[9],
    90: theme.colors.blue[9],
  } as const;
}
