import { SendingMediaNames } from 'api/models';
import { i18nKeys } from 'locales';
import { all, call, select, takeLatest } from 'redux-saga/effects';
import { invoiceFormToData } from 'shared/serializer';
import { api } from 'store/apis';
import {
  creditNoteActions,
  creditNoteConstants as events,
} from 'store/creditNote/creditnote.actions';
import { StoreState } from 'types/storeTypes';

import { apiCall, sendApi } from '../sagas';

function* creditNoteAdd(action) {
  const { payload } = action;
  const constants = yield select((state: StoreState) => state.app.constants);
  const toSend = invoiceFormToData(payload.data, constants);

  yield apiCall({
    api: api.creditNote.add,
    data: toSend,
    actionRes: creditNoteActions.addRes,
    callback: payload.callback,
  });
}

function* creditNoteUpdate({ payload }: any) {
  const constants = yield select((state: StoreState) => state.app.constants);
  const toSend = invoiceFormToData(payload.data, constants);

  yield apiCall({
    api: api.creditNote.update,
    id: toSend.id,
    data: toSend,
    actionRes: creditNoteActions.updateRes,
    callback: payload.callback,
    success: i18nKeys.INVOICE.SUCCESSFUL_CN_UPDATE,
  });
}

function* creditNoteLinkToInvoices({ payload }: any) {
  const { id, ...data } = payload.data;

  yield apiCall({
    api: api.creditNote.update,
    id,
    data,
    actionRes: creditNoteActions.linkToInvoicesRes,
    callback: payload.callback,
    success: i18nKeys.INVOICE.SUCCESSFUL_CN_UPDATE,
  });
}

function* creditNoteUnLinkToInvoices({ payload }: any) {
  const { id, data, callback } = payload;

  yield apiCall({
    api: api.creditNote.update,
    id,
    data,
    callback,
    actionRes: creditNoteActions.linkToInvoicesRes,
    success: i18nKeys.INVOICE.SUCCESSFUL_CN_UPDATE,
  });
}

function* creditNoteInfo({ payload }: any) {
  const { callback, ...rest } = payload;
  yield creditNoteActions.detail(payload);
  yield creditNoteActions.activity(rest);
}

function* creditNoteSend({ payload }: any) {
  const data = payload && payload.data;

  if (!data) return;

  if (data.by_mail && data.by_post) {
    data.sending_media = [
      { name: SendingMediaNames.email },
      {
        name: data.by_registered_post ? SendingMediaNames.registered_post : SendingMediaNames.post,
      },
    ];
  } else if (data.by_mail) {
    data.sending_media = [{ name: SendingMediaNames.email }];
  } else {
    data.sending_media = [
      {
        name: data.by_registered_post ? SendingMediaNames.registered_post : SendingMediaNames.post,
      },
    ];
  }

  yield apiCall({
    api: api.creditNote.send,
    id: payload.id,
    data,
    callback: payload.callback,
    actionRes: creditNoteActions.sendRes,
    success: i18nKeys.INVOICE.SUCCESSFUL_CN_SENT,
  });
}

function* invoiceSearch({ payload }: any) {
  const { callback, ...data } = payload;

  yield apiCall({
    api: api.creditNote.invoiceSearch,
    data,
    callback,
    noFeedback: true,
    noLoading: true,
    actionRes: creditNoteActions.invoiceSearchRes,
  });
}

function* creditNoteWatchers() {
  yield all([
    yield takeLatest(
      events.listPage.request,
      sendApi(api.creditNote.list, creditNoteActions.listPageRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(events.get.request, sendApi(api.creditNote.list)),
    yield takeLatest(
      events.detail.request,
      sendApi(api.creditNote.detail, creditNoteActions.detailRes),
    ),
    yield takeLatest(events.add.request, creditNoteAdd),
    yield takeLatest(events.update.request, creditNoteUpdate),
    yield takeLatest(events.linkToInvoices.request, creditNoteLinkToInvoices),
    yield takeLatest(events.unlinkToInvoices.request, creditNoteUnLinkToInvoices),
    yield takeLatest(
      events.delete.request,
      sendApi(api.creditNote.delete, creditNoteActions.deleteRes),
    ),
    yield takeLatest(events.info.request, creditNoteInfo),
    yield takeLatest(events.copy.request, sendApi(api.invoice.copy, creditNoteActions.detailRes)),
    yield takeLatest(events.send.request, creditNoteSend),
    yield takeLatest(events.settle.request, sendApi(api.creditNote.settle)),
    yield takeLatest(events.batchAction.request, sendApi(api.creditNote.batchAction)),
    yield takeLatest(
      events.activity.request,
      sendApi(api.creditNote.activity, creditNoteActions.activityRes),
    ),
    yield takeLatest(events.invoiceSearch.request, invoiceSearch),
    yield takeLatest(
      events.updateOriginalFile.request,
      sendApi(
        api.creditNote.updateOriginalFile,
        creditNoteActions.updateOriginalFileRes,
        (payload) => ({
          ...payload,
          fileUpload: true,
          success: i18nKeys.SUCCESSFULLY_FILE_UPLOAD,
        }),
      ),
    ),
  ]);
}

export default function* saga() {
  yield call(creditNoteWatchers);
}
