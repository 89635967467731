import { useState } from 'react';
import { useTranslation } from 'locales';
import { Redirect, useHistory } from 'react-router';
import { useAcceptRecovrTos, useProfile } from 'shared/hooks';
import { config } from 'store/constants';

import { Anchor, Button, Checkbox, Modal, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';

interface AcceptTermsOfServiceProps {}

export const AcceptTermsOfService = ({}: AcceptTermsOfServiceProps) => {
  const { currentLang } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const profile = useProfile();

  const { acceptRecovrTos, isAcceptRecovrTosLoading } = useAcceptRecovrTos();
  const [areTosAccepted, setAreTosAccepted] = useState(false);

  if (profile.hasAcceptedGc) return <Redirect to="/dashboard" />;

  const link = config.terms[currentLang];

  const onClickProceed = () => {
    acceptRecovrTos(
      {},
      {
        onSuccess: () => {
          history.push('/dashboard');
          queryClient.invalidateQueries({ queryKey: ['profile'] });
        },
      },
    );
  };

  return (
    <Modal
      title="Accept terms of service"
      withCloseButton={false}
      centered
      opened
      onClose={() => {}}
    >
      <Text pb="lg" size="sm">
        In order to proceed to your platform, please review and accept our{' '}
        <Anchor href={link} target="_blank">
          Terms of Service
        </Anchor>
      </Text>
      <Checkbox
        size="sm"
        label="I accept the terms of service"
        checked={areTosAccepted}
        onChange={(event) => setAreTosAccepted(event.currentTarget.checked)}
      />
      <Button
        w="100%"
        mt="lg"
        disabled={!areTosAccepted}
        loading={isAcceptRecovrTosLoading}
        onClick={onClickProceed}
      >
        Proceed
      </Button>
    </Modal>
  );
};
