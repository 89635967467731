import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { ExternalMail } from 'shared/hooks';

import styleIdentifiers from './ExternalMailDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailReplyHeadProps = {
  externalMail: ExternalMail;
  originalSubject: string;
  subject: string | undefined;
  setSubject: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const ExternalMailReplyHead = ({
  externalMail,
  originalSubject,
  subject,
  setSubject,
}: ExternalMailReplyHeadProps) => {
  const { t } = useTranslation();

  const {
    attributes: { from_mail },
  } = externalMail;

  return (
    <div className={styles('detail-head')}>
      <div className={styles('grid')}>
        <p className={styles('text')}>{t(i18nKeys.SUBJECT)}</p>
        <input
          type="text"
          className={styles('subject-input')}
          value={subject}
          placeholder={`RE: ${originalSubject}`}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className={styles('grid')}>
        <p className={styles('text')}>{t(i18nKeys.FORM.TO)}</p>
        <a className={styles('value')} href={`mailto:${from_mail}`}>
          {from_mail}
        </a>
      </div>
    </div>
  );
};
