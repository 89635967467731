import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, Checkbox, CustomSelect, DateSelector, Input } from 'shared/io';
import { useGetClientState, useGetCompany, useGetInvoiceState } from 'shared/utils/selectors';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { onSubmitFunction } from 'types/react-hook-form';

import { VarType } from '../CustomVariables.types';

import styleIdentifiers from './CustomVariablesBatchForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  resourceType: 'invoice' | 'debtor';
};

export default function CustomVariablesBatchForm({ onSubmit, resourceType }: Props) {
  const { t } = useTranslation();
  const { isLoading: isInvoiceLoading } = useGetInvoiceState();
  const { isLoading: isClientLoading } = useGetClientState();
  const isLoading = isInvoiceLoading || isClientLoading;
  const { custom_variables_attributes: availableCustomVariables } = useGetCompany()!;

  const resourceVariables = availableCustomVariables?.filter(
    (variable) => variable.model_type === resourceType,
  );

  const initialValues = { custom_variables: {}, blank_data: false };

  const form = useForm({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    sideMenuSetAskBeforeClose(isDirty);
  }, [isDirty]);

  const resourceTypeKey = resourceType === 'invoice' ? 'INVOICES' : 'CLIENTS';

  if (resourceVariables.length === 0)
    return (
      <div className={styles('no-content')}>
        <p>
          {t(i18nKeys.ERROR.NO_CUSTOM_VARIABLE, {
            resourceType: t(i18nKeys[resourceTypeKey]).toLowerCase(),
          })}
        </p>
      </div>
    );

  const submit = (values) => {
    onSubmit({ ...values });
  };

  const setEdit = (customVarKey) => {
    setValue(`custom_variables.${customVarKey}.edit` as any, true);
  };

  const resetValue = (value, customVarKey) => {
    if (value) return;
    setValue(`custom_variables.${customVarKey}.value` as any, null);
  };

  const accurateInput = (customVarKey, varType) => {
    switch (varType) {
      case VarType.BOOLEAN:
        return (
          <Controller
            defaultValue=""
            name={`custom_variables.${customVarKey}.value`}
            render={() => (
              <CustomSelect
                selectClassName={styles('input')}
                keyText="description"
                keyValue="value"
                items={[
                  {
                    description: t(i18nKeys.YES),
                    value: 'true',
                  },
                  {
                    description: t(i18nKeys.NO),
                    value: 'false',
                  },
                ]}
                withBorder
                name={`custom_variables.${customVarKey}.value`}
                label={customVarKey.replaceAll('_', ' ')}
                onValueChanged={() => setEdit(customVarKey)}
              />
            )}
          />
        );
      case VarType.DATE:
        return (
          <DateSelector
            name={`custom_variables.${customVarKey}.value`}
            className={styles('input')}
            noMinDate
            withBorder
            placeholder=" "
            label={customVarKey.replaceAll('_', ' ')}
            handleChange={(value) => {
              setEdit(customVarKey);
              setValue(`custom_variables.${customVarKey}.value` as any, value);
            }}
          />
        );
      default:
        return (
          <Input
            register={register(`custom_variables.${customVarKey}.value` as any)}
            withBorder
            label={customVarKey.replaceAll('_', ' ')}
            className={styles('input')}
            type={varType === VarType.NUMBER ? 'number' : 'text'}
            step="0.01"
            noMargin
            onValueChanged={() => setEdit(customVarKey)}
          />
        );
    }
  };

  const renderInput = (customVarKey) => {
    const varType = availableCustomVariables!.find(
      (el) => el.column_name === customVarKey,
    )?.var_type;
    return (
      <div key={customVarKey} className={styles('content')}>
        <Checkbox
          watch={watch}
          register={register(`custom_variables.${customVarKey}.edit` as any)}
          onChange={(value) => resetValue(value, customVarKey)}
        />
        {accurateInput(customVarKey, varType)}
      </div>
    );
  };

  return (
    <FormProvider {...form}>
      <form className={styles('custom-variables-batch-form')} onSubmit={handleSubmit(submit)}>
        <div className={styles('form-wrapper')}>
          {resourceVariables.map(({ column_name }) => renderInput(column_name))}
        </div>
        <div className={styles('button-wrapper')}>
          <Button isLoading={isLoading} noMargin label={t(i18nKeys.SAVE)} type="submit" />
        </div>
      </form>
    </FormProvider>
  );
}
