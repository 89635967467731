import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect, Input } from 'shared/io';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './addressForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues?: any;
};

export default function AddressForm({ onSubmit, initialValues }: Props) {
  const { t, currentLang } = useTranslation();

  const form = useForm({
    defaultValues: initialValues,
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = form;

  const countriesForSelect = Object.entries(countries.getNames(currentLang)).map(
    ([value, description]) => ({ value, description }),
  );

  return (
    <FormProvider {...form}>
      <form className={styles('AddressForm')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('content')}>
          <div className={styles('grid-row')}>
            <div className={styles('col-12')}>
              <Input
                register={register('additional_line_1', { maxLength: 35 })}
                className={styles('input')}
                withBorder
                maxLength={35}
                errorMessage={errors.additional_line_1}
                type="text"
                label={t(i18nKeys.ADDITIONAL_LINE_1)}
              />
            </div>
            <div className={styles('col-12')}>
              <Input
                register={register('additional_line_2', { maxLength: 35 })}
                errorMessage={errors.additional_line_2}
                className={styles('input')}
                withBorder
                type="text"
                label={t(i18nKeys.ADDITIONAL_LINE_2)}
              />
            </div>
            <div className={styles('col-6')}>
              <Input
                register={register('street_name', { required: true })}
                errorMessage={errors.street_name}
                className={styles('input')}
                withBorder
                label={t(i18nKeys.FORM.ADDRESS.STREET)}
              />
            </div>
            <div className={styles('col-3')}>
              <Input
                register={register('street_number', { required: true })}
                errorMessage={errors.street_number}
                className={styles('input')}
                withBorder
                label={t(i18nKeys.NUMBER)}
                noErrorText
              />
            </div>
            <div className={styles('col-3')}>
              <Input
                register={register('street_box')}
                className={styles('input')}
                withBorder
                label={t(i18nKeys.FORM.ADDRESS.BOX)}
                noErrorText
              />
            </div>
            <div className={styles('col-6')}>
              <Input
                register={register('zip_code', { required: true })}
                errorMessage={errors.zip_code}
                className={styles('input')}
                withBorder
                label={t(i18nKeys.FORM.ADDRESS.POSTAL_CODE)}
              />
            </div>
            <div className={styles('col-6')}>
              <Input
                register={register('city', { required: true })}
                errorMessage={errors.city}
                className={styles('input')}
                withBorder
                label={t(i18nKeys.FORM.ADDRESS.CITY)}
              />
            </div>
            <div className={styles('col-12')}>
              <Controller
                defaultValue=""
                name="country_code"
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    className={styles('input')}
                    keyText="description"
                    name="country_code"
                    keyValue="value"
                    withBorder
                    filter
                    items={countriesForSelect}
                    label={t(i18nKeys.FORM.ADDRESS.COUNTRY)}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button label={t(i18nKeys.ACTIONS.ADD)} type="submit" />
        </div>
      </form>
    </FormProvider>
  );
}
