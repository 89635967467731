import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getInvoiceFormValues, InvoiceForm } from 'shared/forms/InvoiceForm';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './manage.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function ManageInvoice({ match: { params } }: RouteComponentProps<{ id?: string }>) {
  const invoice = useSelector((state: StoreState) => state.invoice.current?.data || undefined);

  useEffect(() => {
    if (params.id) {
      invoiceActions.detail({ id: Number(params.id) });
    }
  }, [params]);

  if (!params.id || !invoice) {
    return (
      <div className={styles('ManageCreditNote')}>
        <InvoiceForm
          initialValues={getInvoiceFormValues()}
          invoice={invoice}
          submit={(data) => invoiceActions.add({ data })}
        />
      </div>
    );
  }

  return (
    <div className={styles('ManageInvoice')}>
      <InvoiceForm
        initialValues={getInvoiceFormValues(invoice)}
        invoice={invoice}
        submit={(data) =>
          invoiceActions.update({
            id: invoice.id,
            data,
          })
        }
      />
    </div>
  );
}
