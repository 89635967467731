import { ChangeEvent, PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import styleIdentifiers from './InfiniteScroll.module.scss';

const styles = classNames.bind(styleIdentifiers);

// The scrolltop value is sometimes a decimal and alternatively needs to be rounded
// up or down to match scrollheight. We check against a tolerance to account for this.
// Increase tolerance if you find that the load function is not being called while the
// scrollarea is maxed out.
function isWithinToleranceOf(value, target, tolerance): boolean {
  return Math.abs(value - target) <= tolerance;
}

type Props = {
  className?: string;
  fwRef?: any;
  load: Function;
};

export function InfiniteScrollCustom({
  fwRef,
  children,
  className,
  load,
}: PropsWithChildren<Props>) {
  const innerRef = useRef(null);
  const listRef = fwRef ?? innerRef;

  const isElementAtBottom = (element: ChangeEvent) => {
    const { scrollTop, clientHeight, scrollHeight } = element.target;
    return isWithinToleranceOf(scrollTop + clientHeight, scrollHeight, 3);
  };

  const setScrollTop = useCallback(
    (target: ChangeEvent) => {
      if (isElementAtBottom(target)) load();
    },
    [load],
  );

  useEffect(() => {
    listRef.current.addEventListener('scroll', setScrollTop);

    const node = listRef.current;
    return () => node.removeEventListener('scroll', setScrollTop);
  }, [listRef, setScrollTop]);

  return (
    <div ref={listRef} className={styles(className, 'infinite-scroll')}>
      {children}
    </div>
  );
}
