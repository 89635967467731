import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon, IconName } from 'shared/components/Icon';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { OnClickFunction } from 'types/html-type';
import { CreditNote } from 'types/store/credit-note-state';
import { Invoice } from 'types/store/invoice-state';

import styleIdentifiers from './downloadInvoice.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  className?: string;
  ubl?: boolean;
  invoice?: Partial<Invoice> | Partial<CreditNote>;
  text?: boolean;
  importOriginalInvoice?: OnClickFunction;
};

export default function DownloadInvoice({
  className,
  invoice,
  ubl,
  text,
  importOriginalInvoice,
}: Props) {
  const { t } = useTranslation();

  if (!invoice?.id) {
    return null;
  }

  const { id, has_pdf: hasPdf } = invoice;

  const handleDownload = (type) => () => {
    invoiceActions.document({
      id,
      type: type === 'ubl' ? 'invoice_ubl' : 'invoice_pdf',
    });
  };

  return (
    <div className={styles('DownloadInvoice', className)}>
      {(hasPdf || importOriginalInvoice) && (
        <Icon
          title={t(i18nKeys.INVOICE.DOWNLOAD_PDF)}
          onClick={importOriginalInvoice || handleDownload('pdf')}
          name={IconName.PDF}
          className={styles('icon')}
        />
      )}
      {text && (
        <span onClick={handleDownload('pdf')} className={styles('text')}>
          {t(i18nKeys.INVOICE.DOWNLOAD_PDF)}
        </span>
      )}
      {ubl && (
        <Icon
          name={IconName.XML}
          onClick={handleDownload('ubl')}
          className={styles('xml', 'icon')}
        />
      )}
    </div>
  );
}
