import { useDeleteReason, useGetReasons, useUpdateReason } from 'api';
import { CreateReasonBody, Reason } from 'api/models';
import { ReasonForm } from 'app/Private/Reasons/ReasonForm/ReasonForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';
import { TableItemProps } from 'types/table';

import styleIdentifiers from './ReasonListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ReasonListItemProps = TableItemProps<Reason>;

export const ReasonListItem = ({ item }: ReasonListItemProps) => {
  const { t } = useTranslation();

  const { reason_types } = useSelector((state: StoreState) => state.app.constants);

  const { refetch } = useGetReasons();

  const { mutate: deleteReason } = useDeleteReason();
  const { mutate: updateReason } = useUpdateReason();

  const {
    id,
    attributes: { name, reason_type, is_deletable },
  } = item;

  const handleUpdate = () =>
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(item ? i18nKeys.EDIT_TASK_TYPE : i18nKeys.ADD_TASK_TYPE),
      children: (
        <ReasonForm
          reason={item}
          onCancel={() => dialogHide(DialogShowId.CUSTOM)}
          onSubmit={(data: CreateReasonBody) =>
            updateReason(
              { id, data },
              {
                onSuccess: () => {
                  refetch();
                  dialogHide(DialogShowId.CUSTOM);
                },
              },
            )
          }
        />
      ),
    });

  const handleDelete = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_REASON)}
          onConfirm={() => {
            deleteReason(
              { id },
              {
                onSuccess: () => {
                  refetch();
                  dialogHide(DialogShowId.CONFIRM);
                },
              },
            );
          }}
        />
      ),
    });

  return (
    <tr className={styles('reason-item')}>
      <td />
      <td>{id}</td>
      <td>{name}</td>
      <td>{reason_types.find((reasonType) => reasonType.value === reason_type)?.description}</td>
      <td>
        {t(
          !is_deletable
            ? i18nKeys.SETTINGS.TASKS.TASK_DEFAULT
            : i18nKeys.SETTINGS.TASKS.TASK_CUSTOM,
        )}
      </td>
      <td className={styles('actions')}>
        {is_deletable && (
          <>
            <span onClick={handleUpdate}>
              <Icon name={IconName.PENCIL} size="18px" className={styles('action')} />
            </span>
            <span onClick={handleDelete}>
              <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
            </span>
          </>
        )}
      </td>
    </tr>
  );
};
