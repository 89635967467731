import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const PausedInvoicesSchema = z
  .object({
    totalInvoicesPaused: z.preprocess((val) => Number(val), z.number()),
  })
  .transform(({ totalInvoicesPaused }) => totalInvoicesPaused);

export type PausedInvoices = z.infer<typeof PausedInvoicesSchema>;

export async function loadPausedInvoicesFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('analytics/paused_invoices');
  return PausedInvoicesSchema.parse(data);
}

export function useLoadPausedInvoices() {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<PausedInvoices, ApiError>({
    queryKey: ['paused-invoices'],
    queryFn: () => loadPausedInvoicesFn(axiosInstance),
  });

  return addResourceNameToQueryResult<PausedInvoices, ApiError, 'pausedInvoices'>(
    'pausedInvoices',
    queryResult,
  );
}
