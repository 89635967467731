import React from 'react';
import { SendingMedium } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { CustomSelect } from 'shared/io';
import { StoreState } from 'types/storeTypes';

import { getDescription } from './SendingMedia.utils';

import styleIdentifiers from './SendingMedia.module.scss';

const styles = classNames.bind(styleIdentifiers);

type SendingMediaProps = {
  fieldName: string;
  className: string;
  noMargin?: boolean;
  withBorder?: boolean;
  required?: boolean;
};

export default function SendingMedia({
  fieldName,
  className,
  noMargin = true,
  withBorder = false,
  required = true,
}: SendingMediaProps) {
  const { sending_medium } = useSelector((state: StoreState) => state.app.constants);
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const deleteLine = (currentValues: SendingMedium[], index: number) => {
    setValue(
      fieldName,
      currentValues.filter((e, i) => i !== index),
      {
        shouldTouch: true,
      },
    );
  };

  const onSetMedium = (currentValues: SendingMedium[], value: string, index: number) => {
    setValue(
      fieldName,
      currentValues.map((e, i) =>
        i === index
          ? {
              ...e,
              name: value,
            }
          : e,
      ),
      {
        shouldTouch: true,
      },
    );
  };

  const onSetMediumForceSending = (
    currentValues: SendingMedium[],
    value: boolean,
    index: number,
  ) => {
    setValue(
      fieldName,
      currentValues.map((e, i) =>
        i === index
          ? {
              ...e,
              force_sending: value,
            }
          : e,
      ),
      {
        shouldTouch: true,
      },
    );
  };

  const values = watch(fieldName) || [];

  return (
    <Controller
      defaultValue={[]}
      name={fieldName}
      rules={{ required }}
      render={() => {
        const forceSendingValues = values.filter(
          (medium: SendingMedium) => String(medium.force_sending) === 'true',
        );
        const sendingValues = values.filter(
          (medium: SendingMedium) => String(medium.force_sending) === 'false',
        );

        return (
          <div className={styles('SendingMedia')}>
            {!!values.length && (
              <div className={styles('description')}>
                {`${getDescription(forceSendingValues, t)} ${t(
                  forceSendingValues.length > 1
                    ? i18nKeys.WILL_BE_SENT.MULTIPLE
                    : i18nKeys.WILL_BE_SENT.SINGLE,
                )}.`}
                {!!sendingValues.length && (
                  <div>
                    {`${t(i18nKeys.IF_NOT)} ${getDescription(sendingValues, t).toLowerCase()} ${t(
                      sendingValues.length > 1
                        ? i18nKeys.WILL_BE_SENT.MULTIPLE
                        : i18nKeys.WILL_BE_SENT.SINGLE,
                    )}.`}
                  </div>
                )}
              </div>
            )}
            {values.map((item: SendingMedium, index: number) => {
              const { name } = item;
              const selectName = `${name}-${index}`;

              const availableMedium = sending_medium.filter(
                (medium) =>
                  !values.find(
                    (e: SendingMedium) => e.name === medium.value && e.name !== item.name,
                  ),
              );

              return (
                <React.Fragment key={selectName}>
                  {index > 0 && (
                    <div className={styles('field-wrapper', 'force')}>
                      <CustomSelect
                        name={`${fieldName}.${index}.force_sending`}
                        keyText="description"
                        keyValue="value"
                        items={[
                          { description: t(i18nKeys.AND), value: 'true' },
                          { description: t(i18nKeys.OR), value: 'false' },
                        ]}
                        selectClassName={className}
                        withBorder={withBorder}
                        noMargin={noMargin}
                        canUnselect={false}
                        onValueChanged={(value) => {
                          onSetMediumForceSending(values, value, index);
                        }}
                      />
                    </div>
                  )}
                  <div className={styles('field-wrapper')}>
                    <CustomSelect
                      label={t(i18nKeys.DEBTOR.SENDING_MEDIUM)}
                      withBorder={withBorder}
                      keyText="description"
                      keyValue="value"
                      name={`${fieldName}.${index}.name`}
                      items={availableMedium}
                      selectClassName={className}
                      noMargin={noMargin}
                      canUnselect={index !== 0}
                      onValueChanged={(value) => {
                        if (!value) {
                          deleteLine(values, index);
                        } else {
                          onSetMedium(values, value || item.name, index);
                        }
                      }}
                    />

                    <Icon
                      name={IconName.TRASH_SIMPLE}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteLine(values, index);
                      }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
            {errors[fieldName] && (
              <div className={styles('error-message', 'error')}>
                {t(i18nKeys.FORM.CHOOSE_MEDIA)}
              </div>
            )}
          </div>
        );
      }}
    />
  );
}
