import { useTranslation } from 'locales';
import { isDefined } from 'remeda';
import DateItem from 'shared/components/DateItem';

import { ActionIcon, Box, Tooltip, useMantineTheme } from '@mantine/core';
import { captureException } from '@sentry/react';
import { IconGavel, IconPhoneCall, IconScale } from '@tabler/icons-react';

import debtCollectorIcon from '../../../shared/components/InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';
import { KanbanInvoice } from '../api/use-load-invoice-column';

interface ThirdPartyIconProps {
  invoice: KanbanInvoice;
}

export const ThirdpartyIcon = ({ invoice }: ThirdPartyIconProps) => {
  const { currentLang } = useTranslation();
  const theme = useMantineTheme();

  if (!isDefined(invoice.thirdPartyCase)) return null;

  const { type, description, date } = invoice.thirdPartyCase;

  const ICON_PROPS = { size: 18, stroke: 1.3, color: theme.colors.gray[7] };

  const icon = (() => {
    switch (type) {
      case 'callcenter':
        return <IconPhoneCall style={{ width: '70%', height: '70%' }} {...ICON_PROPS} />;
      case 'lawyer':
        return <IconGavel style={{ width: '70%', height: '70%' }} {...ICON_PROPS} />;
      case 'bailiff':
        return <IconScale style={{ width: '70%', height: '70%' }} {...ICON_PROPS} />;
      case 'debt_collector':
        return <img width="70%" height="70%" src={debtCollectorIcon} alt="Debt collector" />;
      default:
        captureException('Encountered unknown third party case type', { extra: { invoice } });
        return null;
    }
  })();

  return (
    <Tooltip
      withArrow
      label={
        <>
          {description} <DateItem lg={currentLang} date={new Date(date)} />
        </>
      }
    >
      <Box>
        <ActionIcon size="sm" variant="default" style={{ pointerEvents: 'none' }}>
          {icon}
        </ActionIcon>
      </Box>
    </Tooltip>
  );
};
