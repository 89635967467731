import { AxiosInstance } from 'axios';
import { Id } from 'types';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import { addActionNameToMutationResult, ApiError, useLegacyAxiosInstance } from './utils';

export const SendToThirdPartyVariablesSchema = z.object({
  third_party_company_id: z.number(),
  additional_invoices: z.array(z.number()),
  ape_code: z.string(),
  siren: z.string(),
});

const SendToThirdPartyResponseSchema = z.object({
  message: z.string(),
  third_party_case_ids: z.array(z.number()),
});

export type SendToThirdPartyVariables = z.infer<typeof SendToThirdPartyVariablesSchema>;
export type SendToThirdPartyResponse = z.infer<typeof SendToThirdPartyResponseSchema>;

export async function sendToThirdPartyFn(
  axiosInstance: Promise<AxiosInstance>,
  { id, data }: { id: Id; data: SendToThirdPartyVariables },
) {
  const instance = await axiosInstance;
  const res = await instance.post<SendToThirdPartyResponse>(
    `invoices/${id}/send_to_third_party`,
    SendToThirdPartyVariablesSchema.parse(data),
  );
  return SendToThirdPartyResponseSchema.parse(res.data);
}

export function useSendToThirdParty() {
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation<
    SendToThirdPartyResponse,
    ApiError,
    { id: Id; data: SendToThirdPartyVariables }
  >({
    mutationFn: (variables) => sendToThirdPartyFn(axiosInstance, variables),
    onError: (error: ApiError) => {
      notifications.show({
        title: 'Error',
        color: 'red',
        message: error.response?.data.error_message,
      });
    },
  });

  return addActionNameToMutationResult<
    SendToThirdPartyResponse,
    ApiError,
    { id: Id; data: SendToThirdPartyVariables },
    'sendToThirdParty'
  >('sendToThirdParty', mutation);
}
