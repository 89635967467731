import {
  BaseApiActionsType,
  buildActions,
  makeReqCons,
  makeResourceConstants,
} from 'store/actions';
import { ReduxFunction } from 'types/redux';

const prefix = 'credit_note';

export const creditNoteConstants = {
  ...makeResourceConstants(prefix),
  get: makeReqCons(`${prefix}_GET`),
  search: makeReqCons(`${prefix}_SEARCH`),
  invoiceSearch: makeReqCons(`${prefix}_INVOICE_SEARCH`),
  send: makeReqCons(`${prefix}_SEND`),
  info: makeReqCons(`${prefix}_info`),
  copy: makeReqCons(`${prefix}_copy`),
  settle: makeReqCons(`${prefix}_settle`),
  activity: makeReqCons(`${prefix}_activity`),
  updateOriginalFile: makeReqCons(`${prefix}_UPDATE_ORIGINAL_FILE`),
  batchAction: makeReqCons(`${prefix}_BATCH_ACTION`),
  linkToInvoices: makeReqCons(`${prefix}_LINK_TO_INVOICES`),
  unlinkToInvoices: makeReqCons(`${prefix}_UNLINK_TO_INVOICES`),
};

type CreditNoteActions = BaseApiActionsType & {
  get: ReduxFunction;
  getRes: Function;
  search: Function;
  searchRes: Function;
  invoiceSearch: Function;
  invoiceSearchRes: Function;
  send: Function;
  sendRes: Function;
  info: Function;
  infoRes: Function;
  copy: Function;
  copyRes: Function;
  settle: Function;
  settleRes: Function;
  activity: Function;
  activityRes: Function;
  updateOriginalFile: ReduxFunction;
  updateOriginalFileRes: Function;
  batchAction: ReduxFunction;
  batchActionRes: Function;
  linkToInvoices: ReduxFunction;
  linkToInvoicesRes: Function;
  unlinkToInvoices: ReduxFunction;
  unlinkToInvoicesRes: Function;
};

const creditNoteActions = buildActions<CreditNoteActions>('', creditNoteConstants, {});

export { creditNoteActions };
