import { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { isDefined, isNullish } from 'remeda';
import {
  AgedBalanceOverTime,
  useLoadAgedBalance,
  useLoadAgedBalanceCompact,
  useLoadAgedBalanceOverTime,
  useLoadViewsV2,
} from 'shared/hooks';
import { PageTitle } from 'shared/layout';

import { ActionIcon, Card, Group, LoadingOverlay, Select, Space, Stack } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconArrowLeft } from '@tabler/icons-react';

import { UpdatedAt } from '../components';
import {
  AgedBalanceCompactChart,
  AgedBalanceOverTimeChart,
  AgedBalanceTable,
} from '../components/charts';

export const AgedBalance = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [selectedViewId, setSelectedViewId] = useLocalStorage<string | null>({
    key: 'aged-balance-view',
    defaultValue: null,
  });

  const { views: debtorViews } = useLoadViewsV2({
    resourceType: 'debtors',
    onSuccess: (views) => {
      if (isNullish(selectedViewId)) setSelectedViewId(views[0].id);
    },
  });

  useEffect(() => {
    if (isDefined(debtorViews) && isNullish(selectedViewId)) setSelectedViewId(debtorViews[0].id);
  }, [debtorViews]);

  const { agedBalanceCompact, isAgedBalanceCompactLoading } = useLoadAgedBalanceCompact({
    viewId: selectedViewId,
  });

  const { agedBalance, isAgedBalanceLoading } = useLoadAgedBalance({
    viewId: selectedViewId,
  });

  const { agedBalanceOverTime, isAgedBalanceOverTimeLoading } = useLoadAgedBalanceOverTime({
    viewId: selectedViewId,
  });

  const [agedBalanceVersion, setAgedBalanceVersion] = useState<'compact' | 'overTime'>('overTime');

  // If there are too few dates for the balance over time to display nicely, fallback to compact balance
  useEffect(() => {
    if (!isAgedBalanceOverTimeLoading && agedBalanceOverTime != null) {
      dispatchChartComponent(agedBalanceOverTime);
    }
  }, [isAgedBalanceOverTimeLoading, agedBalanceOverTime]);

  const dispatchChartComponent = (_agedBalanceOverTime: AgedBalanceOverTime) => {
    const amountOfDatesPresent = Object.keys(_agedBalanceOverTime.data).length;
    const shouldFallbackToCompactBalance = amountOfDatesPresent < 2;
    if (shouldFallbackToCompactBalance) setAgedBalanceVersion('compact');
  };

  const [isLoading, chartComponent] =
    agedBalanceVersion === 'compact'
      ? [
          isAgedBalanceCompactLoading,
          <AgedBalanceCompactChart agedBalanceCompact={agedBalanceCompact} />,
        ]
      : [
          isAgedBalanceOverTimeLoading,
          <AgedBalanceOverTimeChart agedBalanceOverTime={agedBalanceOverTime} />,
        ];

  return (
    <div>
      <PageTitle>
        <Group align="center" justify="center">
          {/* TODO extract to a generic back button component */}
          <ActionIcon onClick={() => history.push('/analytics')} size="xl" variant="light">
            <IconArrowLeft />
          </ActionIcon>
          <Stack gap={0}>
            {t(i18nKeys.ANALYTICS.AGED_BALANCE.TITLE)}
            <UpdatedAt />
          </Stack>
        </Group>
        <PageTitle.Actions>
          <Select
            label={t(i18nKeys.FILTER)}
            onChange={setSelectedViewId}
            allowDeselect={false}
            value={selectedViewId}
            data={debtorViews?.map(({ id, name }) => ({ label: name, value: id })) ?? []}
          />
        </PageTitle.Actions>
      </PageTitle>
      <Space h="md" />
      <Card radius="md" shadow="sm" style={{ overflow: 'visible' }}>
        <LoadingOverlay visible={isLoading} />
        {chartComponent}
      </Card>
      <AgedBalanceTable agedBalance={agedBalance} isLoading={isAgedBalanceLoading} />
    </div>
  );
};
