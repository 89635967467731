import { memo } from 'react';
import { times } from 'remeda';
import { PageTitle } from 'shared/layout';

import { Box, Group, Skeleton, Stack } from '@mantine/core';

import { Kanban } from '.';

interface KanbanLoadingStateProps {
  columnCount: number;
  title: string;
}

export const KanbanLoadingState = memo(({ columnCount, title }: KanbanLoadingStateProps) => {
  const cols = times(columnCount, (index) => {
    const cardAmount = Math.round(Math.random() * 15) + 4;
    return (
      <Kanban.Column key={index} color="gray" title={<Skeleton width="100%" h="40px" />}>
        {times(cardAmount, (cardIndex) => (
          <Kanban.Card key={cardIndex}>
            <Stack justify="space-between" h={90}>
              <Skeleton h={15} w="80%" />
              <Group justify="space-between">
                <Skeleton h={10} w={50} />
                <Skeleton h={10} w={40} />
              </Group>
            </Stack>
          </Kanban.Card>
        ))}
      </Kanban.Column>
    );
  });

  return (
    <>
      {/* Fixed height necessary to constrain Kanban columns, This is the natural height of the component without actions */}
      <Box h="60px">
        <PageTitle>
          {title}
          <PageTitle.Actions>
            <Skeleton width={100} h={30} />
          </PageTitle.Actions>
        </PageTitle>
      </Box>
      <Box h="calc(100% - 60px)">
        <Kanban>{cols}</Kanban>
      </Box>
    </>
  );
});
