/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type Notification = (typeof Notification)[keyof typeof Notification];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Notification = {
  invoice_sending: 'invoice_sending',
  reminders: 'reminders',
  formal_notice: 'formal_notice',
  legal_case: 'legal_case',
  Debtor: 'Debtor',
} as const;
