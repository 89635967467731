import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { CreateReportSchema } from './use-create-report';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

const UpdateReportSchema = z.object({
  id: z.string(),
  name: CreateReportSchema.shape.name.optional(),
  emails: CreateReportSchema.shape.emails.optional(),
  periodicity: CreateReportSchema.shape.periodicity.optional(),
});

type UpdateReportVariables = z.infer<typeof UpdateReportSchema>;

export async function updateReportFn(
  axiosInstance: Promise<AxiosInstance>,
  data: UpdateReportVariables,
) {
  const instance = await axiosInstance;
  return instance.put(`/reports/export_tasks/${data.id}`, data);
}

export const useUpdateReport = () => {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, UpdateReportVariables>({
    mutationFn: (variables) => updateReportFn(axiosInstance, variables),
    onSuccess: (response, { id }) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['reports'],
      });
      client.invalidateQueries({
        queryKey: ['report', id],
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    UpdateReportVariables,
    'updateReport'
  >('updateReport', mutation);
};
