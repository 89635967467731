import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller } from 'react-hook-form';
import UserInfo from 'shared/components/UserInfo';
import { useLoadLightUsers } from 'shared/hooks';
import { CustomSelect } from 'shared/io';

import styleIdentifiers from './TaskForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskFormColleagueProps = {
  name?: 'user_id' | 'task_attributes.user_id';
  disabled?: boolean;
};

export const TaskFormColleague = ({
  name = 'user_id',
  disabled = false,
}: TaskFormColleagueProps) => {
  const { t } = useTranslation();
  const { users = [] } = useLoadLightUsers();

  const colleaguesOrAccountManager =
    name === 'task_attributes.user_id'
      ? [
          {
            name: {
              full: t(i18nKeys.ACCOUNT_MANAGER),
              first: t(i18nKeys.ACCOUNT_MANAGER),
              last: '',
              initials: t(i18nKeys.ACCOUNT_MANAGER)[0].toUpperCase(),
            },
            email: '-',
            id: 'auto',
            isActive: true,
            profilePicture: null,
          },
          ...users,
        ]
      : users;

  return (
    <Controller
      name={name}
      rules={{ required: true }}
      render={() => (
        <CustomSelect
          name={name}
          keyValue="id"
          label={t(i18nKeys.TASK.ATTRIBUTES.ASSIGNEE)}
          items={colleaguesOrAccountManager}
          selectClassName={styles('input')}
          itemRendering={(props) => <UserInfo picture {...props} />}
          valueRendering={(props) => <UserInfo inline {...props} />}
          notAllowed={disabled}
          withBorder
        />
      )}
    />
  );
};
