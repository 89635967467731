import { i18nKeys, useTranslation } from 'locales';
import { useLoadCei } from 'shared/hooks';

import { Box, LoadingOverlay, Text } from '@mantine/core';

import { AnalyticsCard } from '../AnalyticsCard';
import { CeiChart } from '../charts';

export const CeiCard = () => {
  const { t } = useTranslation();
  const { cei = {}, isCeiLoading } = useLoadCei();

  return (
    <AnalyticsCard title={t(i18nKeys.ANALYTICS.CEI.TITLE)} helpContent={<CeiHelpContent />}>
      <Box w="100%" h="400px">
        {isCeiLoading ? <LoadingOverlay visible /> : <CeiChart cei={cei} />}
      </Box>
    </AnalyticsCard>
  );
};

const CeiHelpContent = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text fw="bold">{t(i18nKeys.ANALYTICS.CEI.HELP.TITLE)}</Text>
      <br />
      <Text>{t(i18nKeys.ANALYTICS.CEI.HELP.LINE_1)}</Text>
      <br />
      <Text>{t(i18nKeys.ANALYTICS.CEI.HELP.LINE_2)}</Text>
    </Box>
  );
};
