export enum VarType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean',
}

export enum ModelType {
  INVOICE = 'invoice',
  DEBTOR = 'debtor',
}

export enum DisplayType {
  MONEY = 'money',
  PERCENTAGE = 'percentage',
}
