import { useSearchInvoices } from 'api';
import { CreatePaymentStepCalculationBody, PaymentStepCalculationResponse } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import InvoiceReferences from 'shared/components/InvoiceReferences/InvoiceReferences';
import UsedWorkflow from 'shared/components/UsedWorkflow/UsedWorkflow';
import { Button } from 'shared/io';
import { formattedDate } from 'shared/utils/view';

import styleIdentifiers from './PaymentPlanForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type PaymentPlanFormStepsPropertiesProps = {
  recalculateSteps: Function;
  paymentProperties: CreatePaymentStepCalculationBody;
  paymentSteps: PaymentStepCalculationResponse;
};

export const PaymentPlanFormStepsProperties = ({
  recalculateSteps,
  paymentProperties,
  paymentSteps,
}: PaymentPlanFormStepsPropertiesProps) => {
  const { t, currentLang } = useTranslation();

  const { invoice_ids, due_date, delay, with_fees, workflow_id } = paymentProperties;
  const { total_amount, debtor } = paymentSteps;

  const { data: invoicesResponse } = useSearchInvoices({
    debtor_id: debtor.id,
  });
  const filteredInvoices = invoicesResponse?.data.filter((invoice) =>
    invoice_ids.includes(invoice.id),
  );

  const invoiceIds = filteredInvoices?.map(({ id }) => id) || [];

  const {
    attributes: { full_name },
  } = debtor;

  return (
    <Card
      title={t(i18nKeys.DETAILS)}
      infosRight={
        <Button
          small
          noMargin
          onClick={() => recalculateSteps()}
          label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.RECALCULATE)}
        />
      }
    >
      <div className={styles('details')}>
        <div className="left">
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.COMMON.CLIENT)}</span>
            <span className={styles('value')}>{full_name}</span>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.INVOICES)}</span>
            <span className={styles('value')}>
              <InvoiceReferences ids={invoiceIds} />
            </span>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.WITH_FEES)}</span>
            <span className={styles('value')}>{t(i18nKeys[with_fees ? 'YES' : 'NO'])}</span>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>
              {t(i18nKeys.FORM.PAYMENT_PLAN.USED_WORKFLOW)}
            </span>
            <span className={styles('value')}>
              {workflow_id ? <UsedWorkflow workflowId={workflow_id} /> : t(i18nKeys.DEFAULT)}
            </span>
          </div>
        </div>
        <div className="right">
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>
              {t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DUE_DATE)}
            </span>
            <span className={styles('value')}>{formattedDate(due_date, currentLang)}</span>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>
              {t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DELAY)}
            </span>
            <span className={styles('value')}>{t(i18nKeys.DAYS, { count: delay })}</span>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.TOTAL)}</span>
            <span className={styles('value')}>
              <Amount value={total_amount} suffix={debtor.attributes.currency} />
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};
