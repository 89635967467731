import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import InvoiceSettingsForm from 'shared/forms/InvoiceSettingsForm';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';
import { StoreState } from 'types/storeTypes';

export type InvoiceSettingsProps = {
  companyData: Object;
  updateCompany: Function;
};

export const InvoiceSettings = () => {
  const { t } = useTranslation();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const handleUpdate = (callback) => (values) => {
    values.billing_logs_attributes =
      values.billing_logs_attributes?.map((billing_log) => {
        if (!billing_log.id) delete billing_log.id;
        return billing_log;
      }) ?? [];

    accountActions.updateInvoiceConfigurations({
      data: values,
      callback,
    });
  };

  const initialValues = {
    color: company.invoice_customization.color,
    billing_logs_attributes: company.invoice_customization.billing_logs,
    logo: company.invoice_customization.logo_url,
    sending_media: company.sending_media_attributes.map((medium) => ({
      ...medium,
      force_sending: medium.force_sending,
    })),
    generate_invoice_in_debtor_locale: company.generate_invoice_in_debtor_locale.toString(),
    default_credit_note_template_id: company.default_templates.credit_note,
    default_invoice_template_id: company.default_templates.invoice,
    display_qr_code: company.display_qr_code.toString(),
  };

  return (
    <>
      <PageTitle>{t(i18nKeys.SETTINGS.INVOICING.TITLE)}</PageTitle>
      <InvoiceSettingsForm onSubmit={handleUpdate} initialValues={initialValues} />
    </>
  );
};
