import { AxiosInstance } from 'axios';
import * as R from 'remeda';
import { omit } from 'remeda';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const thirdPartyCompanySchema = z.object({
  id: z.number(),
  name: z.string().min(1),
});

// Debt collectors are a special case, because they have a boolean field `gcollect`; other than that they
// should be identical to any other third party company
const debtCollectorCompanySchema = z
  .object({
    id: z.number(),
    name: z.string().min(1),
    gcollect: z.boolean(),
  })
  .transform((debtCollector) => ({
    ...omit(debtCollector, ['gcollect']),
    isGcollect: debtCollector.gcollect,
  }));

const debtCollectorSchema = z
  .object({
    isAllowed: z.boolean(),
    availableCompanies: z.array(debtCollectorCompanySchema),
  })
  .transform((category) => ({
    ...category,
    canBeAccessed: category.isAllowed && category.availableCompanies.length > 0,
  }));

const thirdPartyCategorySchema = z
  .object({
    isAllowed: z
      .boolean()
      .describe('Whether or not the status of the invoice permits using this type of third party'),
    availableCompanies: z
      .array(thirdPartyCompanySchema)
      .describe(
        'The array of third parties of this type that are available for the debtor/invoice country',
      ),
  })
  .transform((category) => ({
    ...category,
    canBeAccessed: category.isAllowed && category.availableCompanies.length > 0,
  }));

export enum ThirdPartyType {
  BAILIFF = 'bailiff',
  CALL_CENTER = 'callcenter',
  DEBT_COLLECTOR = 'debtCollector',
  LAWYER = 'lawyer',
}

const thirdPartiesSchema = z
  .object({
    [ThirdPartyType.BAILIFF]: thirdPartyCategorySchema,
    [ThirdPartyType.CALL_CENTER]: thirdPartyCategorySchema,
    [ThirdPartyType.LAWYER]: thirdPartyCategorySchema,
    [ThirdPartyType.DEBT_COLLECTOR]: debtCollectorSchema,
  })
  .transform((thirdParties) => ({
    ...thirdParties,
    areAnyThirdPartiesAccessible: R.pipe(
      thirdParties,
      Object.values,
      R.map(R.prop('canBeAccessed')),
      (arr) => arr.some((val) => val),
    ),
  }));

export type ThirdParties = z.infer<typeof thirdPartiesSchema>;

type IdOrIds =
  | { id: number | undefined; ids?: never }
  | { ids: Array<number> | undefined; id?: never };
type LoadThirdPartiesVariables = { enabled?: boolean } & IdOrIds;

export async function loadThirdPartiesQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get('third_party_companies', {
    params: {
      [Array.isArray(queryKey[1]) ? 'invoice_ids' : 'invoice_id']: queryKey[1],
    },
  });
  return thirdPartiesSchema.parse(data);
}

export function useLoadThirdParties({ enabled = true, id, ids }: LoadThirdPartiesVariables) {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<ThirdParties, ApiError>({
    enabled,
    queryKey: ['thirdParty', id ?? ids],
    queryFn: (context) => loadThirdPartiesQueryFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<ThirdParties, unknown, 'thirdParties'>(
    'thirdParties',
    queryResult,
  );
}
