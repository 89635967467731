import { useEffect } from 'react';
import { isNullish } from 'remeda';

import { Box, Checkbox } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';

import { i18nKeys, useTranslation } from '../../../../locales';
import {
  UpdateTaskForm,
  UpdateTaskFormSchema,
  useUpdateTaskSettings,
} from '../../../../shared/hooks';
import { useGetCompany } from '../../../../shared/utils/selectors';
import { MantineSettingsCard } from '../MantineSettingsCard';

export function TaskSettings() {
  const { t } = useTranslation();
  const company = useGetCompany();

  const { updateTaskSetting } = useUpdateTaskSettings();
  const form = useForm<UpdateTaskForm>({
    validate: zodResolver(UpdateTaskFormSchema),
    validateInputOnBlur: true,
  });

  useEffect(() => {
    if (isNullish(company)) return;

    form.setInitialValues({ autoclose_tasks: company.autoclose_tasks });
    form.setValues({ autoclose_tasks: company.autoclose_tasks });
  }, [company?.autoclose_tasks]);

  const handleSave = () => {
    updateTaskSetting(form.getValues());
  };

  return (
    <MantineSettingsCard
      onSave={form.isDirty() ? handleSave : undefined}
      title={t(i18nKeys.FORM.PREFERENCES.TASKS.TITLE)}
    >
      <Box mx="sm">
        <Checkbox
          my="xs"
          label={t(i18nKeys.FORM.PREFERENCES.TASKS.AUTO_UPDATE_LABEL)}
          {...form.getInputProps('autoclose_tasks', { type: 'checkbox' })}
        />
      </Box>
    </MantineSettingsCard>
  );
}
