import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { RouteChildrenProps, useHistory } from 'react-router';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { StoreState } from 'types/storeTypes';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import { CodaboxForm, CodaboxFormFields } from './CodaboxForm';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Codabox = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const { canActivate, isActive } = getIntegrationRights(Integration.CODABOX, company);

  useEffect(() => {
    if (!canActivate && !isActive) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, isActive, replace]);

  if (!company?.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  const handleSubmit = (data: CodaboxFormFields) => {
    onBoardingActions.codaboxToken({
      settings: true,
      data,
      callback: () => {
        INTEGRATION_UPDATE_CALLBACK();
        history.push('/settings/integrations');
      },
    });
  };

  const tokenRequest = () =>
    onBoardingActions.codaboxRequest({
      settings: true,
      callback: (data) => {
        onBoardingActions.changeCodaboxMessage(data.message);
      },
    });

  return (
    <article className={styles('codabox')}>
      <IntegrationHeader name={Integration.CODABOX} goBack={goBack} />
      <CodaboxForm onSubmit={handleSubmit} tokenRequest={tokenRequest} />
    </article>
  );
};
