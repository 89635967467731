import { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, ButtonColor, Input } from 'shared/io';
import { dialogHide, DialogShowId } from 'store/view/view.actions';

import styleIdentifiers from './RecalculateDatesModal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  text: ReactNode;
  onConfirm: Function;
  initialDelay?: number;
};

export default function RecalculateDatesModal({ text, onConfirm, initialDelay = 31 }: Props) {
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: { newDelay: initialDelay },
  });

  const { watch, register } = form;

  const handleSubmit = (confirm: boolean) => (e) => {
    e.preventDefault();
    dialogHide(DialogShowId.CONFIRM);
    if (!confirm) return;
    onConfirm(watch('newDelay') as number);
  };

  return (
    <div className={styles('recalculate-dates-modal')}>
      {text}
      <FormProvider {...form}>
        <form>
          <Input
            className={styles('delay-input')}
            register={register('newDelay', { required: true, min: 1 })}
            type="number"
            min="1"
            step="1"
            withBorder
            noMargin
          />
          <div className={styles('buttons-wrapper')}>
            <Button
              color={ButtonColor.GREY}
              type="submit"
              label={t(i18nKeys.NO)}
              onClick={handleSubmit(false)}
            />
            <Button type="submit" label={t(i18nKeys.YES)} onClick={handleSubmit(true)} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
