import { captureException } from '@sentry/react';

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEMO = 'demo',
  DEVELOPMENT = 'development',
  PREVIEW = 'preview',
}

function isValidEnv(env: string): env is Environment {
  return Object.values(Environment).includes(env as Environment);
}

if (!isValidEnv(__APP_ENV__)) throw new Error(`Received a bad environment: ${__APP_ENV__}`);

export function getEnv(): Environment {
  // Type cast okay because we validate above or crash the app
  return __APP_ENV__ as Environment;
}

export function isProduction(): boolean {
  return getEnv() === Environment.PRODUCTION;
}

export function isStaging(): boolean {
  return getEnv() === Environment.STAGING;
}

export function isPreview(): boolean {
  return getEnv() === Environment.PREVIEW;
}

export function isDemo(): boolean {
  return getEnv() === Environment.DEMO;
}

export function isDevelopment(): boolean {
  return getEnv() === Environment.DEVELOPMENT;
}

export function isDeployedEnvironment(): boolean {
  return isProduction() || isStaging() || isPreview() || isDemo();
}

// By public/private here we mean: Will or won't clients and prospects ever see it
export function isPublicEnvironment(): boolean {
  return isProduction() || isDemo();
}

export function isPrivateEnvironment(): boolean {
  return isStaging() || isPreview() || isDevelopment();
}

export function isOneOfEnvs(...envs: Array<Environment>): boolean {
  return envs.includes(getEnv());
}

const environmentFlags = {
  isProd: isProduction(),
  isStaging: isStaging(),
  isPreview: isPreview(),
  isDemo: isDemo(),
  isDev: isDevelopment(),
};

// @ts-ignore Exposed for debugging purposes
window.APP_ENV = {
  env: getEnv(),
  ...environmentFlags,
};

if (Object.values(environmentFlags).filter(Boolean).length !== 1) {
  captureException(
    new Error(`Multiple environments are active: ${JSON.stringify(environmentFlags)}`),
  );
}

export function getEnvPrefix(): string {
  switch (getEnv()) {
    case Environment.STAGING:
      return '[S] ';
    case Environment.DEMO:
      return '[DM] ';
    case Environment.DEVELOPMENT:
      return '[D] ';
    case Environment.PREVIEW:
      return '[P] ';
    case Environment.PRODUCTION:
    default:
      return '';
  }
}

export function getEnvName(): string {
  switch (getEnv()) {
    case Environment.STAGING:
      return 'Staging';
    case Environment.DEMO:
      return 'Demo';
    case Environment.DEVELOPMENT:
      return 'Development';
    case Environment.PRODUCTION:
      return 'Production';
    case Environment.PREVIEW:
      return 'Preview ';
    default:
      return 'Unknown';
  }
}

export function getEnvIcon(): string {
  switch (getEnv()) {
    case Environment.STAGING:
      return '🔍 ';
    case Environment.DEMO:
      return '📈 ';
    case Environment.DEVELOPMENT:
      return '🛠️ ';
    case Environment.PREVIEW:
      return '🫠 ';
    case Environment.PRODUCTION:
    default:
      return '';
  }
}
