import { AxiosInstance } from 'axios';
import { ResourceType } from 'types/resource-types';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { LightUserSchema } from './use-load-light-users';
import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const ViewSchema = z.object({
  id: z.string(),
  user: LightUserSchema,
  name: z.string(),
  isShared: z.boolean(),
  isDefault: z.boolean(),
  resourceType: z.string(),
  isFavorite: z.boolean(),
  columns: z.array(
    z.object({
      id: z.string(),
      order: z.number(),
      name: z.string().min(1),
    }),
  ),
  filters: z.array(
    z.object({
      id: z.string(),
      name: z.string().min(1),
    }),
  ),
});

const ViewsSchema = z.array(ViewSchema);

export type ViewV2 = z.infer<typeof ViewSchema>;
export type ViewsV2 = z.infer<typeof ViewsSchema>;

type LoadThirdPartiesVariables = {
  resourceType: ResourceType;
  onSuccess?: (data: ViewsV2) => void;
};

export async function loadViewsQueryFnV2(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get(`views/${queryKey[1]}`);
  return ViewsSchema.parse(data);
}

// TODO: create a utility type that contains onSuccess, onError, etc
export function useLoadViewsV2({ resourceType }: LoadThirdPartiesVariables) {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<ViewsV2, ApiError>({
    queryKey: ['views', resourceType],
    queryFn: (context) => loadViewsQueryFnV2(axiosInstance, context),
  });

  return addResourceNameToQueryResult<ViewsV2, unknown, 'views'>('views', queryResult);
}
