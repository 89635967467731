import { Reducer, useEffect, useReducer, useState } from 'react';
import AnimateNumber from 'animated-number-react';
import { useRequestPaymentPlan } from 'api';
import { PaymentPlanComplete, PaymentPlansListResponse, RequestPaymentPlanBody } from 'api/models';
import classNames from 'classnames/bind';
import { jotaiStore } from 'init';
import { currentLanguageAtom, i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Icon, IconName } from 'shared/components/Icon';
import LanguagePicker from 'shared/components/LanguagePicker/LanguagePicker';
import Logo from 'shared/components/Logo';
import Tabs from 'shared/components/Tabs';
import { Button, ButtonColor } from 'shared/io';
import apiService from 'shared/service/api.service';
import { isProduction } from 'shared/utils/environment';
import { useLocalizedCurrencyFormatter } from 'shared/utils/normalization';
import { setQueryParams } from 'shared/utils/url';
import { reducerState, removeAttributes } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  error,
  showDialog,
  success,
} from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import { Currency } from 'types/currency';
import { DebtorReactionInvoice } from 'types/debtor';
import { MetaData } from 'types/storeTypes';

import RequestPaymentPlanModal from './RequestPaymentPlanModal/RequestPaymentPlanModal';
import DebtorReactionCardHeader from './DebtorReactionCardHeader';
import DebtorReactionInvoicesList from './DebtorReactionInvoicesList';
import DebtorReactionPaymentPlansList from './DebtorReactionPaymentPlansList';

import styleIdentifiers from './debtorReaction.module.scss';

const styles = classNames.bind(styleIdentifiers);

type State = Reducer<
  {
    company_data?: {
      country_code: string;
      has_logo: boolean;
      has_cgv: boolean;
      iban: string;
      company_name: string;
      vat_number: string;
      use_digiteal_integration: boolean;
      has_trademark: boolean;
      can_use_online_payment: boolean;
      can_use_payment_plans: boolean;
      can_request_payment_plans: boolean;
    };
    debtor_data: { full_name: string; currency: string };
    totalRemainingBalance: number;
    invoices: {
      data: DebtorReactionInvoice[];
      metadata: MetaData;
      currentPage: 1;
    };
    paidInvoices?: {
      data: DebtorReactionInvoice[];
      metadata: MetaData;
      currentPage: 1;
    };
    payments_sum: number;
    credit_notes: DebtorReactionInvoice[];
    creditNotes?: {
      data: DebtorReactionInvoice[];
      metadata: MetaData;
    };
    payment_plans?: PaymentPlansListResponse;
  },
  any
>;

export default function DebtorReaction() {
  const { t, currentLang } = useTranslation();
  const [logoUrl, setLogoUrl] = useState<string>('');
  const location = useLocation();
  const { params } = useRouteMatch<{ id: any }>();

  const [queryStrings] = useState(qs.parse(location.search));
  const [data, setData] = useReducer<State>(reducerState, null as any);
  const [selectedInvoices, setSelectedInvoices] = useState<DebtorReactionInvoice[]>([]);
  const [isAllSelected, setSelectAll] = useState(false);
  const [isInvoicesTabActive, setIsInvoicesTabActive] = useState(false);
  const form = useForm();
  const formatter = useLocalizedCurrencyFormatter(
    false,
    (data?.debtor_data?.currency as Currency) ?? 'EUR',
  );
  const { mutate: requestPaymentPlan } = useRequestPaymentPlan();

  useEffect(() => {
    if (queryStrings.paymentError) {
      error({
        text: i18nKeys.DEBTOR.REACTION.PAYMENT_FAILED,
      });
      setQueryParams('paymentError', undefined);
    }

    if (queryStrings.paymentSucceeded) {
      success({
        text: i18nKeys.DEBTOR.REACTION.PAYMENT_SUCCEEDED,
      });
      setQueryParams('paymentSucceeded', undefined);
    }

    invoiceActions.debtorReaction({
      id: params.id,
      data: {
        token: queryStrings.document_token,
      },
      callback: (debtorReactionData) => {
        debtorReactionData.invoices.data = debtorReactionData.invoices.data.map((invoice) =>
          removeAttributes(invoice),
        );

        debtorReactionData.credit_notes = debtorReactionData.credit_notes.map((cn) =>
          removeAttributes(cn),
        );

        debtorReactionData.payments_sum = -debtorReactionData.payments_sum;

        if (debtorReactionData.company_data.has_logo) {
          invoiceActions.document({
            id: params.id,
            public: true,
            noFeedBack: true,
            noLoading: true,
            fileUpload: true,
            document_type: 'company_logo',
            data: {
              token: queryStrings.document_token,
            },
            callback: (logo: Blob) => {
              if (logo) setLogoUrl(URL.createObjectURL(logo));
            },
          });
        }

        setData({
          ...debtorReactionData,
          totalRemainingBalance:
            debtorReactionData.invoices.data.reduce(
              (acc, invoice) =>
                acc + Number(invoice.localized_money_object.remaining_balance_with_fees),
              0,
            ) +
            debtorReactionData.credit_notes.reduce(
              (acc, cn) => acc + Number(cn.localized_money_object.remaining_balance),
              0,
            ) +
            debtorReactionData.payments_sum,
        });
      },
    });

    return () => {
      if (logoUrl) URL.revokeObjectURL(logoUrl);
    };
  }, []);

  if (data == null || data.company_data == null) return null;

  const { company_data } = data;

  // Here they've added a debtor data into a company object : company_data.can_request_payment_plans
  const shouldDisplayPaymentPlanTab =
    company_data.can_request_payment_plans && company_data.can_use_payment_plans;
  const isFrenchClient = company_data.country_code === 'FR';
  const hasPaymentOptions = isFrenchClient ? company_data.use_digiteal_integration : true;

  const invoices = isInvoicesTabActive
    ? data?.paidInvoices?.data || []
    : data?.invoices?.data || [];

  const downloadCgv = () => {
    invoiceActions.document({
      id: params.id,
      public: true,
      document_type: 'payment_terms_pdf',
      data: {
        token: queryStrings.document_token,
      },
    });
  };

  const handleSelectAll = (active: boolean) => {
    const newFormValues = {
      payment: active,
    };

    setSelectedInvoices(
      active
        ? [
            ...(invoices || []).slice(),
            ...(data.credit_notes || []).slice(),
            {
              id: 'payment',
              remaining_balance_with_fees: data.payments_sum,
            } as any,
          ]
        : [],
    );

    for (const invoice of invoices || []) {
      newFormValues[invoice.id] = active;
    }

    for (const cn of data?.credit_notes || []) {
      newFormValues[cn.id] = active;
    }

    form.reset(newFormValues);
    setSelectAll(active);
  };

  const onCheckboxChange = (invoice: DebtorReactionInvoice) => (checked) => {
    const newSelectedInvoices = selectedInvoices.slice();
    if (checked) {
      newSelectedInvoices.push(invoice);
    } else {
      newSelectedInvoices.splice(
        newSelectedInvoices.findIndex((selectedInvoice) => selectedInvoice.id === invoice.id),
        1,
      );
    }

    if (
      newSelectedInvoices.length ===
      invoices.length + data.credit_notes.length + (data.payments_sum !== 0 ? 1 : 0)
    ) {
      !isAllSelected && setSelectAll(true);
    } else {
      isAllSelected && setSelectAll(false);
    }

    setSelectedInvoices(newSelectedInvoices);
  };

  const amountToPay = selectedInvoices.reduce(
    (acc, invoice) =>
      acc +
      ((invoice as any).id === 'payment'
        ? data.payments_sum
        : invoice.type === 'debtor_reaction_credit_note'
          ? +invoice.localized_money_object.remaining_balance
          : +invoice.localized_money_object.remaining_balance_with_fees),
    0,
  );

  const paymentLink = (structured_communication: string, amount: number) => {
    const fullPayment = Math.round(amount * 100);

    // todo: Is this used in demo? Does it need tailored values for that env?
    window.location.href = `https://${
      isProduction() ? 'api' : 'test'
    }.digiteal.eu/api/v1/payment-request/pay-button/execute?requesterVAT=${
      isProduction() ? company_data.vat_number : 'BE0206607129'
    }&creditorReference=${structured_communication}&amountInCents=${fullPayment}&iban=${
      isProduction() ? company_data.iban : 'BE95330012367958'
    }&language=${currentLang.toUpperCase()}&confirmationURL=${encodeURIComponent(
      `${window.location.href}&paymentSucceeded=true`,
    )}&errorURL=${encodeURIComponent(
      `${window.location.href}&paymentError=true`,
    )}&cancelURL=${encodeURIComponent(window.location.href)}`;
  };

  const showQrCode = (invoicesSelected) => {
    invoiceActions.debtorReactionQrCode({
      id: params.id,
      data: {
        invoice_ids: invoicesSelected.map((invoice) => invoice.id),
        token: queryStrings.document_token,
      },
      callback: ({ data: response }) => {
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.MEDIUM,
          title: t(i18nKeys.DEBTOR.REACTION.PAYMENT_QR_CODE),
          children: (
            <div className={styles('qr-code-modal')}>
              <div>{t(i18nKeys.DEBTOR.REACTION.QR_CODE_DESCRIPTION)}</div>
              <img src={response} alt="qr code" />
            </div>
          ),
        });
      },
    });
  };

  const pay = (invoicesToPay) => {
    if (!company_data.use_digiteal_integration) {
      showQrCode(invoicesToPay);
    } else if (isAllSelected ? data.invoices.data.length === 1 : invoicesToPay.length === 1) {
      paymentLink(invoicesToPay[0].structured_communication, amountToPay);
    } else {
      invoiceActions.getGroupedInvoicePaymentInfo({
        id: params.id,
        data: {
          invoice_ids: invoicesToPay.map((invoice) => invoice.id),
          token: queryStrings.document_token,
        },
        callback: ({ data: response }) => {
          paymentLink(response.attributes.structured_communication, amountToPay);
        },
      });
    }
  };

  const onChangeSelectAll = () => {
    handleSelectAll(!isAllSelected);
  };

  const onChangeTab = (value, oldTabIndex) => {
    if (oldTabIndex === 0) {
      handleSelectAll(false);
    }

    if (value === 0) {
      setIsInvoicesTabActive(false);
    } else if (value === 1) {
      setIsInvoicesTabActive(true);
      if (!data?.paidInvoices) {
        invoiceActions.debtorReactionInvoices({
          id: params.id,
          data: {
            page: 1,
            token: queryStrings.document_token,
          },
          callback: (paidInvoices) => {
            paidInvoices.data = paidInvoices.data.map((elements) => removeAttributes(elements));
            setData({
              ...data!,
              paidInvoices,
            });
          },
        });
      }
    } else if (value === 2) {
      setIsInvoicesTabActive(false);
      if (!data?.creditNotes) {
        invoiceActions.debtorReactionCreditNotes({
          id: params.id,
          data: {
            page: 1,
            token: queryStrings.document_token,
          },
          callback: (creditNotes) => {
            creditNotes.data = creditNotes.data.map((elements) => removeAttributes(elements));
            setData({
              ...data!,
              creditNotes,
            });
          },
        });
      }
    } else if (value === 3) {
      setIsInvoicesTabActive(false);
      if (!data?.payment_plans) {
        setData({
          ...data!,
        });
      }
    }
  };

  const handleRequestPaymentPlan = (planInvoices, amount) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.DEBTOR.REACTION.REQUEST_PAYMENT_PLAN),
      children: (
        <RequestPaymentPlanModal
          invoices={planInvoices}
          invoiceId={params.id}
          token={queryStrings.document_token as string}
          amount={amount}
          currency={data.debtor_data.currency}
          onSubmit={(values: RequestPaymentPlanBody) => {
            requestPaymentPlan(
              {
                data: values,
              },
              {
                onSuccess: () => {
                  dialogHide(DialogShowId.CUSTOM);
                },
              },
            );
          }}
        />
      ),
    });
  };

  const isAllEligibleForPaymentPlan = selectedInvoices.every(
    (invoice) => invoice.eligible_for_payment_plan,
  );

  return (
    <div className={styles('DebtorReaction')}>
      <div className={styles('layout-container')}>
        <div className={styles('head')}>
          <div>
            {logoUrl && <img src={logoUrl} alt="" />}
            <div>
              {data?.debtor_data && (
                <h1>
                  {t(i18nKeys.DEBTOR.REACTION.CLIENT_PORTAL_OF, {
                    text: data!.debtor_data!.full_name,
                  })}
                </h1>
              )}
              <div className={styles('debtor-fullname')}>
                {t(i18nKeys.INVOICE.YOUR_INVOICE_FOR)} {company_data.company_name}
              </div>
            </div>
          </div>

          <div className={styles('lang-container')}>
            <LanguagePicker
              currentLang={currentLang}
              availableLang={AVAILABLE_LANGUAGES}
              changeLang={(val) => jotaiStore.set(currentLanguageAtom, val)}
            />
            {company_data.has_cgv && (
              <div className={styles('cgv')} onClick={downloadCgv}>
                <Icon name={IconName.DOWNLOAD} /> {t(i18nKeys.DEBTOR.REACTION.GTC)}
              </div>
            )}
          </div>
        </div>
        <FormProvider {...form}>
          <Tabs
            className={styles('tabs')}
            items={[
              t(i18nKeys.STATEMENT),
              t(i18nKeys.INVOICES),
              t(i18nKeys.NAV.CREDIT_NOTES),
              ...(shouldDisplayPaymentPlanTab ? [t(i18nKeys.NAV.PAYMENT_PLAN)] : []),
            ]}
            onChange={onChangeTab}
          >
            <div className={styles('card-content')}>
              {company_data.can_use_online_payment && (
                <DebtorReactionCardHeader
                  data={data}
                  hasPaymentOptions={hasPaymentOptions}
                  selectAll={isAllSelected}
                  onChangeSelectAll={onChangeSelectAll}
                />
              )}
              <DebtorReactionInvoicesList
                billingStatement
                data={data}
                items={invoices}
                metadata={data?.invoices?.metadata}
                isInvoicesTabActive={isInvoicesTabActive}
                onCheckboxChange={onCheckboxChange}
                setData={setData}
                paymentLink={pay}
                queryStrings={queryStrings}
                canUseOnlinePayment={company_data.can_use_online_payment}
                canUsePaymentPlan={company_data.can_use_payment_plans}
                canRequestPaymentPlan={company_data.can_request_payment_plans}
                hasPaymentOptions={hasPaymentOptions}
                isFrenchClient={isFrenchClient}
                canUseDigiteal={company_data.use_digiteal_integration}
              />
              <div className={styles('pay-button-container')}>
                {!isInvoicesTabActive && amountToPay > 0 && (
                  <>
                    {company_data.can_use_payment_plans &&
                      company_data.can_request_payment_plans && (
                        <Button
                          label={t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)}
                          onClick={() => handleRequestPaymentPlan(selectedInvoices, amountToPay)}
                          color={ButtonColor.BLUE}
                          iconLeft={IconName.PAYMENT_BOOK}
                          iconSize="20px"
                          disabled={!isAllEligibleForPaymentPlan}
                        />
                      )}
                    {company_data.can_use_online_payment &&
                      (isFrenchClient ? company_data.use_digiteal_integration : true) && (
                        <Button
                          onClick={() => pay(selectedInvoices)}
                          color={ButtonColor.MAIN}
                          iconLeft={
                            company_data.use_digiteal_integration
                              ? IconName.CREDIT_CARD
                              : IconName.QR_CODE
                          }
                          iconSize="20px"
                        >
                          <span>
                            {t(i18nKeys.DEBTOR.REACTION.PAY)}
                            {' : '}
                            <AnimateNumber
                              duration={400}
                              value={Math.max(0, amountToPay)}
                              formatValue={(value) => formatter.format(value)}
                            />
                          </span>
                        </Button>
                      )}
                  </>
                )}
              </div>
            </div>
            <div className={styles('card-content')}>
              <DebtorReactionInvoicesList
                data={data}
                items={data?.paidInvoices?.data || []}
                isInvoicesTabActive={isInvoicesTabActive}
                onCheckboxChange={onCheckboxChange}
                metadata={data?.paidInvoices?.metadata}
                setData={setData}
                paymentLink={pay}
                queryStrings={queryStrings}
                canUseOnlinePayment={company_data.can_use_online_payment}
                isFrenchClient={isFrenchClient}
                canUseDigiteal={company_data.use_digiteal_integration}
              />
            </div>
            <div className={styles('card-content')}>
              <DebtorReactionInvoicesList
                data={data}
                credit
                items={data?.creditNotes?.data || []}
                metadata={data?.creditNotes?.metadata}
                isInvoicesTabActive={isInvoicesTabActive}
                onCheckboxChange={onCheckboxChange}
                setData={setData}
                paymentLink={pay}
                queryStrings={queryStrings}
                canUseOnlinePayment={company_data.can_use_online_payment}
              />
            </div>
            {shouldDisplayPaymentPlanTab && (
              <div className={styles('card-content')}>
                <DebtorReactionPaymentPlansList
                  data={data}
                  items={(data?.payment_plans?.data || []) as PaymentPlanComplete[]}
                />
              </div>
            )}
          </Tabs>
        </FormProvider>
        {company_data.has_trademark && (
          <div className={styles('logo')}>
            <span>Powered by</span>
            <a
              href={`https://www.recovr.eu/?utm_source=client_referrer&utm_medium=email&utm_campaign=debtor_reaction&utm_content=${apiService.tenant}`}
              target="_blank"
              rel="noreferrer"
            >
              <Logo type="blue" width="130px" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
