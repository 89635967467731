import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Checkbox } from 'shared/io';

import {
  CompanyEdit,
  GeneralConditionsFieldsName,
  ReconciliationFieldNames,
} from '../PreferencesForm.constants';

export const GeneralConditionsPartialSendTos = () => {
  const { t } = useTranslation();

  const { watch, register } = useFormContext<CompanyEdit>();

  return (
    <>
      <Checkbox
        register={register(GeneralConditionsFieldsName.SEND_TOS)}
        watch={watch}
        label={
          <span>
            {t(i18nKeys.FORM.PREFERENCES.SEND_CONDITION_EACH_INVOICE)}{' '}
            <Tooltip
              icon={IconName.INFO}
              size="14px"
              text={t(i18nKeys.FORM.PREFERENCES.TOOLTIP.SEND_CONDITION_EACH_INVOICE)}
            />
          </span>
        }
      />
      <Checkbox
        register={register(ReconciliationFieldNames.IGNORE_LATE_FEES)}
        watch={watch}
        label={
          <span>
            {t(i18nKeys.FORM.PREFERENCES.MARK_PAID_ON_NO_BALANCE)}{' '}
            <Tooltip
              icon={IconName.INFO}
              size="14px"
              text={t(i18nKeys.FORM.PREFERENCES.MARK_PAID_ON_NO_BALANCE_TOOLTIP)}
            />
          </span>
        }
      />
    </>
  );
};
