import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AddressBlock from 'shared/components/AddressBlock';
import TvaNumber from 'shared/components/TvaNumber';
import { Debtor } from 'types/store/client-state';

import { InvoiceFormSharedProps } from '../InvoiceForm.types';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type DebtorDetailsProps = InvoiceFormSharedProps & {
  debtor: Debtor;
};

export const DebtorDetails = ({ debtor }: DebtorDetailsProps) => {
  const { t } = useTranslation();

  const { address_attributes, vat_number, co_contractor, intracom } = debtor;

  return (
    <>
      {address_attributes && <AddressBlock address={address_attributes} />}
      <br />
      {vat_number && (
        <div>
          {t(i18nKeys.VAT)} : <TvaNumber value={vat_number} />
        </div>
      )}
      {co_contractor && (
        <div className={styles('customer-type')}>
          {t(i18nKeys.INVOICE.REVERSE_CHARGE_COCONTRACTOR)}
        </div>
      )}
      {intracom && (
        <div className={styles('customer-type')}>{t(i18nKeys.INVOICE.INTRACOMMUNITY)}</div>
      )}
    </>
  );
};
