import { store } from 'init/redux-store';
import { upperFirst } from 'lodash-es';
import { ReduxFunction } from 'types/redux';
import { ResStatus } from 'types/utilTypes';

export function firstLowerCase(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function makeAc(type: string) {
  return function (payload?: any) {
    store.dispatch({
      type,
      payload,
    });
  };
}

export function makeResAc(type: string) {
  return function (
    payload?: any,
    status?: ResStatus,
    requestData?: Object,
    reset?: boolean,
    id?: string,
  ) {
    store.dispatch({
      type,
      payload,
      status,
      requestData,
      reset,
      id,
    });
  };
}

export function makeReqCons(str: string, reset?: boolean) {
  const newStr = str.toUpperCase();

  const returnObj: {
    request: string;
    result: string;
    reset: string | boolean;
  } = {
    request: `${newStr}_REQUEST`,
    result: `${newStr}_RESULT`,
    reset: false,
  };

  if (reset) returnObj.reset = `${newStr}_RESET`;

  return returnObj;
}

export type BaseApiActionsType = {
  listPage: ReduxFunction;
  listPageRes: Function;
  detail: ReduxFunction;
  detailRes: Function;
  detailReset: Function;
  add: ReduxFunction;
  addRes: Function;
  update: ReduxFunction;
  updateRes: Function;
  delete: ReduxFunction;
  deleteRes: Function;
};

export function makeResourceConstants(str: string) {
  return {
    listPage: makeReqCons(`${str}_list_page`),
    detail: makeReqCons(`${str}_detail`, true),
    add: makeReqCons(`${str}_add`),
    update: makeReqCons(`${str}_update`),
    delete: makeReqCons(`${str}_delete`),
  };
}

type BuildActions<T> = T & {};

export function buildActions<T>(
  prefix: string,
  listConstants: any,
  returnObj: any,
  sync?: boolean,
): BuildActions<T> {
  const keys = Object.keys(listConstants);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const label = firstLowerCase(prefix + upperFirst(keys[i]));

    if (
      typeof listConstants[key] === 'string' ||
      (listConstants[key] && listConstants[key].result)
    ) {
      if (typeof listConstants[key] === 'string') {
        returnObj[label] = makeAc(listConstants[key]);
      } else {
        returnObj[label] = makeAc(listConstants[key].request);
        if (listConstants[keys[i]].reset)
          returnObj[`${label}Reset`] = makeAc(listConstants[keys[i]].reset);
        returnObj[`${label}Res`] = makeResAc(listConstants[keys[i]].result);
      }
    } else if (typeof listConstants[key] === 'object') {
      buildActions(label, listConstants[key], returnObj, sync);
    }
  }

  return returnObj;
}
