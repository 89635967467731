import { useState } from 'react';
import { useDeleteAutomaticAssignation, useUpdateAutomaticAssignation } from 'api';
import {
  AssignableModel,
  AutomaticAssignation,
  AvailableCondition,
  CreateAutomaticAssignationBody,
  Metadata,
} from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';
import { TableItemProps } from 'types/table';

import { AutomaticAssignationForm } from '../Forms/AutomaticAssignationForm';

import { AssignationConditions } from './AssignationConditions';

import styleIdentifiers from './AutomaticAssignationsListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type EnhancedTableAutomaticAssignation = AutomaticAssignation & {
  checked;
};

type AutomaticAssignationListItemProps = TableItemProps<EnhancedTableAutomaticAssignation> & {
  refetch: () => void;
  metadata: Metadata;
  availableConditions: AvailableCondition[];
};

export const AutomaticAssignationListItem = ({
  item,
  refetch,
  metadata,
  availableConditions,
}: AutomaticAssignationListItemProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [hover, setHover] = useState(false);

  const {
    id,
    attributes: { model_to_assign, assign_to, order, assignation_conditions },
  } = item;

  const { mutate: deleteAutomaticAssignationMutation } = useDeleteAutomaticAssignation();

  const { mutate: updateAutomaticAssignationMutation } = useUpdateAutomaticAssignation();

  const updateAutomaticAssignation = () =>
    sideMenuShow({
      unmount: true,
      content: (
        <AutomaticAssignationForm
          availableConditions={availableConditions}
          automaticAssignation={item}
          itemsTotal={metadata.total}
          onSubmit={(data: CreateAutomaticAssignationBody) =>
            updateAutomaticAssignationMutation(
              { id, data },
              {
                onSuccess: () => {
                  sideMenuHide();
                  refetch();
                },
              },
            )
          }
        />
      ),
    });

  const deleteAutomaticAssignation = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.AUTOMATIC_ASSIGNATION_DELETE)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteAutomaticAssignationMutation(
              { id },
              {
                onSuccess: () => {
                  dialogHide(DialogShowId.CONFIRM);
                  refetch();
                },
              },
            )
          }
        />
      ),
    });

  const showAutomaticAssignation = () => {
    history.push(`/settings/automatic-assignation/${item.id}`);
  };

  return (
    <tr
      className={styles('automatic-assignation-list-item', hover && 'hover')}
      onMouseEnter={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={id}
    >
      <td />
      <td onClick={showAutomaticAssignation}>
        {t(
          i18nKeys.COMMON[
            model_to_assign === AssignableModel.invoice
              ? AssignableModel.invoice.toUpperCase()
              : 'CLIENT'
          ],
        )}
      </td>
      <td onClick={showAutomaticAssignation}>{assign_to.name}</td>
      <td onClick={showAutomaticAssignation}>
        <AssignationConditions
          items={assignation_conditions}
          availableConditions={availableConditions}
        />
      </td>
      <td onClick={showAutomaticAssignation}>{order}</td>
      <td className={styles('actions')}>
        <span onClick={updateAutomaticAssignation}>
          <Icon name={IconName.SETTINGS_GEAR} size="18px" className={styles('action')} />
        </span>
        <span onClick={deleteAutomaticAssignation}>
          <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
        </span>
      </td>
    </tr>
  );
};
