import { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { first, partition } from 'remeda';
import { useSendToThirdParty } from 'shared/hooks';
import { useGetCompany, useGetInvoiceState } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { Id } from 'types';
import { Company } from 'types/store/account-state';

import { Button, Group, NumberInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';

import { GcollectCaseError, GcollectModalValidate } from './GcollectModalValidate';

interface GcollectModalProps {
  invoiceIds: Array<Id>;
  gcollectId: Id;
  additionalInvoiceIds?: Array<Id>;
}

// Either calls the API right away, if the codes are present, or prompts the user for them in a form, then calls the API
export function GcollectModal({
  invoiceIds,
  gcollectId,
  additionalInvoiceIds = [],
}: GcollectModalProps) {
  const { t } = useTranslation();
  const company = useGetCompany();
  const [wereCodesMissing, setWereCodesMissing] = useState(false);
  const { sendToThirdParty, isSendToThirdPartyLoading } = useSendToThirdParty();
  const { isLoading: isBatchActionLoading } = useGetInvoiceState();

  const form = useForm<{ sirenCode: Company['siren']; apeCode: Company['ape_code'] }>({
    initialValues: {
      sirenCode: company?.siren ?? '',
      apeCode: company?.ape_code ?? '',
    },
    validateInputOnBlur: true,
    validate: {
      sirenCode: (value) => {
        if (value == null || value === '') {
          return t(i18nKeys.ERROR.FIELD_REQUIRED);
        }
        // todo: The input should actually be text, not number, as it's not a number but a string of digits
        // this would avoid the incorrect typing  that make s the string cast below necessary.
        if (String(value).length !== 9) {
          return t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.SIREN_LENGTH_ERROR);
        }
        return null;
      },
      apeCode: (value) => {
        if (value == null || value === '') {
          return t(i18nKeys.ERROR.FIELD_REQUIRED);
        }
        if (value.match(/\d{4}[a-zA-Z]/) == null) {
          return t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.APE_CODE_FORMAT);
        }
        return null;
      },
    },
  });

  const isBatch = invoiceIds.length > 1;

  const handleNextStep = (caseIds: Array<number>, errors?: Array<GcollectCaseError>) => {
    modals.open({
      title: t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.CONFIRM_RATES),
      size: 'fit-content',
      centered: true,
      closeOnClickOutside: false,
      withCloseButton: false,
      children: <GcollectModalValidate caseIds={caseIds} errors={errors} />,
    });
  };

  // The batch or send_to_third_party calls will update Company with the SIREN and APE codes if they were missing from it.
  // We refetch company so that users won't be prompted a second time if they open another case right after the current one.
  const handleReloadCompany = () => {
    if (wereCodesMissing) {
      accountActions.getCompany();
    }
  };

  const openSingleCase = async ({ sirenCode, apeCode }: (typeof form)['values']) => {
    const options = {
      id: first(invoiceIds)!,
      data: {
        third_party_company_id: gcollectId,
        additional_invoices: additionalInvoiceIds,
        siren: String(sirenCode)!,
        ape_code: apeCode!,
      },
    };
    sendToThirdParty(options, {
      onSuccess: (data) => {
        handleNextStep(data.third_party_case_ids);
        handleReloadCompany();
      },
    });
  };

  const openBatchCase = async ({ sirenCode, apeCode }: (typeof form)['values']) => {
    invoiceActions.actionsBatchAction({
      data: {
        third_party_company_id: gcollectId,
        siren: String(sirenCode),
        ape_code: apeCode,
        action_type: 'third_party',
        invoice_ids: invoiceIds,
      },
      callback: (res) => {
        const [cases, errors] = partition(res, (x: any) => x.third_party_case_id != null);
        handleNextStep(
          cases.map(({ third_party_case_id }) => third_party_case_id),
          errors as Array<GcollectCaseError>,
        );
        handleReloadCompany();
      },
    });
  };

  const openCase = isBatch ? openBatchCase : openSingleCase;
  const isLoading = isBatch ? isBatchActionLoading : isSendToThirdPartyLoading;

  useEffect(() => {
    if (company != null && company.siren != null && company.ape_code != null) {
      openCase({ sirenCode: company.siren, apeCode: company.ape_code });
    } else {
      setWereCodesMissing(true);
    }
  }, []);

  return (
    <form onSubmit={form.onSubmit((values) => openCase(values))}>
      <NumberInput
        disabled={isLoading}
        withAsterisk
        label="SIREN"
        placeholder="123456789"
        min={0}
        allowDecimal={false}
        allowLeadingZeros
        allowNegative={false}
        hideControls
        valueIsNumericString
        {...form.getInputProps('sirenCode')}
      />
      <TextInput
        disabled={isLoading}
        mt="md"
        withAsterisk
        label="APE"
        placeholder="1234E"
        {...form.getInputProps('apeCode')}
      />
      <Group justify="flex-end" mt="md">
        <Button type="submit" loading={isLoading}>
          {t(i18nKeys.SUBMIT)}
        </Button>
      </Group>
    </form>
  );
}
