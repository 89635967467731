import { PropsWithChildren } from 'react';
import { ExternalMailAttributesDebtor } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ExternalMail, UpdateExternalMailVariables } from 'shared/hooks';
import { Button } from 'shared/io';

import styleIdentifiers from './ExternalMailAction.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailActionProps = {
  onSubmit: SubmitHandler<UpdateExternalMailVariables>;
  externalMail: ExternalMail;
};

export const ExternalMailAction = ({
  onSubmit,
  externalMail,
  children,
}: PropsWithChildren<ExternalMailActionProps>) => {
  const { t } = useTranslation();

  const {
    attributes: { ...rest },
  } = externalMail;

  const form = useForm<UpdateExternalMailVariables & { debtor: ExternalMailAttributesDebtor }>({
    shouldUnregister: true,
    defaultValues: {
      ...rest,
      debtor_id: rest.debtor?.id,
      user_id: externalMail.attributes.account_manager?.id,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  return (
    <FormProvider {...form}>
      <form className={styles('external-email-action-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('content')}>{children}</div>
        <div className={styles('button-wrapper')}>
          <Button disabled={!isDirty} type="submit" label={t(i18nKeys.SAVE)} noShadow noMargin />
        </div>
      </form>
    </FormProvider>
  );
};
