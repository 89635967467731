import { jotaiStore } from 'init';
import { i18nKeys } from 'locales';
import { delay } from 'redux-saga';
import { all, call, takeLatest } from 'redux-saga/effects';
import { ublProgressAtom } from 'shared/forms/InvoiceImportForm/InvoiceImportForm';
import { downloadFile, extractFilename } from 'shared/utils/view';
import { accountActions, accountConstants as events } from 'store/account/account.actions';
import { api } from 'store/apis';
import { error, success } from 'store/view/view.actions';

import { apiCall, sendApi } from '../sagas';

/**
 * Other
 */
export function* userInit() {
  yield apiCall({
    api: api.company.detail,
    noFeedback: true,
    noLoading: true,
    actionRes: accountActions.getCompanyRes,
  });
}

/**
 * Company
 */
function* updateCompany({ payload }: any) {
  const { callback, noFeedback, noLoading, ...data } = payload;
  if (data.logo) {
    yield apiCall({
      api: api.company.update,
      fileUpload: true,
      data: {
        logo: '', // data.logo,
      },
    });
    data.logo = undefined;
  }

  yield apiCall({
    api: api.company.update,
    data,
    callback,
    noLoading,
    success: !noFeedback && i18nKeys.SETTINGS.COMPANY.SUCCESSFULLY_UPDATE,
    actionRes: accountActions.updateCompanyRes,
  });
}
/**
 * Upload terms
 */
function* uploadTerms({ payload }: any) {
  const { callback, id, pdf } = payload;

  yield apiCall({
    api: api.company.uploadTerms,
    id,
    fileUpload: true,
    callback,
    data: {
      pdf,
    },
  });
}
/**
 * Download terms
 */
function* downloadTerms({ payload }: any) {
  const request = yield call(apiCall, {
    api: api.company.getTerms,
    id: payload.id,
    data: payload,
  });

  if (request && request.status === 200) {
    const filename = extractFilename(request.headers);
    downloadFile(request.data, filename || 'recovr.company-terms.pdf');
  } else {
    yield error({
      text: i18nKeys.ERROR.DOC_NOT_AVAILABLE,
    });
  }
}
/**
 * Import
 */
function* importInvoice({ payload }: any) {
  const { ublFiles, planId } = payload;

  jotaiStore.set(ublProgressAtom, { active: true, value: 0 });

  const errors: string[] = [];

  for (let i = 0; i < ublFiles.length; i++) {
    yield call(apiCall, {
      api: api.invoice.import.ubl,
      fileUpload: true,
      noFeedback: true,
      noLoading: true,
      progress: () => {},
      data: {
        invoice_ubl_file: ublFiles[i],
        recovery_plan_id: planId,
      },
      actionFailure: (response) => {
        errors.push(response?.data?.error_message ?? '');
        jotaiStore.set(ublProgressAtom, { active: true, value: (100 * (i + 1)) / ublFiles.length });
      },
      callback: () =>
        jotaiStore.set(ublProgressAtom, {
          active: true,
          value: Math.round((100 * (i + 1)) / ublFiles.length),
        }),
    });
  }

  if (errors.length > 0) jotaiStore.set(ublProgressAtom, { active: true, errors });
  else {
    yield success({ text: i18nKeys.INVOICE.SUCCESSFUL_IMPORT });
    yield call(delay, 500);
    if (payload.callback) yield call(payload.callback);
  }
}

function* UserWatchers() {
  yield all([
    yield takeLatest(events.editBankingInfo.request, sendApi(api.settings.editBankingInfo)),
    yield takeLatest(events.resetSecretId.request, sendApi(api.settings.resetSecretId)),
    yield takeLatest(events.askUpgradePackage.request, sendApi(api.settings.askUpgradePackage)),
    yield takeLatest(
      events.syncIntegration.request,
      sendApi(api.settings.syncIntegration, accountActions.getCompanyRes),
    ),
    yield takeLatest(events.importInvoice.request, importInvoice),
    yield takeLatest(
      events.updateBillitSettings.request,
      sendApi(api.settings.updateBillitSettings),
    ),
    yield takeLatest(events.updateYukiSettings.request, sendApi(api.settings.updateYukiSettings)),
    yield takeLatest(events.updateHorusSettings.request, sendApi(api.settings.updateHorusSettings)),
    yield takeLatest(
      events.updateDbasicsSettings.request,
      sendApi(api.settings.updateDbasicsSettings),
    ),
    yield takeLatest(
      events.setDigitealConfiguration.request,
      sendApi(api.settings.setDigitealConfiguration),
    ),
    yield takeLatest(
      events.updateTeamleaderSettings.request,
      sendApi(api.settings.updateTeamleaderSettings),
    ),
    yield takeLatest(events.updateExactSettings.request, sendApi(api.settings.updateExactSettings)),
    yield takeLatest(events.horusFetchCompanies.request, sendApi(api.settings.horusFetchCompanies)),
    yield takeLatest(events.exactFetchCompanies.request, sendApi(api.settings.exactFetchCompanies)),
    yield takeLatest(
      events.activityPage.request,
      sendApi(api.activity.list, accountActions.activityPageRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(
      events.activityDetail.request,
      sendApi(api.activity.detail, accountActions.activityDetailRes),
    ),
    yield takeLatest(
      events.activitySeen.request,
      sendApi(api.activity.markAsRead, accountActions.activitySeenRes),
    ),
    yield takeLatest(events.activitySeenAll.request, sendApi(api.activity.markAllAsRead)),
    yield takeLatest(
      events.getCompany.request,
      sendApi(api.company.detail, accountActions.getCompanyRes, (payload) => ({
        ...payload,
        noFeedback: true,
      })),
    ),
    yield takeLatest(
      events.updateInvoiceConfigurations.request,
      sendApi(
        api.settings.updateInvoiceConfigurations,
        accountActions.updateCompanyRes,
        ({ data }, ...payload) => ({
          ...payload,
          data: {
            ...data,
            color: data.color?.hex ? data.color.hex : data.color,
          },
        }),
      ),
    ),
    yield takeLatest(events.updateCompany.request, updateCompany),
    yield takeLatest(
      events.updateCompanyInfo.request,
      sendApi(api.settings.updateCompany, accountActions.updateCompanyRes),
    ),
    yield takeLatest(
      events.updateCompanyFiles.request,
      sendApi(api.company.updateCompanyFiles, accountActions.updateCompanyRes),
    ),
    yield takeLatest(events.getDigitealCGV.request, sendApi(api.settings.getDigitealCGV)),
    yield takeLatest(events.getTerms.request, downloadTerms),
    yield takeLatest(events.uploadTerms.request, uploadTerms),
  ]);
}

export default function* saga() {
  yield call(UserWatchers);
}
