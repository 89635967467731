import { IconName } from '../Icon';

export enum Integration {
  YUKI = 'Yuki',
  HORUS = 'Horus',
  BILLIT = 'Billit',
  EXACT = 'Exact',
  TEAMLEADER = 'Teamleader',
  CODABOX = 'Codabox',
  PONTO = 'Ponto',
  DIGITEAL = 'Digiteal',
  DBASICS = 'Dbasics',
  COMPANYWEB = 'Companyweb',
  CHIFT = 'Chift',
}

// These are essentially integrations that the client cannot perform on his own.
// They do not exist in the UI but can sent from the backend and need to be tested against in some cases.
export enum Connector {
  // Inconsistent casing unfortunately deliberate.
  BOB = 'bob',
  WINBOOKS = 'Winbooks',
}

export const ENABLED_INTEGRATIONS = [
  Integration.YUKI,
  Integration.HORUS,
  // Integration.BILLIT,
  Integration.EXACT,
  Integration.TEAMLEADER,
  Integration.CODABOX,
  Integration.PONTO,
  Integration.DBASICS,
  Integration.DIGITEAL,
  Integration.COMPANYWEB,
  Integration.CHIFT,
];

export const IntegrationQueryParams = {
  NAME: 'integration',
  CATEGORY: 'category',
};

export const INTEGRATIONS_ROUTE = '/settings/integrations';

export const INTEGRATION_ROUTE = {
  [Integration.YUKI]: `/settings/integration/${Integration.YUKI.toLowerCase()}`,
  [Integration.HORUS]: `/settings/integration/${Integration.HORUS.toLowerCase()}`,
  [Integration.BILLIT]: `/settings/integration/${Integration.BILLIT.toLowerCase()}`,
  [Integration.EXACT]: `/settings/integration/${Integration.EXACT.toLowerCase()}`,
  [Integration.TEAMLEADER]: `/settings/integration/${Integration.TEAMLEADER.toLowerCase()}`,
  [Integration.CODABOX]: `/settings/integration/${Integration.CODABOX.toLowerCase()}`,
  [Integration.PONTO]: `/settings/integration/${Integration.PONTO.toLowerCase()}`,
  [Integration.DBASICS]: `/settings/integration/${Integration.DBASICS.toLowerCase()}`,
  [Integration.DIGITEAL]: `/settings/integration/${Integration.DIGITEAL.toLowerCase()}`,
  [Integration.COMPANYWEB]: `/settings/integration/${Integration.COMPANYWEB.toLowerCase()}`,
  [Integration.CHIFT]: `/settings/integration/${Integration.CHIFT.toLowerCase()}`,
};

export enum IntegrationCategory {
  ALL = 'ALL',
  ACCOUNTING = 'ACCOUNTING',
  ERP = 'ERP',
  BANKING = 'BANKING',
  MY_INTEGRATIONS = 'MY_INTEGRATIONS',
  MONITORING = 'MONITORING',
}

export type IntegrationCard = {
  title: Integration;
  category: IntegrationCategory;
  description: string;
  icon: IconName | JSX.Element;
  isActive: boolean;
  canActivate: boolean;
};
