import { useState } from 'react';
import { base64StringToBlob } from 'blob-util';
import { i18nKeys, useTranslation } from 'locales';
import { Document, Page } from 'react-pdf';

import { css } from '@emotion/css';
import { Box, Card, Center, Pagination, Tabs } from '@mantine/core';

import HTML from '../../../HTML/HTML';

const styles = {
  emailFix: css`
    text-align: left;
    padding: 2em;
  `,
  paginationWrapper: css`
    position: sticky;
    bottom: 30px;
    display: flex;
    justify-content: center;

    .mantine-Pagination-control {
      box-shadow: var(--mantine-shadow-xs);
    }
  `,
};

type ReminderPreviewProps = {
  pdf: string | null;
  email: string | null;
};

export const ReminderPreview = ({ pdf, email }: ReminderPreviewProps) => {
  const { t } = useTranslation();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const blob = pdf && base64StringToBlob(pdf, 'application/pdf');

  return (
    <Tabs defaultValue={email ? 'email' : 'pdf'} mb="xl">
      <Tabs.List my="lg">
        {email && <Tabs.Tab value="email">{t(i18nKeys.EMAIL)}</Tabs.Tab>}
        {pdf && <Tabs.Tab value="pdf">{t(i18nKeys.POST)}</Tabs.Tab>}
      </Tabs.List>
      {email && (
        <Tabs.Panel value="email">
          <Center>
            <Card withBorder w={900}>
              <HTML className={styles.emailFix} html={email} />
            </Card>
          </Center>
        </Tabs.Panel>
      )}
      {pdf && (
        <Tabs.Panel value="pdf">
          <Center>
            <Card withBorder>
              <Document
                file={blob}
                onLoadSuccess={(pdfObject) => setPageCount(pdfObject._pdfInfo.numPages)}
              >
                <Page
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  width={900}
                  pageNumber={currentPage}
                />
              </Document>
            </Card>
          </Center>
          {pageCount > 1 && (
            <Box mt="lg" className={styles.paginationWrapper}>
              <Pagination
                size="xl"
                total={pageCount}
                value={currentPage}
                onChange={setCurrentPage}
              />
            </Box>
          )}
        </Tabs.Panel>
      )}
    </Tabs>
  );
};
