import { Fragment } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon, IconName } from 'shared/components/Icon';
import { RadioButton } from 'shared/io';

import { captureMessage } from '@sentry/react';

import { RecoveryPlanAddStep } from './RecoveryPlanAddStep';
import { RecoveryPlanStepEl } from './RecoveryPlanStep';
import { RecoveryPlanProps, StepFiltered } from './RecoveryPlanStep.types';

import styleIdentifiers from './RecoveryPlan.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const RecoveryPlan = (props: RecoveryPlanProps) => {
  const { recoverPlan, editStep, addStep, messageInfo, setLateFees, lateFeesStep } = props;

  const { t } = useTranslation();

  const stepsBefore = [] as StepFiltered[];
  const stepsAfter = [] as StepFiltered[];

  recoverPlan.recovery_steps.forEach((step, index) => {
    if (step.before_due_date) {
      stepsBefore.push({ step, index });
    } else {
      stepsAfter.push({ step, index });
    }
  });

  const add = ({ index, before_due_date }) => {
    addStep?.(index === -1 ? 0 : index)(index === -1, before_due_date);
    if (addStep == null) {
      captureMessage(
        'In RecoveryPlan.tsx: Invalid behavior, addStep is nullish but  abutton calling it was visible to the user. This message means an error was avoided but we allowed a user to click on a button that has no effect. Audit the code and remove it when appropriate.',
      );
    }
  };

  return (
    <div className={styles('recovery-plan')}>
      {stepsBefore.map(({ step, index }, i, array) => {
        const { delay, task_attributes } = step;

        return (
          <Fragment key={`before-${i}`}>
            <RecoveryPlanStepEl
              index={index}
              array={array}
              arrayIndex={i}
              step={step}
              add={(number: number) => add({ before_due_date: true, index: number })}
              {...props}
            >
              {editStep && (
                <div className={styles('delay')}>
                  <div />
                  <div>
                    - {t(i18nKeys.DAY_S, { count: delay })}{' '}
                    <Icon
                      name={IconName.PENCIL}
                      onClick={editStep!(index, typeof task_attributes !== 'undefined')}
                    />
                  </div>
                  <div />
                </div>
              )}
            </RecoveryPlanStepEl>
          </Fragment>
        );
      })}
      {!stepsBefore.length && (
        <RecoveryPlanAddStep
          onClick={() => add({ before_due_date: true, index: 0 })}
          text={t(i18nKeys.ADD_STEP)}
        />
      )}
      <div className={styles('debt-collection-item', 'key-date')}>
        <span>{t(i18nKeys.DUE_DATE)}</span>
      </div>
      {!stepsAfter.length && (
        <RecoveryPlanAddStep
          onClick={() =>
            add({
              before_due_date: false,
              index: recoverPlan.recovery_steps.length,
            })
          }
          text={t(i18nKeys.ADD_STEP)}
        />
      )}
      {stepsAfter.map(({ step, index }, i, array) => {
        const { delay, step_type, order, task_attributes, id } = step;

        return (
          <Fragment key={`after-${i}`}>
            <RecoveryPlanStepEl
              index={index}
              array={array}
              arrayIndex={i}
              step={step}
              add={(number: number) => add({ before_due_date: false, index: number })}
              {...props}
            >
              {editStep ? (
                <>
                  <div />
                  <div>
                    + {t(i18nKeys.DAY_S, { count: delay })}{' '}
                    <Icon
                      name={IconName.PENCIL}
                      onClick={editStep!(index, typeof task_attributes !== 'undefined')}
                    />
                  </div>
                  <div className={styles('late-fees-button')}>
                    <div>
                      {(step_type === 'intermediate_reminder' || step_type === 'last_reminder') && (
                        <RadioButton
                          canRemove
                          noMargin
                          onChange={setLateFees!}
                          value={lateFeesStep}
                          name="fee_starting_step"
                          items={[{ value: order, label: t(i18nKeys.LATE_FEES) }]}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                messageInfo && !id && <div className={styles('message-info')}>{messageInfo}</div>
              )}
            </RecoveryPlanStepEl>
          </Fragment>
        );
      })}
    </div>
  );
};
