import { useEffect } from 'react';

import { useForm, zodResolver } from '@mantine/form';

import {
  LoadInvoiceColumnVariablesInput,
  LoadInvoiceColumnVariablesSchema,
  useLoadInvoiceColumn,
} from './use-load-invoice-column';

export function useLoadKanbanInvoices() {
  const searchParams = useSearchParams();
  const filtersForm = useForm<LoadInvoiceColumnVariablesInput>({
    validate: zodResolver(LoadInvoiceColumnVariablesSchema),
    initialValues: {
      accountManagerId: null,
      column: 'late', // placholder for type correctness, overwritten below,
      debtorName: '',
      endDate: null,
      startDate: null,
      maxDaysLate: '',
      minDaysLate: '',
      reference: '',
      remainingBalance: {
        amount: '',
        operator: '<=',
      },
    },
  });

  useEffect(() => {
    const debtorName = searchParams.get('debtorName');
    if (debtorName) filtersForm.setFieldValue('debtorName', debtorName);
  }, []);

  const filters = filtersForm.getValues();

  const late = useLoadInvoiceColumn({ ...filters, column: 'late' });
  const firstReminder = useLoadInvoiceColumn({ ...filters, column: 'first_reminder' });
  const lastReminder = useLoadInvoiceColumn({ ...filters, column: 'last_reminder' });
  const formalNotice = useLoadInvoiceColumn({ ...filters, column: 'formal_notice' });
  const thirdPartyCase = useLoadInvoiceColumn({ ...filters, column: 'third_party_case' });
  const disputed = useLoadInvoiceColumn({ ...filters, column: 'disputed' });

  return {
    columns: {
      late,
      firstReminder,
      lastReminder,
      formalNotice,
      thirdPartyCase,
      disputed,
    },
    filtersForm,
  };
}

const useSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    get: (key: string) => searchParams.get(key),
    set: (key: string, value: string) => {
      searchParams.set(key, value);
      window.history.replaceState(null, '', `?${searchParams.toString()}`);
    },
  };
};
