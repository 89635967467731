import classNames from 'classnames/bind';
import { Button, ButtonColor, UploadZone } from 'shared/io';

import styleIdentifiers from './InputFile.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InputFileProps = {
  accept: string;
  name: string;
  label: string;
  color: ButtonColor;
  className?: string;
  noShadow?: boolean;
  onChange: Function;
};

export const InputFile = ({
  accept,
  onChange,
  name,
  label,
  color,
  className,
  noShadow,
  ...props
}: InputFileProps) => (
  <div className={styles(styles('button-file-input'))}>
    <Button label={label} color={color} noMargin noShadow={noShadow} />
    <UploadZone
      className={styles('file-input', className)}
      onChange={onChange}
      accept={accept}
      noResize
      noMargin
      noPreview
      {...props}
    />
  </div>
);
