import { useEffect } from 'react';
import { CreateTaskBody, Task } from 'api/models';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, Checkbox, DateSelector, Input, Textarea } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';

import { getInitialValues } from '../Task.utils';

import { TaskFormColleague } from './TaskFormColleague';
import { TaskFormDebtor } from './TaskFormDebtor';
import { TaskFormInvoices } from './TaskFormInvoices';
import { TaskFormType } from './TaskFormType';

import styleIdentifiers from './TaskForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskFormProps = {
  onSubmit: SubmitHandler<CreateTaskBody & { group_same_debtor: boolean }>;
  task?: Task;
  close?: () => void;
  clientId?: string | number;
  batch?: boolean;
  invoiceId?: string | number;
  debtors?: boolean;
};

export const TaskForm = ({
  onSubmit,
  task,
  close,
  clientId,
  batch,
  invoiceId,
  debtors,
}: TaskFormProps) => {
  const { t } = useTranslation();
  const { isLoading: isInvoiceLoading } = useGetInvoiceState()!;
  const { isLoading: isClientLoading } = useGetClientState()!;
  const isLoading = isInvoiceLoading || isClientLoading;

  const defaultValues = {
    title: '',
    description: '',
    task_type: '',
    debtor_id: clientId,
    group_same_debtor: true,
    ...getInitialValues(task),
  };

  const form = useForm<CreateTaskBody & { group_same_debtor: boolean }>({
    shouldUnregister: true,
    defaultValues,
  });

  const {
    setValue,
    register,
    watch,
    formState: { isDirty, errors },
    handleSubmit,
  } = form;

  useEffect(() => {
    sideMenuSetAskBeforeClose(isDirty);
  }, [isDirty]);

  const minDueDate = dayjs();

  return (
    <FormProvider {...form}>
      <form className={styles('task-form', batch && 'no-width')} onSubmit={handleSubmit(onSubmit)}>
        {!batch && (
          <div className={styles('head')}>
            {t(task ? i18nKeys.FORM.TASK.EDIT : i18nKeys.FORM.TASK.NEW)}
            {close && (
              <div className={styles('close')} onClick={close}>
                <Icon name={IconName.SIMPLE_REMOVE} size="20px" />
              </div>
            )}
          </div>
        )}
        <div className={styles('content')}>
          <div className={styles('grid-row')}>
            <div className={styles('col-12')}>
              <Input
                register={register('title', {
                  required: true,
                })}
                errorMessage={get(errors, 'title')}
                noMargin
                withBorder
                label={t(i18nKeys.TASK.ATTRIBUTES.TITLE)}
              />
            </div>
            <div className={styles('col-12')}>
              <Textarea
                register={register('description')}
                className={styles('input')}
                withBorder
                label={t(i18nKeys.DESCRIPTION)}
              />
            </div>
            <div className={styles('col-12')}>
              <TaskFormColleague />
            </div>
            <div className={styles('col-12')}>
              <TaskFormType />
            </div>
            <div className={styles('col-12')}>
              <Controller
                name="due_date"
                render={() => (
                  <DateSelector
                    label={t(i18nKeys.DUE_DATE)}
                    name="due_date"
                    withBorder
                    className={styles('input', 'date-selector')}
                    inputClassName={styles('value')}
                    errorClassName={styles('error')}
                    minDate={minDueDate}
                    placeholder={minDueDate.format('DD/MM/YYYY')}
                    handleChange={(value) => {
                      setValue('due_date', value);
                    }}
                  />
                )}
              />
            </div>
            {!batch && !invoiceId && (
              <>
                <div className={styles('col-12')}>
                  <TaskFormDebtor currentDebtor={task?.attributes?.debtor} />
                </div>
                <div className={styles('col-12')}>
                  <TaskFormInvoices attachedInvoices={task?.attributes?.invoices?.details} />
                </div>
              </>
            )}
            {batch && !debtors && (
              <div className={styles('col-12')}>
                <Checkbox
                  label={t(i18nKeys.FORM.TASK.GROUP_SAME_DEBTOR)}
                  name="group_same_debtor"
                  register={register('group_same_debtor')}
                  watch={watch}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button isLoading={isLoading} type="submit" label={t(i18nKeys.SAVE)} noShadow noMargin />
        </div>
      </form>
    </FormProvider>
  );
};
