import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import HTML from 'shared/components/HTML/HTML';
import { Button, ButtonColor, CustomRadio } from 'shared/io';
import { OnClickFunction } from 'types/html-type';
import { onSubmitFunction } from 'types/react-hook-form';
import { PostponableInvoice } from 'types/store/invoice-state';

import styleIdentifiers from './postponeForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  close: OnClickFunction;
  invoices: PostponableInvoice[] | 'all';
  onSubmit: onSubmitFunction;
};

export default function PostponeForm({ onSubmit, close, invoices: _invoices }: Props) {
  const { t } = useTranslation();

  const areAllInvoicesSelected = _invoices === 'all';
  const invoices = _invoices === 'all' ? [] : _invoices;

  const buttonLabel = (() => {
    const basePath = i18nKeys.INVOICING.IMPENDING_REMINDERS;

    if (areAllInvoicesSelected) return t(basePath.POSTPONE_ALL);
    if (invoices.length === 1) return t(basePath.INVOICE_TO_POSTPONE);
    return t(basePath.INVOICES_TO_POSTPONE, { count: invoices.length });
  })();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const contentRadio = (day) => (
    <div>
      <div>{day}</div>
      <div>{t(i18nKeys.DAY)}</div>
    </div>
  );

  return (
    <form className={styles('PostponeForm')} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles('wrapper')}>
        <div className={styles('content')}>
          {invoices.length === 1 && <HTML html={invoices[0].next_step_information} />}
        </div>
        <div className={styles('form-wrapper')}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="days_delayed"
            render={({ field: { ref: _, ...values } }) => (
              <CustomRadio
                {...values}
                nameItem="postpone"
                className={styles('custom-radio')}
                label={contentRadio(7)}
                valueItem="7"
              />
            )}
          />
          <Controller
            control={control}
            rules={{ required: true }}
            name="days_delayed"
            render={({ field: { ref: _ref, ...values } }) => (
              <CustomRadio
                {...values}
                nameItem="postpone"
                className={styles('custom-radio')}
                label={contentRadio(14)}
                valueItem="14"
              />
            )}
          />
          <Controller
            control={control}
            name="days_delayed"
            rules={{ required: true }}
            render={({ field: { ref: _ref, ...values } }) => (
              <CustomRadio
                {...values}
                nameItem="postpone"
                className={styles('custom-radio')}
                label={contentRadio(21)}
                valueItem="21"
              />
            )}
          />
          <Controller
            control={control}
            name="days_delayed"
            rules={{ required: true }}
            render={({ field: { ref: _ref, ...values } }) => (
              <CustomRadio
                {...values}
                nameItem="postpone"
                className={styles('custom-radio')}
                label={contentRadio(28)}
                valueItem="28"
              />
            )}
          />
        </div>
        {errors.days_delayed && (
          <div className={styles('error-message')}>{t(i18nKeys.FORM.SELECT_AT_LEAST_ONE)}</div>
        )}
        <div className={styles('buttons-wrapper')}>
          <Button
            noMargin
            color={ButtonColor.GREY}
            label={t(i18nKeys.INVOICING.DONT_CHANGE)}
            onClick={close}
          />
          <Button noMargin label={buttonLabel} type="submit" className={styles('button')} />
        </div>
      </div>
    </form>
  );
}
