import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import apiService from 'shared/service/api.service';
import { error as onError, success as onSuccess } from 'store/view/view.actions';

const { baseUrl } = apiService;

// #region Callbacks

const onFulfilled = (
  response: AxiosResponse<any>,
): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
  if (response?.data?.message) {
    onSuccess({
      data: response.data.message,
    });
  }
  return response;
};

const onRejected = (error: AxiosError<any> | Error): Promise<never> => {
  if (Axios.isAxiosError(error)) {
    onError({
      data:
        // @ts-ignore
        error.response?.data?.error_message ||
        // @ts-ignore
        error.response?.data?.message ||
        error.response?.statusText,
    });
  }
  return Promise.reject(error);
};

// #region Instances

export const ORVAL_AXIOS_INSTANCE = Axios.create({
  baseURL: baseUrl,
});

ORVAL_AXIOS_INSTANCE.interceptors.response.use(onFulfilled, onRejected);

export const customInstance = <T>({ headers, ...config }: AxiosRequestConfig): Promise<T> => {
  const promise = ORVAL_AXIOS_INSTANCE({
    headers: {
      ...headers,
      tenant: apiService.tenant,
    },
    ...config,
  }).then(({ data }) => data);

  return promise;
};

export default customInstance;

export interface ErrorType<Error> extends AxiosError<Error> {}
