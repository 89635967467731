import classNames from 'classnames/bind';

import logoColorWhite from './assets/logo-color-white.svg';
import logoSmallDark from './assets/logo-dark.svg';
import logoSmallGrey from './assets/logo-grey.svg';
import logoGrey from './assets/logo-white.svg';
import logoBlue from './assets/recovr.svg';
import logoSmallRecovr from './assets/small-recovr.svg';

import styleIdentifiers from './logo.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  type: 'blue' | 'white' | 'grey' | 'small-grey' | 'small-dark' | 'small';
  width?: string | number;
  className?: string;
  alt?: string;
  height?: number | string;
};

const logoImg = {
  blue: logoBlue,
  white: logoColorWhite,
  grey: logoGrey,
  'small-grey': logoSmallGrey,
  'small-dark': logoSmallDark,
  small: logoSmallRecovr,
};

export default function Logo({ type, width, height, className, alt }: Props) {
  const style = {
    width: width || '100%',
    height,
  };
  return (
    <div className={styles('Logo', className)}>
      <img src={logoImg[type]} style={style} alt={alt} />
    </div>
  );
}
