import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Input } from 'shared/io';
import { FieldArray } from 'types/react-hook-form';

import styleIdentifiers from './invoiceJournal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  formArray: FieldArray;
};

export default function InvoiceJournal({ formArray }: Props) {
  const { t } = useTranslation();

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const { billing_logs_attributes } = watch();

  const removeLine = (index) => () => {
    const lines: any[] = billing_logs_attributes;
    lines.splice(index, 1);
    formArray.remove(index);
  };

  return (
    <div className={styles('InvoiceJournal')}>
      {formArray.fields.map((item, index) => {
        const line = billing_logs_attributes && billing_logs_attributes[index];
        const prefix = line?.prefix;

        let counter = line?.counter;
        counter = counter ? +counter : 0;

        counter += 1;
        let next;
        if (prefix) next = `${prefix} - ${counter}`;

        return (
          <div className={styles('line', 'grid-row')} key={item.rhfId}>
            <div className={styles('col-2')}>
              <Input
                errorMessage={
                  errors.billing_logs_attributes?.[index] &&
                  errors.billing_logs_attributes[index].prefix
                }
                register={register(`billing_logs_attributes.${index}.prefix`, {
                  required: true,
                })}
                defaultValue={line?.prefix}
                className={styles('input')}
                noMargin
                label={t(i18nKeys.FORM.INVOICING.PREFIX)}
                type="text"
              />
            </div>
            <input
              {...register(`billing_logs_attributes.${index}.id` as any)}
              defaultValue={line?.id}
              className="invisible-input"
            />
            <div className={styles('col-3')}>
              <Input
                register={register(`billing_logs_attributes.${index}.counter`)}
                className={styles('input')}
                noMargin
                defaultValue={line?.counter}
                type="number"
                // min={counter}
                label={t(i18nKeys.FORM.INVOICING.CURRENT_COUNTER)}
              />
            </div>
            <div className={styles('col-5', 'next')}>
              <div className={styles('label')}>
                {t(i18nKeys.FORM.INVOICING.NEXT_INVOICE_REFRENCE)}
              </div>
              <div className={styles('value-wrapper')}>
                <span>{next}</span>
              </div>
              <div className={styles('remove')} onClick={removeLine(index)}>
                <Icon name={IconName.TRASH_SIMPLE} size="16px" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
