import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import { FieldError } from 'react-hook-form';
import { validationMessage } from 'shared/utils/validation';
import { OnChangeEvent } from 'types/html-event';

import { useId } from '@mantine/hooks';

import styleIdentifiers from './Checkbox.module.scss';

const styles = classNames.bind(styleIdentifiers);

type CheckboxProps = {
  label?: ReactNode;
  className?: string;
  errorMessage?: FieldError | string | false;
  tabIndex?: number;
  disabled?: boolean;
  noMargin?: boolean;
  onChange?: Function;
  checked?: boolean;
  watch?: any;
  register?: any;
  name?: string;
  id?: any;
};

export const Checkbox = ({
  register,
  noMargin,
  errorMessage,
  onChange,
  label,
  children,
  className,
  checked,
  tabIndex,
  disabled,
  watch,
}: PropsWithChildren<CheckboxProps>) => {
  const { t } = useTranslation();
  const id = useId();

  const value = (watch ? watch(register.name) : checked) || false;

  const handleChange = (e: OnChangeEvent) => {
    const val = e.target.checked;
    onChange?.(val);
    register?.onChange(e);
  };

  return (
    <div
      className={styles(
        'Checkbox',
        className,
        errorMessage && 'error',
        disabled && 'disabled',
        noMargin && 'no-margin',
      )}
    >
      <label className={styles('label')} htmlFor={id}>
        <div className={styles('checkbox', value && 'checked')} />
        <input
          id={id}
          tabIndex={tabIndex}
          className={styles('input')}
          type="checkbox"
          checked={watch ? undefined : value}
          disabled={disabled}
          {...register}
          onChange={handleChange}
        />
        {(children || label) && (
          <span className={styles({ text: !!label, textLabel: true })}>{children || label}</span>
        )}
      </label>
      {errorMessage && (
        <div className={styles('error-message')}>
          {t(
            validationMessage[(errorMessage as FieldError).type] ||
              (errorMessage as FieldError).message ||
              validationMessage.invalid,
          )}
        </div>
      )}
    </div>
  );
};
