import { treatClient } from 'shared/serializer';
import { clientConstants as events } from 'store/client/client.actions';
import {
  baseReducerData,
  baseReducerListPage,
  handlePageRequest,
  handlePageResponse,
  handleRequest,
  handleResponse,
} from 'store/reducers';
import { ReduxAction } from 'types/redux';
import { ClientState } from 'types/store/client-state';

export const initialState: ClientState = {
  list: baseReducerListPage,
  current: baseReducerData,
  search: null as any,
  toConfirm: baseReducerListPage,
  isLoading: false,
};

const reducer = (state: ClientState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case events.batchAction.request:
      return { ...state, isLoading: true };
    case events.batchAction.result:
      return { ...state, isLoading: false };
    case events.listPage.request:
      return handlePageRequest(state, action, 'list');
    case events.listPage.result:
      return handlePageResponse(state, action, 'list', (item) => item);
    case events.detail.request:
      return handleRequest(state, action, 'current', true);
    case events.detail.result:
      return handleResponse(state, action, 'current', treatClient);
    case events.search.result:
      return handleResponse(state, action, 'search', treatClient);
    case events.update.result:
      return handleResponse(state, action, 'current', treatClient);
    case events.getAllToConfirm.request:
      return handlePageRequest(state, action, 'toConfirm');
    case events.getAllToConfirm.result:
      return handlePageResponse(state, action, 'toConfirm', treatClient);
    case events.getToConfirm.request:
      return handleRequest(state, action, 'current', true);
    case events.getToConfirm.result:
      return handleResponse(state, action, 'current', treatClient);
    case events.validateAllImportedClients.result:
      const newState = { ...state };
      if (action.status === 'fulfilled') {
        newState.toConfirm = baseReducerListPage;
      }
      return newState;
    default:
      return state;
  }
};

export default reducer;
