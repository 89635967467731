import classNames from 'classnames/bind';
import { Input } from 'shared/io';
import { OnChangeEvent } from 'types/html-event';

import styleIdentifiers from './IntervalFields.module.scss';

const styles = classNames.bind(styleIdentifiers);

type IntervalFieldsProps = {
  register: any;
  label: string;
  nameFrom: string;
  nameTo: string;
  className?: string;
  placeholderFrom: string;
  placeholderTo: string;
  onChange: (e: OnChangeEvent) => {};
};

export const IntervalFields = ({
  register,
  label,
  nameFrom,
  nameTo,
  className,
  placeholderFrom,
  placeholderTo,
  onChange,
}: IntervalFieldsProps) => (
  <div className={styles('IntervalFields', className)}>
    <div className={styles('label-input')}>{label}</div>
    <div className={styles('input-wrapper')}>
      <Input
        register={register(nameFrom)}
        className={styles('value')}
        defaultValue=""
        type="number"
        step="1"
        min="0"
        placeholder={placeholderFrom || 'De'}
        noBorder
        noMargin
        onValueChanged={onChange}
      />
      <Input
        register={register(nameTo)}
        className={styles('value')}
        defaultValue=""
        type="number"
        step="1"
        min="0"
        noMargin
        placeholder={placeholderTo || 'à'}
        noBorder
        onValueChanged={onChange}
      />
    </div>
  </div>
);
