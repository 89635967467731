import { useEffect } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { Redirect } from 'react-router';
import { isDefined, isNonNullish } from 'remeda';
import { Tenant, useValidateTenant } from 'shared/hooks';
import apiService from 'shared/service/api.service';

import * as Sentry from '@sentry/react';

import { LoadingScreen } from './Private/LoadingScreen';

interface TenantValidatorProps extends React.PropsWithChildren<{}> {}

// @ts-ignore We ignore the type error here because we inforce the invariant below; we don't
// want to spend our time doing conditional navifgation on the tenant when the app just shouldn't load as song as it's not loaded
export const tenantAtom = atom<Tenant>(undefined);
tenantAtom.debugLabel = 'Tenant';

export const useTenant = () => useAtomValue(tenantAtom);

export const TenantValidator = ({ children }: TenantValidatorProps) => {
  const { tenant, tenantError } = useValidateTenant(apiService.tenant);
  const [tenantFromAtom, setTenant] = useAtom(tenantAtom);

  useEffect(() => {
    if (isDefined(tenant)) {
      setTenant(tenant);
      Sentry.setTag('tenant', tenant?.domain);
    }
  }, [tenant, setTenant]);

  // This request is typically short, and mounting the LoadingScreen here as well as lower down in PrivateLoader causes the animation to sutter
  if (tenantError?.response?.status === 404) return <Redirect to="/bad-company" />;
  if (isNonNullish(tenantError)) return <Redirect to="/server-error" />;

  if (tenantFromAtom == null) return <LoadingScreen />;

  if (!isDefined(tenant)) console.error("Expected tenant to be defined but it wasn't");

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
