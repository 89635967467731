import { useEffect, useState } from 'react';
import { useTenant } from 'app/TenantValidator';
import { motion } from 'framer-motion';
import { useBootIntercom, useInitialisePosthog } from 'init';
import { useAtomValue } from 'jotai';
import { Redirect, useHistory } from 'react-router';
import { isDefined, isNonNullish, isNullish } from 'remeda';
import {
  profileAtom,
  useEnforceProfile,
  useLoadCompany,
  useLoadCompanyV1,
  useLoadPrivateConstants,
} from 'shared/hooks';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import { LoadingScreen } from './LoadingScreen';

interface LoggedLoaderProps extends React.PropsWithChildren<{}> {}

const _PrivateLoader = ({ children }: LoggedLoaderProps) => {
  const history = useHistory();
  const tenant = useTenant();

  // These are the 4 requests that must complete before the app can be considered loaded and children rendered
  const { profile, profileError } = useEnforceProfile();
  const { privateConstantsError, isLoadingPrivateConstants } = useLoadPrivateConstants();
  const { isCompanyLoading: isCompanyV2Loading, companyError: companyV2Error } = useLoadCompany();
  const { isCompanyV1Loaded } = useLoadCompanyV1();

  const profileFromAtom = useAtomValue(profileAtom);

  useInitialisePosthog();
  useBootIntercom();

  const [isAnimationDone, setIsAnimationDone] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsAnimationDone(true);
    }, 1000); // Must be synced with the animation duration in the LoadingScreen component
  }, []);

  useEffect(() => {
    if (
      isDefined(profile) &&
      !profile.hasAcceptedGc &&
      !window.location.pathname.includes('terms')
    ) {
      history.push('/terms-and-conditions');
    } else if (!tenant.onboarding.isComplete && !window.location.pathname.includes('onboarding')) {
      history.push('/onboarding');
    }
  }, [profile?.hasAcceptedGc, tenant.onboarding.isComplete]);

  const isAppLoading =
    !isCompanyV1Loaded ||
    isCompanyV2Loading ||
    isLoadingPrivateConstants ||
    isNullish(profileFromAtom);

  const hasLoadingFailed =
    isNonNullish(companyV2Error) ||
    isNonNullish(profileError) ||
    isNonNullish(privateConstantsError);

  if (hasLoadingFailed) return <Redirect to="/server-error" />;

  if (isAppLoading || !isAnimationDone) return <LoadingScreen />;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      {children}
    </motion.div>
  );
};

export const PrivateLoader = withAuthenticationRequired(_PrivateLoader);
