import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CustomSelect, Input } from 'shared/io';
import { useFilterForm } from 'shared/utils/hooks';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './TemplateFilters.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues: any;
  filterObject: any;
};

export default function TemplateFilters({ initialValues, onSubmit, filterObject }: Props) {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);

  const { form, resetForm, submit } = useFilterForm({
    onSubmit,
    initialValues,
    fieldToRegister: [],
  });
  const { handleSubmit, register } = form;

  useEffect(() => {
    if (filterObject) {
      filterObject.reset = () => {
        resetForm();
      };
    }
  }, [filterObject]);

  const itemsWithoutTasks = constants.recovery_step_types.template_types.filter(
    (e) => e.value !== 'task_generation',
  );

  return (
    <FormProvider {...form}>
      <form className={styles('template-filters')} onSubmit={handleSubmit(submit)}>
        <div className={styles('container-fields')}>
          <div className={styles('fields')}>
            <div className={styles('large')}>
              <Input
                register={register('name')}
                noMargin
                withBorder
                defaultValue=""
                shadow
                type="text"
                label={t(i18nKeys.NAME)}
                onValueChanged={submit}
              />
            </div>
            <div className={styles('large')}>
              <Controller
                defaultValue=""
                name="step_type"
                render={() => (
                  <CustomSelect
                    name="step_type"
                    noMargin
                    size="small"
                    onChange={(value) => {
                      submit(value);
                      return value;
                    }}
                    items={itemsWithoutTasks}
                    keyText="description"
                    keyValue="value"
                    withBorder
                    noBorder
                    shadow
                    label={t(i18nKeys.FORM.TYPE)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
