import { treatActivity, treatInvoice } from 'shared/serializer';
import { removeAttributes } from 'shared/utils/view';
import { invoiceConstants as events } from 'store/invoice/invoice.actions';
import {
  baseReducerData,
  baseReducerInfinitePage,
  baseReducerListPage,
  handlePageRequest,
  handlePageResponse,
  handleRequest,
  handleReset,
  handleResponse,
  handleUpdateMultiplePaginationResponse,
} from 'store/reducers';
import { settingsConstants } from 'store/settings/settings.actions';
import { File } from 'types/file';
import { ReduxAction } from 'types/redux';
import { InvoiceState } from 'types/store/invoice-state';

export const initialState: InvoiceState = {
  toHandle: baseReducerListPage,
  list: {
    ...baseReducerInfinitePage,
    pages: [],
  },
  postponable: baseReducerListPage,
  current: baseReducerData as any,
  currentActivity: baseReducerData as any,
  toConfirm: baseReducerListPage,
  invoiceActionData: baseReducerData,
  isLoading: false,
};

const addActivities = (state: InvoiceState, { payload }) => {
  const currentActivity = { ...state.currentActivity };
  currentActivity.data = [
    ...state.currentActivity.data,
    ...payload.data.map((activity) => treatActivity(activity)),
  ];
  currentActivity.metadata = payload.metadata;
  return { ...state, currentActivity };
};

const onCommentInvoice = (state: InvoiceState, { data }): InvoiceState => ({
  ...state,
  current: {
    ...state.current,
    data: {
      ...state.current.data,
      comments_attributes: [
        ...state.current.data.comments_attributes,
        removeAttributes(data.comment.data),
      ],
    },
  },
  currentActivity: {
    ...state.currentActivity,
    data: [data.activity, ...state.currentActivity.data],
  },
});

const reducer = (state: InvoiceState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case events.listPage.request:
      return handlePageRequest(state, action, 'list');
    case events.listPage.result:
      return handlePageResponse(state, action, 'list', removeAttributes);
    case events.detail.request:
      return handleRequest(state, action, 'current', true);
    case events.setSendingOptions.result:
    case events.detail.result:
      return handleResponse(state, action, 'current', treatInvoice);
    case events.send.request:
    case events.actions.thirdParty.request:
    case events.actions.formalNotice.request:
    case events.updateOriginalFile.request:
    case events.actions.additionalReminder.request:
    case events.actions.batchAction.request:
    case settingsConstants.switchPlansInvoices.request:
    case events.validateAllImportedInvoices.request:
      return { ...state, isLoading: true };
    case events.send.result:
    case events.actions.thirdParty.result:
    case events.actions.formalNotice.result:
    case events.updateOriginalFile.result:
    case events.actions.additionalReminder.result:
    case events.actions.batchAction.result:
    case settingsConstants.switchPlansInvoices.result:
      return { ...state, isLoading: false };
    case events.addAdditionalFile.result:
      const files: any = state.current.data!.additional_files_attributes;
      files.push({
        ...action.payload.data.attributes,
        id: action.payload.data.id,
      });

      return {
        ...state,
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            additional_files_attributes: files.slice(),
          },
        },
      };
    case events.deleteAdditionalFile.result:
      const files1 = state.current.data!.additional_files_attributes;
      const fileIndex = files1.findIndex((file: File) => action.payload.document_id === file.id);
      files1.splice(fileIndex, 1);

      return {
        ...state,
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            additional_files_attributes: files1.slice(),
          },
        },
      };
    case events.detail.reset:
      return handleReset(state, action, 'current');
    // to handle
    case events.toHandle.request:
      return handlePageRequest(state, action, 'toHandle');
    case events.toHandle.result:
      return handlePageResponse(state, action, 'toHandle', (item) => item);
    // postponable
    case events.postponable.request:
      return handlePageRequest(state, action, 'postponable');
    case events.postponable.result:
      return handlePageResponse(state, action, 'postponable', (item) => item);
    // current activity
    case events.activity.request:
      return handleRequest(state, action, 'currentActivity', true);
    case events.activity.result:
      return handleResponse(state, action, 'currentActivity', treatActivity);
    case events.getActivityPage.result:
      return addActivities(state, action);
    case events.getGroupedInvoices.request:
      return handleRequest(state, action, 'invoiceActionData', true);
    case events.actions.forceReminder.result:
      return handleUpdateMultiplePaginationResponse(state, action, 'postponable', treatInvoice);
    case events.actions.comment.result:
      return onCommentInvoice(state, action.payload);
    case events.getAllToConfirm.request:
      return handlePageRequest(state, action, 'toConfirm');
    case events.getAllToConfirm.result:
      return handlePageResponse(state, action, 'toConfirm', treatInvoice);
    case events.getToConfirm.request:
      return handleRequest(state, action, 'current', true);
    case events.getToConfirm.result:
      return handleResponse(state, action, 'current', treatInvoice);
    case events.validateAllImportedInvoices.result:
      const newState = { ...state, isLoading: false };
      if (action.status === 'fulfilled') {
        newState.toConfirm = baseReducerListPage;
      }
      return newState;
    case events.previewTemplate.result:
      return handleRequest(state, action, 'preview_template');
    default:
      return state;
  }
};

export default reducer;
