import { useHistory } from 'react-router';

export function useEnhancedNavigation() {
  const history = useHistory();

  const handleNavigation = (url: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.ctrlKey || event.metaKey || event.button === 1) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };

  return handleNavigation;
}
