import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import { UploadZone } from 'shared/io';
import { DialogShowId, showDialog, sideMenuHide } from 'store/view/view.actions';

import ImportCsvForm from '../ImportCsvForm';

import styleIdentifiers from './importClientsForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function ImportClientsForm() {
  const { t } = useTranslation();

  const { control } = useForm({ shouldUnregister: true });

  const onUploadCsv = (file: File) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      title: t(i18nKeys.IMPORT_CSV),
      children: <ImportCsvForm debtor file={file} />,
    });
    sideMenuHide();
  };

  return (
    <form className={styles('ImportClientsForm')}>
      <div className={styles('head')}>{t(i18nKeys.FORM.CLIENT_IMPORT.TITLE)}</div>
      <div className={styles('content')}>
        <Controller
          control={control}
          defaultValue=""
          rules={{ required: true }}
          name="csv_file"
          render={() => (
            <UploadZone
              accept=".csv"
              info={t(i18nKeys.FORM.CLIENT_IMPORT.IMPORT_CLIENTS_LIST)}
              subinfo={t(i18nKeys.FORM.CLIENT_IMPORT.CSV_FILE_FORMAT)}
              noPreview
              onChange={onUploadCsv}
            />
          )}
        />
      </div>
    </form>
  );
}
