import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useLegacyAxiosInstance } from './utils';

const updateExternalMailVariablesSchema = z.object({
  id: z.string(),
  status: z.enum(['processed', 'unprocessed']).optional(),
  debtor_id: z.string().optional(),
  user_id: z
    .string()
    .optional()
    .describe("This is the associated account manager's id. Rename when migrating to v2"),
});

export type UpdateExternalMailVariables = z.infer<typeof updateExternalMailVariablesSchema>;

export async function updateExternalMailFn(
  axiosInstance: Promise<AxiosInstance>,
  data: UpdateExternalMailVariables,
) {
  const instance = await axiosInstance;
  return instance.put(`external_mails/${data.id}`, data);
}

export function useUpdateExternalMail() {
  const client = useQueryClient();
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, UpdateExternalMailVariables>({
    mutationFn: (variables) => updateExternalMailFn(axiosInstance, variables),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['externalMails'] });
    },
    onError: (error) => {
      notifications.show({
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
        color: 'red',
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    UpdateExternalMailVariables,
    'updateExternalMail'
  >('updateExternalMail', mutation);
}
