import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

const ExternalMailSchema = z.object({
  body: z.string(),
  original_mail_id: z.string(),
  subject: z.string(),
  to_mail: z.array(z.string()),
});

export type ExternalMail = z.infer<typeof ExternalMailSchema>;

export async function sendExternalMail(axiosInstance: Promise<AxiosInstance>, data: ExternalMail) {
  const instance = await axiosInstance;

  return instance.post('external_mails', ExternalMailSchema.parse(data));
}

export function useSendExternalMail() {
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, ExternalMail>({
    mutationFn: (data) => sendExternalMail(axiosInstance, data),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
    },
    onError: (error) => {
      notifications.show({
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
        color: 'red',
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, ExternalMail, 'sendExternalMail'>(
    'sendExternalMail',
    mutation,
  );
}
