import { buildActions, makeReqCons } from 'store/actions';
import { ReduxFunction } from 'types/redux';

export const accountConstants = {
  updateCompanyInfo: makeReqCons('account_update_company_info'),
  updateYukiSettings: makeReqCons('account_update_yuki'),
  updateHorusSettings: makeReqCons('account_update_horus'),
  updateDbasicsSettings: makeReqCons('account_update_dbasics'),
  updateTeamleaderSettings: makeReqCons('account_update_teamleader'),
  updateExactSettings: makeReqCons('account_update_exact'),
  updateBillitSettings: makeReqCons('account_update_billit'),
  exactFetchCompanies: makeReqCons('exact_fetch_companies'),
  horusFetchCompanies: makeReqCons('horus_fetch_companies'),
  syncIntegration: makeReqCons('sync_integration'),
  editBankingInfo: makeReqCons('edit-banking-info'),
  getCompany: makeReqCons('ACCOUNT_GET_COMPANY'),
  updateInvoiceConfigurations: makeReqCons('ACCOUNT_UPDATE_INVOICE_FOOTER'),
  updateCompany: makeReqCons('ACCOUNT_UPDATE_COMPANY'),
  updateCompanyFiles: makeReqCons('UPDATE_COMPANY_FILES'),
  importInvoice: makeReqCons('invoice_import'),
  activityPage: makeReqCons('account_activity_page'),
  activityDetail: makeReqCons('account_activity_detail'),
  activitySeen: makeReqCons('account_activity_seen'),
  activitySeenAll: makeReqCons('account_activity_seen_all'),
  setDigitealConfiguration: makeReqCons('set_digiteal_configuration'),
  resetSecretId: makeReqCons('reset_secret_id'),
  askUpgradePackage: makeReqCons('ask_upgrade_package'),
  getDigitealCGV: makeReqCons('get_digiteal_CGV'),
  getTerms: makeReqCons('GET_ACCOUNT_CGV'),
  uploadTerms: makeReqCons('PUT_ACCOUNT_CGV'),
};

type AccountActions = {
  updateCompanyInfo: Function;
  updateCompanyInfoRes: Function;
  syncIntegration: ReduxFunction;
  syncIntegrationRes: Function;
  updateYukiSettings: ReduxFunction;
  updateYukiSettingsRes: Function;
  updateHorusSettings: ReduxFunction;
  updateHorusSettingsRes: Function;
  updateDbasicsSettings: ReduxFunction;
  updateDbasicsSettingsRes: Function;
  updateTeamleaderSettings: ReduxFunction;
  updateTeamleaderSettingsRes: Function;
  updateExactSettings: ReduxFunction;
  updateExactSettingsRes: Function;
  updateBillitSettings: ReduxFunction;
  updateBillitSettingsRes: Function;
  exactFetchCompanies: ReduxFunction;
  exactFetchCompaniesRes: Function;
  horusFetchCompanies: ReduxFunction;
  horusFetchCompaniesRes: Function;
  editBankingInfo: ReduxFunction;
  editBankingInfoRes: Function;
  getCompany: Function;
  getCompanyRes: Function;
  updateInvoiceConfigurations: ReduxFunction;
  updateInvoiceConfigurationsRes: Function;
  updateCompany: Function;
  updateCompanyRes: Function;
  updateCompanyFiles: ReduxFunction;
  updateCompanyFilesRes: Function;
  setLocalCompanyRes: Function;
  importInvoice: Function;
  importInvoiceRes: Function;
  activity: Function;
  activityRes: Function;
  activityPage: ReduxFunction;
  activityPageRes: Function;
  activityDetail: ReduxFunction;
  activityDetailRes: Function;
  activitySeen: ReduxFunction;
  activitySeenRes: Function;
  activitySeenAll: ReduxFunction;
  activitySeenAllRes: Function;
  setDigitealConfiguration: ReduxFunction;
  setDigitealConfigurationRes: Function;
  resetSecretId: ReduxFunction;
  resetSecretIdRes: Function;
  askUpgradePackage: Function;
  askUpgradePackageRes: Function;
  getDigitealCGV: Function;
  getDigitealCGVRes: Function;
  getTerms: Function;
  getTermsRes: Function;
  uploadTerms: Function;
  uploadTermsRes: Function;
};

// Autogenerated actions according to constants
const accountActions = buildActions<AccountActions>('', accountConstants, {});

export { accountActions };
