import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales';

import { Icon, IconName } from '../Icon';

import styleIdentifiers from './addressBlock.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  // TODO: clean this with Address from 'api/models' instead of 'any'
  address: any;
  className?: string;
  inlined?: boolean;
  clean?: boolean;
  edit?: () => void;
  remove?: () => void;
};

export default function AddressBlock({ address, className, inlined, clean, edit, remove }: Props) {
  const { t, currentLang } = useTranslation();

  if (!address) {
    return null;
  }

  const {
    additional_line_1,
    additional_line_2,
    street_name,
    street_number,
    street_box,
    zip_code,
    country,
    country_code,
    city,
  } = (clean ? address.attributes : address) || {};

  const countryCleaned = country ?? countries.getName(country_code, currentLang);

  const commonAddressBlock = (
    <>
      <div>{additional_line_1 && additional_line_1}</div>
      <div>{additional_line_2 && additional_line_2}</div>
      <div>
        {street_name}
        {street_number && ` ${street_number}`}

        {street_box && ` ${t(i18nKeys.FORM.ADDRESS.BOX)} ${street_box}`}
      </div>
      {inlined && <span>,&nbsp;</span>}
      <div>
        {zip_code && `${zip_code} `}
        {city}
      </div>
      {inlined && country && <span>,&nbsp;</span>}
      {countryCleaned && <div>{countryCleaned}</div>}
    </>
  );

  if (edit) {
    return (
      <div className={styles('AddressBlock', 'flex', 'small')}>
        <div>{commonAddressBlock}</div>
        <div>
          <Icon className={styles('action-icon')} name={IconName.PENCIL} onClick={edit} />
          {remove && (
            <Icon className={styles('action-icon')} name={IconName.TRASH_SIMPLE} onClick={remove} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles('AddressBlock', className, inlined && 'inlined')}>
      {commonAddressBlock}
    </div>
  );
}
