// / <reference types="@remeda" />

import { isNumber, omitBy } from 'remeda';

import { useForm } from '@mantine/form';

function _areFieldsDirty<Values extends Record<string, any>>(
  form: ReturnType<typeof useForm<Values>>,
  ...fields: Array<keyof Values>
) {
  return fields.some((field) => form.isDirty(field));
}

export function createAreFieldsDirty<Values extends Record<string, any>>(
  form: ReturnType<typeof useForm<Values>>,
) {
  return (...fields: Array<keyof Values>) => _areFieldsDirty(form, ...fields);
}

export function getDirtyFields<Values extends Record<string, any>>(
  form: ReturnType<typeof useForm<Values>>,
  ...fields: Array<keyof Values>
) {
  return omitBy(form.values, (_, key) => !form.isDirty(key) || !fields.includes(key));
}

export function createGetDirtyFields<Values extends Record<string, any>>(
  form: ReturnType<typeof useForm<Values>>,
) {
  return (...fields: Array<keyof Values>) => getDirtyFields(form, ...fields);
}

function _areFieldsValid<Values extends Record<string, any>>(
  form: ReturnType<typeof useForm<Values>>,
  ...fields: Array<keyof Values>
): boolean {
  return fields.every((field) => !form.errors[field as string]);
}

export function createAreFieldsValid<Values extends Record<string, any>>(
  form: ReturnType<typeof useForm<Values>>,
) {
  return (...fields: Array<keyof Values>) => _areFieldsValid(form, ...fields);
}

export function numberOrNull(value: number | string): number | null {
  if (isNumber(value)) return value;

  if (value === '') return null; // Number('') === 0 in js

  const parsed = Number(value);
  if (isNaN(parsed)) return null;

  return Number(value);
}
