import React from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import BankAccount from 'shared/components/BankAccount';
import DateItem from 'shared/components/DateItem';
import { IconColor, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { transactionActions } from 'store/transaction/transaction.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';
import { Invoice } from 'types/store/invoice-state';
import { PaymentHistory } from 'types/store/transaction-state';

import { captureMessage } from '@sentry/react';

import styleIdentifiers from './historyListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  item: PaymentHistory;
  action: OnClickFunction;
};

export default function HistoryListItem({ item, action }: Props) {
  const { t, currentLang } = useTranslation();

  const unignorePayment = (paymentId) => () => {
    transactionActions.unignorePayments({
      data: {
        payment_ids: [paymentId],
      },
    });
  };

  const detailModal = (item: Invoice) => {
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail id={item.id} modal />,
    });
  };

  const constructReference = (item: Invoice) => (
    <b className={styles('reference')} onClick={() => detailModal(item)}>
      {item.reference}
    </b>
  );

  const constructMatchedInfo = () => {
    const list = item.matched_invoices_attributes;
    if (!list || list.length === 0) return t(i18nKeys.BANK_ACCOUNT.HISTORY.PAYMENT_NO_RECONCILE);

    return (
      <div>
        {t(
          item.matched
            ? i18nKeys.BANK_ACCOUNT.HISTORY.RECONCILE_WITH
            : i18nKeys.BANK_ACCOUNT.HISTORY.PARTIALLY_MATCHED_WITH,
        )}
        {': '}
        {list.map((it, index) => (
          <React.Fragment key={index}>
            {index > 0 && index < list.length - 1 && ', '}
            {index > 0 && index === list.length - 1 && ' et '}
            {constructReference(it)}
          </React.Fragment>
        ))}
      </div>
    );
  };

  // Sentry 173
  if (item.localized_money_object == null) {
    captureMessage('In `HistoryListItem.tsx`, `item.localized_money_object` is null.', {
      extra: { item },
    });
  }

  return (
    <tr className={styles('HistoryListItem')}>
      <td>
        <div onClick={action} className={styles('box', 'checkbox', item.checked && 'checked')} />
      </td>
      <td>{item.transaction_id}</td>
      <td>
        <BankAccount value={item.iban} small />
      </td>
      <td>{item.counterparty_name}</td>
      <td>
        <DateItem lg={currentLang} date={item.value_date} />
      </td>
      <td>
        <Amount
          localizedValue={item.amount}
          value={item.localized_money_object?.amount ?? item.amount}
          suffix={item.currency}
        />
      </td>
      <td>{item.communication}</td>
      <td className={styles('actions')}>
        <div>
          {item.ignored && (
            <Tooltip
              className={styles('unignored-payment')}
              contentClass={styles('tooltip')}
              selectorClass={styles('tooltip-selector')}
              oneLine
              direction={DropdownDirection.LEFT}
              icon={IconName.SPINNER}
              size="16px"
              onClick={unignorePayment(item.id)}
            >
              {t(i18nKeys.BANK_ACCOUNT.HISTORY.UNIGNORED_PAYMENT)}
            </Tooltip>
          )}
          <Tooltip
            contentClass={styles('tooltip')}
            oneLine
            direction={DropdownDirection.LEFT}
            icon={IconName.REPLACE}
            size="16px"
            color={
              item.matched
                ? IconColor.DARK_GREEN
                : item.matched_invoices_attributes?.length > 0
                  ? IconColor.WARN
                  : IconColor.DARK
            }
          >
            {constructMatchedInfo()}
          </Tooltip>
        </div>
      </td>
    </tr>
  );
}
