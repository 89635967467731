import { AvailableCondition, CreateAutomaticAssignationBody, Operator } from 'api/models';
import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { OperatorValues } from 'shared/forms/PreferencesForm';
import { CustomSelect, DateSelector, Input } from 'shared/io';
import { AVAILABLE_LANGUAGES } from 'types';

import styleIdentifiers from './AssignationConditionsForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  formArray: FieldArrayWithId<
    CreateAutomaticAssignationBody,
    'assignation_conditions_attributes',
    'id'
  >[];
  removeCondition: Function;
  availableConditions: AvailableCondition[];
};

export default function AssignationConditionsForm({
  formArray,
  removeCondition,
  availableConditions,
}: Props) {
  const { t, currentLang } = useTranslation();

  const { register, watch, setValue } = useFormContext();

  const countriesForSelect = Object.entries(countries.getNames(currentLang)).map(
    ([value, description]) => ({ value, description }),
  );

  const conditionName = (index: number) => watch(`assignation_conditions_attributes.${index}.name`);

  const operators = Object.values(Operator).map((e) => ({
    description: OperatorValues[e.toUpperCase() as keyof OperatorValues],
    value: e,
  }));

  const numberField = (index: number) =>
    availableConditions.find((condition) => condition.value === conditionName(index))?.var_type ===
    'number';

  const booleanField = (index: number) =>
    availableConditions.find((condition) => condition.value === conditionName(index))?.var_type ===
    'boolean';

  const dateFields = (index: number) =>
    availableConditions.find((condition) => condition.value === conditionName(index))?.var_type ===
    'date';

  const predefinedSelect = (index: number) => {
    if (numberField(index) || booleanField(index) || dateFields(index)) {
      return false;
    }
    return conditionName(index) && ['locale', 'country_code'].includes(conditionName(index));
  };

  const defaultSelect = (index: number) => {
    if (predefinedSelect(index) || numberField(index) || booleanField(index) || dateFields(index)) {
      return false;
    }
    return true;
  };

  const setDateArray = (value, index, arrayIndex) => {
    const otherIndex = arrayIndex === 0 ? 1 : 0;
    setValue(
      `assignation_conditions_attributes.${index}.value[${otherIndex}]`,
      watch(`assignation_conditions_attributes.${index}.value[${otherIndex}]`) || '',
    );
    setValue(`assignation_conditions_attributes.${index}.value[${arrayIndex}]`, value);
  };

  return (
    <div className={styles('assignation-conditions')}>
      {formArray?.map((item, index) => {
        register(`assignation_conditions_attributes.${index}.id`);
        if (watch(`assignation_conditions_attributes.${index}._destroy`)) {
          return undefined;
        }
        return (
          <div key={item.id} className={styles('assignation-condition')}>
            {index > 0 && <hr className={styles('separator')} />}
            <div className={styles('assignation-condition-item')}>
              <Controller
                name={`assignation_conditions_attributes.${index}.name`}
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    name={`assignation_conditions_attributes.${index}.name`}
                    label={t(i18nKeys.NAME)}
                    keyValue="value"
                    keyText="description"
                    items={availableConditions}
                    selectClassName={styles('input')}
                    withBorder
                    onChange={() => {
                      setValue(`assignation_conditions_attributes.${index}.value`, null);
                      setValue(`assignation_conditions_attributes.${index}.operator`, null);
                    }}
                  />
                )}
              />
              <Icon name={IconName.TRASH_SIMPLE} onClick={() => removeCondition(index)} />
            </div>
            {conditionName(index) && (
              <>
                {predefinedSelect(index) && (
                  <Controller
                    defaultValue=""
                    rules={{ required: true }}
                    name={`assignation_conditions_attributes.${index}.value`}
                    render={() => (
                      <CustomSelect
                        items={
                          conditionName(index) === 'locale'
                            ? AVAILABLE_LANGUAGES
                            : countriesForSelect
                        }
                        name={`assignation_conditions_attributes.${index}.value`}
                        label={t(i18nKeys.VALUE)}
                        withBorder
                        multiple
                        registerMultipleInput={register('valuesinput')}
                        placeholderFilter={t(i18nKeys.FORM.RESEARCH)}
                        keyText="description"
                        keyValue="value"
                        filter
                      />
                    )}
                  />
                )}
                {defaultSelect(index) && (
                  <Controller
                    defaultValue=""
                    rules={{ required: true }}
                    name={`assignation_conditions_attributes.${index}.value`}
                    render={() => (
                      <CustomSelect
                        items={formArray[index]?.value}
                        name={`assignation_conditions_attributes.${index}.value`}
                        label={t(i18nKeys.VALUE)}
                        withBorder
                        multiple
                        registerMultipleInput={register('valuesinput')}
                        canAdd
                        info={t(i18nKeys.FORM.CONFIRM_VALUE)}
                      />
                    )}
                  />
                )}
                {numberField(index) && (
                  <>
                    <Controller
                      name={`assignation_conditions_attributes.${index}.operator`}
                      rules={{ required: true }}
                      render={() => (
                        <CustomSelect
                          name={`assignation_conditions_attributes.${index}.operator`}
                          keyValue="value"
                          keyText="description"
                          label="Opérateur"
                          items={operators}
                          withBorder
                        />
                      )}
                    />
                    <Input
                      register={register(`assignation_conditions_attributes.${index}.value[0]`)}
                      label={t(i18nKeys.VALUE)}
                      type="number"
                      withBorder
                    />
                  </>
                )}
                {booleanField(index) && (
                  <Controller
                    name={`assignation_conditions_attributes.${index}.value[0]`}
                    rules={{ required: true }}
                    render={() => (
                      <CustomSelect
                        name={`assignation_conditions_attributes.${index}.value[0]`}
                        keyValue="value"
                        keyText="description"
                        label={t(i18nKeys.VALUE)}
                        items={[
                          {
                            description: t(i18nKeys.YES),
                            value: 'true',
                          },
                          {
                            description: t(i18nKeys.NO),
                            value: 'false',
                          },
                        ]}
                        withBorder
                      />
                    )}
                  />
                )}
                {dateFields(index) && (
                  <DateSelector
                    className={styles('input', 'date-selector')}
                    name={`assignation_conditions_attributes.${index}.value[0]`}
                    endName={`assignation_conditions_attributes.${index}.value[1]`}
                    placeholder={t(i18nKeys.OF)}
                    endPlaceholder={t(i18nKeys.FORM.TO)}
                    withBorder
                    shadow
                    label={t(i18nKeys.DATE)}
                    handleChange={(value) => {
                      setDateArray(value, index, 0);
                    }}
                    handleEndChange={(value) => {
                      setDateArray(value, index, 1);
                    }}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
