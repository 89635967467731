import { isDefined } from 'remeda';

import { Badge, Group, Text } from '@mantine/core';

const badgeStyles = {
  notification: {
    root: {
      padding: '11px 6px',
    },
  },
  tag: {
    root: {
      borderColor: '#CCD7DE',
      borderWidth: 1,
      padding: '11px 6px',
    },
  },
};

interface MenuItemBadgesProps {
  unreadCount?: number;
  tagLabel?: string;
}

export const MenuItemBadges = ({ unreadCount, tagLabel }: MenuItemBadgesProps) => {
  if (!isDefined(unreadCount) && !isDefined(tagLabel)) return null;

  return (
    <Group gap={5}>
      {isDefined(unreadCount) && unreadCount > 0 && (
        <Badge styles={badgeStyles.notification} size="sm" color="#CCD7DE" radius={6}>
          <Text fw={500} fz={12} c="#345D79">
            {unreadCount}
          </Text>
        </Badge>
      )}
      {isDefined(tagLabel) && (
        <Badge
          styles={{ ...badgeStyles.tag, label: { textTransform: 'capitalize' } }}
          color="#F2F5F7"
          size="sm"
          radius={6}
        >
          <Text fw={500} fz={12} c="#345D79">
            {tagLabel}
          </Text>
        </Badge>
      )}
    </Group>
  );
};
