import { Id } from 'api/models';
import { useTranslation } from 'locales';
import { Link } from 'react-router-dom';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { Icon, IconName } from 'shared/components/Icon';
import { ReconciliationInvoice } from 'types/store/transaction-state';

import { styled } from '@stitches/react';

const AddButton = styled('div', {
  display: 'inline-block',
  borderRadius: '100%',
  width: '24px',
  height: '24px',
  backgroundColor: '#0e5cad',
  transition: 'all 0.2s ease',
  padding: '6px 4px 4px 4px',
  color: 'white',
  marginLeft: '25px',
  cursor: 'pointer',
  '&:hover': {
    filter: 'brightness(1.2)',
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        backgroundColor: '#ccc',
      },
    },
  },
});

const Input = styled('input', {
  borderBottom: '1px solid #24252A',
  fontSize: '12px',
});

interface InvoiceTableItemProps {
  item: ReconciliationInvoice;
  amounts: Record<Id, string>;
  disableButtons: boolean;
  handleSetAmount: (id: Id, amount: string) => void;
}

export const InvoiceTableItem = ({
  item,
  amounts,
  disableButtons,
  handleSetAmount,
}: InvoiceTableItemProps) => {
  const { currentLang } = useTranslation();

  const {
    id,
    reference,
    issue_date: issueDate,
    remaining_balance: remainingBalance,
    remaining_late_fees: remainingFees,
    currency,
    localized_money_object: {
      remaining_balance: remainingBalanceLocalized,
      remaining_late_fees: remainingFeesLocalized,
    },
  } = item;

  const amount = amounts[id];

  const isRemainingBalanceSaturated = Number(amount) >= remainingBalance;
  const isRemainingBalanceWithFeesSaturated =
    Number(amount) === Number(remainingBalance) + Number(remainingFees);

  return (
    <tr>
      <td />
      <td>
        <Link to={`/invoices/${id}`} target="_blank">
          {reference}
        </Link>
      </td>
      <td>
        <DateItem date={issueDate} lg={currentLang} format="DD/MM/YY" />
      </td>
      <td>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Amount
            style={{ display: 'inline' }}
            localizedValue={remainingBalanceLocalized}
            value={remainingBalance}
            suffix={currency}
          />
          <AddButton
            disabled={disableButtons || isRemainingBalanceSaturated}
            onClick={() => {
              handleSetAmount(item.id, String(remainingBalance));
            }}
          >
            <Icon size="12px" name={IconName.PLUS} />
          </AddButton>
        </div>
      </td>
      <td>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Amount
            style={{ display: 'inline' }}
            localizedValue={remainingFeesLocalized}
            value={Number(remainingBalance) + Number(remainingFees)}
            suffix={currency}
          />
          <AddButton
            disabled={disableButtons || isRemainingBalanceWithFeesSaturated}
            onClick={() => {
              handleSetAmount(item.id, String(Number(remainingBalance) + Number(remainingFees)));
            }}
          >
            <Icon size="12px" name={IconName.PLUS} />
          </AddButton>
        </div>
      </td>
      <td>
        <Input
          type="number"
          value={amount === '' ? amount : Number(amount)}
          onChange={(val) => handleSetAmount(id, val.target.value)}
        />
      </td>
    </tr>
  );
};
