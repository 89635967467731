import { i18nKeys, useTranslation } from 'locales';
import { Textfit } from 'react-textfit';
import { times } from 'remeda';
import { STEP_TO_STEP_THEME } from 'shared/constants';

import { Box, Group, Stack } from '@mantine/core';

export type CollectionStepIndex = 0 | 1 | 2 | 3 | 4 | 5;

interface CollectionStepProps {
  step: CollectionStepIndex;
}

export const CollectionStep = ({ step }: CollectionStepProps) => {
  const { t } = useTranslation();

  const { color, filledDots } = STEP_TO_STEP_THEME[step];

  const getColor = (index) => {
    if (index < filledDots) return color;
    return '#343944';
  };

  return (
    <Stack gap="4" fw={500}>
      <Textfit mode="single" max={16} min={12}>
        {t(i18nKeys.FOLLOW_UP.SUMMARY.COLLECTION_STEP[step])}
      </Textfit>
      <Group gap={5}>
        {times(6, (index) => (
          <Box key={index} h={4} w={15} style={{ borderRadius: '2px' }} bg={getColor(index)} />
        ))}
      </Group>
    </Stack>
  );
};
