import { removeAttributes } from 'shared/utils/view';
import { RecoveryPlan } from 'types/recovery-plan';
import { FullTemplate } from 'types/template';

export const treatTemplate = (item: any) => {
  const newItem = removeAttributes(item);

  if (newItem.email_template_bodies) {
    newItem.email_template = newItem.email_template_bodies.map((template) =>
      removeAttributes(template),
    );
    delete newItem.email_template_bodies;
  }
  if (newItem.letter_template_bodies) {
    newItem.letter_template = newItem.letter_template_bodies.map((template) =>
      removeAttributes(template),
    );
    delete newItem.letter_template_bodies;
  }

  return newItem as FullTemplate;
};

export const treatRecoveryPlan = (item: any) => {
  const newItem = removeAttributes(item);

  if (newItem.recovery_steps) {
    newItem.recovery_steps = newItem.recovery_steps.map((recovery_step) => {
      const item = removeAttributes(recovery_step);
      if (item.template) {
        item.template = removeAttributes(item.template);
      }
      return item;
    });
  }

  return newItem;
};

export const recoveryPlanSerializer = (item: any, oldPlan?: RecoveryPlan) => {
  const newPlan = { ...item };
  newPlan.recovery_steps_attributes = newPlan.recovery_steps.slice();
  newPlan.recovery_steps_attributes = newPlan.recovery_steps_attributes.map((step) => ({
    ...step,
  }));

  const stepsIds = oldPlan?.recovery_steps.map((step) => step.id) as number[];

  newPlan.recovery_steps_attributes = newPlan.recovery_steps_attributes.map(
    (recoveryStep, index) => {
      if (oldPlan && recoveryStep.id) {
        stepsIds.splice(
          stepsIds.findIndex((id) => id === recoveryStep.id),
          1,
        );
      }

      if (recoveryStep.template) {
        recoveryStep.template_id = recoveryStep.template.id;
        delete recoveryStep.template;
      }
      recoveryStep.order = index;

      delete recoveryStep.invoices_count;
      delete recoveryStep.fee_step_reached;

      return recoveryStep;
    },
  );
  if (oldPlan) {
    stepsIds.forEach((id, index) => {
      newPlan.recovery_steps_attributes.push({
        id,
        _destroy: true,
        order: newPlan.recovery_steps_attributes.length,
      });
    });
  }

  delete newPlan.recovery_steps;

  return newPlan;
};
