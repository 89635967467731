import React, { useEffect } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { InvoiceFooterField, useLoadInvoiceFooter } from 'shared/hooks';
import { isDynamicFooterField } from 'shared/utils';

import { Divider, Modal, Radio, Select, Text, TextInput } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { styled } from '@stitches/react';

import { ModalFooter } from '../ModalFooter';

const ItemOptionContainer = styled('div', {
  paddingLeft: '20px',
  display: 'flex',
  height: '100px',
  gap: '30px',
  alignItems: 'center',
  cursor: 'pointer',

  variants: {
    isChecked: {
      true: {
        backgroundColor: '#E7F5FF',
      },
      false: {
        opacity: 0.6,
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      },
    },
  },
});

interface ItemOptionProps extends React.PropsWithChildren<{}> {
  isChecked: boolean;
  onClick?: () => void;
}

const ItemOption = ({ isChecked, onClick, children }: ItemOptionProps) => (
  <ItemOptionContainer isChecked={isChecked} onClick={isChecked ? undefined : onClick}>
    <Radio readOnly size="md" checked={isChecked} />
    {children}
  </ItemOptionContainer>
);

interface EditFooterItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (newValue: InvoiceFooterField) => void;
  item: InvoiceFooterField;
}

export const EditFooterItemModal = ({
  isOpen,
  onClose,
  onSave,
  item,
}: EditFooterItemModalProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<InvoiceFooterField>(item);
  const textInputRef = React.useRef<HTMLInputElement>(null);
  const selectInputRef = React.useRef<HTMLInputElement>(null);
  const { invoiceFooter } = useLoadInvoiceFooter();

  useHotkeys([['Enter', () => onSave(value)]]);

  useEffect(() => setValue(item), [item, isOpen]);

  if (invoiceFooter == null) return null;

  const isNull = value == null;
  const isString = typeof value === 'string';
  const isDynamicField = isDynamicFooterField(value);

  const isDirty = value !== item;

  const selectOptions = invoiceFooter.availableFields.map(({ field, name }) => ({
    value: field,
    label: name,
  }));

  return (
    <Modal
      h="fit-content"
      opened={isOpen}
      onClose={onClose}
      title={t(i18nKeys.SETTINGS.INVOICING.FOOTER.MODAL_TITLE)}
      centered
      styles={{ body: { paddingLeft: '0px', paddingRight: '0px' } }}
    >
      <Divider my={0} />
      <ItemOption
        isChecked={isString}
        onClick={() => {
          setValue('');
          textInputRef.current?.focus();
        }}
      >
        <TextInput
          {...(isString && { 'data-autofocus': true })}
          ref={textInputRef}
          mt={0}
          placeholder={t(i18nKeys.SETTINGS.INVOICING.FOOTER.ENTER_TEXT)}
          value={isString ? value : ''}
          onChange={(event) => setValue(event.currentTarget.value)}
        />
      </ItemOption>
      <Divider my={0} />
      <ItemOption
        isChecked={isDynamicField}
        onClick={() => {
          setValue(
            // If deselecting then reselecting this option, attmept to use the initial value of the field
            // otherwise, pick the first option to fill the select.
            isDynamicFooterField(item)
              ? item
              : {
                  field: invoiceFooter?.availableFields[0].field,
                  content: invoiceFooter?.availableFields[0].content,
                },
          );
          selectInputRef.current?.focus();
        }}
      >
        <Select
          {...(isDynamicField && { 'data-autofocus': true })}
          placeholder={t(i18nKeys.SETTINGS.INVOICING.FOOTER.SELECT_FIELD)}
          allowDeselect={false}
          value={isDynamicField ? value.field : null}
          onChange={(val) =>
            setValue(invoiceFooter.availableFields.find((el) => el.field === val)!)
          }
          data={selectOptions}
        />
      </ItemOption>
      <Divider my={0} />
      <ItemOption isChecked={isNull} onClick={() => setValue(null)}>
        <Text fz="16px" lh="16px" fw={400} c="gray.7">
          {t(i18nKeys.SETTINGS.INVOICING.FOOTER.EMPTY)}
        </Text>
      </ItemOption>
      <Divider my={0} />
      <ModalFooter
        isDisabled={(isString && value === '') || !isDirty}
        onCancel={onClose}
        onSave={() => onSave(value)}
        withOwnPadding
      />
    </Modal>
  );
};
