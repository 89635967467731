import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { DateSelector } from 'shared/io';

import { InvoiceFormFieldValues, InvoiceFormSharedProps } from '../InvoiceForm.types';
import { getDefaultDueDate } from '../InvoiceForm.utils';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type DueDateFieldProps = InvoiceFormSharedProps;

export const DueDateField = ({ company }: DueDateFieldProps) => {
  const { setValue } = useFormContext<InvoiceFormFieldValues>();

  const defaultDate = getDefaultDueDate(company);

  const minDate = dayjs();

  return (
    <Controller
      name="due_date"
      rules={{ required: true }}
      defaultValue={defaultDate.toDate()}
      render={() => (
        <DateSelector
          name="due_date"
          placeholder={defaultDate.format('DD/MM/YYYY')}
          className={styles('date-selector')}
          inputClassName={styles('value')}
          errorClassName={styles('error')}
          noErrorText
          noMargin
          noBorder
          minDate={minDate}
          handleChange={(value: string) => {
            setValue('due_date', value);
          }}
        />
      )}
    />
  );
};
