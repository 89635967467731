import moment, { Moment, MomentInput } from 'moment';

export const momentToIsoDate = (date: Moment): string => date.format('YYYY-MM-DD');

type MomentKeyOf<T> = {
  [K in keyof T]: T[K] extends Moment ? K : never;
}[keyof T];

export function sortByDate<Item extends object>(
  items: Array<Item>,
  dateKey: MomentKeyOf<Item>,
  order: 'oldestFirst' | 'newestFirst' = 'newestFirst',
): Array<Item> {
  const sorted = items.sort((a, b) => {
    const dateA = moment(a[dateKey] as MomentInput);
    const dateB = moment(b[dateKey] as MomentInput);
    return dateA.valueOf() - dateB.valueOf();
  });

  return order === 'newestFirst' ? sorted.reverse() : sorted;
}
