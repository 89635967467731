import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';
import { StoreState } from 'types/storeTypes';

import { SettingsCard } from '../SettingsCard';

import CompanySettingsForm from './CompanySettingsForm';

export const CompanySettings = () => {
  const { t } = useTranslation();
  const company = useSelector((state: StoreState) => state.account.company.data!);

  const handleUpdate = (callback) => (values) => {
    const data = { ...values };
    if (company.phones_attributes.length > 0) {
      const differentPhone =
        data.phones_attributes[0].number !== company.phones_attributes[0].number;
      if (differentPhone) {
        data.phones_attributes[0] = {
          number: data.phones_attributes[0].number,
        };
        data.phones_attributes.push({
          ...company.phones_attributes[0],
          _destroy: true,
        });
      }
    }
    company.company_bank_accounts.forEach((bankAccount) => {
      if (data.company_bank_accounts) {
        if (
          !data.company_bank_accounts.some(
            (companyBankAccount) => companyBankAccount.id === bankAccount.id,
          )
        ) {
          data.company_bank_accounts.push({
            id: bankAccount.id,
            _destroy: true,
          });
        }
      } else {
        data.company_bank_accounts = company.company_bank_accounts.map(({ id }) => ({
          id,
          _destroy: true,
        }));
      }
    });
    data.company_bank_accounts_attributes = data.company_bank_accounts;
    delete data.bank_accounts_attributes;
    delete data.company_bank_accounts;
    accountActions.updateCompanyInfo({
      data,
      callback,
    });
  };

  return (
    <>
      <PageTitle>{t(i18nKeys.SETTINGS.COMPANY.PROFILE)}</PageTitle>
      <SettingsCard>
        <CompanySettingsForm onSubmit={handleUpdate} initialValues={company} />
      </SettingsCard>
    </>
  );
};
