import { treatTableViews } from 'shared/serializer';
import { handleResponse } from 'store/reducers';
import { ReduxAction } from 'types/redux';
import { AppState, ViewsByResource } from 'types/store/app-state';

export const initialState: AppState = {
  constants: null as any,
  viewsByResource: {} as ViewsByResource,
};

const reducer = (state: AppState = initialState, action: ReduxAction): AppState => {
  switch (action.type) {
    case 'GET_VIEWS_RESULT':
      return {
        ...state,
        viewsByResource: {
          ...state.viewsByResource,
          [action.requestData.view_type]: treatTableViews(action.payload.data).slice(),
        },
      };
    case 'APP_CONSTANTS_RESULT':
      return handleResponse(state, action, 'constants');
    default:
      return state;
  }
};

export default reducer;
