import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { useProfile } from './use-enforce-profile';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const NotificationsCountSchema = z
  .object({ notificationsCount: z.number() })
  .transform((data) => data.notificationsCount);

export type NotificationsCount = z.infer<typeof NotificationsCountSchema>;

export async function loadNotificationsCountFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/notifications/count');
  return NotificationsCountSchema.parse(data);
}

export function useLoadNotificationsCount() {
  const axiosInstance = useAxiosInstance();
  const profile = useProfile();

  const queryResult = useQuery<NotificationsCount, ApiError>({
    queryKey: ['notifications', 'count'],
    queryFn: () => loadNotificationsCountFn(axiosInstance),
    enabled: profile.hasAcceptedGc,
    placeholderData: 0,
  });

  return addResourceNameToQueryResult<NotificationsCount, ApiError, 'notificationsCount'>(
    'notificationsCount',
    queryResult,
  );
}
