import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RouteChildrenProps } from 'react-router';
import { useGetCompany } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK, IntegrationFormFieldsName } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import { TeamleaderForm, TeamleaderFormFields, TeamleaderFormFieldsName } from './TeamleaderForm';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Teamleader = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();

  const company = useGetCompany()!;

  const { isActive, canActivate, isConnected } = getIntegrationRights(
    Integration.TEAMLEADER,
    company,
  );

  useEffect(() => {
    if (!canActivate && !isActive) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, isActive, replace]);

  const handleSubmit = (data: TeamleaderFormFields) => {
    accountActions.updateTeamleaderSettings({
      data,
      callback: INTEGRATION_UPDATE_CALLBACK,
    });
  };

  if (!company?.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  const {
    tp_accounting: {
      use_import,
      issue_date_limit,
      automatic_sync,
      use_payment_matching,
      use_payment_export,
      debtor_update,
    },
  } = company;

  return (
    <article className={styles('teamleader')}>
      <IntegrationHeader
        name={Integration.TEAMLEADER}
        goBack={goBack}
        isActive={isActive}
        isConnected={isConnected}
      />
      <TeamleaderForm
        onSubmit={handleSubmit}
        initialValues={{
          [TeamleaderFormFieldsName.IMPORT_INTEGRATION]: use_import,
          [TeamleaderFormFieldsName.PAYMENT_MATCHING]: use_payment_matching,
          [TeamleaderFormFieldsName.PAYMENT_EXPORT]: use_payment_export,
          [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: issue_date_limit,
          [IntegrationFormFieldsName.DEBTOR_UPDATE]: debtor_update,
          [IntegrationFormFieldsName.AUTOMATIC_SYNC]: automatic_sync,
        }}
      />
    </article>
  );
};
