import { useEffect, useState } from 'react';
import { Id } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import TableFooter from 'shared/components/CustomTable/TableFooter';
import { Icon, IconName } from 'shared/components/Icon';
import ThirdpartyCaseListItem from 'shared/components/ThirdpartyCaseListItem';
import { thirdPartyCaseActions } from 'store/thirdpartyCase/thirdpartycase.actions';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './ThirdpartyCaseTable.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ThirdpartyCaseTableProps = {
  debtorId: Id;
};

type State = {
  sort_order: 'asc' | 'desc' | null;
  sort_by: string | null;
  page_limit: number | null;
};

export const ThirdPartyCaseTable = ({ debtorId }: ThirdpartyCaseTableProps) => {
  const { t } = useTranslation();
  const thirdpartyCases = useSelector((state: StoreState) => state.thirdpartyCase.list);
  const [sortData, setSortData] = useState<State>({
    sort_order: null,
    sort_by: null,
    page_limit: null,
  });
  const THIRD_PARTY_CASES_TABLE_HEADERS = [
    {
      key: 'case_type',
      title: t(i18nKeys.CASE_TYPE),
      width: 140,
    },
    {
      key: 'case_reference',
      title: t(i18nKeys.FORM.REFERENCE),
      width: 160,
    },
    {
      key: 'created_at',
      title: t(i18nKeys.CREATION_DATE),
      width: 140,
    },
    {
      key: 'updated_at',
      title: t(i18nKeys.LAST_UPDATE),
      width: 140,
    },
    {
      key: 'status',
      title: t(i18nKeys.STATUS),
      width: 120,
    },
    {
      key: 'total_tvac',
      title: t(i18nKeys.TOTAL_VAT_PRICE),
    },
    {
      key: 'remaining_balance',
      title: t(i18nKeys.BALANCE),
    },
  ];

  const getThirdPartyCases = (value?: any) => {
    thirdPartyCaseActions.listPage({
      debtor_id: debtorId,
      status: 'with_closed',
      ...value,
    });
  };

  useEffect(() => {
    getThirdPartyCases({ page: 1 });
  }, []);

  const items = thirdpartyCases?.pages || [];

  const setPage = ({ selected }) => {
    getThirdPartyCases({ page: selected + 1 });
  };
  const getOrderedInvoices = (key) => () => {
    const sortOrder = sortData.sort_order === 'asc' ? 'desc' : 'asc';

    getThirdPartyCases({
      ...sortData,
      sort_order: sortOrder,
      sort_by: key,
    });
    setSortData({
      ...sortData,
      sort_order: sortOrder,
      sort_by: key,
    });
  };
  const customKey = ({ case_type, id }) => case_type + id;

  const setPageLimit = (page_limit) => () => {
    getThirdPartyCases({ page: 1, page_limit });
    setSortData({
      ...sortData,
      page_limit,
    });
  };

  return thirdpartyCases ? (
    <div className={styles('thirdparty-case-table')}>
      <div className={styles('recovr-table')}>
        <table>
          <thead>
            <tr>
              <th className={styles('box-wrapper', 'small')} style={{ width: 30 }}>
                <div className={styles('not-sortable-head')} />
              </th>
              {THIRD_PARTY_CASES_TABLE_HEADERS.map((item, index) => (
                <th
                  key={index}
                  className={styles(
                    index === THIRD_PARTY_CASES_TABLE_HEADERS.length - 1 && 'last',
                    index === 0 && 'first',
                  )}
                >
                  {item.key ? (
                    <div className={styles('sortable-head')} onClick={getOrderedInvoices(item.key)}>
                      {item.title}
                      {sortData.sort_by === item.key && (
                        <Icon
                          name={IconName.TAILDOWN}
                          size="15px"
                          className={styles('arrow', sortData.sort_order === 'asc' && 'inverted')}
                        />
                      )}
                    </div>
                  ) : (
                    <div className={styles('not-sortable-head')}>{item.title}</div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <ThirdpartyCaseListItem
                key={customKey(item)}
                item={item}
                reloadInfo={getThirdPartyCases}
                noDebtorName
              />
            ))}
          </tbody>
        </table>
        {items.length === 0 && <div className={styles('no-result')}>{t(i18nKeys.NO_RESULT)}</div>}
      </div>
      {thirdpartyCases.metadata && (
        <TableFooter
          className={styles('table-footer')}
          pagination={thirdpartyCases.metadata.pagination}
          setPage={setPage}
          itemsLength={items.length}
          setPageLimit={setPageLimit}
        />
      )}
    </div>
  ) : null;
};
