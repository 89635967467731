import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

const InvitationSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
});

export type Invitation = z.infer<typeof InvitationSchema>;

export async function sendInvitationFn(axiosInstance: Promise<AxiosInstance>, data: Invitation) {
  const instance = await axiosInstance;
  return instance.post('send_invitations', InvitationSchema.parse(data));
}

export function useSendInvitation() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, Invitation>({
    mutationFn: (data) => sendInvitationFn(axiosInstance, data),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['users'],
      });
    },
    onError: (error) => {
      notifications.show({
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
        color: 'red',
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, Invitation, 'sendInvitation'>(
    'sendInvitation',
    mutation,
  );
}
