import { jotaiStore } from 'init';
import { i18nKeys, translationStateAtom } from 'locales';
import { formattedDate, removeAttributes } from 'shared/utils/view';
import { FilterType, TableView } from 'types/views';

export const treatTableViews = (items: any[]): TableView[] => {
  const tableTypes = items.map((item) => {
    const tableType = removeAttributes(item);

    tableType.table_columns = tableType.table_columns
      .map((column) => removeAttributes(column))
      .sort((i1, i2) => (i1.order > i2.order ? 1 : -1));

    tableType.filter_fields = tableType.filter_fields.map((filter_field) =>
      removeAttributes(filter_field),
    );

    return tableType;
  });

  return tableTypes;
};

export const filterToText = (
  filterType: FilterType,
  value,
  handler,
  operator,
  min_value,
  max_value,
) => {
  const { t, currentLang } = jotaiStore.get(translationStateAtom);

  if (handler || operator === 'null' || operator === 'not_null') {
    return t(
      (handler || operator) === 'not_null'
        ? i18nKeys.VIEWS.FILTERS.FILL_DATA
        : i18nKeys.VIEWS.FILTERS.EMPTY_DATA,
    );
  }

  switch (filterType) {
    case 'select':
      return Array.isArray(value) ? value.join(', ') : value;
    case 'boolean':
      return value && t(value === 'false' ? i18nKeys.NO : i18nKeys.YES);
    case 'date':
      const cleanedValue = Array.isArray(value) ? value : value?.split(',');
      return value
        ? `${formattedDate(cleanedValue[0], currentLang) || '∞'} - ${
            formattedDate(cleanedValue[1], currentLang) || '∞'
          }`
        : '';
    case 'number':
      return (
        (min_value || max_value) &&
        `${min_value && min_value !== 'null' ? min_value : '∞'} - ${
          max_value && max_value !== 'null' ? max_value : '∞'
        }`
      );
    default:
      return value;
  }
};
