import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Cards from 'react-credit-cards';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, MaskInput } from 'shared/io';
import { formatCreditCardNumber } from 'shared/utils/normalization';
import { onSubmitFunction } from 'types/react-hook-form';

import '~/../node_modules/react-credit-cards/es/styles-compiled.css';
import styleIdentifiers from './paymentForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  className?: string;
};

export default function PaymentForm({ className, onSubmit }: Props) {
  const { t } = useTranslation();

  const [focused, setFocused] = useState('');

  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({ shouldUnregister: true });

  const { bank_account_number, holder_name, expiry, verification_numbers } = watch();

  const handleInputFocus: any = (e: any) => {
    setFocused(e?.target.name === 'verification_numbers' ? 'cvc' : e?.target.name);
  };

  const onSetCreditCardNumber = (value) => {
    setValue('bank_account_number', formatCreditCardNumber(value.target.value));
  };

  return (
    <form className={styles('payment-form', className)} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles('card')}>
        <Cards
          number={bank_account_number || ''}
          name={holder_name || ''}
          expiry={expiry || ''}
          cvc={verification_numbers || ''}
          focused={focused}
        />
      </div>

      <Input
        register={register('bank_account_number', { required: true })}
        errorMessage={errors.bank_account_number}
        type="text"
        label={t(i18nKeys.FORM.STRIPE.CARD_NUMBER)}
        onChange={onSetCreditCardNumber}
        onFocus={handleInputFocus}
      />
      <Input
        errorMessage={errors.holder_name}
        register={register('holder_name', { required: true })}
        type="text"
        label={t(i18nKeys.FORM.STRIPE.FULL_NAME)}
        onFocus={handleInputFocus}
      />
      <div className="grid-row">
        <div className="col-6">
          <Controller
            control={control}
            defaultValue=""
            name="expiry"
            rules={{
              validate: (val: string) =>
                val === '__/__'
                  ? 'ERROR.FIELD_REQUIRED'
                  : val.indexOf('_') > -1
                    ? 'ERROR.NOT_FULLY_FILLED'
                    : true,
            }}
            render={({ field: { ref, ...values } }) => (
              <MaskInput
                {...values}
                name="expiry"
                error={errors.expiry}
                label={t(i18nKeys.FORM.STRIPE.EXPIRATION)}
                numberOnly
                mask="__/__"
                onFocus={handleInputFocus}
              />
            )}
          />
        </div>
        <div className="col-6">
          <Input
            register={register('verification_numbers', {
              required: true,
              validate: {
                maxLength4: (value) => (value > 9999 ? 'ERROR.INVALID' : true),
              },
            })}
            errorMessage={errors.verification_numbers}
            type="number"
            label={t(i18nKeys.FORM.STRIPE.CVC)}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
      <div className={styles('button-wrapper')}>
        <Button
          className={styles('submit-button')}
          label={t(i18nKeys.FORM.STRIPE.ADD_PAYMENT_METHOD)}
          type="submit"
        />
      </div>
    </form>
  );
}
