import classNames from 'classnames/bind';

import styleIdentifiers from './Loading.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  active: boolean;
  size?: 'medium' | 'small';
  className?: string;
};
export default function Loading({ active, size, className }: Props) {
  return active ? (
    <div className={styles('loading', size || 'medium', className)}>
      <div className={styles('logo')}>
        <div className={styles('circle', 'top')} />
        <div className={styles('circle', 'middle')} />
        <div className={styles('circle', 'left')} />
        <div className={styles('circle', 'right')} />
        <div className={styles('circle', 'left-bottom')} />
        <div className={styles('circle', 'right-bottom')} />
        <div className={styles('circle', 'bottom')} />
        <div className={styles('line', 'top-left')} />
        <div className={styles('line', 'top-right')} />
        <div className={styles('line', 'vertical', 'left-side')} />
        <div className={styles('line', 'vertical', 'right-side')} />
        <div className={styles('line', 'vertical', 'middle-top')} />
        <div className={styles('line', 'bottom-left')} />
        <div className={styles('line', 'bottom-right')} />
        <div className={styles('line', 'middle-top-right')} />
        <div className={styles('line', 'middle-bottom-right')} />
        <div className={styles('line', 'middle-bottom-left')} />
        <div className={styles('line', 'middle-top-left')} />
      </div>
    </div>
  ) : null;
}
