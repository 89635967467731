import { AxiosInstance } from 'axios';
import { omit } from 'remeda';
import apiService from 'shared/service/api.service';
import { LocaleSchema } from 'types';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const TenantSchema = z
  .object({
    id: z.number(),
    subdomain: z.string().min(1),
    companyName: z.string().min(1),
    logo: z.string().url().nullable(),
  })
  .transform((data) => ({
    ...data,
    url: window.location.origin.replace(apiService.tenant, data.subdomain),
  }));

const ProfileSchema = z
  .object({
    id: z.number(),
    profilePicture: z.string().url().nullable(),
    accessibleTenants: z.array(TenantSchema),
    email: z.string().email(),
    firstName: z.string().min(1),
    hasAcceptedGc: z.boolean(),
    intercomHash: z.string(),
    isActive: z.boolean(),
    lastName: z.string().min(1),
    locale: LocaleSchema,
    preferences: z.object({
      itemsPerPage: z.number(),
      emails: z.object({
        daily: z.boolean(),
        weekly: z.boolean(),
        notifications: z.boolean(),
      }),
    }),
    roles: z.array(z.string()),
    signature: z.string().nullable(),
  })
  .transform((data) => ({
    ...omit(data, ['firstName', 'lastName']),
    isAdmin: data.roles.includes('admin'),
    name: {
      first: data.firstName,
      last: data.lastName,
      full: `${data.firstName} ${data.lastName}`,
      initials: `${data.firstName.charAt(0).toUpperCase()}${data.lastName.charAt(0).toUpperCase()}`,
    },
  }));

export type Profile = z.infer<typeof ProfileSchema>;

export async function loadProfileQueryFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/profile');
  return ProfileSchema.parse(data);
}

interface LoadProfileVariables {
  enabled?: boolean;
}

export const useLoadProfile = ({ enabled = true }: LoadProfileVariables) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Profile, ApiError>({
    enabled,
    queryKey: ['profile'],
    queryFn: () => loadProfileQueryFn(axiosInstance),
  });

  return addResourceNameToQueryResult<Profile, unknown, 'profile'>('profile', queryResult);
};
