import { useState } from 'react';
import classNames from 'classnames/bind';
import { atom, useAtom } from 'jotai';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useLocation } from 'react-router';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import { IconName } from 'shared/components/Icon';
import { Button, UploadZone } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { useGetCompany } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { settingsActions } from 'store/settings/settings.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
} from 'store/view/view.actions';

import { Box } from '@mantine/core';
import { modals } from '@mantine/modals';

import ImportCsvForm from '../ImportCsvForm';

import styleIdentifiers from './invoiceImportForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const ublProgressAtom = atom<{
  active: boolean;
  errors?: any;
  value?: number;
}>({
  active: false,
});

type Props = {
  onlyCSV?: boolean;
};

export default function InvoiceImportForm({ onlyCSV }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const company = useGetCompany()!;
  const [ublFiles, setUblFiles] = useState<Array<File> | null>(null);
  const [progress, setProgress] = useAtom(ublProgressAtom);

  const uploadChange = (files) => {
    setUblFiles(files);
    setProgress({ active: false });
  };

  const onSelectPdfUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (location.pathname !== '/invoices/import-pdf') history.push('/invoices/import-pdf');
    sideMenuHide();
  };

  const onUploadCsv = (file: File) => {
    modals.open({
      title: t(i18nKeys.IMPORT_CSV),
      children: <ImportCsvForm update={onlyCSV} file={file} />,
      fullScreen: true,
    });
    sideMenuHide();
  };

  const importUbl = (planId?: any) => {
    accountActions.importInvoice({
      ublFiles,
      planId,
      callback: () => {
        setUblFiles(null);
        setProgress({ active: false });
      },
    });
  };

  const chooseRecoveryPlan = (e) => {
    e.stopPropagation();
    if (company.active_plans_count > 1) {
      settingsActions.recoveryPlanList({
        callback: (res) => {
          const plans = res.data.map((plan) => treatRecoveryPlan(plan));
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.PLAN.SELECT_PLAN),
            children: (
              <ChooseRecoveryPlanModal
                recoverPlans={plans}
                onClick={(planId) => {
                  importUbl!(planId);
                  dialogHide(DialogShowId.CUSTOM);
                }}
              />
            ),
          });
        },
      });
    } else {
      importUbl();
    }
  };

  return (
    <form className={styles('InvoiceImportForm')}>
      <div className={styles('head')}>{t(i18nKeys.INVOICE_IMPORT)}</div>
      <div className={styles('content')}>
        {!onlyCSV && (
          <UploadZone
            noMargin
            icon={IconName.XML}
            accept=".xml"
            info={t(i18nKeys.INVOICING.IMPORT.UBL_INVOICES)}
            noPreview
            onChange={uploadChange}
            multiple
            progress={progress}
            action={chooseRecoveryPlan}
            value={ublFiles}
          />
        )}
        {!onlyCSV && (
          <Box onClick={onSelectPdfUpload}>
            <UploadZone
              icon={IconName.PDF}
              accept=".pdf"
              info={t(i18nKeys.INVOICING.IMPORT.PDF_INVOICES)}
              noPreview
              multiple
              inert
            />
          </Box>
        )}
        <div className={styles('csv-container')}>
          <UploadZone
            icon={IconName.CSV}
            accept=".csv"
            info={t(i18nKeys.INVOICING.IMPORT.CSV_INVOICES)}
            noPreview
            onChange={onUploadCsv}
          />
          <div className={styles('mobile-element')}>{t(i18nKeys.UNAVAILABLE_WITH_SCREEN_SIZE)}</div>
        </div>
      </div>
      {!onlyCSV && company.unvalidated_invoices_count > 0 && (
        <div className={styles('button-wrapper')}>
          <Button
            noMargin
            label={`${t(i18nKeys.INVOICING.IMPORT.SEE_INVOICES_TO_CONFIRM)} (${
              company.unvalidated_invoices_count
            })`}
            onClick={() => {
              history.push('/invoices/to-confirm');
              sideMenuHide();
            }}
          />
        </div>
      )}
    </form>
  );
}
