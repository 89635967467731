import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetDebtorSendingOptions } from 'api';
import { Notification, SendingMediaNames } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import {
  addressesToAttributes,
  emailValues,
  getDefaultAddresses,
  getDefaultEmails,
  transformEmailsAndSubmit,
} from 'shared/utils/contacts';
import { useForceUpdate } from 'shared/utils/hooks';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { Invoice, SendingOptions } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import FormalNoticeForm from './FormalNoticeForm';

import styleIdentifiers from './formalNotice.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  invoice: Invoice;
  onSubmit: onSubmitFunction;
  items: any;
  invoiceActionData: any;
};

export default function FormalNotice({ onSubmit, invoice, items, invoiceActionData }: Props) {
  const { t } = useTranslation();
  const invoiceActionDataStore = useSelector(
    (state: StoreState) => state.invoice.invoiceActionData?.data,
  );
  const company = useSelector((state: StoreState) => state.account.company.data);

  const refContainer = useRef<HTMLDivElement | null>(null);

  const [previewEmail, setPreviewEmail] = useState<boolean>(true);
  const [displayEmail, setDisplayEmail] = useState<boolean>(false);
  const [displayPost, setDisplayPost] = useState<boolean>(true);
  const defaultTemplateId = company?.default_templates.formal_notice.id;
  const [currentTemplateId, setCurrentTemplateId] = useState(defaultTemplateId);

  const invoiceData = invoiceActionData || invoiceActionDataStore;
  const [state, _setState] = useState({
    pageNumber: 1,
    numberOfPages: 1,
    url: '',
    email: '',
  });

  const forceUpdate = useForceUpdate();
  const setState = (newState: any) => {
    _setState((oldState) => ({ ...oldState, ...newState }));
    forceUpdate();
  };

  useEffect(() => {
    if (currentTemplateId == null) return;

    getEmail(currentTemplateId);
    getPdf(currentTemplateId);
  }, [currentTemplateId]);

  const getPdf = (template_id?: any) => {
    invoiceActions.document({
      id: invoice.id,
      noLoading: true,
      data: {
        document_type: 'formal_notice_pdf',
        template_id,
        ...items,
      },
      callback: (data) => {
        const url = URL.createObjectURL(data);
        setState({ pageNumber: 1, url });
      },
    });
  };

  const getEmail = (template_id: any) => {
    invoiceActions.previewTemplate({
      id: invoice.id,
      noLoading: true,
      data: {
        document_type: 'formal_notice',
        template_id,
        manual_reminder: true,
        ...items,
      },
      callback: (data) => {
        setState({ email: data.email });
      },
    });
  };

  const onDocumentLoadSuccess = (pdf) => setState({ numberOfPages: pdf._pdfInfo.numPages });

  const { url, pageNumber, numberOfPages, email } = state;

  const {
    data: sendingOptionsResponse,
    isError,
    isLoading,
    refetch,
  } = useGetDebtorSendingOptions(invoice.debtor_id);

  const { emails, addresses } = sendingOptionsResponse || {
    emails: [],
    addresses: [],
  };

  const defaultEmails = getDefaultEmails(emails, Notification.formal_notice);
  const defaultEmailValues = emailValues(defaultEmails);
  const defaultAddresses = getDefaultAddresses(addresses, Notification.formal_notice);
  const addressesAttributes = addressesToAttributes(defaultAddresses);

  const initialValues = useMemo(
    () => ({
      ...(invoiceData.sending_options as SendingOptions | undefined),
      addresses_attributes: addressesAttributes,
      emails: defaultEmailValues,
      send_email: true,
      template_id: defaultTemplateId,
    }),
    [invoiceData, sendingOptionsResponse],
  );

  if (isLoading && !sendingOptionsResponse) return <RecovrLogoLoader />;

  if (isError || !sendingOptionsResponse) return <span>Error</span>;

  const onChangeTemplate = (templateId) => {
    setCurrentTemplateId(Number(templateId));
    if (displayEmail) getEmail(templateId);
    if (displayPost) getPdf(templateId);
  };

  const setPreview = (media) => {
    setPreviewEmail(media.email);
    if (!media.email) {
      setDisplayEmail(false);
      setDisplayPost(true);
    }
  };

  const switchDisplay = (medium) => {
    if (medium === SendingMediaNames.email && !displayEmail) getEmail(currentTemplateId);
    if (medium === SendingMediaNames.post && !displayPost) getPdf(currentTemplateId);
    setDisplayEmail(medium === SendingMediaNames.email);
    setDisplayPost(medium !== SendingMediaNames.email);
  };

  return (
    <div className={styles('FormalNotice')}>
      <div className={styles('left')} ref={refContainer}>
        <div className={styles('tab-wrapper')}>
          {previewEmail && (
            <div
              className={styles('tab-item', displayEmail && 'active')}
              onClick={() => {
                switchDisplay(SendingMediaNames.email);
              }}
            >
              {t(i18nKeys.EMAIL)}
            </div>
          )}
          <div
            className={styles('tab-item', displayPost && 'active')}
            onClick={() => {
              switchDisplay(SendingMediaNames.post);
            }}
          >
            {t(i18nKeys.POST)}
          </div>
        </div>
        {displayEmail && email && (
          <div className={styles('email-preview')}>
            <HTML className="email-preview" html={email} />
          </div>
        )}
        {displayPost && (
          <div className={styles('pdf-actions')} style={{ border: url ? '1px solid #bbb' : '' }}>
            {url ? (
              <>
                <div className={styles('container-actions')}>
                  {pageNumber > 1 && (
                    <Icon
                      name={IconName.MINIMAL_UP}
                      onClick={() => {
                        setState({ pageNumber: pageNumber - 1 });
                      }}
                    />
                  )}
                  {numberOfPages > pageNumber && (
                    <Icon
                      name={IconName.MINIMAL_DOWN}
                      onClick={() => {
                        setState({ pageNumber: pageNumber + 1 });
                      }}
                    />
                  )}
                </div>
                <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    width={(refContainer?.current?.offsetWidth || 680) - 10}
                    pageNumber={pageNumber}
                  />
                </Document>
              </>
            ) : (
              <div className={styles('loading')}>
                <div>{t(i18nKeys.LOADING)}</div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles('right')}>
        <FormalNoticeForm
          onChangeTemplate={onChangeTemplate}
          initialValues={initialValues}
          debtorEmails={emails}
          setPreview={setPreview}
          onSubmit={(values) => transformEmailsAndSubmit(emails, values, onSubmit)}
          addresses={addresses}
          refetchSendingOptions={refetch}
          debtorId={invoice.debtor_id}
        />
      </div>
    </div>
  );
}
