/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type DueDateDelay = (typeof DueDateDelay)[keyof typeof DueDateDelay];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DueDateDelay = {
  NUMBER_0: 0,
  NUMBER_3: 3,
  NUMBER_7: 7,
  NUMBER_999: 999,
} as const;
