import { PropsWithChildren } from 'react';
import { ObjectType } from 'api/models';
import classNames from 'classnames/bind';
import { Icon, IconName } from 'shared/components/Icon';

import styleIdentifiers from './ClientDetailTabsTimeline.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientDetailTabsTimelineItemProps = {
  type: ObjectType;
};

export const ClientDetailTabsTimelineItem = ({
  type,
  children,
}: PropsWithChildren<ClientDetailTabsTimelineItemProps>) => (
  <div className={styles('item', type)}>
    <div className={styles('see-detail')}>
      <Icon name={IconName.EXTERNAL_LINK} />
    </div>
    {children}
  </div>
);
