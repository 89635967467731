import { useState } from 'react';
import { useGetDebtorsInfinite } from 'api';
import { DebtorLight } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller } from 'react-hook-form';
import { concat, flat, map, pipe, prop, uniqueBy } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import { Id } from 'types';

import styleIdentifiers from './TaskForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskFormDebtorProps = {
  name?: 'debtor_id';
  currentDebtor?: DebtorLight | null;
  extraOptions?: Array<{ id: string; full_name: string }>;
};

export const TaskFormDebtor = ({
  name = 'debtor_id',
  currentDebtor,
  extraOptions,
}: TaskFormDebtorProps) => {
  const { t } = useTranslation();

  const [selectSearchValue, setSelectSearchValue] = useState<string>();
  const { data: debtorsResponse, fetchNextPage } = useGetDebtorsInfinite(
    { name: selectSearchValue },
    {
      query: {
        getNextPageParam,
      },
    },
  );

  const pages = debtorsResponse?.pages ?? [];
  const selectedDebtorArray = currentDebtor
    ? [{ id: currentDebtor.id, full_name: currentDebtor.attributes.full_name }]
    : [];

  const items = pipe(
    pages,
    map((e) => e.data),
    flat(),
    concat(selectedDebtorArray),
    map(treatClient),
    uniqueBy(prop('id')),
  );

  const options =
    debtorsResponse == null
      ? []
      : [
          ...(extraOptions?.map(treatClient) ?? []),
          ...(currentDebtor ? [currentDebtor].map(treatClient) : []),
          ...items.filter((el: { id: Id }) => el.id !== currentDebtor?.id),
        ];

  return (
    <Controller
      name={name}
      render={() => (
        <CustomSelect
          selectClassName={styles('input')}
          filter
          withBorder
          infiniteScroll
          onChangeFilter={setSelectSearchValue}
          items={options}
          keyValue="id"
          keyText="full_name"
          name={name}
          label={t(i18nKeys.TASK.ATTRIBUTES.DEBTOR)}
          load={fetchNextPage}
        />
      )}
    />
  );
};
