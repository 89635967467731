import { flattenItem } from 'store/reducers';

export const treatProduct = (item: any) => {
  const newItem = flattenItem(item);
  // new field for invoice
  if (newItem && !newItem.current_unity_price_htva) {
    newItem.current_unity_price_htva = newItem.price_htva;
  }
  return newItem;
};
