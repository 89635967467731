import { AssignableTo, AutomaticAssignation, CreateAutomaticAssignationBody } from 'api/models';
import { pick } from 'lodash-es';

export const getInitialValues = (
  automatic_assignation?: AutomaticAssignation,
): Partial<CreateAutomaticAssignationBody> => {
  if (!automatic_assignation) {
    return {};
  }

  const {
    attributes: { assign_to, assignation_conditions, ...rest },
  } = automatic_assignation;

  return {
    ...pick(rest, ['model_to_assign', 'order']),
    ...(assign_to
      ? {
          assign_to_id: assign_to.id,
          assign_to_type: assign_to.type as AssignableTo,
        }
      : undefined),
    ...(assignation_conditions
      ? {
          assignation_conditions_attributes: assignation_conditions.map((e) => ({
            id: e.id,
            ...e.attributes,
          })),
        }
      : undefined),
  };
};
