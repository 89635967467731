import { useState } from 'react';
import { useGetMergeableDebtors, useIgnoreDuplicates, useMergeSimilarDebtors } from 'api';
import { MergeSimilarDebtorsBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useParams } from 'react-router-dom';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import { Button, ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { AttributesSelection } from './AttributesSelection';
import { MergeableDebtor } from './MergeableDebtor';

import styleIdentifiers from './DuplicatesClientDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const DuplicatesClientDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id: baseDebtorId } = useParams<{ id: string }>();

  const {
    data: mergeableDebtorsListResponse,
    isLoading,
    isError,
  } = useGetMergeableDebtors(baseDebtorId);

  const { mutate: mergeDebtors } = useMergeSimilarDebtors();
  const { mutate: ignoreDuplicates } = useIgnoreDuplicates();

  const [checkedDebtorId, setCheckedDebtorId] = useState<string>('');

  if (isLoading && !mergeableDebtorsListResponse) {
    return <RecovrLogoLoader />;
  }

  if (isError || !mergeableDebtorsListResponse) {
    return <span>Error</span>;
  }

  const debtorsToMerge = mergeableDebtorsListResponse.data || [];

  const goToList = () => {
    history.push('/client/duplicates');
  };

  const skipMerge = () => {
    ignoreDuplicates(
      {
        id: baseDebtorId,
      },
      {
        onSuccess: () => {
          goToList();
        },
      },
    );
  };

  const submitMerge = (data: MergeSimilarDebtorsBody) => {
    mergeDebtors(
      {
        data,
      },
      {
        onSuccess: () => {
          dialogHide(DialogShowId.CUSTOM);
          goToList();
        },
      },
    );
  };

  const openAttributesSelectionModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(i18nKeys.CLIENT.MERGE.TITLE),
      children: (
        <AttributesSelection
          baseDebtorId={checkedDebtorId}
          debtors={debtorsToMerge}
          onSubmit={(data: MergeSimilarDebtorsBody) => submitMerge(data)}
        />
      ),
    });
  };

  return (
    <div className={styles('mergeable-clients')}>
      <div className={styles('title-line')}>
        <Icon name={IconName.MINIMAL_LEFT} onClick={goToList} /> <h1>{t(i18nKeys.CLIENTS)}</h1>
        <div className={styles('buttons-wrapper')}>
          <Button
            label={t(i18nKeys.CLIENT.MERGE.IGNORE)}
            onClick={skipMerge}
            color={ButtonColor.GREY}
            noMargin
          />
          <Button
            label={t(i18nKeys.MERGE_CLIENTS)}
            disabled={checkedDebtorId === ''}
            onClick={openAttributesSelectionModal}
            noMargin
          />
        </div>
      </div>
      <HTML className={styles('warning')} html={t(i18nKeys.CLIENT.MERGE.SELECT_CLIENT)} />
      <div className={styles('clients-wrapper')}>
        {debtorsToMerge.map((client) => (
          <MergeableDebtor
            key={client.id}
            client={client}
            checkedDebtorId={checkedDebtorId}
            onCheck={(e) => {
              setCheckedDebtorId(e);
            }}
          />
        ))}
      </div>
    </div>
  );
};
