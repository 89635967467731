import { i18nKeys, useTranslation } from 'locales';

import { Center, NumberFormatter, Stack, Text, Title } from '@mantine/core';

import { Monitoring } from '../../../../shared/hooks/use-load-monitoring-data';

interface CreditLimitProps {
  creditLimit: NonNullable<Monitoring>['creditLimit'];
}

export const CreditLimit = ({ creditLimit }: CreditLimitProps) => {
  const { t } = useTranslation();
  const { status, limit } = creditLimit;

  return (
    <Center h="100%">
      <Stack justify="space-between">
        <Title order={3} mb="lg">
          {t(i18nKeys.DEBTOR.MONITORING.CREDIT_LIMIT)}
        </Title>
        <Center w="100%">
          <Stack align="center">
            <Text size="50" fw={500} lh={1} c={limit === 0 ? 'red' : 'black'}>
              <NumberFormatter value={limit} thousandSeparator=" " suffix=" €" />
            </Text>
            <Text size="lg" c={limit === 0 ? 'red' : 'black'}>
              {status}
            </Text>
          </Stack>
        </Center>
      </Stack>
    </Center>
  );
};
