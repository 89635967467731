import { BaseApiActionsType, buildActions, makeResourceConstants } from 'store/actions';

const prefix = 'colleague';

export const colleagueConstants = {
  ...makeResourceConstants(prefix),
};

type ColleagueActions = BaseApiActionsType & {
  list: Function;
  listRes: Function;
};

const colleagueActions = buildActions<ColleagueActions>('', colleagueConstants, {});

export { colleagueActions };
