import { i18nKeys, useTranslation } from 'locales';
import { Button } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import DebtorBatchActions from './DebtorBatchActions';

type ShowCheckBoxProps = {
  listUserChecked: Debtor[];
  selectAll: boolean;
};

export const ShowCheckBox = ({ listUserChecked, selectAll }: ShowCheckBoxProps) => {
  const { t } = useTranslation();

  const openBatchActionsModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.FORM.ACTIONS),
      children: <DebtorBatchActions debtorsSelected={listUserChecked} isSelectedAll={selectAll} />,
    });
  };

  return <Button noMargin label={t(i18nKeys.FORM.ACTIONS)} onClick={openBatchActionsModal} />;
};
