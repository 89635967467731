import { UpdateTasksStatusBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'shared/io';

import { TaskFormColleague } from '../TaskForm/TaskFormColleague';

import styleIdentifiers from './TaskActions.module.scss';

const styles = classNames.bind(styleIdentifiers);

export type TaskActionsAssignFields = Pick<UpdateTasksStatusBody, 'user_id'>;

type TaskActionsAssignProps = {
  onSubmit: SubmitHandler<TaskActionsAssignFields>;
};

export const TaskActionsAssign = ({ onSubmit }: TaskActionsAssignProps) => {
  const { t } = useTranslation();

  const form = useForm<TaskActionsAssignFields>({
    shouldUnregister: true,
  });

  const { handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <form className={styles('task-action-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('content')}>
          <TaskFormColleague />
        </div>
        <div className={styles('button-wrapper', 'buttons')}>
          <Button type="submit" label={t(i18nKeys.SAVE)} />
        </div>
      </form>
    </FormProvider>
  );
};
