import { useEffect, useState } from 'react';
import CustomVariables from 'app/Private/CustomVariables';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useRouteMatch } from 'react-router-dom';
import Card from 'shared/components/Card';
import DebtorInfo from 'shared/components/DebtorInfo';
import { treatInvoice } from 'shared/serializer';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { Invoice } from 'types/store/invoice-state';

import InvoiceDetailContext from '../ToHandleDetail/InvoiceDetail.context';
import InvoiceInfo from '../ToHandleDetail/InvoiceInfo';
import { LatePaymentInfo } from '../ToHandleDetail/LatePaymentInfo';

import styleIdentifiers from './ImportedInvoiceDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  credit?: boolean;
  modal?: boolean;
};

export default function ImportedInvoiceDetail({ credit, modal }: Props) {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ id: string }>();
  const [importedInvoice, setImportedInvoice] = useState<Invoice>();

  useEffect(() => {
    invoiceActions.getToConfirm({
      id: params.id as any,
      callback: ({ data }) => {
        setImportedInvoice(treatInvoice(data) as any);
      },
    });
  }, []);

  const reloadInfo = () => {
    invoiceActions.getToConfirm({
      id: params.id as any,
    });
  };

  const debtor = importedInvoice?.debtor_attributes;

  return (
    <InvoiceDetailContext.Provider
      value={{
        data: importedInvoice!,
        credit,
        reloadInfo,
        clientData: debtor,
        noValidate: true,
      }}
    >
      <div className={styles('imported-invoice-detail', importedInvoice && modal && 'modal')}>
        {importedInvoice && (
          <div className={styles('invoice-wrapper')}>
            <h1>{importedInvoice.reference || importedInvoice.id}</h1>
            <div className={styles('line')}>
              <Card
                title={t(credit ? 'INVOICE.CREDIT_NOTE_STATUS' : 'INVOICE.INVOICE_STATUS')}
                className={styles('box', 'middle')}
                classContent={styles('status-card')}
              >
                <span className={styles('text')}>{importedInvoice.status}</span>
              </Card>

              {debtor && <DebtorInfo imported clientData={debtor} invoiceData={importedInvoice} />}
            </div>
            <div className={styles('line')}>
              <InvoiceInfo />
              <LatePaymentInfo />
            </div>
            <div className={styles('line')}>
              {importedInvoice?.custom_variables && (
                <>
                  {Object.keys(importedInvoice.custom_variables).length > 0 && (
                    <CustomVariables
                      data={importedInvoice.custom_variables}
                      invoiceView
                      cardTitle={` - ${t(i18nKeys.COMMON.INVOICE)}`}
                    />
                  )}
                </>
              )}
              {debtor?.custom_variables && (
                <>
                  {Object.keys(debtor.custom_variables).length > 0 && (
                    <CustomVariables
                      data={debtor.custom_variables}
                      invoiceView
                      cardTitle={` - ${t(i18nKeys.COMMON.CLIENT)}`}
                      marginLeft
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </InvoiceDetailContext.Provider>
  );
}
