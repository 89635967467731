import { useEffect, useState } from 'react';
import { DebtorSendingOptionsResponse, Notification, SendingMediaNames } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddContactPersonButton from 'shared/components/AddContactPersonButton/AddContactPersonButton';
import AddressPartialForm from 'shared/forms/partial/AddressPartialForm';
import SendInvoiceFormEmail from 'shared/forms/SendInvoiceForm/SendInvoiceFormEmail';
import { Button, Checkbox, CustomSelect } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import {
  addressesToAttributes,
  emailValues,
  getDefaultAddresses,
  getDefaultEmails,
} from 'shared/utils/contacts';
import { settingsActions } from 'store/settings/settings.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { Invoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';
import { Template } from 'types/template';

import styleIdentifiers from './SendingOptionsForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  customButtonText?: string;
  customText?: string;
  debtorSendingOptions?: DebtorSendingOptionsResponse;
  initialValues: any;
  invoice?: Invoice; // No invoice means we are in a batch action. We only use debtor_id
  invoiceActionsData?: any;
  onSubmit: onSubmitFunction;
  refetchSendingOptions?: () => void;
  setTemplate?: any;
  type?: 'additional_reminder';
  setIsPostSelected?: (val: boolean) => void;
  setIsEmailSelected?: (val: boolean) => void;
};

export default function SendingOptionsForm({
  customButtonText,
  customText,
  debtorSendingOptions,
  initialValues,
  invoice,
  invoiceActionsData,
  onSubmit,
  refetchSendingOptions,
  setTemplate,
  type,
  setIsPostSelected,
  setIsEmailSelected,
}: Props) {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);
  const isLoading = useSelector((state: StoreState) => state.invoice.isLoading);
  const [templates, setTemplates] = useState<Template[]>([]);

  const { emails } = debtorSendingOptions ?? { emails: [] };
  const defaultDebtorEmails = getDefaultEmails(emails, Notification.reminders);
  const defaultEmailValues = emailValues(defaultDebtorEmails);

  const { addresses } = debtorSendingOptions ?? { addresses: [] };
  const defaultAddresses = getDefaultAddresses(addresses, Notification.reminders);
  const addressesAttributes = addressesToAttributes(defaultAddresses);

  // Make sure you understand which defaults come from the debtor, and which ones
  // come from the invoice's own settings. Invoice settings should override.
  const defaultSendingMedia = (initialValues.sending_media_attributes ?? []).map((val) => val.name);
  const form = useForm({
    defaultValues: {
      ...initialValues,
      emails: invoice ? invoice.sending_options_attributes.emails : defaultEmailValues,
      addresses_attributes: addressesAttributes,
      send_email: defaultSendingMedia.includes(SendingMediaNames.email),
      send_post: defaultSendingMedia.includes(SendingMediaNames.post),
      send_registered_post: defaultSendingMedia.includes(SendingMediaNames.registered_post),
    },
  });

  const { handleSubmit, watch, reset, register } = form;

  useEffect(() => {
    settingsActions.templateList({
      data: { step_type: 'additional_reminder' },
      callback: ({ data }) => {
        setTemplates(data.map((template) => treatTemplate(template)));
      },
    });
  }, []);

  const { send_email, send_post, send_registered_post } = form.getValues();

  useEffect(() => setIsEmailSelected?.(send_email), [send_email]);

  useEffect(
    () => setIsPostSelected?.(send_post || send_registered_post),
    [send_post, send_registered_post],
  );

  useEffect(() => {
    const values =
      type === 'additional_reminder'
        ? {
            ...invoiceActionsData?.sending_options,
            addresses_attributes: addressesAttributes,
          }
        : initialValues;

    reset(values, { keepValues: true });
  }, [initialValues, invoiceActionsData]);

  const sendEmail = watch('send_email');
  const sendPost = watch('send_post');
  const sendRegisteredPost = watch('send_registered_post');

  const groupedTypes = [
    {
      description: t(i18nKeys.INVOICE.SELECTED_ONLY),
      value: '',
    },
    {
      description: t(i18nKeys.INVOICE.IN_PROGRESS),
      value: 'unpaid_invoices',
    },
    {
      description: t(i18nKeys.INVOICE.LATE_ONLY),
      value: 'overdue_invoices',
    },
    {
      description: t(i18nKeys.CLIENT_BALANCE),
      value: 'debtor_balance',
    },
  ];

  const submitDisabled =
    isLoading ||
    (sendEmail && (watch('emails') ?? []).length === 0) ||
    ((sendPost || sendRegisteredPost) && (watch('addresses_attributes') ?? []).length === 0);

  return (
    <FormProvider {...form}>
      <form className={styles('sending-options-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('content')}>
          <div className={styles('summary')}>
            {customText || t(i18nKeys.INVOICE.SENDING_OPTIONS_SUMMARY)}
          </div>
          {setTemplate && (
            <Controller
              name="template_id"
              defaultValue=""
              render={() => (
                <CustomSelect
                  noMargin
                  selectClassName={styles('input')}
                  keyText="name"
                  keyValue="id"
                  onValueChanged={setTemplate}
                  name="template_id"
                  items={templates}
                  label={t(i18nKeys.COMMON.TEMPLATE)}
                />
              )}
            />
          )}

          {!invoice && (
            <>
              <Controller
                name="grouped_type"
                defaultValue=""
                render={() => (
                  <CustomSelect
                    selectClassName={styles('input')}
                    keyText="description"
                    keyValue="value"
                    name="grouped_type"
                    items={groupedTypes}
                    label={t(i18nKeys.STATEMENT)}
                  />
                )}
              />
              <div className={styles('add-fees')}>
                <Checkbox
                  register={register('add_late_fees')}
                  watch={watch}
                  label={<span>{t(i18nKeys.LATE_FEES_INCLUDED)}</span>}
                />
              </div>
            </>
          )}

          <div className={styles('send-by')}>
            <div className={styles('label-input')}>{t(i18nKeys.INVOICE.SEND_REMINDERS_BY)}</div>
            <div className={styles('sending-media')}>
              {constants.sending_medium.map(({ value, description }) => (
                <Checkbox
                  key={value}
                  register={register(`send_${value}`)}
                  watch={watch}
                  label={<span>{description}</span>}
                  disabled={
                    (value === SendingMediaNames.post && watch('send_registered_post')) ||
                    (value === SendingMediaNames.registered_post && watch('send_post'))
                  }
                />
              ))}
            </div>
          </div>
          {invoice && sendEmail && <SendInvoiceFormEmail emails={emails} />}
          {invoice && refetchSendingOptions && (
            <AddContactPersonButton
              debtorId={invoice.debtor_id}
              refetch={() => refetchSendingOptions()}
              withMargin
            />
          )}
          {invoice && (sendPost || sendRegisteredPost) && (
            <AddressPartialForm addresses={addresses} />
          )}
        </div>
        {(sendEmail || sendPost || sendRegisteredPost) && (
          <div className={styles('button-wrapper')}>
            <Button
              isLoading={isLoading}
              label={customButtonText || t(i18nKeys.SUBMIT)}
              type="submit"
              disabled={invoice && submitDisabled}
            />
          </div>
        )}
      </form>
    </FormProvider>
  );
}
