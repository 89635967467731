import { Address, EmailAttributes, Notification } from 'api/models';
import { Address as OldAddress } from 'types/storeTypes';

export const getDefaultEmails = (emails: EmailAttributes[], notification: Notification) => {
  const notificationEmails = emails.filter(({ notifications }) =>
    notifications.includes(notification),
  );

  if (notificationEmails.length > 0) return notificationEmails;

  const mainEmails = emails.filter(({ main_contact }) => main_contact);

  if (mainEmails.length > 0) return mainEmails;

  return emails;
};

export const emailValues = (emails: EmailAttributes[]) => emails.map(({ value }) => value);

export const getDefaultAddresses = (addresses: Address[], notification: Notification) => {
  const filteredAddresses = addresses.filter(
    ({ attributes: { use_postal_address } }) => use_postal_address,
  );
  const notificationAddresses = filteredAddresses.filter(({ attributes: { notifications } }) =>
    notifications!.includes(notification),
  );

  if (notificationAddresses.length > 0) return notificationAddresses;

  const mainAddresses = filteredAddresses.filter(
    ({ attributes: { main_contact } }) => main_contact,
  );

  if (mainAddresses.length > 0) return mainAddresses;

  return filteredAddresses;
};

export const addressesToAttributes = (addresses: Address[]) =>
  addresses?.map(
    ({ id, type, attributes }) =>
      ({
        id,
        type,
        ...attributes,
      }) as OldAddress,
  );

export const transformEmailsAndSubmit = (emails: Array<Record<string, any>>, values, onSubmit) => {
  const parsedEmails = values.emails?.map((rawEmail) => {
    const maybeExistingEmail = emails.find((e) => e.value === rawEmail);
    if (maybeExistingEmail) return maybeExistingEmail;
    return { value: rawEmail };
  });

  onSubmit({ ...values, emails: parsedEmails });
};
