import { useGetDebtor } from 'api';
import classNames from 'classnames/bind';
import { useParams } from 'react-router';

import { Box, LoadingOverlay } from '@mantine/core';

import { ClientDetailHeader } from './ClientDetailHeader';
import { ClientDetailTabs } from './ClientDetailTabs';

import styleIdentifiers from './ClientDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const ClientDetail = () => {
  const { id: debtorId } = useParams<{ id: string }>();

  const { data: debtorDetailsResponse, isLoading, isError, refetch } = useGetDebtor(debtorId);

  if (isLoading && !debtorDetailsResponse) {
    return (
      <Box h="100%" pos="relative">
        <LoadingOverlay visible />
      </Box>
    );
  }

  if (isError || !debtorDetailsResponse) {
    return <span>Error</span>;
  }

  const { data: debtor } = debtorDetailsResponse;

  return (
    <div className={styles('client-detail')}>
      <ClientDetailHeader debtor={debtor} refetch={() => refetch()} />
      <ClientDetailTabs debtor={debtor} refetch={() => refetch()} />
    </div>
  );
};
