import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { formattedDate } from 'shared/utils/view';
import { Locale } from 'types';

import styleIdentifiers from './dateItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  date: moment.Moment | Date;
  format?: string;
  lg: Locale;
  specialClass?: string;
  className?: string;
};

export default function DateItem({
  date,
  format,
  lg,
  className,
  specialClass,
  children,
}: PropsWithChildren<Props>) {
  return (
    <span className={styles('DateItem', className, specialClass)}>
      {formattedDate(date, lg, format)} {children}
    </span>
  );
}
