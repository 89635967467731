import { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const ExportSchema = z.object({
  file: z.string().url(),
  executionDate: z.string().transform((date) => dayjs(date)),
});

const ReportSchema = z.object({
  id: z.string(),
  name: z.string().min(1),
  periodicity: z.object({
    type: z.enum(['weekly', 'monthly']),
    days: z.array(z.number()).transform((days) => [...days].sort((a, b) => a - b)),
  }),
  emails: z.array(z.string().email()),
  exports: z.array(ExportSchema),
});

export type Report = z.infer<typeof ReportSchema>;

export async function loadReportFooterQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get(`/reports/export_tasks/${queryKey[1]}`);
  return ReportSchema.parse(data);
}

export const useLoadReport = (reportId: string) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Report, ApiError>({
    queryKey: ['report', reportId],
    queryFn: (context) => loadReportFooterQueryFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<Report, ApiError, 'report'>('report', queryResult);
};
