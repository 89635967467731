import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, CustomSelect, DateSelector, Input } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import { VarType } from '../CustomVariables.types';

import styleIdentifiers from './CustomVariablesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit?: onSubmitFunction;
  initialValues: any;
};

export default function CustomVariablesForm({ onSubmit, initialValues }: Props) {
  const { t } = useTranslation();
  const availableCustomVariables = useSelector(
    (state: StoreState) => state.account.company?.data?.custom_variables_attributes,
  );
  const form = useForm({
    shouldUnregister: true,
    defaultValues: initialValues,
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    sideMenuSetAskBeforeClose(isDirty);
  }, [isDirty]);

  const submit = (values) => {
    onSubmit({ ...values });
  };

  const customVariables = watch('custom_variables');

  const accurateInput = (customVarKey, varType) => {
    switch (varType) {
      case VarType.BOOLEAN:
        return (
          <Controller
            defaultValue=""
            name={`custom_variables.${customVarKey}`}
            render={() => (
              <CustomSelect
                selectClassName={styles('input')}
                keyText="description"
                keyValue="value"
                items={[
                  {
                    description: t(i18nKeys.YES),
                    value: 'true',
                  },
                  {
                    description: t(i18nKeys.NO),
                    value: 'false',
                  },
                ]}
                withBorder
                name={`custom_variables.${customVarKey}`}
                label={customVarKey.replaceAll('_', ' ')}
              />
            )}
          />
        );
      case VarType.DATE:
        return (
          <DateSelector
            name={`custom_variables.${customVarKey}`}
            className={styles('input')}
            noMinDate
            withBorder
            placeholder=" "
            label={customVarKey.replaceAll('_', ' ')}
            handleChange={(value) => {
              setValue(`custom_variables.${customVarKey}`, value);
            }}
          />
        );
      default:
        return (
          <Input
            register={register(`custom_variables.${customVarKey}`)}
            withBorder
            label={customVarKey.replaceAll('_', ' ')}
            className={styles('input')}
            type={varType === VarType.NUMBER ? 'number' : 'text'}
            step="0.01"
            noMargin
          />
        );
    }
  };

  const renderInput = (customVarKey) => {
    const varType = availableCustomVariables!.find(
      (el) => el.column_name === customVarKey,
    )?.var_type;
    return (
      <div key={customVarKey} className={styles('content')}>
        {accurateInput(customVarKey, varType)}
      </div>
    );
  };

  return (
    <FormProvider {...form}>
      <form className={styles('custom-variables-form')} onSubmit={handleSubmit(submit)}>
        <div className={styles('wrapper')}>
          <div className={styles('head')}>{t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE)}</div>
          {Object.keys(customVariables)
            .sort((a, b) => a.localeCompare(b))
            .map((customVarKey) => renderInput(customVarKey))}
        </div>
        <div className={styles('button-wrapper')}>
          <Button noMargin label={t(i18nKeys.SAVE)} type="submit" />
        </div>
      </form>
    </FormProvider>
  );
}
