import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useProfile } from 'shared/hooks';
import { useGetCompany } from 'shared/utils/selectors';

import { css } from '@emotion/css';
import { Avatar, Button, Space, Text } from '@mantine/core';

const styles = {
  profileButtonRoot: (isOpen: boolean) => css`
    background-color: ${isOpen ? '#F2F5F7' : 'transparent'} !important;
    border-color: ${isOpen ? '#CCD7DE' : 'transparent'};
    color: '#1A1919';
    transition: background-color 100ms ease;

    &:hover {
      background-color: #f2f5f7 !important;
    }
  `,
};

interface ProfileButtonProps extends ComponentPropsWithoutRef<'button'> {
  isOpened: boolean;
}

export const ProfileButton = forwardRef<HTMLButtonElement, ProfileButtonProps>(
  ({ isOpened, ...others }: ProfileButtonProps, ref) => {
    const company = useGetCompany();
    const profile = useProfile();

    return (
      <Button
        pl={4}
        justify="space-between"
        w="100%"
        ref={ref}
        radius={8}
        classNames={{ root: styles.profileButtonRoot(isOpened) }}
        rightSection={
          <Avatar
            size="sm"
            radius={6}
            src={company?.invoice_customization.logo_url}
            name={company?.name}
          />
        }
        {...others}
      >
        <Avatar size="sm" src={profile.profilePicture} name={profile.name.full} />
        <Space w={12} />
        <Text c="#1A1919">{profile.name.full}</Text>
      </Button>
    );
  },
);
