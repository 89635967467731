import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useGetCompany } from 'shared/utils/selectors';

import {
  IconAt,
  IconCalendarClock,
  IconFileAlert,
  IconFolders,
  IconListCheck,
  IconTimeline,
  IconWallet,
} from '@tabler/icons-react';

import { Menu } from '../types';

import { useBuildKanbanMenu } from './use-build-kanban-menu';

export function useBuildFollowupMenu(): Menu {
  const { t } = useTranslation();
  const company = useGetCompany();
  const kanbanMenu = useBuildKanbanMenu();

  return useMemo<Menu>(() => {
    const items = [
      kanbanMenu,
      {
        path: '/invoices/to-handle',
        label: t(i18nKeys.INVOICE_TO_PROCESS),
        Icon: IconFileAlert,
      },
      {
        path: '/invoices/actions',
        label: t(i18nKeys.NAV.IMINENT_REMINDERS),
        Icon: IconCalendarClock,
        unreadCount: company?.reminders_to_send_count?.next_five_days,
      },
      {
        path: '/thirdparty_cases',
        label: t(i18nKeys.NAV.THIRDPARTY),
        Icon: IconFolders,
      },
      {
        path: '/payment-plans/listing',
        pathSuffix: '?status=in_progress',
        twat: 'test',
        label: t(i18nKeys.NAV.PAYMENT_PLAN),
        Icon: IconWallet,
      },
      {
        path: '/tasks',
        label: t(i18nKeys.NAV.TASKS),
        Icon: IconListCheck,
        isHidden: !company?.package.can_use_tasks,
      },
      {
        path: '/mails',
        label: t(i18nKeys.MAILS.NAV),
        Icon: IconAt,
      },
    ];

    return {
      parent: null,
      label: t(i18nKeys.NAV.MY_FOLLOW_UP),
      Icon: IconTimeline,
      items,
    };
  }, [company, kanbanMenu, t]);
}
