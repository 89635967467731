import { AxiosInstance } from 'axios';
import { ResourceType } from 'types/resource-types';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useLegacyAxiosInstance } from './utils';

const ViewSchema = z
  .object({
    attributes: z.object({
      name: z.string(),
    }),
    id: z.string(),
  })
  .transform((data) => ({ id: data.id, name: data.attributes.name }));

const ViewsSchema = z.object({ data: z.array(ViewSchema) }).transform((data) => data.data);

export type View = z.infer<typeof ViewSchema>;
export type Views = z.infer<typeof ViewsSchema>;

type LoadViewsVariables = { resourceType: ResourceType };

export async function loadViewsQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get('views', {
    params: {
      view_type: queryKey[1],
    },
  });
  return ViewsSchema.parse(data);
}

export function useLoadViews({ resourceType }: LoadViewsVariables) {
  const legacyAxiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<Views, ApiError>({
    queryKey: ['views', resourceType],
    queryFn: (context) => loadViewsQueryFn(legacyAxiosInstance, context),
  });

  return addResourceNameToQueryResult<Views, unknown, 'views'>('views', queryResult);
}
