/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

/**
 * @nullable
 */
export type TimelineTypes = (typeof TimelineTypes)[keyof typeof TimelineTypes] | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimelineTypes = {
  external_mail: 'external_mail',
  debtor_reaction: 'debtor_reaction',
  activity: 'activity',
} as const;
