import { useTranslation } from 'locales';

import { Box, Card, LoadingOverlay } from '@mantine/core';

interface DashboardChartLoaderProps {
  titleKey: string;
}

export const DashboardChartLoader = ({ titleKey }: DashboardChartLoaderProps) => {
  const { t } = useTranslation();

  return (
    <Card title={t(titleKey)}>
      <Box w="100%" h="285px">
        <LoadingOverlay visible />
      </Box>
    </Card>
  );
};
