import { i18nKeys, useTranslation } from 'locales';

import { Box, Button, Stack, Text, Timeline, Title } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';

import { Monitoring } from '../../../../shared/hooks';

interface WarningOverviewProps {
  warnings: NonNullable<Monitoring>['warnings'];
  warningsURL: NonNullable<Monitoring>['warningsUrl'];
}

export const WarningsOverview = ({ warnings, warningsURL }: WarningOverviewProps) => {
  const { t } = useTranslation();

  return (
    <Stack align="center">
      <Box w="100%" mb="sm">
        <Title order={2} mb={0}>
          {t(i18nKeys.DEBTOR.MONITORING.WARNINGS)}
        </Title>
      </Box>
      <Stack gap="lg">
        {warnings && (
          <Timeline bulletSize={25} active={-1}>
            {warnings.map(({ year, descriptions }, key) => (
              <Timeline.Item
                title={year ?? t(i18nKeys.DEBTOR.MONITORING.ONGOING)}
                key={`${year}-${key}`}
              >
                {descriptions.map((description, recordKey) => (
                  <Text key={`${year}-${recordKey}`} size="sm">{`${description}`}</Text>
                ))}
              </Timeline.Item>
            ))}
          </Timeline>
        )}
        {warningsURL && (
          <Button
            variant="transparent"
            component="a"
            href={warningsURL}
            target="_blank"
            rightSection={<IconExternalLink size={16} stroke={1.5} />}
          >
            {t(i18nKeys.DEBTOR.MONITORING.WARNING_DETAILS)}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
