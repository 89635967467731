import { AxiosInstance } from 'axios';
import { ResourceType } from 'types/resource-types';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export type DeleteViewVariables = {
  viewId: string;
  resourceType: ResourceType;
};

export async function deleteViewFn(
  axiosInstance: Promise<AxiosInstance>,
  variables: DeleteViewVariables,
): Promise<ApiResponse> {
  const { viewId } = variables;
  const instance = await axiosInstance;
  return instance.delete(`views/${viewId}`);
}

export function useDeleteView() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, DeleteViewVariables>({
    mutationFn: (variables) => deleteViewFn(axiosInstance, variables),
    onSuccess: (response, { resourceType }) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['views', resourceType],
      });
    },
    onError: (error: ApiError) => {
      notifications.show({
        color: 'red',
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, DeleteViewVariables, 'deleteView'>(
    'deleteView',
    mutation,
  );
}
