import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import UserAvatar from 'shared/components/UserAvatar';
import { UpdateUserForm, User, useUpdateUser } from 'shared/hooks';
import { useResendInvitation } from 'shared/hooks/use-resend-invitation';
import { Button } from 'shared/io';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { DescVal } from 'types/genericType';
import { StoreState } from 'types/storeTypes';

import EditCollaboratorForm from './EditCollaboratorForm';

import styleIdentifiers from './colleagueItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ColleagueItemProps = {
  item: User;
};

export default function ColleagueItem({ item: user }: ColleagueItemProps) {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);
  const { updateUser } = useUpdateUser();
  const { resendInvitation, isResendInvitationLoading } = useResendInvitation();

  const handleEditUser = (newUser: UpdateUserForm) => {
    updateUser({ id: user.id, data: newUser });
    sideMenuHide();
  };

  const onClickEditUser = () => {
    sideMenuShow({
      unmount: true,
      content: <EditCollaboratorForm initialValues={user} onSubmit={handleEditUser} />,
    });
  };

  const { id, roles, email, isAdmin, isActive, hasAcceptedGc } = user;

  return (
    <tr className={styles('ColleagueItem')} key={id}>
      <td className={styles('small')} />
      <td>{id}</td>
      <td>
        <div className={styles('user')}>
          <UserAvatar user={user} />
          <div className={styles('name')}>{user.name.full}</div>
        </div>
      </td>
      <td className={styles('role')}>
        {hasAcceptedGc ? (
          <>
            {isAdmin
              ? (constants.user_roles.find((role) => role.value === 'admin') as DescVal).description
              : roles.map((currentRole, i) => (
                  <div key={i}>
                    {
                      (constants.user_roles.find((role) => role.value === currentRole) as DescVal)
                        .description
                    }
                  </div>
                ))}
          </>
        ) : (
          <Button
            label={t(i18nKeys.FORM.INVITE_STAFF.RESEND_INVITATION)}
            noMargin
            noShadow
            small
            iconRight={IconName.SEND}
            disabled={isResendInvitationLoading}
            onClick={() => resendInvitation(id)}
          />
        )}
      </td>
      <td className={styles('email')}>{email}</td>
      <td className={styles('status', isActive && 'active')}>
        {t(isActive ? i18nKeys.SETTINGS.STAFF.ACTIVE : i18nKeys.SETTINGS.STAFF.INACTIVE)}
      </td>
      <td className={styles('actions')}>
        <span onClick={onClickEditUser}>
          <Icon name={IconName.SETTINGS_GEAR} size="18px" className={styles('action')} />
        </span>
      </td>
    </tr>
  );
}
