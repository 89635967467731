import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const AvailableFooterFieldSchema = z.object({
  name: z.string().describe('Translated display name'),
  field: z.string().describe('Slugified name'),
  content: z.string(),
});

const FooterFieldSchema = z.union([
  z.object({
    field: z.string(),
    content: z.string(),
  }),
  z.string(),
  z.null(),
]);

const InvoiceFooterSchema = z.object({
  availableFields: z.array(AvailableFooterFieldSchema),
  fields: z.array(FooterFieldSchema),
});

export type InvoiceFooterField = z.infer<typeof FooterFieldSchema>;
export type InvoiceFooter = z.infer<typeof InvoiceFooterSchema>;

export async function loadInvoiceFooterQueryFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;
  const { data } = await instance.get('invoice_footer_customisation');
  return InvoiceFooterSchema.parse(data);
}

export function useLoadInvoiceFooter() {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<InvoiceFooter, ApiError>({
    queryKey: ['invoice-footer'],
    queryFn: () => loadInvoiceFooterQueryFn(axiosInstance),
  });

  return addResourceNameToQueryResult<InvoiceFooter, ApiError, 'invoiceFooter'>(
    'invoiceFooter',
    queryResult,
  );
}
