import { i18nKeys, useTranslation } from 'locales';
import { useLoadLightUsers } from 'shared/hooks';

import { Group, NumberInput, Select, Stack, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';

import { LoadInvoiceColumnVariablesInput } from '../../api/use-load-invoice-column';

interface InvoiceKanbanFiltersProps {
  form: UseFormReturnType<LoadInvoiceColumnVariablesInput>;
}

export const InvoiceKanbanFilters = ({ form }: InvoiceKanbanFiltersProps) => {
  const { t } = useTranslation();
  const { users = [] } = useLoadLightUsers(false);

  const accountManagersForSelect = users.map((user) => ({
    value: String(user.id),
    label: user.name.full,
  }));

  return (
    <Stack gap="lg">
      <Select
        autoFocus
        label={t(i18nKeys.ACCOUNT_MANAGER)}
        placeholder={t(i18nKeys.DASHBOARD.SELECT_ACCOUNT_MANAGER)}
        data={accountManagersForSelect}
        {...form.getInputProps('accountManagerId')}
      />
      <Group>
        <DatePickerInput
          label={t(i18nKeys.FORM.START_DATE)}
          placeholder={t(i18nKeys.FORM.CHOOSE_DATE)}
          flex={1}
          {...form.getInputProps('startDate')}
        />
        <DatePickerInput
          label={t(i18nKeys.FORM.START_DATE)}
          placeholder={t(i18nKeys.FORM.CHOOSE_DATE)}
          flex={1}
          {...form.getInputProps('endDate')}
        />
      </Group>
      <TextInput
        label={t(i18nKeys.KANBAN.FILTERS.INVOICE_NAME)}
        placeholder={t(i18nKeys.KANBAN.FILTERS.INVOICE_NAME_PH)}
        {...form.getInputProps('reference')}
      />
      <TextInput
        label={t(i18nKeys.KANBAN.FILTERS.CLIENT_NAME)}
        placeholder={t(i18nKeys.KANBAN.FILTERS.CLIENT_NAME_PH)}
        {...form.getInputProps('debtorName')}
      />
      <Group>
        <NumberInput
          label={t(i18nKeys.KANBAN.FILTERS.MIN_DAYS_LATE)}
          placeholder={t(i18nKeys.KANBAN.FILTERS.MIN_DAYS_LATE_PH)}
          flex={1}
          {...form.getInputProps('minDaysLate')}
        />
        <NumberInput
          label={t(i18nKeys.KANBAN.FILTERS.MAX_DAYS_LATE)}
          placeholder={t(i18nKeys.KANBAN.FILTERS.MAX_DAYS_LATE_PH)}
          flex={1}
          {...form.getInputProps('maxDaysLate')}
        />
      </Group>
      <Group>
        <Select
          label={t(i18nKeys.KANBAN.FILTERS.OPERATOR)}
          flex={1}
          data={[
            { label: t(i18nKeys.KANBAN.FILTERS.LESS_THAN), value: '<=' },
            { label: t(i18nKeys.KANBAN.FILTERS.MORE_THAN), value: '>=' },
          ]}
          {...form.getInputProps('remainingBalance.operator')}
        />
        <NumberInput
          label={t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE)}
          placeholder="e.g. 1000"
          flex={2}
          {...form.getInputProps('remainingBalance.amount')}
        />
      </Group>
    </Stack>
  );
};
