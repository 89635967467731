import { useState } from 'react';
import { DebtorLight, UpdatePaymentStepCalculationBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Card from 'shared/components/Card';
import { Button, ButtonColor, Input, RadioButton } from 'shared/io';

import { StepsCalculationInvoices } from '../../PaymentPlanForm/StepsCalculation/StepsCalculationInvoices';

import styleIdentifiers from '../../PaymentPlanForm/StepsCalculation/StepsCalculation.module.scss';

const styles = classNames.bind(styleIdentifiers);

type AddInvoicesFormProps = {
  onSubmit: SubmitHandler<UpdatePaymentStepCalculationBody>;
  onCancel: () => void;
  debtor: DebtorLight;
};

export const AddInvoicesForm = ({ onSubmit, debtor, onCancel }: AddInvoicesFormProps) => {
  const { t } = useTranslation();

  const form = useForm<UpdatePaymentStepCalculationBody>({
    shouldUnregister: true,
    defaultValues: {
      new_steps: false,
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = form;

  register('new_steps');

  const newSteps = watch('new_steps');

  const [deadlineType, setDeadlineType] = useState<'amount' | 'count'>('count');

  return (
    <FormProvider {...form}>
      <form className={styles('add-invoices-form')} onSubmit={handleSubmit(onSubmit)}>
        <Card title={t(i18nKeys.DETAILS)}>
          <div className={styles('grid-row')}>
            <div className={styles('content', 'col-12')}>
              <RadioButton
                name="new_steps"
                className={styles('radio-buttons')}
                onChange={(value: boolean) => {
                  setValue('new_steps', value);
                }}
                noMargin
                items={[
                  {
                    value: false,
                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.SPREAD_TO_EXISTING),
                  },
                  {
                    value: true,
                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.ADD_NEW_STEPS),
                  },
                ]}
              />
            </div>
          </div>
          <div className={styles('content')}>
            <StepsCalculationInvoices currentDebtor={debtor} />
          </div>
          {newSteps && (
            <div className={styles('grid-row')}>
              {/* another radio to select deadline_amount or deadline_count */}
              <div className={styles('content', 'col-12')}>
                <RadioButton
                  className={styles('radio-buttons')}
                  noMargin
                  onChange={(value) => {
                    setDeadlineType(value);
                  }}
                  value={deadlineType}
                  name="deadline_type"
                  items={[
                    {
                      value: 'count',
                      label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT),
                    },
                    {
                      value: 'amount',
                      label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT),
                    },
                  ]}
                />
              </div>
              {deadlineType === 'count' && (
                <div className={styles('content', 'col-6')}>
                  <Input
                    register={register('deadline_count', {
                      required: true,
                      min: 1,
                    })}
                    errorMessage={get(errors, 'deadline_count')}
                    noMargin
                    withBorder
                    label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT)}
                    type="number"
                    step="1"
                  />
                </div>
              )}
              {deadlineType === 'amount' && (
                <div className={styles('content', 'col-6')}>
                  <Input
                    register={register('deadline_amount', {
                      required: true,
                      min: 0,
                    })}
                    errorMessage={get(errors, 'deadline_amount')}
                    noMargin
                    withBorder
                    label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT)}
                    type="number"
                    step="0.01"
                  />
                </div>
              )}
              <div className={styles('content', 'col-6')}>
                <Input
                  register={register('delay', { required: true, min: 1 })}
                  errorMessage={get(errors, 'delay')}
                  noMargin
                  withBorder
                  label={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DELAY)}
                  type="number"
                  step="1"
                  placeholder={t(i18nKeys.DAYS, { count: 30 })}
                />
              </div>
            </div>
          )}
          <div className={styles('content', 'col-12', 'button-wrapper')}>
            <Button
              onClick={onCancel}
              noMargin
              color={ButtonColor.GREY}
              label={t(i18nKeys.CANCEL)}
              className={styles('cancel')}
            />
            <Button type="submit" noMargin label={t(i18nKeys.ADD_INVOICES)} />
          </div>
        </Card>
      </form>
    </FormProvider>
  );
};
