import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { Button, Input } from 'shared/io';
import { useGetCompany } from 'shared/utils/selectors';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import { Integration } from '../../Integrations.constants';
import { getIntegrationRights } from '../Integration.utils';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum CodaboxFormFieldsName {
  CODABOX_TOKEN = 'codabox_token',
}

export type CodaboxFormFields = {
  [CodaboxFormFieldsName.CODABOX_TOKEN]: string;
};

type CodaboxFormProps = {
  onSubmit: (values: CodaboxFormFields) => void;
  tokenRequest: () => void;
};

export const CodaboxForm = ({ onSubmit, tokenRequest }: CodaboxFormProps) => {
  const { t } = useTranslation();
  const { codaboxMessage, isLoading } = useSelector((state: StoreState) => state.onBoarding);
  const company = useGetCompany()!;
  const {
    codabox_integration: { codabox_token: codaboxToken },
  } = company;
  const [createNewToken, setCreateNewToken] = useState(!codaboxToken);
  const { register, handleSubmit } = useForm<CodaboxFormFields>();

  const { isActive } = getIntegrationRights(Integration.CODABOX, company);

  const handleTokenRequest = () => {
    if (!isActive) {
      tokenRequest();
    } else {
      showDialog({
        id: DialogShowId.CONFIRM,
        size: DialogShowSize.SMALL,
        keepMountOnExit: true,
        title: t(i18nKeys.CONFIRM),
        children: (
          <AYSModal
            text={t(i18nKeys.SETTINGS.INTEGRATIONS.CODABOX.ALERT_NEW_TOKEN)}
            onConfirm={() => {
              tokenRequest();
            }}
          />
        ),
      });
    }
  };

  if (!createNewToken) {
    return (
      <div className={styles('integration-form')}>
        <div className={styles('row')}>
          <div className={styles('attribute')}>
            {t(i18nKeys.SETTINGS.INTEGRATIONS.CODABOX.CODABOX_TOKEN)}:
          </div>
          <div className={styles('value')}>*****{codaboxToken}</div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button
            className={styles('button')}
            onClick={() => {
              setCreateNewToken(true);
            }}
            label={t(i18nKeys.SETTINGS.INTEGRATIONS.CODABOX.NEW_TOKEN)}
            type="button"
          />
        </div>
      </div>
    );
  }

  return (
    <form className={styles('integration-form')} onSubmit={handleSubmit(onSubmit)}>
      {!isActive ? (
        <div className={styles('info')}>
          <p>{t(i18nKeys.ONBOARDING[2].CODABOX.EASY_TO_VALID)}</p>
          <p>
            {t(i18nKeys.ONBOARDING[2].CODABOX.FIRSTLY)}{' '}
            <button
              type="button"
              className={styles('info-link')}
              onClick={() => handleTokenRequest()}
            >
              {t(i18nKeys.ONBOARDING[2].CODABOX.CLICK_TO_RECEIVE_TOKEN)}
            </button>{' '}
            {t(i18nKeys.OF)}{' '}
            <a href="https://www.codabox.com" target="_blank" rel="noopener noreferrer">
              {Integration.CODABOX}
            </a>{' '}
            {t(i18nKeys.ONBOARDING[2].CODABOX.CODE_SENT_BY_EMAIL)}
          </p>
          {codaboxMessage && <p>{codaboxMessage}</p>}
          <p>{t(i18nKeys.ONBOARDING[2].CODABOX.ENTER_TOKEN_BELOW)}</p>
        </div>
      ) : (
        <div>
          <button
            type="button"
            className={styles('info-link')}
            onClick={() => handleTokenRequest()}
          >
            {t(i18nKeys.SETTINGS.INTEGRATIONS.CODABOX.ASK_TOKEN)}
          </button>
        </div>
      )}
      <Input
        type="text"
        label="Token Codabox"
        register={register(CodaboxFormFieldsName.CODABOX_TOKEN, {
          required: true,
        })}
      />
      <div className={styles('button-wrapper')}>
        <Button
          isLoading={isLoading}
          type="submit"
          className={styles('button')}
          label={t(i18nKeys.SAVE)}
        />
      </div>
    </form>
  );
};
