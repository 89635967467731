import { PropsWithChildren, useEffect } from 'react';
import { CreateWorkflowBody } from 'api/models';
import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';

import { WorkflowStepsTarget } from '../../Workflows.constants';

import { WorkflowFormStepsItemDetailReminder } from './WorkflowFormStepsItemDetailReminder';
import { WorkflowFormStepsItemDetailTask } from './WorkflowFormStepsItemDetailTask';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsItemDetailProps = {
  name: WorkflowStepsTarget;
  index: number;
};

export const WorkflowFormStepsItemDetail = ({
  name,
  index,
  children,
}: PropsWithChildren<WorkflowFormStepsItemDetailProps>) => {
  const { watch, register, setValue } = useFormContext<CreateWorkflowBody>();

  const { step_type, template, sending_media, task_attributes } = watch(`${name}.${index}`);

  const order =
    (name === WorkflowStepsTarget.AFTER_DUE_DATE
      ? [...watch(WorkflowStepsTarget.BEFORE_DUE_DATE)].length + index
      : index) + 1;

  register(`${name}.${index}.order`);

  useEffect(() => {
    setValue(`${name}.${index}.order`, order - 1);
  }, [name, index, order, setValue]);

  return (
    <article className={styles('workflows-form-step')}>
      <div className={styles('order')}>
        <span>{order}</span>
      </div>
      {template && (
        <WorkflowFormStepsItemDetailReminder
          step_type={step_type}
          template={template}
          sending_media={sending_media}
        />
      )}
      {task_attributes && <WorkflowFormStepsItemDetailTask task={task_attributes} />}
      <div className={styles('actions')}>{children}</div>
    </article>
  );
};
