import { useCreateContactPerson, useCreateTask, useDisputeDebtor } from 'api';
import { CreateContactPersonBody, CreateTaskBody } from 'api/models';
import { ContactPersonForm } from 'app/Private/ContactPersons';
import { SetReasonForm } from 'app/Private/Reasons/SetReasonForm/SetReasonForm';
import { TaskForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import EditClient from 'shared/action-component/EditClient';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { treatClient } from 'shared/serializer';
import { isConnectorActive } from 'shared/utils/connector';
import { useGetCompany } from 'shared/utils/selectors';
import { clientActions } from 'store/client/client.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';

import { SelectDuplicateDebtorForm } from '../Duplicates/SelectDuplicateDebtorForm';

import { ClientDetailTabProps } from './ClientDetail.types';

import styleIdentifiers from './ClientDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientDetailHeaderProps = ClientDetailTabProps & {};

export const ClientDetailHeader = ({ debtor, refetch }: ClientDetailHeaderProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const company = useGetCompany()!;
  const hasActiveConnector = isConnectorActive();

  const { mutate: disputeDebtor } = useDisputeDebtor();
  const { mutate: createTask } = useCreateTask();
  const { mutate: createContactPerson } = useCreateContactPerson();

  const client = treatClient(debtor);

  const handleClientSettings = () => {
    EditClient({
      client,
      callback: () => {
        refetch();
      },
    });
  };

  const handleDelete = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.CLIENT.AYS_TO_DELETE_CLIENT)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            clientActions.delete({
              id: client.id,
              callback: () => {
                history.push('/clients');
                sideMenuHide();
              },
            })
          }
        />
      ),
    });
  };

  const handleMerge = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.INVOICING.CREATE.SELECT_CLIENT),
      children: <SelectDuplicateDebtorForm currentDebtor={client} />,
    });
  };

  const handlePause = (values: Object) => {
    clientActions.pauseDebtor({
      id: client.id,
      data: { ...values, pause_debtor: true },
      callback: () => {
        dialogHide(DialogShowId.CUSTOM);
        clientActions.detail({ id: client.id });
      },
    });
  };

  const pauseDebtor = (pause: boolean) => (e) => {
    e.stopPropagation();
    if (client.disputed) return;

    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CLIENT.PAUSE),
      children: pause ? (
        <SetReasonForm onSubmit={handlePause} reasonType="pause" />
      ) : (
        <AYSModal
          text={t(i18nKeys.INVOICE.AYS_TO_UNPAUSE)}
          onConfirm={() => {
            clientActions.pauseDebtor({
              id: client.id,
              data: { pause_debtor: false },
              callback: () => {
                dialogHide(DialogShowId.CUSTOM);
                clientActions.detail({ id: client.id });
              },
            });
          }}
        />
      ),
    });
  };

  const handleDispute = (data) => {
    disputeDebtor(
      {
        id: client.id,
        data: { ...data, dispute: true },
      },
      {
        onSuccess: () => {
          dialogHide(DialogShowId.CUSTOM);
          clientActions.detail({ id: client.id });
        },
      },
    );
  };

  const dispute = (clientDisputed: boolean) => (e) => {
    e.stopPropagation();
    if (client.paused) return;

    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys[clientDisputed ? 'CONFIRM' : 'MARK_AS_DISPUTED']),
      children: clientDisputed ? (
        <AYSModal
          text={t(i18nKeys.AYS.CLIENT_ACTION_UNDISPUTED)}
          onConfirm={() => {
            disputeDebtor(
              { id: client.id, data: { dispute: false } },
              {
                onSuccess: () => {
                  dialogHide(DialogShowId.CUSTOM);
                  clientActions.detail({ id: client.id });
                },
              },
            );
          }}
        />
      ) : (
        <SetReasonForm onSubmit={handleDispute} reasonType="dispute" />
      ),
    });
  };

  const create = (type) => () => {
    history.push(`/${type}/create?debtor_id=${client.id}`);
  };

  const addTask = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <TaskForm
          clientId={client.id}
          onSubmit={(data: CreateTaskBody) =>
            createTask(
              { data },
              {
                onSuccess: () => {
                  sideMenuHide();
                },
              },
            )
          }
        />
      ),
    });
  };

  const addPaymentPlan = () => {
    history.push(`/payment-plans/create?debtor_id=${client.id}`);
  };

  const addContactPerson = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <ContactPersonForm
          onSubmit={(data: CreateContactPersonBody) =>
            createContactPerson(
              { id: client.id, data },
              {
                onSuccess: () => {
                  refetch();
                  sideMenuHide();
                },
              },
            )
          }
        />
      ),
    });
  };

  return (
    <PageTitle>
      {client.full_name}{' '}
      {client.paused && <Icon name={IconName.PAUSE} title={t(i18nKeys.RECOVERY_IN_PAUSE)} />}
      {client.has_payment_plan && (
        <Icon name={IconName.PAYMENT_BOOK} title={t(i18nKeys.ONGOING_PAYMENT_PLAN)} />
      )}
      {client.disputed && (
        <Icon
          name={IconName.ALERT_CIRCLE}
          title={t(i18nKeys.FOLLOW_UP.SUMMARY.CONFLICT)}
          color={IconColor.RED}
        />
      )}
      <PageTitle.Actions>
        <Dropdown
          sideMenuInMobile
          sideMenuTitle={t(i18nKeys.FORM.ACTIONS)}
          selectorContent={
            <Button
              style={{
                borderRadius: '100%',
                aspectRatio: '1',
                width: '44px',
                fontSize: '24px',
                padding: '0px',
              }}
              label="+"
              noMargin
            />
          }
        >
          {!hasActiveConnector && (
            <div>
              <div className={styles('dropdown-item')} onClick={create('invoices')}>
                {t(i18nKeys.NEW_INVOICE)}
              </div>
              <div className={styles('dropdown-item')} onClick={create('credit-notes')}>
                {t(i18nKeys.NEW_CREDIT_NOTE)}
              </div>
            </div>
          )}
          {company.package.can_use_tasks && (
            <div className={styles('dropdown-item')} onClick={addTask}>
              {t(i18nKeys.NEW_TASK)}
            </div>
          )}
          {company.package.can_use_payment_plans && (
            <div className={styles('dropdown-item')} onClick={addPaymentPlan}>
              {t(i18nKeys.NEW_PAYMENT_PLAN)}
            </div>
          )}
          <div className={styles('dropdown-item')} onClick={addContactPerson}>
            {t(i18nKeys.CONTACT_PERSON.ADD)}
          </div>
        </Dropdown>
        <Button
          noMargin
          label={t(i18nKeys.EDIT)}
          onClick={handleClientSettings}
          iconLeft={IconName.PENCIL}
        />
        <Dropdown
          sideMenuInMobile
          sideMenuTitle={t(i18nKeys.FORM.ACTIONS)}
          selectorContent={
            <Button
              noMargin
              iconRight={IconName.ARROW_BOTTOM_ROUNDED}
              color={ButtonColor.WHITE}
              label={t(i18nKeys.FORM.ACTIONS)}
            />
          }
        >
          {client.paused ? (
            <div
              className={styles('dropdown-item', client.disputed && 'disabled')}
              onClick={pauseDebtor(false)}
            >
              {t(i18nKeys.CLIENT.DETAIL.UNPAUSE_DEBTOR_RECOVERY)}
            </div>
          ) : (
            <div
              className={styles('dropdown-item', client.disputed && 'disabled')}
              onClick={pauseDebtor(true)}
            >
              {t(i18nKeys.CLIENT.DETAIL.PAUSE_DEBTOR_RECOVERY)}
            </div>
          )}
          <div
            className={styles('dropdown-item', client.paused && 'disabled')}
            onClick={dispute(client.disputed)}
          >
            {t(i18nKeys[client.disputed ? 'REVERT_DISPUTED' : 'MARK_AS_DISPUTED'])}
          </div>

          <div className={styles('dropdown-item')} onClick={handleMerge}>
            {t(i18nKeys.MERGE_CLIENT)}
          </div>
          <div className={styles('dropdown-item')} onClick={handleDelete}>
            {t(i18nKeys.DELETE)}
          </div>
        </Dropdown>
      </PageTitle.Actions>
    </PageTitle>
  );
};
