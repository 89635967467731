import { i18nKeys } from 'locales';

export const emailRegex = new RegExp('^\\w+[\\+\\.\\w-]*@([\\w-]+\\.)+[\\w-]{2,}$');

export const email = (value) =>
  value && !emailRegex.test(value) ? i18nKeys.ERROR.INVALID_EMAIL : true;

export const validationMessage = {
  invalid: i18nKeys.ERROR.INVALID,
  required: i18nKeys.ERROR.FIELD_REQUIRED,
  emailInputNotEmpty: i18nKeys.ERROR.FIELD_NOT_EMPTY,
  passwordNotMatch: i18nKeys.ERROR.INVALID_PASSWORD,
  email: i18nKeys.ERROR.INVALID_EMAIL,
  minLength: i18nKeys.ERROR.MIN_LENGTH,
  maxLength: i18nKeys.ERROR.MAX_LENGTH,
  min: i18nKeys.ERROR.FIELD_VALUE_TOO_SMALL,
  max: i18nKeys.ERROR.FIELD_VALUE_TOO_BIG,
};
