import { useContext, useEffect, useRef } from 'react';
import { Comment } from 'api/models';
import { CommentForm, CommentItem } from 'app/Private/Comments';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import moment from 'moment';
import Card from 'shared/components/Card';
import { invoiceActions } from 'store/invoice/invoice.actions';

import InvoiceDetailContext from '../InvoiceDetail.context';

import styleIdentifiers from '../ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function InvoiceDetailComment() {
  const { t } = useTranslation();

  const { data, credit } = useContext(InvoiceDetailContext);
  const commentCardRef = useRef<HTMLDivElement>(null);

  const comments = data.comments_attributes;

  useEffect(() => {
    commentCardRef.current!.scrollTop = commentCardRef.current!.scrollHeight;
  }, [comments]);

  const submitComment = (values: Object) => {
    if (!data) return;

    invoiceActions.actionsComment({
      id: data.id,
      data: values,
    });
  };

  return (
    <Card
      title={<div className={styles('comment-head')}>{t(i18nKeys.NOTES)}</div>}
      className={styles('comments')}
    >
      <div className={styles('comment-container')} ref={commentCardRef}>
        {comments?.map((comment) => (
          <CommentItem
            comment={
              {
                id: comment.id,
                type: comment.type,
                attributes: {
                  ...omit(comment, ['id', 'type', 'date']),
                  date: moment.isMoment(comment.date) ? comment.date.toISOString() : comment.date,
                },
              } as Comment
            }
            key={comment.id}
          />
        ))}
        {!credit && (
          <CommentForm
            onSubmit={submitComment}
            label={t(i18nKeys.ACTIONS.ADD)}
            labelText={t(i18nKeys.NEW_NOTE)}
          />
        )}
      </div>
    </Card>
  );
}
