import { Address, Notification } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddressBlock from 'shared/components/AddressBlock';
import { Icon, IconName } from 'shared/components/Icon';
import AddressForm from 'shared/forms/AddressForm';
import { Dropdown } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import styleIdentifiers from './AddressPartialForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type AddressPartialFormProps = {
  addresses?: Address[];
};
export default function AddressPartialForm({ addresses = [] }: AddressPartialFormProps) {
  const { t } = useTranslation();

  const { control, setValue, watch, register } = useFormContext();

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'addresses_attributes',
  });

  const openAddressForm = (address, index?: number) => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.FORM.ADDRESS.ADD),
      children: (
        <AddressForm
          initialValues={address}
          onSubmit={(values) => {
            if (Number.isInteger(index)) {
              setValue(`addresses_attributes.${index as number}`, values);
            } else {
              append(values);
            }
            dialogHide(DialogShowId.CONFIRM);
          }}
        />
      ),
    });
  };

  const removeField = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles('address-partial-form')}>
      <div className={styles('title')}>
        <div className={styles('label-input')}>
          {t(i18nKeys.FORM.FORMAL_NOTICE.SHIPPING_ADDRESS)}
        </div>
        <div className={styles('line')} />
        <Dropdown selectorContent={<Icon name={IconName.PLUS} />}>
          <div className={styles('dropdown-item')} onClick={() => openAddressForm({})}>
            <span>
              <Icon name={IconName.PLUS} size="10px" /> {t(i18nKeys.NEW_ADDRESS)}
            </span>
          </div>
          {addresses.map((address) => (
            <div
              key={address.id}
              className={styles('dropdown-item')}
              onClick={() => append({ ...address.attributes })}
            >
              {!address.attributes.notifications?.includes(Notification.Debtor) && (
                <b>{address.attributes.description}</b>
              )}
              <AddressBlock address={address} clean />
            </div>
          ))}
        </Dropdown>
      </div>
      {fields.map((field, index) => {
        register(`addresses_attributes.${index}`);

        if (watch(`addresses_attributes.${index}._destroy`)) {
          return undefined;
        }
        return (
          <div className={styles('address')} key={field.id}>
            <AddressBlock
              address={watch(`addresses_attributes.${index}`)}
              edit={() => openAddressForm(watch(`addresses_attributes.${index}`), index)}
              remove={
                watch('addresses_attributes').length === 1 ? undefined : () => removeField(index)
              }
            />
          </div>
        );
      })}
    </div>
  );
}
