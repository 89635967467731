import classNames from 'classnames/bind';
import { LightUser } from 'shared/hooks';

import styleIdentifiers from './UserAvatar.module.scss';

const styles = classNames.bind(styleIdentifiers);

type UserAvatarProps = {
  user: LightUser;
};

export default function UserAvatar({ user }: UserAvatarProps) {
  if (user.profilePicture != null) {
    return (
      <div
        className={styles('collaborator-picture')}
        style={{
          backgroundImage: `url(${user.profilePicture})`,
        }}
      />
    );
  }

  return (
    <div className={styles('collaborator-picture')}>
      <span className={styles('initials')}>{user.name.initials}</span>
    </div>
  );
}
