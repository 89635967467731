export const STEP_TO_STEP_THEME = {
  0: { color: 'var(--mantine-color-cyan-5)', filledDots: 1 },
  1: { color: 'var(--mantine-color-yellow-4)', filledDots: 2 },
  2: { color: 'var(--mantine-color-orange-5)', filledDots: 3 },
  3: { color: 'var(--mantine-color-red-5)', filledDots: 4 },
  4: { color: 'var(--mantine-color-orange-9)', filledDots: 5 },
  5: { color: '#343944', filledDots: 0 },
};

export const AVAILABLE_CURRENCIES = [
  {
    description: 'EUR (€)',
    value: 'EUR',
    symbol: '€',
  },
  {
    description: 'USD ($)',
    value: 'USD',
    symbol: '$',
  },
  {
    description: 'GBP (£)',
    value: 'GBP',
    symbol: '£',
  },
  {
    description: 'CHF (CHF)',
    value: 'CHF',
    symbol: 'CHF',
  },
  {
    description: 'SEK (kr)',
    value: 'SEK',
    symbol: 'kr',
  },
  {
    description: 'JPY (¥)',
    value: 'JPY',
    symbol: '¥',
  },
  {
    description: 'NOK (kr)',
    value: 'NOK',
    symbol: 'kr',
  },
] as const;
