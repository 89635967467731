import { i18nKeys, useTranslation } from 'locales';
import { Button } from 'shared/io';

type ExportButtonProps = {
  exportCsv: () => void;
};

export const ExportButton = ({ exportCsv }: ExportButtonProps) => {
  const { t } = useTranslation();

  return <Button noMargin label={t(i18nKeys.EXPORT_CSV)} onClick={exportCsv} />;
};
