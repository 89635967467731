import createDOMPurify from 'dompurify';

const Dompurify = createDOMPurify(window);
export type HtmlProps = {
  html: any;
  className?: string;
};

export default function HTML({ html, className }: HtmlProps) {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: Dompurify.sanitize(html, { ADD_ATTR: ['target'] }),
      }}
    />
  );
}
