import { Id } from 'api/models';
import { AxiosInstance } from 'axios';
import { mapKeys, omit, toCamelCase } from 'remeda';
import { CamelCasedProperties } from 'type-fest';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useLegacyAxiosInstance } from './utils';

const CurrencyDataSchema = z
  .object({
    localized_total: z.coerce.number(),
    EUR: z.string().optional(),
    USD: z.string().optional(),
    GBP: z.string().optional(),
    CHF: z.string().optional(),
    SEK: z.string().optional(),
    JPY: z.string().optional(),
    NOK: z.string().optional(),
  })
  .transform((obj) => ({
    ...omit(obj, ['localized_total']),

    localizedTotal: obj.localized_total,
  }));
const DebtorsDetailedBalanceSchema = z.object({
  data: z
    .object({
      detailed_balance: z
        .object({
          invoices_total: CurrencyDataSchema,
          claimed_fees: CurrencyDataSchema,
          credit_notes_total: CurrencyDataSchema,
          payments_total: z.string(),
          remaining_balance: z.string(),
          remaining_late_fees: z.string(),
          remaining_balance_with_fees: z.string(),
          lost_total: z.string(),
        })
        .transform((obj) => mapKeys(obj, toCamelCase) as CamelCasedProperties<typeof obj>),
    })
    .transform((obj) => mapKeys(obj, toCamelCase) as CamelCasedProperties<typeof obj>),
});

export type DebtorsDetailedBalance = z.infer<typeof DebtorsDetailedBalanceSchema>;

export async function LoadDebtorsDetailedBalanceFn(
  axiosInstance: Promise<AxiosInstance>,
  { queryKey },
) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/debtors_detailed_balance', {
    params: { account_manager_id: queryKey[1] },
  });
  return DebtorsDetailedBalanceSchema.parse(data);
}

export const useLoadDetailedDebtorBalance = (variables: { accountManagerId?: Id }) => {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<DebtorsDetailedBalance, ApiError>({
    queryKey: ['detailedDebtorBalance', variables.accountManagerId],
    queryFn: (context) => LoadDebtorsDetailedBalanceFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<DebtorsDetailedBalance, ApiError, 'detailedDebtorBalance'>(
    'detailedDebtorBalance',
    queryResult,
  );
};
