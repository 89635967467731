import { DisplayType, VarType } from 'app/Private/CustomVariables/CustomVariables.types';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import EditCustomVariable from 'shared/action-component/EditCustomVariable';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { formattedDate } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { CustomVariable } from 'types/custom-variable-state';
import { StoreState } from 'types/storeTypes';
import { TableItemProps } from 'types/table';

import { captureMessage } from '@sentry/react';

import styleIdentifiers from './CustomizationSettingsCustomVariablesItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = TableItemProps<CustomVariable>;

export const CustomizationSettingsCustomVariablesItem = ({ item }: Props) => {
  const { t, currentLang } = useTranslation();
  // For diagnostics below
  const customVariableList = useSelector((state: StoreState) => state.settings.customVariableList);

  const handleClick = (item) => (e) => {
    e.stopPropagation();
    EditCustomVariable({
      customVariable: item,
    });
  };

  const handleDelete = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.AYS_TO_DELETE)}
          onConfirm={() => settingsActions.deleteCustomVariable({ id: item.id })}
        />
      ),
    });
  };

  const renderValue = () => {
    const defaultValue = item.default_value;
    if (defaultValue?.toString()) {
      switch (item.var_type) {
        case VarType.BOOLEAN:
          return t(i18nKeys[defaultValue.toString() === 'true' ? 'YES' : 'NO']);
        case VarType.DATE:
          return formattedDate(Date.parse(defaultValue), currentLang);
        default:
          switch (item.display) {
            case DisplayType.MONEY:
              return (
                <NumericFormat
                  value={defaultValue}
                  displayType="text"
                  suffix="€"
                  decimalSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator=" "
                />
              );
            case DisplayType.PERCENTAGE:
              return (
                <NumericFormat
                  value={defaultValue}
                  suffix="%"
                  decimalScale={2}
                  displayType="text"
                />
              );
            default:
              return defaultValue;
          }
      }
    } else {
      return 'N/A';
    }
  };

  if (item == null || item.var_type == null) {
    captureMessage(
      'In CustomizationSettingsCustomVariablesItem, item was nullish or missing `var_type` property',
      { extra: { item, customVariables: customVariableList.pages } },
    );
    return null;
  }

  return (
    <tr className={styles('custom-variable-item')}>
      <td />
      <td>{item.name}</td>
      <td>{item.column_name}</td>
      <td>{t(i18nKeys.COLUMN_TYPE[item.var_type.toUpperCase()])}</td>
      <td>{t(i18nKeys.COMMON[item.model_type === 'invoice' ? 'INVOICE' : 'CLIENT'])}</td>
      <td>{renderValue()}</td>
      <td className={styles('actions')}>
        <span onClick={handleClick(item)}>
          <Icon name={IconName.PENCIL} size="18px" className={styles('action')} />
        </span>
        <span onClick={handleDelete}>
          <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
        </span>
      </td>
    </tr>
  );
};
