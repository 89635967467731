import { AxiosInstance } from 'axios';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useLegacyAxiosInstance } from './utils';

export type DeleteExternalMailVariables = {
  id: any;
};

export async function deleteExternalMailFn(
  axiosInstance: Promise<AxiosInstance>,
  variables: DeleteExternalMailVariables,
): Promise<ApiResponse> {
  const { id } = variables;
  const instance = await axiosInstance;
  return instance.delete(`external_mails/${id}`);
}

export function useDeleteExternalMail() {
  const client = useQueryClient();
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, DeleteExternalMailVariables>({
    mutationFn: (variables) => deleteExternalMailFn(axiosInstance, variables),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({ queryKey: ['externalMails'] });
    },
    onError: (error: ApiError) => {
      notifications.show({
        color: 'red',
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    DeleteExternalMailVariables,
    'deleteExternalMail'
  >('deleteExternalMail', mutation);
}
