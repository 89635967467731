/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

/**
 * @nullable
 */
export type BalanceTypes = (typeof BalanceTypes)[keyof typeof BalanceTypes] | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BalanceTypes = {
  invoice: 'invoice',
  credit_note: 'credit_note',
  payment: 'payment',
} as const;
