import { browserHistory } from 'init/redux-store';
import moment, { Moment } from 'moment';
import qs from 'query-string';
import { mapKeys } from 'remeda';
import { appActions } from 'store/app/app.actions';
import { Locale } from 'types';

import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/nl';

export const animationClassList = (name, styles) => ({
  appear: styles(`${name}-appear`),
  appearActive: styles(`${name}-appear-active`),
  enter: styles(`${name}-enter`),
  enterActive: styles(`${name}-enter-active`),
  enterDone: styles(`${name}-enter-done`),
  exit: styles(`${name}-exit`),
  exitActive: styles(`${name}-exit-active`),
  exitDone: styles(`${name}-exit-done`),
});

export default animationClassList;

// Only used once, easy to replace by getQueryParams in url.ts
export const getQueryVariable = (query, variable) => {
  if (!query) return '';
  query = query.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
};

// Only used once
export function downloadBlobFile(blobURL, filename, blob?) {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    if (blob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      fetch(blobURL)
        .then((res) => res.blob())
        .then((resBlob) => {
          window.navigator.msSaveBlob!(resBlob, filename);
        });
    }
  } else {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }
}

/**
 * Download a file from an api
 */
export function downloadFile(data: any, filename: string, mime?: string) {
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  const blobUrl = window.URL.createObjectURL(blob);
  downloadBlobFile(blobUrl, filename, blob);
  window.URL.revokeObjectURL(blobUrl);
}

/**
| Date function
|--------------------------------------------------
*/

export function formattedDate(date: Date | Moment | number | string, lg: Locale, format?: string) {
  return (
    date &&
    moment
      .utc(date)
      .local()
      .locale((lg || 'fr') as string)
      .format(format || 'DD/MM/YYYY')
  );
}

export function dateFromNow(date: Date, lg: Locale) {
  return (
    date &&
    moment
      .utc(date)
      .local()
      .locale(lg ?? 'fr')
      .fromNow()
  );
}

export const extractFilename = (headers) => {
  const disposition = headers['content-disposition'];
  let filename = disposition.match('filename="(.*)"');
  filename = filename && filename[1];

  return filename;
};

export const removeAttributes = (item) => {
  item = {
    ...item,
    ...item.attributes,
  };
  delete item.attributes;
  return item;
};

// Only used twice, easy to replace
export const updateQueryFilters = (queryStrings) => {
  browserHistory.push({
    pathname: location.pathname,
    search: qs.stringify(queryStrings, { arrayFormat: 'comma' }),
  });
};

export const reducerState = (prevState, updatedProperty) => ({
  ...prevState,
  ...updatedProperty,
});

export const getBankImage = async (bic: string) => {
  const banks = {
    ARSPBE22: 'argenta.png',
    AXABBE22: 'axa.png',
    GKCCBEBB: 'belfius.png',
    CTBKBEBX: 'beobank.png',
    GEBABEBB: 'bnp.png',
    BPOTBEB1: 'bpost.png',
    CREGBEBB: 'cbc.png',
    CEKVBE81: 'ckv.png',
    NICABEBB: 'crelan.png',
    DEUTBEBE: 'deutschebank.png',
    EURBBE99: 'europabank.png',
    BBRUBEBB: 'ing.png',
    KREDBEBB: 'kbc.png',
    SGPBBE99: 'societe-generale.png',
    TRIOBEBB: 'triodos.png',
  };

  if (banks[bic]) {
    const imageModule = await import(/* @vite-ignore */ `./assets/bank/${banks[bic]}`);
    return imageModule.default;
  }

  return null;
};

export const queryParamParser = (value) => {
  Object.keys(value).forEach((key) => {
    if (key.indexOf('_start') !== -1) {
      const fieldName = key.substring(0, key.indexOf('_start'));
      value[fieldName] = [value[`${fieldName}_start`] || null, value[`${fieldName}_end`] || null];
    } else if (key.indexOf('_end') !== -1) {
      const fieldName = key.substring(0, key.indexOf('_end'));
      value[fieldName] = [value[`${fieldName}_start`] || null, value[`${fieldName}_end`] || null];
    }
  });

  return mapKeys(value, (k) => String(k).replace('?', ''));
};

export const reloadCustomView = (callback) => {
  appActions.getView({
    id: location.hash.slice(1) as any,
    data: queryParamParser(qs.parse(location.search, { arrayFormat: 'comma' })),
    callback: (data) => {
      callback(data, 'fulfilled');
    },
  });
};
