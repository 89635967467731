import { ExternalMailReadStatus, ExternalMailStatus } from 'api/models';
import { i18nKeys } from 'locales';
import { ExternalMailsSearchParams } from 'shared/hooks';

export type ExternalMailListFiltersFields = Omit<
  ExternalMailsSearchParams,
  'page' | 'page_limit' | 'sort_by' | 'sort_order'
>;

type TableHeader<T extends object> = {
  title: string;
  key?: keyof T;
  width?: number;
};

export const TABLE_HEADERS: TableHeader<ExternalMailsSearchParams & { date: string }>[] = [
  {
    key: 'subject',
    title: i18nKeys.SUBJECT,
  },
  {
    key: 'from_mail',
    title: i18nKeys.FROM,
  },
  {
    key: 'status',
    title: i18nKeys.STATUS,
  },
  {
    key: 'date',
    title: i18nKeys.DATE,
  },
  {
    title: i18nKeys.FORM.ACTIONS,
    width: 100,
  },
];

export const EXTERNAL_MAIL_STATUSES = Object.values({
  ...ExternalMailStatus,
  ...ExternalMailReadStatus,
}).map((value) => ({
  description: i18nKeys.MAILS.STATUS[value.toUpperCase()],
  value,
}));
