import { remove } from 'lodash-es';
import moment from 'moment';

import { captureMessage } from '@sentry/react';

function parseAsDateOrForwardValue<T>(key: string, value: T): T | moment.Moment {
  /* Leo on 2021-03-04: It's unclear to me whether this function is actually useful but I won't
  risk breaking something deep in the app by removing it as it's used a lot in mutation calls.
  We capture a message when we do actually parse a date so we can see if this is ever useful.
  Since so far the only field it attempts to parse is a false positive, I'm adding an exclusion array
  to at least silence the moment.js warning about being fed bad data.
  */
  const excludedKeys = ['currency_conversion_date'];

  const couldBeDate =
    typeof value === 'string' && key.includes('date') && !excludedKeys.includes(key);

  if (!couldBeDate) return value;
  const maybeDate = moment.utc(value);

  if (maybeDate.isValid()) return maybeDate;

  const message = `In store/reducers.ts, expected to parse the following as date but failed.
  This could be normal but should be used to narrow date casting rules`;
  console.warn(message, { [key]: value });
  captureMessage(`${message}: ${JSON.stringify({ [key]: value })}`);
  return value;
}

const getStateData = (state, name) => (!state[name]?.data ? false : state[name].data.slice());

// return true if same
const compareSearch = (search1, search2) => {
  const elem1 = {
    ...search1,
    page: undefined,
  };
  const elem2 = {
    ...search2,
    page: undefined,
  };

  return JSON.stringify(elem1) === JSON.stringify(elem2);
};

export const compareItems = (item1, item2, key?) =>
  transformValue(item1, key) === transformValue(item2, key);

const transformValue = (item, key) => {
  let itemValue;
  const label = key || 'value';
  if (item && item[label] !== undefined) itemValue = item[label];
  else itemValue = item;

  if (itemValue === undefined) return undefined;

  itemValue = !isNaN(itemValue) ? itemValue.toString() : itemValue;

  return itemValue;
};

function treatData(data: any, transformFunc?: (args: any) => unknown) {
  if (transformFunc == null) return data;
  // Pass the item explicitly to avoid all the extra arguments that map passes being spread to transformFunc
  if (Array.isArray(data)) return data.map((item) => transformFunc(item));
  return transformFunc(data);
}

function handleDefaultEndRequest(state, name) {
  return {
    ...state,
    [name]: {
      ...state[name],
      loaded: true,
      loading: false,
    },
  };
}

export function handleRequest(state, { payload }, name: string, reset?) {
  let noReset = payload?.noReset;
  if (!noReset) noReset = !reset;

  const newElem = noReset
    ? {
        ...state[name],
        loaded: false,
        loading: true,
      }
    : {
        loaded: false,
        loading: true,
      };

  return {
    ...state,
    [name]: newElem,
  };
}

export const handlePageRequest = (state, action, name) => {
  const payload = action.payload || {};
  const currentSearch = state[name] && state[name].search;
  const isReset = !compareSearch(currentSearch, payload);

  const tmpState = handleRequest(state, action, name, payload.reset === false ? false : isReset);

  return {
    ...tmpState,
    [name]: {
      ...tmpState[name],
      search: payload,
      currentPage: (payload && payload.page) || 1,
    },
  };
};

/**
 * Setter
 */
export function handleResponse(
  state,
  action,
  name: string,
  transformFunc?: (value: any) => unknown,
) {
  const { payload, status, requestData } = action;

  if (payload && status === 'fulfilled') {
    return {
      ...state,
      [name]: {
        ...state[name],
        ...payload,
        requestData,
        data: treatData(payload.data, transformFunc),
        loaded: true,
        loading: false,
      },
    };
  }
  return handleDefaultEndRequest(state, name);
}

export function handleReset(state, action, name) {
  return {
    ...state,
    [name]: null,
  };
}

export function handlePageResponse(
  state,
  { payload, status, reset },
  name,
  transformFunc,
  options?: { infiniteScroll: boolean },
) {
  const { infiniteScroll: isInfiniteScrollEnabled } = options || {};

  if (!payload) return handleDefaultEndRequest(state, name);

  const { data, metadata } = payload;

  if (data && status === 'fulfilled') {
    const items = isInfiniteScrollEnabled
      ? {
          data: [...(reset ? [] : state[name].data || []), ...treatData(data, transformFunc)],
        }
      : {
          pages: treatData(data, transformFunc),
        };

    return {
      ...state,
      [name]: {
        ...state[name],
        ...items,
        metadata: {
          ...metadata,
        },
        loaded: true,
        loading: false,
      },
    };
  }

  return handleDefaultEndRequest(state, name);
}

/**
 * Any add response (list or unit)
 */
export function handleAddResponse(state, action, name, transformFunc) {
  const {
    payload: { data, metadata },
    status,
  } = action;

  const list = getStateData(state, name);
  if (!list) return handleDefaultEndRequest(state, name);

  if (data && status === 'fulfilled') {
    return {
      ...state,
      [name]: {
        ...state[name],
        metadata,
        data: [...list.concat(treatData(data, transformFunc))],
        loaded: true,
        loading: false,
      },
    };
  }

  // PAGES MODE
  return handleDefaultEndRequest(state, name);
}

export function handleUpdateMultipleResponse(state, action, name, transformFunc) {
  const {
    payload: { data },
    status,
  } = action;

  if (data && status === 'fulfilled') {
    const list = getStateData(state, name);
    if (!list) return handleDefaultEndRequest(state, name);

    for (const item of data) {
      const index = list.findIndex((listItem) => listItem.id === item.id);
      list.splice(index, 1, treatData(item, transformFunc));
    }

    return {
      ...state,
      [name]: {
        ...state[name],
        data: list,
        loaded: true,
        loading: false,
      },
    };
  }
  return handleDefaultEndRequest(state, name);
}

export function handleUpdateResponse(state, action, name, transformFunc) {
  const {
    payload: { data },
    status,
  } = action;

  if (data && status === 'fulfilled') {
    const list = getStateData(state, name);
    if (!list) return handleDefaultEndRequest(state, name);

    const index = list.findIndex((item) => item.id === data.id);
    list.splice(index, 1, treatData(data, transformFunc));

    return {
      ...state,
      [name]: {
        ...state[name],
        data: list,
        loaded: true,
        loading: false,
      },
    };
  }
  return handleDefaultEndRequest(state, name);
}

export function handleUpdatePaginationResponse(
  state,
  action: any,
  name: string,
  transformFunc?: (value: any) => unknown,
) {
  const {
    payload: { data },
    status,
  } = action;

  if (data && status === 'fulfilled') {
    const pages = state[name]?.pages;
    if (!pages) return handleDefaultEndRequest(state, name);

    const index = pages.findIndex((item) => item.id === data.id);
    pages.splice(index, 1, treatData(data, transformFunc));

    return {
      ...state,
      [name]: {
        ...state[name],
        pages: [...pages],
        loaded: true,
        loading: false,
      },
    };
  }

  return handleDefaultEndRequest(state, name);
}

export function handleUpdateMultiplePaginationResponse(
  state,
  action: any,
  name: string,
  transformFunc?: (value: any) => unknown,
) {
  const {
    payload: { data },
    status,
  } = action;

  if (data && status === 'fulfilled') {
    const pages = state[name]?.pages;
    if (!pages) return handleDefaultEndRequest(state, name);

    for (const item of data) {
      const index = pages.findIndex((listItem) => listItem.id === item.id);
      pages.splice(index, 1, treatData(item, transformFunc));
    }

    return {
      ...state,
      [name]: {
        ...state[name],
        pages: [...pages],
        loaded: true,
        loading: false,
      },
    };
  }

  return handleDefaultEndRequest(state, name);
}

export function handleDeleteResponse(state, action, name) {
  const { payload, status } = action;

  if (payload && status === 'fulfilled') {
    const list = getStateData(state, name);
    if (!list) return handleDefaultEndRequest(state, name);

    remove<{ id: number }>(list, (item) => item.id === payload);

    return {
      ...state,
      [name]: {
        ...state[name],
        data: list,
        loaded: true,
        loading: false,
      },
    };
  }

  return handleDefaultEndRequest(state, name);
}

export function handleDeletePaginationResponse(state, action, name) {
  const { payload, status } = action;

  if (payload && status === 'fulfilled') {
    const pages = state[name]?.pages;
    if (!pages) return handleDefaultEndRequest(state, name);

    remove<{ id: number }>(pages, (item) => item.id === payload);

    return {
      ...state,
      [name]: {
        ...state[name],
        pages: [...pages],
        loaded: true,
        loading: false,
      },
    };
  }

  return handleDefaultEndRequest(state, name);
}

export function flattenItem(
  item: unknown,
  keysToExclude: Array<string> = [], // Keys to remove from the result if item is an object
  keysToPreserve: Array<string> = [], // Keys to leave unchanged if item is an object
): any {
  // Return primitives as is
  if (['boolean', 'number', 'function', 'string', 'undefined'].includes(typeof item)) return item;

  // Remaining possible type is object, which includes array and null, take care of those
  if (Array.isArray(item)) return item;
  if (item == null) return item;

  // item should now be object
  if (typeof item !== 'object') console.warn(`${item} expected to be object but isn't`);
  let objectItem = item as Record<string, unknown>;
  const flattenedItem = {
    id: objectItem.id,
    type: objectItem.type,
  };

  objectItem = (objectItem.attributes || objectItem) as Record<string, unknown>;
  Object.entries(objectItem).forEach(([key, value]) => {
    if (keysToExclude.includes(key)) return;
    if (keysToPreserve.includes(key)) flattenedItem[key] = value;

    if (Array.isArray(value)) {
      const flattenedArray: Array<unknown> = [];
      for (const element of value) {
        const flattened = flattenItem(element);
        flattenedArray.push(flattened);
      }
      flattenedItem[`${key}_attributes`] = flattenedArray;
      return;
    }

    // extra check for null because typeof null === 'object' sadly
    if (typeof value === 'object' && value != null && 'attributes' in value) {
      flattenedItem[`${key}_attributes`] = flattenItem(value);
      return;
    }

    const parsedValue = parseAsDateOrForwardValue(key, value);
    flattenedItem[key] = parsedValue;
  });

  return flattenedItem;
}

export function addDataAction(action) {
  return {
    ...action,
    payload: {
      data: action.payload,
    },
  };
}

export const baseReducerInfinitePage = {
  currentPage: 0,
  data: [],
  loaded: false,
  loading: false,
  metadata: {
    pagination: {
      current_page: 0,
      last_page: 0,
      page_limit: 0,
      total_objects: 0,
    },
    total: 0,
  },
  search: '',
};

export const baseReducerListPage = {
  ...baseReducerInfinitePage,
  pages: [],
};

export const baseReducerData = {
  data: null,
  loading: false,
  loaded: false,
};
