import { CreateTaskTypeBody, TaskType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, ButtonColor, Input } from 'shared/io';

import styleIdentifiers from './TaskTypeForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskFormProps = {
  taskType?: TaskType;
  onSubmit: SubmitHandler<CreateTaskTypeBody>;
  onCancel?: () => void;
};

export const TaskTypeForm = ({ taskType, onSubmit, onCancel }: TaskFormProps) => {
  const { t } = useTranslation();

  const defaultValues = {
    name: taskType?.attributes.name,
  };

  const form = useForm<CreateTaskTypeBody>({
    shouldUnregister: true,
    defaultValues,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;

  return (
    <FormProvider {...form}>
      <form className={styles('task-type-form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('content')}>
          <Input
            register={register('name', {
              required: true,
            })}
            errorMessage={get(errors, 'name')}
            noMargin
            withBorder
            label={t(i18nKeys.TASK.ATTRIBUTES.TITLE)}
          />
        </div>
        <div className={styles('button-wrapper', 'buttons')}>
          <Button color={ButtonColor.GREY} label={t(i18nKeys.CANCEL)} onClick={onCancel} />
          <Button type="submit" label={t(i18nKeys.SAVE)} />
        </div>
      </form>
    </FormProvider>
  );
};
