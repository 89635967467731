import { isPlainObject } from 'remeda';
import { DescVal } from 'types/genericType';

export function isPropertyPresent(obj: Object, property: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, property);
}

export function arePropertiesPresent(obj: Object, properties: Array<string>): boolean {
  return properties.every((property) => isPropertyPresent(obj, property));
}

export function isDescVal(candidate: unknown): candidate is DescVal {
  return isPlainObject(candidate) && arePropertiesPresent(candidate, ['description', 'value']);
}

export function isDynamicFooterField(
  candidate: unknown,
): candidate is { field: string; name: string } {
  return isPlainObject(candidate) && arePropertiesPresent(candidate, ['field', 'content']);
}
