import { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import { FieldError } from 'react-hook-form';
import Switch from 'react-switch';
import { validationMessage } from 'shared/utils/validation';
import { OnChangeEvent } from 'types/html-event';

import { useMantineTheme } from '@mantine/core';

import styleIdentifiers from './SwitchInput.module.scss';

const styles = classNames.bind(styleIdentifiers);

type SwitchInputProps = {
  label?: ReactNode;
  noMargin?: boolean;
  className?: string;
  inactiveColor?: string;
  activeColor?: string;
  inversed?: boolean;
  onChange?: OnChangeEvent;
  value?: any;
  labelClassName?: string;
  error?: any;
  noShadow?: boolean;
};

export const SwitchInput = ({
  label,
  labelClassName,
  noMargin,
  className,
  inversed,
  onChange,
  error,
  value,
  noShadow,
  ...rest
}: SwitchInputProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <div className={styles('SwitchInput', className, noMargin && 'no-margin')}>
      <div className={styles('switch-wrapper', inversed && 'inversed')}>
        <div className={styles('label', labelClassName)}>{label}</div>
        <Switch
          onChange={onChange}
          {...rest}
          checked={value || false}
          handleDiameter={20}
          height={18}
          width={34}
          onColor={theme.colors.blue[7]}
          boxShadow={theme.shadows.xs}
          checkedIcon={false}
          className={styles('switch-input', !noShadow && 'shadow')}
          uncheckedIcon={false}
        />
      </div>
      {error && (
        <div className={styles('error-message')}>
          {t(
            validationMessage[(error as FieldError).type] || (error as FieldError).message || error,
          )}
        </div>
      )}
    </div>
  );
};
