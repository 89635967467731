import { useEffect, useState } from 'react';
import { useSearchInvoices } from 'api';
import {
  AttachedInvoice,
  CreatePaymentStepCalculationBody,
  DebtorBasic,
  DebtorLight,
  Id,
  SearchInvoicesParams,
} from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import CustomTable from 'shared/components/CustomTable';

import PaymentPlanInvoiceItem from './PaymentPlanInvoiceItem';

import styleIdentifiers from './StepsCalculation.module.scss';

const styles = classNames.bind(styleIdentifiers);

const GroupedTable = CustomTable(PaymentPlanInvoiceItem);

type StepsCalculationInvoicesProps = {
  name?: 'invoice_ids';
  currentDebtor?: DebtorBasic | DebtorLight;
  attachedInvoices?: AttachedInvoice[];
};

export const StepsCalculationInvoices = ({
  name = 'invoice_ids',
  currentDebtor,
  attachedInvoices = [],
}: StepsCalculationInvoicesProps) => {
  const { t } = useTranslation();

  const { register, watch, setValue } = useFormContext<CreatePaymentStepCalculationBody>();

  const debtor_id = watch('debtor_id');

  const [params, setParams] = useState<SearchInvoicesParams>({
    debtor_id,
    currency: currentDebtor?.attributes.currency,
    exclude: attachedInvoices.map((e) => e.id),
    payment_plan: true,
  });

  const { data: invoicesResponse } = useSearchInvoices(params);

  useEffect(() => {
    if (currentDebtor) {
      setParams((currentParams) => ({
        ...currentParams,
        debtor_id: currentDebtor.id,
      }));
    }
  }, [currentDebtor]);

  useEffect(() => {
    if (debtor_id) {
      setParams((currentParams) => ({ ...currentParams, debtor_id }));
    }
  }, [debtor_id]);

  register(name);

  const invoiceIds = watch(name) || [];

  const onCheck = (value: Id) => {
    setValue(
      name,
      invoiceIds.includes(value) ? invoiceIds.filter((e) => e !== value) : [...invoiceIds, value],
      { shouldTouch: true, shouldDirty: true, shouldValidate: true },
    );
  };

  const invoices = invoicesResponse?.data || [];

  const toggleCheckAll = (checked) => {
    setValue(name, checked ? invoices.map(({ id }) => id) : []);
  };

  return (
    <GroupedTable
      className={styles('invoice-table')}
      onToggleCheckAll={(check) => toggleCheckAll(check)}
      noMargin
      noMinHeight
      items={invoices}
      itemProps={{
        onCheck,
        invoiceIds,
      }}
      headers={[
        { title: t(i18nKeys.FORM.REFERENCE) },
        { title: t(i18nKeys.DATE) },
        { title: t(i18nKeys.AMOUNT) },
        { title: t(i18nKeys.INVOICING.ALREADY_PAID) },
        { title: t(i18nKeys.BALANCE) },
      ]}
    />
  );
};
