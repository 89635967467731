import classNames from 'classnames/bind';
import { LightUser } from 'shared/hooks';

import CollaboratorPicture from '../UserAvatar';

import styleIdentifiers from './UserInfo.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  className?: string;
  text?: any;
  inline: boolean;
  // optional because customSelect is broken
  item?: LightUser;
  picture?: boolean;
};

export default function UserInfo({ className, text, inline, item, picture }: Props) {
  if (item == null && text == null) return null;

  return (
    <div className={styles('user-info', className)}>
      {picture && item != null && <CollaboratorPicture user={item} />}
      <div className={styles(inline && 'inline')}>
        {item != null ? (
          <>
            <div>{item.name.full}</div>
            <div>{item.email}</div>
          </>
        ) : (
          text
        )}
      </div>
    </div>
  );
}
