import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import {
  thirdPartyCaseActions,
  thirdPartyCaseConstants as events,
} from 'store/thirdpartyCase/thirdpartycase.actions';

import { sendApi } from '../sagas';

function* thirdpartyCaseWatchers() {
  yield all([
    yield takeLatest(
      events.listPage.request,
      sendApi(api.thirdpartyCase.list, thirdPartyCaseActions.listPageRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(
      events.detail.request,
      sendApi(api.thirdpartyCase.detail, thirdPartyCaseActions.detailRes),
    ),
  ]);
}

export default function* saga() {
  yield call(thirdpartyCaseWatchers);
}
