import { useEffect } from 'react';
import { useGetWorkflow } from 'api';
import { Id } from 'api/models';

type UsedWorkflowProps = {
  workflowId: Id;
};

export default function UsedWorkflow({ workflowId }: UsedWorkflowProps) {
  const { data: workflowResponse, isLoading, isError, refetch } = useGetWorkflow(workflowId);

  useEffect(() => {
    if (workflowId) {
      refetch();
    }
  }, [workflowId, refetch]);

  if (isLoading && !workflowResponse) {
    return <></>;
  }

  if (isError || !workflowResponse) {
    return <span>Error</span>;
  }

  const { data: workflow } = workflowResponse;

  const {
    attributes: { name },
  } = workflow;

  return <>{name}</>;
}
