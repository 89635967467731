import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { appActions, appConstants as events } from 'store/app/app.actions';
import {} from 'types/storeTypes';

import { userInit } from '../account/account.sagas';
import { sendApi } from '../sagas';

function* reloadCompanyConfiguration() {
  yield call(userInit);
}

function* AppWatchers() {
  yield all([
    yield takeLatest(events.reloadCompanyConfiguration.request, reloadCompanyConfiguration),
    yield takeLatest(events.customUrl.request, sendApi(api.custom)),
    yield takeLatest(events.getFiltersName.request, sendApi(api.tables.getFiltersName)),
    yield takeLatest(events.getViews.request, sendApi(api.tables.getViews, appActions.getViewsRes)),
    yield takeLatest(events.getView.request, sendApi(api.tables.getView)),
    yield takeLatest(events.updateViews.request, sendApi(api.tables.updateViews)),
    yield takeLatest(events.getColumns.request, sendApi(api.tables.getColumnsAvailable)),
    yield takeLatest(events.getFilters.request, sendApi(api.tables.getFiltersAvailable)),
    yield takeLatest(events.export.request, sendApi(api.tables.export)),
    yield takeLatest(
      events.fetchAddress.request,
      sendApi(api.settings.fetchAddress, appActions.fetchAddressRes, (payload) => ({
        ...payload,
        actionFailure: payload.callback,
      })),
    ),
  ]);
}

export default function* saga() {
  yield call(AppWatchers);
}
