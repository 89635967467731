import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';

import { useInvoiceFormTotals } from '../InvoiceForm.hooks';
import { InvoiceFormFieldValues, InvoiceFormSharedProps } from '../InvoiceForm.types';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TotalsDetailsProps = InvoiceFormSharedProps;

export const TotalsDetails = ({ currency, noTva, constants }: TotalsDetailsProps) => {
  const { t, currentLang } = useTranslation();

  const { watch } = useFormContext<InvoiceFormFieldValues>();

  const { totalTvac, totalHtva, totalVat, totalDiscount } = useInvoiceFormTotals();

  const dueDate = watch('due_date');
  return (
    <div className={styles('totals')}>
      <div>
        <div className={styles('main')}>
          <span>{t(i18nKeys.TOTAL_NOT_VAT_PRICE)} :</span>
          <Amount value={totalHtva} suffix={currency} />
        </div>
        <div className={styles('secondary')}>
          <span>{t(i18nKeys.VAT)} :</span>
          <Amount value={totalVat} suffix={currency} />
        </div>
      </div>
      <div className={styles('total', 'bottom')}>
        <div className={styles('main')}>
          <span>{t(i18nKeys.TOTAL_VAT_PRICE)} :</span>
          <Amount value={totalTvac} suffix={currency} />
        </div>
        <div className={styles('secondary', 'spacing')}>
          <span>{t(i18nKeys.INVOICE.DISCOUNT)} :</span>
          <Amount value={totalDiscount} suffix={currency} />
        </div>
        {dueDate && (
          <div className={styles('secondary')}>
            <span>{t(i18nKeys.DUE_DATE)} :</span>
            <DateItem date={dayjs(dueDate).toDate()} lg={currentLang} />
          </div>
        )}
      </div>
    </div>
  );
};
