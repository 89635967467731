import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { clientActions, clientConstants as events } from 'store/client/client.actions';

import { apiCall, sendApi } from '../sagas';

function* ClientAdd(action) {
  const { payload } = action;

  if (payload.data.vat_number && payload.data.vat_number.indexOf('_') !== -1) {
    payload.data.vat_number = '';
  }

  yield apiCall({
    api: api.client.add,
    data: payload.data || payload,
    callback: payload.callback,
    success: 'CLIENT.SUCCESSFULLY_ADD',
    actionRes: clientActions.addRes,
  });
}

function* ClientUpdate({ payload }: any) {
  const { data } = payload;
  if (data.vat_number && data.vat_number.indexOf('_') !== -1) {
    data.vat_number = '';
  }

  yield apiCall({
    api: api.client.update,
    id: data.id,
    data,
    callback: payload.callback,
    success: 'CLIENT.SUCCESSFULLY_UPDATE',
    actionRes: clientActions.updateRes,
  });
}

function* clientSearch({ payload }) {
  const search = payload?.page_limit
    ? {
        name: payload.name,
        page_limit: payload.page_limit || 20,
      }
    : {
        name: payload,
      };

  yield apiCall({
    api: api.client.list,
    data: search,
    noFeedback: true,
    noLoading: true,
    actionRes: clientActions.searchRes,
  });
}

function* clientComment(action) {
  yield apiCall({
    api: api.client.addComment,
    id: action.payload.id,
    data: action.payload,
    callback: () => {
      clientActions.detail({
        id: action.payload.id,
        noReset: true,
        noLoading: true,
      });
      if (action.payload.callback) {
        action.payload.callback();
      }
    },
  });
}

function* ClientWatchers() {
  yield all([
    yield takeLatest(
      events.listPage.request,
      sendApi(api.client.list, clientActions.listPageRes, ({ callback, ...payload } = {}) => ({
        data: { ...payload },
        noLoading: true,
        callback,
      })),
    ),
    yield takeLatest(events.detail.request, sendApi(api.client.detail, clientActions.detailRes)),
    yield takeLatest(
      events.batchAction.request,
      sendApi(api.client.batchAction, clientActions.batchActionRes),
    ),
    yield takeLatest(events.assignToAccManager.request, sendApi(api.client.assignToAccManager)),
    yield takeLatest(events.assignMultipleToUser.request, sendApi(api.client.assignMultipleToUser)),
    yield takeLatest(events.add.request, ClientAdd),
    yield takeLatest(events.update.request, ClientUpdate),
    yield takeLatest(events.delete.request, sendApi(api.client.delete, clientActions.deleteRes)),
    yield takeLatest(events.search.request, clientSearch as any),
    yield takeLatest(events.getFinancialData.request, sendApi(api.client.getFinancialData)),
    yield takeLatest(events.comment.request, clientComment),
    yield takeLatest(events.pauseDebtor.request, sendApi(api.client.pauseDebtor)),
    yield takeLatest(events.deleteMultiple.request, sendApi(api.client.deleteMultiple)),
    yield takeLatest(events.pauseMultiple.request, sendApi(api.client.pauseMultiple)),
    yield takeLatest(
      events.getAllToConfirm.request,
      sendApi(api.client.import.getAllToConfirm, clientActions.getAllToConfirmRes, (payload) => ({
        data: { ...payload },
        noLoading: true,
      })),
    ),
    yield takeLatest(events.getToConfirm.request, sendApi(api.client.import.getToConfirm)),
    yield takeLatest(
      events.deleteClientToConfirm.request,
      sendApi(api.client.import.deleteToConfirm, clientActions.deleteClientToConfirmRes),
    ),
    yield takeLatest(
      events.validateAllImportedClients.request,
      sendApi(api.client.import.validateAll, clientActions.validateAllImportedClientsRes),
    ),
  ]);
}

export default function* saga() {
  yield call(ClientWatchers);
}
