import { z } from 'zod';
/*
 * Source of Truth: Define Enum Values in an Array
 *
 * By defining our enum values in a constant array, we create a single source of truth.
 * This ensures consistency between our runtime validations and our TypeScript types.
 */
export const LOCALES = ['fr', 'en', 'nl'] as const;

/*
 * Native TypeScript union
 *
 * Why use a TypeScript union?
 * - Type Safety: TypeScript unions provide compile-time type checking.
 *   Using them within the application ensures you're working with a valid set of values.
 * - Developer Experience: Unions improve code readability and can be used
 *   in switch statements, benefiting from features like exhaustiveness checking.
 */

/** This is the reference type for representing locales in the app. It should eventually replace the
 * conflicting definitions from orval and "Lang' from translate-state.d.ts"
 */
export type Locale = (typeof LOCALES)[number];

/*
 * Zod Schema
 *
 * Why use a Zod enum?
 * - Runtime Validation: While TypeScript provides compile-time type checks,
 *   it doesn't offer runtime validation. Zod fills this gap by validating data
 *   at runtime, especially useful for data coming from external sources, such as APIs or user input.
 * - Error Reporting: Zod provides detailed error messages that can help diagnose
 *   why a piece of data is invalid.
 */

export const LocaleSchema = z.enum([...LOCALES]);

// Both resulting types are compatible:
//
// const rawInput = 'fr';

// const parsedInput = LocaleSchema.parse(rawInput);

// function acceptsLocale(locale: Locale) {
//   c0nsole.log(locale);
// }

// acceptsLocale(parsedInput);

export const AVAILABLE_LANGUAGES: Array<{ description: string; value: Locale }> = [
  { description: 'Français', value: 'fr' },
  { description: 'English', value: 'en' },
  { description: 'Nederlands', value: 'nl' },
];
