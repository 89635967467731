import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { Id } from '../../api/models';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const WarningsSchema = z.array(
  z.object({
    year: z.number().nullable(),
    descriptions: z.array(z.string()),
  }),
);

const CreditLimitSchema = z.object({
  limit: z.coerce.number(),
  status: z.string().nullable(),
});

const ScoreSchema = z.object({
  value: z.number(),
  image: z.string(),
});

const MonitoringSchema = z
  .object({
    warnings: WarningsSchema,
    score: ScoreSchema,
    warningsUrl: z.string().url().nullable(),
    reportUrl: z.string().url(),
    isMonitoringActive: z.boolean(),
    updatedAt: z.string().nullable(),
    creditLimit: CreditLimitSchema,
  })
  .nullable();

export type Monitoring = z.infer<typeof MonitoringSchema>;

export async function loadMonitoringDataFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get(`/debtors/${queryKey[1]}/monitoring`);
  return MonitoringSchema.parse(data);
}

export const useLoadMonitoringData = (debtorId: Id) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Monitoring, ApiError>({
    queryKey: ['monitoring', debtorId],
    queryFn: (context) => loadMonitoringDataFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<Monitoring, ApiError, 'monitoring'>(
    'monitoring',
    queryResult,
  );
};
