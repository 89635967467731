import dayjs from 'dayjs';
import { atom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { atomEffect } from 'jotai-effect';
import moment from 'moment';
import { Locale } from 'types';

import en from '../source/en.i18n.json';
import fr from '../source/fr.i18n.json';
import nl from '../source/nl.i18n.json';

import { LocaleTree, TranslateFunction, TranslationState } from './types';
import { getPreferredBrowserLanguageOrDefault, getTranslationForKey } from './utils';

export * from './types';

export const currentLanguageAtom = atomWithStorage<Locale>(
  'lastUsedLanguage',
  getPreferredBrowserLanguageOrDefault(),
);

const propagateLanguageChangeEffect = atomEffect((get) => {
  const currentLanguage = get(currentLanguageAtom);
  moment.locale(currentLanguage);
  dayjs.locale(currentLanguage);
});

const translationsObjectAtom = atom<LocaleTree>((get) => {
  get(propagateLanguageChangeEffect);
  const currentLanguage = get(currentLanguageAtom);
  return loadLanguage(currentLanguage);
});

const translateFunctionAtom = atom<TranslateFunction>((get) => {
  const translationsObject = get(translationsObjectAtom);

  return (key, params) => getTranslationForKey(key, params, translationsObject);
});

export const translationStateAtom = atom<TranslationState>((get) => {
  const currentLang = get(currentLanguageAtom);
  const t = get(translateFunctionAtom);

  return { currentLang, t };
});

export function useTranslation() {
  const translationState = useAtomValue(translationStateAtom);

  return translationState;
}

const languages = {
  en,
  fr,
  nl,
};

function loadLanguage(language: Locale): LocaleTree {
  // eslint-disable-next-line global-require, import/no-dynamic-require
  return languages[language] as LocaleTree;
}
