import { useEffect } from 'react';
import { useGetPrivateConstants } from 'api';
import countries from 'i18n-iso-countries';
import { useTranslation } from 'locales';
import { isDefined } from 'remeda';
import { appActions } from 'store/app/app.actions';
import { AVAILABLE_LANGUAGES } from 'types';

import { useProfile } from './use-enforce-profile';

export function useLoadPrivateConstants() {
  const { currentLang } = useTranslation();
  const profile = useProfile();

  const {
    data: constantsRes,
    isLoading: isLoadingPrivateConstants,
    error: privateConstantsError,
  } = useGetPrivateConstants(
    {
      locale: profile?.locale,
    },
    { query: { enabled: isDefined(profile?.locale) } },
  );

  useEffect(() => {
    if (constantsRes != null) {
      (constantsRes as any).available_languages = AVAILABLE_LANGUAGES;
      (constantsRes as any).countries = Object.entries(countries.getNames(currentLang)).map(
        ([value, description]) => ({ value, description }),
      );
      appActions.constantsRes(constantsRes, 'fulfilled', constantsRes);
    }
  }, [constantsRes]);

  return {
    privateConstants: constantsRes,
    isLoadingPrivateConstants,
    privateConstantsError,
  };
}
