import { useEffect, useState } from 'react';
import { useSearchInvoices } from 'api';
import { LightInvoice, SearchInvoicesParams } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { CustomSelect } from 'shared/io';
import { treatInvoice } from 'shared/serializer';

import { LightInvoiceItem } from '../../components';
import { InvoiceFormFieldValues, InvoiceFormSharedProps } from '../../InvoiceForm.types';

import styleIdentifiers from './InvoiceProductLine.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InvoiceProductLineInvoiceProps = InvoiceFormSharedProps & {
  remove: (index?: number | number[] | undefined) => void;
  lineIndex: number;
  selected: number[];
};

export default function InvoiceProductLineInvoice(props: InvoiceProductLineInvoiceProps) {
  const { lineIndex, currency, remove, selected } = props;

  const { t } = useTranslation();

  const { watch, setValue, control, register } = useFormContext<InvoiceFormFieldValues>();

  const [params, setParams] = useState<SearchInvoicesParams>({
    currency,
  });

  const { data: invoicesResponse, refetch } = useSearchInvoices(params);

  const debtor = watch('debtor_attributes');

  register(`product_lines_attributes.${lineIndex}.product_attributes.name`);
  register(`product_lines_attributes.${lineIndex}.product_attributes.current_unity_price_htva`);
  register(`product_lines_attributes.${lineIndex}.total_htva`);
  register(`product_lines_attributes.${lineIndex}.total_tvac`);

  const productLine = watch(`product_lines_attributes.${lineIndex}`);

  useEffect(() => {
    if (Array.isArray(selected)) {
      setParams((currentParams) => ({
        ...currentParams,
        exclude: selected.filter((e) => Number(e) !== Number(productLine.linked_invoice_id)),
      }));
    }
  }, [productLine, selected]);

  useEffect(() => {
    if (debtor) {
      setParams((currentParams) => ({
        ...currentParams,
        debtor_id: debtor.id,
      }));
    }
  }, [debtor]);

  const onSelectProduct = ({ item }) => {
    setValue(`product_lines_attributes.${lineIndex}.product_attributes.name`, item.reference);
    setValue(
      `product_lines_attributes.${lineIndex}.product_attributes.current_unity_price_htva`,
      -item.total_htva,
    );
    setValue(`product_lines_attributes.${lineIndex}.total_htva`, -item.total_htva);
    setValue(`product_lines_attributes.${lineIndex}.total_tvac`, -item.total_tvac);
  };

  const removeProduct = () => {
    remove(lineIndex);
  };

  const concatedInvoices = (invoicesResponse?.data || []).map((e) =>
    // TODO: Change this ... (Required for customSelect)
    treatInvoice<LightInvoice>(e),
  );

  return (
    <tr className={styles('invoice-product-line')}>
      <td>
        <Controller
          control={control}
          rules={{ required: true }}
          name={`product_lines_attributes.${lineIndex}.linked_invoice_id`}
          render={() => (
            <CustomSelect
              load={() => refetch()}
              keyValue="id"
              keyText="reference"
              size="small"
              className={styles('invoice-select-input-wrapper')}
              customInputClassname={styles('invoice-select-input')}
              selectClassName={styles('invoice-select')}
              valueClassName={styles('value')}
              errorClassName={styles('invoice-select-error')}
              itemRendering={(data) => (
                <LightInvoiceItem
                  {...data}
                  {...pick(props, ['isCreditNote', 'noTva', 'currency', 'company', 'constants'])}
                />
              )}
              items={concatedInvoices}
              name={`product_lines_attributes.${lineIndex}.linked_invoice_id`}
              placeholder={t(i18nKeys.INVOICING.CREATE.CHOOSE_PRODUCT)}
              onSelectItem={onSelectProduct}
              noMargin
              noArrow
              noBorder
            />
          )}
        />
      </td>
      <td />
      <td>
        <Amount
          value={watch(`product_lines_attributes.${lineIndex}.total_htva`)}
          suffix={currency}
        />
      </td>
      <td />
      <td className={styles('amount')}>
        <div className={styles('htva-price')}>
          <Amount
            value={watch(`product_lines_attributes.${lineIndex}.total_htva`)}
            suffix={currency}
          />
        </div>
      </td>
      <td />
      <td className={styles('amount')}>
        <div className={styles('vat-total')}>
          <Amount
            value={watch(`product_lines_attributes.${lineIndex}.total_tvac`)}
            suffix={currency}
          />
          <div className={styles('remove')} onClick={removeProduct}>
            <Icon name={IconName.TRASH_SIMPLE} size="16px" />
          </div>
        </div>
      </td>
    </tr>
  );
}
