import { i18nKeys, useTranslation } from 'locales';
import { LoadDebtorColumnVariables } from 'modules/Kanban/api/use-load-debtor-column';
import { useLoadLightUsers } from 'shared/hooks';

import { Group, NumberInput, Select, Stack, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface DebtorKanbanFiltersProps {
  form: UseFormReturnType<LoadDebtorColumnVariables>;
}

export const DebtorKanbanFilters = ({ form }: DebtorKanbanFiltersProps) => {
  const { t } = useTranslation();
  const { users = [] } = useLoadLightUsers(false);

  const accountManagersForSelect = users.map((user) => ({
    value: String(user.id),
    label: user.name.full,
  }));

  return (
    <Stack gap="lg">
      <Select
        autoFocus
        label={t(i18nKeys.ACCOUNT_MANAGER)}
        placeholder={t(i18nKeys.DASHBOARD.SELECT_ACCOUNT_MANAGER)}
        data={accountManagersForSelect}
        {...form.getInputProps('accountManagerId')}
      />
      <TextInput
        label={t(i18nKeys.KANBAN.FILTERS.CLIENT_NAME)}
        placeholder={t(i18nKeys.KANBAN.FILTERS.CLIENT_NAME_PH)}
        {...form.getInputProps('fullName')}
      />
      <Group>
        <NumberInput
          label={t(i18nKeys.KANBAN.FILTERS.MIN_BALANCE)}
          placeholder="e.g. 100"
          flex={1}
          {...form.getInputProps('minSaldo')}
        />
        <NumberInput
          label={t(i18nKeys.KANBAN.FILTERS.MAX_BALANCE)}
          placeholder="e.g. 5000"
          flex={1}
          {...form.getInputProps('maxSaldo')}
        />
      </Group>
    </Stack>
  );
};
