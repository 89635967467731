export enum IconName {
  MINUS = 'minus',
  SYNC = 'sync',
  PONTO = 'ponto',
  BANK = 'bank',
  DOMICILIATION = 'domiciliation',
  EXACT = 'exact',
  CODABOX = 'codabox',
  PENCIL = 'pencil',
  HORUS = 'horus',
  FILTER = 'filter',
  CSV = 'csv',
  XML = 'xml',
  PDF = 'pdf',
  QUESTION = 'question',
  AT = 'at',
  UNLINK = 'unlink',
  TEAMLEADER = 'teamleader',
  YUKI = 'yuki',
  INTEGRATION = 'integration',
  USER = 'user',
  GEARS = 'gears',
  PACKAGE = 'package',
  INVOICE = 'invoice',
  COMPANY = 'company',
  ADDITIONAL_DOCUMENT = 'additional-document',
  PAYMENT_BOOK = 'payment-book',
  RECONCILIATION = 'reconciliation',
  ADD_PAYMENT = 'add-payment',
  FORMAL_NOTICE = 'formal-notice',
  COMMENT = 'comment',
  SENDING_OPTIONS = 'sending-options',
  BALANCE = 'balance',
  IMPORT_FILE = 'import-file',
  CREDIT_NOTE = 'credit-note',
  FILE = 'file',
  EXTERNAL_LINK = 'external-link',
  RECOVERY_PLAN = 'recovery-plan',
  POINTS = 'points',
  CUSTOMIZATION = 'customization',
  STAR_EMPTY = 'star-empty',
  STAR_FULL = 'star-full',
  LATER = 'later',
  QR_CODE = 'qr-code',
  MAG = 'mag',
  MOVE_VERTICAL = 'move-vertical',
  BILLIT = 'billit',
  CREDIT_CARD = 'credit-card',
  SPINNER = 'spinner',
  HAMBURGER = 'hamburger',
  DOWNLOAD = 'download',
  EYE = 'eye',
  ARROW_BOTTOM_ROUNDED = 'arrow-bottom-rounded',
  WALLET = 'wallet',
  TRASH_SIMPLE = 'trash-simple',
  TAILDOWN = 'taildown',
  SMALL_REMOVE = 'small-remove',
  SIMPLE_REMOVE = 'simple-remove',
  SETTINGS_GEAR = 'settings-gear',
  SERVER_RACK = 'server-rack',
  SEND = 'send',
  ROBOT = 'robot',
  REPLACE = 'replace',
  PLUS = 'plus',
  PHONE_CALL = 'phone-call',
  INFO = 'info',
  CHECK = 'check',
  PLAY = 'play',
  MONEY = 'money',
  PAUSE = 'pause',
  MINIMAL_DOWN = 'minimal-down',
  LOGOUT = 'logout',
  LAW = 'law',
  DASHBOARD_HALF = 'dashboard-half',
  CLOUD_DOWNLOAD = 'cloud-download',
  CLIENT = 'client',
  CIRCLE_DOWN = 'circle-down',
  CHART_BAR = 'chart-bar',
  BOX_RIBBON = 'box-ribbon',
  BELL = 'bell',
  ALERT_CIRCLE = 'alert-circle',
  OPENING_TIMES = 'opening-times',
  LINE_CHART = 'line-chart',
  COPY = 'copy',
  CURRENCY = 'currency',
  // Custom CSS
  MINIMAL_UP = 'minimal-up',
  MINIMAL_RIGHT = 'minimal-right',
  MINIMAL_LEFT = 'minimal-left',
  CIRCLE_UP = 'circle-up',
  CIRCLE_RIGHT = 'circle-right',
  CIRCLE_LEFT = 'circle-left',
}

export enum IconColor {
  BLUE = 'blue',
  DARK = 'dark',
  DARK_GREEN = 'dark-green',
  GREEN = 'green',
  GREY = 'grey',
  RED = 'red',
  WARN = 'warn',
  WHITE = 'white',
  ORANGE = 'orange',
}
