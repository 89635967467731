import { ReactNode, Ref, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import { FieldError } from 'react-hook-form';
import { usePrevious } from 'shared/utils/hooks';
import { validationMessage } from 'shared/utils/validation';
import { OnChangeEvent } from 'types/html-event';

import styleIdentifiers from './MaskInput.module.scss';

const styles = classNames.bind(styleIdentifiers);

type MaskInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: ReactNode;
  className?: string;
  inputClassName?: string;
  onFocus?: Function;
  onChange?: Function;
  noMargin?: boolean;
  withBorder?: boolean;
  noError?: boolean;
  errorClassName?: string;
  noBorder?: boolean;
  shadow?: boolean;
  noErrorText?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  error?: FieldError | string;
  name: string;
  length?: number;
  guide?: boolean;
  numberOnly?: boolean;
  mask?: string;
};

// TO DO cursor at the good place when user write bad character
export const MaskInput = ({
  onFocus,
  label,
  withBorder,
  noMargin,
  className,
  noBorder,
  shadow,
  noErrorText,
  noError,
  onChange,
  error,
  errorClassName,
  inputClassName,
  length,
  guide,
  prefix,
  numberOnly,
  mask,
  ...rest
}: MaskInputProps) => {
  const { t } = useTranslation();

  const replaceAt = function (text, index, replacement) {
    return text.substr(0, index) + replacement + text.substr(index + replacement.length);
  };

  const removePrefix = (value) =>
    prefix && value?.slice(0, prefix.length) === prefix ? value.slice(prefix.length) : value;

  const addMask: Function = (value) => {
    if (!mask) return value;
    if (!value) return mask;

    let inputVal = mask;
    let valuePos = 0;

    for (let i = 0; i < mask!.length; i++) {
      if (value[valuePos] === undefined) break;
      if (mask![i] === '_') {
        inputVal = replaceAt(inputVal, i, value[valuePos]);
        valuePos++;
      } else if (mask[i] === value[i]) valuePos++;
    }
    return inputVal;
  };

  const initData = (value) => addMask(removePrefix(value));
  const [val, _setVal] = useState<string>(initData(rest.value));
  const ref = useRef<any>(null);

  const setValue = (value: string) => {
    const inputVal = addGuide(value);
    _setVal(inputVal as any);
    ref.current!.value = inputVal;
    onChange!(((prefix || '') + value) as any);
  };
  const previousMask = usePrevious(mask);
  useEffect(() => {
    if (previousMask !== null) {
      setValue(initData(''));
    }
  }, [mask]);

  useEffect(() => {
    if (guide && ref.current) {
      setValue(addGuide(val));
    }
  }, [ref.current]);

  useEffect(() => {
    if (rest.value !== undefined && rest.value !== val) {
      changeValue(rest.value as any, 0);
    }
  }, [rest.value]);

  const addGuide = (text) => {
    if (guide) {
      let value = text || '';

      for (let i = value.length; value.length < length!; i++) {
        value = `${value}_`;
      }
      return value;
    }
    return text;
  };

  const handleInputFocus = (event: OnChangeEvent) => {
    onFocus?.(event);
  };

  const showError = !noError && error;

  const changeValue = (value, cursorPosition) => {
    if (mask) {
      let maskPosition = 0;
      let valuePosition = 0;
      let newValue = '';
      const textToAdd = value.replace(/\_/g, '');
      while (!(textToAdd[valuePosition] === undefined || maskPosition > mask.length - 1)) {
        if (mask[maskPosition] === '_') {
          if (numberOnly && isNaN(Number(textToAdd[valuePosition]))) {
            valuePosition++;
          } else {
            newValue += textToAdd[valuePosition];
            valuePosition++;
            maskPosition++;
          }
        } else {
          newValue += mask[maskPosition];
          maskPosition++;
        }
      }
      newValue += mask.substring(maskPosition);
      setValue(newValue);

      const findFirstDiff = (str1, str2) => [...str1].findIndex((el, index) => el !== str2[index]);

      if (value.length < mask.length) {
        ref.current.setSelectionRange(cursorPosition, cursorPosition);
      } else {
        const firstDiff = findFirstDiff(val, newValue);
        const newPosition =
          firstDiff === -1 ? cursorPosition : firstDiff + value.length - mask.length;

        ref.current.setSelectionRange(newPosition, newPosition);
      }
    } else {
      value = value.replace(/_/g, '') as string;
      if (numberOnly && isNaN(Number(value))) {
        setValue(val!);
        ref.current.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
      } else {
        if (length && length < value.length) {
          value = value.slice(0, cursorPosition) + value.slice(cursorPosition + 1);
          value = value.slice(0, length);
        }
        setValue(value);
        ref.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }
  };

  const handleChange = (e) => {
    changeValue((e.target.value || '').trim(), e.target.selectionStart);
  };

  return (
    <div
      className={styles(
        'MaskInput',
        noMargin && 'no-margin',
        className,
        shadow && 'shadow',
        withBorder && 'with-border',
        showError && 'error',
      )}
    >
      <div
        className={styles(
          'input-wrapper',
          !label && 'no-label',
          showError && 'error',
          showError && errorClassName,
        )}
      >
        {label && <div className={styles('label-input')}>{label}</div>}
        <div className={styles('input-container')}>
          <span className={styles('input', !noBorder && 'bordered')}>{prefix}</span>
          <input
            className={styles(inputClassName, !noBorder && 'bordered')}
            onChange={handleChange}
            onFocus={handleInputFocus}
            ref={ref}
            {...rest}
            value={val || ''}
          />
        </div>
      </div>
      {showError && !noErrorText && (
        <div className={styles('error-message')}>
          {t(
            validationMessage[(error as FieldError).type] || (error as FieldError).message || error,
          )}
        </div>
      )}
    </div>
  );
};
