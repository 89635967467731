import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { DateSelector } from 'shared/io';

import { InvoiceFormFieldValues, InvoiceFormSharedProps } from '../InvoiceForm.types';
import { getDefaultIssueDate } from '../InvoiceForm.utils';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type IssueDateFieldProps = InvoiceFormSharedProps;

export const IssueDateField = ({}: IssueDateFieldProps) => {
  const { setValue } = useFormContext<InvoiceFormFieldValues>();

  const defaultDate = getDefaultIssueDate();

  return (
    <Controller
      name="issue_date"
      rules={{ required: true }}
      defaultValue={defaultDate.toDate()}
      render={() => (
        <DateSelector
          name="issue_date"
          placeholder={defaultDate.format('DD/MM/YYYY')}
          className={styles('date-selector')}
          inputClassName={styles('value')}
          errorClassName={styles('error')}
          noErrorText
          noMargin
          noBorder
          noMinDate
          handleChange={(value: string) => {
            setValue('issue_date', value);
          }}
        />
      )}
    />
  );
};
