import { PaymentSteps, PaymentStepsAttributes } from 'api/models';
import dayjs from 'dayjs';
import { formattedDate } from 'shared/utils/view';
import { Locale } from 'types';

import { PaymentPlanStatus } from './PaymentPlan.constants';

export const computeStatus = (cancelled: boolean, completed: boolean) => {
  if (cancelled) {
    return PaymentPlanStatus.CANCELLED;
  }

  if (completed) {
    return PaymentPlanStatus.COMPLETED;
  }

  return PaymentPlanStatus.IN_PROGRESS;
};

export const computeNextStepDate = (
  status: PaymentPlanStatus,
  lang: Locale,
  step?: PaymentSteps,
) => {
  if ([PaymentPlanStatus.CANCELLED, PaymentPlanStatus.COMPLETED].includes(status) || !step) {
    return '-';
  }

  return formattedDate(dayjs(step.attributes.due_date).toDate(), lang);
};

export const adjustNextDates = (
  values: PaymentStepsAttributes[],
  index: number,
  newDelay: number | string,
) => {
  let dueDate = dayjs(values[index].due_date);

  return values.map((el, i) => {
    // eslint-disable-next-line
    if (i <= index || el['_destroy']) {
      return el;
    }

    dueDate = dueDate.add(Number(newDelay), 'day');

    return {
      ...el,
      due_date: dueDate.format('YYYY-MM-DD'),
    };
  });
};

export const adjustNextAmounts = (
  values: PaymentStepsAttributes[],
  index: number,
  total: number,
) => {
  const value = values[index];

  const previousStepsTotal = values.reduce(
    (acc, el, i) =>
      // eslint-disable-next-line
      i <= index && !el['_destroy'] ? acc + Number(el.amount) : acc,
    0,
  );

  let remaining = Math.round((total - previousStepsTotal) * 100) / 100;

  return values
    .map((el, i, arr) => {
      // eslint-disable-next-line
      if (i <= index || el['_destroy']) {
        return el;
      }

      if (remaining <= 0) {
        return el.id
          ? {
              ...el,
              _destroy: true,
            }
          : undefined;
      }

      const amount =
        arr.length - 1 === i && remaining > 0
          ? Math.round(remaining * 100) / 100
          : Math.round(Math.min(remaining, value.amount) * 100) / 100;

      remaining -= amount;

      return {
        ...el,
        amount,
      };
    })
    .filter((e) => e);
};
