import { AxiosError, AxiosInstance } from 'axios';
import { entries, filter, map, omit, pipe } from 'remeda';
import { Id } from 'types';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult, ApiResponse, useAxiosInstance } from './utils';

function getRolesFromPermissions(permissions?: Record<string, boolean>) {
  if (permissions == null) return [];
  if (permissions.admin) return ['admin'];

  return pipe(
    permissions,
    entries(),
    filter(([, val]) => val === true),
    map(([role]) => role),
  );
}

export const UpdateUserFormSchema = z
  .object({
    isActive: z.boolean(),
    permissions: z.record(z.boolean()),
    preferences: z.object({
      emails: z.object({
        daily: z.boolean(),
        weekly: z.boolean(),
        notifications: z.boolean(),
      }),
    }),
  })
  .partial();

export const UpdateUserSchema = UpdateUserFormSchema.transform((data) => ({
  ...omit(data, ['preferences', 'permissions']),
  roles: getRolesFromPermissions(data.permissions),
  receiveDailyEmails: data.preferences?.emails.daily,
  receiveWeeklyEmails: data.preferences?.emails.weekly,
  receiveNotificationEmails: data.preferences?.emails.notifications,
}));

export type UpdateUserForm = z.infer<typeof UpdateUserFormSchema>;

export async function updateUserFn(
  axiosInstance: Promise<AxiosInstance>,
  { id, data }: { id: Id; data: UpdateUserForm },
) {
  const instance = await axiosInstance;
  return instance.put(`/users/${id}`, UpdateUserSchema.parse(data));
}

export function useUpdateUser() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<
    ApiResponse,
    AxiosError<{ error_message: Array<string> }>,
    { id: Id; data: UpdateUserForm }
  >({
    mutationFn: (variables) => updateUserFn(axiosInstance, variables),
    onSuccess: () => {
      notifications.show({
        title: 'User updated',
        message: "This user's settings have been updated.",
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['users'],
      });
    },
    onError: (error) => {
      error.response?.data.error_message.forEach((message: string) => {
        notifications.show({
          title: 'Error',
          color: 'red',
          message,
        });
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    AxiosError<{ error_message: Array<string> }>,
    { id: Id; data: UpdateUserForm },
    'updateUser'
  >('updateUser', mutation);
}
