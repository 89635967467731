import { useEffect, useState } from 'react';
import { useTenant } from 'app/TenantValidator';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Redirect } from 'react-router';
import Card from 'shared/components/Card';
import GradientBorder from 'shared/components/GradientBorder';
import { Tenant, useEnforceProfile } from 'shared/hooks';
import { unimplementedWith } from 'shared/utils';

import { useQueryClient } from '@tanstack/react-query';

import GeneralInfo from './GeneralInfo/GeneralInfo';
import BankConnection from './BankConnection';
import ColorLogo from './ColorLogo';
import Integrations from './Integrations';
import PaymentMethod from './PaymentMethod';
import SetupDebtCollection from './SetupDebtCollection';

import styleIdentifiers from './onBoarding.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const OnBoarding = () => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const queryClient = useQueryClient();
  useEnforceProfile();

  const [step, setStep] = useState<Tenant['onboarding']['currentStep']>(
    tenant.onboarding.currentStep,
  );

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['tenant'] });
  }, [step, queryClient]);

  if (tenant.onboarding.isComplete) return <Redirect to="/" />;

  return (
    <div className={styles('on-boarding', 'wrapper')}>
      <div>
        <Card title={t(i18nKeys.ONBOARDING.TITLE)}>
          {step !== 7 && (
            <div className={styles('header-card')}>
              <div className={styles('step-name')}>
                {`${t(i18nKeys.ONBOARDING.STEP)} ${step} :${' '}`}
                {t(i18nKeys.ONBOARDING[step!].TITLE)}
              </div>
              <div className={styles('progress-bar')}>
                <GradientBorder className={styles('progress')} />
                <div
                  className={styles('anti-progress')}
                  style={{
                    left: `${step! * (100 / 7)}%`,
                  }}
                />
              </div>
            </div>
          )}
        </Card>
        {step === 1 && <GeneralInfo onValidStep={() => setStep(2)} />}
        {step === 2 && <PaymentMethod onValidStep={() => setStep(3)} />}
        {step === 3 && (
          <BankConnection
            onValidStep={() => setStep(4)}
            disableIntegration={unimplementedWith(false)}
          />
        )}
        {step === 4 && (
          <SetupDebtCollection
            onValidStep={() => {
              setStep(5);
            }}
          />
        )}
        {step === 5 && <ColorLogo onValidStep={() => setStep(6)} />}
        {step === 6 && <Integrations onValidStep={() => setStep(7)} />}
      </div>
    </div>
  );
};
