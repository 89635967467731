import { i18nKeys, useTranslation } from 'locales';
import { InvoiceFooterField, useLoadInvoiceFooter } from 'shared/hooks';

import { Stack, Text } from '@mantine/core';
import { styled } from '@stitches/react';

const Item = styled('div', {
  height: '55px',
  width: '100%',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease',
  border: 'solid 2px #ADB5BD',

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#f5f5f5',
  },

  variants: {
    label: {
      true: {
        justifyContent: 'start',
        paddingLeft: '20px',
        paddingTop: '3px',
      },
    },
    empty: {
      true: {
        border: 'dashed 3px #DEE2E6',
      },
    },
  },
});

const Placeholder = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <Item empty onClick={onClick}>
      <Text fw={500} c="#CED4DA" mt={0}>
        {t(i18nKeys.SETTINGS.INVOICING.FOOTER.CLICK_TO_EDIT)}
      </Text>
    </Item>
  );
};

interface InvoiceItemProps {
  item: InvoiceFooterField;
  onClickEdit: () => void;
}

export const FooterItem = ({ item, onClickEdit }: InvoiceItemProps) => {
  const { invoiceFooter } = useLoadInvoiceFooter();

  if (invoiceFooter == null) return null;

  if (item == null) {
    return <Placeholder onClick={onClickEdit} />;
  }

  if (typeof item === 'string') {
    return (
      <Item onClick={onClickEdit}>
        <Text mt={0}>{item}</Text>
      </Item>
    );
  }

  const displayName = invoiceFooter.availableFields.find((el) => el.field === item.field)?.name;

  return (
    <Item label onClick={onClickEdit}>
      <Stack justify="center" gap={0}>
        <Text size="xs" c="gray.6">
          {displayName}
        </Text>
        <Text>{item.content}</Text>
      </Stack>
    </Item>
  );
};
