import { useGetDomain, useUpdateDomain } from 'api';
import { DnsRecord, DomainResponseResponse, Id, ObjectType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import CopyToClipboard from 'shared/components/CopyToClipboard';
import { Icon, IconColor, IconName } from 'shared/components/Icon';

import { Button, Group, LoadingOverlay } from '@mantine/core';

import styleIdentifiers from '../PreferencesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

const PLACEHOLDER_RECORD: DnsRecord = {
  data: 'data',
  host: 'host',
  type: 'type',
  valid: false,
};

const PLACEHOLDER_DATA: DomainResponseResponse = {
  data: {
    id: '1',
    type: 'email_domain' as ObjectType,
    attributes: {
      domain: 'domain.com',
      domain_valid: false,
      dns_records: {
        mail_cname: PLACEHOLDER_RECORD,
        dkim1: PLACEHOLDER_RECORD,
        dkim2: PLACEHOLDER_RECORD,
      },
    },
  },
};

type DnsSettingsProps = {
  domainId: Id;
};

export const DnsSettings = ({ domainId }: DnsSettingsProps) => {
  const { t } = useTranslation();
  const { data: domainResponse, isError, isLoading, refetch } = useGetDomain(domainId);

  const { mutate: updateDomain, isPending: isUpdatingDomain } = useUpdateDomain();

  if (isError) {
    return <span>Error</span>;
  }

  const { data: domainData } = domainResponse ?? PLACEHOLDER_DATA;

  const {
    attributes: { domain, domain_valid: isDomainValid, dns_records },
  } = domainData;

  const getIconName = (isValid: boolean) => {
    if (isUpdatingDomain) return 'SYNC';
    return isValid ? 'CHECK' : 'SIMPLE_REMOVE';
  };

  const getIconColor = (isValid: boolean) => {
    if (isUpdatingDomain) return 'BLUE';
    return isValid ? 'GREEN' : 'RED';
  };

  const renderRecord = (record: DnsRecord) => {
    const { data, host, type, valid: isRecordValid } = record;
    return (
      <div className={styles('dns-param')}>
        <h4>
          {type.toUpperCase()}{' '}
          <Icon
            name={IconName[getIconName(isRecordValid)]}
            color={IconColor[getIconColor(isRecordValid)]}
            rotate={isUpdatingDomain}
          />
        </h4>
        <div className={styles('key-values')}>
          <div className={styles('key')}>
            <p>Nom</p>
          </div>
          <CopyToClipboard textToCopy={host} text={host} custom />
        </div>
        <div className={styles('key-values')}>
          <div className={styles('key')}>
            <p>Valeur</p>
          </div>
          <CopyToClipboard textToCopy={data} text={data} custom />
        </div>
      </div>
    );
  };

  const verifyDomain = () => {
    updateDomain(
      { id: domainId },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  return (
    <div className={styles('dns-settings')}>
      <LoadingOverlay visible={isLoading} />
      <p>{t(i18nKeys.CUSTOM_DOMAIN.DNS_INFO)}</p>
      <div className={styles('domain')}>
        <p>{domain}</p>
        <Icon
          name={IconName[getIconName(isDomainValid)]}
          color={IconColor[getIconColor(isDomainValid)]}
          rotate={isUpdatingDomain}
        />
      </div>
      <p className={styles('validate')}>{t(i18nKeys.CUSTOM_DOMAIN.VALIDATE_DOMAIN)}</p>
      {Object.values(dns_records).map((record) => renderRecord(record))}
      <Group justify="end">
        <Button size="md" loading={isUpdatingDomain} onClick={verifyDomain}>
          {t(i18nKeys.CUSTOM_DOMAIN.VERIFY)}
        </Button>
      </Group>
    </div>
  );
};
