import { useEffect, useState } from 'react';
import { ORVAL_AXIOS_INSTANCE } from 'api/mutator/custom-instance';
import { useHistory } from 'react-router';
import { isDefined, isNullish } from 'remeda';
import { REDUX_AXIOS_INSTANCE } from 'shared/utils/api';
import { config } from 'store/constants';

import { useAuth0 } from '@auth0/auth0-react';
import { captureMessage } from '@sentry/react';

export const AUTH0_SCOPE = 'openid profile email offline_access';

export const useAuthenticate = () => {
  const history = useHistory();
  const {
    isAuthenticated,
    getAccessTokenSilently,
    error: auth0Error,
    isLoading: isAuth0Loading,
  } = useAuth0();
  const [auth0AccessToken, setAuth0AccessToken] = useState<string | null>(null);
  const [haveInterceptorsBeenSet, setHaveInterceptorsBeenSet] = useState(false);

  // MARK: Get token
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: config.auth0.audience,
            scope: AUTH0_SCOPE,
          },
        });
        setAuth0AccessToken(token);
      } catch (e) {
        history.push('/logout');
      }
    };
    if (isAuthenticated && isNullish(auth0AccessToken)) getToken();
  });

  // MARK: Logout if error
  useEffect(() => {
    if (isDefined(auth0Error)) {
      console.error(auth0Error);
      captureMessage('Auth0 error', { extra: { auth0Error } });
      history.push('/logout');
    }
  }, [auth0Error]);

  // MARK: Set interceptors
  useEffect(() => {
    if (isNullish(auth0AccessToken)) return () => {};

    const orvalInterceptor = ORVAL_AXIOS_INSTANCE.interceptors.request.use(async (_config) => {
      _config.headers = { ..._config.headers, Authorization: `Bearer ${auth0AccessToken}` };
      return _config;
    });

    const reduxInterceptor = REDUX_AXIOS_INSTANCE.interceptors.request.use(async (_config) => {
      _config.headers = { ..._config.headers, Authorization: `Bearer ${auth0AccessToken}` };
      return _config;
    });

    setHaveInterceptorsBeenSet(true);

    return () => {
      ORVAL_AXIOS_INSTANCE.interceptors.request.eject(orvalInterceptor);
      REDUX_AXIOS_INSTANCE.interceptors.request.eject(reduxInterceptor);
    };
  }, [auth0AccessToken]);

  return {
    isAuth0Loading,
    isAuthenticated,
    canProceedIntoAuthenticatedDomain:
      isAuthenticated && !isNullish(auth0AccessToken) && haveInterceptorsBeenSet,
    auth0AccessToken,
    auth0Error,
  };
};
