import { useEffect } from 'react';
import { MantineSettingsCard } from 'app/Private/Settings/MantineSettingsCard';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import NavigationPrompt from 'shared/components/BlockNavigation';
// import { Button } from 'shared/io';
import { Company } from 'types/store/account-state';

import { Box } from '@mantine/core';

import { CustomPreferencesPartialForm } from './CustomPreferencesPartialForm/CustomPreferencesPartialForm';
import { GeneralConditionsPartialSendTos } from './GeneralConditionsPartialForm/GeneralConditionsPartialSendTos';
import { PaymentPlanPartialForm } from './PaymentPlanPartialForm/PaymentPlanPartialForm';
import { PostalPreferencesPartialForm } from './PostalPreferencesPartialForm/PostalPreferencesPartialForm';
import { SalePreferencesPartialForm } from './SalePreferencesPartialForm/SalePreferencesPartialForm';
import { CompanyEdit } from './PreferencesForm.constants';

import styleIdentifiers from './PreferencesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  company: Company;
  onSubmit: SubmitHandler<CompanyEdit>;
};

export const PreferencesForm = ({ company, onSubmit }: Props) => {
  const { t } = useTranslation();

  const form = useForm<CompanyEdit>({
    defaultValues: company,
  });

  const {
    formState: { dirtyFields },
    reset,
    handleSubmit,
  } = form;

  useEffect(() => {
    reset({
      ...company,
    });
  }, [company, reset]);

  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <FormProvider {...form}>
      <MantineSettingsCard
        title={t(i18nKeys.SALE_CONDITION)}
        onSave={isDirty ? handleSubmit(onSubmit) : undefined}
      >
        <Box mb="xl" mx="sm">
          <NavigationPrompt when={isDirty} onSaveAndQuit={handleSubmit(onSubmit)} />
          <form className={styles('PreferencesForm')} onSubmit={handleSubmit(onSubmit)}>
            <SalePreferencesPartialForm />
            <GeneralConditionsPartialSendTos />
            <h3 className={styles('margin')}>{t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)}</h3>
            <PaymentPlanPartialForm />
            <h3 className={styles('margin')}>{t(i18nKeys.FORM.PREFERENCES.PREFERENCES_SEND)}</h3>
            <h4 className={styles('margin')}>{t(i18nKeys.EMAIL)}</h4>
            <CustomPreferencesPartialForm />
            <h4 className={styles('margin')}>
              {t(i18nKeys.FORM.PREFERENCES.PREFERENCES_POSTAL_SEND)}
            </h4>
            <PostalPreferencesPartialForm />
          </form>
        </Box>
      </MantineSettingsCard>
    </FormProvider>
  );
};
