import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Button, ButtonColor } from 'shared/io';

import styleIdentifiers from './cropper.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  src: string;
  aspectRatio: number;
  action: Function;
  options: Object;
};

export default function Cropper({ src, aspectRatio, action, options }: Props) {
  let cropper: any;

  const { t } = useTranslation();
  useEffect(() => {
    if (cropper) {
      cropper.replace(src);
      cropper.setAspectRatio(aspectRatio);
    }
  }, [src, aspectRatio]);

  const setRef = (image) => {
    if (!image) {
      return;
    }

    image = image;
    if (!cropper) {
      createCropper();
    }
  };

  const saveCrop = () => {
    const canvas = cropper.getCroppedCanvas();
    if (!canvas) {
      return false;
    }

    if (typeof action === 'function') {
      action(canvas.toDataURL());
    }
  };

  const createCropper = () => {
    let newOptions: any = options;
    newOptions = newOptions || {};
    newOptions.aspectRatio = aspectRatio;

    newOptions.ready = () => {};
    newOptions.crop = (event) => {};
  };

  return (
    <div className={styles('Cropper')}>
      <div className={styles('img')}>
        <img ref={setRef} src={src} alt="" />
      </div>
      <div className={styles('actions')}>
        <Button
          className={styles('no-margin')}
          color={ButtonColor.RED}
          label={t(i18nKeys.SAVE)}
          onClick={saveCrop}
        />
      </div>
    </div>
  );
}
