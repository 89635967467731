import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

interface LogoutProps {}

export const Logout = ({}: LogoutProps) => {
  const { logout } = useAuth0();

  React.useEffect(() => {
    logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
  }, [logout]);

  return null;
};
