import { useEffect } from 'react';
import { useTranslation } from 'locales';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import Modals from 'shared/components/Modals';
import SideMenu from 'shared/components/SideMenu';
import { useAuthenticate } from 'shared/hooks';
import { getEnvIcon } from 'shared/utils/environment';
import { useGetCompany } from 'shared/utils/selectors';

import { OnboardingWrapper } from './Private/OnboardingWrapper';
import { PrivateLoader } from './Private/PrivateLoader';
import { PrivateRoutesWrapper } from './Private/PrivateRoutesWrapper';
import DebtorReaction from './Public/DebtorReaction';
import { Login } from './Public/Login';
import { Logout } from './Public/Logout';
import { PrivateRoutes } from './Private';

export const App = () => {
  useTranslation(); // Removing this call causes the translation system to error out. Whatever the reason is, it's wrong.
  const history = useHistory();
  const location = useLocation();
  const company = useGetCompany();
  const { canProceedIntoAuthenticatedDomain, isAuth0Loading, isAuthenticated } = useAuthenticate();

  useEffect(() => {
    document.title = `${getEnvIcon()}${company?.name ?? 'Recovr'} `;
  }, [company?.name]);

  useEffect(() => {
    //? The debtor reaction portal is a public access page that should have been another app
    if (location.pathname.includes('debtor-reaction')) return;
    //? This does not cause an infinite loop because /login immediately performs a redirect to auth0
    if (!isAuth0Loading && !isAuthenticated) history.push('/login');
  }, [isAuth0Loading, isAuthenticated, history]);

  return (
    <>
      <Modals />
      <SideMenu />
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/login" component={Login} />
        {/* I belong in a separate application */}
        <Route exact path="/invoices/:id/debtor-reaction" component={DebtorReaction} />

        {canProceedIntoAuthenticatedDomain && (
          <PrivateLoader>
            <Switch>
              <Route path="/onboarding" component={OnboardingWrapper} />
              <Route path="/">
                <PrivateRoutesWrapper>
                  <PrivateRoutes />
                </PrivateRoutesWrapper>
              </Route>
            </Switch>
          </PrivateLoader>
        )}
      </Switch>
    </>
  );
};
