import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import EmailRendering from 'shared/components/EmailRendering/EmailRendering';
import { CustomSelect } from 'shared/io';
import { email } from 'shared/utils/validation';

type SendInvoiceFormEmailProps = {
  emails: Array<{
    value: string;
    description: string;
  }>;
};

export default function SendInvoiceFormEmail({ emails }: SendInvoiceFormEmailProps) {
  const { t } = useTranslation();

  const { register } = useFormContext();

  return (
    <Controller
      name="emails"
      rules={{ required: true }}
      render={() => (
        <CustomSelect
          name="emails"
          label={t(i18nKeys.EMAILS)}
          items={emails}
          inputValidation={email}
          keyValue="value"
          registerMultipleInput={register('emailsinput', {
            validate: {
              emailInputNotEmpty: (value) => !value,
            },
          })}
          info={t(i18nKeys.FORM.CONFIRM_EMAIL)}
          itemRendering={({ item }) => <EmailRendering data={item} />}
          multiple
          canAdd
        />
      )}
    />
  );
}
