import { treatClient } from 'shared/serializer';
import { clientActions } from 'store/client/client.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import ClientForm from './ClientForm';

type Props = {
  client?: Partial<Debtor>;
  callback?: Function;
  sideMenuProps?: any;
};

export default function EditClient({ client, callback, sideMenuProps }: Props) {
  let debtor = client;

  const submitClient = (values: Debtor) => {
    const action = ({ data }) => {
      callback?.(treatClient(data));
      sideMenuHide();
    };

    values.emails = values.emails?.map((value) => (value as any).email) || [];
    values.emails.unshift(values.emails.splice((values as any).mainEmailIndex, 1)[0]);
    delete (values as any).mainEmailIndex;
    if (!values.locale) delete (values as any).locale;

    if (debtor?.id) {
      debtor.phones_attributes?.forEach((phone) => {
        const doesPhoneExist = values.phones_attributes.some(({ id }) => id === phone.id);
        if (!doesPhoneExist) {
          (values.phones_attributes as any[]).push({
            ...phone,
            _destroy: true,
          });
        }
      });

      debtor?.debtor_bank_accounts_attributes?.forEach((bank_account) => {
        const doesBankAccountExist = values.debtor_bank_accounts_attributes.some(
          ({ id }) => id === bank_account.id,
        );
        if (!doesBankAccountExist) {
          (values.debtor_bank_accounts_attributes as any).push({
            ...bank_account,
            _destroy: true,
          });
        }
      });

      clientActions.update({
        data: {
          ...values,
          id: debtor.id,
        },
        callback: action,
      });
    } else {
      clientActions.add({
        data: values,
        callback: action,
      });
    }
  };

  const transformVatNumber = (vat_number) => {
    let vatNumber = vat_number;
    if (vatNumber) {
      if (vatNumber.indexOf('BE0') !== -1) {
        vatNumber = vatNumber.slice(3);
        vatNumber = `BE 0${vatNumber}`;
      }
    } else {
      return '';
    }

    return vatNumber;
  };

  const showSideMenu = () => {
    const init = {
      moral_entity: false,
      ...debtor,
      debtor_bank_accounts_attributes:
        debtor?.debtor_bank_accounts_attributes?.length! > 0
          ? debtor!.debtor_bank_accounts_attributes
          : undefined,
      phones_attributes:
        debtor?.phones_attributes?.length! > 0 ? debtor!.phones_attributes : undefined,
      vat_number: transformVatNumber(debtor?.vat_number),
      emails: debtor?.emails?.map((email) => ({ email })),
      mainEmailIndex: 0,
      send_to_all_emails: debtor?.send_to_all_emails?.toString() || 'default',
      allow_payment_plans: debtor?.allow_payment_plans?.toString() || 'default',
      sending_media_attributes:
        debtor?.sending_media_attributes?.length! > 0
          ? debtor!.sending_media_attributes
          : undefined,
      recovery_plan_id: debtor?.recovery_plan
        ? { name: debtor.recovery_plan.name, id: debtor.recovery_plan.id }
        : 'default',
      default_invoice_template_id: debtor?.default_invoice_template
        ? {
            name: debtor.default_invoice_template.name,
            id: debtor.default_invoice_template.id,
          }
        : 'default',
      default_credit_note_template_id: debtor?.default_credit_note_template
        ? {
            name: debtor.default_credit_note_template.name,
            id: debtor.default_credit_note_template.id,
          }
        : 'default',
      days_between_reminder: debtor?.days_between_reminder,
      minimum_saldo_sending: debtor?.minimum_saldo_sending,
    } as any;

    sideMenuShow({
      unmount: true,
      noBackgroundClose: true,
      ...sideMenuProps,
      content: <ClientForm onSubmit={submitClient} initialValues={init} />,
    });
  };

  if (debtor?.id && !debtor.debtor_bank_accounts_attributes) {
    clientActions.detail({
      id: debtor.id,
      callback: ({ data }) => {
        debtor = treatClient(data);
        showSideMenu();
      },
    });
  } else {
    showSideMenu();
  }
}
