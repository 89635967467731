import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { currencySymbol } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { AttachedInvoices } from 'types/store/thirdparty-case-state';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './AttachedInvoices.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  invoices: AttachedInvoices;
  multiple?: boolean;
  title?: string;
  withoutFees?: boolean;
};

export default function AttachedInvoicesList({ invoices, multiple, title, withoutFees }: Props) {
  const { t, currentLang } = useTranslation();
  const company = useSelector((state: StoreState) => state.account.company.data);
  const constants = useSelector((state: StoreState) => state.app.constants);

  const showInvoice = (invoice_id) => () => {
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail id={invoice_id} modal />,
    });
  };

  return (
    <div className={styles('attached-invoices')}>
      <Card
        title={title || t(`THIRD_PARTY_CASES.INVOICES_OF_CASE.${multiple ? 'MANY' : 'ONE'}`)}
        className={styles('invoice')}
      >
        <table>
          <thead>
            <tr>
              <th>{t(i18nKeys.FORM.REFERENCE)}</th>
              <th>{t(i18nKeys.DUE_DATE)}</th>
              <th>{t(i18nKeys.TOTAL_VAT_PRICE)}</th>
              {!withoutFees && <th>{t(i18nKeys.LATE_FEES)}</th>}
              <th>{t(i18nKeys.INVOICING.ALREADY_PAID)}</th>
              <th>
                {t(i18nKeys.BALANCE_CURRENCY, {
                  text: currencySymbol(undefined, company),
                })}
              </th>
            </tr>
          </thead>
          <tbody>
            {invoices.details.map((invoice) => (
              <tr
                key={invoice.id}
                className={styles('invoice-line')}
                onClick={showInvoice(invoice.id)}
              >
                <td>{invoice.reference}</td>
                <td>{formattedDate(invoice.due_date, currentLang)}</td>
                <td>
                  <Amount
                    localizedValue={invoice.total_tvac}
                    value={invoice.localized_money_object.total_tvac}
                    suffix={invoice.currency}
                  />
                </td>
                {!withoutFees && (
                  <td>
                    <Amount
                      localizedValue={invoice.late_fees}
                      value={invoice.localized_money_object.late_fees}
                      suffix={invoice.currency}
                    />
                  </td>
                )}
                <td>
                  <Amount
                    localizedValue={invoice.amount_already_paid || 0}
                    value={invoice.localized_money_object.amount_already_paid || 0}
                    suffix={invoice.currency}
                  />
                </td>
                <td>
                  <Amount
                    localizedValue={
                      withoutFees ? invoice.remaining_balance : invoice.remaining_balance_with_fees
                    }
                    value={
                      withoutFees
                        ? invoice.localized_money_object.remaining_balance
                        : invoice.localized_money_object.remaining_balance_with_fees
                    }
                    suffix={invoice.currency}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          {invoices.details.length > 1 && (
            <tfoot>
              <tr className={styles('total-line')}>
                <td />
                <td />
                <td>
                  <Amount
                    localizedValue={invoices.total_tvac}
                    value={invoices.total_tvac}
                    suffix={company?.currency}
                  />
                </td>
                {!withoutFees && (
                  <td>
                    <Amount
                      localizedValue={invoices.total_late_fees}
                      value={invoices.total_late_fees}
                      suffix={company?.currency}
                    />
                  </td>
                )}
                <td>
                  <Amount
                    localizedValue={invoices.paid_amount}
                    value={invoices.paid_amount}
                    suffix={company?.currency}
                  />
                </td>
                <td>
                  <Amount
                    localizedValue={
                      withoutFees ? invoices.total_due_without_fees : invoices.total_due
                    }
                    value={withoutFees ? invoices.total_due_without_fees : invoices.total_due}
                    suffix={company?.currency}
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </Card>
    </div>
  );
}
