import { i18nKeys, useTranslation } from 'locales';
import { isEmpty } from 'remeda';
import { useLoadLightUsers } from 'shared/hooks';

import { Avatar, Group, MultiSelect, MultiSelectProps, TagsInput, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconCheck } from '@tabler/icons-react';

interface FormType {
  collaborators: Array<string>;
  emails: Array<string>;
}

interface RecipientsStepProps {
  form: UseFormReturnType<FormType>;
}

export const RecipientsStep = ({ form }: RecipientsStepProps) => {
  const { t } = useTranslation();
  const { users } = useLoadLightUsers();

  const renderMultiSelectUser: MultiSelectProps['renderOption'] = ({ option, checked }) => {
    const user = users?.find((u) => u.email === option.value)!;
    return (
      <Group w="100%" gap="sm" justify="space-between">
        <Group>
          <Avatar color="blue" src={user.profilePicture} size={36} radius="xl">
            {user.name.initials}
          </Avatar>
          <div>
            <Text size="sm">{user.name.full}</Text>
            <Text size="xs" opacity={0.5}>
              {user.email}
            </Text>
          </div>
        </Group>
        {checked && <IconCheck color="var(--mantine-color-blue-6)" size={24} />}
      </Group>
    );
  };

  return (
    <>
      <Text size="lg" fw={500}>
        {t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.TITLE)}
      </Text>
      <MultiSelect
        searchable
        {...form.getInputProps('collaborators')}
        renderOption={renderMultiSelectUser}
        styles={{
          label: { fontWeight: 400, marginBottom: '5px' },
        }}
        placeholder={
          isEmpty(form.values.collaborators)
            ? t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.COLLABORATORS_PLACEHOLDER)
            : undefined
        }
        w="100%"
        label={t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.COLLABORATORS)}
        data={users?.map((user) => ({ value: user.email, label: user.name.full }))}
      />
      <div>
        <TagsInput
          {...form.getInputProps('emails')}
          styles={{
            label: { fontWeight: 400, marginBottom: '5px' },
          }}
          placeholder={
            isEmpty(form.values.emails)
              ? t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL_PLACEHOLDER)
              : undefined
          }
          w="100%"
          label={t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL)}
        />
        <Text c="dimmed" size="sm" mt={4}>
          {t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL_SUBMIT_HINT)}
        </Text>
      </div>
    </>
  );
};
