import { memo, useRef } from 'react';
import { motion } from 'framer-motion';
import { i18nKeys, useTranslation } from 'locales';
import { sample } from 'remeda';
import { RecoverSpinner } from 'shared/components/Loader';
import { isDevelopment } from 'shared/utils';

import { Center, Overlay, Stack, Title } from '@mantine/core';

const LOADING_FLAVOR_TEXTS = [
  "You're reading this",
  'Let me out of here',
  'Heading to the beach...',
  'Inflating Celia...',
  'Whipping backend devs...',
  'Loading 7th payment model...',
  "Salting Pablo's water...",
  'Looking for my leatherman...',
  'Fetching even more private constants...',
  'Waking Niko...',
  'Removing "legacy" code...',
  'Waiting for the test suite...',
  'Hiring more frontends...',
  'Writing standup...',
  'LISTENING TO ALESS TALK...',
  "Shining Andrea's bald head...",
  'Deleting the onboarding...',
  'It works on my machine',
  'The cool side of the stack',
];

interface LoadingScreenProps {}

export const LoadingScreen = memo(() => {
  const { t } = useTranslation();
  const flavorText = useRef(sample(LOADING_FLAVOR_TEXTS, 1));

  return (
    <Overlay fixed color="#ffffff" backgroundOpacity={1}>
      <Center h="100%">
        <Stack align="center" gap="xl">
          <RecoverSpinner />
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Title ta="center" order={1}>
              {t(i18nKeys.LOADING)}
            </Title>
            {isDevelopment() && (
              <Title mt="sm" ta="center" order={5}>
                {flavorText.current}
              </Title>
            )}
          </motion.div>
        </Stack>
      </Center>
    </Overlay>
  );
});
