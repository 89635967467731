import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';

import { apiCall, sendApi } from '../sagas';

import { onBoardingActions, onBoardingConstants } from './onBoarding.actions';

export function* codaboxRequest(action: any) {
  yield apiCall({
    api: action.payload?.settings ? api.settings.codaboxRequest : api.onBoarding.codaboxRequest,
    callback: action.payload?.callback,
    actionRes: onBoardingActions.codaboxRequestRes,
  });
}

export function* codaboxToken({ payload }: any) {
  yield apiCall({
    api: payload.settings ? api.settings.codaboxToken : api.onBoarding.codaboxToken,
    data: payload.data,
    callback: payload.callback,
    actionRes: onBoardingActions.codaboxTokenRes,
  });
}

export function* ponto({ payload }: any) {
  yield apiCall({
    api: payload.settings ? api.settings.ponto : api.onBoarding.ponto,
    data: payload.data,
    callback: payload.callback,
  });
}

export function* uploadTerms({ payload }: any) {
  const { callback, pdf } = payload;

  yield apiCall({
    api: api.onBoarding.uploadTerms,
    fileUpload: true,
    callback,
    data: {
      pdf,
    },
  });
}

export function* setupDebtCollectionSettings({ payload }: any) {
  yield call(apiCall, {
    api: api.onBoarding.debtCollectionSettings,
    data: payload.data,
    callback: payload.callback,
    actionFailure: payload.actionFailure,
  });
}

export function* sendColor(payload) {
  yield call(apiCall, {
    api: api.onBoarding.invoicing_settings,
    data: payload.data,
    callback: payload.callback,
  });
}

export function* companyIdentity({ payload }: any) {
  if (payload.data.logo) {
    const response = yield call(apiCall, {
      api: api.onBoarding.onBoardingFiles,
      data: {
        logo: payload.data.logo,
      },
    });
    if (response && response.status === 200) {
      yield sendColor(payload);
    }
  } else {
    yield sendColor(payload);
  }
}

function* UserWatchers() {
  yield all([
    yield takeLatest(onBoardingConstants.codaboxRequest.request, codaboxRequest),
    yield takeLatest(onBoardingConstants.codaboxToken.request, codaboxToken),
    yield takeLatest(onBoardingConstants.ponto.request, ponto),
    yield takeLatest(onBoardingConstants.noPayment.request, sendApi(api.onBoarding.noPayment)),

    yield takeLatest(onBoardingConstants.bankAccount.request, sendApi(api.onBoarding.bankAccount)),
    yield takeLatest(onBoardingConstants.stripe.request, sendApi(api.onBoarding.stripe)),
    yield takeLatest(
      onBoardingConstants.setupDebtCollectionSettings.request,
      setupDebtCollectionSettings,
    ),
    yield takeLatest(onBoardingConstants.companyIdentity.request, companyIdentity),
    yield takeLatest(onBoardingConstants.yuki.request, sendApi(api.onBoarding.yuki)),
    yield takeLatest(onBoardingConstants.horus.request, sendApi(api.onBoarding.horus)),
    yield takeLatest(
      onBoardingConstants.horusFetchCompanies.request,
      sendApi(api.onBoarding.horusFetchCompanies),
    ),
    yield takeLatest(onBoardingConstants.teamleader.request, sendApi(api.onBoarding.teamleader)),
    yield takeLatest(onBoardingConstants.exact.request, sendApi(api.onBoarding.exact)),
    yield takeLatest(
      onBoardingConstants.exactFetchCompanies.request,
      sendApi(api.onBoarding.exactFetchCompanies),
    ),
    yield takeLatest(onBoardingConstants.twikey.request, sendApi(api.onBoarding.twikey)),
    yield takeLatest(onBoardingConstants.billit.request, sendApi(api.onBoarding.billit)),
    yield takeLatest(onBoardingConstants.uploadTerms.request, uploadTerms),
  ]);
}

export default function* saga() {
  yield call(UserWatchers);
}
