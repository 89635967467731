import { useEffect } from 'react';
import classNames from 'classnames/bind';
import en from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'locales';
import { get } from 'lodash-es';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { validationMessage } from 'shared/utils/validation';

import styleIdentifiers from './DateSelector.module.scss';

registerLocale('fr', fr);
registerLocale('nl', nl);
registerLocale('en', en);

const styles = classNames.bind(styleIdentifiers);

type DateSelectorProps = {
  centered?: boolean;
  className?: string;
  disabled?: boolean;
  endName?: string;
  endPlaceholder?: string;
  errorClassName?: string;
  forcePlacement?: boolean;
  handleChange?: Function;
  handleEndChange?: Function;
  inputClassName?: string;
  label?: string;
  minDate?: Dayjs;
  name: string;
  noBorder?: boolean;
  noErrorText?: boolean;
  noMargin?: boolean;
  noMinDate?: boolean;
  placeholder?: string;
  required?: boolean;
  shadow?: boolean;
  withBorder?: boolean;
};

export const DateSelector = ({
  centered,
  className,
  disabled,
  endName,
  endPlaceholder,
  errorClassName,
  forcePlacement,
  handleChange,
  handleEndChange,
  inputClassName,
  label,
  minDate,
  name,
  noBorder,
  noErrorText,
  noMargin,
  noMinDate,
  placeholder,
  required,
  shadow,
  withBorder,
}: DateSelectorProps) => {
  const {
    watch,
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  useEffect(() => {
    register(name, { required });
    if (endName) register(endName);

    return () => {
      unregister(name);
      if (endName) unregister(endName);
    };
  }, []);

  const date = watch(name);
  const endDate = endName && watch(endName);

  const { t, currentLang } = useTranslation();

  const onSelectDate = (value: any, isEndDate?: boolean) => {
    value = moment(new Date(value)).startOf('day');
    const newDate = moment(
      new Date(value).getTime() + new Date(value).getTimezoneOffset() * -60000,
    ).toISOString();
    if (isEndDate) {
      if (handleEndChange) handleEndChange(newDate || null);
    } else if (handleChange) handleChange(newDate || null);
  };

  const dateFormat = 'dd/MM/yyyy';

  return (
    <div
      className={styles(
        'DateSelector',
        noMargin && 'no-margin',
        withBorder && 'with-border',
        shadow && 'shadow',
        className,
        error && 'error',
        centered && 'centered',
      )}
    >
      <div className={styles('input-wrapper', !noBorder && 'bordered', error && errorClassName)}>
        {label && <div className={styles('label-input')}>{label}</div>}
        <div className={styles('date-wrapper')}>
          <DatePicker
            className={inputClassName}
            locale={currentLang}
            calendarClassName="calendar"
            minDate={minDate ? dayjs(minDate, dateFormat).toDate() : null}
            dateFormat={dateFormat}
            onChange={(d) => onSelectDate(d)}
            selected={Date.parse(date) as any}
            placeholderText={placeholder || label}
            showMonthDropdown
            showYearDropdown
            disabled={disabled}
          />
          {endName && (
            <DatePicker
              className={inputClassName}
              locale={currentLang}
              calendarClassName="calendar"
              minDate={noMinDate ? null : new Date(date)}
              dateFormat={dateFormat}
              onChange={(d) => onSelectDate(d, true)}
              selected={Date.parse(endDate) as any}
              placeholderText={endPlaceholder}
              showMonthDropdown
              showYearDropdown
              popperPlacement={`bottom-${forcePlacement ? 'end' : 'start'}`}
              disabled={disabled}
            />
          )}
        </div>
      </div>
      {!noErrorText && error && (
        <div className={styles('error-message')}>{t(validationMessage[error.type])}</div>
      )}
    </div>
  );
};
