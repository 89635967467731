import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Card from 'shared/components/Card';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';

import { StepProps } from '../OnBoarding.types';

import ColorLogoForm from './ColorLogoForm';

import styleIdentifiers from './ColorLogo.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function ColorLogo({ onValidStep }: StepProps) {
  const { t } = useTranslation();

  const onSubmit = (data) => {
    data.color = data.color?.hex ? data.color.hex.replace('#', '') : data.color;

    onBoardingActions.companyIdentity({
      data,
      callback: () => {
        accountActions.getCompany({
          callback: () => {
            onValidStep();
          },
          actionFailure: (response) => {
            onValidStep(response.data.next_step);
          },
        });
      },
    });
  };

  return (
    <div className={styles('color-logo')}>
      <div className={styles('steps')}>
        <Card title={t(i18nKeys.ONBOARDING[4].TITLE)}>
          <ColorLogoForm onSubmit={onSubmit} />
        </Card>
      </div>
    </div>
  );
}
