import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, ButtonColor, RadioButton } from 'shared/io';
import { formatAmount } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';

import styleIdentifiers from './SelectStatementType.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSelectAction: Function;
  initialValue: string;
};

export default function SelectStatementType({ onSelectAction, initialValue }: Props) {
  const { t, currentLang } = useTranslation();
  const form = useForm({
    defaultValues: { grouped_type: initialValue || '' },
  });

  const submit = () => {
    onSelectAction(form.getValues().grouped_type);
  };

  const grouped_type = form.watch('grouped_type');

  return (
    <div className={styles('select-statement-type')}>
      <div className={styles('header')}>
        <div className={styles('description')}>{t(i18nKeys.TEMPLATE.SELECT_STATEMENT)}</div>
        <div>
          <Button
            color={ButtonColor.MAIN}
            label={t(i18nKeys.FORM.VALIDATE)}
            noMargin
            onClick={submit}
          />
        </div>
      </div>

      <FormProvider {...form}>
        <div className={styles('table-wrapper')}>
          <div className={styles('statement-type')}>
            <Controller
              name="grouped_type"
              defaultValue=""
              render={({ field: { ref, value, onChange, ...values } }) => (
                <RadioButton
                  {...values}
                  labelStyle={styles('radio-button-label')}
                  value={grouped_type}
                  noMargin
                  onChange={(value) => {
                    form.setValue('grouped_type', value);
                  }}
                  name="grouped_type"
                  items={[{ value: '', label: t(i18nKeys.NO_STATEMENT) }]}
                />
              )}
            />
          </div>
        </div>
        <div className={styles('table-wrapper')}>
          <div className={styles('statement-type')}>
            <Controller
              name="grouped_type"
              defaultValue=""
              render={({ field: { ref, value, ...values } }) => (
                <RadioButton
                  {...values}
                  labelStyle={styles('radio-button-label')}
                  value={grouped_type}
                  noMargin
                  onChange={(value) => {
                    form.setValue('grouped_type', value);
                  }}
                  name="grouped_type"
                  items={[
                    {
                      value: 'unpaid_invoices',
                      label: t(i18nKeys.INVOICE.IN_PROGRESS),
                    },
                  ]}
                />
              )}
            />
          </div>
          <div className="recovr-table">
            <table>
              <thead>
                <tr>
                  <th style={{ width: 200 }}>
                    <div>{t(i18nKeys.FORM.REFERENCE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.ISSUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.DUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.TOTAL_VAT_PRICE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.LATE_FEES)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.INVOICING.ALREADY_PAID)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT)}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: 200 }}>INV_1</td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 30),
                      currentLang,
                    )}
                  </td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 15),
                      currentLang,
                    )}
                  </td>
                  <td>{formatAmount(500)}</td>
                  <td>{formatAmount(50)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(550)}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }}>INV_2</td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 5),
                      currentLang,
                    )}
                  </td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * -10),
                      currentLang,
                    )}
                  </td>
                  <td>{formatAmount(250)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(250)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles('table-wrapper')}>
          <div className={styles('statement-type')}>
            <Controller
              name="grouped_type"
              defaultValue=""
              render={({ field: { ref, value, ...values } }) => (
                <RadioButton
                  {...values}
                  labelStyle={styles('radio-button-label')}
                  value={grouped_type}
                  onChange={(value) => {
                    form.setValue('grouped_type', value);
                  }}
                  noMargin
                  name="grouped_type"
                  items={[
                    {
                      value: 'overdue_invoices',
                      label: t(i18nKeys.INVOICE.LATE_ONLY),
                    },
                  ]}
                />
              )}
            />
          </div>
          <div className="recovr-table">
            <table>
              <thead>
                <tr>
                  <th style={{ width: 200 }}>
                    <div>{t(i18nKeys.FORM.REFERENCE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.ISSUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.DUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.TOTAL_VAT_PRICE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.LATE_FEES)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.INVOICING.ALREADY_PAID)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT)}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: 200 }}>INV_1</td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 30),
                      currentLang,
                    )}
                  </td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 15),
                      currentLang,
                    )}
                  </td>
                  <td>{formatAmount(500)}</td>
                  <td>{formatAmount(50)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(550)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles('table-wrapper')}>
          <div className={styles('statement-type')}>
            <Controller
              name="grouped_type"
              defaultValue=""
              render={({ field: { ref, value, ...values } }) => (
                <RadioButton
                  {...values}
                  labelStyle={styles('radio-button-label')}
                  value={grouped_type}
                  noMargin
                  onChange={(value) => {
                    form.setValue('grouped_type', value);
                  }}
                  name="grouped_type"
                  items={[
                    {
                      value: 'debtor_balance',
                      label: t(i18nKeys.CLIENT_BALANCE),
                    },
                  ]}
                />
              )}
            />
            <span>{t(i18nKeys.TEMPLATE.FULL_DEBTOR_BALANCE)}</span>
          </div>
          <div className="recovr-table">
            <table>
              <thead>
                <tr>
                  <th style={{ width: 200 }}>
                    <div>{t(i18nKeys.FORM.REFERENCE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.ISSUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.DUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.TOTAL_VAT_PRICE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.LATE_FEES)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.INVOICING.ALREADY_PAID)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT)}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: 200 }}>INV_1</td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 30),
                      currentLang,
                    )}
                  </td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 15),
                      currentLang,
                    )}
                  </td>
                  <td>{formatAmount(500)}</td>
                  <td>{formatAmount(50)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(550)}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }}>INV_2</td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 5),
                      currentLang,
                    )}
                  </td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * -10),
                      currentLang,
                    )}
                  </td>
                  <td>{formatAmount(250)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(250)}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className={styles('red')}>
                    CN_1
                  </td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 5),
                      currentLang,
                    )}
                  </td>
                  <td />
                  <td>{formatAmount(250)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(250)}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className={styles('yellow')}>
                    {t(i18nKeys.PAYMENT)} +++000/0000/00000+++
                  </td>
                  <td>{formattedDate(new Date(), currentLang)}</td>
                  <td />
                  <td>{formatAmount(300)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(300)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles('table-wrapper')}>
          <div className={styles('statement-type')}>
            <Controller
              name="grouped_type"
              defaultValue=""
              render={({ field: { ref, value, ...values } }) => (
                <RadioButton
                  {...values}
                  labelStyle={styles('radio-button-label')}
                  value={grouped_type}
                  noMargin
                  onChange={(value) => {
                    form.setValue('grouped_type', value);
                  }}
                  name="grouped_type"
                  items={[
                    {
                      value: 'overdue_debtor_balance',
                      label: t(i18nKeys.OVERDUE_BALANCE),
                    },
                  ]}
                />
              )}
            />
            <span>{t(i18nKeys.TEMPLATE.OVERDUE_DEBTOR_BALANCE)}</span>
          </div>
          <div className="recovr-table">
            <table>
              <thead>
                <tr>
                  <th style={{ width: 200 }}>
                    <div>{t(i18nKeys.FORM.REFERENCE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.ISSUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.DUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.TOTAL_VAT_PRICE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.LATE_FEES)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.INVOICING.ALREADY_PAID)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT)}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: 200 }}>INV_1</td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 30),
                      currentLang,
                    )}
                  </td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 15),
                      currentLang,
                    )}
                  </td>
                  <td>{formatAmount(500)}</td>
                  <td>{formatAmount(50)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(550)}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className={styles('red')}>
                    CN_1
                  </td>
                  <td>
                    {formattedDate(
                      new Date((new Date() as any) - 60 * 60 * 24 * 1000 * 5),
                      currentLang,
                    )}
                  </td>
                  <td />
                  <td>{formatAmount(250)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(250)}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className={styles('yellow')}>
                    {t(i18nKeys.PAYMENT)} +++000/0000/00000+++
                  </td>
                  <td>{formattedDate(new Date(), currentLang)}</td>
                  <td />
                  <td>{formatAmount(300)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(0)}</td>
                  <td>{formatAmount(300)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
