import classNames from 'classnames/bind';
import { Icon, IconName } from 'shared/components/Icon';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsButtonProps = {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  label?: boolean;
};

export const WorkflowFormStepsButton = ({
  label,
  title,
  onClick,
  disabled,
}: WorkflowFormStepsButtonProps) => (
  <div className={styles('add', disabled && 'disabled')}>
    <button type="button" onClick={onClick} disabled={disabled} title={title}>
      <Icon name={IconName.PLUS} />
      {label && <span>{title}</span>}
    </button>
  </div>
);
