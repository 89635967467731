import { Reducer, useReducer } from 'react';
import { SendingMedium } from 'api/models';
import { SettingsCard } from 'app/Private/Settings';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconName } from 'shared/components/Icon';
import { InvoiceFooterCustomization } from 'shared/components/InvoiceFooter';
import SendingMedia from 'shared/components/SendingMedium/SendingMedia';
import { Button as OldButton, ButtonColor, CustomSelect, UploadZone } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { reducerState } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { IdName } from 'types/genericType';
import { onSubmitFunction } from 'types/react-hook-form';
import { BillingLog } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import { Button, ColorInput, Stack } from '@mantine/core';

import InvoiceJournal from './InvoiceJournal';

import styleIdentifiers from './invoiceSettingsForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  initialValues: {
    color: string;
    billing_logs_attributes: BillingLog[];
    logo: string;
    sending_media: SendingMedium[];
    generate_invoice_in_debtor_locale: string;
    default_credit_note_template_id: IdName;
    default_invoice_template_id: IdName;
    display_qr_code: string;
  };
  onSubmit: onSubmitFunction;
};

type State = Reducer<
  {
    invoiceTemplate: any[];
    cnTemplate: any[];
  },
  any
>;

export default function InvoiceSettingsForm({ initialValues, onSubmit }: Props) {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);
  const company = useSelector((state: StoreState) => state.account.company.data!);
  const [{ cnTemplate, invoiceTemplate }, setState] = useReducer<State>(reducerState, {
    invoiceTemplate: [],
    cnTemplate: [],
  });

  const getTemplates = (step_type) => () => {
    settingsActions.templateList({
      data: {
        step_type,
      },
      noLoading: true,
      callback: ({ data }) => {
        setState({
          [step_type === 'invoice' ? 'invoiceTemplate' : 'cnTemplate']: data.map((template) =>
            treatTemplate(template),
          ),
        });
      },
    });
  };

  const form = useForm({
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    control,
    formState: { dirtyFields, touchedFields },
    reset,
    getValues,
    setValue,
    watch,
  } = form;

  const isDirty = !!Object.keys(dirtyFields).length || !!Object.keys(touchedFields).length;

  const formArray = useFieldArray({
    control,
    name: 'billing_logs_attributes',
    keyName: 'rhfId',
  });

  const addJournal = () => {
    formArray.append({});
  };

  const submit = (callback) => {
    handleSubmit(onSubmit(callback))();
  };

  const updateLogo = (logo) => {
    if (logo) {
      accountActions.updateCompanyFiles({
        fileUpload: true,
        data: {
          logo,
        },
        success: 'FORM.PREFERENCES.SUCCESSFULL_LOGO_UPDATE',
      });
    } else {
      showDialog({
        id: DialogShowId.CONFIRM,
        size: DialogShowSize.MEDIUM,
        title: t(i18nKeys.CLIENT.CLIENT_DELETION),
        children: (
          <AYSModal
            text={t(i18nKeys.SETTINGS.INVOICING.AYS_DELETE_LOGO)}
            confirmButtonColor={ButtonColor.RED}
            onCancel={() => {
              dialogHide(DialogShowId.CONFIRM);
              setValue('logo', initialValues.logo);
            }}
            onConfirm={() => {
              accountActions.updateCompanyFiles({
                fileUpload: true,
                data: {
                  logo: null,
                },
                success: 'FORM.PREFERENCES.SUCCESSFULL_LOGO_UPDATE',
              });
            }}
          />
        ),
      });
    }
  };

  const submitReset = (value: unknown) => {
    const formValues = getValues();
    onSubmit()(value);
    reset(formValues);
  };

  const logoImg = watch('logo');
  const sendingMedia = watch('sending_media') ?? [];

  const addSendingMedium = () => {
    if (sendingMedia.length < (constants.sending_medium ?? []).length) {
      const defaultSendingMedium = (constants.sending_medium.filter(
        (e) => !sendingMedia.some((el) => e.value === el.name),
      ) || constants.sending_medium)[0];

      setValue(
        'sending_media',
        [
          ...sendingMedia,
          {
            name: defaultSendingMedium.value,
            order: sendingMedia.length + 1,
            force_sending: true,
          },
        ] as SendingMedium[],
        {
          shouldTouch: true,
        },
      );
    }
  };

  return (
    <Stack>
      <SettingsCard>
        <FormProvider {...form}>
          <NavigationPrompt when={isDirty} onSaveAndQuit={submit} />
          <form className={styles('InvoiceSettingsForm')} onSubmit={handleSubmit(submitReset)}>
            <h3>{t(i18nKeys.FORM.INVOICING.CUSTOM_INVOICE)}</h3>
            <div className={styles('content', 'section')}>
              <div className={styles('col', 'company-logo')}>
                <h4>{t(i18nKeys.FORM.INVOICING.YOUR_LOGO)}</h4>
                <div className={styles('upload-zone')}>
                  <UploadZone
                    onChange={updateLogo}
                    accept="image/*"
                    info={t(i18nKeys.FORM.INVOICING.IMPORT_LOGO)}
                    subinfo={t(i18nKeys.FORM.INVOICING.FILE_FORMAT)}
                    errorMessage={t(i18nKeys.ERROR.INVALID_FILE_FORMAT)}
                    value={logoImg}
                    small
                    img
                    logo
                    noMargin
                    noResize
                  />
                </div>
              </div>
              <div className={styles('col', 'company-color')}>
                <h4>{t(i18nKeys.FORM.INVOICING.YOUR_COLOR)}</h4>
                <Controller
                  control={control}
                  defaultValue={initialValues.color}
                  name="color"
                  render={({ field: { ref, ...values } }) => <ColorInput {...values} />}
                />
              </div>
            </div>
            <h3>{t(i18nKeys.SENDING_SETTINGS)}</h3>
            <div className={styles('content', 'section', 'grid-row')}>
              <div className={styles('col-6')}>
                <Controller
                  defaultValue=""
                  name="generate_invoice_in_debtor_locale"
                  rules={{ required: true }}
                  render={() => (
                    <CustomSelect
                      label={t(i18nKeys.DOCUMENTS_LOCALE)}
                      keyText="description"
                      keyValue="value"
                      name="generate_invoice_in_debtor_locale"
                      items={[
                        {
                          description: t(i18nKeys.DEBTOR_LOCALE),
                          value: 'true',
                        },
                        {
                          description: t(i18nKeys.COMPANY_LOCALE),
                          value: 'false',
                        },
                      ]}
                    />
                  )}
                />
              </div>
              <div className={styles('col-6')}>
                <Controller
                  rules={{ required: true }}
                  defaultValue=""
                  name="default_invoice_template_id"
                  render={() => (
                    <CustomSelect
                      label={t(i18nKeys.DEFAULT_INVOICE_TEMPLATE)}
                      keyText="name"
                      keyValue="id"
                      hideOnLoading
                      name="default_invoice_template_id"
                      load={getTemplates('invoice')}
                      items={invoiceTemplate}
                    />
                  )}
                />
              </div>
              <div className={styles('col-6')}>
                <Controller
                  rules={{ required: true }}
                  defaultValue=""
                  name="default_credit_note_template_id"
                  render={() => (
                    <CustomSelect
                      label={t(i18nKeys.DEFAULT_CN_TEMPLATE)}
                      keyText="name"
                      keyValue="id"
                      hideOnLoading
                      name="default_credit_note_template_id"
                      load={getTemplates('credit_note')}
                      items={cnTemplate}
                    />
                  )}
                />
              </div>
              <div className={styles('col-6')}>
                <Controller
                  defaultValue=""
                  name="display_qr_code"
                  rules={{ required: true }}
                  render={() => (
                    <CustomSelect
                      info={!company.package.can_use_online_payment ? t(i18nKeys.NOT_INCLUDED) : ''}
                      notAllowed={!company.package.can_use_online_payment}
                      noArrow={!company.package.can_use_online_payment}
                      label={t(i18nKeys.DISPLAY_QR_CODE)}
                      keyText="description"
                      keyValue="value"
                      name="display_qr_code"
                      items={[
                        { description: t(i18nKeys.YES), value: 'true' },
                        { description: t(i18nKeys.NO), value: 'false' },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <h3>{t(i18nKeys.DEBTOR.SENDING_MEDIUMS)}</h3>
            <div className={styles('content', 'section', 'grid-row')}>
              <div className={styles('col-6')}>
                <SendingMedia fieldName="sending_media" className={styles('input')} />
                {sendingMedia.length < constants.sending_medium.length && (
                  <OldButton
                    color={ButtonColor.GREY}
                    label={t(i18nKeys.ACTIONS.ADD)}
                    iconRight={IconName.PLUS}
                    onClick={addSendingMedium}
                    small
                  />
                )}
              </div>
            </div>
            <div className={styles('invoice-journal')}>
              <h3>
                {t(i18nKeys.FORM.INVOICING.INVOICING_BOOK)}{' '}
                <Icon
                  name={IconName.PLUS}
                  title={t(i18nKeys.FORM.INVOICING.ADD_NEW_BOOK)}
                  onClick={addJournal}
                  className={styles('add-icon')}
                />
              </h3>
              <InvoiceJournal formArray={formArray} />
            </div>
            <Stack align="end">
              <Button disabled={!isDirty} type="submit">
                {t(i18nKeys.SAVE)}
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </SettingsCard>
      <SettingsCard>
        <div className={styles('section')}>
          <h3>{t(i18nKeys.SETTINGS.INVOICING.FOOTER.TITLE)}</h3>
          <p>{t(i18nKeys.SETTINGS.INVOICING.FOOTER.DESCRIPTION)}</p>
          <InvoiceFooterCustomization />
        </div>
      </SettingsCard>
    </Stack>
  );
}
