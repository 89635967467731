import { store } from 'init/redux-store';
import { first } from 'remeda';
import { Connector } from 'shared/components/Integrations/Integrations.constants';

// These are the magic values that qualify a third-party integration as a "connector" in the context of this feature.
// See card RCVR-152 for discussion.
const CONNECTORS = Object.values(Connector) as Array<string>;

// Name misleading, this checks whether:
// - either a connector is active
// - OR: an integration is active, AND reconciliation is done on their end.
// We use this check to disable the manual creation of resources like invoices and payments
// which would lead to incoherent states once that same info is synced from the connector  or integrator.
export const isConnectorActive = (): boolean => {
  const company = store.getState().account.company.data;

  // Most restrictive option if constants aren't loaded
  if (company == null) return true;

  const integrationsInUse = company.tp_accounting.integration_in_use;
  const maybeIntegrationInUse = first(integrationsInUse);

  if (maybeIntegrationInUse == null) return false;

  return CONNECTORS.includes(maybeIntegrationInUse) || company.tp_accounting.use_payment_matching;
};
