import { AssignableModel, AssignableTo } from 'api/models';
import { AutomaticAssignationsList } from 'app/Private/AutomaticAssignations/Listing/AutomaticAssignationsList';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { partition } from 'remeda';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import { useLoadUsers } from 'shared/hooks';
import { Button } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { sideMenuShow } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import { SettingsCard } from '../SettingsCard';

import ColleagueItem from './ColleagueItem';
import InviteCollaboratorForm from './InviteCollaboratorForm';

import styleIdentifiers from './CollaboratorsSettings.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EnhancedTable = CustomTable(ColleagueItem);

export const CollaboratorsSettings = () => {
  const { t } = useTranslation();
  const company = useSelector((state: StoreState) => state.account.company.data!);
  const { users = [], isUsersLoading } = useLoadUsers(true);

  const [activeCollaborators, _] = partition(users, (user) => user.isActive);

  const TABLE_HEADERS = [
    {
      key: 'id',
      title: 'ID',
    },
    {
      key: 'first_name',
      title: t(i18nKeys.SETTINGS.STAFF.COLLABORATOR_NAME),
      width: 200,
    },
    {
      key: 'roles',
      title: t(i18nKeys.SETTINGS.STAFF.PERMISSIONS),
    },
    {
      key: 'email',
      title: t(i18nKeys.EMAIL),
      width: 200,
    },
    {
      key: 'active',
      title: t(i18nKeys.SETTINGS.STAFF.ACTIVE),
      width: 80,
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  const newColleague = () => {
    sideMenuShow({
      unmount: true,
      content: <InviteCollaboratorForm />,
    });
  };

  return (
    <div className={styles('collaborators-settings')}>
      <PageTitle>{t(i18nKeys.SETTINGS.STAFF.TITLE)}</PageTitle>
      <SettingsCard>
        <EnhancedTable
          noFooter
          noShadow
          subtitle={t(i18nKeys.SETTINGS.STAFF.YOUR_STAFF)}
          actions={
            <Button
              noMargin
              label={t(i18nKeys.INVITE)}
              onClick={newColleague}
              iconRight={IconName.MINIMAL_RIGHT}
              disabled={
                company.package.allowed_users_count !== null &&
                company.package.allowed_users_count < activeCollaborators.length
              }
            />
          }
          loading={isUsersLoading}
          loaded={isUsersLoading === false}
          headers={TABLE_HEADERS}
          items={users}
          noCheckbox
        />
        <AutomaticAssignationsList
          defaultType={AssignableTo.user}
          tabItems={[
            {
              model_to_assign: AssignableModel.invoice,
              assign_to_type: AssignableTo.user,
              key: i18nKeys.COMMON.INVOICE,
            },
            {
              model_to_assign: AssignableModel.debtor,
              assign_to_type: AssignableTo.user,
              key: i18nKeys.COMMON.CLIENT,
            },
          ]}
        />
      </SettingsCard>
    </div>
  );
};
