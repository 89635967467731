import { DebtorReaction } from 'api/models';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { dateFromNow } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import styleIdentifiers from './ClientDetailTabsTimeline.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientDetailTabsTimelineItemReactionProps = {
  reaction: DebtorReaction;
};

export const ClientDetailTabsTimelineItemReaction = ({
  reaction,
}: ClientDetailTabsTimelineItemReactionProps) => {
  const { t, currentLang } = useTranslation();

  const {
    attributes: { date, invoice, comment },
  } = reaction;

  const onClick = () => {
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail id={invoice.id} modal />,
    });
  };

  return (
    <div onClick={onClick}>
      <span className={styles('date')}>{dateFromNow(date as any, currentLang)}</span>
      <div className={styles('from')}>
        {(invoice.attributes.debtor.attributes as any).full_name}
      </div>
      <p className={styles('sub-detail')}>
        {t(i18nKeys.TIMELINE.DEBTOR_REACTION)} {invoice.attributes.reference}
      </p>
      <div className={styles('message')}>{comment}</div>
    </div>
  );
};
