import { buildActions, makeReqCons } from 'store/actions';

const prefix = 'transaction';

export const transactionConstants = {
  getReconciliation: makeReqCons(`${prefix}_GET_RECONCILIATION`),
  selectReconciliation: `${prefix.toUpperCase()}_SELECT_RECONCILIATION`,
  manualReconciliation: makeReqCons(`${prefix}_MANUAL_RECONCILIATION`),
  reconciliate: makeReqCons(`${prefix}_RECONCILIATE`),
  ignorePayments: makeReqCons(`${prefix}_DELETE_PAYMENT`),
  unignorePayments: makeReqCons(`${prefix}_UNIGNORE_PAYMENT`),
  getReconciliationPayments: makeReqCons(`${prefix}_PAYMENT`),
  paymentHistory: makeReqCons(`${prefix}_PAYMENT_HISTORY`),
  paymentHistoryPage: makeReqCons(`${prefix}_PAYMENT_HISTORY_PAGE`),
  unmatchManualPayment: makeReqCons(`${prefix}_UNMATCH_MANUAL_PAYMENT`),
  unmatchExternalPayment: makeReqCons(`${prefix}_UNMATCH_EXTERNAL_PAYMENT`),
  resetReconciliationSelection: makeReqCons(`${prefix}_RESET_RECONCILIATION_SELECTION`),
};

type TransactionActions = {
  getReconciliation: Function;
  getReconciliationRes: Function;
  selectReconciliation: Function;
  selectReconciliatioResn: Function;
  manualReconciliation: Function;
  manualReconciliationRes: Function;
  reconciliate: Function;
  reconciliateRes: Function;
  ignorePayments: Function;
  ignorePaymentsRes: Function;
  unignorePayments: Function;
  unignorePaymentsRes: Function;
  getReconciliationPayments: Function;
  getReconciliationPaymentsRes: Function;
  paymentHistory: Function;
  paymentHistoryRes: Function;
  paymentHistoryPage: Function;
  paymentHistoryPageRes: Function;
  unmatchManualPayment: Function;
  unmatchManualPaymentRes: Function;
  unmatchExternalPayment: Function;
  unmatchExternalPaymentRes: Function;
  resetReconciliationSelection: Function;
  resetReconciliationSelectionRes: Function;
};

const transactionActions = buildActions<TransactionActions>('', transactionConstants, {});

export { transactionActions };
