import classNames from 'classnames/bind';
import { Icon, IconName } from 'shared/components/Icon';

import styleIdentifiers from './RecoveryPlan.module.scss';

const styles = classNames.bind(styleIdentifiers);

type RecoveryPlanStepAddProps = {
  onClick: () => void;
  text?: string;
};

export const RecoveryPlanAddStep = ({ onClick, text }: RecoveryPlanStepAddProps) => (
  <div className={styles('add-circle')} onClick={onClick}>
    <Icon name={IconName.PLUS} />
    {text && (
      <>
        {' '}
        <span className={styles('label')}>{text}</span>
      </>
    )}
  </div>
);
