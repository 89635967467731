import classNames from 'classnames/bind';
import { Button, ButtonColor } from 'shared/io';
import { Locale } from 'types';
import { DescVal } from 'types/storeTypes';

import styleIdentifiers from './languagePicker.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  changeLang: Function;
  currentLang: Locale;
  availableLang: DescVal[];
};

// Only used for debtor reaction
export default function LanguagePicker({ changeLang, currentLang, availableLang }: Props) {
  const onChangeLang = (lang) => {
    changeLang(lang);
  };
  return (
    <div className={styles('container')}>
      {availableLang?.map((lang) => (
        <Button
          key={lang.value}
          label={lang.value.toUpperCase()}
          className={styles('lang-button', currentLang === lang.value && 'active')}
          color={ButtonColor.MAIN}
          noMargin
          noShadow
          type="button"
          onClick={() => onChangeLang(lang.value)}
        />
      ))}
    </div>
  );
}
