import { PropsWithChildren } from 'react';
import { DueDateDelay } from 'api/models';
import { TaskFormColleague, TaskFormType } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect, Input, Textarea } from 'shared/io';
import { RecoveryPlanStep } from 'types/recovery-plan';

import styleIdentifiers from './RecoveryStepForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const RecoveryStepFormCreateTask = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext<RecoveryPlanStep>();

  register('step_type', { required: true, value: 'task_generation' });

  const dueDateDescription = (days) => {
    switch (days) {
      case 0:
        return i18nKeys.CREATION_DATE;
      case 999:
        return i18nKeys.NEXT_STEP_DATE;
      default:
        return i18nKeys.N_DAYS_AFTER_CREATION;
    }
  };

  const dueDateDelays = Object.values(DueDateDelay).map((e) => ({
    description: t(dueDateDescription(e), { days: e }),
    value: e.toString(),
  }));

  return (
    <>
      {children}
      <Input
        register={register('task_attributes.title', {
          required: true,
        })}
        errorMessage={get(errors, 'task_attributes.title')}
        label={t(i18nKeys.TASK.ATTRIBUTES.TITLE)}
        withBorder
      />
      <Textarea
        register={register('task_attributes.description')}
        className={styles('input')}
        withBorder
        label={t(i18nKeys.DESCRIPTION)}
      />
      <TaskFormType name="task_attributes.task_type_id" />
      <TaskFormColleague name="task_attributes.user_id" />
      <Controller
        name="task_attributes.due_date_delay"
        rules={{ required: true }}
        render={() => (
          <CustomSelect
            label={t(i18nKeys.DUE_DATE)}
            keyText="description"
            keyValue="value"
            name="task_attributes.due_date_delay"
            withBorder
            items={dueDateDelays}
          />
        )}
      />
      <Controller
        name="task_attributes.mandatory"
        rules={{ required: true }}
        render={() => (
          <CustomSelect
            label={t(i18nKeys.TASK.ATTRIBUTES.MANDATORY)}
            keyText="description"
            keyValue="value"
            name="task_attributes.mandatory"
            withBorder
            items={[
              { description: t(i18nKeys.YES), value: 'true' },
              { description: t(i18nKeys.NO), value: 'false' },
            ]}
          />
        )}
      />
    </>
  );
};
