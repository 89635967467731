import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import { addActionNameToMutationResult, ApiError, ApiResponse, useAxiosInstance } from './utils';

export const AcceptRecovrTosSchema = z.object({});

export type AcceptRecovrTosVariables = z.infer<typeof AcceptRecovrTosSchema>;

async function acceptRecovrTosMutation(
  axiosInstance: Promise<AxiosInstance>,
  vars: AcceptRecovrTosVariables,
) {
  const instance = await axiosInstance;

  const data = instance.post('accept_tos', AcceptRecovrTosSchema.parse(vars));
  return data;
}

export function useAcceptRecovrTos() {
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation({
    mutationFn: (vars: AcceptRecovrTosVariables) => acceptRecovrTosMutation(axiosInstance, vars),
    onError: (error: ApiError) => {
      console.error(error);
      notifications.show({
        title: 'Error',
        color: 'red',
        message: error.response?.data.error_message,
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    AcceptRecovrTosVariables,
    'acceptRecovrTos'
  >('acceptRecovrTos', mutation);
}
