import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { isEmpty } from 'remeda';
import RecovrLogoLoader from 'shared/components/Loader';
import { useAcceptGcollectRates, useLoadGcollectCaseFee } from 'shared/hooks';
import { formatAmount } from 'shared/utils/normalization';
import { Id } from 'types';

import { Button, Group, Stack, Table, Text } from '@mantine/core';
import { modals } from '@mantine/modals';

export interface GcollectCaseError {
  id: Id;
  error_message: string;
  key: string;
}

interface GcollectModalValidateProps {
  caseIds: Array<Id>;
  errors?: Array<GcollectCaseError>;
}

export const GcollectModalValidate = ({ caseIds, errors = [] }: GcollectModalValidateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { gcollectCaseFee, isGcollectCaseFeeLoading } = useLoadGcollectCaseFee(caseIds);
  const { acceptGcollectRates, isAcceptGcollectRatesLoading } = useAcceptGcollectRates();

  if (isGcollectCaseFeeLoading) return <RecovrLogoLoader />;

  const caseRows = (gcollectCaseFee?.invoices ?? []).map(
    ({ reference, collectionFee, gcollectCollectionRate, remainingAmount }) => [
      reference,
      formatAmount(remainingAmount),
      `${Number((gcollectCollectionRate * 100).toFixed(2))} %`,
      formatAmount(collectionFee),
    ],
  );

  const errorRows = errors.map(({ key, error_message }) => [key, error_message]);

  return (
    <Stack gap="md">
      {!isEmpty(caseRows) && (
        <>
          <Text>{t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.VALIDATE_FEES_TEXT)}</Text>
          <Table
            w="100%"
            striped
            style={{ border: '1px solid lightgrey' }}
            data={{
              head: [
                t(i18nKeys.COMMON.INVOICE),
                t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.INVOICE_AMOUNT),
                t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.COLLECTION_PERCENTAGE),
                t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.COLLECTION_AMOUNT),
              ],
              body: caseRows,
            }}
          />
        </>
      )}
      {!isEmpty(errors) && (
        <>
          <Text>{t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.VALIDATE_FEES_ERRORS)}</Text>
          <Table
            w="100%"
            striped
            style={{ border: '1px solid lightgrey' }}
            data={{
              head: [t(i18nKeys.COMMON.INVOICE), t(i18nKeys.REASON)],
              body: errorRows,
            }}
          />
        </>
      )}
      <Group gap="md" justify="end">
        {isEmpty(caseRows) ? (
          <Button color="red" onClick={modals.closeAll}>
            {t(i18nKeys.CLOSE)}
          </Button>
        ) : (
          <>
            <Button
              variant="subtle"
              color="red"
              disabled={isAcceptGcollectRatesLoading}
              onClick={() =>
                acceptGcollectRates(
                  { thirdPartyCaseIds: caseIds, acceptRates: false },
                  { onSuccess: () => modals.closeAll() },
                )
              }
            >
              {t(i18nKeys.CANCEL)}
            </Button>
            <Button
              loading={isAcceptGcollectRatesLoading}
              onClick={() =>
                acceptGcollectRates(
                  { thirdPartyCaseIds: caseIds, acceptRates: true },
                  {
                    onSuccess: () => {
                      history.push('/thirdparty_cases');
                      modals.closeAll();
                    },
                  },
                )
              }
            >
              {t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.ACCEPT)}
            </Button>
          </>
        )}
      </Group>
    </Stack>
  );
};
