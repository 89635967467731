import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import Percentage from 'shared/components/Percentage';
import { CalculationType, InterestCalculationType } from 'shared/forms/PreferencesForm';
import { Invoice } from 'types/store/invoice-state';

import styleIdentifiers from '../ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type LatePaymentInfoTermsModalProps = { invoice: Invoice };

export default function LatePaymentInfoTermsModal({ invoice }: LatePaymentInfoTermsModalProps) {
  const { t } = useTranslation();

  const {
    terms_and_conditions: { fix_fee, interest, penalty_clause },
    localized_money_object,
    currency,
  } = invoice;

  return (
    <div className={styles('box', 'to-handle-detail-modal')}>
      {!!penalty_clause?.value && (
        <>
          <h4>{t(i18nKeys.PENALTY_CLAUSE)}</h4>
          <div className={styles('item-container')}>
            {!!penalty_clause?.minimum && (
              <div className={styles('item')}>
                <span className={styles('subtitle')}>{t(i18nKeys.AMOUNT_MIN)}</span>
                <span className={styles('value')}>
                  <Amount
                    localizedValue={penalty_clause.minimum}
                    value={localized_money_object.terms_and_conditions.penalty_clause.minimum}
                    suffix={currency}
                  />
                </span>
              </div>
            )}
            <div className={styles('item')}>
              <span className={styles('subtitle')}>
                {t(
                  penalty_clause.type === InterestCalculationType.PERCENTAGE
                    ? i18nKeys.VALUE
                    : i18nKeys.AMOUNT,
                )}
              </span>
              <span className={styles('value')}>
                {penalty_clause.type === InterestCalculationType.PERCENTAGE ? (
                  <Percentage value={penalty_clause.value} />
                ) : (
                  <Amount
                    localizedValue={penalty_clause.value}
                    value={localized_money_object.terms_and_conditions.penalty_clause.value}
                    suffix={currency}
                  />
                )}
              </span>
            </div>
          </div>
        </>
      )}
      {!!interest?.value && (
        <>
          <h4>{t(i18nKeys.FORM.PREFERENCES.INTEREST)}</h4>
          <div className={styles('item-container')}>
            <div className={styles('item')}>
              <span className={styles('subtitle')}>{t(i18nKeys.FORM.PREFERENCES.PERIODICITY)}</span>
              <span className={styles('value')}>
                {t(
                  i18nKeys.CALCULATION_TYPE[
                    Object.keys(CalculationType).find(
                      (key) => interest.period === CalculationType[key as keyof CalculationType],
                    ) as CalculationType
                  ],
                )}
              </span>
            </div>
            <div className={styles('item')}>
              <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.DETAIL.INTEREST_RATE)}</span>
              <span className={styles('value')}>
                <Percentage value={interest.value} />
              </span>
            </div>
          </div>
        </>
      )}
      {!!fix_fee?.value && (
        <>
          <h4>{t(i18nKeys.FORM.PREFERENCES.FIX_FEE)}</h4>
          <div className={styles('item-container')}>
            <div className={styles('item')}>
              <span className={styles('subtitle')}>{t(i18nKeys.AMOUNT)}</span>
              <span className={styles('value')}>
                <Amount
                  localizedValue={fix_fee.value}
                  value={localized_money_object.terms_and_conditions.fix_fee.value}
                  suffix={currency}
                />
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
