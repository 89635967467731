import Activities from 'app/Private/Activities';
import CreditNotesListing from 'app/Private/CreditNotes/CreditNoteListing';
import { Dashboard } from 'app/Private/Dashboard';
import InvoicesDetail from 'app/Private/Invoices/Detail';
import ImminentReminders from 'app/Private/Invoices/ImminentReminders';
import InvoicesListing from 'app/Private/Invoices/Listing';
import InvoicesToHandle from 'app/Private/Invoices/ToHandle';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import ProductsListing from 'app/Private/Products/Listing';
import SettingsChunk from 'app/Private/Settings';
import ThirdpartyCasesListing from 'app/Private/ThirdpartyCases/ThirdpartyCaseListing';
import TransactionHistory from 'app/Private/Transactions/History';
import Reconciliation from 'app/Private/Transactions/Reconciliation';
import { i18nKeys, useTranslation } from 'locales';
import { upperFirst } from 'lodash-es';
import { Kanban } from 'modules/Kanban';
import { Reminders } from 'modules/Reminders';
import { Reports } from 'modules/Reports';
import qs from 'query-string';
import { Switch, useLocation, useRouteMatch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { isConnectorActive } from 'shared/utils/connector';
import { success } from 'store/view/view.actions';

import { Analytics } from '../../modules/Analytics';

import { DuplicatesClientDetail } from './Clients/Duplicates/DuplicatesClientDetail';
import { DuplicatesClients } from './Clients/Duplicates/DuplicatesClients';
import ManageCreditNote from './CreditNotes/Manage/Manage';
import ImportedInvoiceDetail from './Invoices/ImportedInvoiceDetail';
import InvoiceListToConfirm from './Invoices/InvoiceListToConfirm';
import ManageInvoice from './Invoices/Manage/Manage';
import { AcceptTermsOfService } from './AcceptTermsOfService';
import { ClientDetail, ClientsImportedDetail, ClientsList, ClientsToConfirmList } from './Clients';
import { ExternalMailDetail, ExternalMailList } from './ExternalMailbox';
import ImportPDFInvoice from './ImportPDFInvoice';
import {
  PaymentPlanDetail,
  PaymentPlanEditForm,
  PaymentPlanForm,
  PaymentPlansList,
} from './PaymentPlans';
import { CustomTasksList, TaskDetail } from './Tasks';

const RedirectInvoiceActivity = () => {
  const {
    params: { id, activityId },
    path,
  } = useRouteMatch<{ id: string; activityId: string }>();

  return (
    <Redirect
      to={`/${
        path.includes('invoices') ? 'invoices' : 'credit-notes'
      }/${id}?activityId=${activityId}`}
    />
  );
};

export const PrivateRoutes = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const hasActiveConnector = isConnectorActive();

  return (
    <Switch>
      {/* MARK: Dashboard */}
      <Route path="/dashboard" component={Dashboard} />
      {/* MARK: Kanban */}
      <Route path="/kanban" component={Kanban} />
      {/* MARK: Client */}
      <Route exact path="/clients" component={ClientsList} />
      <Route path="/clients/:id" component={ClientDetail} />
      <Route path="/client/duplicates" component={DuplicatesClients} />
      <Route path="/client/to-confirm" component={ClientsToConfirmList} />
      <Route path="/client-to-confirm/:id" component={ClientsImportedDetail} />
      <Route path="/client-to-merge/:id" component={DuplicatesClientDetail} />
      {/* MARK: Invoices */}
      <Route path="/invoices/actions" component={ImminentReminders} />
      <Route path="/invoices/listing" component={InvoicesListing} />
      <Route path="/invoices/to-handle" component={InvoicesToHandle} />
      <Route path="/invoices/detail/:id" component={InvoicesDetail} />
      <Route path="/invoices/to-confirm" component={InvoiceListToConfirm} />
      <Route path="/invoice/to-confirm/:id" component={ImportedInvoiceDetail} />
      <Route path="/invoices/create" component={ManageInvoice} />
      <Route path="/invoices/edit/:id" component={ManageInvoice} />
      <Route path="/invoices/import-pdf" component={ImportPDFInvoice} />
      <Route path="/invoices/:id/:activityId" component={RedirectInvoiceActivity} />
      <Route path="/invoices/:id" component={ToHandleDetail} />
      {/* MARK: Credit notes */}
      <Route path="/credit-notes/listing" component={CreditNotesListing} />
      <Route path="/credit-notes/create" component={ManageCreditNote} />
      <Route path="/credit-notes/edit/:id" component={ManageCreditNote} />
      <Route path="/credit-notes/detail/:id" render={() => <InvoicesDetail credit />} />
      <Route path="/credit-notes/:id/:activityId" component={RedirectInvoiceActivity} />
      <Route path="/credit-notes/:id" render={() => <ToHandleDetail isCreditNote />} />
      {/* MARK: Others */}
      <Route path="/reminders" component={Reminders} />
      <Route path="/activities" component={Activities} />
      <Route path="/transactions/reconciliation" component={Reconciliation} />
      <Route path="/transactions/history" component={TransactionHistory} />
      {!hasActiveConnector ? <Route path="/products" component={ProductsListing} /> : null}
      <Route path="/thirdparty_cases" component={ThirdpartyCasesListing} />
      <Route path="/payment-plans/listing" component={PaymentPlansList} />
      <Route path="/payment-plans/create" component={PaymentPlanForm} />
      <Route path="/payment-plan/:id/edit" component={PaymentPlanEditForm} />
      <Route path="/payment-plan/:id" component={PaymentPlanDetail} />
      <Route path="/mails/:id" component={ExternalMailDetail} />
      <Route path="/mails" component={ExternalMailList} />
      <Route path="/tasks/:id" component={TaskDetail} />
      <Route path="/tasks" component={CustomTasksList} />
      <Route path="/analytics" component={Analytics} />
      <Route path="/reports" component={Reports} />
      <Route path="/terms-and-conditions" component={AcceptTermsOfService} />
      <Route path="/settings" component={SettingsChunk} />
      <Route
        path="/callback"
        render={() => {
          const queryParams = qs.parse(location.search);
          const integration = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
          if (JSON.parse((queryParams.successfulConnection as any) || false)) {
            success({
              data: t(i18nKeys.FORM.SUCCESSFULLY_CONNEXION, {
                text: upperFirst(integration),
              }),
            });
          }

          return (
            <Redirect
              to={`/settings/integration/${integration}?${
                queryParams.errorMessage ? `errorMessage=${queryParams.errorMessage}` : ''
              }`}
            />
          );
        }}
      />
      {/* Legacy kanban route */}
      <Route path="/my-followup">
        <Redirect to="/kanban/invoices" />
      </Route>
      <Redirect to="/dashboard" />
    </Switch>
  );
};
