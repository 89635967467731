import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadNotificationsCount } from 'shared/hooks/use-load-notifications-count';
import { useGetCompany } from 'shared/utils/selectors';

import {
  IconAlarm,
  IconBell,
  IconChartAreaLine,
  IconDashboard,
  IconReport,
  IconUser,
} from '@tabler/icons-react';

import { Menu } from '../types';
import { isMenu } from '../utils';

import { useBuildBankAccountMenu } from './use-build-bank-account-menu';
import { useBuildFollowupMenu } from './use-build-followup-menu';
import { useBuildInvoiceMenu } from './use-build-invoice-menu';
import { useBuildSettingMenu } from './use-build-setting-menu';

export const populateParent = (menu: Menu, parent: Menu | null): Menu => {
  menu.parent = parent;
  menu.items.map((item) => {
    if (!isMenu(item)) return null;
    return populateParent(item, menu);
  });

  return menu;
};

export function useBuildMenuItems(): Menu {
  const { t } = useTranslation();
  const company = useGetCompany();
  const { notificationsCount } = useLoadNotificationsCount();

  const invoiceMenu = useBuildInvoiceMenu();
  const followUpMenu = useBuildFollowupMenu();
  const bankAccountMenu = useBuildBankAccountMenu();
  const settingMenu = useBuildSettingMenu();

  return useMemo(() => {
    const items = [
      {
        path: '/dashboard',
        label: t(i18nKeys.COMMON.DASHBOARD),
        Icon: IconDashboard,
      },
      followUpMenu,
      invoiceMenu,
      {
        path: '/analytics',
        label: 'Analytics',
        Icon: IconChartAreaLine,
        isHidden: !company?.package?.can_use_analytics,
      },
      bankAccountMenu,
      {
        path: '/clients',
        label: t(i18nKeys.CLIENTS),
        Icon: IconUser,
      },
      {
        path: '/reports',
        label: t(i18nKeys.REPORTS.INDEX.TITLE),
        Icon: IconReport,
        isHidden: !company?.package?.can_use_reports,
      },
      {
        path: '/activities',
        label: t(i18nKeys.NAV.ACTIVITY_BOOK),
        Icon: IconBell,
        unreadCount: notificationsCount,
      },
      settingMenu,
    ];

    const menu = {
      parent: null,
      label: 'root',
      Icon: IconAlarm,
      items,
    };

    return populateParent(menu, null);
  }, [company, followUpMenu, bankAccountMenu, invoiceMenu, notificationsCount, settingMenu, t]);
}
