import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import Percentage from 'shared/components/Percentage';
import { ProductLine } from 'types/store/invoice-state';

import styleIdentifiers from './ProductsDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  productLine: ProductLine;
};

export default function ProductsDetailItem({ productLine }: Props) {
  const { t } = useTranslation();

  const {
    current_product_name,
    quantity,
    current_unity_price_htva,
    total_htva,
    discount_rate,
    current_vat_rate,
    total_tvac,
  } = productLine;

  const isInvoice = !!productLine?.linked_invoice_id;

  return (
    <tr className={styles('product-line')}>
      <td className={styles('left')}>{current_product_name}</td>
      <td>{!isInvoice ? quantity : '-'}</td>
      <td>
        <Amount value={current_unity_price_htva} />
      </td>
      <td>
        <Amount value={total_htva} />
      </td>
      <td>{!isInvoice ? <Percentage value={discount_rate} /> : '-'}</td>
      <td>{!isInvoice ? <Percentage value={current_vat_rate} /> : ' -'}</td>
      <td className={styles('right')}>
        <Amount value={total_tvac} />
      </td>
    </tr>
  );
}
