import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Button, ButtonColor } from 'shared/io';

import styleIdentifiers from './ExternalMailDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailReplyActionProps = {
  disabled: boolean;
  onSubmit: () => void;
};

export const ExternalMailReplyAction = ({
  disabled = false,
  onSubmit,
}: ExternalMailReplyActionProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles('info-right')}>
      <Button
        noShadow
        color={ButtonColor.GREEN}
        label={t(i18nKeys.SUBMIT)}
        noMargin
        disabled={disabled}
        small
        onClick={onSubmit}
      />
    </div>
  );
};
