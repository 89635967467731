import classNames from 'classnames/bind';
import { NumericFormat } from 'react-number-format';

import styleIdentifiers from './percentage.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  value: any;
  className?: string;
};

export default function Percentage({ value, className, ...rest }: Props) {
  return (
    <NumericFormat
      className={styles('Percentage', className)}
      value={value}
      suffix="%"
      decimalScale={2}
      // fixedDecimalScale
      displayType="text"
      {...rest}
    />
  );
}
