import PaymentSettingsForm from 'app/Private/Settings/PackageSettings/PaymentSettingsForm';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import { SettingsCard } from '../SettingsCard';

export const PackageSettings = () => {
  const { t } = useTranslation();

  const company = useSelector((state: StoreState) => state.account.company.data);

  const handleUpdate: onSubmitFunction = (values) => {
    accountActions.updateCompany(values);
  };

  return (
    <>
      <PageTitle>{t(i18nKeys.SETTINGS.PACKAGE.ACCOUNT_SUMMARY)}</PageTitle>
      <SettingsCard>
        <PaymentSettingsForm initialValues={company} onSubmit={handleUpdate} />
      </SettingsCard>
    </>
  );
};
