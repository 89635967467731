import { AxiosInstance } from 'axios';
import { store } from 'init/redux-store';
import { mapToObj, omit, sortBy } from 'remeda';
import { Constants } from 'types/store/app-state';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const UserSchema = z
  .object({
    email: z.string().email(),
    firstName: z.string().nonempty(),
    id: z.number(),
    isActive: z.boolean(),
    lastName: z.string().nonempty(),
    profilePicture: z.string().url().nullable(),
    roles: z.array(z.string()),
    hasAcceptedGc: z.boolean(),
    preferences: z.object({
      itemsPerPage: z.number(),
      emails: z.object({
        daily: z.boolean(),
        weekly: z.boolean(),
        notifications: z.boolean(),
      }),
    }),
  })
  .transform((data) => {
    // https://github.com/colinhacks/zod/issues/2203
    const roles = data.roles.includes('admin') ? ['admin'] : [...data.roles];

    return {
      ...omit(data, ['firstName', 'lastName', 'roles']),
      roles,
      isAdmin: data.roles.includes('admin'),
      permissions: mapToObj((store.getState().app.constants as Constants).user_roles, (role) => [
        role.value,
        roles.includes(role.value),
      ]),
      name: {
        full: `${data.firstName} ${data.lastName}`,
        first: data.firstName,
        last: data.lastName,
        initials: `${data.firstName[0]}${data.lastName[0]}`,
      },
    };
  });

const UsersSchema = z.array(UserSchema).transform((users) => sortBy(users, (user) => user.id));

export type User = z.infer<typeof UserSchema>;
type Users = z.infer<typeof UsersSchema>;

export async function loadUsersQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get(
    `/users?${new URLSearchParams({
      showInactive: queryKey[1].loadInactiveUsers.toString(),
      fullResponse: 'true',
    }).toString()}`,
  );

  return UsersSchema.parse(data);
}

export const useLoadUsers = (loadInactiveUsers: boolean = false) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Users, ApiError>({
    queryKey: ['users', { loadInactiveUsers }] as const,
    queryFn: (context) => loadUsersQueryFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<Users, unknown, 'users'>('users', queryResult);
};
