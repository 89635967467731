import { ReactNode, Ref, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import { debounce } from 'lodash-es';
import { FieldError } from 'react-hook-form';
import { validationMessage } from 'shared/utils/validation';
import { OnChangeEvent } from 'types/html-event';

import styleIdentifiers from './Input.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  errorClassName?: string;
  errorMessage?: FieldError;
  inputClassName?: string;
  inputRef?: Ref<HTMLInputElement>;
  label?: ReactNode;
  noBorder?: boolean;
  noError?: boolean;
  noErrorText?: boolean;
  noMargin?: boolean;
  onChange?: Function;
  onFocus?: Function;
  onValueChanged?: Function;
  quickSearch?: boolean;
  register?: any;
  shadow?: boolean;
  timeout?: number;
  withBorder?: boolean;
};

export const Input = ({
  className,
  errorClassName,
  errorMessage,
  inputClassName,
  inputRef,
  label,
  noBorder,
  noError,
  noErrorText,
  noMargin,
  onChange,
  onFocus,
  onValueChanged,
  quickSearch,
  register,
  shadow,
  timeout = 0,
  withBorder,
  ...rest
}: InputProps) => {
  const { t } = useTranslation();
  const handleInputFocus = (event: OnChangeEvent) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const debouncedValue = useRef(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      register?.onChange(e);

      if (onValueChanged) {
        onValueChanged(e.target.value);
      }
    }, timeout),
  ).current;

  useEffect(
    () => () => {
      debouncedValue.cancel();
    },
    [debouncedValue],
  );

  const onSetValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeout) {
      e.persist();

      debouncedValue(e);
    } else {
      register?.onChange(e);

      if (onValueChanged) {
        onValueChanged(e.target.value);
      }
    }
  };

  const showError = !noError && errorMessage;

  // ? The component was built to receive translation keys as the value of incorrectly named errorMessage.message;
  // ? In some cases, we may want to display some field errors that come translated from the backend.
  // ? To achieve this, we try to translate the error message, if it fails, we display the message as it is.
  // ? This is a limitation of react hook form, which only allows us to conform to its builtin FieldError type
  // ? and prevents us form adding, say, a `translatedError` property that we would display as is.
  // ? N.B.: If a key missing a translation is passed, the key itself will be displayed and no error will be raised.
  // ? This is relatively low risk though thanks to the finite amount of error keys that can reach here in the first place
  // ? Paths to improvement:
  // ? 1. Overload the FieldError type to add a `translatedError` property in a d.ts file
  // ? 2. Mantine
  let finalErrorMessage = '';
  try {
    finalErrorMessage = t(
      validationMessage[(errorMessage as FieldError).type] ||
        (errorMessage as FieldError).message ||
        validationMessage.invalid,
    );
  } catch {
    finalErrorMessage = errorMessage?.message ?? '';
  }

  return (
    <div
      className={styles(
        'Input',
        noMargin && 'no-margin',
        className,
        shadow && 'shadow',
        quickSearch && 'with-small-border',
        withBorder && 'with-border',
        showError && 'error',
      )}
    >
      <div
        className={styles(
          'input-wrapper',
          !label && 'no-label',
          showError && 'error',
          showError && errorClassName,
        )}
      >
        {label && <div className={styles('label-input')}>{label}</div>}
        <input
          className={styles(inputClassName, !noBorder && 'bordered')}
          type={rest.type || 'text'}
          onFocus={handleInputFocus}
          ref={inputRef}
          {...rest}
          {...register}
          onChange={onSetValue}
          data-quick-search={quickSearch ? '1' : '0'}
        />
      </div>
      {showError && !noErrorText && (
        <div className={styles('error-message')}>{finalErrorMessage}</div>
      )}
    </div>
  );
};
