import { PropsWithChildren } from 'react';

import { Group } from '@mantine/core';

import { KanbanCard } from './KanbanCard';
import { KanbanColumn } from './KanbanColumn';

interface KanbanProps
  extends PropsWithChildren<{
    minColumnWidth?: string | number;
  }> {}

export const Kanban = ({ children, minColumnWidth = 140 }: KanbanProps) => (
  <Group
    h="100%"
    w="100%"
    wrap="nowrap"
    grow
    gap={12}
    style={{
      overflowX: 'auto',
      '--kanban-min-width':
        typeof minColumnWidth === 'string' ? minColumnWidth : `${minColumnWidth}px`,
    }}
  >
    {children}
  </Group>
);

Kanban.Column = KanbanColumn;
Kanban.Card = KanbanCard;
