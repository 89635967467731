import { i18nKeys, useTranslation } from 'locales';
import { Link } from 'react-router-dom';
import { useLoadAgedBalanceCompact } from 'shared/hooks';

import { Button, LoadingOverlay } from '@mantine/core';
import { IconArrowRight, IconZoomMoney } from '@tabler/icons-react';

import { AnalyticsCard } from '../AnalyticsCard';
import { AgedBalanceCompactChart } from '../charts';

export const AgedBalanceCard = () => {
  const { t } = useTranslation();
  const { agedBalanceCompact, isAgedBalanceCompactLoading } = useLoadAgedBalanceCompact();

  return (
    <AnalyticsCard
      title={t(i18nKeys.ANALYTICS.AGED_BALANCE.TITLE)}
      actions={
        <Button
          component={Link}
          to="/analytics/aged-balance"
          variant="light"
          leftSection={<IconZoomMoney size={20} stroke={1.5} />}
          rightSection={<IconArrowRight size={20} stroke={1.5} />}
        >
          {t(i18nKeys.ANALYTICS.AGED_BALANCE.SEE_BREAKDOWN)}
        </Button>
      }
    >
      {isAgedBalanceCompactLoading ? (
        <LoadingOverlay visible />
      ) : (
        <AgedBalanceCompactChart agedBalanceCompact={agedBalanceCompact!} />
      )}
    </AnalyticsCard>
  );
};
