import classNames from 'classnames/bind';

import styleIdentifiers from './gradientBorder.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = { bottom?: boolean; className?: string };

export default function GradientBorder({ bottom, className }: Props) {
  return <div className={styles('GradientBorder', bottom && 'bottom', className)} />;
}
