import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import DraggableList from 'react-draggable-list';
import { FormProvider, useForm } from 'react-hook-form';
import { reloadCustomView, removeAttributes } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { TableColumn, TableView } from 'types/views';

import { Button, Group, ScrollArea, TextInput } from '@mantine/core';

import { Icon, IconName } from '../Icon';

import styleIdentifiers from './ChooseColumnModal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  tableName: string;
  currentView: TableView;
  callbackAction: Function;
};

export default function ChooseColumnModal({ tableName, currentView, callbackAction }: Props) {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const form = useForm({
    defaultValues: {
      table_columns: currentView.table_columns,
      choose_columns: [] as any,
    },
  });

  const { watch, setValue, reset } = form;

  const formData = watch();

  useEffect(() => {
    appActions.getColumns({
      data: {
        view: tableName,
      },
      callback: ({ data }) => {
        const choose_columns: any[] = [];
        const columns = data.map((item, index) => {
          choose_columns[index] = currentView.table_columns.some(
            (tableColumn) => tableColumn.custom_column_id.toString() === item.id,
          );
          return removeAttributes(item);
        });
        setColumns(columns);
        reset({ choose_columns, table_columns: currentView.table_columns });
      },
    });
  }, []);

  const tableRef = useRef(null);

  const onMoveEnd = (value) => {
    setValue('table_columns', value);
  };

  const tableColumns = watch('table_columns');

  const save = () => {
    const table_columns_attributes = tableColumns.map((tableColumn, index) => ({
      ...tableColumn,
      order: index + 1,
      id: tableColumn.type === 'table_column' ? tableColumn.id : undefined,
    }));

    currentView.table_columns
      .filter((item) => !tableColumns.some((column) => column.id.toString() === item.id))
      .forEach((column) => {
        table_columns_attributes.push({ ...column, _destroy: true } as any);
      });

    appActions.updateViews({
      data: {
        views_attributes: [
          {
            id: currentView.id,
            view_type: currentView.view_type,
            table_columns_attributes,
          },
        ],
      },
      callback: (res) => {
        appActions.getViewsRes(res, 'fulfilled', { view_type: tableName });
        dialogHide(DialogShowId.CUSTOM);
        reloadCustomView(callbackAction);
      },
    });
  };

  const onCheckboxChange = (item, itemIndex) => {
    if (!formData.choose_columns[itemIndex]) {
      tableColumns.push({ ...item, custom_column_id: item.id });
      setValue('table_columns', tableColumns);
    } else {
      const index = tableColumns.findIndex(
        (tableColumn) => tableColumn.custom_column_id.toString() === item.id,
      );
      if (index >= 0) {
        tableColumns.splice(index, 1);
        setValue('table_columns', tableColumns);
      }
    }
    formData.choose_columns[itemIndex] = !formData.choose_columns[itemIndex];
    setValue('choose_columns', formData.choose_columns);
  };

  const removeColumn = (item) => {
    const index = tableColumns.findIndex((tableColumn) => tableColumn.id.toString() === item.id);
    const itemIndex = columns.findIndex(
      (tableColumn) => tableColumn.id.toString() === item.custom_column_id,
    );
    formData.choose_columns[itemIndex] = !formData.choose_columns[itemIndex];
    tableColumns.splice(index, 1);
    setValue('table_columns', tableColumns);
  };

  type DragProps = { item: any; dragHandleProps: any; list: any };

  function DragItem({ item, dragHandleProps }: DragProps) {
    return (
      <div key={item.name} className={styles('draggable-column-item')} {...dragHandleProps}>
        <div className={styles('name-container')}>
          <Icon name={IconName.MOVE_VERTICAL} />

          <div>{item.name_translated}</div>
        </div>
        <div className={styles('field-container')}>
          <div>{t(i18nKeys.COLUMN_TYPE[item.column_type.toUpperCase()])}</div>
          <Icon name={IconName.SIMPLE_REMOVE} onClick={() => removeColumn(item)} />
        </div>
      </div>
    );
  }

  const filteredColumns =
    searchText === ''
      ? columns
      : columns.filter((col) =>
          col.name_translated.toLowerCase().includes(searchText.toLowerCase()),
        );

  return (
    <div className={styles('choose-column-modal')}>
      <div className={styles('body')}>
        <div className={styles('column-available')}>
          <TextInput
            pb="sm"
            placeholder={t(i18nKeys.SEARCH)}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
          <FormProvider {...form}>
            <ScrollArea h="calc(100% - 50px)">
              <div className={styles('recovr-table')}>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className={styles('not-sortable-head')} />
                      </th>
                      <th>
                        <div className={styles('not-sortable-head')}>{t(i18nKeys.COLUMN_NAME)}</div>
                      </th>
                      <th>
                        <div className={styles('not-sortable-head')}>{t(i18nKeys.TYPE_COLUMN)}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Iterate over all columns rather than the filtered ones
                    so that we can still rely on their index to determine which ones are checked */}
                    {columns.map((column, index) => {
                      if (!filteredColumns.some((col) => col.id === column.id)) return null;

                      return (
                        <tr
                          key={column.id}
                          onClick={() => {
                            onCheckboxChange(column, index);
                          }}
                        >
                          <td>
                            <div
                              className={styles(
                                'box',
                                'checkbox',
                                formData.choose_columns[index] && 'checked',
                              )}
                            />
                          </td>
                          <td>{column.name_translated}</td>
                          <td>{t(`COLUMN_TYPE.${column.column_type.toUpperCase()}`)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </ScrollArea>
          </FormProvider>
        </div>
        <div className={styles('displayed-column')} ref={tableRef}>
          <Group pb="sm" justify="end">
            <Button onClick={save}>{t(i18nKeys.SAVE)}</Button>
          </Group>
          <ScrollArea h="calc(100% - 50px)">
            <DraggableList<TableColumn, void, any>
              itemKey="name"
              template={DragItem as any}
              list={tableColumns}
              onMoveEnd={onMoveEnd}
              container={() => tableRef.current || document.body}
            />
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
