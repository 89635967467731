import React, { isValidElement, PropsWithChildren, ReactNode } from 'react';

import { Box, LoadingOverlay, ScrollArea, Stack, useMantineTheme } from '@mantine/core';

export interface KanbanColumnProps
  extends PropsWithChildren<{
    title: ReactNode;
    color: string;
    isLoading?: boolean;
  }> {}

export const KanbanColumn = ({ children, title, color, isLoading = false }: KanbanColumnProps) => {
  const theme = useMantineTheme();
  const arrayChildren = React.Children.toArray(children).filter(isValidElement);
  const cards = arrayChildren.map((child) =>
    // @ts-ignore todo: Fix typing
    React.cloneElement(child, { color }),
  );

  return (
    <Stack
      gap={10}
      h="100%"
      miw="calc(var(--kanban-min-width) + 20px)"
      style={{ borderRadius: '4px' }}
      bg="#ececec"
      pos="relative"
    >
      <LoadingOverlay
        zIndex={1}
        visible={isLoading}
        overlayProps={{ radius: 'sm', color: theme.colors.gray[4], backgroundOpacity: 0.75 }}
      />
      {/* The explicit height prevents the title box from shrinking alongside the adaptive text */}
      <Box p={10} pb={0} mb="xs" h={62}>
        {title}
      </Box>
      <ScrollArea mr={2} scrollHideDelay={0} scrollbarSize={6}>
        {/* This intermediary element provides room for the ScrollArea's scrollbar to display in the gutter without obscuring the content */}
        <Box pl={10} pr={8} pb={16}>
          <Stack gap={8}>{cards}</Stack>
        </Box>
      </ScrollArea>
    </Stack>
  );
};
