import { useUpdateReport } from 'shared/hooks';
import { Report } from 'shared/hooks/use-load-report';

import { Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';

import { NameStep } from '../../CreateReport/steps';

interface EditReportNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  report: Report;
}

export const EditReportNameModal = ({ isOpen, onClose, report }: EditReportNameModalProps) => {
  const { updateReport, isUpdateReportLoading } = useUpdateReport();
  const form = useForm({
    initialValues: {
      name: report.name,
    },
  });

  const handleSave = () => {
    updateReport(
      {
        id: report.id,
        name: form.values.name,
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Modal opened={isOpen} onClose={onClose} title="Edit name">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <NameStep formProps={form.getInputProps('name')} />
        <Group w="100%" justify="end" mt="xl">
          <Button variant="light" color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" loading={isUpdateReportLoading}>
            Save
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
