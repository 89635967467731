import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RouteChildrenProps } from 'react-router';
import { useGetCompany } from 'shared/utils/selectors';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Chift = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();

  const company = useGetCompany()!;

  const { isActive, canActivate, isConnected } = getIntegrationRights(Integration.CHIFT, company);

  useEffect(() => {
    if (!canActivate && !isActive) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, isActive, replace]);

  if (!company?.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  return (
    <article className={styles('chift')}>
      <IntegrationHeader
        name={Integration.CHIFT}
        goBack={goBack}
        isActive={isActive}
        isConnected={isConnected}
      />
      <div style={{ width: '100%' }} />
    </article>
  );
};
