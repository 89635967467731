import { TvaTypes } from 'shared/io/VatInput/VatInput.types';

// todo: Bank transfer communication belongs here too

export const maskVat: {
  [key in TvaTypes]: string;
} = {
  [TvaTypes.BE]: 'BE ____ ___ ___',
  [TvaTypes.FR]: 'FR __ _________',
  [TvaTypes.NL]: 'NL _________ B__',
  [TvaTypes.LU]: 'LU ________',
};
