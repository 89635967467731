import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useForm } from 'react-hook-form';
import AYSModal from 'shared/components/AYSModal';
import { Button, Checkbox } from 'shared/io';
import { isProduction } from 'shared/utils/environment';
import { useGetInvoiceState } from 'shared/utils/selectors';
import { config } from 'store/constants';
import { Id } from 'types';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './confirmBailiffForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  bailiffId: Id;
};

export default function ConfirmBailiffForm({ onSubmit, bailiffId }: Props) {
  const { t, currentLang } = useTranslation();
  const [isConfirmationStep, setIsConfirmationStep] = useState(false);
  const { isLoading } = useGetInvoiceState();

  const {
    register,
    watch,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ shouldUnregister: true });

  const bailiffCgLink = isProduction() ? config.bailiffTerms[bailiffId][currentLang] : '';

  const submit = async () => {
    const isFormValid = await trigger();
    if (isFormValid) {
      const formValues = getValues();
      if (isConfirmationStep || formValues.notContested) {
        onSubmit(formValues);
      } else {
        setIsConfirmationStep(true);
      }
    }
  };

  const toggleConfirmationStep = () => {
    setIsConfirmationStep(!isConfirmationStep);
  };

  return isConfirmationStep ? (
    <AYSModal
      text={t(i18nKeys.FORM.CONFIRM_BAILIFF.AYS_SEND_CONTESTED_INVOICE)}
      onCancel={toggleConfirmationStep}
      onConfirm={submit}
    />
  ) : (
    <form className={styles('ConfirmBailiffForm')}>
      <div className={styles('wrapper')}>
        <div
          className={styles('text')}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: t(i18nKeys.FORM.CONFIRM_BAILIFF.CHECK_INCONTESTED) }}
        />
        <Checkbox
          watch={watch}
          label={t(i18nKeys.FORM.CONFIRM_BAILIFF.INVOICE_UNCONTESTABLE)}
          register={register('notContested')}
        />
        <Checkbox
          watch={watch}
          register={register('user.has_accepted_gc', { required: true })}
          errorMessage={errors.user?.has_accepted_gc}
        >
          <span>
            {t(i18nKeys.FORM.CONFIRM_BAILIFF.READ_AND_APPROVE)}{' '}
            <a href={bailiffCgLink} target="_blank" rel="noopener noreferrer">
              {t(i18nKeys.FORM.GENERAL_CONDITIONS_OF_USE)}
            </a>
          </span>
        </Checkbox>
        <div className={styles('button-wrapper')}>
          <Button
            isLoading={isLoading}
            label={t(i18nKeys.CONFIRM)}
            className={styles('button')}
            onClick={submit}
          />
        </div>
      </div>
    </form>
  );
}
