import React, { PropsWithChildren } from 'react';
import { SendingMedia, StepType, Template } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon } from 'shared/components/Icon';
import { SendingMediaIcon } from 'shared/components/SendingMedium';

import { isTrue } from '../WorkflowForm.utils';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsItemDetailReminderProps = {
  step_type: StepType;
  template: Template;
  sending_media?: SendingMedia;
};

export const WorkflowFormStepsItemDetailReminder = ({
  step_type,
  template,
  sending_media,
}: PropsWithChildren<WorkflowFormStepsItemDetailReminderProps>) => {
  const { t } = useTranslation();

  const {
    attributes: { name },
  } = template;

  return (
    <div className={styles('description')}>
      <div>
        <h3>{name}</h3>
        <p>{t(i18nKeys.TEMPLATE_TYPES[step_type.toUpperCase()])}</p>
      </div>
      {sending_media && (
        <div className={styles('sending_media')}>
          {sending_media.map(({ name: mediaName, force_sending }, i) =>
            i === 0 ? (
              <span className={styles('button')} key={mediaName}>
                <Icon name={SendingMediaIcon[mediaName]} />
              </span>
            ) : (
              <React.Fragment key={mediaName}>
                <span>{t(isTrue(force_sending) ? i18nKeys.AND : i18nKeys.OR)}</span>
                <span className={styles('button')}>
                  <Icon name={SendingMediaIcon[mediaName]} />
                </span>
              </React.Fragment>
            ),
          )}
        </div>
      )}
    </div>
  );
};
