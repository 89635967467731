import { Route, Switch, useHistory } from 'react-router';
import { useGetCompany } from 'shared/utils/selectors';

import { Box } from '@mantine/core';

import { AgedBalance } from './pages/AgedBalance';
import { Overview } from './pages/Overview';

export const Analytics = () => {
  const history = useHistory();
  const company = useGetCompany();

  if (!company?.package.can_use_analytics) {
    history.push('/dashboard');
  }

  return (
    <Box w="80%" mx="auto">
      <Switch>
        <Route path="/analytics/aged-balance" component={AgedBalance} />
        <Route path="/analytics" component={Overview} />
      </Switch>
    </Box>
  );
};
