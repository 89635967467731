import { useState } from 'react';
import { PlanType } from 'api/models';
import ListDebtCollectionCustomization from 'app/Private/Templates/ListDebtCollectionCustomization';
import { WorkflowsList } from 'app/Private/Workflows/';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RouteComponentProps, useHistory } from 'react-router';
import Card from 'shared/components/Card';
import Tabs from 'shared/components/Tabs';
import { PageTitle } from 'shared/layout/PageTitle';

import styleIdentifiers from './WorkflowsSettings.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const WorkflowsSettings = ({ location }: RouteComponentProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const tabItems = [
    {
      title: t(i18nKeys.BATCH.CATEGORIES.RECOVERY),
      path: '/settings/workflows/recovery-plans',
      children: <ListDebtCollectionCustomization />,
    },
    {
      title: t(i18nKeys.PAYMENT_PLAN.TITLE.ONE),
      path: `/settings/workflows/${PlanType.payment}`,
      children: <WorkflowsList planType={PlanType.payment} />,
    },
  ];

  const [tabIndex, setTabIndex] = useState(tabItems.map((e) => e.path).indexOf(location.pathname));

  return (
    <div className={styles('workflows')}>
      <PageTitle>{t(i18nKeys.AUTOMATED_PLANS)}</PageTitle>
      <Tabs
        className={styles('workflows__tabs')}
        tabIndex={tabIndex}
        items={tabItems.map((e) => (
          <h3>{e.title}</h3>
        ))}
        noBody
        onChange={(e) => {
          setTabIndex(e);
          history.push(tabItems[e].path);
        }}
      />
      {tabItems.map(
        ({ title, children }, i) =>
          tabIndex === i && (
            <Card key={`tab-${title}`} noRadiusTop noHead>
              {children}
            </Card>
          ),
      )}
    </div>
  );
};
