import { Reducer, useEffect, useReducer } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router-dom';
import { isPrivateEnvironment, isPublicEnvironment } from 'shared/utils/environment';
import { reducerState } from 'shared/utils/view';
import { DialogShowId, showDialog } from 'store/view/view.actions';

import AYSModal from './AYSModal';

const INIT_STATE = {
  isActive: false,
  nextLocation: undefined,
  blockNavigation: undefined,
};

type Props = {
  when: boolean;
  disableNative?: boolean;
  dialogProps?: any;
  redirect?: Function;
  onSaveAndQuit?: Function;
  canChangePage?: boolean;
};

type State = Reducer<
  {
    nextLocation?: Location;
    isActive: boolean;
    unblock?: Function;
    blockNavigation?: boolean;
  },
  any
>;

let unblock;

function NavigationPromptInner({
  disableNative,
  canChangePage,
  when,
  dialogProps = {},
  redirect,
  onSaveAndQuit,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useReducer<State>(reducerState, INIT_STATE);

  const onCancel = () => {
    setState({ ...INIT_STATE });
  };

  const onBeforeUnload = (e) => {
    // custom message doesn't work for security reason
    const msg = t(i18nKeys.AYS.EXIT_PAGE);
    e.returnValue = msg;
    return msg;
  };

  const block = (nextLocation, action): string | false | void => {
    if (isPrivateEnvironment()) return undefined;

    if (!canChangePage && when) {
      setState({
        nextLocation,
        isActive: true,
      });

      showDialog({
        id: DialogShowId.CONFIRM,
        keepMountOnExit: true,
        [onSaveAndQuit ? 'medium' : 'small']: true,
        title: t(i18nKeys.ATTENTION),
        children: (
          <AYSModal
            text={t(i18nKeys.AYS.EXIT_PAGE)}
            onCancel={onCancel}
            onConfirm={() => navigateToNextLocation(nextLocation, action)}
            customAction={
              onSaveAndQuit
                ? () => {
                    onSaveAndQuit!(() => {
                      navigateToNextLocation(nextLocation, action);
                    });
                  }
                : undefined
            }
            confirmButtonText={t(onSaveAndQuit ? 'FORM.QUIT_WITHOUT_SAVING' : 'CONFIRM')}
            customButtonText={t(i18nKeys.FORM.SAVE_AND_QUIT)}
          />
        ),
        ...dialogProps,
      });
    }

    return canChangePage || (!when as any);
  };

  useEffect(() => {
    if (when !== state.blockNavigation) {
      unblock = history.block(block);
      setState({
        blockNavigation: when,
      });

      if (!disableNative) {
        window.addEventListener('beforeunload', onBeforeUnload);
      }
    }

    return () => {
      unblock?.();
      if (!disableNative) {
        window.removeEventListener('beforeunload', onBeforeUnload);
      }
    };
  }, [when]);

  useEffect(() => {
    if (redirect) {
      unblock?.();
      redirect();
    }
  }, [redirect]);

  const navigateToNextLocation = (nextLocation, action) => {
    const currentAction: string = {
      POP: 'goBack',
      PUSH: 'push',
      REPLACE: 'replace',
    }[action || 'PUSH'];

    if (!nextLocation) nextLocation = { pathname: '/' };
    unblock!();
    if (action === 'goBack') {
      // Because there is asynchronous time between calling history.goBack()
      // and history actually changing, we need to set up this temporary callback
      // -- if we tried to run this synchronously after calling history.goBack(),
      // then navigateToNextLocation would be triggered again.
      const unlisten = history.listen(() => {
        unlisten();
        setState({
          ...INIT_STATE,
        });
      });

      history.goBack();
    } else history[currentAction](nextLocation);
  };

  return null;
}

export default function NavigationPrompt(props: Props) {
  return isPublicEnvironment() ? <NavigationPromptInner {...props} /> : null;
}
