/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type ExternalMailStatus = (typeof ExternalMailStatus)[keyof typeof ExternalMailStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalMailStatus = {
  processed: 'processed',
  unprocessed: 'unprocessed',
} as const;
