import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { isDefined } from 'remeda';
import CustomTable from 'shared/components/CustomTable';
import { useLoadNotificationsCount } from 'shared/hooks';
import { Button } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { StoreState } from 'types/storeTypes';

import { useQueryClient } from '@tanstack/react-query';

import ActivitiesItem from './ActivitiesItem';
import ActivityFiltersForm from './ActivityFiltersForm';

const EnhancedTable = CustomTable(ActivitiesItem, ActivityFiltersForm);

export default function Activities() {
  const { t } = useTranslation();
  const activity = useSelector((state: StoreState) => state.account.activity);
  const { notificationsCount } = useLoadNotificationsCount();
  const queryClient = useQueryClient();

  if (!isDefined(activity)) return null;

  const TABLE_HEADERS = [
    {
      title: t(i18nKeys.ACTIVITIES.AUTHOR),
      key: 'author',
      width: 120,
    },
    {
      title: t(i18nKeys.DATE),
      key: 'date',
      width: 150,
    },
    {
      title: t(i18nKeys.ACTIVITIES.DETAIL),
      key: 'description',
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 180,
    },
  ];

  const reload = () => {
    accountActions.activityPage(qs.parse(location.search));
    queryClient.invalidateQueries({ queryKey: ['notifications', 'count'] });
  };

  const seenAll = () => {
    accountActions.activitySeenAll({
      callback: reload,
    });
  };

  return (
    <EnhancedTable
      title={t(i18nKeys.NAV.ACTIVITY_BOOK)}
      loadFunc={accountActions.activityPage}
      loading={activity.loading}
      loaded={activity.loaded}
      pagination={activity.metadata?.pagination}
      headers={TABLE_HEADERS}
      items={activity.pages}
      noCheckbox
      actions={
        notificationsCount !== 0 ? (
          <Button label={t(i18nKeys.ACTIVITIES.MARK_ALL_AS_READ)} noMargin onClick={seenAll} />
        ) : null
      }
    />
  );
}
