import { useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { useIsMobile } from 'shared/hooks/utils';

import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Text } from '@mantine/core';
import { IconLogout, IconPencilMinus } from '@tabler/icons-react';

import { ProfileButton } from './ProfileButton';
import { TenantSwitcher } from './TenantSwitcher';

const dropdownStyles = {
  dropdown: {
    borderRadius: 8,
    borderColor: '#EDEDED',
    borderWidth: 1,
    marginLeft: 20,
    boxShadow: '4px 8px 12px -2px rgba(0, 0, 0, 0.12)',
  },
};

export default function UserMenuButton({}) {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const { logout } = useAuth0();

  const [isOpened, setIsOpened] = useState(false);

  return (
    <Menu
      shadow="md"
      position={isMobile ? 'top' : 'right-end'}
      opened={isOpened}
      onChange={setIsOpened}
    >
      <Menu.Target>
        <ProfileButton isOpened={isOpened} />
      </Menu.Target>
      <Menu.Dropdown w={300} styles={dropdownStyles}>
        <Menu.Label>{t(i18nKeys.HEADER.USER_ACTIONS.SPACES)}</Menu.Label>
        <TenantSwitcher />
        <Menu.Divider />
        <Menu.Item
          onClick={() => history.push('/settings/user')}
          leftSection={<IconPencilMinus stroke="1.5" size={16} color="#666666" />}
        >
          <Text>{t(i18nKeys.HEADER.USER_ACTIONS.EDIT_PROFILE)}</Text>
        </Menu.Item>
        <Menu.Item
          onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })}
          leftSection={<IconLogout stroke="1.5" size={16} />}
          color="#EC5962"
        >
          <Text>{t(i18nKeys.HEADER.USER_ACTIONS.LOGOUT)}</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
