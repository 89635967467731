import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { isDefined } from 'remeda';

const visitsAtom = atomWithImmer<Record<string, boolean>>({});

export function useIsFirstVisit(pageName: string) {
  const [visits, setVisits] = useAtom(visitsAtom);
  const key = `visited-${pageName}`;

  useEffect(
    () => () => {
      setVisits((draft) => {
        draft[key] = true;
        return draft;
      });
    },
    [],
  );

  if (!isDefined(visits[key])) return true;

  return false;
}
