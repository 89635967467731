import { Currency, PaymentSteps } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { formattedDate } from 'shared/utils/view';

import styleIdentifiers from '../PaymentPlanDetailDebtor.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  currency: Currency;
  paymentSteps: PaymentSteps[];
};

export default function PaymentStepsDebtor({ paymentSteps, currency }: Props) {
  const { t, currentLang } = useTranslation();

  const renderStep = (date, remainingBalance) => (
    <div className={styles('key-text')}>
      <div className={styles('attribute')}>{formattedDate(date, currentLang)} </div>
      <div className={styles('value')}>
        <Amount localizedValue={remainingBalance} value={remainingBalance} suffix={currency} />
      </div>
    </div>
  );

  return (
    <>
      <h3>{t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.PAYMENT_STEPS)}</h3>
      {paymentSteps.map(({ attributes: { due_date, remaining_balance } }) => (
        <>{renderStep(due_date, remaining_balance)}</>
      ))}
    </>
  );
}
