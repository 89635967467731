import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export const CreateReportSchema = z.object({
  viewId: z.string(),
  name: z.string(),
  emails: z.array(z.string()),
  periodicity: z.object({
    type: z.union([z.literal('weekly'), z.literal('monthly')]),
    days: z.array(z.number()),
  }),
});

export type CreateReportVariables = z.infer<typeof CreateReportSchema>;

export async function createReportFn(
  axiosInstance: Promise<AxiosInstance>,
  data: CreateReportVariables,
) {
  const instance = await axiosInstance;

  return instance.post('reports/export_tasks', CreateReportSchema.parse(data));
}

export function useCreateReport() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, CreateReportVariables>({
    mutationFn: (data) => createReportFn(axiosInstance, data),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['reports'],
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    CreateReportVariables,
    'createReport'
  >('createReport', mutation);
}
