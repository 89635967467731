import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor } from 'shared/io';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { transactionActions } from 'store/transaction/transaction.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { CreditNote } from 'types/store/credit-note-state';
import { ManualPayment, MatchedPayment } from 'types/store/invoice-state';

import styleIdentifiers from './UnmatchPayment.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  reload: Function;
  creditNote: CreditNote;
};

export default function UnmatchCreditNote({ reload, creditNote }: Props) {
  const { t } = useTranslation();

  const unmatchInvoice = (matchedPayment: MatchedPayment) => {
    creditNoteActions.update({
      data: {
        id: creditNote.id,
        unlink_invoice: matchedPayment.invoice_attributes.id,
      },
      callback: () => {
        dialogHide(DialogShowId.CUSTOM);
        reload();
      },
    });
  };

  const cancelSettelment = (manualPayment: ManualPayment) => {
    transactionActions.unmatchManualPayment({
      id: manualPayment.id,
      data: { id: manualPayment.id },
      callback: () => {
        reload();
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  const { matched_payments_attributes, manual_payments } = creditNote;

  return (
    <div className={styles('UnmatchPayment')}>
      <div className="recovr-table">
        <table>
          <thead>
            <tr>
              <th>
                <div>{t(i18nKeys.MODALITY)}</div>
              </th>
              <th>
                <div>{t(i18nKeys.DEBTOR.INVOICE_REFERENCE)}</div>
              </th>
              <th>
                <div>{t(i18nKeys.AMOUNT)}</div>
              </th>
              <th>
                <div>{t(i18nKeys.FORM.ACTIONS)}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {matched_payments_attributes.map((matchedPayment: MatchedPayment) => (
              <tr key={matchedPayment.id}>
                <td>{t(i18nKeys.INVOICE.AMOUNT_LINKED)} </td>
                <td>{matchedPayment.invoice_attributes.reference}</td>
                <td>
                  <Amount
                    value={matchedPayment.amount}
                    suffix={matchedPayment.invoice_attributes.currency}
                  />
                </td>
                <td className="actions">
                  <Button
                    className={styles('delete-payment')}
                    label={t(i18nKeys.DELETE)}
                    onClick={() => unmatchInvoice(matchedPayment)}
                    small
                    noMargin
                    color={ButtonColor.GREY}
                    iconLeft={IconName.TRASH_SIMPLE}
                  />
                </td>
              </tr>
            ))}
            {manual_payments.map((manualPayment: ManualPayment) => (
              <tr key={manualPayment.id}>
                <td>{t(i18nKeys.DEBTOR.AMOUNT_LINKED)}</td>
                <td>-</td>
                <td>
                  <Amount value={manualPayment.amount} suffix={manualPayment.currency} />
                </td>
                <td className="actions">
                  <Button
                    className={styles('delete-payment')}
                    label={t(i18nKeys.DELETE)}
                    onClick={() => cancelSettelment(manualPayment)}
                    small
                    noMargin
                    color={ButtonColor.GREY}
                    iconLeft={IconName.TRASH_SIMPLE}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
