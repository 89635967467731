import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';

import { IconInvoice, IconLayoutKanban, IconUsers } from '@tabler/icons-react';

import { Menu } from '../types';

export function useBuildKanbanMenu(): Menu {
  const { t } = useTranslation();

  return useMemo(() => {
    const items = [
      {
        path: '/kanban/invoices',
        label: t(i18nKeys.KANBAN.INVOICE.TITLE),
        Icon: IconInvoice,
      },
      {
        path: '/kanban/debtors',
        label: t(i18nKeys.KANBAN.DEBTOR.TITLE),
        Icon: IconUsers,
      },
    ];

    return {
      parent: null,
      label: t(i18nKeys.NAV.SUMMARY_TABLE),
      Icon: IconLayoutKanban,
      items,
    };
  }, [t]);
}
