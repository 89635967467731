import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const BalanceValueSchema = z.preprocess((val) => Number(val), z.number());

const BalanceSchema = z.object({
  '0': BalanceValueSchema,
  '30': BalanceValueSchema,
  '60': BalanceValueSchema,
  '90': BalanceValueSchema,
  notDue: BalanceValueSchema,
});

const AgedBalanceOverTimeSchema = z.object({
  computedAt: z.string(),
  isComputing: z.boolean(),
  data: z.record(
    z.object({
      credits: BalanceSchema,
      debits: BalanceSchema,
    }),
  ),
});

export type AgedBalanceOverTime = z.infer<typeof AgedBalanceOverTimeSchema>;

export async function loadAgedBalanceOverTimeFn(
  axiosInstance: Promise<AxiosInstance>,
  { queryKey },
): Promise<AgedBalanceOverTime> {
  const instance = await axiosInstance;

  const { data } = await instance.get('/analytics/aged_balances/stacked', {
    params: { view_id: queryKey[2] },
  });

  return AgedBalanceOverTimeSchema.parse(data);
}

export function useLoadAgedBalanceOverTime({ viewId }: { viewId: string | null }) {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<AgedBalanceOverTime, ApiError>({
    queryKey: ['aged-balance', 'over-time', viewId],
    queryFn: (context) => loadAgedBalanceOverTimeFn(axiosInstance, context),
    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<AgedBalanceOverTime, ApiError, 'agedBalanceOverTime'>(
    'agedBalanceOverTime',
    queryResult,
  );
}
