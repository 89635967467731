import { call, crud, download, downloadBlob } from '../shared/utils/api';

export const api = {
  custom: (options) => call(options.url, options.method, options),
  onBoarding: {
    stripe: (options) => call('/private_api/onboarding/stripe', 'POST', options),
    noPayment: (options) => call('/private_api/onboarding/no_payment', 'POST', options),
    codaboxToken: (options) => call('/private_api/onboarding/codabox', 'POST', options),
    codaboxRequest: (options) =>
      call('/private_api/onboarding/codabox/request_token', 'POST', options),
    ponto: (options) => call('/private_api/onboarding/ponto', 'POST', options),
    bankAccount: (options) => call('/private_api/onboarding/company_bank_account', 'POST', options),
    debtCollectionSettings: (options) =>
      call('/private_api/onboarding/company_settings', 'POST', options),
    onBoardingFiles: (options) =>
      call('/private_api/onboarding/company_files', 'POST', {
        ...options,
        fileUpload: true,
      }),
    invoicing_settings: (options) =>
      call('/private_api/onboarding/invoicing_settings', 'POST', options),
    horus: (options) => call('/private_api/onboarding/horus', 'POST', options),
    horusFetchCompanies: (options) =>
      call('/private_api/onboarding/horus/fetch_companies', 'GET', options),
    teamleader: (options) => call('/private_api/onboarding/teamleader', 'POST', options),
    exact: (options) => call('/private_api/onboarding/exact', 'POST', options),
    exactFetchCompanies: (options) =>
      call('/private_api/onboarding/exact/fetch_companies', 'GET', options),
    yuki: (options) => call('/private_api/onboarding/yuki', 'POST', options),
    twikey: (options) => call('/private_api/onboarding/twikey', 'POST', options),
    billit: (options) => call('/private_api/onboarding/billit', 'POST', options),
    uploadTerms: (options) =>
      call('/private_api/onboarding/terms_and_conditions_files', 'POST', options),
  },
  settings: {
    constants: (options) => call('/private_api/constants', 'GET', options),
    fetchAddress: (options) => call('/private_api/fetch_address', 'POST', options),
    updateBillitSettings: (options) => call('/private_api/billit_configurations', 'PUT', options),
    updateYukiSettings: (options) => call('/private_api/yuki_configurations', 'PUT', options),
    updateHorusSettings: (options) => call('/private_api/horus_configurations', 'PUT', options),
    updateDbasicsSettings: (options) => call('/private_api/dbasics_configurations', 'PUT', options),
    addTwikey: (options) => call('/private_api/billing/twikey', 'POST', options),
    updateCompany: (options) =>
      call('/private_api/company_configurations/company_profile', 'PUT', options),
    updateTeamleaderSettings: (options) =>
      call('/private_api/teamleader_configurations', 'PUT', options),
    updateInvoiceConfigurations: (options) =>
      call('/private_api/company_configurations/invoice_configuration', 'PUT', options),
    editDebtCollection: (options) =>
      call('/private_api/company_configurations/company_debt_collection', 'PUT', options),
    updateExactSettings: (options) => call('/private_api/exact_configurations', 'PUT', options),
    exactFetchCompanies: (options) =>
      call('/private_api/exact_configurations/fetch_companies', 'GET', options),
    horusFetchCompanies: (options) =>
      call('/private_api/horus_configurations/fetch_companies', 'GET', options),
    codaboxToken: (options) => call('/private_api/codabox', 'POST', options),
    codaboxRequest: (options) => call('/private_api/codabox/request_token', 'POST', options),
    ponto: (options) => call('/private_api/ponto', 'POST', options),
    editBankingInfo: (options) => call('/private_api/billing/stripe', 'PUT', options),
    setDigitealConfiguration: (options) =>
      call('/private_api/digiteal_configurations', 'PUT', options),
    resetSecretId: (options) => call('/private_api/api_secrets', 'POST', options),
    askUpgradePackage: (options) => call('/private_api/api_subscriptions', 'POST', options),
    getDigitealCGV: (options) => call('/private_api/digiteal_tos', 'GET', options),
    syncIntegration: (options) => call('/private_api/accounting_synchronization', 'POST', options),
  },
  session: {
    login: (options) => call('/auth/sign_in', 'POST', options),
    logout: (options) => call('/auth/sign_out', 'DELETE', options),
    resetPassword: (options) => call('/auth/password', 'POST', options),
    getTokenToResetPassword: (options) =>
      call('/auth/password/edit?config=default', 'GET', options),
    editPassword: (options) => call('/auth/password', 'PUT', options),
  },
  company: {
    detail: (options) => call('/private_api/company_configurations', 'GET', options),
    update: (options) => call('/private_api/company_configurations', 'PUT', options),
    updateCompanyFiles: (options) =>
      call('/private_api/company_configurations/company_files_upload', 'PUT', options),
    getTerms: (options) =>
      downloadBlob(
        `/private_api/company_configurations/terms_and_conditions_files?=${options.id}`,
        'POST',
        options,
      ),
    uploadTerms: (options) =>
      call(
        `/private_api/company_configurations/terms_and_conditions_files/${options.id}`,
        'PUT',
        options,
      ),
  },
  colleague: {
    ...crud('/private_api/colleagues'),
    add: (options) => call('/private_api/users/invite_colleague', 'POST', options),
  },
  signup: {
    info: (options) => call('/auth/invitation', 'PUT', options),
  },
  activity: {
    list: (options) => call('/private_api/activities', 'GET', options),
    detail: (options) => call(`/private_api/activities/${options.id}`, 'GET', options),
    markAsRead: (options) =>
      call(`/private_api/activities/${options.id}/mark_as_read`, 'POST', options),
    markAllAsRead: (options) => call('/private_api/activities/mark_as_read', 'POST', options),
  },
  account: {
    kanban: (options) => call('/private_api/kanban', 'GET', options),
    kanbanColumn: (options) => call(`/private_api/kanban/${options.id}`, 'GET', options),
  },
  product: {
    ...crud('/private_api/products'),
  },
  client: {
    ...crud('/private_api/debtors'),
    addComment: (options) => call(`/private_api/debtors/${options.id}/comments`, 'POST', options),
    assignToAccManager: (options) =>
      call(`/private_api/debtors/${options.id}/assign_debtor`, 'POST', options),
    assignMultipleToUser: (options) => call('/private_api/debtors/assign_debtors', 'POST', options),
    getFinancialData: (options) =>
      call(`/private_api/debtors/${options.id}/balance_detail`, 'GET', options),
    pauseDebtor: (options) =>
      call(`/private_api/debtors/${options.id}/pause_debtor`, 'POST', options),
    deleteMultiple: (options) => call('/private_api/debtors/destroy_multiple', 'POST', options),
    pauseMultiple: (options) => call('/private_api/debtors/pause_debtors', 'POST', options),
    batchAction: (options) => call('/private_api/debtors/batch', 'POST', options),
    import: {
      getAllToConfirm: (options) => call('/private_api/import_debtors', 'GET', options),
      getToConfirm: (options) => call(`/private_api/import_debtors/${options.id}`, 'GET', options),
      validateAll: (options) => call('/private_api/import_debtors', 'PUT', options),
      deleteToConfirm: (options) =>
        call(`/private_api/import_debtors/${options.id}`, 'DELETE', options),
    },
  },
  transactions: {
    getReconciliation: (options) => call('/private_api/manual_reconciliation', 'GET', options),
    manualReconciliation: (options) => call('/private_api/manual_reconciliation', 'POST', options),
    payment: (options) => call('/private_api/payments', 'GET', options),
    ignorePayments: (options) => call('/private_api/payments/ignore', 'POST', options),
    unignorePayments: (options) => call('/private_api/payments/unignore', 'POST', options),
    unmatchManualPayment: (options) =>
      call(`/private_api/unmatch_manual_payments/${options.id}`, 'PUT', options),
    unmatchExternalPayment: (options) =>
      call(`/private_api/unmatch_external_payments/${options.id}`, 'PUT', options),
  },
  creditNote: {
    ...crud('/private_api/credit_notes'),
    send: (options) =>
      call(`/private_api/credit_notes/${options.id}/send_credit_note_to_debtor`, 'POST', options),
    activity: (options) => call(`/private_api/invoices/${options.id}/activities`, 'GET', options),
    settle: (options) =>
      call(`/private_api/credit_notes/${options.id}/credit_note_settlement`, 'POST', options),
    batchAction: (options) => call('/private_api/credit_notes/batch', 'POST', options),
    invoiceSearch: (options) => call('/private_api/credit_note/invoices', 'GET', options),
    updateOriginalFile: (options) =>
      call(`/private_api/credit_notes/${options.id}/update_pdf`, 'PUT', options),
  },
  invoice: {
    ...crud('/private_api/invoices'),
    deleteMultiple: (options) => call('/private_api/invoices/destroy_multiple', 'POST', options),
    import: {
      ubl: (options) => call('/private_api/ubl', 'POST', options),
      pdf: (options) => call('/private_api/invoice_with_pdf', 'POST', options),
      csv: (options) => call('/private_api/import_invoices', 'POST', options),
      getAllToConfirm: (options) => call('/private_api/import_invoices', 'GET', options),
      getToConfirm: (options) => call(`/private_api/import_invoices/${options.id}`, 'GET', options),
      validateAll: (options) => call('/private_api/import_invoices', 'PUT', options),
      deleteToConfirm: (options) =>
        call(`/private_api/import_invoices/${options.id}`, 'DELETE', options),
    },
    additionalFile: {
      detail: (options) =>
        call(`/private_api/invoices/${options.id}/additional_files`, 'GET', options),
      add: (options) => call('/private_api/additional_files', 'POST', options),
      delete: (options) => call(`/private_api/additional_files/${options.id}`, 'DELETE', options),
    },
    debtorReaction: {
      qrCode: (options) => call(`/private_api/invoices/${options.id}/qr_code`, 'GET', options),
      getUnpaid: (options) =>
        call(`/private_api/invoices/${options.id}/debtor_reaction_balance`, 'GET', options),
      comment: (options) =>
        call(`/private_api/invoices/${options.id}/debtor_reaction`, 'POST', options),
      getInvoices: (options) =>
        call(`/private_api/invoices/${options.id}/debtor_invoices`, 'GET', options),
      getCreditNotes: (options) =>
        call(`/private_api/invoices/${options.id}/debtor_credit_notes`, 'GET', options),
      getGroupedInvoicePaymentInfo: (options) =>
        call(
          `/private_api/invoices/${options.id}/debtor_digiteal_grouped_payment`,
          'POST',
          options,
        ),
    },
    updateOriginalFile: (options) =>
      call(`/private_api/invoices/${options.id}/update_pdf`, 'PUT', options),
    toHandle: (options) => call('/private_api/actionable_invoices', 'GET', options),
    document: (options) =>
      downloadBlob(`/private_api/invoices/${options.id}/invoice_document`, 'POST', options),
    previewTemplate: (options) =>
      call(`/private_api/invoices/${options.id}/preview_template`, 'GET', options),
    setSendingOptions: (options) =>
      call(`/private_api/invoices/${options.id}/sending_options`, 'PUT', options),
    publicDocument: (options) =>
      download(
        `/private_api/invoices/${options.id}/document?additional_file_id=${options.data.additional_file_id}&token=${options.data.token}&document_type=${options.data.document_type}`,
        'GET',
        options,
      ),
    copy: (options) => call(`/private_api/invoices/${options.id}/invoice_copy`, 'POST', options),
    assignToAccManager: (options) =>
      call(`/private_api/invoices/${options.id}/assign_invoice`, 'POST', options),
    getRecoveryPlans: (options) =>
      call('/private_api/invoices/invoices_recovery_plan', 'GET', options),
    setRecoveryPlan: (options) =>
      call(`/private_api/invoices/${options.id}/invoices_recovery_plan`, 'POST', options),
    postponable: (options) => call('/private_api/postponable_invoices', 'GET', options),
    postpone: (options) => call(`/private_api/invoices/${options.id}/postpone`, 'POST', options),
    forceReminder: (options) => call('/private_api/force_reminders', 'POST', options),
    postponeMultiple: (options) => call('/private_api/postponable_invoices', 'POST', options),
    activity: (options) => call(`/private_api/invoices/${options.id}/activities`, 'GET', options),
    send: (options) => call(`/private_api/invoices/${options.id}/send_to_debtor`, 'POST', options),
    getGroupedInvoices: (options) =>
      call(`/private_api/invoices/${options.id}/grouped_invoices_data`, 'GET', options),
    comment: (options) => call(`/private_api/invoices/${options.id}/comments`, 'POST', options),
    conversationMessage: (options) =>
      call(`/private_api/invoice_feedback/${options.id}/conversation_message`, 'POST', options),
    addPayment: (options) =>
      call(`/private_api/invoices/${options.id}/manual_payments`, 'POST', options),
    // mark as
    paid: (options) => call(`/private_api/invoices/${options.id}/mark_as_paid`, 'POST', options),
    lost: (options) => call(`/private_api/invoices/${options.id}/lose_invoice`, 'POST', options),
    disputed: (options) =>
      call(`/private_api/invoices/${options.id}/mark_as_disputed`, 'POST', options),
    pause: (options) => call(`/private_api/invoices/${options.id}/update_pause`, 'POST', options),
    batchAction: (options) => call('/private_api/invoices/batch', 'POST', options),
    // Actionable apis
    ignoreLateFees: (options) =>
      call(`/private_api/invoices/${options.id}/ignore_late_fees`, 'POST', options),
    additionalReminder: (options) =>
      call(`/private_api/invoices/${options.id}/additional_reminder`, 'POST', options),
    callcenter: (options) =>
      call(`/private_api/invoices/${options.id}/callcenter`, 'POST', options),
    lawyer: (options) => call(`/private_api/invoices/${options.id}/lawyer`, 'POST', options),
    closeLawyer: (options) =>
      call(`/private_api/invoices/${options.id}/lawyer/close`, 'POST', options),
    createFormalNotice: (options) =>
      call(`/private_api/invoices/${options.id}/formal_notice`, 'POST', options),
    bailiff: (options) => call(`/private_api/invoices/${options.id}/bailiff`, 'POST', options),
    closeBailiff: (options) =>
      call(`/private_api/invoices/${options.id}/bailiff/close`, 'POST', options),
    markAsToBeProccesed: (options) =>
      call(`/private_api/invoices/${options.id}/mark_as_to_be_processed`, 'POST', options),
    sendToThirdParty: (options) =>
      call(`/private_api/invoices/${options.id}/send_to_third_party`, 'POST', options),
  },
  template: {
    ...crud('/private_api/templates'),
    duplicate: (options) =>
      call(`/private_api/templates/${options.id}/template_copy`, 'POST', options),
    preview: (options) =>
      (options.download ? downloadBlob : call)(
        `/private_api/templates/${options.id}/template_preview`,
        'POST',
        options,
      ),
  },
  recoveryPlan: {
    ...crud('/private_api/recovery_plans'),
    duplicate: (options) =>
      call(`/private_api/recovery_plans/${options.id}/recovery_plan_copy`, 'POST', options),
    switchPlansInvoices: (options) => call('/private_api/recovery_plan_switch', 'POST', options),
    getPlansInvoices: (options) => call('/private_api/recovery_plan_switch', 'GET', options),
  },
  customVariable: {
    ...crud('/private_api/custom_variables'),
  },
  thirdpartyCase: {
    list: (options) => call('/private_api/thirdparty_cases', 'GET', options),
    detail: (options) => call(`/private_api/thirdparty_cases/${options.id}`, 'GET', options),
  },
  twikey: {
    login: (options) =>
      call('https://api.twikey.com/creditor', 'POST', {
        ...options,
        raw: true,
      }),
    invite: (options) =>
      call('https://api.twikey.com/creditor/invite', 'POST', {
        ...options,
        raw: true,
      }),
  },
  tables: {
    getView: (options) => call(`/private_api/views/${options.id}`, 'GET', options),
    getViews: (options) => call('/private_api/views', 'GET', options),
    updateViews: (options) => call('/private_api/views', 'POST', options),
    getColumnsAvailable: (options) => call('/private_api/custom_columns', 'GET', options),
    getFiltersAvailable: (options) => call('/private_api/custom_filters', 'GET', options),
    getFiltersName: (options) => call('/private_api/filter_names', 'GET', options),
    export: (options) => call('/private_api/download_resources', 'POST', options),
  },
};

export default api;
