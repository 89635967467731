import { fork } from 'redux-saga/effects';

import account from './account/account.sagas';
import app from './app/app.sagas';
import client from './client/client.sagas';
import colleague from './colleague/colleague.sagas';
import creditNote from './creditNote/creditnote.sagas';
import invoice from './invoice/invoice.sagas';
import onBoarding from './onBoarding/onBoarding.sagas';
import product from './product/product.sagas';
import settings from './settings/settings.sagas';
import thirdpartyCase from './thirdpartyCase/thirdpartycase.sagas';
import transaction from './transaction/transaction.sagas';
import view from './view/view.sagas';

export function* rootSagas() {
  yield fork(app);
  yield fork(account);
  yield fork(client);
  yield fork(colleague);
  yield fork(creditNote);
  yield fork(invoice);
  yield fork(settings);
  yield fork(product);
  yield fork(transaction);
  yield fork(onBoarding);
  yield fork(view);
  yield fork(thirdpartyCase);
}
