import { useTranslation } from 'locales';
import Logo from 'shared/components/Logo';

import { Card, Center, Group, Stack, Text, Title } from '@mantine/core';

export const MaintenancePage = () => (
  <Center h="100%">
    <Stack>
      <Title>Maintenance</Title>
      <Card miw="25%" p="xl" shadow="sm">
        <Text>
          Recovr is undergoing scheduled maintenance. <br />
          We are striving to keep the interruption as short as possible.
        </Text>
      </Card>
      <Group justify="end">
        <Logo type="blue" width={150} />
      </Group>
    </Stack>
  </Center>
);
