import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { productActions, productConstants as events } from 'store/product/product.actions';

import { sendApi } from '../sagas';

function* ProductWatchers() {
  yield all([
    yield takeLatest(
      events.listPage.request,
      sendApi(api.product.list, productActions.listPageRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(
      events.add.request,
      sendApi(api.product.add, productActions.addRes, (payload) => ({
        ...payload,
        success: 'INVOICING.SUCCESSFULLY_PRODUCT_CREATION',
      })),
    ),
    yield takeLatest(
      events.update.request,
      sendApi(api.product.update, productActions.updateRes, (payload) => ({
        ...payload,
        success: 'INVOICING.SUCCESSFULLY_PRODUCT_UPDATE',
      })),
    ),
    yield takeLatest(events.delete.request, sendApi(api.product.delete, productActions.deleteRes)),
    yield takeLatest(
      events.search.request,
      sendApi(api.product.list, productActions.searchRes, (payload) => ({
        data: payload,
        noFeedback: true,
        noLoading: true,
      })),
    ),
  ]);
}

export default function* saga() {
  yield call(ProductWatchers);
}
