import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import {
  addResourceNameToQueryResult,
  ApiError,
  useAxiosInstance,
} from '../../../shared/hooks/utils';

const ReminderSchema = z.object({
  id: z.number(),
  sentAt: z.string().datetime(),
  debtorFullName: z.string(),
  reference: z.string(),
  reminderType: z.string(),
});

export const RemindersSchema = z.array(ReminderSchema);

export type Reminders = z.infer<typeof RemindersSchema>;
export type ReminderItem = z.infer<typeof ReminderSchema>;

export async function loadRemindersQueryFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/payment_requests');
  return RemindersSchema.parse(data);
}

export const useLoadReminders = () => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Reminders, ApiError>({
    queryKey: ['reminders'],
    queryFn: () => loadRemindersQueryFn(axiosInstance),
  });

  return addResourceNameToQueryResult<Reminders, unknown, 'reminderIndex'>(
    'reminderIndex',
    queryResult,
  );
};
