import { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useIsMobile } from 'shared/hooks/utils';
import { sideMenuShow } from 'store/view/view.actions';

import styleIdentifiers from './Dropdown.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum DropdownDirection {
  BOTTOM = 'bottom',
  TOP = 'top',
  LEFT = 'left',
  TOP_LEFT = 'top-left',
  RIGHT = 'right',
  KANBAN = 'kanban',
}

export type DropdownProps = {
  active?: boolean;
  forceClose?: boolean;
  toggleContent?: boolean;
  actionContent?: Function;
  action?: () => void;
  selectorContent?: React.ReactElement | string;
  className?: string;
  direction?: DropdownDirection;
  dropdownContentStyle?: CSSProperties;
  selectorClass?: string;
  contentClass?: string;
  activeClass?: string;
  noArrow?: boolean;
  hover?: boolean;
  oneLine?: boolean;
  sideMenuInMobile?: boolean;
  sideMenuTitle?: string;
  containerStyle?: React.CSSProperties;
};

export const Dropdown = ({
  active,
  forceClose,
  action,
  toggleContent,
  actionContent,
  selectorContent,
  className,
  direction = DropdownDirection.BOTTOM,
  selectorClass,
  contentClass,
  dropdownContentStyle,
  children,
  noArrow,
  activeClass,
  oneLine,
  hover,
  sideMenuInMobile,
  sideMenuTitle,
  containerStyle,
}: PropsWithChildren<DropdownProps>) => {
  const isMobile = useIsMobile();
  const node = useRef<HTMLDivElement>(null);
  const [isDropdownActive, setDropdownActive] = useState<boolean>(active || false);

  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = ({ target }: MouseEvent) => {
    if (isDropdownActive && node && !node.current!.contains(target as any)) {
      setDropdownActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [isDropdownActive, node]);

  useEffect(() => {
    setDropdownActive(false);
  }, [forceClose]);

  const toggleClick = (forcedValue: boolean) => {
    if (isMobile && sideMenuInMobile) {
      sideMenuShow({
        unmount: true,
        content: (
          <div className={styles('dropdown-side-menu')}>
            <div className={styles('head')}>{sideMenuTitle}</div>
            <div className={styles('body')}> {children}</div>
          </div>
        ),
      });
    } else {
      if (!isDropdownActive) action?.();
      setDropdownActive(forcedValue || !isDropdownActive);
    }
  };

  const onAction = (event) => {
    if (toggleContent) setDropdownActive(!isDropdownActive);
    if (actionContent) actionContent(event);
  };

  return (
    <div
      className={styles(
        'Dropdown',
        className,
        noArrow && 'no-triangle',
        direction,
        oneLine && 'one-line',
      )}
      style={containerStyle}
      onMouseEnter={() => (hover ? toggleClick(true) : null)}
      onMouseLeave={() => (hover ? toggleClick(false) : null)}
      ref={node}
    >
      <div
        className={styles(
          'dropdown-selector',
          isDropdownActive && 'active',
          isDropdownActive && activeClass,
          selectorClass,
        )}
        onClick={() => toggleClick(false)}
      >
        <div className={styles('dropdown-selector-content')}>{selectorContent}</div>
        {!noArrow && (
          <div
            className={styles('arrow')}
            onMouseEnter={() => {
              if (hover) toggleClick(true);
            }}
          />
        )}
      </div>
      <div
        className={styles('dropdown-content', isDropdownActive && 'active', contentClass)}
        style={dropdownContentStyle}
        onClick={onAction}
      >
        {children}
      </div>
    </div>
  );
};
