import {
  useDeleteAutomaticAssignation,
  useGetAutomaticAssignation,
  useGetAvailableConditions,
  useUpdateAutomaticAssignation,
} from 'api';
import { AssignableModel, AssignableTo, CreateAutomaticAssignationBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useParams } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';

import { AutomaticAssignationForm } from '../Forms/AutomaticAssignationForm';
import { AssignationConditions } from '../Listing/AssignationConditions';

import styleIdentifiers from './AutomaticAssignationDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function AutomaticAssignationDetail() {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: availableConditionsListResponse } = useGetAvailableConditions({});

  const availableConditions = availableConditionsListResponse?.data || [];

  const { id: automaticAssignationId } = useParams<{ id: string }>();

  const {
    data: automaticAssignationResponse,
    isLoading,
    isError,
    refetch,
  } = useGetAutomaticAssignation(automaticAssignationId);

  const { mutate: updateAutomaticAssignationMutation } = useUpdateAutomaticAssignation();
  const { mutate: deleteAutomaticAssignationMutation } = useDeleteAutomaticAssignation();

  if (isLoading && !automaticAssignationResponse) {
    return <RecovrLogoLoader />;
  }

  if (isError || !automaticAssignationResponse) {
    return <span>Error</span>;
  }

  const { data: automaticAssignation } = automaticAssignationResponse;

  const {
    id,
    attributes: { model_to_assign, assign_to, assignation_conditions, order },
  } = automaticAssignation;

  const goToList = () => {
    history.goBack();
  };

  const handleUpdateAutomaticAssignation = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <AutomaticAssignationForm
          availableConditions={availableConditions}
          automaticAssignation={automaticAssignation}
          onSubmit={(data: CreateAutomaticAssignationBody) =>
            updateAutomaticAssignationMutation(
              { id, data },
              {
                onSuccess: () => {
                  refetch();
                  sideMenuHide();
                },
              },
            )
          }
        />
      ),
    });
  };

  const handleDeleteAutomaticAssignation = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.AUTOMATIC_ASSIGNATION_DELETE)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteAutomaticAssignationMutation(
              { id },
              {
                onSuccess: () => {
                  goToList();
                  dialogHide(DialogShowId.CONFIRM);
                },
              },
            )
          }
        />
      ),
    });

  return (
    <div className={styles('automatic-assignation-detail')}>
      <div className={styles('automatic-assignation-wrapper')}>
        <div className={styles('title-line')}>
          <Icon name={IconName.MINIMAL_LEFT} onClick={goToList} />
          <h1>{`${t(i18nKeys.AUTOMATIC_ASSIGNATION)} - ${id}`}</h1>
          <div className={styles('buttons-wrapper')}>
            <div>
              <Dropdown
                sideMenuInMobile
                sideMenuTitle={t(i18nKeys.INVOICE.OTHER_ACTIONS)}
                selectorContent={
                  <Button
                    noShadow
                    noMargin
                    label={t(i18nKeys.FORM.ACTIONS)}
                    className={styles('button')}
                    color={ButtonColor.BLUE}
                    iconRight={IconName.MINIMAL_DOWN}
                  />
                }
              >
                <div className={styles('dropdown-item')} onClick={handleUpdateAutomaticAssignation}>
                  {t(i18nKeys.EDIT)}
                </div>
                <div className={styles('dropdown-item')} onClick={handleDeleteAutomaticAssignation}>
                  {t(i18nKeys.DELETE)}
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className={styles('line-wrapper')}>
          <Card
            title={t(i18nKeys.CLIENT.GENERAL_INFO)}
            className={styles('automatic-assignation-info', 'box')}
          >
            <div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>
                  {t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ORDER)}
                </span>
                <span className={styles('value', 'blue')}>{order}</span>
              </div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>
                  {t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.MODEL_TO_ASSIGN)}
                </span>
                <span className={styles('value', 'blue')}>
                  {t(
                    i18nKeys.COMMON[
                      model_to_assign === AssignableModel.debtor ? 'CLIENT' : 'INVOICE'
                    ],
                  )}
                </span>
              </div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>
                  {t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGN_TO)}
                </span>
                <span className={styles('value')}>
                  {t(
                    i18nKeys[
                      assign_to.type === AssignableTo.recovery_plan
                        ? 'RECOVERY_PLAN'
                        : 'ACCOUNT_MANAGER'
                    ],
                  )}
                </span>
              </div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}> </span>
                <span className={styles('value')}>{assign_to.name}</span>
              </div>
            </div>
          </Card>
          <Card
            title={t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGNATION_CONDITIONS)}
            className={styles('task-info', 'box')}
          >
            <AssignationConditions
              items={assignation_conditions}
              availableConditions={availableConditions}
              detailView
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
