import { useGetWorkflows } from 'api';
import { Workflow } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller } from 'react-hook-form';
import { CustomSelect } from 'shared/io';

import styleIdentifiers from './StepsCalculation.module.scss';

const styles = classNames.bind(styleIdentifiers);

const cleanWorkflow = ({ id, attributes }) => ({
  id,
  ...attributes,
});

type StepsCalculationWorkflowProps = {
  name?: 'workflow_id';
  currentWorkflow?: Workflow;
  setCurrentWorkflow: (workflow: Workflow) => void;
  canUnselect?: boolean;
};

export const StepsCalculationWorkflow = ({
  name = 'workflow_id',
  currentWorkflow,
  setCurrentWorkflow,
  canUnselect = true,
}: StepsCalculationWorkflowProps) => {
  const { t } = useTranslation();

  const { data: workflowsResponse } = useGetWorkflows();

  const workflows = workflowsResponse?.data || [];

  const workflowsCleaned = workflows.map(cleanWorkflow);

  const concatenatedWorkflows = (currentWorkflow ? [currentWorkflow] : []).concat(
    workflows.filter((e) => e.id !== currentWorkflow?.id),
  );

  return (
    <Controller
      name={name}
      defaultValue=""
      render={() => (
        <CustomSelect
          canUnselect={canUnselect}
          selectClassName={styles('input')}
          withBorder
          noMargin
          placeholder={t(i18nKeys.DEFAULT)}
          items={workflowsCleaned}
          keyValue="id"
          keyText="name"
          onChange={(id) => {
            setCurrentWorkflow(concatenatedWorkflows.find((e) => e.id === id)!);
          }}
          name={name}
          label={t(i18nKeys.FORM.PAYMENT_PLAN.USED_WORKFLOW)}
        />
      )}
    />
  );
};
