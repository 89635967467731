import { i18nKeys, useTranslation } from 'locales';

import { Badge } from '@mantine/core';

export const InvoiceLabel = () => {
  const { t } = useTranslation();

  return (
    <Badge color="blue" size="xs">
      {t(i18nKeys.COMMON.INVOICE)}
    </Badge>
  );
};

export const CreditNoteLabel = () => {
  const { t } = useTranslation();

  return (
    <Badge color="red" size="xs">
      {t(i18nKeys.CREDIT_NOTE_SHORT)}
    </Badge>
  );
};

export const PaymentLabel = () => {
  const { t } = useTranslation();

  return (
    <Badge color="yellow" size="xs">
      {t(i18nKeys.PAYMENT)}
    </Badge>
  );
};
