import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { invoiceActions } from 'store/invoice/invoice.actions';
import {
  transactionActions,
  transactionConstants as events,
} from 'store/transaction/transaction.actions';

import { sendApi } from '../sagas';

function* transactionWatchers() {
  yield all([
    yield takeLatest(
      events.paymentHistory.request,
      sendApi(api.transactions.payment, transactionActions.paymentHistoryRes),
    ),
    yield takeLatest(
      events.getReconciliationPayments.request,
      sendApi(api.transactions.payment, transactionActions.getReconciliationPaymentsRes),
    ),
    yield takeLatest(
      events.paymentHistoryPage.request,
      sendApi(api.transactions.payment, transactionActions.paymentHistoryPageRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(
      events.getReconciliation.request,
      sendApi(api.transactions.getReconciliation, transactionActions.getReconciliationRes),
    ),
    yield takeLatest(
      events.manualReconciliation.request,
      sendApi(api.transactions.manualReconciliation, transactionActions.manualReconciliationRes),
    ),
    yield takeLatest(
      events.ignorePayments.request,
      sendApi(api.transactions.ignorePayments, transactionActions.ignorePaymentsRes),
    ),
    yield takeLatest(
      events.unignorePayments.request,
      sendApi(api.transactions.unignorePayments, transactionActions.unignorePaymentsRes),
    ),
    yield takeLatest(
      events.unmatchManualPayment.request,
      sendApi(api.transactions.unmatchManualPayment, invoiceActions.detailRes),
    ),
    yield takeLatest(
      events.unmatchExternalPayment.request,
      sendApi(api.transactions.unmatchExternalPayment, invoiceActions.detailRes),
    ),
  ]);
}

export default function* saga() {
  yield call(transactionWatchers);
}
