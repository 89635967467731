import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Card from 'shared/components/Card';
import { useSaveCompanyConfiguration } from 'shared/hooks';

import { StepProps } from '../OnBoarding.types';

import GeneralInfoForm from './GeneralInfoForm';

import styleIdentifiers from './GeneralInfo.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function GeneralInfo({ onValidStep }: StepProps) {
  const { t } = useTranslation();
  const { saveCompanyConfiguration } = useSaveCompanyConfiguration();

  const onSubmit = (data) => {
    saveCompanyConfiguration(data, { onSuccess: () => onValidStep() });
  };

  return (
    <div className={styles('general-info')}>
      <Card title={t(i18nKeys.ONBOARDING[4].TITLE)} className={styles('small')}>
        <GeneralInfoForm onSubmit={onSubmit} />
      </Card>
    </div>
  );
}
