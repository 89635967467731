import { CreateTaskBody, DescVal, Task } from 'api/models';
import { pick } from 'lodash-es';

export const getInitialValues = (task?: Task): Partial<CreateTaskBody> => {
  if (!task) {
    return {};
  }

  const {
    attributes: { debtor, assignee, invoices, ...rest },
  } = task;

  return {
    ...pick(rest, ['title', 'description', 'task_type_id', 'due_date']),
    ...(debtor
      ? {
          debtor_id: debtor.id,
        }
      : undefined),
    ...(assignee
      ? {
          user_id: assignee.id,
        }
      : undefined),
    ...(invoices
      ? {
          invoice_ids: task.attributes.invoices.details.map((e) => e.id),
        }
      : undefined),
  };
};

export const getTaskTypeDescription = (taskTypes: DescVal[], task: Task) => {
  const taskType = taskTypes.find(
    (type) => Number(type.value) === Number(task.attributes.task_type_id),
  );
  return taskType ? taskType.description : undefined;
};

export const getAssigneeFullName = ({
  attributes: {
    assignee: {
      attributes: { first_name, last_name },
    },
  },
}: Task) => `${first_name} ${last_name}`;
