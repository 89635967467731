import { DebtorComplete } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { NavLink } from 'react-router-dom';
import AddressBlock from 'shared/components/AddressBlock';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';
import { formattedDate } from 'shared/utils/view';

import styleIdentifiers from './MergeableDebtor.module.scss';

const styles = classNames.bind(styleIdentifiers);

type MergeableDebtorProps = {
  client: DebtorComplete;
  onCheck: Function;
  checkedDebtorId: string;
};

export const MergeableDebtor = ({ client, onCheck, checkedDebtorId }: MergeableDebtorProps) => {
  const { t, currentLang } = useTranslation();

  const {
    id,
    attributes: {
      full_name,
      vat_number,
      external_id,
      address,
      phones,
      emails,
      invoices_count,
      currency,
      last_issue_date,
      total_ca,
    },
  } = client;

  const selected = id === checkedDebtorId;

  const icon = (
    <Icon name={IconName[selected ? 'COPY' : 'TRASH_SIMPLE']} className={styles('icon')} />
  );

  const headClass = selected ? 'active' : 'inactive';

  return (
    <Card
      title={<NavLink to={`/clients/${id}`}>{full_name || id}</NavLink>}
      titleIcon={!!checkedDebtorId && icon}
      className={styles('client-wrapper')}
      classTitle={styles(!!checkedDebtorId && headClass)}
      infosRight={
        <Checkbox
          noMargin
          checked={id === checkedDebtorId}
          onChange={() => {
            onCheck(id);
          }}
        />
      }
    >
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.EXTERNAL_ID)}</span>
        <div className={styles('value', 'medium')}>{external_id || '-'}</div>
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.VAT_NUMBER)}</span>
        <div className={styles('value', 'medium')}>{vat_number || '-'}</div>
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.EMAIL)}</span>
        {emails.length > 0 ? (
          <a href={`mailto:${emails[0]}`} className={styles('value', 'medium')}>
            {emails[0]}
          </a>
        ) : (
          '-'
        )}
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.PHONE)}</span>
        {phones.length > 0 ? (
          <a
            href={`tel:${phones[phones.length - 1].attributes.number}`}
            className={styles('value', 'medium')}
          >
            {phones[phones.length - 1].attributes.number}
          </a>
        ) : (
          '-'
        )}
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.ADDRESS)}</span>
        {address ? (
          <div className={styles('value', 'medium')}>
            <AddressBlock address={address} className={styles('address')} clean />
          </div>
        ) : (
          <div>
            -<br />
            &nbsp;
            <br />
            &nbsp;
          </div>
        )}
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.DETAIL.CLIENT_TOTAL_TVAC)}</span>
        <div className={styles('value', 'medium', 'blue')}>
          <Amount localizedValue={total_ca.value} value={total_ca.localized} suffix={currency} />
        </div>
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.INVOICES)}</span>
        <div className={styles('value', 'medium')}>{invoices_count!.total}</div>
      </div>
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.LAST_ISSUE_DATE)}</span>
        <div className={styles('value', 'medium')}>
          {last_issue_date ? formattedDate(last_issue_date, currentLang) : '-'}
        </div>
      </div>
    </Card>
  );
};
