import { cloneDeep } from 'lodash-es';
import { removeAttributes } from 'shared/utils/view';
import { flattenItem } from 'store/reducers';
import { Constants } from 'types/store/app-state';
import { GroupedInvoices, Invoice } from 'types/store/invoice-state';

import { treatClient } from './debtor.serializer';
import { treatProduct } from './product.serializer';

export const invoiceFormToData = (data: any, constants: Constants) => {
  const toSend = { ...cloneDeep(data) };

  if (toSend.invoices_to_link) {
    delete toSend.invoices_to_link;
  }

  if (data.debtor_attributes) {
    toSend.debtor_id = data.debtor_attributes.id;
    delete toSend.debtor_attributes;
  }

  if (data.invoice_id?.id) {
    toSend.invoice_id = data.invoice_id.id;
  }

  if (!toSend.id) {
    delete toSend.id;
  }

  if (toSend.product_lines_attributes) {
    for (const productLine of toSend.product_lines_attributes) {
      delete productLine.isInvoice;
      productLine.current_unity_price_htva =
        +productLine.product_attributes.current_unity_price_htva;
      delete productLine.product_attributes.current_unity_price_htva;
      productLine.total_htva =
        productLine.quantity *
        productLine.current_unity_price_htva *
        (1 - (productLine.discount_rate || 0) / 100);
      const tvaRate = constants.tax_categories.find(
        (taxCategory) => taxCategory.value === productLine.product_attributes.tax_category_code,
      )!.vat_value;
      productLine.total_tvac =
        productLine.total_htva *
        (data.debtor_attributes.intracom || data.debtor_attributes.co_contractor
          ? 1
          : 1 + +tvaRate / 100);

      if (productLine.product_id) {
        delete productLine.product_attributes;
      } else {
        delete productLine.product_id;
        productLine.product_attributes.price_htva = +productLine.current_unity_price_htva;
      }
      if (productLine.id) {
        delete productLine.product_attributes;
        delete productLine.product_id;
      } else {
        delete productLine.id;
      }
    }
  }

  return toSend;
};

export const treatInvoice = <T = Invoice>(item: T): T => {
  const excludedKeys = ['debtor'];

  const data = flattenItem(item, excludedKeys, [
    'actions',
    'manual_payments',
    'external_payments',
    'account_manager',
  ]);

  if (data?.account_manager) {
    data.account_manager = removeAttributes(data.account_manager);
  }

  if (data?.next_step_information?.conversation) {
    data.next_step_information.conversation = removeAttributes(
      data.next_step_information.conversation,
    );

    data.next_step_information.conversation.messages =
      data.next_step_information.conversation.messages.map((message) => removeAttributes(message));
  }

  if ((item as any)?.attributes?.debtor) {
    data.debtor_attributes = treatClient((item as any).attributes.debtor);
  }

  if (data?.manual_payments) {
    data.manual_payments = data.manual_payments.map((payments) => removeAttributes(payments));
  }

  if (data?.external_payments) {
    data.external_payments = data.external_payments.map((payment) => {
      payment = removeAttributes(payment);
      payment.payment = removeAttributes(payment.payment);
      return payment;
    });
  }

  if (data?.product_lines_attributes) {
    for (let i = 0; i < data.product_lines_attributes.length; i++) {
      const product = data.product_lines_attributes[i];
      if (product.product) {
        product.product_attributes = treatProduct(product.product);
      }

      if (product.current_unity_price_htva && product.product_attributes) {
        product.product_attributes.current_unity_price_htva = product.current_unity_price_htva;
      }
    }
  }

  if (data?.sending_options_attributes) {
    data.sending_options_attributes.emails = data.sending_options_attributes.emails_attributes;
    delete data.sending_options_attributes.emails_attributes;
  }

  if (data?.matched_payments) {
    data.matched_payments = data.matched_payments
      .map((el) => removeAttributes(el))
      .map(({ invoice, ...rest }) => ({
        ...rest,
        invoice: treatInvoice(invoice),
      }));
  }

  return data;
};

export const treatInvoiceActionData = (data: any): GroupedInvoices => {
  const newData = removeAttributes(data);

  if (newData.debtor_address) {
    newData.debtor_address = removeAttributes(newData.debtor_address);
  }
  newData.sending_options = removeAttributes(newData.sending_options);
  if (newData.sending_options.address) {
    newData.sending_options.address_attributes = removeAttributes(newData.sending_options.address);
  }

  return newData;
};

export const treatBillingStatement = (data) => {
  const newData = removeAttributes(data);

  newData.additional_invoices = newData.additional_invoices.map((item) => ({
    itemType: 'additional_invoices',
    ...removeAttributes(item),
  }));
  newData.credit_notes = newData.credit_notes.map((item) => ({
    itemType: 'credit_notes',
    ...removeAttributes(item),
  }));
  newData.accounting_payments = newData.accounting_payments.map((item) => ({
    itemType: 'accounting_payments',
    ...removeAttributes(item),
  }));
  newData.tp_payments = newData.tp_payments.map((item) => ({
    itemType: 'tp_payments',
    ...removeAttributes(item),
  }));
  newData.main_invoice = removeAttributes(newData.main_invoice);

  return newData;
};
