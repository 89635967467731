import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Document, Page } from 'react-pdf';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor, InputFile } from 'shared/io';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { File } from 'types/file';
import { Invoice } from 'types/store/invoice-state';

import styleIdentifiers from './LinkDocument.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  invoice: Invoice;
};

export default function LinkDocument({ invoice }: Props) {
  const [showDocument, setShowDocument] = useState(false);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [activeDocument, setActiveDocument] = useState({ url: '', id: -1 });
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [additionnalFiles, setAdditionnalFiles] = useState<File[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setAdditionnalFiles(JSON.parse(JSON.stringify(invoice.additional_files_attributes)));
    return () => {
      additionnalFiles.map((file: File) => {
        file.url && URL.revokeObjectURL(file.url);
      });
    };
  }, []);

  useEffect(() => {
    if (additionnalFiles.length > 0) {
      displayDocument(additionnalFiles[0]);
    }
  }, [additionnalFiles]);

  const downloadFile = (file) => {
    invoiceActions.document({
      id: invoice.id,
      noLoading: true,
      document_type: 'invoice_additional_pdf',
      data: {
        additional_file_id: file.id,
      },
    });
  };
  const displayDocument = (additional_file) => {
    if (additional_file.url) {
      setActiveDocument({
        url: additional_file.url,
        id: additional_file.id,
      });
    } else {
      invoiceActions.document({
        id: invoice.id,
        noLoading: true,
        document_type: 'invoice_additional_pdf',
        data: {
          additional_file_id: additional_file.id,
        },
        callback: (data) => {
          const url = URL.createObjectURL(data);
          const index = additionnalFiles.findIndex((file) => file.id === additional_file.id);

          additionnalFiles[index].url = url;

          setActiveDocument({
            url,
            id: additional_file.id,
          });
          setAdditionnalFiles(additionnalFiles);
        },
      });
    }
  };
  const removeFile = (idFile) => {
    invoiceActions.deleteAdditionalFile({
      id: idFile,
      callback: () => {
        const newfiles = additionnalFiles.slice();
        const indexFile = additionnalFiles.findIndex((file) => file.id === idFile);
        newfiles[indexFile].url && URL.revokeObjectURL(newfiles[indexFile].url);
        newfiles.splice(indexFile, 1);
        setAdditionnalFiles(newfiles);
      },
    });
  };

  const addFile = (file, send_by_post) => {
    invoiceActions.addAdditionalFile({
      data: {
        invoice_id: invoice.id,
        file,
        send_by_post,
        is_original_invoice_file: false,
      },
      callback: ({ data }) => {
        const newFile = { ...data.attributes, id: data.id };

        const url = URL.createObjectURL(file);
        newFile.url = url;
        additionnalFiles.push(newFile);

        setAdditionnalFiles(additionnalFiles);
        displayDocument(newFile);
      },
    });
  };
  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf._pdfInfo.numPages);
  };

  return (
    <div
      className={styles(
        'link-document',
        showDocument ? 'show-document' : 'show-document-list',
        additionnalFiles.length === 0 && 'empty',
        invoice.status === 'Brouillon' && 'brouillon',
      )}
    >
      <div className={styles('left')}>
        {isLoadingFile ? (
          <div className={styles('loading')}>
            <div>{t(i18nKeys.LOADING)}</div>
          </div>
        ) : (
          <div
            className={styles('file-container', 'pdf-actions', {
              empty: additionnalFiles.length === 0,
            })}
          >
            {activeDocument.url && (
              <>
                <div className={styles('container-actions')}>
                  {page > 1 && (
                    <Icon
                      name={IconName.MINIMAL_UP}
                      onClick={() => {
                        setPage(page - 1);
                      }}
                    />
                  )}
                  {numPages > page && (
                    <Icon
                      name={IconName.MINIMAL_DOWN}
                      onClick={() => {
                        setPage(page + 1);
                      }}
                    />
                  )}
                </div>

                <Document file={activeDocument.url} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    width={540}
                    pageNumber={page}
                  />
                </Document>
              </>
            )}
          </div>
        )}
        <div
          className={styles('show-document-button')}
          onClick={() => setShowDocument(!showDocument)}
        >
          {t(i18nKeys.BACK)} <Icon name={IconName.MINIMAL_RIGHT} />
        </div>
      </div>
      <form className={styles('right')}>
        <div className={styles('container-right')}>
          <div className={styles('actions-container', additionnalFiles.length === 0 && 'center')}>
            <InputFile
              color={ButtonColor.MAIN}
              name="additional-file"
              label={t(i18nKeys.INVOICE.IMPORT_NEW_DOC)}
              accept="application/pdf"
              onChange={(file) => addFile(file, false)}
            />
          </div>
          <div className={styles('recovr-list')}>
            {additionnalFiles.map((file: File) => (
              <div key={file.id} className={styles({ active: activeDocument.id === file.id })}>
                <span className={styles('name')} onClick={() => displayDocument(file)}>
                  {file.file_name}
                </span>
                <div className={styles('actions')}>
                  <Icon
                    name={IconName.CLOUD_DOWNLOAD}
                    size="18px"
                    onClick={() => downloadFile(file)}
                  />
                  <Icon
                    name={IconName.TRASH_SIMPLE}
                    onClick={() => removeFile(file.id)}
                    size="18px"
                  />
                </div>
              </div>
            ))}
          </div>
          {additionnalFiles.length > 0 && (
            <div
              className={styles('show-document-button')}
              onClick={() => setShowDocument(!showDocument)}
            >
              <Icon name={IconName.MINIMAL_LEFT} /> {t(i18nKeys.SHOW_DOCUMENT)}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
