import { useState } from 'react';
import { useCreatePaymentPlan, useCreatePaymentStepCalculation } from 'api';
import { CreatePaymentStepCalculationBody, Id, PaymentStepCalculationResponse } from 'api/models';
import qs from 'query-string';
import { useHistory } from 'react-router';

import { StepsCalculation } from './StepsCalculation/StepsCalculation';
import { PartialCreatePaymentPlanBody, PaymentPlanFormSteps } from './PaymentPlanFormSteps';
import { PaymentPlanFormWrapper } from './PaymentPlanFormWrapper';

export const PaymentPlanForm = () => {
  const history = useHistory();
  const queryParams = qs.parse(location.search);
  const invoicesParams = queryParams.invoice_ids as string;

  const [paymentProperties, setPaymentProperties] = useState<CreatePaymentStepCalculationBody>();

  const [paymentSteps, setPaymentSteps] = useState<PaymentStepCalculationResponse>();

  const { mutate: createPaymentStepCalculation } = useCreatePaymentStepCalculation();

  const calculateStep = (data: CreatePaymentStepCalculationBody) => {
    createPaymentStepCalculation(
      { data },
      {
        onSuccess: (response) => {
          setPaymentProperties(data);
          setPaymentSteps(response);
        },
      },
    );
  };

  const { mutate: createPaymentPlan } = useCreatePaymentPlan();

  if (!paymentProperties || !paymentSteps) {
    return (
      <PaymentPlanFormWrapper>
        <StepsCalculation
          onSubmit={calculateStep}
          initialValues={paymentProperties}
          debtorId={paymentProperties?.debtor_id || (queryParams.debtor_id as Id)}
          invoiceIds={paymentProperties?.invoice_ids || (invoicesParams?.split(',') as Id[])}
        />
      </PaymentPlanFormWrapper>
    );
  }

  const recalculateSteps = () => {
    setPaymentSteps(undefined);
  };

  const onSubmit = (data: PartialCreatePaymentPlanBody) => {
    createPaymentPlan(
      { data: { ...paymentProperties, ...data } },
      {
        onSuccess: ({ data: planData }) => {
          history.push(`/payment-plan/${planData.id}`);
        },
      },
    );
  };

  return (
    <PaymentPlanFormSteps
      paymentProperties={paymentProperties}
      recalculateSteps={recalculateSteps}
      paymentSteps={paymentSteps}
      onSubmit={onSubmit}
    />
  );
};
