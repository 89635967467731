import { useEffect, useState } from 'react';
import { useGetSimilarDebtors } from 'api';
import { SimilarDebtorPair } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import CustomTable from 'shared/components/CustomTable';
import ListResModal from 'shared/components/DeleteModal';
import { IconName } from 'shared/components/Icon';
import { Button } from 'shared/io';
import { clientActions } from 'store/client/client.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import DuplicatesClientListItem from './DuplicatesClientListItem';

import styleIdentifiers from './DuplicatesClients.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EnhancedTable = CustomTable(DuplicatesClientListItem);

export const DuplicatesClients = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    data: similarDebtorsListResponse,
    isLoading,
    isSuccess,
    refetch,
  } = useGetSimilarDebtors({});

  const CLIENTS_TABLE_HEADERS = [
    {
      title: `${t(i18nKeys.CONTACT)} 1`,
      width: 160,
    },
    {
      title: `${t(i18nKeys.CONTACT)} 2`,
      width: 140,
    },
    {
      title: t(i18nKeys.INVOICING.ACTION),
      width: 120,
    },
  ];

  const handleFilters = (filters) => {
    const newFilters = {
      ...filters,
      debtor: undefined,
    };

    return newFilters;
  };

  const orderField = (field: string) => field;

  const items = similarDebtorsListResponse?.data || [];
  useEffect(() => {
    if (isSuccess && items.length === 0) {
      history.push('/clients');
    }
  }, [isSuccess]);

  const [{ selectAll, selectedItems }, setSelection] = useState({
    selectAll: false,
    selectedItems: [] as SimilarDebtorPair[],
  });

  const onCheckBoxChange = (newSelectedItems: SimilarDebtorPair[]) => {
    setSelection((currentState) => ({
      ...currentState,
      selectedItems: newSelectedItems,
    }));
  };

  const onSelectAll = (value: boolean) => {
    setSelection((currentState) => ({
      ...currentState,
      selectedItems: items,
      selectAll: value,
    }));
  };

  const ignoreDuplicates = (action) => {
    const data = {} as any;
    data.debtor_ids = selectAll ? null : selectedItems?.map(({ id }) => id);
    action(() => {
      clientActions.batchAction({
        data: {
          ...data,
          action_type: 'ignore_duplicates',
        },
        callback: (response) => {
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.RESULT),
            children: <ListResModal data={response} />,
          });
          setSelection((currentState) => ({
            ...currentState,
            selectedItems: [],
            selectAll: false,
          }));
          refetch();
        },
      });
    });
  };

  const aysModal = (action_type) => (onSubmit) => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: <AYSModal text={t(action_type)} onConfirm={() => onSubmit({})} />,
    });
  };

  return (
    <div className={styles('client-list-to-confirm')}>
      <EnhancedTable
        notSortable
        title={t(i18nKeys.DUPLICATES)}
        orderField={orderField}
        loading={isLoading}
        loaded={isSuccess}
        pagination={similarDebtorsListResponse?.metadata.pagination}
        headers={CLIENTS_TABLE_HEADERS}
        handleFilters={handleFilters}
        items={items}
        itemProps={{ checkbox: true, refetch }}
        onCheckBoxChange={onCheckBoxChange}
        setSelectAll={onSelectAll}
        actions={
          (selectAll || !!selectedItems.length) && (
            <Button
              className={styles('action-button')}
              noMargin
              label={t(i18nKeys.CLIENT.MERGE.IGNORE)}
              onClick={() => ignoreDuplicates(aysModal(i18nKeys.AYS.IGNORE_DUPLICATES))}
              iconRight={IconName.SIMPLE_REMOVE}
            />
          )
        }
      />
    </div>
  );
};
