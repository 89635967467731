import { ContactPerson, CreateContactPersonBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import FormSection from 'shared/forms/FormSection';
import { Input } from 'shared/io';

import styleIdentifiers from './ContactPersonForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ContactPersonFormPhonesProps = { contactPerson?: ContactPerson };

export const ContactPersonFormPhones = ({ contactPerson }: ContactPersonFormPhonesProps) => {
  const { t } = useTranslation();

  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<CreateContactPersonBody>();

  const { fields, append, remove } = useFieldArray({
    name: 'phones',
  });

  return (
    <FormSection
      title={t(i18nKeys.PHONES)}
      startClosed={!contactPerson?.attributes?.phones?.length}
      onAdd={() => {
        append({});
      }}
    >
      <div className={styles('phones')}>
        {fields.map((item, index) => {
          register(`phones.${index}._destroy`);
          register(`phones.${index}.id`);

          if (watch(`phones.${index}._destroy`)) {
            return null;
          }

          return (
            <div key={item.id} className={styles('line-with-delete')}>
              <Input
                label={t(i18nKeys.PHONE)}
                type="phone"
                className={styles('input')}
                errorMessage={get(errors, `phones.${index}.attributes.number`)}
                register={register(`phones.${index}.attributes.number`, {
                  required: true,
                })}
                withBorder
              />
              <Icon
                name={IconName.TRASH_SIMPLE}
                className={styles('delete')}
                onClick={() => {
                  if (watch(`phones.${index}.id`)) {
                    setValue(`phones.${index}._destroy`, true);
                  } else {
                    remove(index);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    </FormSection>
  );
};
