import { AssignationCondition, AvailableCondition } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { OperatorValues } from 'shared/forms/PreferencesForm';
import { formattedDate } from 'shared/utils/view';

import styleIdentifiers from './AssignationConditions.module.scss';

const styles = classNames.bind(styleIdentifiers);

type AssignationConditionTextProps = {
  assignationCondition: AssignationCondition;
  availableConditions: AvailableCondition[];
  detailView?: boolean;
};

export const AssignationConditionText = ({
  assignationCondition,
  availableConditions,
  detailView,
}: AssignationConditionTextProps) => {
  const { t, currentLang } = useTranslation();
  const {
    attributes: { operator, value, name, id },
  } = assignationCondition;

  const { description, var_type } =
    availableConditions.find((condition) => condition.value === name) || {};

  const parsedValues = value
    .map((v, i) => {
      if (var_type === 'boolean') {
        return t(i18nKeys[v ? 'YES' : 'NO']);
      }
      if (var_type === 'date') {
        if (i === 0) {
          return '';
        }
        if (i === 1) {
          return [
            value[0] === '' ? '∞' : formattedDate(new Date(value[0]), currentLang),
            v === '' ? '∞' : formattedDate(new Date(v), currentLang),
          ].join(' - ');
        }
      }
      return v;
    })
    .filter((v) => v !== '')
    .join(' / ');

  if (detailView) {
    return (
      <div className={styles('item')} key={id}>
        <span className={styles('subtitle')}>{description}</span>
        <span className={styles('value')}>
          {operator && OperatorValues[operator?.toUpperCase()]} {parsedValues}
        </span>
      </div>
    );
  }

  return (
    <p className={styles('assignation-condition')}>
      {description} ({operator && `${OperatorValues[operator?.toUpperCase()]} `}
      {parsedValues})
    </p>
  );
};
