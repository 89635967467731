import { useGetTask, useUpdateTask } from 'api';
import { CreateTaskBody, Id } from 'api/models';
import { sideMenuHide } from 'store/view/view.actions';

import { TaskForm } from './TaskForm';

type TaskSideMenuProps = {
  taskId: Id;
  refetch: () => void;
};

export const TaskSideMenu = ({ taskId, refetch }: TaskSideMenuProps) => {
  const { data: taskResponse, isLoading, isError } = useGetTask(taskId);
  const { mutate: updateTaskMutation } = useUpdateTask();

  if (isLoading && !taskResponse) return null;

  if (isError || !taskResponse) return <span>Error</span>;

  return (
    <TaskForm
      task={taskResponse.data}
      onSubmit={(data: CreateTaskBody) =>
        updateTaskMutation(
          { id: taskId, data },
          {
            onSuccess: () => {
              refetch();
              sideMenuHide();
            },
          },
        )
      }
    />
  );
};
