import { AxiosInstance } from 'axios';
import { ResourceType } from 'types/resource-types';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export type DuplicateViewVariables = {
  viewId: string;
  resourceType: ResourceType;
};

export async function duplicateViewFn(
  axiosInstance: Promise<AxiosInstance>,
  variables: DuplicateViewVariables,
): Promise<ApiResponse> {
  const { viewId } = variables;
  const instance = await axiosInstance;
  return instance.post(`views/${viewId}/duplicate`);
}

export function useDuplicateView() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, DuplicateViewVariables>({
    mutationFn: (variables) => duplicateViewFn(axiosInstance, variables),
    onSuccess: (response, { resourceType }) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['views', resourceType],
      });
    },
    onError: (error: ApiError) => {
      notifications.show({
        color: 'red',
        message: error.response?.data.error_message,
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    DuplicateViewVariables,
    'duplicateView'
  >('duplicateView', mutation);
}
