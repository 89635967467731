import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { NavLink } from 'react-router-dom';
import { ExternalMail } from 'shared/hooks';

import styleIdentifiers from './ExternalMailDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailDetailHeadProps = {
  externalMail: ExternalMail;
  fullDetail?: boolean;
};

export const ExternalMailDetailHead = ({
  externalMail,
  fullDetail = false,
}: ExternalMailDetailHeadProps) => {
  const { t } = useTranslation();

  const {
    attributes: { debtor, from_mail, from_name, to_mail, cc, bcc, attachments },
  } = externalMail;

  const wide = attachments?.length > 0 ? 'wide' : '';

  const restOfAttachments = attachments.length > 1 ? `+${attachments.length - 1}` : null;

  const renderAttachment = (index) => {
    const attachment = attachments[index];
    return (
      <p>
        <a href={attachment.url} target="_blank" rel="noreferrer">
          {attachment.filename}
        </a>
      </p>
    );
  };

  return (
    <div className={styles('detail-head')}>
      <div className={styles('grid', wide)}>
        <p className={styles('text')}>{t(i18nKeys.FORM.FROM)}</p>
        <div className={styles('value', 'flex')}>
          {from_name && <span>{from_name}</span>}
          {from_name && <>&#x3c;</>}
          <a href={`mailto:${from_mail}`}>{from_mail}</a>
          {from_name && <>&#x3e;</>}
        </div>
      </div>
      <div className={styles('grid', wide)}>
        <p className={styles('text')}>{t(i18nKeys.FORM.TO)}</p>
        <div>
          {to_mail.map((mail, idx) => (
            <a key={idx} className={styles('value', 'to-mail')} href={`mailto:${mail}`}>
              {mail}
            </a>
          ))}
        </div>
      </div>
      {fullDetail && (
        <>
          <div className={styles('grid', wide)}>
            <p className={styles('text')}>{t(i18nKeys.CC)}</p>
            <p className={styles('value')}>{cc || '-'}</p>
          </div>
          <div className={styles('grid', wide)}>
            <p className={styles('text')}>{t(i18nKeys.BCC)}</p>
            <p className={styles('value')}>{bcc || '-'}</p>
          </div>
        </>
      )}
      {(fullDetail || debtor) && (
        <div className={styles('grid', wide)}>
          <p className={styles('text')}>{t(i18nKeys.COMMON.CLIENT)}</p>
          <div className={styles('value')}>
            {debtor ? (
              <NavLink to={`/clients/${debtor.id}`}>{debtor.attributes.full_name}</NavLink>
            ) : (
              '-'
            )}
          </div>
        </div>
      )}
      {attachments?.length > 0 && (
        <div className={styles('grid', wide)}>
          <p className={styles('text')}>{t(i18nKeys.EXTERNAL_MAIL.ATTACHMENTS)}</p>
          <div className={styles('value')}>
            {fullDetail ? (
              <>{attachments.map((attachment, i) => renderAttachment(i))}</>
            ) : (
              <div className={styles('first-attachment')}>
                {renderAttachment(0)} {restOfAttachments}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
