import { useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider as RawJotaiProvider } from 'jotai';
import { DevTools } from 'jotai-devtools';
import { useTranslation } from 'locales';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { isPublicEnvironment } from 'shared/utils/environment';
import { config } from 'store/constants';
import { rootSagas } from 'store/root.sagas';
import { PartialObjectDeep } from 'type-fest/source/partial-deep';

import { Auth0Provider as _Auth0Provider } from '@auth0/auth0-react';
import {
  Button,
  createTheme,
  DEFAULT_THEME,
  MantineProvider,
  MantineTheme,
  mergeMantineTheme,
  Modal,
  Table,
} from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { useHotkeys } from '@mantine/hooks';
import { ModalsProvider as MantineModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { captureException } from '@sentry/react';
import { styled } from '@stitches/react';
import {
  QueryCache,
  QueryClient as QueryClientV5,
  QueryClientProvider as QueryClientProviderV5,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { jotaiStore } from './jotai';
import { browserHistory, sagaMiddleware, store } from './redux-store';

const queryClient = new QueryClient();
const queryClientV4 = new QueryClientV5({
  queryCache: new QueryCache({
    onError: (error) => {
      console.error(error);
      captureException(error);
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const MANTINE_THEME_OVERRIDES: PartialObjectDeep<MantineTheme, {}> = createTheme({
  cursorType: 'pointer',
  fontFamily: 'Work Sans, sans-serif',
  headings: {
    fontFamily: 'Roboto, sans-serif',
  },
  breakpoints: {
    mobile: '64em',
  },
  colors: {
    // For the record because my name is attached to it now: I considered looking for another job over this.
    green: [
      '#2ce1be',
      '#2ce1be',
      '#2ce1be',
      '#2ce1be',
      '#2ce1be',
      '#2ce1be',
      '#2ce1be',
      '#2ce1be',
      '#2ce1be',
      '#2ce1be',
    ],
  },
  components: {
    // Without this directive, buttons behave as if they received the fullSize prop, I do not know why.
    Button: Button.extend({
      styles: {
        root: { width: 'fit-content', fontWeight: 500 },
        // Force the default mantine font-family on buttons
        label: {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
        },
      },
    }),
    Table: Table.extend({
      styles: {
        td: {
          verticalAlign: 'middle', // Our reset sets this to baseline, which causes misalignment in tables
        },
      },
    }),
    Modal: Modal.extend({
      styles: {
        title: {
          fontWeight: 600,
          fontSize: '1.2rem',
        },
        header: {
          borderBottom: '1px solid lightgrey',
          paddingBottom: '12px',
          paddingTop: '12px',
        },
        body: {
          paddingTop: 'var(--mantine-spacing-md)',
        },
      },
    }),
  },
});

export const MANTINE_THEME = mergeMantineTheme(DEFAULT_THEME, MANTINE_THEME_OVERRIDES);

const DevtoolStyleFixes = styled('span', {
  '.jotai-devtools-trigger-button': {
    bottom: '60px',
    left: '0px',
    transform: 'scale(0.65)',
  },
});

const DevToolsWrapper = () => {
  const [areDevtoolsVisible, setAreDevtoolsVisible] = useState(false);
  useHotkeys([['shift+mod+D', () => setAreDevtoolsVisible((state) => !state)]]);

  if (isPublicEnvironment()) return null;

  if (!areDevtoolsVisible) return null;

  return (
    <DevtoolStyleFixes>
      <ReactQueryDevtools initialIsOpen={false} />
      <DevTools />
    </DevtoolStyleFixes>
  );
};

export const Providers = ({ children }) => {
  sagaMiddleware.run(rootSagas);
  return (
    <Auth0Provider>
      <ReduxProviders store={store} history={browserHistory}>
        <JotaiProvider>
          <ReactQueryProviders>
            <MantineProviders>
              {children}
              <DevToolsWrapper />
              <Notifications zIndex={10} position="bottom-right" />
            </MantineProviders>
          </ReactQueryProviders>
        </JotaiProvider>
      </ReduxProviders>
    </Auth0Provider>
  );
};

const Auth0Provider = ({ children }) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <_Auth0Provider
    domain={config.auth0.domain}
    clientId={config.auth0.clientId}
    cacheLocation="localstorage"
    useRefreshTokens
    authorizationParams={{
      audience: config.auth0.audience,
      redirect_uri: `${window.location.origin}/dashboard`,
      scope: 'openid profile email offline_access',
    }}
  >
    {children}
  </_Auth0Provider>
);

const ReduxProviders = ({ store: _store, history, children }) => (
  <ReduxProvider store={_store}>
    <ConnectedRouter history={history}>{children}</ConnectedRouter>
  </ReduxProvider>
);

const JotaiProvider = ({ children }) => (
  <RawJotaiProvider store={jotaiStore}>{children}</RawJotaiProvider>
);

const ReactQueryProviders = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <QueryClientProviderV5 client={queryClientV4}>{children}</QueryClientProviderV5>
  </QueryClientProvider>
);

const MantineProviders = ({ children }) => {
  const { currentLang } = useTranslation();

  return (
    <MantineProvider theme={MANTINE_THEME_OVERRIDES}>
      <DatesProvider settings={{ locale: currentLang }}>
        <MantineModalsProvider>{children}</MantineModalsProvider>
      </DatesProvider>
    </MantineProvider>
  );
};
