import { i18nKeys, useTranslation } from 'locales';
import { mergeWith, subtract } from 'lodash-es';
import { clone } from 'remeda';
import {
  AgedBalanceCompact,
  useLoadAgedBalanceCompact,
  useLoadDisputedInvoices,
} from 'shared/hooks';
import { useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';

import { BaseTicker } from '../BaseTicker';

function getAmountDisputed(disputedAmount: number, formatter: Intl.NumberFormat) {
  return formatter.format(disputedAmount);
}

function getPercentageDisputed(agedBalanceCompact: AgedBalanceCompact, disputedAmount: number) {
  const netAgedBalance = mergeWith(
    clone(agedBalanceCompact.debits),
    agedBalanceCompact.credits,
    subtract,
  );

  const totalReceivable =
    netAgedBalance.notDue +
    netAgedBalance['0'] +
    netAgedBalance['30'] +
    netAgedBalance['60'] +
    netAgedBalance['90'];

  const percentage = (disputedAmount / totalReceivable) * 100;
  return percentage.toFixed(2);
}

interface DisputedTickerProps {
  format: 'percentage' | 'absolute';
}

export const DisputedTicker = ({ format }: DisputedTickerProps) => {
  const { t } = useTranslation();
  const { agedBalanceCompact, isAgedBalanceCompactLoading } = useLoadAgedBalanceCompact();
  const { disputedInvoices, isDisputedInvoicesLoading } = useLoadDisputedInvoices();
  const formatter = useSafeLocalizedCompactCurrencyFormatter(true);

  if (
    isAgedBalanceCompactLoading ||
    agedBalanceCompact == null ||
    isDisputedInvoicesLoading ||
    disputedInvoices == null
  ) {
    return <BaseTicker label={t(i18nKeys.ANALYTICS.TICKERS.DISPUTED.TITLE)} />;
  }

  const value =
    format === 'percentage'
      ? getPercentageDisputed(agedBalanceCompact, disputedInvoices)
      : getAmountDisputed(disputedInvoices, formatter);

  return (
    <BaseTicker
      label={t(i18nKeys.ANALYTICS.TICKERS.DISPUTED.TITLE)}
      value={value}
      suffix={format === 'percentage' ? '%' : '€'}
      url="/invoices/listing?status=disputed"
    />
  );
};
