import { useEffect, useRef, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { isNonNull } from 'remeda';
import HTML from 'shared/components/HTML/HTML';
import {
  Profile,
  UpdateProfileForm,
  UpdateProfileFormSchema,
  useProfile,
  useUpdateProfile,
} from 'shared/hooks';
import { PageTitle } from 'shared/layout/PageTitle';
import { createAreFieldsDirty, createAreFieldsValid, createGetDirtyFields } from 'shared/utils';
import { AVAILABLE_LANGUAGES } from 'types';

import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Fieldset,
  FileButton,
  Flex,
  Grid,
  Group,
  Select,
  Switch,
  TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';

import { MantineSettingsCard } from './MantineSettingsCard';
import SignatureEditorModal from './SignatureEditorModal';

function profileToProfileForm(profile: Profile): UpdateProfileForm {
  return {
    firstName: profile.name.first,
    lastName: profile.name.last,
    email: profile.email,
    locale: profile.locale,
    pageLimit: profile.preferences.itemsPerPage,
    receiveDailyEmails: profile.preferences.emails.daily,
    receiveWeeklyEmails: profile.preferences.emails.weekly,
    receiveNotificationEmails: profile.preferences.emails.notifications,
  };
}

export const UserSettings = () => {
  const { t } = useTranslation();
  const profile = useProfile();
  const { updateProfile, isUpdateProfileLoading } = useUpdateProfile();
  const [isSignatureModalOpen, { open: openModal, close: closeModal }] = useDisclosure();

  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const resetRef = useRef<() => void>(null);

  const canResetImage =
    !isUpdateProfileLoading && (isNonNull(avatarFile) || isNonNull(profile?.profilePicture));

  const form = useForm<UpdateProfileForm>({
    validate: zodResolver(UpdateProfileFormSchema),
    validateInputOnBlur: true,
  });

  useEffect(() => {
    form.setInitialValues(profileToProfileForm(profile));
    form.setValues(profileToProfileForm(profile));
  }, [JSON.stringify(profile)]);

  const areFieldsDirty = createAreFieldsDirty(form);
  const getDirtyFields = createGetDirtyFields(form);
  const areFieldsValid = createAreFieldsValid(form);

  const personalInformationFields: Array<keyof UpdateProfileForm> = [
    'firstName',
    'lastName',
    'email',
  ];

  const personalPreferencesFields: Array<keyof UpdateProfileForm> = [
    'locale',
    'pageLimit',
    'receiveDailyEmails',
    'receiveWeeklyEmails',
    'receiveNotificationEmails',
  ];

  const paginationOptions = [
    { label: `10 ${t(i18nKeys.PER_PAGE)}`, value: '10' },
    { label: `20 ${t(i18nKeys.PER_PAGE)}`, value: '20' },
    { label: `50 ${t(i18nKeys.PER_PAGE)}`, value: '50' },
    { label: `100 ${t(i18nKeys.PER_PAGE)}`, value: '100' },
  ];

  const clearFile = () => {
    setAvatarFile(null);
    resetRef.current?.();
    updateProfile({ avatar: null });
  };

  return (
    <>
      <PageTitle>{t(i18nKeys.SETTINGS.PROFILE.EDIT)}</PageTitle>
      <Grid pb="lg" pr="lg">
        <Grid.Col span={5}>
          <MantineSettingsCard
            title={t(i18nKeys.PERSONAL_INFO)}
            onSave={
              areFieldsDirty(...personalInformationFields) &&
              areFieldsValid(...personalInformationFields)
                ? () => updateProfile(getDirtyFields(...personalInformationFields))
                : undefined
            }
          >
            <TextInput
              label={t(i18nKeys.FORM.FIRSTNAME)}
              required
              {...form.getInputProps('firstName')}
            />
            <TextInput
              label={t(i18nKeys.FORM.LASTNAME)}
              required
              mt="md"
              {...form.getInputProps('lastName')}
            />
            <TextInput
              label={t(i18nKeys.EMAIL)}
              required
              mt="md"
              {...form.getInputProps('email')}
            />
          </MantineSettingsCard>
        </Grid.Col>
        <Grid.Col span={4}>
          <MantineSettingsCard
            title={t(i18nKeys.SIGNATURE)}
            onSave={openModal}
            saveButtonLabel={t(i18nKeys.EDIT)}
          >
            {profile?.signature ? (
              <HTML html={profile.signature} />
            ) : (
              <div>{t(i18nKeys.NO_SIGNATURE)}</div>
            )}
          </MantineSettingsCard>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex h="100%" align="center" justify="flex-start" direction="column" gap="xl">
            <Avatar
              variant="light"
              size="180px"
              radius="100%"
              color="blue"
              src={avatarFile ? URL.createObjectURL(avatarFile) : profile?.profilePicture}
            >
              {profile?.name.initials}
            </Avatar>
            <Group justify="center">
              <FileButton
                onChange={(avatar) => {
                  setAvatarFile(avatar);
                  updateProfile({ avatar });
                }}
                accept="image/png,image/jpeg,image/webp,image/jpg"
              >
                {(props) => (
                  <Button loading={isUpdateProfileLoading} {...props}>
                    {t(i18nKeys.FORM.USER_SETTINGS.UPLOAD_BUTTON)}
                  </Button>
                )}
              </FileButton>
              <ActionIcon
                disabled={!canResetImage}
                variant="light"
                size={36}
                color="red"
                aria-label="Settings"
                onClick={clearFile}
              >
                <IconTrash style={{ width: '70%', height: '70%' }} size={16} stroke={1.5} />
              </ActionIcon>
            </Group>
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <MantineSettingsCard
            title={t(i18nKeys.PERSONAL_PREFENCES)}
            onSave={
              areFieldsDirty(...personalPreferencesFields)
                ? () => updateProfile(getDirtyFields(...personalPreferencesFields))
                : undefined
            }
          >
            <Group justify="space-between">
              <Box w="50%">
                <Select
                  label={t(i18nKeys.FORM.USER_SETTINGS.INTERFACE_LANG)}
                  data={AVAILABLE_LANGUAGES.map((item) => ({
                    label: item.description,
                    value: item.value,
                  }))}
                  allowDeselect={false}
                  checkIconPosition="right"
                  {...form.getInputProps('locale')}
                />
                <Select
                  label={t(i18nKeys.FORM.USER_SETTINGS.PAGINATION)}
                  allowDeselect={false}
                  checkIconPosition="right"
                  data={paginationOptions}
                  mt="md"
                  {...form.getInputProps('pageLimit')}
                  value={String(form.getInputProps('pageLimit').value)}
                />
              </Box>
              <Fieldset legend={t(i18nKeys.SETTINGS.STAFF.RECEIVE_EMAIL)} w="40%">
                <Switch
                  size="xs"
                  label={t(i18nKeys.SETTINGS.STAFF.DAILY)}
                  {...form.getInputProps('receiveDailyEmails', { type: 'checkbox' })}
                />
                <Switch
                  size="xs"
                  label={t(i18nKeys.SETTINGS.STAFF.WEEKLY)}
                  mt="md"
                  {...form.getInputProps('receiveWeeklyEmails', { type: 'checkbox' })}
                />
                <Switch
                  size="xs"
                  label={t(i18nKeys.SETTINGS.STAFF.NOTIFICATION)}
                  mt="md"
                  {...form.getInputProps('receiveNotificationEmails', { type: 'checkbox' })}
                />
              </Fieldset>
            </Group>
          </MantineSettingsCard>
        </Grid.Col>
      </Grid>
      <SignatureEditorModal
        signature={profile?.signature ?? null}
        isOpen={isSignatureModalOpen}
        onClose={closeModal}
        onSave={(signature) => updateProfile({ signature })}
      />
    </>
  );
};
