import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { DateSelector, DropdownDirection, SwitchInput } from 'shared/io';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName, IntegrationFormProps } from '../Integration.constants';
import { getIntegrationRights, getTeamleaderConnectUrl } from '../Integration.utils';
import { IntegrationForm } from '../IntegrationForm';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum TeamleaderFormFieldsName {
  IMPORT_INTEGRATION = 'use_teamleader_import_integration',
  PAYMENT_MATCHING = 'use_teamleader_payment_matching',
  PAYMENT_EXPORT = 'use_teamleader_payment_export',
}

export type TeamleaderFormFields = {
  [TeamleaderFormFieldsName.IMPORT_INTEGRATION]: boolean;
  [TeamleaderFormFieldsName.PAYMENT_MATCHING]: boolean;
  [TeamleaderFormFieldsName.PAYMENT_EXPORT]: boolean;
  [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: Date;
  [IntegrationFormFieldsName.DEBTOR_UPDATE]: boolean;
  [IntegrationFormFieldsName.AUTOMATIC_SYNC]: boolean;
};

export type TeamleaderFormProps = IntegrationFormProps<TeamleaderFormFields>;

export const TeamleaderForm = ({ onSubmit, initialValues, onboarding }: TeamleaderFormProps) => {
  const { t } = useTranslation();

  const company = useSelector((state: StoreState) => state.account.company.data!);

  const { isConnected } = getIntegrationRights(Integration.TEAMLEADER, company);

  const [preventSubmit, setPreventSubmit] = useState<boolean>(false);

  const form = useForm<TeamleaderFormFields>({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const submit = (values: TeamleaderFormFields) => {
    if (preventSubmit) {
      showDialog({
        id: DialogShowId.CONFIRM,
        title: t(i18nKeys.CONFIRM),
        children: (
          <AYSModal
            text={t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE)}
            onConfirm={() => {
              setPreventSubmit(false);
              onSubmit(values);
            }}
          />
        ),
      });
    } else {
      onSubmit(values);
    }
  };

  const debtorUpdate = watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
  const paymentMatching = watch(TeamleaderFormFieldsName.PAYMENT_MATCHING);
  const paymentExport = watch(TeamleaderFormFieldsName.PAYMENT_EXPORT);
  const importIntegration = watch(TeamleaderFormFieldsName.IMPORT_INTEGRATION);

  useEffect(() => {
    if (importIntegration) {
      setValue(
        IntegrationFormFieldsName.ISSUE_DATE_LIMIT,
        initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          : new Date(),
      );
    }
  }, [importIntegration, initialValues, setValue]);

  if (!isConnected) {
    return (
      <IntegrationForm<TeamleaderFormFields>
        integration={Integration.TEAMLEADER}
        onSubmit={handleSubmit(submit)}
        summary={i18nKeys.FORM.TEAMLEADER.DESCRIPTION}
        connectUrl={getTeamleaderConnectUrl()}
        form={form}
      />
    );
  }

  return (
    <IntegrationForm<TeamleaderFormFields>
      integration={Integration.TEAMLEADER}
      onSubmit={handleSubmit(submit)}
      summary={i18nKeys.FORM.TEAMLEADER.DESCRIPTION}
      form={form}
    >
      <div className={styles('grid-row')}>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={TeamleaderFormFieldsName.IMPORT_INTEGRATION}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                  text: Integration.TEAMLEADER,
                })}
              />
            )}
          />
          {importIntegration && (
            <>
              <div className={styles('col-12')}>
                <DateSelector
                  name={IntegrationFormFieldsName.ISSUE_DATE_LIMIT}
                  className={styles('issue-date')}
                  noMinDate
                  label={t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT)}
                  placeholder={t(i18nKeys.ISSUE_DATE)}
                  endPlaceholder={t(i18nKeys.FORM.TO)}
                  required
                  handleChange={(date: Date) => {
                    if (!onboarding) {
                      setPreventSubmit(true);
                    }
                    setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                  }}
                />
              </div>
              <div className={styles('col-12')}>
                <Controller
                  control={control}
                  name={IntegrationFormFieldsName.DEBTOR_UPDATE}
                  render={({ field: { ref, ...values } }) => (
                    <SwitchInput
                      {...values}
                      inversed
                      className={styles('switch-button', 'issue-date')}
                      label={t(
                        debtorUpdate
                          ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                          : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR,
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={TeamleaderFormFieldsName.PAYMENT_MATCHING}
            rules={{
              validate: (value) =>
                value && paymentExport ? i18nKeys.ERROR.RECONCILATION_ACTIVATED : true,
            }}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                error={errors[TeamleaderFormFieldsName.PAYMENT_MATCHING]}
                className={styles('switch-button')}
                onChange={(value) => {
                  setValue(TeamleaderFormFieldsName.PAYMENT_MATCHING, value);
                  if (value) {
                    setValue(TeamleaderFormFieldsName.PAYMENT_EXPORT, false);
                  }
                }}
                label={t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                  text: Integration.TEAMLEADER,
                })}
              />
            )}
          />
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={TeamleaderFormFieldsName.PAYMENT_EXPORT}
            rules={{
              validate: (value) =>
                value && paymentMatching ? i18nKeys.ERROR.RECONCILATION_ACTIVATED : true,
            }}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                error={errors[TeamleaderFormFieldsName.PAYMENT_EXPORT]}
                onChange={(value) => {
                  setValue(TeamleaderFormFieldsName.PAYMENT_EXPORT, value);
                  if (value) {
                    setValue(TeamleaderFormFieldsName.PAYMENT_MATCHING, false);
                  }
                }}
                className={styles('switch-button')}
                label={
                  <>
                    {t(i18nKeys.FORM.EXPORT_RECOVR_PAYMENTS, {
                      text: Integration.TEAMLEADER,
                    })}
                    <Tooltip
                      icon={IconName.INFO}
                      direction={DropdownDirection.RIGHT}
                      iconClassName={styles('icon-tooltip')}
                      contentClassName={styles('subject-tooltip')}
                    >
                      {t(i18nKeys.ERROR.RECONCILATION_ACTIVATED)}
                    </Tooltip>
                  </>
                }
              />
            )}
          />
        </div>
        <div className={styles('col-12')}>
          <Controller
            control={control}
            name={IntegrationFormFieldsName.AUTOMATIC_SYNC}
            render={({ field: { ref, ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC)}
              />
            )}
          />
        </div>
      </div>
    </IntegrationForm>
  );
};
