import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useParams } from 'react-router';
import { isDefined } from 'remeda';
import HTML from 'shared/components/HTML/HTML';
import { PageTitle } from 'shared/layout';
import { useGetCompany } from 'shared/utils/selectors';
import { formattedDate } from 'shared/utils/view';
import { EmailDeliveryStatus } from 'types/store/activity';

import { Accordion, Card, Divider, Group, Select, Stack, Text } from '@mantine/core';
import { styled } from '@stitches/react';

import { useLoadReminder } from '../api';

const EmailStatusHistory = styled('div', {});

const EmailStatusHistoryItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  padding: '10px 0px 0px 5px',
});

interface ReminderProps {}

export const Reminder = ({}: ReminderProps) => {
  const { t, currentLang } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { reminder, isReminderLoading } = useLoadReminder(id);
  const [selectedRecipient, setSelectedRecipient] = useState<string | null>();
  const {
    package: { can_use_emails_activity: canUseEmailStatus },
  } = useGetCompany()!;

  useEffect(() => {
    if (reminder != null) {
      const firstRecipient = reminder.recipients[0];
      if (isDefined(firstRecipient)) setSelectedRecipient(firstRecipient.email);
    }
  }, [reminder == null]);

  const selectedEmail = reminder?.recipients.find(
    (recipient) => recipient.email === selectedRecipient,
  );

  const flattenedStatuses = Object.keys(selectedEmail?.statuses ?? {}).reduce(
    (memo, currentKey) => [
      ...memo,
      ...selectedEmail?.statuses![currentKey].map((date) => ({
        status: currentKey,
        timestamp: dayjs(date),
      })),
    ],
    [] as Array<{ status: EmailDeliveryStatus; timestamp: Dayjs }>,
  );

  const sortedStatuses = flattenedStatuses.sort((a, b) => b.timestamp.diff(a.timestamp));

  if (isReminderLoading) return null;

  return (
    <>
      <PageTitle>{t(i18nKeys.REMINDER.REMINDER)}</PageTitle>
      <Card>
        <Stack gap="lg">
          <Group>
            <Text fw="bold" c="dimmed" fz="xs" w={120}>
              {t(i18nKeys.REMINDER.SENT_TO)}
            </Text>
            <Select
              w={300}
              size="sm"
              data={reminder?.recipients.map((recipient) => recipient.email)}
              value={selectedRecipient}
              onChange={setSelectedRecipient}
              allowDeselect={false}
            />
          </Group>
          <Group>
            <Text fw="bold" c="dimmed" fz="xs" w={120}>
              {t(i18nKeys.REMINDER.DATE_SENT)}
            </Text>
            <Text>{dayjs(selectedEmail?.sentAt).format('dddd DD MMMM YYYY - HH:mm')}</Text>
          </Group>
        </Stack>
        {sortedStatuses.length !== 0 && canUseEmailStatus && (
          <Group pt="sm">
            <Text fw="bold" c="dimmed" fz="xs" w={120}>
              {t(i18nKeys.REMINDER.DELIVERY_STATUS)}
            </Text>
            <Accordion variant="separated" w={300}>
              <Accordion.Item value="1">
                <Accordion.Control>{t(i18nKeys.REMINDER.SEE_DELIVERY_HISTORY)}</Accordion.Control>
                <Accordion.Panel>
                  <EmailStatusHistory>
                    {sortedStatuses.map((statusObject, index) => (
                      <EmailStatusHistoryItem key={index}>
                        <span>{t(i18nKeys.MAILS.STATUS[statusObject.status.toUpperCase()])}</span>
                        <span style={{ fontStyle: 'italic', color: '#bbb' }}>
                          {formattedDate(
                            statusObject.timestamp.toDate(),
                            currentLang,
                            'DD/MM/YYYY - HH:mm',
                          )}
                        </span>
                      </EmailStatusHistoryItem>
                    ))}
                  </EmailStatusHistory>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Group>
        )}
        <Divider my="xl" />
        <HTML html={selectedEmail?.body} />
      </Card>
    </>
  );
};
