import { i18nKeys, useTranslation } from 'locales';
import { Controller } from 'react-hook-form';
import { useLoadLightUsers } from 'shared/hooks';
import { CustomSelect } from 'shared/io';

import UserInfo from './UserInfo';

interface AccountManagerSelectorProps {
  shadow?: boolean;
  name?: string;
  loadInactive?: boolean;
  showMargin?: boolean;
}

export const AccountManagerSelector = ({
  shadow = false,
  name = 'accountManagerId',
  loadInactive = false,
  showMargin = false,
}: AccountManagerSelectorProps) => {
  const { t } = useTranslation();
  const { users = [] } = useLoadLightUsers(loadInactive);

  return (
    <Controller
      name={name}
      render={() => (
        <CustomSelect
          placeholder={t(i18nKeys.DASHBOARD.SELECT_ACCOUNT_MANAGER)}
          noMargin={!showMargin}
          keyValue="id"
          withBorder
          shadow={shadow}
          itemRendering={(props) => <UserInfo picture {...props} />}
          valueRendering={(props) => <UserInfo inline {...props} />}
          name={name}
          items={users}
          label={t(i18nKeys.ACCOUNT_MANAGER)}
        />
      )}
    />
  );
};
