import { treatActivity, treatCompany } from 'shared/serializer';
import { removeAttributes } from 'shared/utils/view';
import { clientConstants } from 'store/client/client.actions';
import {
  baseReducerData,
  baseReducerListPage,
  handlePageRequest,
  handlePageResponse,
  handleRequest,
  handleResponse,
  handleUpdatePaginationResponse,
} from 'store/reducers';
import { settingsConstants } from 'store/settings/settings.actions';
import { ReduxAction } from 'types/redux';
import { AccountState } from 'types/store/account-state';

import { invoiceConstants } from '../invoice/invoice.actions';

import { accountConstants as events } from './account.actions';

export const initialState: AccountState = {
  activity: baseReducerListPage,
  company: baseReducerData,
  activityDetail: null,
};

const changeUnvalidateInvoices = (
  state,
  unvalidated_invoices_count,
  unvalidated_debtors_count,
) => ({
  ...state,
  company: {
    ...state.company,
    data: {
      ...state.company.data,
      unvalidated_invoices_count,
      unvalidated_debtors_count,
    },
  },
});

const changeUnvalidateClients = (state, unvalidated_debtors_count) => ({
  ...state,
  company: {
    ...state.company,
    data: {
      ...state.company.data,
      unvalidated_debtors_count,
    },
  },
});

const updateCustomVariablesList = (state, custom_variables) => {
  const customVariablesAttributes = custom_variables.map((cusVarAttributes) =>
    removeAttributes(cusVarAttributes),
  );
  return {
    ...state,
    company: {
      ...state.company,
      data: {
        ...state.company.data,
        custom_variables_attributes: customVariablesAttributes,
      },
    },
  };
};

const reducer = (state: AccountState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case events.getCompany.request:
      return handleRequest(state, action, 'company');
    case events.getCompany.result:
      return handleResponse(state, action, 'company', treatCompany);
    case events.updateCompany.result:
      return handleResponse(state, action, 'company', treatCompany);
    case events.activityPage.request:
      return handlePageRequest(state, action, 'activity');
    case events.activityPage.result:
      return handlePageResponse(state, action, 'activity', treatActivity);
    case events.activityDetail.request:
      return handlePageRequest(state, action, 'activityDetail');
    case events.activityDetail.result:
      return handlePageResponse(state, action, 'activityDetail', treatActivity);
    case events.activitySeen.result:
      const tmp = handleUpdatePaginationResponse(state, action, 'activity', treatActivity);
      return tmp;
    case invoiceConstants.importInvoiceCSV.result:
      return changeUnvalidateInvoices(
        state,
        action.payload.unvalidated_invoices_count,
        action.payload.unvalidated_debtors_count,
      );
    case invoiceConstants.validateAllImportedInvoices.result:
      return changeUnvalidateInvoices(state, 0, action.payload.unvalidated_debtors_count);
    case invoiceConstants.deleteInvoiceToConfirm.result:
      return changeUnvalidateInvoices(
        state,
        state.company.data!.unvalidated_invoices_count - 1,
        action.payload.unvalidated_debtors_count,
      );
    case clientConstants.getAllToConfirm.result:
      return changeUnvalidateClients(state, action.payload.data.length);
    case clientConstants.validateAllImportedClients.result:
      return changeUnvalidateClients(state, action.payload.unvalidated_debtors_count);
    case clientConstants.deleteClientToConfirm.result:
      if (action.status === 'fulfilled')
        return changeUnvalidateClients(state, state.company.data!.unvalidated_debtors_count - 1);
      return state;
    case settingsConstants.customVariableListPage.result:
      return updateCustomVariablesList(state, action.payload.data);
    default:
      return state;
  }
};

export default reducer;
