import { useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { ExportCsv } from 'shared/components/ExportCsv';
import { appActions } from 'store/app/app.actions';
import { StoreState } from 'types/storeTypes';

export function ExportCreditNoteCsv() {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);

  const [{ exportFields, wrappers }] = useState({
    exportFields: constants.credit_note_export_columns.debtor_fields.concat(
      constants.credit_note_export_columns.credit_note_fields,
    ),
    wrappers: [
      {
        name: 'selectInvoice',
        fields: constants.credit_note_export_columns.credit_note_fields,
        text: t(i18nKeys.CREDIT_NOTE),
      },
      {
        name: 'selectDebtor',
        fields: constants.credit_note_export_columns.debtor_fields,
        text: t(i18nKeys.INVOICING.EXPORT_CSV.CLIENT_DATA),
      },
    ],
  });

  const downloadCsv = (data) => {
    appActions.export({
      data: {
        ...data,
        ...(data.export_with_filters === true ? qs.parse(location.search) : {}),
        view_id: location.hash.slice(1),
        resource_type: 'credit_note',
        type: 'csv',
      },
      noLoading: true,
    });
  };

  return (
    <ExportCsv
      downloadCsv={downloadCsv}
      allFields={exportFields}
      wrappers={wrappers}
      selectAllLabel={t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_ALL_CREDIT_NOTE)}
      selectFilteredLabel={t('INVOICING.EXPORT_CSV.EXPORT_FILTERED_CREDIT_NOTE')}
    />
  );
}
