import { useLayoutEffect, useState } from 'react';
import { useGetPaymentPlans } from 'api/index';
import { GetPaymentPlansParams } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import { useProfile } from 'shared/hooks';
import { Button } from 'shared/io';
import { StoreState } from 'types/storeTypes';

import { PaymentPlanStatus, TABLE_HEADERS } from '../PaymentPlan.constants';

import { PaymentPlansListFilters } from './PaymentPlansListFilters';
import { PaymentPlanListItem } from './PaymentPlansListItem';

import styleIdentifiers from './PaymentPlansList.module.scss';

const styles = classNames.bind(styleIdentifiers);
const EnhancedTable = CustomTable(PaymentPlanListItem, PaymentPlansListFilters);

export const PaymentPlansList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const profile = useProfile();

  const {
    package: { can_use_payment_plans },
  } = useSelector((state: StoreState) => state.account.company.data!);

  const [params, setParams] = useState<GetPaymentPlansParams>({
    page_limit: profile.preferences.itemsPerPage,
    page: 1,
  });

  useLayoutEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<GetPaymentPlansParams>(Object.fromEntries(new URLSearchParams(location.search)), [
        'page',
        'page_limit',
        'sort_by',
        'sort_order',
        'status',
        'debtor_id',
        'invoice_ids',
      ]),
    }));
  }, []);

  const {
    data: PaymentPlansListResponse,
    isLoading,
    isSuccess,
    refetch,
  } = useGetPaymentPlans(params);

  const addPaymentPlan = () => {
    history.push('/payment-plans/create');
  };

  const headers = TABLE_HEADERS.map(({ title, ...rest }) => ({
    title: title && t(title),
    ...rest,
  }));

  const onSortChange = (newParams: GetPaymentPlansParams) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']),
    }));
  };

  const handleFilters = (data: GetPaymentPlansParams) => {
    const filters = {
      ...pick(params, ['page_limit', 'page']),
      ...data,
    };
    setParams(filters);
    return filters;
  };

  const planStatus = Object.values(PaymentPlanStatus).map((value) => ({
    description: t(i18nKeys[value]),
    value: value.toLowerCase(),
  }));

  const filtersNames = {
    status: {
      description: t(i18nKeys.TASK.ATTRIBUTES.STATUS),
      value: planStatus,
    },
    invoice_ids: {
      description: t(i18nKeys.TASK.ATTRIBUTES.INVOICE),
    },
    debtor_id: {
      description: t(i18nKeys.TASK.ATTRIBUTES.DEBTOR),
    },
  };

  return (
    <div className={styles('listing')}>
      <EnhancedTable
        title={t(i18nKeys.NAV.PAYMENT_PLAN)}
        headers={headers}
        items={PaymentPlansListResponse?.data || []}
        pagination={PaymentPlansListResponse?.metadata.pagination}
        loading={isLoading}
        loaded={isSuccess}
        itemProps={{ refetch }}
        showShadow
        handleFilters={handleFilters}
        onSortChange={onSortChange}
        filtersNames={filtersNames}
        noCheckbox
        actions={
          <Button
            className={styles('action-button')}
            noMargin
            label={t(i18nKeys.NEW_PAYMENT_PLAN)}
            onClick={addPaymentPlan}
            iconRight={IconName.PLUS}
            disabled={!can_use_payment_plans}
            title={can_use_payment_plans ? '' : t(i18nKeys.NOT_INCLUDED)}
          />
        }
      />
    </div>
  );
};
