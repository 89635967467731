import { MouseEventHandler, PropsWithChildren } from 'react';
import { isDefined } from 'remeda';

import { css, cx } from '@emotion/css';
import { Card } from '@mantine/core';

const styles = {
  clickable: css`
    cursor: pointer;
    transition: all 150ms ease-in-out;

    &:hover {
      box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.05) 0 10px 15px -5px,
        rgba(0, 0, 0, 0.04) 0 7px 7px -5px;
    }
  `,
};

export interface KanbanCardProps
  extends PropsWithChildren<{
    color?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
  }> {}

export const KanbanCard = ({ children, color, onClick }: KanbanCardProps) => (
  <Card
    className={cx({ [styles.clickable]: isDefined(onClick) })}
    shadow="xs"
    onClick={onClick}
    p={8}
    style={{
      borderLeft: `4px solid ${color}`,
      flexShrink: 0,
    }}
  >
    {children}
  </Card>
);
