import { upperFirst } from 'lodash';

import { useMutation, UseMutationResult } from '@tanstack/react-query';

export type UseActionMutationResult<Response, TError, TVariables, ActionName extends string> = {
  [K in 'data' as `${ActionName}Result`]: Response | undefined;
} & {
  [K in 'error' as `${ActionName}${Capitalize<K>}`]: TError | null;
} & {
  [K in 'isLoading' as `is${Capitalize<ActionName>}Loading`]: boolean;
} & {
  [K in 'mutate' as `${ActionName}`]: UseMutationResult<Response, TError, TVariables>['mutate'];
};

export function addActionNameToMutationResult<
  Response,
  TError,
  TVariables,
  ActionName extends string,
>(
  actionName: ActionName,
  mutationResult: ReturnType<typeof useMutation<Response, TError, TVariables>>,
): UseActionMutationResult<Response, TError, TVariables, ActionName> {
  const actionNameCapitalised = upperFirst(actionName);

  return {
    [`${actionName}Result`]: mutationResult.data,
    [`${actionName}Error`]: mutationResult.error,
    [`is${actionNameCapitalised}Loading`]: mutationResult.isPending,
    [actionName]: mutationResult.mutate,
  } as UseActionMutationResult<Response, TError, TVariables, ActionName>;
}
