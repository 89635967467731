import { useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { ExportCsv } from 'shared/components/ExportCsv';
import { appActions } from 'store/app/app.actions';
import { StoreState } from 'types/storeTypes';

export const ExportTasksCsv = () => {
  const { t } = useTranslation();

  const constants = useSelector((state: StoreState) => state.app.constants);

  const [{ exportFields, wrappers }] = useState({
    exportFields: constants.task_export_columns.task_fields,
    wrappers: [
      {
        name: 'selectTaskInfo',
        fields: constants.task_export_columns.task_fields,
        text: t(i18nKeys.COMMON.CLIENT),
      },
    ],
  });

  const downloadCsv = (data) => {
    appActions.export({
      data: {
        ...data,
        ...(data.export_with_filters === true ? qs.parse(location.search) : {}),
        view_id: location.hash.slice(1),
        resource_type: 'task',
        type: 'csv',
      },
      noLoading: true,
    });
  };

  return (
    <ExportCsv
      downloadCsv={downloadCsv}
      allFields={exportFields}
      wrappers={wrappers}
      selectAllLabel={t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_ALL_TASKS)}
      selectFilteredLabel={t('INVOICING.EXPORT_CSV.EXPORT_FILTERED_TASKS')}
    />
  );
};
