import { Currency } from 'api/models';
import classNames from 'classnames/bind';
import Amount from 'shared/components/Amount';
import Percentage from 'shared/components/Percentage';
import { ProductLine } from 'types/store/invoice-state';

import styleIdentifiers from './tableDetails.module.scss';

const styles = classNames.bind(styleIdentifiers);

export type TableDetailsItemProps = {
  productLine: ProductLine;
  suffix: Currency;
  noTva?: boolean;
};

export default function TableDetailsItem({ productLine, noTva, suffix }: TableDetailsItemProps) {
  const {
    current_product_name,
    quantity,
    current_unity_price_htva,
    discount_rate,
    total_htva,
    current_vat_rate,
    total_tvac,
  } = productLine;

  const isInvoice = !!productLine?.linked_invoice_id;

  return (
    <tr>
      <td>{current_product_name}</td>
      <td>{!isInvoice ? quantity : '-'}</td>
      <td>
        <Amount value={current_unity_price_htva} suffix={suffix} />
      </td>
      <td>{!isInvoice ? <Percentage value={discount_rate} /> : '-'}</td>
      <td>
        <Amount value={total_htva} suffix={suffix} />
      </td>
      <td>{!isInvoice ? <Percentage value={noTva ? 0 : current_vat_rate} /> : '-'}</td>
      <td className={styles('total')}>
        <Amount value={total_tvac} suffix={suffix} />
      </td>
    </tr>
  );
}
