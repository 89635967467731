import { upperFirst } from 'lodash-es';
import { BarChartTooltip } from 'shared/charts';
import { Cei, useProfile } from 'shared/hooks';

import { Text, useMantineTheme } from '@mantine/core';
import { ResponsiveLine } from '@nivo/line';

const Y_STEPS = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

interface CeiChartProps {
  cei: Cei;
}

export const CeiChart = ({ cei }: CeiChartProps) => {
  const profile = useProfile();
  const theme = useMantineTheme();

  return (
    <ResponsiveLine
      isInteractive
      enableSlices="x"
      enableArea
      defs={[
        {
          colors: [
            {
              color: 'inherit',
              offset: 0,
            },
            {
              color: 'inherit',
              offset: 100,
              opacity: 0,
            },
          ],
          id: 'gradientA',
          type: 'linearGradient',
        },
      ]}
      fill={[
        {
          id: 'gradientA',
          match: '*',
        },
      ]}
      useMesh
      curve="monotoneX"
      enableCrosshair
      margin={{ top: 10, right: 15, bottom: 35, left: 55 }}
      axisBottom={{
        tickPadding: 15,
        tickSize: 0,
        format: (date: Date) => date.toLocaleDateString(profile.locale, { month: 'short' }),
      }}
      axisLeft={{
        tickPadding: 15,
        tickValues: Y_STEPS,
        tickSize: 0,
        format: (value: number) => `${value} %`,
      }}
      sliceTooltip={(d) => {
        setTimeout(() => {
          const tooltip = document.getElementById('tooltip-fix');
          if (tooltip) tooltip.style.opacity = '1';
        }, 50);

        return (
          <BarChartTooltip
            style={{ opacity: 0, transition: 'all 0.1s ease-in-out' }}
            id="tooltip-fix"
          >
            <Text fw={700} mr={7}>
              {`${upperFirst(
                (d.slice.points[0].data.x as Date).toLocaleDateString(profile.locale, {
                  month: 'long',
                }),
              )}:`}
            </Text>
            <Text>{`${d.slice.points[0].data.y} %`}</Text>
          </BarChartTooltip>
        );
      }}
      yScale={{ type: 'linear', min: 0, max: 100 }}
      gridYValues={Y_STEPS}
      enableGridX={false}
      pointSize={8}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      colors={[theme.colors.blue[7]]}
      pointColor="white"
      theme={{
        grid: {
          line: {
            stroke: theme.colors.gray[3],
            strokeDasharray: 7,
            strokeDashoffset: 15,
          },
        },
        labels: {
          text: {
            fontSize: 14,
            fontFamily: 'Work Sans',
            fill: theme.colors.gray[6],
          },
        },
        text: {
          fontSize: 12,
          fontFamily: 'Work Sans',
          fill: theme.colors.gray[6],
        },
      }}
      data={[
        {
          id: 'cei',
          color: theme.colors.cyan[4],
          data: Object.entries(cei).map(([key, value]) => ({ x: new Date(key), y: value })),
        },
      ]}
    />
  );
};
