import { i18nKeys, useTranslation } from 'locales';
import { mergeWith, subtract } from 'lodash-es';
import { clone } from 'remeda';
import { useLoadAgedBalanceCompact } from 'shared/hooks';
import { useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';

import { BaseTicker } from '../BaseTicker';

interface TotalReceivableTickerProps {}

export const TotalReceivableTicker = ({}: TotalReceivableTickerProps) => {
  const { t } = useTranslation();
  const { agedBalanceCompact, isAgedBalanceCompactLoading } = useLoadAgedBalanceCompact();
  const formatter = useSafeLocalizedCompactCurrencyFormatter(true);

  if (isAgedBalanceCompactLoading || agedBalanceCompact == null) {
    return <BaseTicker label={t(i18nKeys.ANALYTICS.TICKERS.TOTAL.TITLE)} />;
  }

  const netAgedBalance = mergeWith(
    clone(agedBalanceCompact.debits),
    agedBalanceCompact.credits,
    subtract,
  );

  const totalReceivable =
    netAgedBalance.notDue +
    netAgedBalance['0'] +
    netAgedBalance['30'] +
    netAgedBalance['60'] +
    netAgedBalance['90'];

  return (
    <BaseTicker
      label={t(i18nKeys.ANALYTICS.TICKERS.TOTAL.TITLE)}
      value={formatter.format(totalReceivable)}
      suffix="€"
      url="analytics/aged-balance"
    />
  );
};
