import { useEffect, useState } from 'react';
import { useCreateTask, useGetTasks, useUpdateTasksStatus } from 'api/index';
import {
  CreateTaskBody,
  GetTasksParams,
  Task,
  TaskActionsResponse,
  UpdateTasksStatusBody,
} from 'api/models';
import { ExportButton } from 'app/Private/Clients/Listing/BatchActions/ExportButton';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import ListResModal from 'shared/components/DeleteModal';
import { IconName } from 'shared/components/Icon';
import { useProfile } from 'shared/hooks';
import { Button } from 'shared/io';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';

import { TasksListFiltersFields } from '../Task.constants';
import { TaskActions } from '../TaskActions/TaskActions';
import { TaskForm } from '../TaskForm/TaskForm';

import { ExportTasksCsv } from './ExportTasksCsv';
import { TaskListItemActions } from './TasksListItemActions';

import styleIdentifiers from './TasksList.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const CustomTasksList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const profile = useProfile();

  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [areAllItemsAcrossPagesSelected, setAreAllItemsAcrossPagesSelected] = useState(false);

  const [params, setParams] = useState<GetTasksParams>({
    view_id: location.hash.slice(1),
    page_limit: profile.preferences.itemsPerPage ?? 20,
    page: 1,
  });

  const { data: tasksListResponse, isLoading, refetch } = useGetTasks(params);
  const { mutate: createTaskMutation } = useCreateTask();
  const { mutate: updateTasksStatus } = useUpdateTasksStatus();

  useEffect(() => {
    setParams(() => ({
      view_id: location.hash.slice(1),
      page_limit: profile.preferences.itemsPerPage ?? 20,
      page: 1,
      ...pick<GetTasksParams>(Object.fromEntries(new URLSearchParams(location.search)), [
        'page',
        'page_limit',
        'sort_by',
        'sort_order',
        'title',
        'title_handler',
        'description',
        'description_handler',
        'status',
        'status_handler',
        'user_id',
        'user_id_handler',
        'debtors__full_name',
        'debtors__full_name_handler',
        'invoice_ids',
        'invoice_ids_handler',
        'mandatory',
        'mandatory_handler',
        'task_type_id',
        'task_type_id_handler',
        'recovery_plan_id',
        'recovery_plan_id_handler',
        'days_postponed',
        'min_days_postponed',
        'max_days_postponed',
        'days_postponed_handler',
        'created_at_start',
        'created_at_handler',
        'created_at_end',
        'due_date_start',
        'due_date_handler',
        'due_date_end',
        'updated_at_start',
        'updated_at_handler',
        'updated_at_end',
        'skipped_at_start',
        'skipped_at_handler',
        'skipped_at_end',
        'completed_at_start',
        'completed_at_handler',
        'completed_at_end',
        'quick_search',
      ]),
    }));
  }, [location, setParams]);

  const addTask = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <TaskForm
          onSubmit={(data: CreateTaskBody) =>
            createTaskMutation(
              { data },
              {
                onSuccess: () => {
                  sideMenuHide();
                  refetch();
                },
              },
            )
          }
        />
      ),
    });
  };

  const batchActions = (data: UpdateTasksStatusBody) =>
    updateTasksStatus(
      {
        data,
      },
      {
        onSuccess: (response: TaskActionsResponse) => {
          if (Array.isArray(response)) {
            showDialog({
              id: DialogShowId.CUSTOM,
              size: DialogShowSize.MEDIUM,
              title: t(i18nKeys.RESULT),
              children: <ListResModal data={response} />,
            });
          } else {
            dialogHide(DialogShowId.CUSTOM);
          }
          refetch();
        },
      },
    );

  const handleFilters = (data: TasksListFiltersFields) => {
    const filters = {
      ...pick(params, ['page_limit', 'page']),
      ...data,
    };

    setParams(filters);
    return filters;
  };

  const taskIds = areAllItemsAcrossPagesSelected ? [] : selectedTasks.map((task) => task.id);

  const goToDetails = (task) => () => {
    history.push(`/tasks/${task.id}`);
  };

  const actionsCol = (item) => <TaskListItemActions item={item} noCheckbox refetch={refetch} />;

  const handleClickExport = () => {
    sideMenuHide();
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.EXPORT_CSV),
      children: <ExportTasksCsv />,
    });
  };

  return (
    <div className={styles('listing')}>
      <CustomTableViews
        title={t(i18nKeys.TASK.TITLE)}
        callbackAction={refetch}
        onClickRow={goToDetails}
        actions={
          <>
            <ExportButton exportCsv={handleClickExport} />
            {areAllItemsAcrossPagesSelected || !!selectedTasks.length ? (
              <div className={styles('actions')}>
                <TaskActions taskIds={taskIds} onClick={batchActions} />
              </div>
            ) : (
              <Button
                className={styles('action-button')}
                noMargin
                label={t(i18nKeys.NEW_TASK)}
                onClick={addTask}
                iconRight={IconName.PLUS}
              />
            )}
          </>
        }
        isLoading={isLoading}
        pagination={tasksListResponse?.metadata.pagination}
        handleFilters={handleFilters}
        items={tasksListResponse?.data || []}
        onChangeSelectionCallback={(vals) => setSelectedTasks(vals as Task[])}
        onChangeSelectItemsAcrossAllPagesCallback={setAreAllItemsAcrossPagesSelected}
        tableName="tasks"
        actionsCol={actionsCol}
        loadDataGetView={false}
      />
    </div>
  );
};
