import dayjs from 'dayjs';
import { merge, pick } from 'lodash-es';
import moment from 'moment';
import { treatInvoice } from 'shared/serializer';
import { momentToIsoDate } from 'shared/utils/date';
import { getCgv } from 'shared/utils/selectors';
import { Company } from 'types/store/account-state';
import { TaxCategories } from 'types/store/app-state';
import { Debtor } from 'types/store/client-state';
import { CreditNote } from 'types/store/credit-note-state';
import { Invoice, LightInvoice } from 'types/store/invoice-state';

import { CustomProductLine, InvoiceFormFieldValues } from './InvoiceForm.types';

const NOW = dayjs();

export const getDefaultIssueDate = () => NOW;

export const getDefaultDueDate = (company?: Company) =>
  NOW.add(getCgv(company)?.minimum_payment_terms_delay || 0, 'day');

export const isNoTva = (debtor?: Debtor) => debtor?.intracom || debtor?.co_contractor;

export const getCurrency = (company?: Company, debtor?: Debtor, values?: Invoice | CreditNote) =>
  values?.currency || debtor?.currency || company?.currency;

export const getInvoicesForReference = (data: any, references: string[]) =>
  data
    .map((e) => treatInvoice<LightInvoice>(e))
    .filter((e) => references.includes(String(e.reference)));

export const getInvoicesForDebtor = (filteredInvoices: LightInvoice[], debtor: Debtor) =>
  filteredInvoices.filter((e) => Number(e.debtor_attributes.id) === Number(debtor.id));

const getBillingLogId = (id?: number) => {
  if (!id) {
    return undefined;
  }

  return {
    billing_log_id: id,
  };
};

const getDate = (key: keyof InvoiceFormFieldValues, date?: moment.Moment) => {
  if (!date) {
    return undefined;
  }

  return {
    [key]: momentToIsoDate(date as unknown as moment.Moment),
  };
};

const getMatchedPayments = <T extends Invoice | CreditNote>({ matched_payments_attributes }: T) => {
  if (!matched_payments_attributes) {
    return undefined;
  }

  return {
    invoices_to_link: matched_payments_attributes.map(({ id }) => id),
  };
};

export const getTvaRate = (
  tax_categories: TaxCategories[],
  tax_category_code?: string,
  noTva = false,
) =>
  !noTva && tax_category_code
    ? tax_categories.find((it) => it.value === tax_category_code)?.vat_value || 0
    : 0;

export const getTotalHtva = ({
  quantity,
  discount_rate,
  product_attributes: { current_unity_price_htva = 0 },
}: CustomProductLine) =>
  Number(quantity) * Number(current_unity_price_htva) * (1 - (Number(discount_rate) || 0) / 100);

export const getTotalTvac = (productLine: CustomProductLine, tvaRate: number | string) =>
  getTotalHtva(productLine) * (1 + Number(tvaRate) / 100);

const getProductLines = <T extends Invoice | CreditNote>({ product_lines_attributes }: T) => ({
  product_lines_attributes: product_lines_attributes.map((e) => ({
    ...e,
    product_id: e.id,
    isInvoice: !!e.linked_invoice_id,
  })),
});

const getObjFormValues = <T extends Invoice | CreditNote>(obj: T) => ({
  ...pick(obj, ['id', 'debtor_attributes', 'comment']),
  ...getDate('issue_date', obj.issue_date as unknown as moment.Moment),
  ...getDate('due_date', obj.due_date as unknown as moment.Moment),
  ...getBillingLogId(obj?.billing_log_attributes?.id),
  ...getMatchedPayments(obj),
  ...getProductLines(obj),
});

export const getCreditNoteFormValues = (
  creditNote?: CreditNote,
): Partial<InvoiceFormFieldValues> => (creditNote ? getObjFormValues<CreditNote>(creditNote) : {});

export const getInvoiceFormValues = (invoice?: Invoice): Partial<InvoiceFormFieldValues> =>
  invoice ? getObjFormValues<Invoice>(invoice) : {};

export const getProductLine = (
  noTva: boolean,
  data: Partial<CustomProductLine> = {},
): Partial<CustomProductLine> =>
  merge(
    {
      quantity: 1,
      total_htva: 0,
      total_tvac: 0,
      discount_rate: 0,
      product_attributes: {
        name: '',
        tax_category_code: noTva ? '00' : '',
        current_unity_price_htva: 0,
      },
    },
    data,
  );
