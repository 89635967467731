import { Id } from 'api/models';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { isNonNull } from 'remeda';
import { Monitoring } from 'shared/hooks';
import { useSubscribeToMonitoring } from 'shared/hooks/use-subscribe-to-monitor';

import { Button, Group, Text } from '@mantine/core';
import { lowerFirst } from '@mantine/hooks';
import { IconRadar } from '@tabler/icons-react';

import { UpdateMonitoringDataButton } from './UpdateMonitoringDataButton';

interface MonitoringStatusActionsProps {
  hasVatNumber: boolean;
  debtorId: Id;
  isExperimentalEnabled: boolean;
  isMonitoringActive: NonNullable<Monitoring>['isMonitoringActive'];
  updatedAt: NonNullable<Monitoring>['updatedAt'];
}

export const MonitoringStatusActions = ({
  isExperimentalEnabled,
  isMonitoringActive,
  updatedAt,
  hasVatNumber,
  debtorId,
}: MonitoringStatusActionsProps) => {
  const { t } = useTranslation();
  const { subscribeToMonitoring, isSubscribeToMonitoringLoading } = useSubscribeToMonitoring();

  return (
    <Group justify="flex-end">
      {!isExperimentalEnabled && !isMonitoringActive && (
        <Group>
          {isNonNull(updatedAt) && (
            <Text size="sm" c="gray.5" fs="italic">
              {t(i18nKeys.ANALYTICS.LAST_UPDATED)}
              {lowerFirst(dayjs(dayjs(updatedAt)).calendar())}
            </Text>
          )}
          <UpdateMonitoringDataButton hasVatNumber={hasVatNumber} debtorId={Number(debtorId)} />
        </Group>
      )}
      {isMonitoringActive && (
        <Group>
          <Text size="sm" c="gray.5" fs="italic">
            {t(i18nKeys.DEBTOR.MONITORING.SUBSCRIPTION_ACTIVE, {
              date: lowerFirst(dayjs(dayjs(updatedAt)).calendar()),
            })}
          </Text>
        </Group>
      )}
      {isExperimentalEnabled && !isMonitoringActive && (
        <Group>
          {isNonNull(updatedAt) && (
            <Text size="sm" c="gray.5" fs="italic">
              {t(i18nKeys.ANALYTICS.LAST_UPDATED)}
              {lowerFirst(dayjs(dayjs(updatedAt)).calendar())}
            </Text>
          )}
          <UpdateMonitoringDataButton hasVatNumber={hasVatNumber} debtorId={Number(debtorId)} />
          <Button
            leftSection={<IconRadar stroke={1.5} />}
            loading={isSubscribeToMonitoringLoading}
            onClick={() => subscribeToMonitoring({ debtorId: String(debtorId) })}
          >
            {t(i18nKeys.DEBTOR.MONITORING.SUBSCRIBE)}
          </Button>
        </Group>
      )}
    </Group>
  );
};
