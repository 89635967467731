import { useState } from 'react';
import { useGetDebtorsInfinite, useMergeSimilarDebtors } from 'api';
import { MergeSimilarDebtorsBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { filter, flat, map, pipe } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import { clientActions } from 'store/client/client.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import { AttributesSelection } from './AttributesSelection';

import styleIdentifiers from './SelectDuplicateDebtorForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskFormDebtorProps = {
  currentDebtor: Debtor;
};

export const SelectDuplicateDebtorForm = ({ currentDebtor }: TaskFormDebtorProps) => {
  const { t } = useTranslation();
  const [selectSearchValue, setSelectSearchValue] = useState<string>();
  const { data: debtorsResponse, fetchNextPage } = useGetDebtorsInfinite(
    { name: selectSearchValue },
    {
      query: {
        getNextPageParam,
      },
    },
  );

  const pages = debtorsResponse?.pages ?? [];
  const concatedDebtors = pipe(
    pages,
    map((e) => e.data),
    flat(),
    filter((e: { id: string | number }) => e.id !== currentDebtor?.id),
  );

  const treatedDebtors = concatedDebtors.map(treatClient);

  const { mutate: mergeDebtors } = useMergeSimilarDebtors();

  const submitMerge = (data: MergeSimilarDebtorsBody) => {
    mergeDebtors(
      { data },
      {
        onSuccess: () => {
          clientActions.detail({ id: currentDebtor.id });
          dialogHide(DialogShowId.CUSTOM);
        },
      },
    );
  };

  const openAttributesSelectionModal = (debtorToMergeId) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(i18nKeys.CLIENT.MERGE.TITLE),
      children: (
        <AttributesSelection
          baseDebtorId={currentDebtor.id as any as string}
          debtors={
            [
              {
                id: currentDebtor.id,
                attributes: {
                  ...currentDebtor,
                  phones: currentDebtor.phones_attributes.map((e) => ({
                    id: e.id,
                    attributes: { number: e.number },
                  })),
                  debtor_bank_accounts: currentDebtor.debtor_bank_accounts_attributes,
                  address: {
                    attributes: currentDebtor.address_attributes,
                  },
                },
              },
              concatedDebtors.find((debtor) => debtorToMergeId === debtor.id),
            ] as any
          }
          onSubmit={(data: MergeSimilarDebtorsBody) => submitMerge(data)}
        />
      ),
    });
  };

  const form = useForm();

  return (
    <FormProvider {...form}>
      <form className={styles('duplicate-debtor')}>
        <div className={styles('select-client')}>
          <Controller
            name="debtor_id"
            render={() => (
              <CustomSelect
                selectClassName={styles('input')}
                filter
                withBorder
                infiniteScroll
                items={treatedDebtors}
                keyValue="id"
                keyText="full_name"
                name="debtor_id"
                load={fetchNextPage}
                onChangeFilter={setSelectSearchValue}
                onValueChanged={openAttributesSelectionModal}
              />
            )}
          />
        </div>
      </form>
    </FormProvider>
  );
};
