import { all, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { downloadFile, extractFilename } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { api } from 'store/apis';

import makeApiCall, { sendApi } from '../sagas';

import { settingsActions, settingsConstants } from './settings.actions';

function* previewTemplate({ payload }: any) {
  if (payload.download) {
    payload.noFeedback = true;
    payload.errors = 'ERROR.DOC_NOT_AVAILABLE';
  }
  const request = yield call(makeApiCall, {
    api: api.template.preview,
    ...payload,
  });

  if (
    payload.data.template_type === 'post' &&
    request &&
    request.status === 200 &&
    payload.download
  ) {
    const filename = extractFilename(request.headers);
    downloadFile(request.data, filename || "template'}");
  }
}

function* settingsWatchers() {
  yield all([
    yield takeLatest(
      settingsConstants.editDebtCollection.request,
      sendApi(api.settings.editDebtCollection, accountActions.getCompanyRes),
    ),
    yield takeLatest(settingsConstants.addTwikey.request, sendApi(api.settings.addTwikey)),
    // template
    yield takeLatest(
      settingsConstants.templateListPage.request,
      sendApi(
        api.template.list,
        settingsActions.templateListPageRes,
        ({ callback, ...payload }) => ({
          data: payload,
          callback,
          noLoading: true,
        }),
      ),
    ),
    yield takeLatest(settingsConstants.templateList.request, sendApi(api.template.list, undefined)),
    yield takeLatest(settingsConstants.getTemplate.request, sendApi(api.template.detail)),
    yield takeLatest(settingsConstants.addTemplate.request, sendApi(api.template.add)),
    yield takeLatest(
      settingsConstants.setTemplate.request,
      sendApi(api.template.update, settingsActions.getTemplateRes),
    ),
    yield takeLatest(settingsConstants.deleteTemplate.request, sendApi(api.template.delete)),
    yield takeLatest(settingsConstants.duplicateTemplate.request, sendApi(api.template.duplicate)),
    yield takeLatest(settingsConstants.previewTemplate.request, previewTemplate),
    // recovery plan
    yield takeLatest(
      settingsConstants.recoveryPlanListPage.request,
      sendApi(api.recoveryPlan.list, settingsActions.recoveryPlanListPageRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(
      settingsConstants.recoveryPlanList.request,
      sendApi(api.recoveryPlan.list, undefined),
    ),
    yield takeEvery(settingsConstants.getRecoveryPlan.request, sendApi(api.recoveryPlan.detail)),
    yield takeLatest(settingsConstants.addRecoveryPlan.request, sendApi(api.recoveryPlan.add)),
    yield takeLatest(settingsConstants.setRecoveryPlan.request, sendApi(api.recoveryPlan.update)),
    yield takeLatest(
      settingsConstants.deleteRecoveryPlan.request,
      sendApi(api.recoveryPlan.delete),
    ),
    yield takeLatest(
      settingsConstants.duplicateRecoveryPlan.request,
      sendApi(api.recoveryPlan.duplicate),
    ),
    yield takeLatest(
      settingsConstants.getPlansInvoices.request,
      sendApi(api.recoveryPlan.getPlansInvoices),
    ),
    yield takeLatest(
      settingsConstants.switchPlansInvoices.request,
      sendApi(api.recoveryPlan.switchPlansInvoices, settingsActions.switchPlansInvoicesRes),
    ),
    // custom variable
    yield takeLatest(
      settingsConstants.customVariableListPage.request,
      sendApi(api.customVariable.list, settingsActions.customVariableListPageRes, (payload) => ({
        data: payload,
        noLoading: true,
      })),
    ),
    yield takeLatest(
      settingsConstants.customVariableList.request,
      sendApi(api.customVariable.list, undefined),
    ),
    yield takeLatest(
      settingsConstants.addCustomVariable.request,
      sendApi(api.customVariable.add, settingsActions.customVariableListPage),
    ),
    yield takeLatest(
      settingsConstants.setCustomVariable.request,
      sendApi(api.customVariable.update, settingsActions.customVariableListPage),
    ),
    yield takeLatest(
      settingsConstants.deleteCustomVariable.request,
      sendApi(api.customVariable.delete, settingsActions.customVariableListPage),
    ),
  ]);
}

export default function* saga() {
  yield call(settingsWatchers);
}
