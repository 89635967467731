import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller } from 'react-hook-form';
import { isNullish } from 'remeda';
import { CustomSelect } from 'shared/io';
import { useGetConstants } from 'shared/utils/selectors';

import { PostalPreferencesFieldsName } from '../PreferencesForm.constants';

import styleIdentifiers from './PostalPreferencesPartialForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const PostalPreferencesPartialForm = () => {
  const { t } = useTranslation();

  const constants = useGetConstants();

  if (isNullish(constants)) return null;

  const { print_modes, print_colors } = constants;

  return (
    <div className={styles('grid-row', 'postal-send-partial-form')}>
      <div className={styles('col-4')}>
        <Controller
          name={PostalPreferencesFieldsName.PRINT_COLOR}
          rules={{ required: true }}
          render={() => (
            <CustomSelect
              name={PostalPreferencesFieldsName.PRINT_COLOR}
              label={t(i18nKeys.FORM.PREFERENCES.COLOR)}
              items={print_colors}
              keyText="description"
              keyValue="value"
            />
          )}
        />
      </div>
      <div className={styles('col-4')}>
        <Controller
          name={PostalPreferencesFieldsName.PRINT_MODE}
          rules={{ required: true }}
          render={() => (
            <CustomSelect
              name={PostalPreferencesFieldsName.PRINT_MODE}
              label={t(i18nKeys.FORM.PREFERENCES.PRINTING)}
              items={print_modes}
              keyText="description"
              keyValue="value"
            />
          )}
        />
      </div>
    </div>
  );
};
