import classNames from 'classnames/bind';
import { CodaboxForm, CodaboxFormFields } from 'shared/components/Integrations';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';

import styleIdentifiers from './Codabox.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: (data: CodaboxFormFields) => void;
};

export default function Codabox({ onSubmit }: Props) {
  const tokenRequest = () => {
    onBoardingActions.codaboxRequest({
      callback: (data) => {
        onBoardingActions.changeCodaboxMessage(data.message);
      },
    });
  };

  return (
    <div className={styles('Codabox')}>
      <CodaboxForm onSubmit={onSubmit} tokenRequest={tokenRequest} />
    </div>
  );
}
