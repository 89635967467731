import { ReactNode } from 'react';
import classNames from 'classnames/bind';

import { useId } from '@mantine/hooks';

import styleIdentifiers from './CustomRadio.module.scss';

const styles = classNames.bind(styleIdentifiers);

type CustomRadioProps = {
  label: ReactNode;
  valueItem: any;
  nameItem?: string;
  className?: string;
  contentClassName?: string;
  onChange: Function;
  ref?: any;
};

export const CustomRadio = ({
  label,
  valueItem,
  nameItem,
  className,
  contentClassName,
  onChange,
  ...props
}: CustomRadioProps) => {
  const id = useId();

  const handleChange = () => {
    onChange(valueItem);
  };

  return (
    <div className={styles('CustomRadio', className)}>
      <label htmlFor={id}>
        <input
          type="radio"
          name={nameItem}
          value={valueItem}
          id={id}
          onChange={handleChange}
          {...props}
        />
        <div className={styles('single', contentClassName)}>{label}</div>
      </label>
    </div>
  );
};
