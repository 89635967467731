import { NavLink, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

import { Menu } from '../types';

import { styles } from './NavMenuItem';

interface NavMenuProps {
  menu: Menu;
  setMenu: (menu: Menu) => void;
}

export const NavMenu = ({ menu, setMenu }: NavMenuProps) => {
  if (menu.isHidden) return null;

  return (
    <NavLink
      onClick={() => setMenu(menu)}
      className={styles.navLink}
      classNames={{
        root: styles.navLinkRoot(),
      }}
      label={<Text c="#4D4D4D">{menu.label}</Text>}
      leftSection={<menu.Icon color="#67869B" stroke="1.7" size={20} />}
      rightSection={<IconChevronRight color="#4D4D4D" size={16} />}
    />
  );
};
