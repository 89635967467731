import { i18nKeys } from 'locales';
import { ReminderItem } from 'modules/Reminders/api/use-load-index-reminders';

export type ExternalMailListFiltersFields = ReminderItem;

type TableHeader<T extends object> = {
  title: string;
  key?: keyof T;
  width?: number;
};

export const TABLE_HEADERS: Array<TableHeader<ReminderItem>> = [
  {
    key: 'reference',
    title: i18nKeys.REMINDER_TABLE.REFERENCE,
  },
  {
    key: 'sentAt',
    title: i18nKeys.REMINDER_TABLE.SENT_AT,
  },
  {
    key: 'debtorFullName',
    title: i18nKeys.REMINDER_TABLE.DEBTOR,
  },
  {
    key: 'reminderType',
    title: i18nKeys.REMINDER_TABLE.REMINDER_TYPE,
  },
];
