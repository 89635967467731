import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'shared/io';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './AddNewView.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
};

export default function AddNewView({ onSubmit }: Props) {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm();

  return (
    <form className={styles('add-new-view')} onSubmit={handleSubmit(onSubmit)}>
      <Input register={register('name')} label={t(i18nKeys.VIEW_NAME)} name="name" noMargin />
      <div className={styles('button-wrapper')}>
        <Button label={t(i18nKeys.ACTIONS.ADD)} type="submit" />
      </div>
    </form>
  );
}
