import { i18nKeys, useTranslation } from 'locales';
import { Reports } from 'shared/hooks';

import { Badge } from '@mantine/core';

interface periodicityLabelProps {
  type: Reports[number]['periodicity']['type'];
}

export const PeriodicityLabel = ({ type }: periodicityLabelProps) => {
  const { t } = useTranslation();
  const isWeekly = type === 'weekly';

  const [text, color] = isWeekly
    ? [t(i18nKeys.DATES.PERIOD_NAMES.WEEKLY), 'indigo.4']
    : [t(i18nKeys.DATES.PERIOD_NAMES.MONTHLY), 'cyan.4'];

  return (
    <Badge variant="light" color={color}>
      {text}
    </Badge>
  );
};
