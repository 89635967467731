import {
  BaseApiActionsType,
  buildActions,
  makeReqCons,
  makeResourceConstants,
} from 'store/actions';
import { ReduxFunction } from 'types/redux';

const prefix = 'invoice';

export const invoiceConstants = {
  ...makeResourceConstants(prefix),
  deleteMultiple: makeReqCons(`${prefix}_DELETE_MULTIPLE`),
  createInvoicePdf: makeReqCons(`${prefix}_PDF_CREATE`),
  getInvoices: makeReqCons(`${prefix}_GET_INVOICES`),
  toHandle: makeReqCons(`${prefix}_TO_HANDLE`),
  postponable: makeReqCons(`${prefix}_POSTPONABLE`),
  activity: makeReqCons(`${prefix}_ACTIVITY`),
  getActivityPage: makeReqCons(`${prefix}_ACTIVITY_PAGE`),
  info: makeReqCons(`${prefix}_info`),
  send: makeReqCons(`${prefix}_SEND`),
  downloadPdf: makeReqCons(`${prefix}_download_pdf`),
  document: makeReqCons(`${prefix}_document`),
  previewTemplate: makeReqCons(`${prefix}_preview_template`),
  setSendingOptions: makeReqCons(`${prefix}_SET_SENDING_OPTIONS`),

  getAdditionalFiles: makeReqCons(`${prefix}_ADDITIONAL_FILES`),
  addAdditionalFile: makeReqCons(`${prefix}_ADD_ADDITIONAL_FILE`),
  deleteAdditionalFile: makeReqCons(`${prefix}_DELETE_ADDITIONAL_FILE`),
  addOriginalInvoice: makeReqCons(`${prefix}_ADD_ORIGINAL_INVOICE`),
  updateOriginalFile: makeReqCons(`${prefix}_UPDATE_ORIGINAL_INVOICE`),

  importInvoiceCSV: makeReqCons(`${prefix}_CSV_IMPORT`),
  getAllToConfirm: makeReqCons(`${prefix}_GET_ALL_TO_CONFIRM`),
  getToConfirm: makeReqCons(`${prefix}_GET_TO_CONFIRM`),
  validateAllImportedInvoices: makeReqCons(`${prefix}_VALIDATE_ALL_TO_CONFIRM`),
  deleteInvoiceToConfirm: makeReqCons(`${prefix}_DELETE_TO_CONFIRM`),

  copy: makeReqCons(`${prefix}_copy`),
  assignToAccManager: makeReqCons(`${prefix}_assign_to_user`),
  getRecoveryPlans: makeReqCons(`${prefix}_get_recovery_plan`),
  setRecoveryPlan: makeReqCons(`${prefix}_set_recovery_plan`),
  getGroupedInvoices: makeReqCons(`${prefix}_get_grouped_invoices`),
  // debtor reaction
  debtorReactionQrCode: makeReqCons(`${prefix}_debtor_reaction_QR_CODE`),
  debtorReaction: makeReqCons(`${prefix}_debtor_reaction`),
  debtorReactionComment: makeReqCons(`${prefix}_debtor_reaction_comment`),
  debtorReactionInvoices: makeReqCons(`${prefix}_debtor_reaction_get_paid_invoices`),
  debtorReactionCreditNotes: makeReqCons(`${prefix}_debtor_reaction_credit_notes`),
  getGroupedInvoicePaymentInfo: makeReqCons(`${prefix}_get_grouped_invoice_payment_info`),
  actions: {
    comment: makeReqCons(`${prefix}_comment`),
    conversationMessage: makeReqCons(`${prefix}_conversation_message`),
    replyLawyerQuestion: makeReqCons(`${prefix}_reply_lawyer_question`),
    addPayment: makeReqCons(`${prefix}_add_payment`),
    postpone: makeReqCons(`${prefix}_POSTPONE`),
    forceReminder: makeReqCons(`${prefix}_FORCE_REMINDER`),
    postponeMultiple: makeReqCons(`${prefix}_POSTPONE_MULTIPLE`),
    ignoreLateFees: makeReqCons(`${prefix}_IGNORE_LATE_FEES`),
    // mark as
    paid: makeReqCons(`${prefix}_paid`),
    lost: makeReqCons(`${prefix}_LOST`),
    pause: makeReqCons(`${prefix}_PAUSE`),
    disputed: makeReqCons(`${prefix}_DISPUTED`),
    batchAction: makeReqCons(`${prefix}_batch_action`),
    // to handle action
    additionalReminder: makeReqCons(`${prefix}_additional_reminder`),
    callcenter: makeReqCons(`${prefix}_CALL_CENTER`),
    formalNotice: makeReqCons(`${prefix}_FORMAL_NOTICE`),
    lawyer: makeReqCons(`${prefix}_LAWYER`),
    closeLawyer: makeReqCons(`${prefix}_CLOSE_LAWYER`),
    bailiff: makeReqCons(`${prefix}_BAILIFF`),
    closeBailiff: makeReqCons(`${prefix}_CLOSE_BAILIFF`),
    markAsToBeProccesed: makeReqCons(`${prefix}_MARK_AS_TO_BE_PROCESSED`),
    thirdParty: makeReqCons(`${prefix}_THIRD_PARTY`),
  },
};

type InvoiceActionsType = BaseApiActionsType & {
  deleteMultiple: Function;
  createInvoicePdf: ReduxFunction;
  createInvoicePdfRes: Function;
  getInvoices: ReduxFunction;
  getInvoicesRes: Function;
  toHandle: ReduxFunction;
  toHandleRes: Function;
  postponable: ReduxFunction;
  postponableRes: Function;
  activity: ReduxFunction;
  getGroupedInvoicePaymentInfo: ReduxFunction;
  activityRes: Function;
  getActivityPage: ReduxFunction;
  getActivityPageRes: Function;
  info: Function;
  infoRes: Function;
  send: Function;
  sendRes: Function;
  downloadPdf: Function;
  downloadPdfRes: Function;
  document: Function;
  documentRes: Function;
  previewTemplate: ReduxFunction;
  previewTemplateRes: Function;
  setSendingOptions: ReduxFunction;
  setSendingOptionsRes: Function;

  getAdditionalFiles: ReduxFunction;
  getAdditionalFilesRes: Function;
  addAdditionalFile: ReduxFunction;
  addAdditionalFileRes: Function;
  deleteAdditionalFile: ReduxFunction;
  deleteAdditionalFileRes: Function;
  addOriginalInvoice: ReduxFunction;
  addOriginalInvoiceRes: Function;
  updateOriginalFile: ReduxFunction;
  updateOriginalFileRes: Function;

  importInvoiceCSV: ReduxFunction;
  importInvoiceCSVRes: Function;
  getAllToConfirm: ReduxFunction;
  getAllToConfirmRes: Function;
  getToConfirm: ReduxFunction;
  getToConfirmRes: Function;
  validateAllImportedInvoices: ReduxFunction;
  validateAllImportedInvoicesRes: Function;
  deleteInvoiceToConfirm: ReduxFunction;
  deleteInvoiceToConfirmRes: Function;

  copy: ReduxFunction;
  copyRes: Function;
  assignToAccManager: ReduxFunction;
  assignToAccManagerRes: Function;
  getRecoveryPlans: ReduxFunction;
  getRecoveryPlansRes: Function;
  setRecoveryPlan: ReduxFunction;
  setRecoveryPlanRes: Function;
  debtorReaction: ReduxFunction;
  debtorReactionRes: Function;
  debtorReactionQrCode: ReduxFunction;
  debtorReactionQrCodeRes: Function;
  debtorReactionComment: ReduxFunction;
  debtorReactionCommentRes: Function;
  debtorReactionInvoices: ReduxFunction;
  debtorReactionInvoicesRes: Function;
  debtorReactionCreditNotes: ReduxFunction;
  debtorReactionCreditNotesRes: Function;
  getGroupedInvoices: ReduxFunction;
  getGroupedInvoicesRes: Function;

  actionsComment: Function;
  actionsCommentRes: Function;
  actionsIgnoreLateFees: ReduxFunction;
  actionsIgnoreLateFeesRes: Function;
  actionsConversationMessage: ReduxFunction;
  actionsConversationMessageRes: Function;
  actionsReplyLawyerQuestion: Function;
  actionsReplyLawyerQuestionRes: Function;
  actionsAddPayment: ReduxFunction;
  actionsAddPaymentRes: Function;
  actionsPostpone: Function;
  actionsPostponeRes: Function;
  actionsForceReminder: Function;
  actionsForceReminderRes: Function;
  actionsPostponeMultiple: ReduxFunction;
  actionsPostponeMultipleRes: Function;
  // mark as
  actionsPaid: ReduxFunction;
  actionsPaidRes: Function;
  actionsLost: ReduxFunction;
  actionsLostRes: Function;
  actionsPause: ReduxFunction;
  actionsPauseRes: Function;
  actionsDisputed: ReduxFunction;
  actionsDisputedRes: Function;
  actionsBatchAction: ReduxFunction;
  actionsBatchActionRes: Function;
  // to handle action
  actionsAdditionalReminder: ReduxFunction;
  actionsAdditionalReminderRes: Function;
  actionsCallcenter: ReduxFunction;
  actionsCallcenterRes: Function;
  actionsFormalNotice: ReduxFunction;
  actionsFormalNoticeRes: Function;
  actionsLawyer: ReduxFunction;
  actionsLawyerRes: Function;
  actionsCloseLawyer: ReduxFunction;
  actionsCloseLawyerRes: Function;
  actionsBailiff: ReduxFunction;
  actionsBailiffRes: Function;
  actionsCloseBailiff: ReduxFunction;
  actionsCloseBailiffRes: Function;
  actionsMarkAsToBeProccesed: ReduxFunction;
  actionsMarkAsToBeProccesedRes: Function;

  actionsThirdParty: ReduxFunction;
  actionsThirdPartyRes: Function;
};
const invoiceActions = buildActions<InvoiceActionsType>('', invoiceConstants, {});

export { invoiceActions };
