import { PropsWithChildren, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { CreateExternalMailboxVariables } from 'shared/hooks';
import { Button, ButtonColor, Input } from 'shared/io';
import { dialogHide, DialogShowId } from 'store/view/view.actions';

import styleIdentifiers from './ExternalMailboxSettingsForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type OAuthModalProps = {
  onConfirm: (data: CreateExternalMailboxVariables) => void;
  confirmButtonColor?: ButtonColor;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function OAauthModal({
  confirmButtonColor,
  onConfirm,
  children,
}: PropsWithChildren<OAuthModalProps>) {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');

  const onClickOnButton = (e) => {
    e.preventDefault();
    dialogHide(DialogShowId.CONFIRM);
  };

  return (
    <div className={styles('oauth-modal')}>
      <Input
        type="email"
        withBorder
        label={t(i18nKeys.EMAIL)}
        noMargin
        value={email}
        onValueChanged={(value) => setEmail(value)}
      />
      <form onSubmit={onClickOnButton}>
        <Button
          type="submit"
          color={ButtonColor.GREY}
          label={t(i18nKeys.CANCEL)}
          onClick={onClickOnButton}
          noShadow
        />
        <Button
          type="submit"
          color={confirmButtonColor}
          label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.CONNECT)}
          onClick={() => onConfirm({ login: email, is_microsoft: true })}
          noShadow
          childrenBefore
          disabled={!email.match(/^[^@\s]+@[^@\s]+\.[^@\s]{2,}$/)}
        >
          {children}
        </Button>
      </form>
    </div>
  );
}
