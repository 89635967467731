import { useEffect, useReducer } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider } from 'react-hook-form';
import { Reducer } from 'redux';
import FormSection from 'shared/forms/FormSection';
import { AmountFields, CustomSelect, DateSelector, Input } from 'shared/io';
import { useFilterForm } from 'shared/utils/hooks';
import { reducerState, removeAttributes } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { TableFilter } from 'types/views';

import styleIdentifiers from './ClientFiltersForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientFiltersFormProps = {
  onSubmit: onSubmitFunction;
  initialValues: any;
  filterObject: any;
};
type State = Reducer<
  {
    filters: TableFilter[];
    filtersSearched: TableFilter[];
  },
  any
>;

export const ClientFiltersForm = ({
  onSubmit,
  initialValues,
  filterObject,
}: ClientFiltersFormProps) => {
  const { t } = useTranslation();

  const [{ filters, filtersSearched }, setState] = useReducer<State>(reducerState, {
    filters: [],
    filtersSearched: [],
  });

  const { form, submit, resetForm } = useFilterForm({
    onSubmit,
    initialValues,
    fieldToRegister: [],
  });

  useEffect(() => {
    if (filterObject) {
      filterObject.reset = () => {
        resetForm();
      };
    }
  }, [filterObject, resetForm]);

  useEffect(() => {
    appActions.getFilters({
      data: {
        view: 'debtors',
      },
      callback: ({ data }) => {
        const newFilters = data.map((item) => removeAttributes(item));
        setState({
          filters: newFilters,
          filtersSearched: newFilters,
        });
      },
    });
  }, []);

  const { register, handleSubmit, watch, setValue } = form;

  const formData = watch();

  const searchText = watch('filter');

  useEffect(() => {
    setState({
      filtersSearched: filters.filter((filter) => filter.name.indexOf(searchText) !== -1),
    });
  }, [searchText]);

  return form ? (
    <FormProvider {...form}>
      <form className={styles('ClientFiltersForm')} onSubmit={handleSubmit(submit)}>
        <div className={styles('search-filter')}>
          <Input
            register={register('filter')}
            noMargin
            type="text"
            label={t(i18nKeys.FORM.RESEARCH)}
          />
        </div>
        <div className={styles('container-fields')}>
          {filtersSearched.map((filter) => (
            <FormSection
              startClosed
              key={filter.id}
              title={filter.name_translated}
              className={styles('filter-item')}
            >
              <div className={styles('filter-container')}>
                {filter.filter_type === 'string' ? (
                  <Input
                    register={register(filter.name)}
                    noMargin
                    withBorder
                    shadow
                    type="text"
                    placeholder={filter.name_translated}
                    onValueChanged={submit}
                  />
                ) : filter.filter_type === 'select' ? (
                  <Controller
                    defaultValue=""
                    name={filter.name}
                    render={() => (
                      <CustomSelect
                        selectClassName={styles('large')}
                        removeAll={t(i18nKeys.CLIENT.FILTER.ALL)}
                        keyText="description"
                        keyValue="value"
                        size="small"
                        name={filter.name}
                        noMargin
                        noBorder
                        withBorder
                        shadow
                        items={[]}
                        onValueChanged={submit}
                      />
                    )}
                  />
                ) : filter.filter_type === 'date' ? (
                  <DateSelector
                    className={styles('input', 'date-selector')}
                    name={`${filter.name}_start`}
                    endName={`${filter.name}_end`}
                    placeholder={t(i18nKeys.OF)}
                    endPlaceholder={t(i18nKeys.FORM.TO)}
                    withBorder
                    shadow
                    noMinDate
                    handleChange={(value) => {
                      setValue(`${filter.name}_start`, value);
                      submit();
                    }}
                    handleEndChange={(value) => {
                      setValue(`${filter.name}_end`, value);
                      submit();
                    }}
                  />
                ) : filter.filter_type === 'number' ? (
                  <AmountFields
                    className={styles('spacing')}
                    name={filter.name}
                    onChange={submit}
                  />
                ) : filter.filter_type === 'boolean' ? (
                  <Controller
                    defaultValue=""
                    name={filter.name}
                    render={() => (
                      <CustomSelect
                        selectClassName={styles('large')}
                        removeAll={t(i18nKeys.CLIENT.FILTER.ALL)}
                        keyText="description"
                        keyValue="value"
                        size="small"
                        name={filter.name}
                        noMargin
                        noBorder
                        withBorder
                        shadow
                        items={[
                          {
                            description: t(i18nKeys.YES),
                            value: true,
                          },
                          {
                            description: t(i18nKeys.NO),
                            value: 'false',
                          },
                        ]}
                        onValueChanged={submit}
                      />
                    )}
                  />
                ) : null}
              </div>
            </FormSection>
          ))}
        </div>
      </form>
    </FormProvider>
  ) : null;
};
