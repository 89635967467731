import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import { validationMessage } from 'shared/utils/validation';
import { OnFocusEvent } from 'types/html-type';

import styleIdentifiers from './Textarea.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  name?: string;
  label?: string;
  icon?: string;
  iconSize?: string;
  noMargin?: boolean;
  withBorder?: boolean;
  noBorder?: boolean;
  rows?: number;
  oneLine?: boolean;
  inputClassName?: string;
  wrapperClassName?: string;
  error?: any;
  shadow?: boolean;
  register?: any;
};

export const Textarea = ({
  onFocus,
  label,
  noBorder,
  withBorder,
  noMargin,
  rows,
  className,
  oneLine,
  wrapperClassName,
  inputClassName,
  error,
  ref,
  register,
  shadow,
  ...props
}: TextareaProps) => {
  const { t } = useTranslation();
  const handleInputFocus = (event: OnFocusEvent) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <div
      className={styles(
        'Textarea',
        withBorder && 'with-border',
        error && 'error',
        !noBorder && 'bordered',
        noMargin && 'no-margin',
        shadow && 'shadow',
        className,
        oneLine && 'one-line',
      )}
    >
      <div className={styles(wrapperClassName, 'input-wrapper')}>
        <div className={styles('label-input')}>{label}</div>
        <textarea
          {...register}
          className={inputClassName}
          rows={rows || 3}
          onFocus={handleInputFocus}
          {...props}
        />
      </div>
      {error && <div className={styles('error-message')}> {t(validationMessage[error.type])}</div>}
    </div>
  );
};
