import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Input } from 'shared/io';

import styleIdentifiers from './Phones.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  className?: string;
  formArray: any[];
  setFormDirty: Function;
};

export default function Phones({ className, formArray, setFormDirty }: Props) {
  const { t } = useTranslation();
  const { register, reset, getValues } = useFormContext();

  const deleteLine = (index) => () => {
    const phones = formArray.slice();
    phones.splice(index, 1);
    if (phones.length === 0) {
      phones.push({ number: '' });
    }
    reset({
      ...getValues(),
      phones_attributes: phones,
    });
    setFormDirty();
  };
  return (
    <div className={styles('Phones')}>
      {formArray?.map((item, index) => (
        <div className={styles('phone-item')} key={index}>
          <Input
            register={register(`phones_attributes.${index}.number`)}
            defaultValue={formArray[index]?.number}
            className={className}
            label={t(i18nKeys.NUMBER)}
            type="text"
            withBorder
          />
          <Icon name={IconName.TRASH_SIMPLE} onClick={deleteLine(index)} />
        </div>
      ))}
    </div>
  );
}
