import { AxiosInstance } from 'axios';
import { omit } from 'remeda';
import { z } from 'zod';

import { useMutation } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export const companyConfigurationSchema = z.object({
  vatNumber: z.string().min(1),
  companyEmail: z.string().min(1),
  phoneNumber: z.string().min(1),
  locale: z.string().min(1),
  currency: z.string().min(1),
  addressAttributes: z.object({
    streetName: z.string().min(1),
    streetNumber: z.string().min(1),
    zipCode: z.string().min(1),
    city: z.string().min(1),
    countryCode: z.string().min(1),
  }),
});

const companyConfigurationTransformedSchema = companyConfigurationSchema.transform((data) => ({
  ...omit(data, ['phoneNumber', 'locale']),
  phonesAttributes: [{ number: data.phoneNumber }],
  locale: data.locale.toLowerCase(),
}));

export type CompanyConfiguration = z.infer<typeof companyConfigurationSchema>;

export async function saveCompanyConfigurationFn(
  axiosInstance: Promise<AxiosInstance>,
  data: CompanyConfiguration,
) {
  try {
    companyConfigurationSchema.parse(data);
  } catch (error) {
    console.error(error?.toString());
  }

  const instance = await axiosInstance;
  return instance.post(
    'onboarding/company_initialization',
    companyConfigurationTransformedSchema.parse(data),
  );
}

export function useSaveCompanyConfiguration() {
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, CompanyConfiguration>({
    mutationFn: (data: CompanyConfiguration) => saveCompanyConfigurationFn(axiosInstance, data),
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    CompanyConfiguration,
    'saveCompanyConfiguration'
  >('saveCompanyConfiguration', mutation);
}
