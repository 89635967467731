import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { accountActions } from 'store/account/account.actions';
import { StoreState } from 'types/storeTypes';

export const useSynchroniseIntegration = () => {
  const company = useSelector((state: StoreState) => state.account.company.data!);

  const {
    tp_accounting: { can_synchronise_import, last_import_date, manual_import_possible_from },
  } = company;

  const canSynchronize = useMemo(() => {
    if (manual_import_possible_from) {
      return new Date(manual_import_possible_from).getTime() < new Date().getTime();
    }

    return !!can_synchronise_import;
  }, [can_synchronise_import, manual_import_possible_from]);

  const synchronize = () => {
    if (canSynchronize) {
      accountActions.syncIntegration({
        actionFailure: accountActions.getCompanyRes,
      });
    }
  };

  return {
    synchronize,
    canSynchronize,
    lastImportDate: last_import_date,
    nextPossibleSynchronizeDate: manual_import_possible_from,
  };
};
