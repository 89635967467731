import { useEffect } from 'react';
import { GetPaymentPlansParams } from 'api/models';
import { TaskFormDebtor, TaskFormInvoices } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { isNil, omit, omitBy } from 'lodash-es';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CustomSelect } from 'shared/io';

import { PaymentPlansListFiltersFields, PaymentPlanStatus } from '../PaymentPlan.constants';

import styleIdentifiers from './PaymentPlansList.module.scss';

const styles = classNames.bind(styleIdentifiers);

type PaymentPlansListFiltersProps = {
  onSubmit: SubmitHandler<PaymentPlansListFiltersFields>;
  initialValues: GetPaymentPlansParams;
  filterObject: {
    reset: () => void;
  };
};

export const PaymentPlansListFilters = ({
  onSubmit,
  initialValues,
  filterObject,
}: PaymentPlansListFiltersProps) => {
  const { t } = useTranslation();

  const form = useForm<PaymentPlansListFiltersFields>({
    shouldUnregister: true,
    defaultValues: omit(initialValues, ['page', 'page_limit', 'sort_by', 'sort_order']),
  });

  const { setValue, handleSubmit, watch, getValues } = form;

  const values = watch();

  useEffect(() => {
    filterObject.reset = () => {
      Object.keys(getValues()).forEach((key) =>
        form.setValue(key as keyof PaymentPlansListFiltersFields, ''),
      );
    };
  }, [filterObject, form, getValues, setValue]);

  useEffect(() => {
    onSubmit(omitBy(values, (v) => isNil(v) || (Array.isArray(v) && !v.length) || v === ''));
  }, [values, onSubmit]);

  const planStatus = Object.values(PaymentPlanStatus).map((value) => ({
    description: t(i18nKeys[value]),
    value: value.toLowerCase(),
  }));

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles('ThirdpartyCasesFiltersForm')}>
        <div className={styles('container-fields')}>
          <div className={styles('fields')}>
            <Controller
              name="status"
              render={() => (
                <CustomSelect
                  name="status"
                  keyText="description"
                  keyValue="value"
                  items={planStatus}
                  label={t(i18nKeys.TASK.ATTRIBUTES.STATUS)}
                  withBorder
                  multiple
                />
              )}
            />
            <TaskFormDebtor />
            <TaskFormInvoices />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
