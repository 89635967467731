/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */

export type AssignableTo = (typeof AssignableTo)[keyof typeof AssignableTo];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignableTo = {
  recovery_plan: 'recovery_plan',
  user: 'user',
} as const;
