import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import {
  CreateExternalMailboxVariables,
  ExternalMailboxConfiguration,
  useDeleteExternalMailbox,
  useUpdateExternalMailbox,
} from 'shared/hooks';
import { ButtonColor } from 'shared/io';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';
import { TableItemProps } from 'types/table';

import { ExternalMailboxSettingsForm } from './ExternalMailboxSettingsForm';

import styleIdentifiers from './ExternalMailboxSettings.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ExternalMailboxSettingsItemProps = TableItemProps<ExternalMailboxConfiguration>;

export const ExternalMailboxSettingsItem = ({ item }: ExternalMailboxSettingsItemProps) => {
  const { t } = useTranslation();

  const { updateExternalMailbox } = useUpdateExternalMailbox();

  const { deleteExternalMailbox } = useDeleteExternalMailbox();

  const {
    id,
    attributes: { host, port, ssl: isUsingSsl, login, is_microsoft: isMicrosoft },
  } = item;

  const handleUpdate = () =>
    sideMenuShow({
      unmount: true,
      content: (
        <ExternalMailboxSettingsForm
          edit
          externalMailboxConfiguration={item}
          onSubmit={(data) => {
            const temp = { ...data, is_microsoft: false } as Extract<
              CreateExternalMailboxVariables,
              { is_microsoft: false }
            >;
            updateExternalMailbox(
              { id, data: temp },
              {
                onSuccess: sideMenuHide,
              },
            );
          }}
        />
      ),
    });

  const handleDelete = () =>
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_EXTERNAL_MAILBOX_CONFIGURATION)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteExternalMailbox(
              { id },
              {
                onSuccess: () => {
                  dialogHide(DialogShowId.CONFIRM);
                },
              },
            )
          }
        />
      ),
    });

  return (
    <tr key={id} className={styles('Mailbox-settings-item')}>
      <td />
      <td>{id}</td>
      <td>{host}</td>
      <td>{port}</td>
      <td>{t(!isUsingSsl ? i18nKeys.NO : i18nKeys.YES)}</td>
      <td>{login}</td>
      <td className={styles('actions')}>
        {!isMicrosoft && (
          <span onClick={handleUpdate}>
            <Icon name={IconName.PENCIL} size="18px" className={styles('action')} />
          </span>
        )}
        <span onClick={handleDelete}>
          <Icon name={IconName.TRASH_SIMPLE} size="18px" className={styles('action')} />
        </span>
      </td>
    </tr>
  );
};
