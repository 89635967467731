import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const InvoiceFeeSchema = z.object({
  id: z.number(),
  reference: z.string(),
  collectionFee: z.preprocess(Number, z.number()),
  gcollectCollectionRate: z.preprocess(Number, z.number()),
  remainingAmount: z.preprocess(Number, z.number()),
});

const GcollectCaseFeeSchema = z.object({
  invoices: z.array(InvoiceFeeSchema),
});

export type GcollectCaseFee = z.infer<typeof GcollectCaseFeeSchema>;

export async function loadGcollectCaseFeeFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const params = new URLSearchParams();
  queryKey[1].forEach((caseId) => params.append('third_party_case_ids[]', caseId));

  const { data } = await instance.get(`/gcollect_cases/rates?${params.toString()}`);

  return GcollectCaseFeeSchema.parse(data);
}

export const useLoadGcollectCaseFee = (caseIds: Array<number>) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<GcollectCaseFee, ApiError>({
    queryKey: ['gcollectCaseFee', caseIds] as const,
    queryFn: (context) => loadGcollectCaseFeeFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<GcollectCaseFee, ApiError, 'gcollectCaseFee'>(
    'gcollectCaseFee',
    queryResult,
  );
};
