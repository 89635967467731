import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { formatBankAccount } from 'shared/utils/normalization';
import { getBankImage } from 'shared/utils/view';

import { Icon, IconName } from '../Icon';

import styleIdentifiers from './bankAccount.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  value: string;
  className?: string;
  small?: boolean;
  image?: string;
};

export default function BankAccount({ className, small, image, value = '' }: Props) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const fetchImageSrc = async () => {
      if (!image) {
        setImageSrc(null);
        return;
      }

      try {
        const bankImage = await getBankImage(image);
        setImageSrc(bankImage || null);
      } catch (error) {
        console.error('Error fetching bank image:', error);
        setImageSrc(null);
      }
    };

    fetchImageSrc();
  }, [image]);

  return (
    <div className={styles('bank-account', className)}>
      {image && (
        <div className={styles('bank-image')}>
          {imageSrc ? <img src={imageSrc} alt="Bank logo" /> : <Icon name={IconName.BANK} />}
        </div>
      )}
      <span className={styles('iban')}>
        {small
          ? `${value.substring(0, 4)} **** ${value.substring(value.length - 4)}`
          : formatBankAccount(value)}
      </span>
    </div>
  );
}
