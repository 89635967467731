import { useEffect, useRef, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { isEmpty } from 'remeda';
import {
  useDeleteView,
  useDuplicateView,
  useProfile,
  useRemoveFavoriteView,
  useSetFavoriteView,
  useToggleViewPrivacy,
  useUpdateView,
  ViewV2,
} from 'shared/hooks';
import { ResourceType } from 'types/resource-types';

import { css, cx } from '@emotion/css';
import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Card,
  Group,
  Loader,
  Overlay,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue, useFocusTrap, useIsFirstRender } from '@mantine/hooks';
import { IconCheck, IconCopy, IconTrash } from '@tabler/icons-react';

import { ViewIcon } from './ViewIcon';

const styles = {
  overlay: {
    base: css`
      pointer-events: none;
      opacity: 0;
      transition: opacity 300ms ease;
    `,
    visible: css`
      pointer-events: all;
      opacity: 1;
    `,
  },
};

interface ViewSettingProps {
  view: ViewV2;
  onDuplicate: () => void;
}

export const ViewSettings = ({ view, onDuplicate }: ViewSettingProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const profile = useProfile();

  const { setFavoriteView } = useSetFavoriteView();
  const { removeFavoriteView } = useRemoveFavoriteView();
  const { toggleViewPrivacy } = useToggleViewPrivacy();
  const { duplicateView, isDuplicateViewLoading } = useDuplicateView();
  const { deleteView, isDeleteViewLoading } = useDeleteView();
  const { updateView, isUpdateViewLoading } = useUpdateView();
  const isFirstRender = useIsFirstRender();
  const focusTrapRef = useFocusTrap();

  const isUserAuthor = view.user.id === profile.id;

  const [showDeletionConfirm, setShowDeletionConfirm] = useState(false);
  const [name, setName] = useState(view.name);
  const initialName = useRef(view.name);
  const [debouncedName] = useDebouncedValue(name, 500);

  useEffect(() => {
    if (isFirstRender) return;

    updateView({
      viewId: view.id,
      name: debouncedName,
      resourceType: view.resourceType as ResourceType,
    });
  }, [debouncedName]);

  const hasNameChanged = view.name !== initialName.current;

  return (
    <Card radius="sm" withBorder>
      <Overlay
        radius="sm"
        center
        backgroundOpacity={0.4}
        color="#ffffff"
        blur={10}
        className={cx(styles.overlay.base, { [styles.overlay.visible]: showDeletionConfirm })}
      >
        <Stack>
          <Text mb="lg">{t(i18nKeys.VIEWS.SETTINGS.CONFIRM_DELETE)}</Text>
          <Group justify="right">
            <Button
              flex={1}
              variant="light"
              color="orange"
              onClick={() => setShowDeletionConfirm(false)}
            >
              {t(i18nKeys.CANCEL)}
            </Button>
            <Button
              flex={1}
              loading={isDeleteViewLoading}
              color="red"
              onClick={() => {
                deleteView({ viewId: view.id, resourceType: view.resourceType as ResourceType });
              }}
            >
              {t(i18nKeys.DELETE)}
            </Button>
          </Group>
        </Stack>
      </Overlay>
      <Group justify="space-between" align="start">
        <Group gap={0}>
          <ViewIcon
            view={view}
            onFavorite={() =>
              setFavoriteView({ viewId: view.id, resourceType: view.resourceType as ResourceType })
            }
            onRemoveFavorite={() =>
              removeFavoriteView({
                viewId: view.id,
                resourceType: view.resourceType as ResourceType,
              })
            }
          />
          <TextInput
            ref={focusTrapRef}
            value={name}
            size="xs"
            ml="lg"
            mr="xs"
            onChange={(event) => setName(event.target.value)}
          />
          {(() => {
            if (isUpdateViewLoading) return <Loader size="xs" color="blue.4" />;
            if (hasNameChanged) return <IconCheck stroke={1.3} color={theme.colors.green[5]} />;
            return null;
          })()}
        </Group>
        <Stack align="end">
          <Group>
            <Avatar size="sm" color="cyan" radius="xl" src={view.user.profilePicture}>
              {view.user.name.initials}
            </Avatar>
            <Text size="sm">{view.user.name.full}</Text>
          </Group>
          {isUserAuthor && !view.isDefault && (
            <Switch
              label={t(i18nKeys.VIEWS.SETTINGS.PUBLIC)}
              size="xs"
              checked={view.isShared}
              onChange={() =>
                toggleViewPrivacy({
                  viewId: view.id,
                  resourceType: view.resourceType as ResourceType,
                })
              }
            />
          )}
        </Stack>
      </Group>
      <Stack gap="xs" mt="lg">
        <Text c="dimmed">{t(i18nKeys.VIEWS.SETTINGS.COLUMNS)}:</Text>
        <Group gap="xs">
          {view.columns.map((column, index) => (
            <Badge key={index} size="sm" radius="sm" variant="light">
              {column.name}
            </Badge>
          ))}
        </Group>
      </Stack>
      {!isEmpty(view.filters) && (
        <Stack gap="xs" mt="lg">
          <Text c="dimmed">{t(i18nKeys.VIEWS.SETTINGS.FILTERS)}:</Text>
          <Group gap="xs">
            {view.filters.map((filter, index) => (
              <Badge key={index} size="sm" radius="sm" variant="light" color="violet.5">
                {filter.name}
              </Badge>
            ))}
          </Group>
        </Stack>
      )}
      <Group justify="end" mt="lg" gap="xs">
        <Tooltip label={t(i18nKeys.VIEWS.SETTINGS.DUPLICATE)} withArrow>
          <ActionIcon
            loading={isDuplicateViewLoading}
            variant="outline"
            size="input-xs"
            color="gray.4"
            onClick={() =>
              duplicateView(
                { viewId: view.id, resourceType: view.resourceType as ResourceType },
                { onSuccess: onDuplicate },
              )
            }
          >
            <IconCopy stroke={1.7} size={16} />
          </ActionIcon>
        </Tooltip>
        {!view.isDefault && (
          <ActionIcon
            loading={isDeleteViewLoading}
            variant="light"
            color="red"
            size="input-xs"
            onClick={() => {
              setShowDeletionConfirm(true);
            }}
          >
            <IconTrash stroke={1.5} size={16} />
          </ActionIcon>
        )}
      </Group>
    </Card>
  );
};
