import { useEffect } from 'react';
import { CreateContactPersonBody } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import FormSection from 'shared/forms/FormSection';
import { Input } from 'shared/io';
import { email } from 'shared/utils/validation';

import styleIdentifiers from './ContactPersonForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ContactPersonFormEmailsProps = {};

export const ContactPersonFormEmails = ({}: ContactPersonFormEmailsProps) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext<CreateContactPersonBody>();

  const { fields, append, remove } = useFieldArray({
    name: 'emails',
  });

  useEffect(() => {
    if (!fields.length) {
      append('');
    }
  }, [fields, append]);

  return (
    <FormSection
      title={t(i18nKeys.EMAILS)}
      onAdd={() => {
        append('');
      }}
    >
      <div className={styles('emails')}>
        {fields.map((item, index) => (
          <div key={item.id} className={styles('line-with-delete')}>
            <Input
              label={t(i18nKeys.EMAIL)}
              type="email"
              className={styles('input')}
              errorMessage={get(errors, `emails.${index}`)}
              register={register(`emails.${index}`, {
                validate: email,
                required: true,
              })}
              withBorder
            />
            {fields.length > 1 && (
              <Icon
                name={IconName.TRASH_SIMPLE}
                className={styles('delete')}
                onClick={() => remove(index)}
              />
            )}
          </div>
        ))}
      </div>
    </FormSection>
  );
};
