import { useEffect } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useFieldArray } from 'react-hook-form';
import CustomTable from 'shared/components/CustomTable';
import { OnClickFunction } from 'types/html-type';
import { CreditNote } from 'types/store/credit-note-state';
import { LightInvoice } from 'types/store/invoice-state';

import { LinkInvoiceFormFields } from './LinkInvoiceForm.types';
import { getLinkInvoiceFormFieldLine } from './LinkInvoiceForm.utils';
import { LinkInvoiceFormTableItem } from './LinkInvoiceFormTableItem';

const GroupedTable = CustomTable(LinkInvoiceFormTableItem);

type Props = {
  creditNote: CreditNote;
  invoices: LightInvoice[];
  className?: string;
  reload?: OnClickFunction;
};

export const LinkInvoiceFormTable = ({ creditNote, invoices, className, reload }: Props) => {
  const { t } = useTranslation();

  const headers = [
    {
      title: t(i18nKeys.FORM.REFERENCE),
    },
    {
      title: t(i18nKeys.ISSUE_DATE),
    },
    {
      title: t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT),
    },
    {
      title: t(i18nKeys.LATE_FEES_INCLUDED),
    },
    {
      title: '',
    },
    {
      title: t(i18nKeys.AMOUNT),
    },
  ];

  const { fields, replace } = useFieldArray<LinkInvoiceFormFields>({
    name: 'invoices_to_link',
  });

  useEffect(() => {
    replace(
      invoices.map((e) => getLinkInvoiceFormFieldLine(e, creditNote.matched_payments_attributes)),
    );
  }, [invoices, creditNote, replace]);

  return (
    <GroupedTable
      className={className}
      items={fields}
      itemProps={{ creditNote, invoices, reload }}
      noShadow
      noMargin
      noCheckbox
      headers={headers}
    />
  );
};
